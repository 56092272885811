/**
 * Email Detail
 */
import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import Button from '@mui/material/Button';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CommentIcon from '@mui/icons-material/Comment';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from '@mui/material/Dialog';
import LifeSherpaLoading from '../../LifeSherpaLoading';

class AchievementStepsDetail extends Component {

	state = {
		displayImage: false,
		selectedStep: null,
		selectedIndex: 0
	}

	handleStepsCompletion = (stepId) => {
		let statusValue = null
		Object.keys(this.props.achievementDetail.StepsCompletion).map((objKey, key) => {
			if (this.props.achievementDetail.StepsCompletion[objKey].step.id === stepId) {
				statusValue = this.props.achievementDetail.StepsCompletion[objKey].status

			}
		})
		return statusValue
	}

	handleStepId = (stepId) => {
		let stepResponseId = null
		if (this.props.achievementDetail.StepsCompletion) {
			Object.keys(this.props.achievementDetail.StepsCompletion).map((objKey, key) => {
				if (this.props.achievementDetail.StepsCompletion[objKey].step.id === stepId) {
					stepResponseId = this.props.achievementDetail.StepsCompletion[objKey].stepNotesResponseId

				}
			})
		}
		return stepResponseId
	}

	handleOpenEvaluationForm = () => {
		const { achievementDetail } = this.props

		const formId = achievementDetail && achievementDetail.routine ? achievementDetail.routine.evaluationId : "";
		if (achievementDetail.evaluationResponseId) {
			this.props.getUpdatedEvaluationForm(this.props.selectedMemberId, achievementDetail.evaluationResponseId)
			this.props.handleOpenForm(achievementDetail.evaluationResponseId, null);
		} else if (formId) {
			this.props.getPokeForm(formId)
			this.props.handleOpenForm(formId, null);
		}
	}

	handleOpenStepsNotesForm = (formId, stepId, stepNotesResponseId) => {
		console.log(" Opening activity form -->", stepId)
		const stepResponseId = stepNotesResponseId;
		if (stepResponseId) {
			console.log(" stepNotesResponse Id -->", stepResponseId);
			this.props.getUpdatedEvaluationForm(this.props.selectedMemberId, stepResponseId);
			this.props.handleOpenForm(stepResponseId, stepId);
		} else if (formId) {
			console.log(" Form Id -->", formId);
			this.props.getPokeForm(formId)
			this.props.handleOpenForm(formId, stepId);
		}
	}

	handleConfirmAcheivement() {
		const userId = this.props.selectedMemberId
		const acheivementId = this.props.achievementDetail ? this.props.achievementDetail.id : null
		if (userId && acheivementId) {
			this.props.confirmAchevement(userId, acheivementId, true)
		}
	}
	getCompletionStatus = () => {
		let done = true
		if (this.props.achievementDetail.StepsCompletion) {
			Object.keys(this.props.achievementDetail.StepsCompletion).map((objKey, key) => {
				if (this.props.achievementDetail.StepsCompletion[objKey].status != "Done") {
					done = false
				}
			})
		}
		return done;
	}

	getActivityStatusDetails = () => {
		const { selectedActivity } = this.props;
		let status = selectedActivity ? selectedActivity.status : "";
		let statusString = "";
		if (status == "confirmed") {
			if (this.getCompletionStatus()) {
				statusString = "Confirmed with No Skipped Steps";
			} else {
				statusString = "Confirmed with Skipped Steps";
			}
		} else if (status == "cancelled" || status == "incomplete") {
			statusString = "Not completed (Cancelled or Started and Expired)";
		} else if (status == "completedConfirmationNeeded") {
			statusString = "Completed, Need Confirmation";
		} else if (status == "completedNoConfirmationNeeded") {
			statusString = "Completed with all steps, but not confirmation was required";
		} else if (status == "expired") {
			statusString = "Achievement is expired (Created but not started and expired)";
		} else if (status == "notStarted") {
			statusString = "User was alerted and has not started";
		} else if (status == "confirmedSkippedSteps") {
			statusString = "Confirmed with Skipped Steps";
		} else if (status == "confirmedNegative") {
			statusString = "Negative, Confirmation"
		} else if (status == "inProgress" || status == "paused") {
			statusString = "Routine execution is in progress"
		}
		return statusString;
	}

	isStepCompleted = (stepId) => {
		let stepResponse = null
		if (this.props.achievementDetail.StepsCompletion) {
			Object.keys(this.props.achievementDetail.StepsCompletion).map((objKey, key) => {
				if (this.props.achievementDetail.StepsCompletion[objKey].step.id === stepId) {
					stepResponse = true
				}
			})
		}
		return stepResponse
	}

	handleNotConfirmAcheivement() {
		const userId = this.props.selectedMemberId
		const acheivementId = this.props.achievementDetail ? this.props.achievementDetail.id : null
		if (userId && acheivementId) {
			this.props.confirmAchevement(userId, acheivementId, false)
		}
	}

	handleOpenImage(key, step){
		const selectedIndex = key
		const selectedStep = step
		this.setState({displayImage: true, selectedIndex, selectedStep})
	}

	getFormatedDuration(duration){
		var timeleft = (+duration) * 1000;
        let seconds = Math.round((Math.floor(timeleft / 1000) % 60));
        let minutes = Math.round((Math.floor(timeleft / 60000) % 60));
        let hours = Math.round(Math.floor(timeleft / 3600000));
		return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
	}


	render() {
		const { selectedActivity, achievementDetail } = this.props
		let dateString = achievementDetail && achievementDetail.createdDate !== '' && achievementDetail.createdDate
		let time = dateString ? moment(dateString).format("hh:mm a") : moment().format("hh:mm a")
		let daysAgo = dateString ? moment(dateString).fromNow() : moment().fromNow();
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		const stepsList = [];
		if (achievementDetail && achievementDetail.routine && achievementDetail.routine.steps) {
			Object.keys(achievementDetail.routine.steps).map((id, index) => {
				let stepDetails = achievementDetail.routine.steps[id];
				stepDetails.id = id;
				if (achievementDetail.StepsCompletion) {
					Object.keys(achievementDetail.StepsCompletion).map((objKey, key) => {
						let stepCompletionDetails = achievementDetail.StepsCompletion[objKey];
						if (stepCompletionDetails.step.id === id) {
							stepDetails.status = stepCompletionDetails.status ? stepCompletionDetails.status : null;
							stepDetails.duration = stepCompletionDetails.duration ? stepCompletionDetails.duration : null;
							stepDetails.stepNotesResponseId = stepCompletionDetails.stepNotesResponseId ? stepCompletionDetails.stepNotesResponseId : null;
							stepDetails.isCompleted = true;
							stepDetails.photoUrl = stepCompletionDetails.photoUrl ? stepCompletionDetails.photoUrl : null
						}
					})
				}
				stepsList.push(stepDetails);
			})
			stepsList.sort((a, b) => a.position - b.position);
		}
		return (
			<div>
				<LifeSherpaLoading loading={this.props.loading}/>
				{achievementDetail ?
					<div className="achievement-steps-list-panel" style={{ width: '100%' }}>
						<div className="list-unstyled mb">
							{stepsList.length > 0 && stepsList.map((step, key) => (
								<div key={key} className="list-item border-bottom  list-card">
									<div className='d-flex'>
										<div className="d-flex align-items-center">
											<Avatar
											    title={step.status || "Not Started"}
												className={`mr-10 align-self-center  ${step.status === 'Skipped' ?
													"achievementStatusCancelled"
													: step.status === 'Done' ?
														"achievementStatusConfirmed"
														: "achievementStatusNotFound"}`}
											// style={
											// 	achievementDetail.StepsCompletion && this.handleStepsCompletion(histDataKey) === 'Skipped' ?
											// 		{backgroundColor: 'red'}
											// 		: achievementDetail.StepsCompletion && this.handleStepsCompletion(histDataKey) === 'Done' ?
											// 		{backgroundColor: 'green'}
											// 		:
											// 		{backgroundColor:'#bdbdbd'}
											// }
											>
												{step.status === 'Skipped' ?
													<ClearIcon style={{fontSize: "2rem"}}/>
													: step.status === 'Done' ?
														<DoneRoundedIcon style={{fontSize: "2rem"}}/>
														:
														<DoneRoundedIcon style={{fontSize: "2rem"}}/>
												}
											</Avatar>
											<h3 className="mb-0 mt-0 heading-text">{`[${key + 1}] ${step.title}`}</h3>
										</div>
										<div className="text-right ml-auto d-flex align-items-center justify-content-end">
											<div className="text-center">
												{step.duration &&
													<div className="activity-time-duration">
														{step.duration > 60 ? `${this.getFormatedDuration(step.duration)}` : `${step.duration}s`}
													</div>
												}
												{(selectedActivity.hasStepNotesForm && auth_uid !== uid && step.isCompleted) &&
													<div className="d-flex justify-content-end">
														<IconButton disabled={step.status === 'Skipped'} className={`p-0 ${step.status === 'Skipped' ? 'half-visibility' : ''}`} title="Step Notes Form" onClick={() => this.handleOpenStepsNotesForm(achievementDetail.routine && achievementDetail.routine.stepNotesId, step.id, step.stepNotesResponseId)} size="small">
															<CommentIcon style={achievementDetail.routine && achievementDetail.routine.stepNotesId && step.stepNotesResponseId ? { color: 'orange', cursor: 'pointer' } : { color: '#A5A7B2', cursor: 'pointer' }} />
														</IconButton>
													</div>
												}

											</div>
										</div>
									</div>
									{ step.photoUrl &&
										<div className="cps mt-2" onClick={(e) => this.handleOpenImage(key, step)}>
											<img className="image-fit-cover" src={step.photoUrl} width="100%" height="150px"  style={{ borderRadius: "5px" }}/>
										</div>
									}
								</div>
							))
							}
						</div>
						{achievementDetail.endDate && typeof achievementDetail.routine.confirmationRewards === 'number' && !achievementDetail.confirmation && auth_uid !== uid &&
							<div className='py-2'>
								<div className="topalign-items-center justify-items-center bg-lifesherpa" style={{ borderRadius: 5, padding:"20px" }}>
									<h3>Satisfactory Completion?</h3>
									<div className="justify-content-end border-bottom-0 d-flex">
										<Button onClick={(e) => this.handleNotConfirmAcheivement()} className="mr-3 satisfactory-no-button satifactory-button" >
											NO
										</Button>
										<Button onClick={(e) => this.handleConfirmAcheivement()} className=" satisfactory-yes-button satifactory-button">
											YES
										</Button>
									</div>
								</div>
							</div>
						}
					</div>
					: !this.props.loading &&
					<p className="text-center mt-2">No Detail available</p>
				}
				{this.state.displayImage &&
					<Dialog
						fullScreen={false}
						open={this.state.displayImage}
						onClose={()=>this.setState({displayImage:false})}
						fullWidth={true}
						maxWidth="xl"
						aria-labelledby="responsive-dialog-title"
						className={`${this.props.popupThemeClass} schedules-list-dialog-popup`}
						PaperProps={
							{ style: {maxWidth:"450px", borderRadius: 8,margin:'auto'} }
							}
							>
						<DialogTitle>
							<div className="p-3 border-bottom bg-lifesherpa d-flex justify-content-between align-items-center" >
								<h2 className="text-white mb-0">{`[${this.state.selectedIndex + 1}] ${this.state.selectedStep.title}`}</h2>
								<IconButton title="Close" className="" size="small" onClick={() => this.setState({displayImage: false})} >
									<ClearIcon />
								</IconButton>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="m-3" >
								<img className="image-fit-contain" src={this.state.selectedStep.photoUrl} width="100%" height="auto" />
							</div>
						</DialogContent>
					</Dialog>
				}
			</div>
		);
	}
}

export default AchievementStepsDetail;