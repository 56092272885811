/**
 * App Routes
 */
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// app default layout
import RctAppLayout from '../components/RctAppLayout';

// router service
import routerService from "../services/_routerService";
import {
	AsyncLifesherpaDashboardComponent,
	AsyncResourcesPage,
	AsyncUserModuleCharts,
	AsyncLifesherpaiFrame,
	AsyncAnalyticsiFrame,
	AsyncLifesherpaContacts,
	AsyncLifesherpaWorkflow
 } from '../components/AsyncComponent/AsyncComponent';
 
class DefaultLayout extends Component {
	render() {
		const { match } = this.props;
		return (
			<RctAppLayout>
				{routerService && routerService.map((route,key)=>
					<Route key={key} path={`${match.url}/${route.path}`} component={route.component} />
				)}
			   <Route path="/app/lsdashboard" component={AsyncLifesherpaDashboardComponent} />
               <Route path="/app/resources" component={AsyncResourcesPage} />
               <Route path="/app/usercharts" component={AsyncUserModuleCharts} />
               <Route path="/app/configurator" component={AsyncLifesherpaiFrame} />
               <Route path="/app/lms" component={AsyncLifesherpaiFrame} />
               <Route path="/app/lms_course" component={AsyncLifesherpaiFrame} />
               <Route path="/app/zoho_analytics" component={AsyncAnalyticsiFrame}/>
			   <Route path="/app/contacts" component={AsyncLifesherpaContacts}/>
			   <Route path="/app/workflows" component={AsyncLifesherpaWorkflow}/>
			</RctAppLayout>
		);
	}
}

export default withRouter(connect(null)(DefaultLayout));
