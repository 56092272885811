/**
 * User List Item
 */
import React from 'react';
import ListItem from '@mui/material/ListItem';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import Avatar from '@mui/material/Avatar';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import moment from 'moment'

// helpers
import { textTruncate } from 'Helpers/helpers';
const getActivityStatusDetails = (status) => {
    let statusString = status;
    if (status == "confirmed") {
        statusString = "Confirmed";
    } else if (status == "cancelled" || status == "incomplete") {
        statusString = "Not completed (Cancelled or Started and Expired)";
    } else if (status == "completedConfirmationNeeded") {
        statusString = "Completed, Need Confirmation";
    } else if (status == "completedNoConfirmationNeeded") {
        statusString = "Completed with all steps, but not confirmation was required";
    } else if (status == "expired") {
        statusString = "Achievement is expired (Created but not started and expired)";
    } else if (status == "notStarted") {
        statusString = "User was alerted and has not started";
    } else if (status == "confirmedSkippedSteps") {
        statusString = "Confirmed with Skipped Steps";
    } else if (status == "confirmedNegative") {
        statusString = "Negative, Confirmation"
    } else if (status == "inProgress" || status == "paused") {
        statusString = "Routine execution is in progress"
    }
    return statusString;
}


const ActivityHistoryListItem = ({ activity, selectedActivity, onClickListItem, alternativeColor, isClientUser, showUnconfirmedAcheivemts }) => (
    <div
        onClick={onClickListItem}
        key={activity.id}
        className={classnames('d-flex align-items-center list-card list-item  my-2 border-bottom cps  justify-content-between',
            { 'item-active': selectedActivity && selectedActivity.id === activity.id },
            { 'alternative-user-list-item': alternativeColor }
        )}
    >
        <div className="media align-items-center " /*onClick={onClickListItem}*/>
            <div className="media-left position-relative mr-10">
                <Avatar
                    width="40"
                    height="40"
                    title={getActivityStatusDetails(activity.status)}
                    className={`align-self-center  ${activity.status === 'confirmed' ?
                        "achievementStatusConfirmed"
                        : activity.status === 'cancelled' ?
                            "achievementStatusCancelled"
                            : activity.status === 'inProgress' ?
                                "achievementStatusInProgress"
                                : activity.status === 'confirmedSkippedSteps' ?
                                    "achievementStatusConfirmedSkippedSteps"
                                    : activity.status === 'completedConfirmationNeeded' ?
                                        "achievementStatusCompletedConfirmationNeeded"
                                        : activity.status === 'completedNoConfirmationNeeded' ?
                                            "achievementStatusCompletedNoConfirmationNeeded"
                                            : activity.status === 'incomplete' ?
                                                "achievementStatusIncomplete"
                                                : activity.status === 'expired' ?
                                                    "achievementStatusExpired"
                                                    : activity.status === 'confirmedNegative' ?
                                                        "achievementStatusConfirmedNegative"
                                                        : activity.status === 'notStarted' ?
                                                            "achievementStatusNotStarted"
                                                            : activity.status === 'paused' ?
                                                                "achievementStatusPaused"
                                                                : "achievementStatusNotFound"}`}
                >
                    {activity.status === 'confirmed' || activity.status === 'confirmedSkippedSteps' || activity.status === 'confirmedNegative' || activity.status === 'completedNoConfirmationNeeded' ?
                        <DoneRoundedIcon style={{ fontSize: "2rem" }} />
                        : activity.status === 'cancelled' || activity.status === 'incomplete' ?
                            <ClearRoundedIcon style={{ fontSize: "2rem" }} />
                            : activity.status === 'inProgress' || activity.status === 'paused' ?
                                <MoreHorizRoundedIcon style={{ fontSize: "2rem" }} />
                                : activity.status === 'expired' || activity.status === 'notStarted' ?
                                    <AccessAlarmRoundedIcon style={{ fontSize: "2rem" }} />
                                    : activity.status === 'completedSkippedStepsNoConfirmationNeeded' ?
                                        <div style={{ fontSize: "2rem" }}>{'S'}</div>
                                        : activity.status === 'completedConfirmationNeeded' ?
                                            <div style={{ fontSize: "2rem" }}>{'?'}</div>
                                            :
                                            <div style={{ fontSize: "2rem" }}>{'A'}</div>
                    }
                </Avatar>
            </div>
            <div className="media-body">
                {showUnconfirmedAcheivemts && <h4 className="heading-text mb-0 mt-0">{activity.title}</h4>}
                <div className="heading-text fs-14">{activity.dateName}</div>
                <div className="sub-text">{moment(activity.date, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}</div>
            </div>
        </div>
        <div className="sub-text ml-auto  activity-time-duration">
            {activity.duration !== '-' ? activity.duration : "-:-"}
        </div>
        <div className="text-right msg-count ml-1 d-flex align-items-center">
            <div>
                {activity.hasEvaluationForm && isClientUser &&
                    <div style={{height:"22px"}}><AssignmentRoundedIcon style={activity.hasEvaluationForm && activity.hasEvaluationResponse ? { color: 'orange' } : { color: '#A5A7B2' }} /></div>
                }
                {activity.hasStepNotesForm && isClientUser &&
                    <div style={{height:"22px"}}><CommentIcon style={activity.hasStepNotesForm && activity.hasStepNotesResponse ? { color: 'orange' } : { color: '#A5A7B2' }} /></div>
                }
            </div>
           
        </div>
    </div>
);

export default ActivityHistoryListItem;
