/**
 * App Routes
 */
import React, { Component, Suspense, lazy } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
// Components
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
// import Tour from 'Components/Tour';
// import ThemeOptions from 'Components/ThemeOptions/ThemeOptions';
import HorizontalMenu from '../SherpaHorizontalMenu/HorizontalMenu'
import NotificationsHandler from "../NotificationsHandler";
// preload Components 
import PreloadHeader from '../PreloadLayout/PreloadHeader';
import UnActiveScreenTimer from '../UnActiveScreenTimer';
import SherpaCamera from "../SherpaCamera";
import NotificationPopup from "../NotificationsHandler/NotificationPopup";
// app config
import AppConfig from '../../constants/AppConfig';

// actions
import { collapsedSidebarAction, startUserTour, hideVideoRoomPanel, getVideoToken, showVideoRoomPanel, setViewSize } from '../../actions';
//helpers
import { scrollFunction, lsTextSpeech, isAuth0SessionExpired } from '../../helpers/helpers'
//components
import ChatSections from "../LifesherpaChatPanel/components/ChatSections";
import VideoChatPanel from "../LifesherpaChatPanel/components/VideoChatPanel";

import VideoCallConfirmation from "../LifesherpaContacts/VideoCallConfirmation";
import MissedCallConfirmation from "../LifesherpaContacts/MissedCallConfirmation";

import ExecuteActivityConfirmation from "../NotificationsHandler/ExecuteActivityConfirmation";
import ActivityCompletionConfirmation from "../NotificationsHandler/ActivityCompletionConfirmation";
const LifesherpaChatPanel = lazy(() => import("../LifesherpaChatPanel"))

// rct page loader
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

class MainApp extends Component {

	constructor(props){
		super(props)
		this.state = {
			loadingHeader: true,
			loadingSidebar: true,
			applyStricky: false,
			windowWidth: window.innerWidth
		}
	}

	async componentDidMount() {
		//console.group("%c[AppLayout] %c Redirected on LS-Portal Home Screen","color:black","color:green");
		const { windowWidth } = this.state;
		window.addEventListener("resize", this.updateDimensions);
		if (AppConfig.enableUserTour && windowWidth > 600) {
			setTimeout(() => {
				this.props.startUserTour();
			}, 2000);
		} 
		setTimeout(() => {
			this.setState({ loadingHeader: false, loadingSidebar: false });
		}, 114);

		window.onscroll = function () { scrollFunction("rct-main-page") };
		const { widgetApp } = this.props;
		if(!widgetApp) {
			lsTextSpeech();
			try {
				await isAuth0SessionExpired();
			} catch(e) {
			}
			 
		}
		//this.props.hideVideoRoomPanel();
		//this.handleVidoeCallContainer();
		if (window.innerWidth > 600) {
			this.props.setViewSize(false)
		} else {
			this.props.setViewSize(true)
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	handleVidoeCallContainer = () => {
		const params = this.getParams();
		console.log(" params -->", params);
		if (params && params.room) {
			let openRoom = { name: params.room };
			this.props.getVideoToken();
			this.props.showVideoRoomPanel(openRoom);
		}
	}

	getParams = () => {
		let params = null
		if (this.props.location && this.props.location.search) {
			params = queryString.parse(this.props.location.search)
		} if (window.location && window.location.search) {
			params = queryString.parse(window.location.search)
		}
		return params
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { windowWidth } = this.state;
		if (nextProps.location !== this.props.location) {
			if (windowWidth <= 1199) {
				this.props.collapsedSidebarAction(true);
			}
		}
	}

	updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
		if (window.innerWidth > 600) {
			this.props.setViewSize(false)
		} else {
			this.props.setViewSize(true)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	renderPage() {
		const { pathname } = this.props.location;
		const { children } = this.props;
		if (pathname === '/app/chat' || pathname.startsWith('/app/mail') || pathname === '/app/todo' || pathname === '/app/user/my_activities') {
			return (
				<div className="rct-page-content p-0">
					<Suspense fallback={<RctSectionLoader/>}>
						{children}
					</Suspense>
				</div>
			);
		}
		let email = localStorage.getItem('user_email')
		let uid = localStorage.getItem('uid')
		return (
			<Scrollbars
				className="rct-scroll"
				autoHide
				autoHideDuration={100}
				style={this.getScrollBarStyle()}
			>
				<div className="rct-page-content">
					<Suspense fallback={<RctSectionLoader/>}>
						{children}
					</Suspense>
					{<Footer email={email} userId={uid} />}
				</div>
			</Scrollbars>
		);
	}

	handleUpdate = (e) => {
		let headerElement = document.getElementById("ls-header-appbar");
		let headerHeight = headerElement ? headerElement.clientHeight - 10 : 110;
		if (e.scrollTop >= headerHeight && !this.state.applyStricky) {
			this.setState({ ...this.state, applyStricky: true });
			// console.log("stricky applyed -->",e.scrollTop,headerHeight);
		}
		else if (e.scrollTop < headerHeight && this.state.applyStricky) {
			this.setState({ ...this.state, applyStricky: false });
			// console.log("stricky removed -->",e.scrollTop,headerHeight);
		}
	}

	renderPageAllScrolling() {
		const { widgetApp } = this.props;
		const { children, screenThemeClass, selectedUser, openChatPanel, selectedVideoChat, showVideoRoom } = this.props;
		let email = localStorage.getItem('user_email')
		let uid = localStorage.getItem('auth_uid');
		let { applyStricky } = this.state;
		return (
			<Scrollbars
				className={"rct-scroll lifesherpa-main-container"}
				autoHide
				autoHideDuration={100}
				style={{ height: 'calc(100vh - 0px)' }}
				onUpdate={this.handleUpdate}
				id="app-main-conatainer-infinitescroll"
			>
				<div className="app-container">
					<div className="rct-app-content ">
						<div className={screenThemeClass}>
							{!widgetApp &&
								<div className="app-header">
									{this.renderHeader()}
									<div className={applyStricky ? "list_header" : "list-header-relative"}>
										<HorizontalMenu />
									</div>
									{applyStricky && <div style={{ height: "55px" }}></div>}
								</div>
							}
							<div id="rct-main-page" className="rct-page">
								{widgetApp ?
									<div className="rct-page-content widget-rct-page-content">
										{children}
									</div>
									:
									<div className="rct-page-content">
										{openChatPanel && selectedUser ? 
											<Suspense fallback={<RctSectionLoader/>}><LifesherpaChatPanel /></Suspense>
											: 
											<Suspense fallback={<RctSectionLoader/>}>
												{children}
											</Suspense>
										}
										{selectedUser ? "" : <Footer email={email} userId={uid} />}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</Scrollbars>
		);
	}

	// render header
	renderHeader() {
		const { loadingHeader } = this.state;
		if (loadingHeader) {
			return <PreloadHeader />;
		}
		return <Header />

	}

	//Scrollbar height
	getScrollBarStyle() {
		return {
			height: 'calc(100vh - 160px)'
		}
	}

	render() {
		const { navCollapsed, rtlLayout, miniSidebar, screenThemeClass } = this.props.settings;
		const { widgetApp } = this.props;
		let { selectedChatUsers, selectedVideoChat, showVideoRoom, showVideoCallConfirmationPanel, videoCallMessage, showMissedCallConfirmationPanel, showCamera } = this.props;
		const { windowWidth } = this.state;
		if (widgetApp) {
			return (
				<div className="app">
					<div className="app-main-container">
						{this.renderPageAllScrolling()}
						<div className={screenThemeClass}>
							{showCamera && <SherpaCamera />}
						</div>	
					</div>
				</div>
			);
		} else {
			return (
				<div className="app">
					<UnActiveScreenTimer history={this.props.history} />
					<div className="app-main-container">
						{this.renderPageAllScrolling()}
						<div className={screenThemeClass}>
							<NotificationsHandler>
							{selectedChatUsers && selectedChatUsers.length > 0 ? <ChatSections windowWidth={windowWidth} /> : ""}
							</NotificationsHandler>
							<ExecuteActivityConfirmation />
							<ActivityCompletionConfirmation />
							{showCamera && <SherpaCamera />}
							<NotificationPopup />
							{showVideoRoom && selectedVideoChat ? <VideoChatPanel windowWidth={windowWidth} /> : ""}
							{showVideoCallConfirmationPanel && videoCallMessage && <VideoCallConfirmation />}
							{showMissedCallConfirmationPanel && videoCallMessage && <MissedCallConfirmation />}
						</div>
					</div>
				</div>
			);
		}

	}
}

// map state to props
const mapStateToProps = ({ settings, chatAppReducer, Contacts, activitiesList }) => {
	const { screenThemeClass, popupThemeClass } = settings;
	const { selectedVideoChat, showVideoRoom, showVideoCallConfirmationPanel, videoCallMessage, showMissedCallConfirmationPanel } = Contacts
	const { selectedUser, selectedChatUsers, openChatPanel } = chatAppReducer;
	const { selectedId, showCamera } = activitiesList;
	return { settings, screenThemeClass, popupThemeClass, selectedUser, selectedChatUsers, openChatPanel, selectedVideoChat, showVideoRoom, showVideoCallConfirmationPanel, videoCallMessage, showMissedCallConfirmationPanel, showCamera }
}

export default withRouter(connect(mapStateToProps, {
	collapsedSidebarAction,
	startUserTour,
	hideVideoRoomPanel,
	getVideoToken,
	showVideoRoomPanel,
	setViewSize
})(MainApp));

