//Visitors Widget

import React, { Component, Fragment } from 'react'

// chart
import Bar from "../../../components/Charts/bar"
import StackedChartComponent from '../../../components/Charts/stackedChart'; 
import ChartConfig from "../../../constants/chart-config"
import DatePickers from '../../../components/Pickers/DatePickers'
import moment from 'moment'
import IntlMessages from "../../../util/IntlMessages"
import FilterAlt from '@mui/icons-material/FilterAlt';
import IconButton from "@mui/material/IconButton";
import OrganizationFilter from '../../../components/Widgets/OrganizationFilter';

export default class WorkflowSummaryReport extends Component {

	state = {
		fromDate: moment().subtract(1, 'months').format('MMM DD YYYY'),
		toDate: moment().format('MMM DD YYYY'),
		chartParams: {},
		filterData: null,
		openOrgFilter: false,
		workflowSummaryPanelOrgFiltered: null
	}

	componentDidMount() {
		const {workflowSummaryPanelOrgFiltered} = this.state 
		const filterData = { fromDate: moment(this.state.fromDate).format('YYYY-MM-DD HH:mm:ss'), toDate: moment(this.state.toDate, 'MMM DD YYYY').add(1, 'days').format('YYYY-MM-DD HH:mm:ss') }
		this.setState({filterData}, () => {
			this.props.handleFilterChartData(filterData)
		})
	}

	handleFromDateChange(date) {
		console.log("from date --> ", date)
		const {workflowSummaryPanelOrgFiltered} = this.state 
		date = moment(date);
		const filterData = { fromDate: moment(date).format('YYYY-MM-DD HH:mm:ss'), toDate: moment(this.state.toDate, 'MMM DD YYYY').add(1, 'days').format('YYYY-MM-DD HH:mm:ss') }
		this.setState({ fromDate: date, filterData }, () => {
			this.props.handleFilterChartData(filterData, workflowSummaryPanelOrgFiltered)
		})
	}

	handleToDateChange(date) {
		console.log("to date --> ", date)
		const {workflowSummaryPanelOrgFiltered} = this.state 
		date = moment(date);
		const filterData = { fromDate: moment(this.state.fromDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss'), toDate: moment(date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss') }
		this.setState({ toDate: date, filterData }, () => {
			this.props.handleFilterChartData(filterData, workflowSummaryPanelOrgFiltered)
		})

	}

	getChartData(chartData) {
		const labels = chartData.labels
		const datasets = []
		chartData.series.map((item, key) => {
			const innerData = {
				label: item,
				fill: false,
				backgroundColor: ChartConfig.colorArray[key],
				borderColor: ChartConfig.colorArray[key],
				borderWidth: 1,
				hoverBackgroundColor: ChartConfig.colorArray[key],
				hoverBorderColor: ChartConfig.colorArray[key],
				data: chartData.data[key]
			}
			datasets.push(innerData)
		})
		return { labels, datasets }
	}

	handleOpenFilter(){
        this.setState({openOrgFilter: true})
    }

    handleCloseFilter(){
        this.setState({openOrgFilter: false})
    }

	clearOrgFilter() {
		const {filterData} = this.state
        this.setState({workflowSummaryPanelOrgFiltered: null, openOrgFilter: false}, () => {
            this.props.handleFilterChartData(filterData)
        })
    }

    applyOrgFilter(filterOrgOptions) {
		const {filterData} = this.state
        this.setState({workflowSummaryPanelOrgFiltered: filterOrgOptions, openOrgFilter: false}, () => {
            this.props.handleFilterChartData(filterData, filterOrgOptions)
        })
    }

	render() {
		const {chartData, workflowChartLoading, filterData, organizations, popupThemeClass} = this.props
		const {workflowSummaryPanelOrgFiltered} = this.state 
		const filterApplied = workflowSummaryPanelOrgFiltered && workflowSummaryPanelOrgFiltered.length > 0 ? true : false
		const {openOrgFilter} = this.state
		const workflowsSupportOrgIds = organizations ? organizations.filter(org => org.enableWorkflows): [];
		
		if (chartData) {
			return (
				<Fragment>
					<div className="top-content mb-3">
						<div className="p-3 list-card">
							<div className="d-flex align-items-center justify-content-between">
								{workflowsSupportOrgIds.length > 1 ? 
									<div className={`d-flex ${filterApplied ? "activity-filter-icon" : "activity-filter-icon-applied"} mr-2`}>
										<IconButton data-testid="orgId-filter-icon-button" title="Filter By OrgId" className="text-white rounded-circle mr-2" onClick={() => this.handleOpenFilter()}>
											<FilterAlt data-testid="orgId-filter-enabled" className="filter-icon" fontSize="large"/>
										</IconButton>
									</div> : ""
								}
								
								<div className="pr-2"><DatePickers testid="data-picker-from-data" value={this.state.fromDate} label={<IntlMessages id="chart.workflowStartedFromDate" />}  handleOnDateChange={this.handleFromDateChange.bind(this)} /></div>
								<DatePickers testid="data-picker-to-data"  value={this.state.toDate} label={<IntlMessages id="chart.toWorkflowCompleteDate" />}  handleOnDateChange={this.handleToDateChange.bind(this)} />
							</div>
						</div>
					</div>
					<div className="chart-wrap">
						{chartData.class === 'bar' && !chartData.options ?
							<Bar  loading={workflowChartLoading} data={this.getChartData(chartData)} getChartData={this.getChartDataById} chartId={chartData.chartId} />
							: chartData.class === 'bar' && chartData.options && chartData.options.scales && chartData.options.scales.xAxes && chartData.options.scales.yAxes && chartData.options.scales.xAxes[0].stacked && chartData.options.scales.yAxes[0].stacked ?
							<StackedChartComponent  loading={workflowChartLoading} data={this.getChartData(chartData)} getChartData={this.getChartDataById} chartId={chartData.chartId} />
							: <></>
						}
					</div>
					{openOrgFilter ?
						<OrganizationFilter
							title="Organization Filter"
							showFilter={openOrgFilter}
							handleCloseFilter={this.handleCloseFilter.bind(this)}
							organizations={organizations}
							clearOrgFilter={this.clearOrgFilter.bind(this)}
							applyOrgFilter={this.applyOrgFilter.bind(this)}
							popupThemeClass={popupThemeClass}
							filterOrgOptions={workflowSummaryPanelOrgFiltered}
						/>
						: <></>
					}
				</Fragment>
			)
		} else return (<div data-testid="chart-data-not-found" className='text-center p-3'>{workflowChartLoading ? "Fetching workflow chart data..." : "Chart data not found"}</div>)
	}
}
