import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
// reactstrap components
import { Label,FormFeedback, Button } from 'reactstrap';
// Material UI Components
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import {checkErrorInTasks} from "../../../helpers/helpers"

class WorkflowHistory extends Component {

    state = {
        anchorEl: null
    };

    handleClosePanel(){
        this.props.handleClosePanel()
    }

    handleAssignUser(pendingWorkflowExecution) {
        this.props.openAssignUserPanel(pendingWorkflowExecution)
    }

    checkErrorInTasks(tasks) {
        const {errorMessage, pendingRoles} = checkErrorInTasks(tasks)
        console.log("errorMessage: ", errorMessage, "pendingRoles: ", pendingRoles)
        return errorMessage;
    }
    
    render() {
        const {workflowHistory, workflowHistoryLoading} = this.props
        return (
            <React.Fragment>
                <DialogTitle>
                    <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Role Assignment Pending List</Label>
                        <IconButton className="text-white mr-2" onClick={() => this.handleClosePanel()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="m-0 p-0 start-workflow-container ">
                    <LifeSherpaLoading loading={workflowHistoryLoading} />
                    <ul className='p-3 list-unstyled'>
                       { workflowHistory && workflowHistory.length > 0 ?  workflowHistory.map((pendingWorkflowExecution, key) => (
                            <li key={key} className='p-3 list-card d-flex align-items-center justify-content-between'>
                                <div className=''>
                                    <Label className='mb-0'>{pendingWorkflowExecution.executionName}</Label>
                                    <FormFeedback className='d-block m-0'>{pendingWorkflowExecution.tasks && this.checkErrorInTasks(pendingWorkflowExecution.tasks)}</FormFeedback>
                                </div>
                                <Button onClick={() => this.handleAssignUser(pendingWorkflowExecution)}>Assign User</Button>
                            </li>
                       ))
                       : workflowHistoryLoading ?
                        <></>
                       :
                        <li className='p-3 list-card d-flex align-items-center justify-content-between'>No workflow found where role assignment is pending</li>
                       }
                    </ul>
                </DialogContent>
            </React.Fragment>
        );
    }
}

export default WorkflowHistory;