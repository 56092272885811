import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {  getVideoToken, getSelectedVideoChat} from "Actions"
import { connect } from 'react-redux';
import queryString from 'query-string';
import VideoChat from './VideoChat';
import LoadingVideoUI from "./LoadingVideoUI";

class LifesherpaVideoCall extends Component {
    state = { 
        windowWidth:window.innerWidth,
        groupId: null,
        roomLeft: false
     }
	 componentDidMount() {
        let idToken = localStorage.getItem('idToken')
		let uid = localStorage.getItem('auth_uid')
        if (!idToken) {
			this.props.history.push('/launchscreen')
		} 
        window.addEventListener("resize", this.updateDimensions);
        this.handleVidoeCallContainer();
	 }
     handleVidoeCallContainer = () => {
		const params = this.getParams();
		console.log( " params -->", params);
		if(params && params.room) {
			let openRoom = {name:params.room};
			this.props.getVideoToken();
			this.props.getSelectedVideoChat(openRoom);
            if(params.groupId) {
                this.setState({groupId:params.groupId})
            }
		} else {
            // window.close();
            // if(this.props.location && this.props.location.pathname == "/lifesherpa/videocall") {
            //     this.props.history.push("/app/lsdashboard");
            // }
        }
	}
    handleMessageForRoom = (roomLeft) => {
        this.setState({roomLeft: roomLeft})
    }

    updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}
    getParams = () => {
		let params = null
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        return params
	 }
    render() { 
		const {  loading, selectedVideoChat, videoToken, videoTokenLoading } = this.props;
        let {groupId} = this.state;
		let idToken=localStorage.getItem("idToken")
         if(!idToken) this.props.history.push("/launchscreen");
		//  console.log(" Constacts loading ---->",loading);
        //  console.log(" videoTokenLoading loading ---->",videoTokenLoading);
        //  console.log(" videoToken ---->",videoToken);
        //  console.log(" selectedVideoChat ---->",selectedVideoChat);
        return (
			 <div className="vidio-commuinication-panel">
                {!videoTokenLoading && videoToken && selectedVideoChat ?
                     <VideoChat windowWidth={this.state.windowWidth} groupId={groupId} handleMessageForRoom={this.handleMessageForRoom}/>
                    :<LoadingVideoUI text={this.state.roomLeft ? "Disconnecting from video room..." : "Connecting to video room ..."}/>
                }	
             </div> 
             );
    }
}

const mapStateToProps = ({Contacts,authUser, chatAppReducer}) => {
	const {userRole}=authUser
	const {videoToken, loading, videoTokenLoading, selectedVideoChat}=Contacts
	const { selectedChatUsers, selectedUser } = chatAppReducer;
	return {videoToken,userRole, loading, selectedUser, videoTokenLoading, selectedVideoChat};
};
export default withRouter(connect(mapStateToProps, {getVideoToken, getSelectedVideoChat})(LifesherpaVideoCall));
