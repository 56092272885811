/**
 * Ecommerce Dashboard
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux';
 import Webcam from "react-webcam";
 //Actions
 import {handleShowPhotoCamera} from 'Actions'
 import Dialog from '@mui/material/Dialog';
 import DialogContent from '@mui/material/DialogContent';
 import Button from '@mui/material/Button';
 import DialogActions from '@mui/material/DialogActions';
 import SweetAlert from 'react-bootstrap-sweetalert'
 import {firebase} from '../../firebase';
 import Moment from 'moment'
 import LifeSherpaLoading from '../LifeSherpaLoading';

 class CameraComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUri:"",
            showWarning: false,
            cameraLoading:true
        }
        this.webcamRef = React.createRef();
     }
     state = {
         stream:null,
         showCamera: true,
         dataUri:"",
         showWarning: false,
         cameraLoading:true
     }

    handleTakePhoto = ()  => {
        // Do stuff with the photo...
        if (!this.state.cameraLoading) {
            const imageSrc = this.webcamRef.current.getScreenshot();
            this.props.handleTakePhoto(imageSrc);
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({cameraLoading:false});
        },4000)
    }
      
    handleCameraStart = (stream) => {
        console.log(" Camera Started --> ", stream);
        this.setState({stream: stream, cameraLoading:false});
    }

    handleClose = () => {
        console.log(" handleClose --> ",this.state.showWarning);
        this.setState({showWarning:""})
    }

    render() {
        let {showWarning, cameraLoading} = this.state;
        const {takePictureMessage} = this.props;
        console.log(" takePictureMessage --> ",takePictureMessage);
        const videoConstraints = {
            facingMode: "user"
        };
         return (
             <div className={this.props.screenThemeClass}>
                 <Dialog
					open={true}
					className={`camera-popup-dialog p-0 ${this.props.popupThemeClass}`}
                    onClose={() => this.handleClose()}
					>
                    <LifeSherpaLoading loading={cameraLoading} />
                    <DialogContent className={`m-0 p-0 camera-popup-dialog-content fix-min-style-content`}>
                        <Webcam
                            audio={false}
                            height={720}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            width={1280}
                            videoConstraints={videoConstraints}
                            onUserMedia={(stream) =>this.handleCameraStart(stream)}
                            onUserMediaError={(e) => this.props.handleCameraSupportError(e)}
                        />
                         {showWarning &&
                            <div className="col-12 p-4">
                                <SweetAlert
                                    warning
                                    btnSize="sm"
                                    customClass="warningText"
                                    confirmBtnText="OK"
                                    confirmBtnBsStyle="warning"
                                    title={"Please take a photo to continue"}
                                    onConfirm={() => this.handleClose()}
                                    >
                                <div className="sweet-alert-text"></div>
                                </SweetAlert>
                            </div>
					    }
                    </DialogContent>
                   <DialogActions className="d-flex justify-content-center camera-popup-dialog-actions">
                       <div>
                           {takePictureMessage && <div className="take-picture-message-camera">{takePictureMessage}</div>}
                            <div className="d-flex align-items-center justify-content-center mt-1">
                                <div className="outer-circle" onClick={() => this.handleTakePhoto()}>
                                    <div className="inner-circle"></div>
                                </div>
                            </div>
                       </div> 
                    </DialogActions>
                    
                   
                 </Dialog>
             </div>
         )
     }
 }
 
 const mapStateToProps = ({ activitiesList, settings}) => {
     const { selectedId, showCamera } = activitiesList;
     const { screenThemeClass, popupThemeClass } = settings; 
     return { screenThemeClass, popupThemeClass, selectedId}
 }
 
 export default connect(mapStateToProps, {handleShowPhotoCamera})(CameraComponent)
 