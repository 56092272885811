import React, { Component } from 'react';

import { connect } from 'react-redux';
// Material UI Components
import Dialog from '@mui/material/Dialog';

import {
    getAssignableWorkflowUsers,
    startWorkflow,
    showExecutionHistoryPendingRolePopup,
    assignUserToPendingWorkflow,
    clearWorkflowErrorResponse
} from '../../../actions';
import UsersToAssign from './UsersToAssign';
import AssignedUsers from './AssignedUsers';
import {checkErrorInTasks} from "../../../helpers/helpers"
import SweetAlert from 'react-bootstrap-sweetalert';

class ExecutionPendingRoleAssign extends Component {

    state = {
        addUsersPage: false,
        search: "",
        contacts: [],
        selectedIndex: 0,
        roleWiseAssignedUserList: null,
        selectedWorkflow: [],
        workflowname: null,
        userByRoles: {},
        usersToAssign: [],
        pageMounted: false,
        pendingWorkflowExecution: null,
        stateUpdated: false,
        isSubWorkflowOpened: false

    };

    componentDidMount() {
        const { selectedWorkflow, subWorkflowDetailData } = this.props;
        if (subWorkflowDetailData) {
            const orgId = subWorkflowDetailData.organization;
            const userByRoles = {};
            
            if (subWorkflowDetailData.roles) {
                const {pendingRoles} = checkErrorInTasks(subWorkflowDetailData.tasks)
                for (const role in subWorkflowDetailData.roles) {
                    userByRoles[role] = {...subWorkflowDetailData.roles[role]};
                    userByRoles[role].isRequired = pendingRoles && pendingRoles[role] ? true : false;
                    if(userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static") {
                        userByRoles[role]["isRequired"] = true;
                    }
                }
            }
            const executionId = subWorkflowDetailData.id;
            const workflowname = subWorkflowDetailData.executionName || subWorkflowDetailData.workflowname || subWorkflowDetailData.name || ""
            this.setState({pendingWorkflowExecution: subWorkflowDetailData , userByRoles, workflowname, pageMounted: true, isSubWorkflowOpened: true}, () => {
                this.props.getAssignableWorkflowUsers({ workflowId: subWorkflowDetailData.workflowId, orgId, executionId });
            })
        } else if (selectedWorkflow) {
            const orgId = selectedWorkflow.organization;
            const userByRoles = {};
            if (selectedWorkflow.roles) {
                const {pendingRoles} = checkErrorInTasks(selectedWorkflow.tasks)
                for (const role in selectedWorkflow.roles) {
                    userByRoles[role] = {...selectedWorkflow.roles[role]};
                    userByRoles[role].isRequired = pendingRoles && pendingRoles[role] ? true : false;
                    if(userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static") {
                        userByRoles[role]["isRequired"] = true;
                    }
                }
            }
            const executionId = selectedWorkflow.id;
            const workflowname = selectedWorkflow.executionName || selectedWorkflow.workflowname || selectedWorkflow.name || ""
            this.setState({pendingWorkflowExecution: selectedWorkflow , userByRoles, workflowname, pageMounted: true}, () => {
                this.props.getAssignableWorkflowUsers({ workflowId: selectedWorkflow.workflowId, orgId, executionId });
            })
        }

    }

    handleClosePanel() {
        this.props.showExecutionHistoryPendingRolePopup(false)
    }


    handleRemoveMember(selectedRole) {
        let { userByRoles } = this.state;
        userByRoles[selectedRole]['data'] = null;
        this.setState({ userByRoles });
    }

    handleCloseAddUsersPage = (user) => {
        let { selectedRole, userByRoles } = this.state;
        userByRoles[selectedRole]['data'] = user;
        this.setState({ addUsersPage: false, userByRoles})
    }

    handleSubmit = (ignoreMaxWorkflowsLimit = false) => {
        const { userByRoles, pendingWorkflowExecution, isSubWorkflowOpened } = this.state
        const { selectedWorkflow, subWorkflowDetailData, userId } = this.props;
        const orgId = subWorkflowDetailData ? subWorkflowDetailData.organization : selectedWorkflow.organization
        const workflowId = subWorkflowDetailData ? subWorkflowDetailData.workflowId : selectedWorkflow.workflowId;

        let assignmentsByRoles = {}
        Object.keys(userByRoles).map(role => {
            if (userByRoles[role]["data"]) {
                const userId = userByRoles[role]["data"].userId;
                const groupId = userByRoles[role]["data"].groupId;
                if(userId) {
                    assignmentsByRoles[role] = {userId}
                } else if(groupId) {
                    assignmentsByRoles[role] = {groupId}
                }
            }
        })
       
        for (const role in pendingWorkflowExecution.roles) {
            if (pendingWorkflowExecution.roles[role].userId || pendingWorkflowExecution.roles[role].groupId){
                delete assignmentsByRoles[role]
            }
        }
        const data = { assignmentsByRoles};
        const executionId = pendingWorkflowExecution.id;
        // console.log("assignUserToPendingWorkflow: ", {orgId, workflowId, executionId, userId, data, ignoreMaxWorkflowsLimit, pendingRoleOnHistory: true, isSubWorkflowOpened})
        this.props.assignUserToPendingWorkflow({orgId, workflowId, executionId, userId, data, ignoreMaxWorkflowsLimit, pendingRoleOnHistory: true, isSubWorkflowOpened})
    }


    handleOpenAssignUsers(selectedRole) {
        this.setState({ addUsersPage: true, selectedRole, pageMounted: true })
    }


    handleWorkflowNameChange(event) {
        this.setState({ workflowname: event.target.value })
    }

    handleCloseWorkflowHistory() {
        const {openWorkflowPendingList} = this.state
        this.setState({openWorkflowPendingList: !openWorkflowPendingList})
    }


    cancelWarningAlert() {
        this.props.clearWorkflowErrorResponse();
    }

    render() {
        const { addUsersPage, pageMounted, selectedRole, pendingWorkflowExecution, stateUpdated, userByRoles, workflowname } = this.state;
        const { startWorkflowLoading, isMobileView, fetchAssigneeLoading, assignableUsersByRoles, workflowErrorResponse, workflowHistoryLoading } = this.props;
        // const {workflowname, userByRoles} = stateUpdated ? this.state : this.filterAssignByRolesForPendingEx(pendingWorkflowExecution);
        return (
            <div>
                <Dialog
                    open={Boolean(pendingWorkflowExecution)}
                    fullScreen={isMobileView}
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} notices-list-panel start-workflow-popup`}
                >
                    { addUsersPage ?
                        <UsersToAssign
                            addUsersPage={addUsersPage}
                            itemsList={assignableUsersByRoles && assignableUsersByRoles[selectedRole] && (assignableUsersByRoles[selectedRole]['users'] || assignableUsersByRoles[selectedRole]['groups'])}
                            selectedItem={userByRoles[selectedRole]['data']}
                            handleClose={this.handleCloseAddUsersPage}
                            assignmentType={userByRoles[selectedRole]['assignmentType']}
                            includeUsers={userByRoles[selectedRole]['includeUsers']}
                        />
                        :
                        <AssignedUsers
                            userByRoles={userByRoles}
                            workflowname={workflowname}
                            loading={startWorkflowLoading  || fetchAssigneeLoading || workflowHistoryLoading}
                            handleOpenAssignUsers={this.handleOpenAssignUsers.bind(this)}
                            handleRemoveMember={this.handleRemoveMember.bind(this)}
                            handleWorkflowNameChange={this.handleWorkflowNameChange.bind(this)}
                            handleClosePanel={this.handleClosePanel.bind(this)}
                            handleSubmit={this.handleSubmit.bind(this)}
                            pageMounted={pageMounted}
                            pendingWorkflowExecution={pendingWorkflowExecution}
                            openWorkflowPendingList={true}
                            assignableUsersByRoles={assignableUsersByRoles}
                        /> 
                    }

                </Dialog>
                {workflowErrorResponse ? 
                    workflowErrorResponse.errorCode === "E1422" ?
                        <SweetAlert
                            warning
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Assigning Role(s) Warning!"
                            onConfirm={() => this.handleSubmit(true)}
                            onCancel={() => this.cancelWarningAlert()}
                            >
                                <div style={{ fontFamily: "'Lato'", fontWeight: 400, fontSize: 16 }}>
                                        <div>Failed to start workflow due to exceeding maximum workflows limit for the users assigned to roles: </div>
                                        <div style={{ margin: "10px 0px" }}>{Object.keys(workflowErrorResponse.userWorkflowsByRoles).join(", ")}</div>
                                        <div> Do you want to start the same workflow again?</div>
                                </div>
                        </SweetAlert>
                        : 
                            <SweetAlert
                                warning
                                btnSize="sm"
                                customClass="warningText"
                                confirmBtnText="OK"
                                confirmBtnBsStyle="warning"
                                cancelBtnBsStyle="default"
                                title="Assigning Role(s) Denied"
                                onConfirm={() => this.cancelWarningAlert()}
                                >
                                    {
                                        workflowErrorResponse.errorCode === "E1421" ?
                                        <div style={{ fontFamily: "'Lato'", fontWeight: 400, fontSize: 16 }}>
                                            <div>Failed to start workflow due to exceeding maximum workflows limit for the users assigned to roles: </div>
                                            <div style={{ margin: "10px 0px" }}>{Object.keys(workflowErrorResponse.userWorkflowsByRoles).join(", ")}</div>
                                        </div>
                                        : 
                                        <div style={{ fontFamily: "'Lato'", fontWeight: 400, fontSize: 16 }}>
                                            {workflowErrorResponse.message}
                                        </div>

                                    } 
                            </SweetAlert>
                    : <></>
                }
            </div>
            
        );
    }
}

const mapStateToProps = ({ settings, Contacts, workflowData }) => {
    const { popupThemeClass, isMobileView } = settings;
    const { contactList } = Contacts;
    const { assignableUsersByRoles, workflowLoading: loading, startWorkflowLoading, fetchAssigneeLoading, workflowErrorResponse, workflowHistoryLoading} = workflowData
    return { popupThemeClass, contactList, assignableUsersByRoles , startWorkflowLoading, isMobileView, fetchAssigneeLoading, workflowErrorResponse, workflowHistoryLoading};
}

export default connect(mapStateToProps, {
    getAssignableWorkflowUsers,
    startWorkflow,
    showExecutionHistoryPendingRolePopup,
    assignUserToPendingWorkflow,
    clearWorkflowErrorResponse,
})(ExecutionPendingRoleAssign);