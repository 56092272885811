/**
 * User List Item
 */
import React from 'react';
import {
	Label,
	Input,
} from 'reactstrap';
import Divider from '@mui/material/Divider';

const TextInpuItem = ({ obj, updateform, section, showError }) => (
    <div className="mb-2" data-testid={`text-area-item-component-longtext-${section}`} key={section}>
       {obj.heading ?  <Label className="form-label">{`${obj.heading}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></Label> : <Divider className="my-2"/>}
		<Input className="form-input-field" data-testid={`activity-form-text-area-${section}`} style={{backgroundColor: 'white'}} onChange={(e) => updateform(e, obj, section)} type="textarea" value={obj.text} name="text" id="exampleText" placeholder={obj.placeholder} />
        {/*<Divider className="mb-2"/>*/}
		{showError && obj.isMandatory && (!obj.text) && <div className="input-options-error">{`${obj.heading} section is required`}</div>}
    </div>
);

export default TextInpuItem;
