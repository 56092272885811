import React, { Component } from 'react';
import moment from 'moment';

class AchievementTimer extends Component {
    state = {
        timerTime: 0,
        elapsedTime: 0,
        intervalID: null,
        achievementDelayed: false,
        expectedDurationSeconds: 0
    }

    componentDidMount() {
        const { achievement } = this.props;
        // console.log("activity selected --> ", achievement);
        let { timerTime, elapsedTime, expectedDurationSeconds, achievementDelayed } = this.state;
        if (achievement && achievement.startDate) {
            let totalTimeTaken = 0;
            if (achievement.startDate) {
                let dateObject = moment(achievement.startDate, "YYYY-MM-DD HH:mm:ss Z").toDate()
                totalTimeTaken = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            }
            let routine = achievement.routine || null;
            if (routine && routine.expectedDuration) {
                expectedDurationSeconds = this.getExpectedDurationSeconds(routine.expectedDuration);
            }
            // console.log("expectedDurationSeconds --> ", expectedDurationSeconds, "totalTimeTaken --> ", totalTimeTaken);
            if (expectedDurationSeconds > totalTimeTaken) {
                elapsedTime = expectedDurationSeconds;
                timerTime = expectedDurationSeconds - totalTimeTaken;
                elapsedTime = elapsedTime - timerTime;
                achievementDelayed = false;
            } else {
                achievementDelayed = true;
                elapsedTime = totalTimeTaken;
                timerTime = totalTimeTaken - expectedDurationSeconds;
            }
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID);
            }
            let intervalID = setInterval(
                this.hanldeUpdateActivityTimer.bind(this)
                , 1000);
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: expectedDurationSeconds, achievementDelayed: achievementDelayed, intervalID: intervalID })
        } else {
            achievementDelayed = true;
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID);
            }
            let intervalID = setInterval(
                this.hanldeUpdateActivityTimer.bind(this)
                , 1000);
            this.setState({ achievementDelayed: achievementDelayed, intervalID: intervalID })
        }
    }

    componentWillUnmount() {
        if (this.state.intervalID) {
            clearInterval(this.state.intervalID);
        }
    }

    hanldeUpdateActivityTimer = () => {
        const { achievement } = this.props;
        let routine = achievement.routine || null;
        let { expectedDurationSeconds, timerTime, elapsedTime, achievementDelayed } = this.state;
        let updatedDurationSeconds = 0;
        if (routine && routine.expectedDuration) {
            updatedDurationSeconds = this.getExpectedDurationSeconds(routine.expectedDuration);
        }
        if (/*expectedDurationSeconds !== updatedDurationSeconds &&*/ achievement.startDate) {
            let totalTimeTaken = 0;
            if (achievement.startDate) {
                let dateObject = moment(achievement.startDate, "YYYY-MM-DD HH:mm:ss Z").toDate();
                totalTimeTaken = Math.floor((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            }
            // console.log("expectedDurationSeconds --> ", updatedDurationSeconds);
            if (updatedDurationSeconds > totalTimeTaken) {
                elapsedTime = updatedDurationSeconds;
                timerTime = updatedDurationSeconds - totalTimeTaken;
                elapsedTime = elapsedTime - timerTime;
                achievementDelayed = false;
            } else {
                achievementDelayed = true;
                elapsedTime = totalTimeTaken;
                timerTime = totalTimeTaken - updatedDurationSeconds;
            }
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: updatedDurationSeconds, achievementDelayed: achievementDelayed })
        }  else {
            if (elapsedTime !== 0 && timerTime === 0) {
                achievementDelayed = true;
            }
            if (achievementDelayed) {
                elapsedTime = elapsedTime + 1;
                timerTime = timerTime + 1;
            } else {
                timerTime = timerTime - 1;
                elapsedTime = elapsedTime + 1;
            }
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: updatedDurationSeconds, achievementDelayed: achievementDelayed })
        }
    }

    componentDidUpdate() {

    }


    getFormatedDuration = (duration) => {
        var timeleft = (+duration) * 1000;
        let seconds = (Math.floor(timeleft / 1000) % 60)
        let minutes = (Math.floor(timeleft / 60000) % 60)
        let hours = Math.floor(timeleft / 3600000);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        hours = hours < 10 ? "0" + hours : hours;
        return `${hours}:${minutes}:${seconds}`;
    }

    getExpectedDurationSeconds = (formatedString) => {
        let total = 0;
        if (formatedString) {
            const dateArray = formatedString.split(":");
            let hr = dateArray[0];
            let min = dateArray[1];
            let sec = dateArray[2];
            if (!sec) {
                if (min) {
                    sec = min;
                    min = hr;
                    hr = 0
                } else {
                    sec = hr;
                    min = 0;
                    hr = 0;
                }
            }
            let totalhrs = hr ? Number(hr) * 3600 : 0;
            let totalmin = min ? Number(min) * 60 : 0;
            total = totalhrs + totalmin + Number(sec);
        }
        return total;
    }


    render() {
        let { achievementDelayed, timerTime, elapsedTime } = this.state

        //console.log("pokeForm",this.props.pokeForm);
        return (
            <React.Fragment>
                <div className="row p-2 border-bottom border-top bg-lifesherpa">
                    <h2 className={`${achievementDelayed ? "" : " text-white"} my-auto mx-auto`} style={achievementDelayed ? { color: "red" } : {}}>{`+${this.getFormatedDuration(timerTime)}`}</h2>
                </div>
                <div className="row p-2 border-bottom bg-lifesherpa">
                    <p className="text-white my-auto ml-1 mx-2">{'Elapsed Time ' + `${this.getFormatedDuration(elapsedTime)}`}</p>
                </div>
            </React.Fragment>
        );
    }
}



export default AchievementTimer;
