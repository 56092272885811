import React, { Component } from 'react';
//Speech Component
import Speech from 'react-speech';
//Redux subscriber
import { connect } from 'react-redux';
//WithRouter
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert'
//reacstrap components
import { FormGroup, Input, Label } from 'reactstrap';
//SCSS file
import '../../../assets/scss/custom/startActivityDetails.scss';
import SetCurrentStatus from '../../SetCurrentStatus';
import { Badge } from 'reactstrap';
import { withAuth0 } from "@auth0/auth0-react";
//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
//Material UI Icons
import BackspaceIcon from '@mui/icons-material/Backspace';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import CloseIcon from '@mui/icons-material/Close';
// Custome Components
import ActivityStepTimer from "./ActivityStepTimer";
import { openPopup } from '../../../helpers/helpers';
//Actions
import {
	getWPQ, 
	logoutUserFromFirebase, 
	removePinMsg, 
	checkPIN, 
	getCourse,
	collapsedSidebarAction, 
	updateStatus, 
	setStatusColor,
	getUserStatusById, 
	getPokeForm, 
	getClientStatusDataById,
} from '../../../actions';
//Speech Component Style
const textstyle = {
	play: {
		backgroundColor: 'white',
		hover: {
			backgroundColor: 'black',
			color: 'white'
		},
		button: {
			padding: '4',
			fontFamily: 'Helvetica',
			fontSize: '1.0em',
			cursor: 'pointer',
			pointerEvents: 'none',
			outline: 'none',
			backgroundColor: 'inherit',
			border: 'none',
		},
	}
}
//globaly store gapi

class StartActivityDetails extends Component {
	state = {
		speak: false,
		StatusPopup: false,
		statusList: [],
		maxStepTimeout: null,
		showCheckIn: false,
		pin: '',
		pinErr: null,
		totalTry: 4,
		showTimer: false,
		hour: '',
		min: '',
		sec: '',
		showEvent: false,
		warningPopup:""
	}

	componentDidMount() {
		const {step, countDownInterval, responseSubmitted} = this.props;
		if((step.cardType === "countDown" || step.cardType === "countDownEnforced" || step.cardType === "countDownPermissive") && step.countDownInterval > 0 && countDownInterval == 0) {
			const maxStepTimeout = step.countDownInterval ? step.countDownInterval : 0;
			this.props.updateTimer(maxStepTimeout);
			this.setState({ maxStepTimeout })
		}
		const stepActions = ["changeStatus", "Course","Form","wpq", "countDownEnforced"];
		if (step && step.cardType && stepActions.indexOf(step.cardType) !== -1) {
			console.log(" handling diable or enable for next button --> ");
			if (step.cardType === 'Course') {
			    this.props.getCourse(step.courseId)
			} else if(step.cardType === 'wpq') {
				this.props.getWPQ();
			} 
			if(step.cardType == "Form") {
				if(!responseSubmitted) {
					this.props.handleNextDisable();
				}
			} else {
				this.props.handleNextDisable();
			}
		} else {
			this.props.handleNextEnable();
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	updateStatusPopup = () => {
		let idToken = localStorage.getItem('idToken')
		let uid = localStorage.getItem('uid')
		const auth_uid = localStorage.getItem('auth_uid');
		if (auth_uid == uid) {
			this.props.getUserStatusById(auth_uid, idToken)
		}
		else {
			this.props.getClientStatusDataById(uid, idToken)
		}
		/*let {clientStatus , status } = this.props;
		let statusList = [];
		let statusObj = auth_uid == uid ? status : clientStatus;
		//console.log('this.props.Status',this.props.Status)
		statusObj && statusObj.sections ?
			Object.keys(statusObj.sections).map((obj, key) => {
				statusList.push(statusObj.sections[obj]) 
			}) : ''
		//console.log("statusListss",statusList)
		this.setState({ StatusPopup: true, statusList })*/
		this.setState({ StatusPopup: true })
	}

	handleStepFrom = (formId) => {
		const {step} = this.props;
		console.log(" step --> ", step);
		//this.props.disableNext()
		//this.props.getPokeForm(formId)
		this.props.showForm(step.stepId, formId);
		//this.setState({showForm:true})
	}

	handleStart = (cardType) => {
		//this.setState({maxStepTimeout:this.props.step.countDownInterval})
		this.props.handlecountDownInterval(cardType)

	}

	handlePIN = (n) => {
		let { pin, totalTry } = this.state
		pin = pin + n
		if (pin.length === 4) {
			this.props.checkPIN(pin)
			totalTry = totalTry - 1
			this.setState({ totalTry, pin })
		}
		else this.setState({ pin })
	}

	handleBack = () => {
		let { pin } = this.state
		pin = pin.slice(0, -1)
		//console.log('pin',pin)
		this.setState({ pin })
	}

	handleClose = () => {
		this.props.handleNextEnable();
		this.props.removePinMsg();
		this.setState({ pin: '', showCheckIn: false, totalTry: 4 });
	}

	logoutUser() {
		const {logout} = this.props.auth0
		this.props.logoutUserFromFirebase(this.props.history, logout);
	}

	handleHour = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 25) {
			//console.log("handleHour",i)
			this.setState({ hour: +e.currentTarget.value })
		}
	}

	handleMin = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			//console.log("handleHour",i)
			this.setState({ min: +e.currentTarget.value })
		}
	}

	handleSec = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			//console.log("handleHour",i)
			this.setState({ sec: +e.currentTarget.value })
		}
	}

	updateTimer = () => {
		let { hour, min, sec } = this.state
		let totalhrs = hour ? hour * 3600 : 0;
		let totalmin = min ? min * 60 : 0;
		let total = totalhrs + totalmin + sec
		//console.log('total',total)
		this.setState({ showTimer: false });
		this.props.updateTimer(total)
	}

	handelShowWarningPopup = (warning) => {
		let {step, executeActivity, wpqLink} = this.props;
		if(executeActivity) {
			if(step.cardType === 'wpq') {
				if(wpqLink) {
					// window.open(wpqLink, '_blank');
					var windowReference = window.open();
            		windowReference.location = wpqLink;
					openPopup(windowReference)
				}
				console.log(" wpqLink -->", wpqLink);
				this.props.handleNextEnable();	
			} else if(step.cardType === 'event') {
				this.setState({...this.state,warningPopup:"Not Supported"});
				// this.props.handleEvent()
				// this.props.handleNextEnable();	
			} else if(step.cardType === "checkIn") {
				this.setState({ showCheckIn: true });
				this.props.handleNextEnable();
			} 
		} else {
			this.setState({...this.state,warningPopup:warning});
		}	
	}

	handelStatusEditClose = () => {
		this.setState({ StatusPopup: false, statusList: [] });
		this.props.handleNextEnable();		
	}
	handleCloseWarningPopup =() => {
		this.setState({...this.state, warningPopup:""});
		this.props.handleNextEnable();	
	}
	handelPinChange =(e) => {

	}
	updateStatus = (statusList) => {
		if(this.props.executeActivity) {
			console.log(" Clicked on submit set status form");	
			let data = this.props.Status.status
			data && data.sections ?
				Object.keys(data.sections).map((obj, key) => {
					data.sections[obj] = statusList[key]
				}) : ''
			let uid = localStorage.getItem('uid')
			this.props.updateStatus(uid, data);
		}
		this.handelStatusEditClose();
	}
	
	render() {
		let { step, stepKey, clientImage, clientName , executeActivity, stepsCompletionList } = this.props;
		const { statusList, maxStepTimeout, pin, totalTry, showEvent } = this.state;

		const stepCompletionDetail = stepsCompletionList ? stepsCompletionList.find(ele => ele.step.id == step.stepId) : {};
		const status = stepCompletionDetail ? stepCompletionDetail.status : "";

		var timeleft = this.props.countDownInterval * 1000;
		let seconds = ("0" + (Math.floor(timeleft / 1000) % 60)).slice(-2);
		let minutes = ("0" + (Math.floor(timeleft / 60000) % 60)).slice(-2);
		let hours = ("0" + Math.floor(timeleft / 3600000)).slice(-2)
		let num = [1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, 'back']
		if (totalTry === 0 && this.props.pinMsg && this.props.pinMsg != "PIN verified successfully") {
			this.logoutUser()
		}
		let show = false
		if (this.props.pinMsg === "PIN verified successfully") {
			show = true
			setTimeout(() => {
				show = false
				this.handleClose()

			}, 2000);
		}
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		return (
			<div data-testid="activity-step-in-flow-view">
				{this.props.pinMsg === "PIN verified successfully" && show &&
					<div className="col-12 p-4">
						<SweetAlert
							success
							btnSize="sm"
							customClass="successText"
							confirmBtnText="YES"
							cancelBtnText="NO"
							confirmBtnBsStyle="success"
							title="Success"
							onConfirm={() => this.handleClose()}
						//onCancel={()=>this.handleCancel()}
						>
							<div className="sweet-alert-text">{this.props.pinMsg}</div>
						</SweetAlert>
					</div>
				}
				{this.state.showCheckIn &&
					<div className="col-12 checkin-container text-white mt-3">
						<div className="text-center">
							<h1 className="mx-auto my-2">Enter PIN</h1>
							<p className="mx-auto my-2">Enter a 4 digit PIN number</p>
						</div>
						<div className="w-70 text-center mx-auto">
							<div className="d-flex mx-auto">
								<input className="mx-auto" onChange={(e) => this.handelPinChange(e)} type="radio" name="num1" checked={pin.length >= 1} />
								<input className="mx-auto" onChange={(e) => this.handelPinChange(e)} type="radio" name="num2" checked={pin.length >= 2} />
								<input className="mx-auto" onChange={(e) => this.handelPinChange(e)} type="radio" name="num3" checked={pin.length >= 3} />
								<input className="mx-auto" onChange={(e) => this.handelPinChange(e)} type="radio" name="num4" checked={pin.length === 4} />
							</div>
							{pin.length >= 4 && this.props.pinMsg && this.props.pinMsg != "PIN verified successfully" ? <h1 className="mx-auto my-2 text-danger">{totalTry + " remaining retries"}</h1> : ''}
						</div>
						<div className="row">
							{num.map((n) => (
								n === "back" ?
									<div className="col-4 text-center button-pointer mx-auto" key={n} onClick={() => this.handleBack()}><BackspaceIcon className="mx-auto" /></div>
									: <div className="col-4 text-center button-pointer mx-auto" key={n} onClick={() => this.handlePIN(n)}>{n}</div>
							))}
						</div>
					</div>

				}

				{!this.state.showCheckIn && step.title &&
					<div className="text-center mt-1 h-100 activity-detail-height">
						<div className="row">
							<div className="col-3">
								{/*<div className="card-type">
								{step.cardType ?
									<Badge color="success">{step.cardType}</Badge>
								: step.actionButtonType ?
									<Badge color="success">{step.actionButtonType}</Badge>
								:
									<Badge color="success">General</Badge>
								}
							   </div>*/}
							</div>
							<div className='col-6 text-center pt-2'>
								<h1>{"Step " + (step.position || stepKey)}</h1>
							</div>
							<div data-testid="activity-step-title-speech" className="col-3 theame-text on-focus justify-content-right text-right">
								<Speech 
									style={textstyle}
									pitch="1"
									rate="1"
									volume="1"
									lang="en-US"
									voice="Fred"
									text={step.title}
									textAsButton={true}    
									displayText={<VolumeUpOutlinedIcon className="mt-5" />}
								/>
							</div>
						</div>
						{step.referenceURL ?
							<a className="text-black" style={{ textDecoration: 'underline' }} href={step.referenceURL} target="_blank">{step.title}</a>
							:
							<h4 data-testid="activity-step-title" className="mb-0 mt-1">{step.title}</h4>
						}
						{executeActivity && this.props.isTimerEnabled &&
						   <ActivityStepTimer 
						   stepDetails={step} 
						   stepsCompletionList={stepsCompletionList}
						   achievement={this.props.achievement}
						   viewType={this.props.viewType}
						   />
						}
						{/* <div className="text-center font-18 text-gray">{step.title}</div> */}
						{step.cardType ?
							<>
								{step.imageURL &&
									<div className="text-center font-18 text-gray">
										<img data-testid="activity-step-image" className="img-fluid mt-3 img-height-330" src={step.imageURL} alt="" />
									</div>
								}
								{step.cardType === 'Course' && this.props.coures &&
									<div className='mx-auto mt-2'>
										<div className="text-center font-18 text-gray">{this.props.coures.name}</div>
										<div className="text-center font-18 text-gray">
											<img className="img-fluid mt-3 img-height-330" src={this.props.coures.big_avatar} alt="" />
										</div>
										{/* <a className=" theame-color text-white mt-4" href={this.props.coures.url} target="_blank" onClick={() => this.props.disableNext()}>Open Course</a> */}
										<Button color="primary" variant="contained" className="mt-4 primary-button" onClick={() => this.props.showOpenCourseAlert(this.props.step.stepId, this.props.stepIndex, this.props.coures.url, null)}>Open Course</Button>
									</div>
								}
								{step.cardType === 'wpq' &&
									<div className="mx-auto mt-2">
										<Button color="primary" variant="contained" className="mt-4 primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open WPQ in activity preview")}>Open Questionnaire</Button>
									</div>
								}
								{step.cardType === 'event' &&
									<div className="mx-auto mt-2">
										<Button color="primary" variant="contained" className="mt-4 primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open event in activity preview")}>Check Event</Button>
									</div>
								}
								{step.cardFormId &&
									<div className='mx-auto mt-2'>
										<Button color="primary" variant="contained" data-testid="complete-form-button" className="primary-button" onClick={() => this.handleStepFrom(step.cardFormId)}>Complete Form</Button>
									</div>}
								{step.cardType === "changeStatus" &&
									<div className='mx-auto mt-2'>
										<Button color="primary" variant="contained" className="primary-button" onClick={() => this.updateStatusPopup()}>Change Status</Button>
									</div>
								}
								{step.cardType === "checkIn" &&
									<div className='mx-auto mt-2'>
										{/* <Button color="primary" variant="contained" className="primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open CheckIn in activity preview")}>CheckIn</Button> */}
									</div>
								}
								{((step.cardType === "countDown" || step.cardType === "countDownPermissive" || step.cardType === "countDownEnforced") && status != "Done") &&
									<div className='countdown-height'>
										{/*this.props.countDownInterval > 0 &&*/
											<div className='mx-auto mt-2'>
												<div className="text-center countdown-time my-50 text-danger">{hours + ':' + minutes + ':' + seconds}</div>
											</div>
										}
										{!this.props.countDownStart && <div className='d-flex mx-auto mt-2'>
											{(step.cardType === "countDown" || step.cardType === "countDownPermissive") && <Button color="primary" variant="outlined" className="countdown-edit-button  mx-auto" onClick={() => this.setState({ showTimer: true, hour: Number(hours), min: Number(minutes), sec: Number(seconds) })}>Edit</Button>}
											<Button color="primary" variant="contained" className="mx-auto primary-button" onClick={() => this.handleStart(step.cardType)}>Start</Button>
										</div>}
									</div>
								}
							</>
							:
							<>
								{step.imageURL &&
									<div className="text-center font-18 text-gray">
										<img data-testid="activity-step-image" className="img-fluid mt-3 img-height-330" src={step.imageURL} alt="" />
									</div>
								}
								{step.actionButtonType === "changeStatus" &&
									<div className='mx-auto mt-2'>
										<Button color="primary" variant="contained" data-testid="change-status-button" className="primary-button" onClick={() => this.updateStatusPopup()}>Change Status</Button>
									</div>
								}
								{step.actionButtonType === "checkIn" &&
									<div className='mx-auto mt-2'>
										<Button color="primary" variant="contained" className="primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open CheckIn in activity preview")}>CheckIn</Button>
									</div>
								}
								{((step.actionButtonType === "countDown" || step.actionButtonType === "countDownEnforced" || step.actionButtonType === "countDownPermissive") && status != "Done") &&
									<div className='countdown-height'>
										{/*this.props.countDownInterval > 0 &&*/
											<div className='mx-auto mt-2'>
												<div className="text-center countdown-time my-50 text-danger">{hours + ':' + minutes + ':' + seconds}</div>
											</div>
										}
										{!this.props.countDownStart && <div className='d-flex mx-auto mt-2'>
											{(step.actionButtonType === "countDown" || step.actionButtonType === "countDownPermissive") && <Button color="primary" variant="outlined" className=" countdown-edit-button mx-auto" onClick={() => this.setState({ showTimer: true, hour: Number(hours), min: Number(minutes), sec: Number(seconds) })}>Edit</Button>}
											<Button color="primary" variant="contained"  className="  mx-auto primary-button" onClick={() => this.handleStart(step.actionButtonType)}>Start</Button>
										</div>}
									</div>
								}
							</>
						}
						{/* {step.referenceURL&&
								<div className='mx-auto mt-2'>
									<a className=" theame-color text-white" href={step.referenceURL} target="_blank">Check</a>
								</div>}
							 */}
						{step.contentUrl &&
							<div className="text-center font-18 text-gray">
								<img className="img-fluid mt-3 img-height-330" src={step.contentUrl} alt="" />
							</div>
						}
						{/*step.helpVideoURL &&
							<div  className='mx-auto mt-2'>
								<Button color="primary" variant="contained" data-testid="help-video-link" className=" primary-button" href={step.helpVideoURL} target="_blank">Help Video</Button>
							</div>
						*/}

					</div>
				}

				{this.state.showTimer &&
					<Dialog
						fullScreen={false}
						open={this.state.showTimer}
						onClose={() => this.setState({ showTimer: false })}
						fullWidth={true}
						maxWidth="xl"
						aria-labelledby="responsive-dialog-title"
                        className={this.props.popupThemeClass}
						PaperProps={
							{ style: { maxWidth: "500px", borderRadius: 8, margin: 'auto' } }
						}
					>
						<div>
							<div className="row bg-lifesherpa p-3">
								<div className="col">
									<h2 className="text-white">{step.title}</h2>
									<h4 className="text-white mb-0 pb-0">Edit Timer</h4>
								</div>
								<IconButton className="ml-auto my-auto text-white" onClick={() => this.setState({ showTimer: false })}><CloseIcon /></IconButton>
							</div>
							<div className="row p-3 mt-3">
								<FormGroup className="col text-center">
									<Label>HH</Label>
									<Input className="msg-inpu text-center" value={this.state.hour} /*pattern="^[0-9]*$"*/ min={0} max={25} type='number' placeholder='00' name='timer' onChange={(e) => this.handleHour(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>MM</Label>
									<Input className="msg-inpu text-center" value={this.state.min} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleMin(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>SS</Label>
									<Input className="msg-inpu text-center" value={this.state.sec} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleSec(e)} />
								</FormGroup>
							</div>
							<div className="row d-flex align-items-center justify-content-center mt-4 update-time-buttons">
								<Button color="primary" variant="outlined" className="  mb-3 mr-2 form-cancel-button " onClick={() => this.setState({ showTimer: false })}>Cancel</Button>
								<Button color="primary" variant="contained" className="  mb-3 ml-2 primary-button" onClick={() => this.updateTimer()}>Update Timer</Button>
							</div>
						</div>
					</Dialog>
				}
				{this.state.StatusPopup &&
					<SetCurrentStatus
					    image={this.props.image}
					    name={this.props.name}
						showStatus={this.state.StatusPopup}
						profile={auth_uid == uid ? this.props.profile : undefined}
						handelStatusEditClose={this.handelStatusEditClose}
						updateStatus={this.updateStatus}
						executeActivity={this.props.executeActivity}
					/>}
				{this.state.warningPopup &&
					<div className="col-12 p-4">
						<SweetAlert
							warning
							btnSize="sm"
							customClass="warningText"
							confirmBtnText="OK"
							confirmBtnBsStyle="warning"
							title={this.state.warningPopup}
							onConfirm={() => this.handleCloseWarningPopup()}
							>
						<div className="sweet-alert-text"></div>
						</SweetAlert>
					</div>
					}
			</div>);
	}
}
const mapStateToProps = ({ settings, authUser, Status, activitiesList, chatAppReducer, GroupList }) => {
	const { selectedMemberId, selectedRole } = chatAppReducer;
	const { popupThemeClass } = settings; 
	const { groupMembersDetails } = GroupList;
	const { coures, pinMsg, wpqLink, } = activitiesList;
	let { clientStatus, status } = Status;
	//console.log("mapStateToProps",coures)
	const { user, profile, credit } = authUser
	const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] : null
	return {popupThemeClass, clientStatus, status, settings, user, profile, credit, Status, coures, pinMsg, wpqLink, selectedMemberId, groupMembersDetails };
};

export default withAuth0(withRouter(connect(mapStateToProps, {
	getClientStatusDataById, getWPQ, logoutUserFromFirebase, removePinMsg, checkPIN, getCourse, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusById, getPokeForm
})(StartActivityDetails)));
