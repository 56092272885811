/**
* Chat
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';

import {
	getPokeForm,
	setSelectedMemberId,
	getSelectedActivity,
	changeRole,
	getResourceList,
	getcategories,
	getKeywords,
	getMyCourses,
	showClientPanel,
	clearHistoryData,
	showActivityHistoryDetail,
	getSelectedHistAchievement,
	setHistoryListPageCount,
	getHistoryList,
	handleShowEncounterActivityHistory
} from '../../actions';

// components
import ActivityHistoryDetail from './components/ActivityHistoryDetail';
import ActivityHistoryListPanel from './components/ActivityHistoryListPanel';
import ActivityConfirmForm from '../ActivityForm/ActivityConfirmForm'


class ActivitiesHistoryPanel extends Component {

	state = {
		openPenel: "list",
		formId: "",
		selectedStepId: null,
		openForm: false
	};

	componentDidMount() {
		const activityConfirmation = localStorage.getItem("activityConfirmation");
		if (activityConfirmation) {
			this.setState({ openPenel: "details" });
		} else {
			this.setState({ openPenel: "list" });
		}
	}

	handleOpenForm = (formId, stepId) => {
		console.log(" formId -->", formId)
		this.setState({ openForm: true, formId: formId, selectedStepId: stepId })
		//this.props.showActivityHistoryDetail(false)
	}
	handleCloseForm = () => {
		this.setState({ openForm: false, formId: null, stepId: null })
	}

	handleOpenActivityDetails = () => {
		this.setState({ openPenel: "details" })
	}

	handleOpenActivitiesList = () => {
		const { selectedActivity, pageCount } = this.props;
		const activityConfirmation = localStorage.getItem("activityConfirmation");
		const uid = localStorage.getItem('uid');
		console.log("pageCount --> ", pageCount)
		if (activityConfirmation && pageCount === 1) {
			console.log("opening activities list --> ", pageCount)
			this.props.clearHistoryData();
			this.props.setHistoryListPageCount(1)
			this.props.getHistoryList(uid, selectedActivity.id, 0);
		}
		this.setState({ openPenel: "list" });
		this.props.getSelectedHistAchievement(null);
	}
	onCloseActivitiesHistoryPanel = () => {
		this.closeActivitiesHistoryPanel();
	}

	closeActivitiesHistoryPanel = () => {
		const { clientPanel, showUnconfirmedAcheivemts, showEncounterActivityHistory } = this.props;
		const activityConfirmation = localStorage.getItem("activityConfirmation");
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		const orgId = localStorage.getItem("orgId")
		if (activityConfirmation && uid != auth_uid) {
			localStorage.setItem('uid', auth_uid);
			localStorage.setItem("selectedOrgId", orgId)
			this.props.setSelectedMemberId(null);
			localStorage.
			this.props.changeRole({ role: 'admin', id: auth_uid });
			//const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : [orgId]
			if (clientPanel) {
				this.props.getResourceList('', '', '');
				this.props.getcategories();
				this.props.getKeywords();
				this.props.getMyCourses();
				this.props.showClientPanel(false);
			}
		}
		localStorage.removeItem("activityConfirmation");
		if (showUnconfirmedAcheivemts || showEncounterActivityHistory) {
			if (!clientPanel) {
				localStorage.setItem('uid', auth_uid);
				localStorage.setItem("selectedOrgId", orgId)
				this.props.setSelectedMemberId(null);
				this.props.changeRole({ role: 'admin', id: auth_uid });
			}
			if(showEncounterActivityHistory) {
				this.props.handleShowEncounterActivityHistory(false)
			}
		}
		this.setState({ openPenel: "list" });
		this.props.clearHistoryData();
		this.props.getSelectedActivity(null);
		this.props.showActivityHistoryDetail(false)
	}


	render() {
		const { openForm, openPenel } = this.state;
		const { showActivityHistory, authUserImage, authUserName, clientImage, clientName } = this.props;
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		const profileImage = auth_uid === uid ? authUserImage : clientImage;
		const displayName = auth_uid === uid ? authUserName : clientName;
		return (
			<div className="achievement-history-list-container lifesherpa-session-theme-support w-100">
				<Hidden mdUp>
					{openPenel === "details" ?
						<ActivityHistoryDetail
							handleOpenActivitiesList={this.handleOpenActivitiesList}
							selectedMemberId={this.props.selectedMemberId}
							selectedActivitiesHistory={this.props.selectedActivitiesHistory}
							handleOpenForm={this.handleOpenForm.bind(this)}
							onCloseActivitiesHistoryPanel={this.onCloseActivitiesHistoryPanel.bind(this)}
							displayName={displayName}
							fullScreen={false}
						/>
						:
						<ActivityHistoryListPanel
							handleOpenActivityDetails={this.handleOpenActivityDetails.bind(this)}
							displayName={displayName}
							onCloseActivitiesHistoryPanel={this.onCloseActivitiesHistoryPanel.bind(this)}
							fullScreen={false}
						/>
					}
				</Hidden>
				<Hidden mdDown>
					<div className="d-flex activities-history-full-screen">
						<ActivityHistoryListPanel
							handleOpenActivityDetails={this.handleOpenActivityDetails.bind(this)}
							displayName={displayName}
							onCloseActivitiesHistoryPanel={this.onCloseActivitiesHistoryPanel.bind(this)}
							fullScreen={true}
						/>
						<div className="w-100">
							<ActivityHistoryDetail
								handleOpenActivitiesList={this.handleOpenActivitiesList}
								selectedMemberId={this.props.selectedMemberId}
								selectedActivitiesHistory={this.props.selectedActivitiesHistory}
								handleOpenForm={this.handleOpenForm.bind(this)}
								onCloseActivitiesHistoryPanel={this.onCloseActivitiesHistoryPanel.bind(this)}
								displayName={displayName}
								fullScreen={true}
							/>
						</div>
					</div>
				</Hidden>

				<ActivityConfirmForm
					open={openForm}
					handleClose={this.handleCloseForm.bind(this)}
					displayName={displayName}
					profileImage={profileImage}
					title={this.props.selectedActivitiesHistory && this.props.selectedActivitiesHistory.title && this.props.selectedActivitiesHistory.title}
					loading={this.props.pokeFormLoading}
					pokeForm={this.props.pokeForm}
					selectedMemberId={this.props.selectedMemberId}
					selectedActivitiesHistory={this.props.selectedActivitiesHistory}
					selectedStepId={this.state.selectedStepId}
					formId={this.state.formId}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ emailApp, chatAppReducer, activitiesList, PokeForm, activitiesHistoryList, GroupList, ActivityReducer, authUser, clientsList }) => {
	const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
	const { selectedUser, displayName, profileImage, selectedMemberId, selectedRole, selectedUserInfo } = chatAppReducer
	const { allActivities, privateActivities, loading, activityFilter, listStyle, selectedActivity, showEncounterActivityHistory } = activitiesList
	const { selected } = ActivityReducer
	const { user, profile, usersInfo } = authUser
	const { selectedActivitiesHistory, selectedStepId, showUnconfirmedAcheivemts, pageCount } = activitiesHistoryList
	// let pokeForm = PokeForm.pokeForm
	const { pokeForm, isPokeForGroup } = PokeForm;
	let { clientPanel } = clientsList;
	let pokeFormLoading = PokeForm.loading
	const { selectedGroup, groups, groupMembersDetails } = GroupList;
	const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] || selectedUserInfo || null : selectedUserInfo || null;
	let authUserImage = profile && profile.picture ? profile.picture : (usersInfo && usersInfo.profileImageURL) || require('Assets/avatars/user-15.jpg');
	let authUserName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name) : 'Display Name'
	let clientImage = selectedClient && selectedClient.profileImageURL && selectedClient.name !== "Test User" ? selectedClient.profileImageURL : require('Assets/avatars/user-15.jpg');
	let clientName = selectedClient && selectedClient.name ? selectedClient.name : 'Client';
	return { currentEmail, user, selectedRole, sendingEmail, listStyle, showActivityHistory, selected, activityFilter, selectedMemberId, showConfirmation, selectedUser, allActivities, privateActivities, loading, pokeFormLoading, pokeForm, displayName, profileImage, selectedActivitiesHistory, selectedStepId, selectedGroup, isPokeForGroup, groups, authUserImage, authUserName, clientImage, clientName, showUnconfirmedAcheivemts, clientPanel, selectedActivity, pageCount, showEncounterActivityHistory };
}

export default withRouter(connect(mapStateToProps, {
	getPokeForm,
	setSelectedMemberId,
	getSelectedActivity,
	changeRole,
	getResourceList,
	getcategories,
	getKeywords,
	getMyCourses,
	showClientPanel,
	clearHistoryData,
	showActivityHistoryDetail,
	getSelectedHistAchievement,
	setHistoryListPageCount,
	getHistoryList,
	handleShowEncounterActivityHistory
})(ActivitiesHistoryPanel));

