/**
* Main App
*/
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserProvider from "../src/providers/UserProvider"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import queryString from 'query-string';
// css
import './lib/reactifyCss';
// app component
import App from './widgets/App';
// import Clientslider from "./routes/about-us/components/client-slider"
import { configureStore } from './store';

class AppWidget extends Component {
    getParamsData = () => {
        let params = {}
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        if(this.props.match && this.props.match.params && this.props.match.params) {
            params.widget = this.props.match.params["widgetApp"]
        }
        return params;
    }

    render() {
        const propsData = this.props.widget ? this.props : this.getParamsData();
        
        if(propsData.widget) {
            return (
                <Provider store={configureStore()}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Router>
                            <Switch>
                                <Route path="/" render={(props) => <App {...props} propsData={propsData} />} />
                            </Switch>
                        </Router>
                    </LocalizationProvider>
                </Provider>
            )
        }
        return "Something went wrong, Please try again";
    }
}

export default AppWidget;