/**
 * Chat App Actions
 */
import {setInprogressActivitiesList} from '../actions'
import {
    CHAT_WITH_SELECTED_USER,
    SEND_MESSAGE_TO_USER,
    UPDATE_USERS_SEARCH,
    SEARCH_USERS,
    GET_RECENT_CHAT_USERS,
    SET_CHAT_OPEN,
    SET_ACTIVITIES_OPEN,
    SET_OPEN_CLIENT_DETAIL,
    SET_USER_SELECTED,
    SET_SELECTED_USER_ID,
    CHANGE_ROLE,
    GET_CLIENT_STATUS,
    SHOW_CHAT_PANEL_FOR_SELCTED_USER,
    HIDE_CHAT_PANEL,
    ADD_SELECTED_CHAT_USER,
    REMOVE_SELECTED_CHAT_USER,
    SELECTED_CHAT_USERS_UPDATE,
    SET_SELECTED_USER,
    REMOVE_SELECTED_USER,
    SET_SELECTED_USER_INFO,
    HANDLE_SHOW_HELP_PANEL
} from './types';

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const chatOpen = (open) => ({
    type: SET_CHAT_OPEN,
    payload: open
});

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const setSelectedMemberId = (id) => ({
    type: SET_SELECTED_USER_ID,
    payload: id
});
export const setSelectedChatUser = (user) => ({
    type: SET_SELECTED_USER,
    payload: user
});
export const removeSelectedUser = () => ({
    type: REMOVE_SELECTED_USER,
});
/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const setUserSelected = (user) => (dispatch) => {
    let status={}
    if(user.lastUserStatus){
        status={status1:user.lastUserStatus.status,color1:user.lastUserStatus.color,status2:'',color2:''}
    } else{
        status={status1:'No Status',color1:'#008C95',status2:'No Status',color2:'#008C95'}
    }
   // dispatch({type:GET_CLIENT_STATUS,payload:status})
    dispatch({type: SET_USER_SELECTED, payload: user})
    dispatch(setInprogressActivitiesList(user ? user.achievementsInProgress : null))
};

export const setSelectedUserInfo = (user) => (dispatch) => {
    dispatch({type: SET_SELECTED_USER_INFO, payload: user})
};

 export const changeRole = (role) => (dispatch) => {
    dispatch({type: CHANGE_ROLE, payload: role})
};

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const openClientDetail = () => (dispatch) => {
    dispatch({type: SET_OPEN_CLIENT_DETAIL})
};


/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const activitiesOpen = (actOpen) => ({
    type: SET_ACTIVITIES_OPEN,
    payload: actOpen
});

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const chatWithSelectedUser = (user) => ({
    type: CHAT_WITH_SELECTED_USER,
    payload: user
});

export const sendMessageToUser = (data) => ({
    type: SEND_MESSAGE_TO_USER,
    payload: data
});

/**
 * Redux Action To Update User Search
 */
export const updateUsersSearch = (value) => ({
    type: UPDATE_USERS_SEARCH,
    payload: value
});

/**
 * export const to search users
 */
export const onSearchUsers = (value) => ({
    type: SEARCH_USERS,
    payload: value
});

/**
 * Get Recent Chat User
 */
export const getRecentChatUsers = () => ({
    type: GET_RECENT_CHAT_USERS
});

export const showFullChatPanelForSelectedUser = (user) => ({
    type: SHOW_CHAT_PANEL_FOR_SELCTED_USER,
    payload:user
});
export const hideChatPanel = () => ({
    type: HIDE_CHAT_PANEL, 
});

export const removeSelectedChatUser = (users) => (dispatch) => {
    dispatch({type: REMOVE_SELECTED_CHAT_USER,payload:users});
    dispatch({type: SELECTED_CHAT_USERS_UPDATE,payload:users.length})
};

export const addSelectedChatUser = (users) => (dispatch) => {
    dispatch({type: ADD_SELECTED_CHAT_USER,payload:users});
    dispatch({type: SELECTED_CHAT_USERS_UPDATE, payload:users.length})
};

export const handleShowHelpPanel = (data) => (dispatch) => {
    dispatch({type: HANDLE_SHOW_HELP_PANEL, payload: data});
};
