/**
* Chat
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoticesList from './NoticesList';
import NoticeForm from './NoticeForm';
import NoticesHistory from './NoticesHistory';
import AlertView from './AlertView';
// reactstrap components
import { Label } from 'reactstrap';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";

import {
    handleNoticesPanel,
    clearNoticesData,
    clientNetworkActivity,
    networkActivity
} from '../../actions';

// Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


class ActivitiesHistoryPanel extends Component {

    state = {
        openPanel: "alerts",
        alert: null,
        postId: null,
    };

    componentDidMount() {
        const { selectedUserId, params } = this.props;
        if (params && params.postId && params.categoryId) {
            this.openAlertView({id: params.postId, categoryId: params.categoryId})
            console.log("params : ", params);
        } 
    }

    // componentDidUpdate(prevProps) {
    //     const { postId, alert, openPanel } = this.state;
    //     const { noticeHistoryLoading, noticesHistory } = this.props;
    //     if (postId && !noticeHistoryLoading && noticesHistory && !alert && openPanel !== "view") {
    //         const alert = noticesHistory.find(post => post.id === postId);
    //         console.log("alert : ", alert);
    //         if (alert) {
    //             this.openAlertView(alert);
    //         }
    //     }
    // }

    handleOpenNoticeForm = (notice) => {
        console.log("Notice Selected : ", notice);
        this.setState({ openPanel: "form", notice })
    }

    handleclosePanel = () => {
        const { widgetApp, selectedUserId, clientPanel} = this.props;
        const orgId = localStorage.getItem("orgId")
        if (widgetApp) {
            window.location.href = window.location.origin + "/success";
        } else {
            this.props.clientNetworkActivity(selectedUserId);
		    this.props.networkActivity()
            this.props.clearNoticesData();  
            if (!clientPanel) {
                localStorage.setItem("selectedOrgId", orgId)
            }
        }
    }

    handleBackToList = () => {
        const { openPanel } = this.state;
        const {selectedUserId, params} = this.props;
        if(params && params.postId && params.categoryId) {
            this.handleclosePanel();
            return;
        }
        if (openPanel == 'form') {
            this.setState({ openPanel: "list" })
        } else if (openPanel == 'list' || openPanel == 'view') {
            this.setState({ openPanel: "alerts", postId: null })
        } else {
            this.handleclosePanel();
        }
    }

    openAlertView = (alert) => {
        this.setState({ openPanel: "view", alert });
    }

    showNoticesListPanel = () => {
        this.setState({ openPanel: "list" })
    }

    render() {
        const { formLoading, noticeHistoryLoading, authUserImage, authUserName, widgetApp, params } = this.props;
        let { clientImage, clientName } = this.props;
        if (widgetApp) {
            clientImage = authUserImage;
            clientName = authUserName;
        }
        const { openPanel, notice } = this.state;
        return (
            <div>
                {openPanel == "view"?
                    <AlertView
                        widgetApp={widgetApp}
                        postId={this.state.alert.id}
                        categoryId={this.state.alert.categoryId}
                        title={clientName}
                        handleBackToList={this.handleBackToList}
                        handleclosePanel={this.handleclosePanel}
                    />
                    :
                    <Dialog
                        open={true}
                        fullScreen={openPanel == "view" || Boolean(widgetApp)}
                        maxWidth="xs"
                        fullWidth={!(Boolean(widgetApp) || openPanel == "view")}
                        aria-labelledby="responsive-dialog-title"
                        className={`${this.props.popupThemeClass} notices-list-panel`}
                    >
                        <DialogTitle>
                            <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>

                                <IconButton className="text-white" title='Back' onClick={() => this.handleBackToList()}><ArrowBackIcon /></IconButton>
                                <Label data-testid="notice-model-heading" className="listItemTitle mb-0 text-white">{openPanel == "form" ? notice.title : clientName}</Label>

                                <IconButton className="text-white mr-2 hide-element" onClick={() => this.handleclosePanel()}><CloseIcon /></IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent className="m-0 p-0 activity-details-height">
                            {
                                openPanel == "form" ?
                                    <NoticeForm
                                        clientName={clientName}
                                        clientImage={clientImage}
                                        notice={notice}
                                        handleclosePanel={this.handleclosePanel}
                                        widgetApp={widgetApp}
                                    />
                                    : openPanel == "list" ?
                                        <NoticesList
                                            handleOpenNoticeForm={this.handleOpenNoticeForm}
                                            widgetApp={widgetApp}
                                        />
                                        :
                                        <NoticesHistory
                                            showNoticesListPanel={this.showNoticesListPanel}
                                            openAlertView={this.openAlertView}
                                            widgetApp={widgetApp}
                                        />
                            }
                        </DialogContent>
                    </Dialog>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ noticesReducer, PokeForm, GroupList, settings, authUser, clientsList }) => {
    const { popupThemeClass } = settings;
    const { profile, usersInfo, user_no_group } = authUser;
    const { pokeForm } = PokeForm;
    let pokeFormLoading = PokeForm.loading;
    const { selectedUserId, noticeLoading, formLoading, noticeHistoryLoading, noticesHistory } = noticesReducer;
    const { groupMembersDetails } = GroupList;
    const selectedClient = groupMembersDetails && selectedUserId ? groupMembersDetails.filter(client => client.userId === selectedUserId)[0] || user_no_group : user_no_group;
    let authUserImage = profile && profile.picture ? profile.picture : (usersInfo && usersInfo.profileImageURL) || require('Assets/avatars/user-15.jpg');
    let authUserName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name) : 'Display Name'
    let clientImage = selectedClient && selectedClient.profileImageURL && selectedClient.name !== "Test User" ? selectedClient.profileImageURL : require('Assets/avatars/user-15.jpg');
    let clientName = selectedClient && (selectedClient.name || selectedClient.displayName || selectedClient.firstName)  ? (selectedClient.name || selectedClient.displayName || selectedClient.firstName)  : 'Client';
    let { clientPanel } = clientsList;
    return { authUserImage, authUserName, selectedUserId, clientName, clientImage, pokeForm, pokeFormLoading, popupThemeClass, formLoading, noticeLoading, noticeHistoryLoading, noticesHistory, clientPanel };
}

export default withRouter(connect(mapStateToProps, {
    handleNoticesPanel,
    clearNoticesData,
    clientNetworkActivity,
    networkActivity
})(ActivitiesHistoryPanel));

