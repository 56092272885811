/**
 * Client Alerts
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'

import NoticesHistory from '../../components/Notices/NoticesHistory';
//Actions
import {
    clearNoticesData
} from '../../actions';
import config from '../../config/config';

class Notices extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null
        }
    }

    handleclosePanel = () => {
        this.props.clearNoticesData();
        window.location.href = window.location.origin + "/success";
    }


    componentDidMount() {
        this.props.handleOpenURL('alerts');
        console.log("Notices History Rendered");
        //http://localhost:8000/widget/client-alerts?userId=0I1aJIkeHPOdG4V896xnmvjrStq1&orgId=Test&region=australia&idToken=
    }

    showNoticesListPanel = () => {
        const {userId, idToken, orgId, theme, widget} = this.props.params;
        let redirctURL = `/widget/${widget}/notices?userId=${userId}&orgId=${orgId}&theme=${theme}&idToken=${idToken}`;
        // window.location.href = redirctURL;
        //this.props.history.push(redirctURL);
        this.props.handleOpenURL('list', redirctURL);
    }

    openAlertView = (alert) => {
        const {userId, idToken, orgId, theme, widget} = this.props.params;
        let redirctURL = `/widget/${widget}/view?userId=${userId}&orgId=${orgId}&theme=${theme}&postId=${alert.id}&categoryId=${alert.categoryId}&idToken=${idToken}`;
        // window.location.href = redirctURL;
        //this.props.history.push(redirctURL);
        this.props.handleOpenURL('view', redirctURL);
    }

    render() {
        const { widgetApp, selectedUserId } = this.props;
        return (
            <div className='notices-history-page'>
                {selectedUserId ?
                    <NoticesHistory
                        widgetApp={widgetApp}
                        showNoticesListPanel={this.showNoticesListPanel}
                        openAlertView={this.openAlertView}
                    />
                    : ""
                }
            </div>
        )
    }
}

const mapStateToProps = ({ authUser, noticesReducer }) => {
    const { themeLoading, userLoading } = authUser;
    const { selectedUserId } = noticesReducer;
    return { themeLoading, userLoading, selectedUserId }
}

export default connect(mapStateToProps, {
    clearNoticesData
})(Notices)
