import {database} from '../firebase'
import { ref, onValue  } from "firebase/database"

import {
    USER_INACTIVITY_CONTINUE,
    USER_INACTIVITY_LOGOUT,
    USER_INACTIVITY_AUTOLOGOUT,
    USER_INACTIVITY_TIMER_UPDATE,
    HANDLE_UNACTIVE_ALERT,
    USER_SET_INACTIVE_TIMER_VALUE,
    CLEAR_INACTIVITY_IDLE_DATA,
    STORE_IN_ACTIVE_SET_INTERVAL_DATA,
} from './types';


//Exporting actions methods
export const setInActivityTimerValue = () => (dispatch) => {
    dispatch(clearInActiviyIdleData());
    const systemRef = ref(database, `System`)
    try {
        onValue(systemRef,(snapshot) => {
            const data = snapshot.val();
            dispatch({ type: USER_SET_INACTIVE_TIMER_VALUE, payload: data});
          
        })
    } catch (error) {
         // dispatch({ type: USER_SET_INACTIVE_TIMER_VALUE ,payload:null}); 
         console.log(error)
    }
}

export const setInActivityLogout = (bool) => ({
    type: USER_INACTIVITY_LOGOUT,
    payload: bool
});

export const setInActivityAutoLogout = (bool) => ({
    type: USER_INACTIVITY_AUTOLOGOUT,
    payload: bool
});

export const setInActivityTimerUpdate = (value) => ({
    type: USER_INACTIVITY_TIMER_UPDATE,
    payload: value
});

export const setInActivityContinue = (bool) => ({
    type: USER_INACTIVITY_CONTINUE,
    payload: bool
});

export const handelUnActiveAlertPopup = (bool) => ({
    type: HANDLE_UNACTIVE_ALERT,
    payload: bool
});

export const clearInActiviyIdleData = () => (dispatch) => {
    dispatch({ type: CLEAR_INACTIVITY_IDLE_DATA});
};

export const storeInActiveSetIntervalData = (interval) => (dispatch) => {
    dispatch({ type: STORE_IN_ACTIVE_SET_INTERVAL_DATA,payload:interval});
};


