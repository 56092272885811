/**
 * App Redux Action Types
 */
export const GET_CLIENT_STATUS_DATA = 'GET_CLIENT_STATUS_DATA';
export const GET_STATUS_LOADING = 'GET_STATUS_LOADING';
export const CLEAR_POKE_FORM_DATA = 'CLEAR_POKE_FORM_DATA';
export const GET_REALTIME_USER_ACTIVITIES = 'GET_REALTIME_USER_ACTIVITIES';
export const SHOW_CLIENT_PANEL = 'SHOW_CLIENT_PANEL';
export const HIDE_RESOURCES_DATA_LOADING = 'HIDE_RESOURCES_DATA_LOADING';
export const HIDE_REWARD_DATA_LOADING = 'HIDE_REWARD_DATA_LOADING';
export const GET_RESOURCES_DATA_LOADING = 'GET_RESOURCES_DATA_LOADING';
export const GET_REWARD_DATA_LOADING = 'GET_REWARD_DATA_LOADING';
export const SET_LOGOUT_LMS_URL = 'SET_LOGOUT_LMS_URL';
export const GET_LMS_URL_FAILED = 'GET_LMS_URL_FAILED';
export const GET_LMS_URL_SUCCESS = 'GET_LMS_URL_SUCCESS';
export const GET_LMS_URL = 'GET_LMS_URL';
export const GET_SUCCESS_ALL_ACTIVITIES_LIST = 'GET_SUCCESS_ALL_ACTIVITIES_LIST';
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const BOXED_LAYOUT = 'BOXED_LAYOUT';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const START_USER_TOUR = 'START_USER_TOUR';
export const STOP_USER_TOUR = 'STOP_USER_TOUR';
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV';
export const SET_VIEW_SIZE = 'SET_VIEW_SIZE'
// Chat App Actions
export const CHAT_WITH_SELECTED_USER = 'CHAT_WITH_SELECTED_USER';
export const SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER';
export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH';
export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_RECENT_CHAT_USERS = 'GET_RECENT_CHAT_USERS';
export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
export const SET_ACTIVITIES_OPEN = 'SET_ACTIVITIES_OPEN';
export const SET_OPEN_CLIENT_DETAIL = 'SET_OPEN_CLIENT_DETAIL';
export const SET_USER_SELECTED = 'SET_USER_SELECTED';
export const SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID';
export const CHANGE_ROLE='CHANGE_ROLE'

// Agency Sidebar
export const AGENCY_TOGGLE_MENU = 'AGENCY_TOGGLE_MENU';
export const CHANGE_AGENCY_LAYOUT_BG = 'CHANGE_AGENCY_LAYOUT_BG';
// Mail App
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const SET_EMAIL_AS_STAR = 'SET_EMAIL_AS_STAR';
export const READ_EMAIL = 'READ_EMAIL';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const FETCH_EMAILS = 'FETCH_EMAILS';
export const ON_SELECT_EMAIL = 'ON_SELECT_EMAIL';
export const UPDATE_EMAIL_SEARCH = 'UPDATE_EMAIL_SEARCH';
export const SEARCH_EMAIL = 'SEARCH_EMAIL';
export const ON_DELETE_MAIL = 'ON_DELETE_MAIL';
export const ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING = 'ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING';
export const GET_SENT_EMAILS = 'GET_SENT_EMAILS';
export const GET_INBOX = 'GET_INBOX';
export const GET_DRAFTS_EMAILS = 'GET_DRAFTS_EMAILS';
export const GET_SPAM_EMAILS = 'GET_SPAM_EMAILS';
export const GET_TRASH_EMAILS = 'GET_TRASH_EMAILS';
export const ON_EMAIL_MOVE_TO_FOLDER = 'ON_EMAIL_MOVE_TO_FOLDER';
export const SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILS';
export const UNSELECT_ALL_EMAILS = 'UNSELECT_ALL_EMAILS';
export const ON_SEND_EMAIL = 'ON_SEND_EMAIL';
export const EMAIL_SENT_SUCCESSFULLY = 'EMAIL_SENT_SUCCESSFULLY';
export const FILTER_EMAILS_WITH_LABELS = 'FILTER_EMAILS_WITH_LABELS';
export const ADD_LABELS_INTO_EMAILS = 'ADD_LABELS_INTO_EMAILS';
// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';
// ToDo App
export const GET_TODOS = 'GET_TODOS';
export const FETCH_TODOS = 'FETCH_TODOS';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const ON_SELECT_TODO = 'ON_SELECT_TODO';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const ON_BACK_TO_TODOS = 'ON_BACK_TO_TODOS';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const MARK_AS_STAR_TODO = 'MARK_AS_STAR_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const ADD_LABELS_INTO_THE_TASK = 'ADD_LABELS_INTO_THE_TASK';
export const GET_ALL_TODO = 'GET_ALL_TODO';
export const GET_COMPLETED_TODOS = 'GET_COMPLETED_TODOS';
export const GET_DELETED_TODOS = 'GET_DELETED_TODOS';
export const GET_STARRED_TODOS = 'GET_STARRED_TODOS';
export const GET_FILTER_TODOS = 'GET_FILTER_TODOS';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const UPDATE_TASK_TITLE = 'UPDATE_TASK_TITLE';
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION';
export const CHANGE_TASK_ASSIGNER = 'CHANGE_TASK_ASSIGNER';
export const ON_CHECK_BOX_TOGGLE_TODO_ITEM = 'ON_CHECK_BOX_TOGGLE_TODO_ITEM';
export const SELECT_ALL_TODO = 'SELECT_ALL_TODO';
export const GET_UNSELECTED_ALL_TODO = 'GET_UNSELECTED_ALL_TODO';
export const SELECT_STARRED_TODO = 'SELECT_STARRED_TODO';
export const SELECT_UNSTARRED_TODO = 'SELECT_UNSTARRED_TODO';
export const ON_LABEL_SELECT = 'ON_LABEL_SELECT';
export const ON_LABEL_MENU_ITEM_SELECT = 'ON_LABEL_MENU_ITEM_SELECT';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const SEARCH_TODO = 'SEARCH_TODO';
// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_SUCCESS_USER_BY_ID = 'GET_SUCCESS_USER_BY_ID'
export const POST_LIFE_SHERPA_CREATE_USER = 'POST_LIFE_SHERPA_CREATE_USER'
export const POST_LIFE_SHERPA_SUCCESS_CREATE_USER = 'POST_LIFE_SHERPA_SUCCESS_CREATE_USER'
export const GET_VALIDATE_INVITE_CODE = 'GET_VALIDATE_INVITE_CODE'
export const GET_SUCCESS_VALIDATE_CODE = 'GET_SUCCESS_VALIDATE_CODE'
export const SHOW_FIREBASE_AUTH_LOADING = 'SHOW_FIREBASE_AUTH_LOADING'
export const HIDE_FIREBASE_AUTH_LOADING = 'HIDE_FIREBASE_AUTH_LOADING'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER__PASSWORD_SUCCESS = 'RESET_USER__PASSWORD_SUCCESS'
export const VERIFY_USER_ACCOUNT_VIA_MAIL = 'VERIFY_USER_ACCOUNT_VIA_MAIL'
export const VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL = 'VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL'
export const RESEND_VERIFICATION_LINK = 'RESEND_VERIFICATION_LINK'
export const RESEND_VERIFICATION_LINK_SUCCESS = 'RESEND_VERIFICATION_LINK_SUCCESS'
export const GET_ORGANIZATION_PRIVACY_POLICY = 'GET_ORGANIZATION_PRIVACY_POLICY'
export const GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY = 'GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY'
export const GET_USER_FIREBASE_TOKEN = 'GET_USER_FIREBASE_TOKEN'
export const GET_SUCCESS_USER_FIREBASE_TOKEN = 'GET_SUCCESS_USER_FIREBASE_TOKEN'
export const GET_USER_INFO = 'GET_USER_INFO'
export const POST_LIFE_SHERPA_LOGIN = 'POST_LIFE_SHERPA_LOGIN'
export const POST_LIFE_SHERPA_LOGIN_SUCCESS = 'POST_LIFE_SHERPA_LOGIN_SUCCESS'
export const GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION'
export const GET_SUCCESS_USER_ORGANIZATION = 'GET_SUCCESS_USER_ORGANIZATION'
export const GET_USER_ORGANIZATION_FROM_TOKEN = 'GET_USER_ORGANIZATION_FROM_TOKEN'
export const GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN = 'GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN'
export const GET_ALLOW_CHANGE_PASSWORD = "GET_ALLOW_CHANGE_PASSWORD"
export const SET_STATUS_COLOR='SET_STATUS_COLOR'
export const SELECT_LANDING_PAGE_MENU_ITEM = 'SELECT_LANDING_PAGE_MENU_ITEM'
export const GET_ALLOWED_MODULE = "GET_ALLOWED_MODULE"
export const GET_ALLOWED_MODULE_SUCCESS = "GET_ALLOWED_MODULE_SUCCESS"
export const GET_SUPER_ADMIN_DATA = 'GET_SUPER_ADMIN_DATA'
export const GET_SUPER_ADMIN_DATA_SUCCESS = 'GET_SUPER_ADMIN_DATA_SUCCESS'
export const GET_GLOBAL_ADMIN_DATA = 'GET_GLOBAL_ADMIN_DATA'
export const GET_GLOBAL_ADMIN_DATA_SUCCESS = 'GET_GLOBAL_ADMIN_DATA_SUCCESS'
export const SHOW_UPDATE_PASSWORD_LOADING = 'SHOW_UPDATE_PASSWORD_LOADING'
export const HIDE_UPDATE_PASSWORD_LOADING = 'HIDE_UPDATE_PASSWORD_LOADING'
export const SHOW_PASSWORD_UPDATE_DIALOG = 'SHOW_PASSWORD_UPDATE_DIALOG'
export const PASSWORD_UPDATED_SUCCESSFULLY = 'PASSWORD_UPDATED_SUCCESSFULLY'
export const PASSWORD_UPDATE_UNSUCCESSFULL = 'PASSWORD_UPDATE_UNSUCCESSFULL'
export const CLEAR_UPDATE_PASSWORD_ERROR = 'CLEAR_UPDATE_PASSWORD_ERROR'
export const CLEAR_PUBLIC_SETTING = 'CLEAR_PUBLIC_SETTING'
export const ANALYTICS_LOGIN = 'ANALYTICS_LOGIN'
export const SHOW_PUSH_NOTIFICATION_ALERT = 'SHOW_PUSH_NOTIFICATION_ALERT'
export const HIDE_PUSH_NOTIFICATION_ALERT = 'HIDE_PUSH_NOTIFICATION_ALERT'
export const SET_CURRENT_USER_TIMEZONE = 'SET_CURRENT_USER_TIMEZONE'
export const SET_SUCCESS_CURRENT_USER_TIMEZONE = 'SET_SUCCESS_CURRENT_USER_TIMEZONE'

export const FETCH_PRIVACY_POLICIES = "FETCH_PRIVACY_POLICIES"
export const FETCH_PRIVACY_POLICIES_SUCCESS = "FETCH_PRIVACY_POLICIES_SUCCESS"
export const SAVED_PRIVACY_POLICIES = "SAVED_PRIVACY_POLICIES"
export const SAVED_PRIVACY_POLICIES_SUCCESS = "SAVED_PRIVACY_POLICIES_SUCCESS"

// Feedbacks    
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS';
export const ON_CHANGE_FEEDBACK_PAGE_TABS = 'ON_CHANGE_FEEDBACK_PAGE_TABS';
export const MAKE_FAVORITE_FEEDBACK = 'MAKE_FAVORITE_FEEDBACK';
export const ON_DELETE_FEEDBACK = 'ON_DELETE_FEEDBACK';
export const VIEW_FEEDBACK_DETAILS = 'VIEW_FEEDBACK_DETAILS';
export const ADD_NEW_FEEDBACK = 'ADD_NEW_FEEDBACK';
export const SHOW_FEEDBACK_LOADING_INDICATOR = 'SHOW_FEEDBACK_LOADING_INDICATOR';
export const HIDE_FEEDBACK_LOADING_INDICATOR = 'HIDE_FEEDBACK_LOADING_INDICATOR';
export const NAVIGATE_TO_BACK = 'NAVIGATE_TO_BACK';
export const REPLY_FEEDBACK = 'REPLY_FEEDBACK';
export const SEND_REPLY = 'SEND_REPLY';
export const UPDATE_SEARCH_IDEA = 'UPDATE_SEARCH_IDEA';
export const ON_SEARCH_IDEA = 'ON_SEARCH_IDEA';
export const ON_COMMENT_FEEDBACK = 'ON_COMMENT_FEEDBACK';
// ecommerce
export const ON_DELETE_ITEM_FROM_CART = 'ON_DELETE_ITEM_FROM_CART';
export const ON_QUANTITY_CHANGE = 'ON_QUANTITY_CHANGE';
export const ON_ADD_ITEM_TO_CART = 'ON_ADD_ITEM_TO_CART';
//crm 
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';

// Activity History
export const SET_ACTIVITY_HISTORY_DETAILS = 'SET_ACTIVITY_HISTORY_DETAILS';
export const SET_ACTIVITY_CONFIRMATION = 'SET_ACTIVITY_CONFIRMATION';
export const SET_ACTIVITIES_REMINDER = 'SET_ACTIVITIES_REMINDER'
export const SET_SUCCESS_ACTIVITIES_REMINDER = 'SET_SUCCESS_ACTIVITIES_REMINDER'
export const GET_REALTIME_ACTIVITY_DETAIL = 'GET_REALTIME_ACTIVITY_DETAIL'
export const GET_SUCCESS_REALTIME_ACTIVITY_DETAIL = 'GET_SUCCESS_REALTIME_ACTIVITY_DETAIL'
export const HIDE_ACTIVITY_HISTORY_DETAILS = 'HIDE_ACTIVITY_HISTORY_DETAILS'

export const GET_RECENT_ACTIVITIES = 'GET_RECENT_ACTIVITIES'
export const GET_SELECTED_ACTIVITIES = 'GET_SELECTED_ACTIVITIES'
export const UPDATE_ACTIVITIES_SEARCH = 'UPDATE_ACTIVITIES_SEARCH'
export const SEARCH_ACTIVITIES = 'SEARCH_ACTIVITIES'
export const GET_ACTIVITIES_LIST = 'GET_ACTIVITIES_LIST'
export const GET_SUCCESS_ACTIVITIES_LIST = 'GET_SUCCESS_ACTIVITIES_LIST'
export const GET_ACTIVITIES_LIST_DETAIL = 'GET_ACTIVITIES_LIST_DETAIL'
export const GET_SUCCESS_ACTIVITIES_LIST_DETAIL = 'GET_SUCCESS_ACTIVITIES_LIST_DETAIL'
export const RESET_ACTIVITIES_LIST = 'RESET_ACTIVITIES_LIST'
export const SHOW_PRIVATE_ACTIVITY_LOADING = 'SHOW_PRIVATE_ACTIVITY_LOADING'
export const HIDE_PRIVATE_ACTIVITY_LOADING = 'HIDE_PRIVATE_ACTIVITY_LOADING'
export const SHOW_LOADING_INDICATOR_ACTIVITIES_LIST = 'SHOW_LOADING_INDICATOR_ACTIVITIES_LIST'
export const HIDE_LOADING_INDICATOR_ACTIVITIES_LIST = 'HIDE_LOADING_INDICATOR_ACTIVITIES_LIST'
export const GET_ACTIVITY_FILTERS='GET_ACTIVITY_FILTERS'
export const GET_NETWORK_ACTIVITY='GET_NETWORK_ACTIVITY'
export const GET_NETWORK_ACTIVITY_SUCCESS = "GET_NETWORK_ACTIVITY_SUCCESS"
export const GET_LAUNCHPAD='GET_LAUNCHPAD'
export const GET_ACTIVITY_CALENDER='GET_ACTIVITY_CALENDER'
export const GET_LIST_STYLE='GET_LIST_STYLE'
export const GET_SUMMERY_ACTIVITY='GET_SUMMERY_ACTIVITY'
export const REMOVE_SUMMERY_ACTIVITY='REMOVE_SUMMERY_ACTIVITY'
export const ACTIVITY_START_TIME='ACTIVITY_START_TIME'
export const REMOVE_RESUME_ACTIVITY='REMOVE_RESUME_ACTIVITY'
export const REMOVE_TIMER_ACTIVITY='REMOVE_TIMER_ACTIVITY'
export const GET_REALTIME_ACTIVITY_DETAIL1='GET_REALTIME_ACTIVITY_DETAIL1'
export const PRIVATE_ACTIVITIES_LIST='PRIVATE_ACTIVITIES_LIST'
export const SELECT_ACTIVITY_ID='SELECT_ACTIVITY_ID'
export const GET_PRIVATE_LIST='GET_PRIVATE_LIST'
export const GET_COURES='GET_COURES'
export const REMOVE_COURES='REMOVE_COURES'
export const GET_PINRESPONSE='GET_PINRESPONSE'
export const REMOVE_PINRESPONSE='REMOVE_PINRESPONSE'
export const GET_WPQLINK='GET_WPQLINK'

//Activity history
export const GET_RECENT_HISTORY = 'GET_RECENT_HISTORY'
export const GET_SELECTED_HISTORY = 'GET_SELECTED_HISTORY'
export const UPDATE_HISTORY_SEARCH = 'UPDATE_HISTORY_SEARCH'
export const SEARCH_HISTORY = 'SEARCH_HISTORY'
export const GET_HISTORY_LIST = 'GET_HISTORY_LIST'
export const GET_SUCCESS_HISTORY_LIST = 'GET_SUCCESS_HISTORY_LIST' 
export const SET_HISTORY_LIST_PAGE_COUNT = 'SET_HISTORY_LIST_PAGE_COUNT'
export const SET_ACTIVITIES_INPROGESS = 'SET_ACTIVITIES_INPROGESS'
export const GET_ACHEIVEMENTS_DETAIL = 'GET_ACHEIVEMENTS_DETAIL'
export const GET_SUCCESS_ACHEIVEMENTS_DETAIL = 'GET_SUCCESS_ACHEIVEMENTS_DETAIL'
export const POST_EVALUATION_FORM = 'POST_EVALUATION_FORM'
export const POST_SUCCESS_EVALUATION_FORM = 'POST_SUCCESS_EVALUATION_FORM'
export const POST_STEPS_NOTES_FORM = 'POST_STEPS_NOTES_FORM'
export const SET_ACTIVITIES_RESET = 'SET_ACTIVITIES_RESET'
export const SET_SUCCESS_ACTIVITIES_RESET = 'SET_SUCCESS_ACTIVITIES_RESET'
export const POST_SUCCESS_STEPS_NOTES_FORM = 'POST_SUCCESS_STEPS_NOTES_FORM'
export const SET_SELECTED_STEP_ID = 'SET_SELECTED_STEP_ID'
export const SET_ACHIEVEMENTS_HAS_MORE_DATA = 'SET_ACHIEVEMENTS_HAS_MORE_DATA'
export const SET_ACHIEVEMENTS_PAGE_COUNT = 'SET_ACHIEVEMENTS_PAGE_COUNT'
export const SET_ACHIEVEMENTS_DATA_CLEAR = 'SET_ACHIEVEMENTS_DATA_CLEAR'
export const SHOW_LOADING_INDICATOR_HISTORY_DETAIL = 'SHOW_LOADING_INDICATOR_HISTORY_DETAIL'
export const HIDE_LOADING_INDICATOR_HISTORY_DETAIL = 'HIDE_LOADING_INDICATOR_HISTORY_DETAIL'
export const SHOW_LOADING_INDICATOR_HISTORY_LIST = 'SHOW_LOADING_INDICATOR_HISTORY_LIST'
export const HIDE_LOADING_INDICATOR_HISTORY_LIST = 'HIDE_LOADING_INDICATOR_HISTORY_LIST'
export const USER_CONFIRM_ACHEIVEMENT = 'USER_CONFIRM_ACHEIVEMENT'
export const USER_CONFIRM_ACHEIVEMENT_SUCCESS = 'USER_CONFIRM_ACHEIVEMENT_SUCCESS'

//Clients List
export const CHAT_WITH_SELECTED_CLIENT = 'CHAT_WITH_SELECTED_CLIENT';
export const SEND_MESSAGE_TO_CLIENT = 'SEND_MESSAGE_TO_CLIENT';
export const UPDATE_CLIENTS_SEARCH = 'UPDATE_CLIENTS_SEARCH';
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS';
export const GET_RECENT_CHAT_CLIENTS = 'GET_RECENT_CHAT_CLIENTS';
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const GET_SUCCESS_CLIENTS_LIST = 'GET_SUCCESS_CLIENTS_LIST';
export const SHOW_LOADING_INDICATOR_CLIENT_LIST = 'SHOW_LOADING_INDICATOR_CLIENT_LIST';
export const HIDE_LOADING_INDICATOR_CLIENT_LIST = 'HIDE_LOADING_INDICATOR_CLIENT_LIST';
export const GET_CLIENT_STATUS='GET_CLIENT_STATUS'
export const MUTE_CLIENT_NOTIFICATIONS = 'MUTE_CLIENT_NOTIFICATIONS'
export const MUTE_CLIENT_NOTIFICATIONS_SUCCESS = 'MUTE_CLIENT_NOTIFICATIONS_SUCCESS'
export const GET_CLIENT_MUTE_NOTIFICATION = 'GET_CLIENT_MUTE_NOTIFICATION'
export const GET_CLIENT_MUTE_NOTIFICATION_SUCCESS = 'GET_CLIENT_MUTE_NOTIFICATION_SUCCESS'
export const GET_CLIENT_DETAIL_BY_ID = 'GET_CLIENT_DETAIL_BY_ID'
export const GET_SUCCESS_CLIENT_DETAIL_BY_ID = 'GET_SUCCESS_CLIENT_DETAIL_BY_ID'

export const FETCH_USER_ORGANIZATIONS = "FETCH_USER_ORGANIZATIONS"
export const FETCH_USER_ORGANIZATIONS_SUCCESS = "FETCH_USER_ORGANIZATIONS_SUCCESS"
export const APPLY_ORGANIZATIONS_FILTER_AT_CLIENT = "APPLY_ORGANIZATIONS_FILTER_AT_CLIENT"
export const APPLY_ORGANIZATIONS_FILTER_AT_ACTIVITIES = "APPLY_ORGANIZATIONS_FILTER_AT_ACTIVITIES"
export const APPLY_ORGANIZATIONS_FILTER_AT_COURSES = "APPLY_ORGANIZATIONS_FILTER_AT_COURSES"
export const APPLY_ORGANIZATIONS_FILTER_AT_RESOURCES = "APPLY_ORGANIZATIONS_FILTER_AT_RESOURCES"
export const CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT = "CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT"
export const CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES = "CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES"
export const CLEAR_ORGANIZATIONS_FILTER_FROM_COURSES = "CLEAR_ORGANIZATIONS_FILTER_FROM_COURSES"
export const CLEAR_ORGANIZATIONS_FILTER_FROM_RESOURCES ="CLEAR_ORGANIZATIONS_FILTER_FROM_RESOURCES"
export const CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW = "CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW"
export const APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW = "APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW"
export const APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW_SUMMARY = "APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW_SUMMARY"
export const CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW_SUMMARY = "CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW_SUMMARY"
export const SAVE_WORKFLOW_ENABLE_STATUS = "SAVE_WORKFLOW_ENABLE_STATUS"

//User Account Details
export const SET_USER_DETAIL = 'SET_USER_DETAIL';

//Group List
export const GET_RECENT_GROUP = 'GET_RECENT_GROUP'
export const GET_SELECTED_GROUP = 'GET_SELECTED_GROUP'
export const UPDATE_GROUP_SEARCH = 'UPDATE_GROUP_SEARCH'
export const SEARCH_GROUP = 'SEARCH_GROUP'
export const GET_GROUP_LIST = 'GET_GROUP_LIST'
export const GET_SUCCESS_GROUP_LIST = 'GET_SUCCESS_GROUP_LIST'
export const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS'
export const GET_SUCCESS_GROUP_MEMBERS = 'GET_SUCCESS_GROUP_MEMBERS'
export const SHOW_LOADING_INDICATOR_GROUP_LIST = 'SHOW_LOADING_INDICATOR_GROUP_LIST'
export const HIDE_LOADING_INDICATOR_GROUP_LIST = 'HIDE_LOADING_INDICATOR_GROUP_LIST'
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP'
export const SET_SUCCESS_SELECTED_GROUP = 'SET_SUCCESS_SELECTED_GROUP'
export const GET_GROUP_MEMBERS_FROM_API = 'GET_GROUP_MEMBERS_FROM_API'
export const GET_GROUP_MEMBERS_FROM_API_SUCCESS = 'GET_GROUP_MEMBERS_FROM_API_SUCCESS'

//Poke Form 
export const GET_CLIENT_POKE_FORM = 'GET_CLIENT_POKE_FORM'
export const GET_CLIENT_SUCCESS_POKE_FORM = 'GET_CLIENT_SUCCESS_POKE_FORM'
export const SET_CLIENT_POKE_FORM = 'SET_CLIENT_POKE_FORM'
export const SET_CLIENT_SUCCESS_POKE_FORM = 'SET_CLIENT_SUCCESS_POKE_FORM'
export const UPDATE_CLIENT_POKE_FORM = 'UPDATE_CLIENT_POKE_FORM'
export const UPDATE_CLIENT_SUCCESS_POKE_FORM = 'UPDATE_CLIENT_SUCCESS_POKE_FORM'
export const SET_GROUP_SUCCESS_POKE_FORM = 'SET_GROUP_SUCCESS_POKE_FORM'
export const SET_GROUP_POKE_FORM = 'SET_GROUP_POKE_FORM'
export const SET_SELECTED_POKE_FOR_GROUP = 'SET_SELECTED_POKE_FOR_GROUP'
export const SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM = 'SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM'
export const HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM = 'HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM'

//Status
export const GET_STATUS='GET_STATUS'
export const UPDATE_USER_STATUS='UPDATE_USER_STATUS'
export const REMOVE_STATUS='REMOVE_STATUS'

//Unconfirmed Activities
export const SET_SELECTED_UNCONFIRM_ACTIVITIES = 'SET_SELECTED_UNCONFIRM_ACTIVITIES'
export const SET_UNCONFIRM_ACTIVITIES_LIST = 'SET_UNCONFIRM_ACTIVITIES_LIST'
export const SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST = 'SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST'
export const SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL = 'SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL'
export const SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL = 'SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL'
export const SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA = 'SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA'
export const SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT = 'SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT'
export const SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR = 'SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR'
export const SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST = 'SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST'
export const HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST = 'HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST'

export const GET_USER_GEOLOCATION = 'GET_USER_GEOLOCATION'
export const GET_SUCCESS_USER_GEOLOCATION = 'GET_SUCCESS_USER_GEOLOCATION'
export const SHOW_LOCATION_LOADING = 'SHOW_LOCATION_LOADING'
export const HIDE_LOCATION_LOADING = 'HIDE_LOCATION_LOADING'
export const UNCONFIRM_loading='UNCONFIRM_loading'

//ACTIVITY PREVIEW

export const ADD_ACTIVITY_SCHEDULE = 'ADD_ACTIVITY_SCHEDULE'
export const ADD_ACTIVITY_SCHEDULE_SUCCESS = 'ADD_ACTIVITY_SCHEDULE_SUCCESS'
export const  DELETE_ACTIVITY_SCHEDULE = 'DELETE_ACTIVITY_SCHEDULE'
export const DELETE_ACTIVITY_SCHEDULE_SUCCESS = 'DELETE_ACTIVITY_SCHEDULE_SUCCESS'
export const UPDATE_ACTIVITY_SCHEDULE = 'UPDATE_ACTIVITY_SCHEDULE'
export const UPDATE_ACTIVITY_SCHEDULE_SUCCESS = 'UPDATE_ACTIVITY_SCHEDULE_SUCCESS'
export const ADD_ACTIVITY_STEP = 'ADD_ACTIVITY_STEP'
export const ADD_ACTIVITY_STEP_SUCCESS = 'ADD_ACTIVITY_STEP_SUCCESS'
export const DELETE_ACTIVITY_STEP = 'DELETE_ACTIVITY_STEP'
export const DELETE_ACTIVITY_STEP_SUCCESS = 'DELETE_ACTIVITY_STEP_SUCCESS'
export const UPDATE_ACTIVITY_STEP = 'UPDATE_ACTIVITY_STEP'
export const UPDATE_ACTIVITY_STEP_SUCCESS = 'UPDATE_ACTIVITY_STEP_SUCCESS'
 
//NEW ACTIVITY

export const ADD_NEW_ACTIVITY = 'ADD_NEW_ACTIVITY'
export const ADD_NEW_ACTIVITY_SUCCESS = 'ADD_NEW_ACTIVITY_SUCCESS'
export const UPDATE_NEW_ACTIVITY = 'UPDATE_NEW_ACTIVITY'
export const UPDATE_NEW_ACTIVITY_SUCCESS = 'UPDATE_NEW_ACTIVITY_SUCCESS'
export const DELTETE_NEW_ACTIVITY = 'DELTETE_NEW_ACTIVITY'
export const DELETE_NEW_ACTIVITY_SUCCESS = 'DELETE_NEW_ACTIVITY_SUCCESS'
export const UPDATE_NEW_ACTIVITY_ALL_STEPSS = 'UPDATE_NEW_ACTIVITY_ALL_STEPSS'
export const UPDATE_NEW_ACTIVITY_ALL_STEPSS_SUCCESS = 'UPDATE_NEW_ACTIVITY_ALL_STEPSS_SUCCESS'

export const ADD_PRIVATE_ACTIVITY = 'ADD_PRIVATE_ACTIVITY'
export const ADD_PRIVATE_ACTIVITY_SUCCESS = 'ADD_PRIVATE_ACTIVITY_SUCCESS'
export const UPDATE_PRIVATE_ACTIVITY = 'UPDATE_PRIVATE_ACTIVITY'
export const UPDATE_PRIVATE_ACTIVITY_SUCCESS = 'UPDATE_PRIVATE_ACTIVITY_SUCCESS'
export const GET_PRIVATE_ACTIVITY = 'GET_PRIVATE_ACTIVITY'
export const GET_PRIVATE_ACTIVITY_SUCCESS = 'GET_PRIVATE_ACTIVITY_SUCCESS'
export const CHECK_DUPLICATE_ACTITY_TITLE = 'CHECK_DUPLICATE_ACTITY_TITLE'
export const CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE = 'CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE'
export const CHECK_FAILURE_DUPLICATE_ACTITY_TITLE = 'CHECK_FAILURE_DUPLICATE_ACTITY_TITLE'
export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA'
export const REMOVE_API_ERROR_MESSAGE = 'REMOVE_API_ERROR_MESSAGE'
export const SHOW_ACTIVITY_CRETAED_MESSAGE = 'SHOW_ACTIVITY_CRETAED_MESSAGE'
export const OPEN_EDITOR='OPEN_EDITOR'
export const SELECT_ACTIVTY='SELECT_ACTIVTY'

//ACTIVITY TEMPLATE
export const GET_ACTIVITY_TEMPLATE_LIST = 'GET_ACTIVITY_TEMPLATE_LIST'
export const GET_SUCCESS_ACTIVITY_TEMPLATE_LIST = 'GET_SUCCESS_ACTIVITY_TEMPLATE_LIST'
export const GET_ACTIVITY_TEMPLATE_GROUPS = 'GET_ACTIVITY_TEMPLATE_GROUPS'
export const GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS = 'GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS'
export const SHOW_ACTIVITY_TEMPLATE_LOADING = 'SHOW_ACTIVITY_TEMPLATE_LOADING'
export const HIDE_ACTIVITY_TEMPLATE_LOADING = 'HIDE_ACTIVITY_TEMPLATE_LOADING'
export const CLEAR_ACTIVITY_TEMPLATE_DATA = 'CLEAR_ACTIVITY_TEMPLATE_DATA'
export const REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE = 'REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE'
export const ACTIVITY_TEMPLATE_SERVER_ERROR = 'ACTIVITY_TEMPLATE_SERVER_ERROR'
export const SELECT_ACTIVITY_TEMPLATE = 'SELECT_ACTIVITY_TEMPLATE'
export const FILTER_ACTIVITY_TEMPLATE = 'FILTER_ACTIVITY_TEMPLATE'
export const SELECT_ACTIVITY_TEMPLATE_GROUP = 'SELECT_ACTIVITY_TEMPLATE_GROUP'

// REWARDS
export const GET_BUY_REWARDS='GET_BUY_REWARDS'
export const GET_PENDING_REWARDS='GET_PENDING_REWARDS'
export const GET_RECIVED_REWARDS='GET_RECIVED_REWARDS'
export const CREDIT_AFTER_BUY_REWARD='CREDIT_AFTER_BUY_REWARD'
export const BUY_NEW_REWARD='BUY_NEW_REWARD'
export const BUY_NEW_REWARD_SUCCESS='BUY_NEW_REWARD_SUCCESS'
export const CONFIRM_REWARDS = 'CONFIRM_REWARDS'
export const CONFIRM_REARDS_SUCCESS = 'CONFIRM_REARDS_SUCCESS'
 
//Resources
export const GET_Resource_List='GET_Resource_List'
export const GET_categories='GET_categories'
export const GET_KEYWORDS='GET_KEYWORDS'
export const GET_RESOURCES_FILTERS = 'GET_RESOURCES_FILTERS'


//contact
export const GET_HELP_CONTACTS='GET_HELP_CONTACTS'
export const GET_HELP_CONTACTS_SUCCESS='GET_HELP_CONTACTS_SUCCESS'
export const GET_VIDEO_GROUPS='GET_VIDEO_GROUPS'
export const GET_VIDEO_GROUPS_SUCCESS='GET_VIDEO_GROUPS_SUCCESS'
export const GET_VIDEO_GROUPS_FAILED='GET_VIDEO_GROUPS_FAILED'
export const SELECT_VIDEO_GROUPS='SELECT_VIDEO_GROUPS'
export const SELECTED_VIDEO_GROUPS='SELECTED_VIDEO_GROUPS'
export const GET_CHAT_TOKEN='GET_CHAT_TOKEN'
export const GET_CHANNEL_INFO='GET_CHANNEL_INFO'
export const GET_VIDEO_TOKEN='GET_VIDEO_TOKEN'
export const GET_STATUS_SECTIONS='GET_STATUS_SECTIONS'
export const GET_CHAT_LOADING='GET_CHAT_LOADING'

//START ACTIVITY
export const GET_START_ACTIVITY='GET_START_ACTIVITY'
export const GET_RESUME_ACTIVITY='GET_RESUME_ACTIVITY'
export const GET_PAUSE_ACTIVITY='GET_PAUSE_ACTIVITY'
export const GET_END_ACTIVITY='GET_END_ACTIVITY'
export const START_ACTIVITY='START_ACTIVITY'
export const GET_CANCEL_ACTIVITY='GET_CANCEL_ACTIVITY'
export const GET_START_ACTIVITY_DETAILS='GET_START_ACTIVITY_DETAILS'
export const GET_RESUME_ACTIVITY_DETAILS='GET_RESUME_ACTIVITY_DETAILS'
export const GET_LOADING='GET_LOADING'
export const EDITOR_LOADING='EDITOR_LOADING'
export const ACTIVITY_PREVIEW_LOADING='ACTIVITY_PREVIEW_LOADING'
export const GET_ENDSTEP='GET_ENDSTEP'
export const STOP_LOADING='STOP_LOADING'
export const SET_ACTIVITY_TYPE='SET_ACTIVITY_TYPE'
export const CLEAR_START_ACTIVITY_DETAILS = 'CLEAR_START_ACTIVITY_DETAILS'
export const CATCH_API_ERROR = 'CATCH_API_ERROR'
export const SHOW_EXECUTE_ACTIVITY_PANEL = 'SHOW_EXECUTE_ACTIVITY_PANEL'

//Chart
export const GET_CHARTS_LIST='GET_CHARTS_LIST'
export const GET_SUCCESS_CHARTS_LIST='GET_SUCCESS_CHARTS_LIST'
export const GET_CHART_BY_CHART_ID='GET_CHART_BY_CHART_ID'
export const GET_SUCCESS_CHART_BY_CHART_ID='GET_SUCCESS_CHART_BY_CHART_ID'
export const FILTER_CHART_DATA='FILTER_CHART_DATA'
export const FILTER_SUCCESS_CHART_DATA='FILTER_SUCCESS_CHART_DATA'
export const SHOW_CHARTS_LOADING='SHOW_CHARTS_LOADING'
export const HIDE_CHARTS_LOADING='HIDE_CHARTS_LOADING'

//Notifications
export const GET_USER_NOTIFICATIONS_HISTORY = "GET_USER_NOTIFICATIONS_HISTORY"
export const GET_SUCCESS_USER_NOTIFICATIONS_HISTORY = "GET_SUCCESS_USER_NOTIFICATIONS_HISTORY"
export const SHOW_USER_NOTIFICATIONS_HISTORY_LOADING = "SHOW_USER_NOTIFICATIONS_HISTORY_LOADING"
export const HIDE_USER_NOTIFICATIONS_HISTORY_LOADING = "HIDE_USER_NOTIFICATIONS_HISTORY_LOADING"
export const SHOW_NOTIFICATION_POPUP = "SHOW_NOTIFICATION_POPUP";
export const HIDE_NOTIFICATION_POPUP = "HIDE_NOTIFICATION_POPUP";


//LSM Courses
export const GET_MY_COURSES = "GET_MY_COURSES"
export const GET_SUCCESS_MY_COURSES = "GET_SUCCESS_MY_COURSES"
export const SHOW_LOADING_INDICATOR_MY_COURSES = "SHOW_LOADING_INDICATOR_MY_COURSES"
export const HIDE_LOADING_INDICATOR_MY_COURSES = "HIDE_LOADING_INDICATOR_MY_COURSES"
export const GET_MY_COURSE_LAUNCH_URL = 'GET_MY_COURSE_LAUNCH_URL'
export const GET_SUCCESS_MY_COURSES_LAUNCH_URL = 'GET_SUCCESS_MY_COURSES_LAUNCH_URL'
export const SHOW_LMS_MODULE = "SHOW_LMS_MODULE"
export const HIDE_LMS_MODULE = "HIDE_LMS_MODULE"
export const SELECT_COURSE_ITEM = "SELECT_COURSE_ITEM"
export const CLOSE_COURSE_IFRAME = "CLOSE_COURSE_IFRAME"

//My Activity
export const GET_MY_ACTIVITY_BY_ID = "GET_MY_ACTIVITY_BY_ID"
export const GET_SUCCESS_MY_ACTIVITY_BY_ID = "GET_SUCCESS_MY_ACTIVITY_BY_ID"
export const SHOW_MY_ACTIVITY_LOADING = "SHOW_MY_ACTIVITY_LOADING"
export const HIDE_MY_ACTIVITY_LOADING = "HIDE_MY_ACTIVITY_LOADING"
export const HIDE_STATUS_LOADING = "HIDE_STATUS_LOADING"
export const GET_LOADING_FOR_COURSE = "GET_LOADING_FOR_COURSE"
export const START_STEP_SLIDER_LOADING = "START_STEP_SLIDER_LOADING"
export const STOP_STEP_SLIDER_LOADING = "STOP_STEP_SLIDER_LOADING"
export const SELECT_ACTIVITY_FILTER_GROUP = "SELECT_ACTIVITY_FILTER_GROUP"

//RESET REDUX DATA 
export const USER_LOGGED_OUT_RESET_REDUX = 'USER_LOGGED_OUT_RESET_REDUX';
export const SET_FORM_STATUS_DATA = 'SET_FORM_STATUS_DATA';

//UnActiveScreenTimer
export const USER_INACTIVITY_CONTINUE = 'USER_INACTIVITY_CONTINUE';
export const USER_INACTIVITY_LOGOUT = 'USER_INACTIVITY_LOGOUT';
export const USER_INACTIVITY_AUTOLOGOUT = 'USER_INACTIVITY_AUTOLOGOUT';
export const USER_INACTIVITY_TIMER_UPDATE = 'USER_INACTIVITY_TIMER_UPDATE';
export const HANDLE_UNACTIVE_ALERT = 'HANDLE_UNACTIVE_ALERT';
export const USER_SET_INACTIVE_TIMER_VALUE = 'USER_SET_INACTIVE_TIMER_VALUE';
export const POST_LIFE_SHERPA_FAILED_CREATE_USER = 'POST_LIFE_SHERPA_FAILED_CREATE_USER';
export const HIDE_CREATE_USER_FAILED_DATA = 'HIDE_CREATE_USER_FAILED_DATA';
export const CLEAR_GET_VALIDATE_INVITE_CODE_RESPONSE = 'CLEAR_GET_VALIDATE_INVITE_CODE_RESPONSE';
export const GET_FAILED_USER_FIREBASE_TOKEN = 'GET_FAILED_USER_FIREBASE_TOKEN';

export const GET_THEME_LS_LOGO = 'GET_THEME_LS_LOGO';
export const GET_THEME_LS_LOGO_SUCCESS = 'GET_THEME_LS_LOGO_SUCCESS';
export const GET_THEME_LS_LOGO_FAILED = 'GET_THEME_LS_LOGO_FAILED';
export const SET_DYNAMIC_THEME_CLASS = 'SET_DYNAMIC_THEME_CLASS';
export const SET_NEW_REPORT_NOT_FOUND = 'SET_NEW_REPORT_NOT_FOUND';
export const SET_RESOURCES_SELECTED_LIST = 'SET_RESOURCES_SELECTED_LIST';
export const CLEAR_RESOURCES_REPORTS_DATA = 'CLEAR_RESOURCES_REPORTS_DATA';
export const SHOW_ASSIGN_ACTIVITY_IFRAME_PANEL = 'SHOW_ASSIGN_ACTIVITY_IFRAME_PANEL';

export const OPENED_CONFIGURATOR_IN_LSPORTAL = 'OPENED_CONFIGURATOR_IN_LSPORTAL';
export const OPENED_LMS_IN_LSPORTAL = 'OPENED_LMS_IN_LSPORTAL';
export const CLEAR_INACTIVITY_IDLE_DATA = 'CLEAR_INACTIVITY_IDLE_DATA';

export const STORE_IN_ACTIVE_SET_INTERVAL_DATA = 'STORE_IN_ACTIVE_SET_INTERVAL_DATA';

export const REMOVE_IN_ACTIVE_SET_INTERVAL_DATA = 'REMOVE_STORE_IN_ACTIVE_SET_INTERVAL_DATA';
export const ENABLE_DFAULT_AUTH0_LOGIN_STATUS = 'ENABLE_DFAULT_AUTH0_LOGIN_STATUS';
export const DISABLE_DFAULT_AUTH0_LOGIN_STATUS = 'DISABLE_DFAULT_AUTH0_LOGIN_STATUS';
export const SET_USA_AUTH0_ENABLED = 'SET_USA_AUTH0_ENABLED';
export const SET_AUSTRALIA_AUTH0_ENABLED = 'SET_AUSTRALIA_AUTH0_ENABLED';
//workspace 
export const GET_WORKSPACE_IS_AVAILABLE = 'GET_WORKSPACE_IS_AVAILABLE';

export const GET_ACTIVITY_GROUPS_LIST = 'GET_ACTIVITY_GROUPS_LIST';
export const GET_ACTIVITY_GROUPS_LIST_SUCCESS = 'GET_ACTIVITY_GROUPS_LIST_SUCCESS';
export const GET_ACTIVITY_GROUPS_LIST_FAILED = 'GET_ACTIVITY_GROUPS_LIST_FAILED';

export const SHOW_ANALYTICS_MENU = 'SHOW_ANALYTICS_MENU'
export const HIDE_ANALYTICS_MENU = 'HIDE_ANALYTICS_MENU'

export const SHOW_CHAT_PANEL_FOR_SELCTED_USER = 'SHOW_CHAT_PANEL_FOR_SELCTED_USER';
export const HIDE_CHAT_PANEL = 'HIDE_CHAT_PANEL';
export const REMOVE_SELECTED_CHAT_USER = 'REMOVE_SELECTED_CHAT_USER';
export const ADD_SELECTED_CHAT_USER = 'ADD_SELECTED_CHAT_USER';
export const SELECTED_CHAT_USERS_UPDATE = 'SELECTED_CHAT_USERS_UPDATE';
export const GET_HELP_CONTACTS_FAILED = 'GET_HELP_CONTACTS_FAILED';
export const HIDE_CHAT_LOADING = 'HIDE_CHAT_LOADING';

export const UPDATE_CONTACTS_LIST = 'UPDATE_CONTACTS_LIST';
export const SET_TWILIO_CLIENT = 'SET_TWILIO_CLIENT';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const REMOVE_SELECTED_USER = 'REMOVE_SELECTED_USER';

export const SHOW_VIDEO_ROOM = 'SHOW_VIDEO_ROOM';
export const HIDE_VIDEO_ROOM = 'HIDE_VIDEO_ROOM';
export const GET_VIDEO_CALL_DETAILS_SUCCESS = 'GET_VIDEO_CALL_DETAILS_SUCCESS';
export const SHOW_VIDEO_CALL_CONFIRMATION = 'SHOW_VIDEO_CALL_CONFIRMATION';
export const HIDE_VIDEO_CALL_CONFIRMATION = 'HIDE_VIDEO_CALL_CONFIRMATION';
export const CONNECT_TWILIO_VIDEO_CALL = 'CONNECT_TWILIO_VIDEO_CALL';
export const CONNECT_TWILIO_VIDEO_CALL_SUCCESS = 'CONNECT_TWILIO_VIDEO_CALL_SUCCESS';

export const GET_VIDOE_TOKEN_LOADING = 'GET_VIDOE_TOKEN_LOADING';
export const HIDE_VIDOE_TOKEN_LOADING = 'HIDE_VIDOE_TOKEN_LOADING';

export const GET_SELECTED_VIDEO_CAHT = 'GET_SELECTED_VIDEO_CAHT';
export const SHOW_MISSED_CALL_CONFIRMATION = 'SHOW_MISSED_CALL_CONFIRMATION';
export const HIDE_MISSED_CALL_CONFIRMATION = 'HIDE_MISSED_CALL_CONFIRMATION';

export const EXECUTE_ACTIVITY = 'EXECUTE_ACTIVITY';

export const SET_ACTIVITIES_ACHIEVEMENTS_LIST = 'SET_ACTIVITIES_ACHIEVEMENTS_LIST';

export const GET_WPQ_LOADING = 'GET_WPQ_LOADING';
export const HIDE_WPQ_LOADING = 'HIDE_WPQ_LOADING';

export const HANDLE_SHOW_PHOTO_CAMERA = 'HANDLE_SHOW_PHOTO_CAMERA';
//WORK SPACE 
export const GET_WORKSPACE_ADMIN_DATA = 'GET_WORKSPACE_ADMIN_DATA';
export const GET_WORKSPACE_ADMIN_DATA_SUCCESS = 'GET_WORKSPACE_ADMIN_DATA_SUCCESS';
export const ADD_NEW_VIDEO_GROUP_DETAILS = 'ADD_NEW_VIDEO_GROUP_DETAILS';
export const ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS = 'ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS';
export const ADD_NEW_VIDEO_GROUP_DETAILS_FAILED = 'ADD_NEW_VIDEO_GROUP_DETAILS_FAILED';
export const SET_SELECTED_GROUP_VIDEO_CALL_DETAILS = 'SET_SELECTED_GROUP_VIDEO_CALL_DETAILS';

export const SET_FILTERED_ACTIVITIES_DATA = 'SET_FILTERED_ACTIVITIES_DATA';

export const SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL = 'SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL';
export const HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL = 'HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL';

export const HANDEL_EXECUTE_ACTIVITY_CONFIRMATION = 'HANDEL_EXECUTE_ACTIVITY_CONFIRMATION';

export const SHOW_ACTIVITY_DETAILS_PANEL = 'SHOW_ACTIVITY_DETAILS_PANEL';
export const HIDE_ACTIVITY_DETAILS_PANEL = 'HIDE_ACTIVITY_DETAILS_PANEL';

export const SHOW_UNCONFIRMED_ACHIEVEMENTS = 'SHOW_UNCONFIRMED_ACHIEVEMENTS';
// Adjust Timer
export const ADJUST_ACHIEVEMENT_TIMER = 'ADJUST_ACHIEVEMENT_TIMER';
export const ADJUST_ACHIEVEMENT_TIMER_SUCCESS = 'ADJUST_ACHIEVEMENT_TIMER_SUCCESS';
export const ADJUST_ACHIEVEMENT_TIMER_FAILED = 'ADJUST_ACHIEVEMENT_TIMER_FAILED';

//Users Encounter Type Activities
export const GET_USERS_ECOUNTER_TYPES_ACTIVITIES = 'GET_USERS_ECOUNTER_TYPES_ACTIVITIES'
export const GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS = 'GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS'
export const MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA = 'MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA'
export const MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS = 'MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS'

export const HANDLE_ENCOUNTER_ACTIVITY_HISTORY = 'HANDLE_ENCOUNTER_ACTIVITY_HISTORY'
export const HANDLE_ENCOUNTER_ACTIVITY_DETAILS = 'HANDLE_ENCOUNTER_ACTIVITY_DETAILS'
export const HANDLE_ENCOUNTER_ACTIVITY_EXECUTION = 'HANDLE_ENCOUNTER_ACTIVITY_EXECUTION'


export const SET_CLIENT_ACTIVITIES_TYPE = 'SET_CLIENT_ACTIVITIES_TYPE'

export const GET_ECOUNTER_ACTIVITIES_SUMMARY = 'GET_ECOUNTER_ACTIVITIES_SUMMARY'
export const GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS = 'GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS'
export const GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED = 'GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED'
export const GET_SUMMARY_LOADING = "GET_SUMMARY_LOADING";
export const CLEAR_REWARDS_DATA = "CLEAR_REWARDS_DATA";

export const GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED";

export const REALTIME_UPDATE_ACTIVITIES = "REALTIME_UPDATE_ACTIVITIES";

export const  UNASSIGN_CLIENT_COMMON_ACTIVITY = "UNASSIGN_CLIENT_COMMON_ACTIVITY"
export const  UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS = "UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS"
export const  UNASSIGN_ACTIVITY_TO_CLIENT_FAILED = "UNASSIGN_ACTIVITY_TO_CLIENT_FAILED"
export const  HIDE_UNASSIGN_ACTIVITY_LOADING = "HIDE_UNASSIGN_ACTIVITY_LOADING"

// Notices: 
export const GET_NOTICES_LIST = "GET_NOTICES_LIST";
export const GET_NOTICES_LIST_SUCCESS = "GET_NOTICES_LIST_SUCCESS";
export const GET_NOTICES_LIST_FAILED = "GET_NOTICES_LIST_FAILED";
export const HANDLE_NOTICE_PANEL = "HANDLE_NOTICE_PANEL";
export const HANDLE_USER_WORKFLOW_PANEL = "HANDLE_USER_WORKFLOW_PANEL"
export const FETCH_NOTICE_FORM = "FETCH_NOTICE_FORM";
export const FETCH_NOTICE_FORM_SUCESS = "FETCH_NOTICE_FORM_SUCESS";
export const FETCH_NOTICE_FORM_FAILED = "FETCH_NOTICE_FORM_FAILED";
export const CLEAR_NOTICES_DATA = "CLEAR_NOTICES_DATA"
export const HANDLE_CLOSE_POST_VIEWER = "HANDLE_CLOSE_POST_VIEWER";
export const HANDLE_SHOW_POST_VIEWER = "HANDLE_SHOW_POST_VIEWER";
export const SHOW_POST_LOADING = "SHOW_POST_LOADING";
export const HIDE_POST_LOADING = "HIDE_POST_LOADING";

export const SHOW_NOTIFICATION_LOADING = "SHOW_NOTIFICATION_LOADING";
export const HIDE_NOTIFICATION_LOADING = "HIDE_NOTIFICATION_LOADING";
export const SET_SELECTED_USER_INFO = "SET_SELECTED_USER_INFO";

export const FETCH_NOTICE_HISTORY = "FETCH_NOTICE_HISTORY";
export const FETCH_NOTICE_HISTORY_SUCCESS = "FETCH_NOTICE_HISTORY_SUCCESS";
export const FETCH_NOTICE_HISTORY_FAILED = "FETCH_NOTICE_HISTORY_FAILED";
export const HANDLE_SHOW_CLIENT_DASHBOARD = "HANDLE_SHOW_CLIENT_DASHBOARD";

export const GET_CLIENT_NETWORK_ACTIVITY = "GET_CLIENT_NETWORK_ACTIVITY";
export const GET_CLIENT_NETWORK_ACTIVITY_SUCCESS = "GET_CLIENT_NETWORK_ACTIVITY_SUCCESS";
export const GET_CLIENT_NETWORK_ACTIVITY_FAILED = "GET_CLIENT_NETWORK_ACTIVITY_FAILED";
export const CLEAR_CLIENT_NETWORK_ACTIVITIES_DATA = "CLEAR_CLIENT_NETWORK_ACTIVITIES_DATA";

export const SUBMIT_NOTICE = "SUBMIT_NOTICE";
export const SUBMIT_NOTICE_SUCCESS = "SUBMIT_NOTICE_SUCCESS";
export const SUBMIT_NOTICE_FAILED = "SUBMIT_NOTICE_FAILED";

export const GET_NOTICE_POST = "GET_NOTICE_POST";
export const GET_NOTICE_POST_SUCCESS = "GET_NOTICE_POST_SUCCESS";
export const GET_NOTICE_POST_FAILED = "GET_NOTICE_POST_FAILED";

export const SAVE_USER_HAVING_NO_GROUP = "SAVE_USER_HAVING_NO_GROUP"

//RESET activitiesList, GroupList, Contacts, chatApp
export const RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST = "RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST";
export const SET_SELECTED_NOTICE = "SET_SELECTED_NOTICE";

export const HIDE_THEME_LOADING = "HIDE_THEME_LOADING";
export const HANDLE_SHOW_HELP_PANEL = "HANDLE_SHOW_HELP_PANEL";


export const GET_CLIENT_CONTACTS = "GET_CLIENT_CONTACTS";
export const GET_CLIENT_CONTACTS_SUCCESS = "GET_CLIENT_CONTACTS_SUCCESS";
export const GET_CLIENT_CONTACTS_FAILED = "GET_CLIENT_CONTACTS_FAILED";

export const SAVED_PRIVACY_POLICIES_FAILED = "SAVED_PRIVACY_POLICIES_FAILED";

//General Activity
export const SUCCESS_CHECK_GENERAL_ACTIVITY_PERMISSION = 'SUCCESS_CHECK_GENERAL_ACTIVITY_PERMISSION'
export const CHECK_GENERAL_ACTIVITY_PERMISSION = 'CHECK_GENERAL_ACTIVITY_PERMISSION'
export const FAILED_CHECK_GENERAL_ACTIVITY_PERMISSION = 'FAILED_CHECK_GENERAL_ACTIVITY_PERMISSION'


// COURSES

export const GET_USER_COURSE_PROGRESS = 'GET_USER_COURSE_PROGRESS'
export const GET_USER_COURSE_PROGRESS_SUCCESS = 'GET_USER_COURSE_PROGRESS_SUCCESS'
export const GET_USER_COURSE_PROGRESS_FAILED = 'GET_USER_COURSE_PROGRESS_FAILED'

export const GET_COURSE_GOTO_URL = 'GET_COURSE_GOTO_URL'
export const GET_COURSE_GOTO_URL_SUCCESS = 'GET_COURSE_GOTO_URL_SUCCESS'
export const GET_COURSE_GOTO_URL_SUCCESS_FAILED = 'GET_COURSE_GOTO_URL_SUCCESS_FAILED'
export const CLEAR_COURSE_DATA = 'CLEAR_COURSE_DATA'
export const HANDLE_SHOW_ACTIVITY_COURSE = "HANDLE_SHOW_ACTIVITY_COURSE"
export const SHOW_RESET_COURSE_ALERT = "SHOW_RESET_COURSE_ALERT"


export const GET_GROUP_POKE_FORM = "GET_GROUP_POKE_FORM"
export const GET_GROUP_POKE_FORM_SUCCESS = "GET_GROUP_POKE_FORM_SUCCESS"
export const GET_GROUP_POKE_FORM_FAILED = "GET_GROUP_POKE_FORM_FAILED"


export const GET_POKE_FORM_OF_CLIENT = "GET_POKE_FORM_OF_CLIENT"
export const GET_POKE_FORM_OF_CLIENT_SUCCESS = "GET_POKE_FORM_OF_CLIENT_SUCCESS"
export const GET_POKE_FORM_OF_CLIENT_FAILED = "GET_POKE_FORM_OF_CLIENT_FAILED"

export const OPEN_LAUNCH_PAD_URL = "OPEN_LAUNCH_PAD_URL";
export const UPDATE_ACTIVITEIS_LIST = "UPDATE_ACTIVITEIS_LIST";
export const SET_RESOURCES_FILTER_ITEMS_COUNT = "SET_RESOURCES_FILTER_ITEMS_COUNT"


// Workflow Actions Types

export const GET_WORKFLOW_LIST = "GET_WORKFLOW_LIST";
export const GET_SUCCESS_WORKFLOW_LIST = "GET_SUCCESS_WORKFLOW_LIST";
export const START_WORKFLOW = "START_WORKFLOW";
export const START_SUCCESS_WORKFLOW = "START_SUCCESS_WORKFLOW";
export const GET_ASSIGNABLE_WORKFLOW_USER = "GET_ASSIGNABLE_WORKFLOW_USER";
export const GET_SUCCESS_ASSIGNABLE_WORKFLOW_USER = "GET_SUCCESS_ASSIGNABLE_WORKFLOW_USER";
export const SHOW_WORKFLOW_LIST_LOADING = "SHOW_WORKFLOW_LIST_LOADING";
export const HIDE_WORKFLOW_LIST_LOADING = "HIDE_WORKFLOW_LIST_LOADING";
export const SHOW_START_WORKFLOW_PANEL = "SHOW_START_WORKFLOW_PANEL";
export const HIDE_START_WORKFLOW_PANEL = "HIDE_START_WORKFLOW_PANEL";

export const GET_WORKFLOW_EXECUTION_HISTORY = "GET_WORKFLOW_EXECUTION_HISTORY"
export const ASSIGN_USER_TO_PENDING_WORKFLOW_EXECUTION = "ASSIGN_USER_TO_PENDING_WORKFLOW_EXECUTION"
export const GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY = "GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY"
export const ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION = "ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION"
export const ASSIGN_USER_TO_PNDING_WORKFLOW_EXECUTION_FAILED = "ASSIGN_USER_TO_PNDING_WORKFLOW_EXECUTION_FAILED";
export const START_WORKFLOW_FAILED = "START_WORKFLOW_FAILED"
export const CLEAR_WORKFLOW_ERROR_RESPONSE = "CLEAR_WORKFLOW_ERROR_RESPONSE"

export const GET_USER_LIST_NOT_ACTIVE_IN_WORKFLOW = "GET_USER_LIST_NOT_ACTIVE_IN_WORKFLOW"
export const GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW = "GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW"

export const HIDE_WORKFLOW_NOTICES = "HIDE_WORKFLOW_NOTICES"
export const GET_WORKFLOW_NOTICES = "GET_WORKFLOW_NOTICES"
export const GET_SUCCESS_WORKFLOW_NOTICES = "GET_SUCCESS_WORKFLOW_NOTICES"

export const CANCEL_WORKFLOW_INSTANCE = "CANCEL_WORKFLOW_INSTANCE"
export const CANCEL_SUCCESS_WORKFLOW_INSTANCE = "CANCEL_SUCCESS_WORKFLOW_INSTANCE"
export const SKIP_WORKFLOW_INSTANCE_TASKS = "SKIP_WORKFLOW_INSTANCE_TASKS"
export const SKIP_SUCCESS_WORKFLOW_INSTANCE_TASKS =  "SKIP_SUCCESS_WORKFLOW_INSTANCE_TASKS"
export const HIDE_WORKFLOW_CANCEL_LOADING = "HIDE_WORKFLOW_CANCEL_LOADING"
export const HIDE_WORKFLOW_SKIP_TASK_LOADING = "HIDE_WORKFLOW_SKIP_TASK_LOADING"
export const OPEN_CANCEL_WORKFLOW_CONFIRMATION = "OPEN_CANCEL_WORKFLOW_CONFIRMATION"
export const OPEN_SKIP_WORKFLOW_TASK = "OPEN_SKIP_WORKFLOW_TASK"

export const GET_WORKFLOW_CHART_DATA = "GET_WORKFLOW_CHART_DATA"
export const GET_SUCCESS_WORKFLOW_CHART_DATA = "GET_SUCCESS_WORKFLOW_CHART_DATA"
export const HIDE_WORKFLOW_WORKFLOW_CHART_DATA_LOADING = "HIDE_WORKFLOW_WORKFLOW_CHART_DATA_LOADING"
export const SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL = "SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL"

//Workflows By Users
export const GET_USER_PENDING_TASK_WORKFLOW_LIST = "GET_USER_PENDING_TASK_WORKFLOW_LIST"
export const GET_SUCCESS_USER_PENDING_TASK_WORKFLOW_LIST = "GET_SUCCESS_USER_PENDING_TASK_WORKFLOW_LIST"
export const HIDE_WORKFLOW_LOADING_BY_USER = "HIDE_WORKFLOW_LOADING_BY_USER"
export const CLEAR_ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION = "CLEAR_ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION"
export const CLEAR_START_SUCCESS_WORKFLOW = "CLEAR_START_SUCCESS_WORKFLOW"
export const GET_WORKFLOW_EXECUTION_DEATIL = "GET_WORKFLOW_EXECUTION_DEATIL"
export const GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL = "GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL"
export const HIDE_WORKFLOW_HISTROY_DETAIL_LOADING = "HIDE_WORKFLOW_HISTROY_DETAIL_LOADING"
export const CLEAR_WORKFLOW_HISTORY_DETAIL_DATA = "CLEAR_WORKFLOW_HISTORY_DETAIL_DATA"
export const GET_SUB_WORKFLOW_EXECUTION_DEATIL = "GET_SUB_WORKFLOW_EXECUTION_DEATIL"
export const GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL = "GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL"
export const SET_SELECTED_SUB_WORKFLOW_ITEM = "SET_SELECTED_SUB_WORKFLOW_ITEM"

//Video call logs
export const SHOW_VIDEO_CAll_LOGS_LOADING = 'SHOW_VIDEO_CAll_LOGS_LOADING'
export const HIDE_VIDEO_CAll_LOGS_LOADING = 'HIDE_VIDEO_CAll_LOGS_LOADING'
export const GET_VIDEO_CAll_LOGS_SUMMARY = 'GET_VIDEO_CAll_LOGS_SUMMARY'
export const GET_VIDEO_CAll_LOGS_SUMMARY_SUCCESS ='GET_VIDEO_CAll_LOGS_SUMMARY_SUCCESS'
export const GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL = 'GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL'
export const GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL_SUCCESS = 'GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL_SUCCESS'
export const CLEAR_VIDEO_CALL_HISTORY_DATA = 'CLEAR_VIDEO_CALL_HISTORY_DATA'
export const SHOW_WORKFLOW_HISTORY_PANEL = "SHOW_WORKFLOW_HISTORY_PANEL"
export const SET_SELECTED_WORKFLOW_ITEM = "SET_SELECTED_WORKFLOW_ITEM"

