// horizontal nav links
export default {
   default: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
   ],
   category1: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
   ],
   category2: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.configurator",
         "menu_icon": "zmdi zmdi-configurator",
         "path": "/app/configurator",
         "new_item": false,
         "child_routes": null,
         "module": "configurator"
      },
      {
         "menu_title": "sidebar.lms",
         "menu_icon": "zmdi zmdi-assignment-o",
         "path": "/app/lms",
         "new_item": false,
         "child_routes": null,
         "module": "lms"
      }
   ],
   category3: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.configurator",
         "menu_icon": "zmdi zmdi-configurator",
         "path": "/app/configurator",
         "new_item": false,
         "child_routes": null,
         "module": "configurator"
      },
      {
         "menu_title": "sidebar.lms",
         "menu_icon": "zmdi zmdi-assignment-o",
         "path": "/app/lms",
         "new_item": false,
         "child_routes": null,
         "module": "lms"
      },
      {
         "menu_title": "sidebar.analytics",
         "menu_icon": "zmdi zmdi-analytics",
         "path": "/app/zoho_analytics",
         "new_item": false,
         "child_routes": null,
         "module": "analytics"
      },
   ],
   category4: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.configurator",
         "menu_icon": "zmdi zmdi-configurator",
         "path": "/app/configurator",
         "new_item": false,
         "child_routes": null,
         "module": "configurator"
      },
      {
         "menu_title": "sidebar.analytics",
         "menu_icon": "zmdi zmdi-analytics",
         "path": "/app/zoho_analytics",
         "new_item": false,
         "child_routes": null,
         "module": "analytics"
      },
   ],
   category5: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.lms",
         "menu_icon": "zmdi zmdi-assignment-o",
         "path": "/app/lms",
         "new_item": false,
         "child_routes": null,
         "module": "lms"
      },
      {
         "menu_title": "sidebar.analytics",
         "menu_icon": "zmdi zmdi-analytics",
         "path": "/app/zoho_analytics",
         "new_item": false,
         "child_routes": null,
         "module": "analytics"
      },
   ],
   category6: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.analytics",
         "menu_icon": "zmdi zmdi-analytics",
         "path": "/app/zoho_analytics",
         "new_item": false,
         "child_routes": null,
         "module": "analytics"
      },
   ],
   category7: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.configurator",
         "menu_icon": "zmdi zmdi-configurator",
         "path": "/app/configurator",
         "new_item": false,
         "child_routes": null,
         "module": "configurator"
      }
   ],
   category8: [
      {
         "menu_title": "sidebar.home",
         "menu_icon": "zmdi zmdi-home",
         "path": "/app/lsdashboard",
         "new_item": false,
         "child_routes": null,
         "module": "home"
      },
      {
         "menu_title": "sidebar.workflows",
         "menu_icon": "zmdi zmdi-peoples",
         "path": "/app/workflows",
         "new_item": false,
         "child_routes": null,
         "module": "workflows"
      },
      {
         "menu_title": "sidebar.reports",
         "menu_icon": "zmdi zmdi-report",
         "path": "/app/usercharts",
         "new_item": false,
         "child_routes": null,
         "module": "reports"
      },
      {
         "menu_title": "sidebar.contacts",
         "menu_icon": "zmdi zmdi-contacts",
         "path": "/app/contacts",
         "new_item": false,
         "child_routes": null,
         "module": "contacts"
      },
      {
         "menu_title": "sidebar.lms",
         "menu_icon": "zmdi zmdi-assignment-o",
         "path": "/app/lms",
         "new_item": false,
         "child_routes": null,
         "module": "lms"
      }
   ],
   configurator: {
      "menu_title": "sidebar.configurator",
      "menu_icon": "zmdi zmdi-configurator",
      "path": "/app/configurator",
      "new_item": false,
      "child_routes": null,
      "module": "configurator"
   },
   lms: {
      "menu_title": "sidebar.lms",
      "menu_icon": "zmdi zmdi-assignment-o",
      "path": "/app/lms",
      "new_item": false,
      "child_routes": null,
      "module": "lms"
   },
   zohoAnalytics: {
      "menu_title": "sidebar.analytics",
      "menu_icon": "zmdi zmdi-analytics",
      "path": "/app/zoho_analytics",
      "new_item": false,
      "child_routes": null,
      "module": "analytics"
   },
   workflow: {
      "menu_title": "sidebar.workflows",
      "menu_icon": "zmdi zmdi-peoples",
      "path": "/app/workflows",
      "new_item": false,
      "child_routes": null,
      "module": "workflows"
   }
}