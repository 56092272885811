/**
 * LandingPageAllActivity
 */
import React from "react";

import { connect } from 'react-redux';
import LifeSherpaLoading from '../LifeSherpaLoading';
import AddActivity from "./AddActivity";
// actions
import {    
    getActivitiesList, 
    getPokeForm, 
    handleListStyle,
    removeFilteredActivitiesList,
    getFilteredActivitiesList,
    getactivitiesCalendar,
    clearAssignUserResponseData,
    clearWorkflowExcResponseData,
    startWorkflow,
} from '../../actions';

// components
import LandingPageAllList from './LandingPageAllList';
import "../../assets/scss/custom/app.scss"
import { Scrollbars } from 'react-custom-scrollbars';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import ActivitiesFilterPopup from "../Widgets/ActivitiesFilterPopup";
import CalendarTodayIcon from '@mui/icons-material//CalendarToday';
import ViewAgendaOutlinedIcon from '@mui/icons-material//ViewAgendaOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import 'firebase/auth';
// import firebase from 'firebase/app';
import {IconButton, Button} from '@mui/material';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import StartWorkflowView from "../Workflows"; 


const drawerWidth = 280;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 'auto',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '620px',
        overflowY: 'scroll'
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        backgroundColor: 'transparent',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});

class LandingPageAllActivity extends React.Component {

    state = {
        mobileOpen: false,
        open: false,
        selectedRoutine: null,
        select: "activity",
        search: '',
        addDilogBox: false,
        showFilter: false,
        filter: '',
        activityFilter: [],
        update: '',
        loading: true,
        filterOptions: [],
        showFilter: false,
        applyedFilterOptions: [],
        applyFiltersOnActivities: false,
        openEditor: false,
        editorType: "general",
        activityId: null
    };

    componentDidMount() {
        console.log("%c[AllActivities]%c Rendered all activities panel","color:black","color:green");
        let { select } = this.state
        select = this.props.showRewards ? 'rewards' : select
        this.setState({ select });
        this.clearFilter();
    }

    clearFilter = () => {
        const auth_uid = localStorage.getItem('auth_uid');
        this.props.removeFilteredActivitiesList();
        this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: false  });
    }

    applyFilter = (orgIds) => {
        let { filterOptions } = this.state;
        let selectedOrgIds = orgIds
        const {activityGroups} = this.props
        const auth_uid = localStorage.getItem('auth_uid');
		//const uid = localStorage.getItem('uid');
        if(filterOptions.length > 0 || (orgIds && orgIds.length > 0)) {
            let newFilterotions  = []
            filterOptions.length > 0 && filterOptions.map(filterGroupId => {
                const index = activityGroups.findIndex(ele => ele.id === filterGroupId)
                const selectedActivityGroup = activityGroups[index]
                if (selectedActivityGroup.name === "Private Activities") {
                    selectedOrgIds = ["Private Activities"]
                    const filterObject = { activityGroupId: selectedActivityGroup.id, organization: "Private Activities" }
                    newFilterotions.push(filterObject)
                } else {
                    const filterObject = { activityGroupId: selectedActivityGroup.id, organization: selectedActivityGroup.orgId }
                    newFilterotions.push(filterObject)
                }
            })
            const filtersOptions = newFilterotions //filterOptions.length > 0 && filterOptions.join(",");
            this.props.getFilteredActivitiesList(auth_uid, filtersOptions, null, selectedOrgIds);
            this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: true, applyedFilterOptions: [...filterOptions], showInactiveActivities: false});
        } else {
            this.props.removeFilteredActivitiesList();
            this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: false });
        }
    }

    handleCloseFilter = () => {
        this.setState({ ...this.state, showFilter: false });
    }

    handelShowFilter = () => {
        let { applyedFilterOptions } = this.state;
        this.setState({ ...this.state, showFilter: true, filterOptions: [...applyedFilterOptions] });
    }
   
    handelFilterOpitonsChange = (id) => {
        let tempState = { ...this.state };
        let index = tempState.filterOptions.findIndex(groupId => groupId === id);
        if (index >= 0) {
            tempState.filterOptions.splice(index, 1);
        }
        else {
            tempState.filterOptions.push(id);
        }
        this.setState(tempState);
    }

    handleClose(activity) {
        this.setState({ selectedRoutine: activity ? activity.title : '' })
        this.props.handleOpenForm(activity)
        this.props.getPokeForm()
    }
    setStateUpdate = () => {
        this.setState({ ...this.state, update: "" });
    }
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    openEditor = (value) => {
        this.props.openEditor(value);
        this.setState({ addDilogBox: false })
    }
    handelSearch = (e) => {
       // console.log(e);
        this.setState({...this.state,search:e.target.value});
    }
    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }
    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh - 160px)';
        } else {
            return '540px';
        }
        
    }

    handleOpenObjectEditor = (type) => {
		console.log("add icon clicked -->", type)
		this.setState({openEditor: true, activityId: null, editorType:type})
	}

	handleCloseObjectEditor = () => {
		this.setState({openEditor: false, activityId: null, editorType:null})
		const auth_uid = localStorage.getItem('auth_uid');
		this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
		this.props.getactivitiesCalendar(auth_uid);
	}

    handleEditActivity = (activityId, editorType) => {
		console.log("edit activity -->", activityId, editorType)
		this.setState({openEditor: true, activityId: activityId, editorType})
	}

    handleListType = (type) => {
        this.props.handleListStyle(type);
    }


    render() {

        const { showConfirmation, activityGroups, filteredActivities, activitiesPanelOrgFiltered, realtimeUpdate, userRole, workflowExecution, assignedUsers, enableWorkflows } = this.props;
        let {search, showFilter, applyedFilterOptions, filterOptions, applyFiltersOnActivities, openEditor, activityId, editorType} = this.state;


        if (this.props.showRewards) select = 'rewards';

        return (
            <div data-testid="my-activities-panel">
                {showConfirmation ?
                    <></>
                    :
                    (
                        <div className="rct-mail-wrapper">
                            <div /*style={{width:100+"%", height: 380, overflowY: 'scroll'}}*/>
                                <LifeSherpaLoading loading={this.props.loading || this.props.summaryLoading || this.props.workflowLoading} />
                                <div className="d-flex p-3 list-card align-items-center">
                                    <div className={`d-flex ${(applyFiltersOnActivities || activitiesPanelOrgFiltered && activitiesPanelOrgFiltered.length > 0) ? "activity-filter-icon" : "activity-filter-icon-applied"}`}>
                                        <div>
                                            <IconButton data-testid="activities-filter-icon-button" disabled={this.props.listStyle == 'summary' || this.props.listStyle === 'workflows'}   title="Filter By Activity Group" className="text-white rounded-circle mr-2" style={{background:'#134266'}} onClick={()=>this.handelShowFilter()}>
                                            {(this.props.listStyle == 'summary' || this.props.listStyle === 'workflows') ? <FilterAltOffIcon data-testid="activities-filter-disabled" className="filter-icon" fontSize="large"/> : <FilterAltIcon data-testid="activities-filter-enabled" className="filter-icon" fontSize="large"/>}
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="mx-2 search-activity-field">
                                        <form noValidate autoComplete="off">
                                            <TextField data-testid={this.props.listStyle === 'summary' ? "my-activity-search-text-field-disabled" : "my-activity-search-text-field"} id="standard-search" 
                                                onChange={(e) => this.handelSearch(e)}
                                                label={this.props.listStyle === 'workflows' ? "Search Workflow" :  "Search Activity"}
                                                type="search" 
                                                value={search} 
                                                disabled={this.props.listStyle == 'summary'}
                                                InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary"/>
                                                </InputAdornment>
                                                ),
                                                endAdornment: ( search && search.length > 0 ?
                                                    <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                                    </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                                )
                                                }}
                                                variant="standard" 
                                            />
                                        </form>
                                     </div>
                                    <div className="d-flex align-items-center justify-content-center ls-btn-tab-container">
                                        <div data-testid="common-activity-tab" className={this.props.listStyle !='summary' && this.props.listStyle !='workflows' ? 'selected-btn' : 'unselected-btn'}>
                                            <IconButton data-testid="common-activity-tab-btn" title="Common Activities" onClick={()=>this.handleListType('common')}> 
                                                <ViewAgendaOutlinedIcon/>
                                            </IconButton>
                                        </div>
                                        <div data-testid="summary-activity-tab" className={this.props.listStyle == 'summary' ? 'selected-btn' : 'unselected-btn'}>
                                            <IconButton data-testid="summary-activity-tab-btn" title="Activities Summary"  onClick={()=>this.handleListType('summary')}>
                                                <CalendarTodayIcon/>
                                            </IconButton>
                                        </div>
                                        {/*userRole && userRole != "Client" && enableWorkflows ? 
                                            <div data-testid="workflow-activity-tab" className={this.props.listStyle == 'workflows' ? 'selected-btn' : 'unselected-btn'}>
                                                <IconButton data-testid="workflow-activity-tab-btn" title="Workflows"  onClick={()=>this.handleListType('workflows')}>
                                                    <Diversity3OutlinedIcon/>
                                                </IconButton>
                                            </div>
                                            : <></>
                                        */}
                                    </div>
                                </div>
                               
                                {showFilter &&
                                    <ActivitiesFilterPopup
                                        activityGroups={activityGroups}
                                        showFilter={showFilter}
                                        filterOptions={this.state.filterOptions}
                                        clearFilter={this.clearFilter}
                                        applyFilter={this.applyFilter}
                                        handleCloseFilter={this.handleCloseFilter}
                                        handelFilterOpitonsChange={this.handelFilterOpitonsChange}
                                        panel="activities"
                                    />
                                }
                                <Scrollbars
                                    className="rct-scroll list-sherpa-activity"
                                    autoHide
                                    style={{ height: this.getScrollHeight() }}
                                    >
                                    <LandingPageAllList
                                        listStyle={this.props.listStyle}
                                        selected={this.props.selected}
                                        handleClose={this.handleClose.bind(this)}
                                        selectedUser={this.props.selectedUser}
                                        search={search}
                                        selectedMember={this.props.selectedMember}
                                        selectedMemberId={this.props.selectedMemberId}
                                        selectedGroup={this.props.selectedGroup}
                                        previewId={(e) => this.props.previewId(e)}
                                        showDetails={(e) => this.props.showDetails(e)}
                                        EditActivity={(e) => this.props.EditActivity(e)}
                                        handleStartActivity={(e) => this.props.handleStartActivity(e)}
                                        showAddButton={() => this.props.showAddButton(false)}
                                        setStateUpdate={this.setStateUpdate}
                                        handleEditActivity={this.props.handleEditActivity}
                                        enableWorkflows={enableWorkflows}
                                    />

                                </Scrollbars>

                            </div>
                        </div>
                    )
                }
                { (this.props.listStyle !== 'workflows' && this.props.listStyle !== 'summary') ?  
                    <AddActivity
                        handleOpenObjectEditor={this.handleOpenObjectEditor}
                        handleCloseObjectEditor={this.handleCloseObjectEditor}
                        openEditor={openEditor}
                        editorType={editorType}
                        activityId={activityId}  
                    />
                    : <></>
                }
                
                {this.props.startWorkflowPanel ? 
                    <StartWorkflowView
                       
                    />
                    : <></>
                }
            </div>
        )
    }
}

// map state to props
const mapStateToProps = ({ emailApp, chatAppReducer, activitiesList, PokeForm, activitiesHistoryList, GroupList, ActivityReducer, authUser, clientsList, workflowData}) => {
    const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
    const { selectedUser, displayName, profileImage, selectedMemberId, selectedRole } = chatAppReducer
    const { allActivities, privateActivities, loading, activityFilter, listStyle, activityGroups, filteredActivities, summaryLoading, realtimeUpdate } = activitiesList
    const { selected } = ActivityReducer
    const { user, userRole, privacyPolicy, enableWorkflows } = authUser
    const { selectedActivitiesHistory, loadingDetail, selectedStepId } = activitiesHistoryList
    const { pokeForm, isPokeForGroup } = PokeForm
    let pokeFormLoading = PokeForm.loading
    const { selectedGroup, groups } = GroupList
    const {activitiesPanelOrgFiltered} = clientsList
    const {workflowLoading, startWorkflowPanel, workflowExecution, assignedUsers} = workflowData;
    return { currentEmail, user, selectedRole, sendingEmail, listStyle, showActivityHistory, selected, activityFilter, selectedMemberId, showConfirmation, selectedUser, allActivities, privateActivities, loading, pokeFormLoading, pokeForm, displayName, profileImage, selectedActivitiesHistory, loadingDetail, selectedStepId, selectedGroup, isPokeForGroup, groups, activityGroups, filteredActivities, summaryLoading, activitiesPanelOrgFiltered, realtimeUpdate, workflowLoading, startWorkflowPanel, userRole, privacyPolicy, workflowExecution, assignedUsers, enableWorkflows};
}

export default connect(mapStateToProps, {
    getPokeForm,
    getActivitiesList,
    handleListStyle,
    removeFilteredActivitiesList,
    getFilteredActivitiesList,
    getactivitiesCalendar,
    clearAssignUserResponseData,
    clearWorkflowExcResponseData,
    startWorkflow,
})(LandingPageAllActivity);