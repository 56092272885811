import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  } from 'Actions'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

class WarningDialog extends Component {
    render() {
        let { warningText , showWarning, acceptText, cancelText } = this.props;
        if(!showWarning) {
            return "";
        }
        return (
            <Dialog
                    fullScreen={false}
                    open={showWarning}
                    fullWidth={true}
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} warning-dialog-popup`}
                    > 
                    <DialogContent className="p-0">
                        <div className="mb-3 mx-4 text-center">
                            <h2 className="exitDialog text-center mb-4">{warningText}</h2>
                            <div className="d-flex align-items-center justify-content-between">
                            <button className="btn btn-warning  mr-2" onClick={()=> this.props.handleCancel()}>{cancelText}</button>
                            <button className="btn btn-grey ml-2" onClick={()=> this.props.handleAccept()}>{acceptText}</button>
                            {/* <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}><a className="text-white" target="_self" href="/success">ok</a></button>*/}
                            </div>
                        </div>
                    </DialogContent>
             </Dialog>
        );
    }
}
const mapStateToProps = ({settings}) => { 
    const { screenThemeClass, popupThemeClass } = settings; 
    return { screenThemeClass, popupThemeClass };
}

export default connect(mapStateToProps, { })(WarningDialog);