/**
 * App Light Theme
 */
import { createTheme } from '@mui/material/styles';
import AppConfig from '../../constants/AppConfig';

const theme = createTheme({
   palette: {
      primary: {
         main: AppConfig.themeColors.primary
      },
      secondary: {
         main: AppConfig.themeColors.warning
      },
      progress: {
         main: AppConfig.themeColors.primary
      }
   },
   typography: {
      useNextVariants: true,
      // Use the system font instead of the default Roboto font.
      fontFamily: [
         "Roboto",
         "Helvetica",
         "Arial",
         'Lato',
         'sans-serif',
      ].join(','),
      htmlFontSize: 16,
      h2: {
         fontSize: 21,
         fontWeight: 400,
      },
      body1: {
         fontWeight: 400,
      },
   },

});

export default theme;