/**
 * Rct Collapsible Card
 */
 import React, { Component, Fragment } from 'react';
 import { Collapse, Badge } from 'reactstrap';
 import classnames from 'classnames';
 import MenuIcon from '@mui/icons-material/Menu';
 import CloseIcon from '@mui/icons-material/Close';
 import IconButton from '@mui/material/IconButton';
 import Remove from '@mui/icons-material/Remove';
 import FullscreenIcon from '@mui/icons-material/Fullscreen';
 import MinimizeIcon from '@mui/icons-material/Minimize';
 import MaximizeIcon from '@mui/icons-material/Maximize';

 // rct section loader
 
 class RctCollapsibleChatCard extends Component {
 
     state = {
         reload: false,
         collapse: true,
         close: false,
        
     }
 
     onCollapse(e) {
         e.preventDefault();
         this.setState({ collapse: !this.state.collapse });
     }
 
     onReload(e) {
         e.preventDefault();
         this.setState({ reload: true });
         let self = this;
         setTimeout(() => {
             self.setState({ reload: false });
         }, 1500);
     }
 
     onCloseSection(e) {
         e.preventDefault();
         this.setState({ close: true });
     }
     handleMinimizeSection= () => {
         
        this.props.handleMinimizeSection();
     }
 
     render() {
         const { close, reload, collapse } = this.state;
         const { children, collapsible, closeable, reloadable, heading, fullBlock, colClasses, customClasses, headingCustomClasses, contentCustomClasses, badge, userKey, minimize } = this.props;
         return (
             <div key={userKey} className={classnames(colClasses ? colClasses : '', { 'd-block': !collapse }, { 'minimized-section': minimize })}>
                 <div className={classnames(`rct-block ${customClasses ? customClasses : ''}`, { 'd-none': close })}>
                     {heading &&
                         <div className={`rct-block-title d-flex align-items-center justify-content-between  ${headingCustomClasses ? headingCustomClasses : ''}`}>
                             {heading} {badge && <Badge className="p-1 ml-10" color={badge.class}>{badge.name}</Badge>}
                                 <div className="d-flex align-items-center">
                                     <IconButton onClick={() => this.handleMinimizeSection()} className=" header-icon-button ">{minimize ? <MaximizeIcon/> : <Remove/>}</IconButton>
                                     <IconButton onClick={() => this.props.showFullChatPanelForUser()} className=" header-icon-button"><FullscreenIcon /></IconButton>
                                     <IconButton onClick={() => this.props.removeSelectedChatUser()} className=" header-icon-button"><CloseIcon/></IconButton>
                                 </div>    
                         </div>
                     }
                     <Collapse isOpen={collapse}>
                         <div className={classnames(contentCustomClasses ? contentCustomClasses : '', { "rct-block-content": !fullBlock, 'rct-full-block': fullBlock })}>
                             {minimize ? "" :  children}
                         </div>
                     </Collapse>
                 </div>
             </div>
         );
     }
 }
 
 export default RctCollapsibleChatCard;
 