import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
// reactstrap components
import { Label } from 'reactstrap';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    handleShowHelpPanel,
} from '../../../actions';
import HelpViewPage from './HelpViewPage';

class ActivityHelpContacts extends Component {
    handleHideHelp() {
        this.props.handleShowHelpPanel({ show: false, url: null });
    }
    handleClosePanel() {
        this.handleHideHelp();
        this.props.handleclosePanel()
    }
    render() {

        return (
            <div>
                <Dialog
                    open={true}
                    fullScreen={true}
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} notices-list-panel`}
                >
                    <DialogTitle>
                        <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>

                            <IconButton title='Back To Activity Page' className="text-white" onClick={() => this.handleHideHelp()}><ArrowBackIcon /></IconButton>
                            <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Help</Label>
                            <IconButton className="text-white mr-2" onClick={() => this.handleClosePanel()}><CloseIcon /></IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className="m-0 p-0 activity-details-height">
                        <HelpViewPage />
                    </DialogContent>
                </Dialog>
            </div>


        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { popupThemeClass } = settings;
    return { popupThemeClass };
}

export default connect(mapStateToProps, { handleShowHelpPanel })(ActivityHelpContacts);
