/**
 * Chat App Actions
 */
import {axiosClient} from '../sherpaApi'
import moment from 'moment'
import { NotificationManager } from 'react-notifications';

import {
    GET_CLIENT_POKE_FORM,
    GET_CLIENT_SUCCESS_POKE_FORM,
    SET_CLIENT_POKE_FORM,
    SET_CLIENT_SUCCESS_POKE_FORM,
    SET_GROUP_POKE_FORM,
    SET_GROUP_SUCCESS_POKE_FORM,
    UPDATE_CLIENT_POKE_FORM,
    UPDATE_CLIENT_SUCCESS_POKE_FORM,
    SET_SELECTED_POKE_FOR_GROUP,
    SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM,
    HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM,
    CLEAR_POKE_FORM_DATA,
    EDITOR_LOADING,
    STOP_LOADING,
    GET_GROUP_POKE_FORM,
    GET_GROUP_POKE_FORM_SUCCESS,
    GET_GROUP_POKE_FORM_FAILED,
    GET_POKE_FORM_OF_CLIENT,
    GET_POKE_FORM_OF_CLIENT_SUCCESS,
    GET_POKE_FORM_OF_CLIENT_FAILED
 } from './types';

 export const setsavePokeForGroup = (isGroup) => ({
     type: SET_SELECTED_POKE_FOR_GROUP,
     payload: isGroup
 })

 export const getUpdatedEvaluationForm = (userId, responseId) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let url = `/users/${userId}/forms-responses/${responseId}?organization=${orgId}` 
    let idToken = localStorage.getItem('idToken')
    dispatch({type: GET_CLIENT_POKE_FORM})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        const pokeFormData = response.data;
        if(pokeFormData && pokeFormData.sections) {
            const sections = pokeFormData.sections;
            let sortedSectionsKey = Object.keys(sections).sort((a, b) => sections[a].position - sections[b].position);
            const sortedSections = {};
            sortedSectionsKey.map((key, index) => {
              sortedSections[key] = sections[key];
            });
            pokeFormData.sections = sortedSections;
        }
        dispatch({ type: GET_CLIENT_SUCCESS_POKE_FORM, payload: pokeFormData });
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM });
    })
}

export const savePokrFormForClients = (userId, body, formId, activity) => (dispatch) => {
    console.log('Selected Activity Id:', activity)
    userId=userId ? userId : localStorage.getItem('uid')
    const orgId = localStorage.getItem("selectedOrgId")
    let bodyData = {
        "description": body ? body.description : "Poke Messages",
        "formName": body ? body.formName : "Poke",
        "additionalProperties": {
          "achievementId": activity && activity.achievementId ? activity.achievementId :  "string",
          "routineId": activity && activity.id ? activity.id :  "string",
        },
        "formID": formId,
        "userId": userId,
        "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z"),
        "sections": body.sections,
      }
    let url = `/users/${userId}/forms-responses?organization=${orgId}`
    let idToken = localStorage.getItem('idToken')
    dispatch({type: SET_CLIENT_POKE_FORM})
    axiosClient.post(url, bodyData, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        const message = "Poke message sent successfully"
        NotificationManager.success(message);
        dispatch({ type: SET_CLIENT_SUCCESS_POKE_FORM, payload: response.data });
        dispatch(clearPokeFormData())
    }).catch((error) => {
        console.log("error occured ", error);
        const errorMsg = error.response ? error.response.data.message : error.message;
        NotificationManager.error(errorMsg);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM });
     })
}
export const clearPokeFormData = () => (dispatch) => {   
            console.log("Activity Form data cleared.......");
            dispatch({ type: CLEAR_POKE_FORM_DATA });       
 };
 export const getPokeForm = (formId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    let uid = localStorage.getItem('uid')
    let pokeFormId = formId ? formId : localStorage.getItem('pokeFormId')
    let url = `/users/${uid}/forms/${pokeFormId}?organization=${orgId}` ///users/{userId}/forms/{formId}
    console.log(" Fetching poke form data for formId:",pokeFormId,".....");
    dispatch({type: EDITOR_LOADING})
    dispatch({type: GET_CLIENT_POKE_FORM})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched poke form data for fromId:"+pokeFormId,"color:green");
        console.log("%c Poke form opened","color:green");
        dispatch({type: STOP_LOADING});
        const pokeFormData = response.data;
        // if(pokeFormData && pokeFormData.sections) {
        //    let id =  "pokeformdate";
        //    const dateSection = {
        //        type:"date",
        //        date:"2022-01-29T07:45:00.000Z",
        //        position: Object.keys(pokeFormData.sections).length +1,
        //        heading: "Form submission date",
        //        isMandatory: true,
        //    }
        //    pokeFormData.sections[id] = dateSection;
        // }
        if(pokeFormData && pokeFormData.sections) {
            const sections = pokeFormData.sections;
            let sortedSectionsKey = Object.keys(sections).sort((a, b) => sections[a].position - sections[b].position);
            const sortedSections = {};
            sortedSectionsKey.map((key, index) => {
              sortedSections[key] = sections[key];
            });
            pokeFormData.sections = sortedSections;
        }
        dispatch({ type: GET_CLIENT_SUCCESS_POKE_FORM, payload: pokeFormData });
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching poke form data ","color:red", errorMsg);
        NotificationManager.error(errorMsg);
        dispatch({type: STOP_LOADING})
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM });
     })
 };

export const setPokeFormData = (pokeFormData) => (dispatch) => {
        if(pokeFormData && pokeFormData.sections) {
            const sections = pokeFormData.sections;
            let sortedSectionsKey = Object.keys(sections).sort((a, b) => sections[a].position - sections[b].position);
            const sortedSections = {};
            sortedSectionsKey.map((key, index) => {
              sortedSections[key] = sections[key];
            });
            pokeFormData.sections = sortedSections;
        }
        dispatch({ type: GET_CLIENT_SUCCESS_POKE_FORM, payload: pokeFormData });
 };

 export const getPokeFormFromConfigurator = (formId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid');
    let orgId =  localStorage.getItem('orgId');
    let pokeFormId = formId ? formId : localStorage.getItem('pokeFormId')
    let url = `/users/${uid}/forms/${pokeFormId}?organization=${orgId}` ///users/{userId}/forms/{formId}
    dispatch({type: EDITOR_LOADING})
    dispatch({type: GET_CLIENT_POKE_FORM})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type: STOP_LOADING});
        const pokeFormData = response.data;
        dispatch({ type: GET_CLIENT_SUCCESS_POKE_FORM, payload: pokeFormData });
    }).catch(async(error) => {
        console.log("error occured ", error);
        dispatch({type: STOP_LOADING})
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM });
     })
 };
 
 export const setPokeFormForGroup = (groups, body, formId) => (dispatch) =>{
    console.log("Poking to groups: ", groups)
    let uid = localStorage.getItem('uid')
    const orgId = localStorage.getItem("selectedOrgId")
    let bodyData = {
        "description": body.description ? body.description : "Poke Messages",
        "formName": body.formName ? body.formName : "Poke",
        "additionalProperties": {
          "achievementId": "string",
          "routineId": "string",
        },
        "formID": formId,
        "userId": uid,
        "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z"),
        "sections": body.sections,
      }
    let idToken = localStorage.getItem('idToken')
    let params = []
    if (groups && groups.length > 0) {
        groups.map(group => {
            params.push({"groupId": group.id, "organization": group.orgId})
        })
    }
    
    // groups=[{groupId, organization}]
    let url = `/forms-responses/batch?groups=${encodeURIComponent(JSON.stringify(params))}`
    dispatch({type: SET_GROUP_POKE_FORM})
    axiosClient.post(url, bodyData, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        const message = "Poke message sent successfully"
        NotificationManager.success(message);
        dispatch({ type: SET_GROUP_SUCCESS_POKE_FORM, payload: response.data });
        dispatch(clearPokeFormData())
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while saving poke form data ","color:red", errorMsg);
        NotificationManager.error(errorMsg);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM });
     })
 };
 
 export const UpdatePokeForm = (data, ID) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    let uid = localStorage.getItem('uid')
    let pokeFormId = localStorage.getItem('pokeFormId')
    let url = `/users/${uid}/${groupId}/select-group?organization=${orgId}`
    dispatch({type: UPDATE_CLIENT_POKE_FORM})
    axiosClient.post(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    })
    .then((response) => {
        dispatch({ type: UPDATE_CLIENT_SUCCESS_POKE_FORM, payload: response.data });
    }).catch(async(error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM });
     })
 }

 export const showPokeFormLoading = () => ({
     type: SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM
 })
 export const hidePokeFormLoading = () => ({
    type: HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM
})

export const getGroupPokeForm = (formId) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let pokeFormId = formId ? formId : localStorage.getItem('pokeFormId')
    let url = `/users/${uid}/forms/${pokeFormId}?organization=${orgId}` ///users/{userId}/forms/{formId}
    console.log(" Fetching group poke form data for formId:",pokeFormId,".....");
    dispatch({type: GET_GROUP_POKE_FORM})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched group poke form data for fromId:"+pokeFormId,"color:green");
        console.log("%c Poke form opened","color:green");
        const pokeFormData = response.data;
        if(pokeFormData && pokeFormData.sections) {
            const sections = pokeFormData.sections;
            let sortedSectionsKey = Object.keys(sections).sort((a, b) => sections[a].position - sections[b].position);
            const sortedSections = {};
            sortedSectionsKey.map((key, index) => {
              sortedSections[key] = sections[key];
            });
            pokeFormData.sections = sortedSections;
        }
        dispatch({ type: GET_GROUP_POKE_FORM_SUCCESS, payload: pokeFormData });
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching poke form data ","color:red", errorMsg);
        NotificationManager.error(errorMsg);
        dispatch({ type: GET_GROUP_POKE_FORM_FAILED });
     })
 };


export const getClientPokeForm = (formId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("orgId")
    let uid = localStorage.getItem('uid')
    let pokeFormId = formId ? formId : localStorage.getItem('pokeFormId')
    let url = `/users/${uid}/forms/${pokeFormId}?organization=${orgId}` ///users/{userId}/forms/{formId}
    console.log(" Fetching client poke form data for formId:",pokeFormId,".....");
    dispatch({type: GET_POKE_FORM_OF_CLIENT})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched client poke form data for fromId:"+pokeFormId,"color:green");
        console.log("%c Poke form opened","color:green");
        const pokeFormData = response.data;
        if(pokeFormData && pokeFormData.sections) {
            const sections = pokeFormData.sections;
            let sortedSectionsKey = Object.keys(sections).sort((a, b) => sections[a].position - sections[b].position);
            const sortedSections = {};
            sortedSectionsKey.map((key, index) => {
              sortedSections[key] = sections[key];
            });
            pokeFormData.sections = sortedSections;
        }
        dispatch({ type: GET_POKE_FORM_OF_CLIENT_SUCCESS, payload: pokeFormData });
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching client poke form data ","color:red", errorMsg);
        NotificationManager.error(errorMsg);
        dispatch({ type: GET_POKE_FORM_OF_CLIENT_FAILED });
     })
 };