/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { Fab } from "@mui/material";
import HeaderComponent from '../Header/HeaderComponent'

// components
// import {
//    SessionSlider
// } from 'Components/Widgets';

// app config
import AppConfig from '../../constants/AppConfig';

// redux action
import {
   signinUserWithFacebook,
   signinUserWithGoogle,
   signinUserWithGithub,
   signinUserWithTwitter
} from '../../actions';

class LoginConfirmation extends Component {

   state = {
      email: 'ajayspecial0502@gmail.com', //'demo@example.com',
      password: 'Abcd1234', //'test#123',
      isTokenEmpty: false,
      token: ''
   }

	/**
	 * On User Login
	 */
   onContinue() {
    this.props.history.push('/success');
   }

   render() {
      const { token, isTokenEmpty } = this.state;
      const { loading } = this.props;
      const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 

      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper">
               <AppBar position="static" className="session-header">
                     <Toolbar>
                        <div className="container">
                           <div className="d-flex justify-content-between">
                              <div className="session-logo">
                                 <Link to="/">
                                    <img src={applogo} alt="session-logo" className="img-fluid auto-resize-img" width="auto" height="auto" />
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </Toolbar>
                  </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                             {/*<div className="session-head mb-30">
                                 <p className="mb-0" style={{color:"gray",fontSize:18+"px"}}>Congratulations!</p>
                                 <p style={{color:"gray",fontSize:18+"px"}}>You have successfully completed your registration.</p>
                                 {process.env.REACT_APP === 'DES' && <p className="text-white" style={{fontSize:18+"px"}}>Your DES Provider Contact has been notified of your registration and will be contacting you shortly.</p>}
                                 <p style={{color:"gray",fontSize:18+"px"}}>We have sent an email to the address you provided. Click the contained link to verify that address. Your account will not be activated until you have completed the verification process.</p>
                                </div>*/} 
                              <div className="session-head mb-30">
                                 <p className="mb-0" style={{color:"gray",fontSize:18+"px"}}>Congratulations!</p>
                                 <p style={{color:"gray",fontSize:18+"px"}}>Your one-time registration is now complete and now you can log in to LifeSherpa.</p>
                                </div>
                              <Form>
                                 <FormGroup>
                                    <Button
                                       className="btn btn-primary text-white btn-block w-100"
                                       variant="contained"
                                       size="large"
                                    >
                                       <a target="_self" href="/signinEmail" className="text-white">Continue To LS Portal</a> 
                            			</Button>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user, loading } = authUser;
   return { user, loading }
}

export default connect(mapStateToProps, {
   signinUserWithFacebook,
   signinUserWithGoogle,
   signinUserWithGithub,
   signinUserWithTwitter
})(LoginConfirmation);
