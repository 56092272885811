import React,{Component} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
class LIfeSherpaBlankPageLoading extends Component
{
    state={}
    render() {
        let {loading} = this.props;
        if(loading) {
            return (
                <div data-testid="lifesherpa-loading" className="d-flex justify-content-center page-loading-overlay">
                   <CircularProgress color="progress"/>
              </div>
            )
        }
        else return "";
    }
}
export default LIfeSherpaBlankPageLoading;