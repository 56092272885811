
import {axiosClient} from '../sherpaApi'

import {
    SHOW_VIDEO_CAll_LOGS_LOADING,
    HIDE_VIDEO_CAll_LOGS_LOADING,
    GET_VIDEO_CAll_LOGS_SUMMARY,
    GET_VIDEO_CAll_LOGS_SUMMARY_SUCCESS,
    GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL,
    GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL_SUCCESS,
    CLEAR_VIDEO_CALL_HISTORY_DATA
 } from './types';


export const getMissedVideoCallSummary = () => (dispatch) => {
    const userId = localStorage.getItem('auth_uid')
    const idToken = localStorage.getItem('idToken')
    const url = `/users/${userId}/video-call-logs/summary?onlyMissedCalls=true`
    dispatch({ type: GET_VIDEO_CAll_LOGS_SUMMARY });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
            dispatch({ type: GET_VIDEO_CAll_LOGS_SUMMARY_SUCCESS, payload: response.data });
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_VIDEO_CAll_LOGS_LOADING });
    })
}

export const getVideoCallSummary = (pageCount) => (dispatch) => {
    const userId = localStorage.getItem('auth_uid')
    const idToken = localStorage.getItem('idToken');
    pageCount = pageCount ? pageCount : 1;
    const url = `/users/${userId}/video-call-logs?page=${pageCount}`;
    dispatch({ type: GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
            dispatch({ type: GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL_SUCCESS, payload:response.data});
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_VIDEO_CAll_LOGS_LOADING });
    })
}

export const showVideoCallHistoryLoading = () => (dispatch) =>{
    dispatch({type: SHOW_VIDEO_CAll_LOGS_LOADING})
}

export const hideVideoCallHistoryLoading = () => (dispatch) =>{
    dispatch({type: HIDE_VIDEO_CAll_LOGS_LOADING})
}
export const clearVideoCallHistoryData = () => (dispatch) =>{
    dispatch({type: CLEAR_VIDEO_CALL_HISTORY_DATA})
}