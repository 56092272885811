/**
 * Chat Area Component
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';

import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import LifeSherpaLoading from '../../LifeSherpaLoading';

//components
import AchievementStepsDetail from './AchievementStepsDetail'
import RctCollapsibleCard from '../../RctCollapsibleCard/RctCollapsibleCard';

// actions
import {
	activitiesOpen,
	chatOpen,
	sendMessageToUser,
	showActivityHistoryDetail,
	getPokeForm,
	getUpdatedEvaluationForm,
	setSelectedStep,
	confirmAchevement,
	clearHistoryData,
	removeAPIError,
	setSelectedMemberId,
	getSelectedActivity,
	changeRole,
	getResourceList,
	getcategories,
	getKeywords,
	getMyCourses,
	showClientPanel,
	setHistoryListPageCount,
	getHistoryList
} from 'Actions'

// app layouts
import { getAppLayout } from 'Helpers/helpers';

class ActivityHistoryDetail extends Component {

	state = {
		message: '',
		anchorEl: null,
		chatOptions: [
			'Reset Activity',
			'Poke',
			'Preview Routine',
			'Cancel'
		]
	}
	componentDidMount() {
		///window.scrollTo(0, 0)
	}
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	chatOptionsHandler = event => {
		this.setState({ anchorEl: event.currentTarget });
	}
	handleOpenClientDetail = () => {
		this.props.chatOpen(!this.props.isChatOpen)
	}
	onBackPress = () => {
		this.props.handleOpenActivitiesList()
	}
	handleCloseHistoryPanel = () => {
		const { clientPanel, showUnconfirmedAcheivemts } = this.props;
		const activityConfirmation = localStorage.getItem("activityConfirmation");
		const uid = localStorage.getItem('uid');
		const auth_uid = localStorage.getItem('auth_uid');
		const orgId = localStorage.getItem("orgId")
		//const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : [orgId]
		if (activityConfirmation && uid != auth_uid) {
			localStorage.setItem('uid', auth_uid);
			localStorage.setItem("selectedOrgId", orgId)
			localStorage.removeItem("activityConfirmation");
			this.props.setSelectedMemberId(null);
			this.props.changeRole({ role: 'admin', id: auth_uid });
			if (clientPanel) {
				this.props.getResourceList('', '', '');
				this.props.getcategories();
				this.props.getKeywords();
				this.props.getMyCourses();
				this.props.showClientPanel(false);
			}
		}
		if (showUnconfirmedAcheivemts) {
			if (!clientPanel) {
				localStorage.setItem('uid', auth_uid);
				localStorage.setItem("selectedOrgId", orgId)
				this.props.setSelectedMemberId(null);
				this.props.changeRole({ role: 'admin', id: auth_uid });
			}
		}
		this.props.clearHistoryData();
		this.props.getSelectedActivity(null);
		this.props.showActivityHistoryDetail(false)
	}

	onSubmitMessage(event) {
		event.preventDefault();
		if (this.state.message !== '') {
			let data = {
				user: this.props.selectedUser,
				message: this.state.message,
				isAdmin: true,
				time: 'Just Now'
			}
			this.props.sendMessageToUser(data);
			this.setState({ message: '' });
			setTimeout(() => {
				this.refs.chatScroll.scrollToBottom();
			}, 200);
		}
	}

	getScrollHeight() {
		const { location } = this.props;
		const appLayout = getAppLayout(location)
		if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh)';
        } else {
            return '555px';
        }
	}

	getActivityStatusDetails = (status) => {
		let statusString = status;
		if (status == "confirmed") {
			statusString = "Confirmed";
		} else if (status == "cancelled" || status == "incomplete") {
			statusString = "Not completed (Cancelled or Started and Expired)";
		} else if (status == "completedConfirmationNeeded") {
			statusString = "Completed, Need Confirmation";
		} else if (status == "completedNoConfirmationNeeded") {
			statusString = "Completed with all steps, but not confirmation was required";
		} else if (status == "expired") {
			statusString = "Achievement is expired (Created but not started and expired)";
		} else if (status == "notStarted") {
			statusString = "User was alerted and has not started";
		} else if (status == "confirmedSkippedSteps") {
			statusString = "Confirmed with Skipped Steps";
		} else if (status == "confirmedNegative") {
			statusString = "Negative, Confirmation"
		} else if (status == "inProgress" || status == "paused") {
			statusString = "Routine execution is in progress"
		}
		return statusString;
	}


	handlePokeFormOpen = () => {
		let { achievementDetail } = this.props;
		const formId = achievementDetail && achievementDetail.routine ? achievementDetail.routine.evaluationId : "";
		if (formId) {
			console.log(" Opening activity form -->", formId)
			this.props.handleClose(formId)
			this.props.getPokeForm(formId);
		}
		//this.props.getUpdatedEvaluationForm()
	}

	handleOpenEvaluationForm = () => {
		const { achievementDetail } = this.props
		const formId = achievementDetail && achievementDetail.routine ? achievementDetail.routine.evaluationId : "";
		if (achievementDetail.evaluationResponseId) {
			this.props.getUpdatedEvaluationForm(this.props.selectedMemberId, achievementDetail.evaluationResponseId)
			this.props.handleOpenForm(achievementDetail.evaluationResponseId, null);
		} else if (formId) {
			this.props.getPokeForm(formId)
			this.props.handleOpenForm(formId, null);
		}
	}

	handleLengendInfo() {
		this.setState({ showLegend: !this.state.showLegend })
	}

	parseDuration(achievementDuration, pauseDuration) {
		const timeStringArray = achievementDuration.includes(" ") ? achievementDuration.split(" ") : []
		let interval = pauseDuration
		if (timeStringArray.length > 0) {
			timeStringArray.map(item => {
				if (item.includes('h')) {
					interval += Number(item.replace("h", "")) * 3600
				} else if (item.includes('m')) {
					interval += Number(item.replace("m", "")) * 60
				} else if (item.includes('s')) {
					interval += Number(item.replace("s", ""))
				}
			})
		} else {
			interval += Number(achievementDuration.replace("s", ""))
		}

		var timeleft = (+interval) * 1000;
       	let seconds = (Math.floor(timeleft / 1000) % 60)
        let minutes = (Math.floor(timeleft / 60000) % 60)
       	let hours = Math.floor(timeleft / 3600000);

		return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
	}

	getCompletionStatus = (achievementDetail) => {
		let done = true;
		console.log("achievementDetail status --> achievementDetail", achievementDetail)
		if (achievementDetail && achievementDetail.StepsCompletion) {
			Object.keys(achievementDetail.StepsCompletion).map((objKey, key) => {
				if (achievementDetail.StepsCompletion[objKey].status != "Done") {
					done = false
				}
			})
		}
		return done;
	}

	getActivityStatusDetails = () => {
		const { selectedActivitiesHistory, achievementDetail } = this.props;
		let status = selectedActivitiesHistory ? selectedActivitiesHistory.status : "";
		let statusString = "";
		if (status == "confirmed") {
			if (this.getCompletionStatus(achievementDetail)) {
				statusString = "Confirmed with No Skipped Steps";
			} else {
				statusString = "Confirmed with Skipped Steps";
			}
		} else if (status == "cancelled" || status == "incomplete") {
			statusString = "Not completed (Cancelled or Started and Expired)";
		} else if (status == "completedConfirmationNeeded") {
			statusString = "Completed, Need Confirmation";
		} else if (status == "completedNoConfirmationNeeded") {
			statusString = "Completed with all steps, but not confirmation was required";
		} else if (status == "expired") {
			statusString = "Achievement is expired (Created but not started and expired)";
		} else if (status == "notStarted") {
			statusString = "User was alerted and has not started";
		} else if (status == "confirmedSkippedSteps") {
			statusString = "Confirmed with Skipped Steps";
		} else if (status == "confirmedNegative") {
			statusString = "Negative, Confirmation"
		} else if (status == "inProgress" || status == "paused") {
			statusString = "Routine execution is in progress"
		}
		return statusString;
	}


	render() {
		const { selectedUser, admin_photo_url, isChatOpen, isActivitiesOpen, achievementDetail, selectedActivitiesHistory, confirmAchevement, apiError, loadingDetail, displayName, loading, fullScreen } = this.props;
		const { chatOptions, anchorEl } = this.state;
		// console.log("selectedActivitiesHistory ------->", selectedActivitiesHistory);
		// console.log("achievementDetail ------->", achievementDetail);
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		const achievementDuration = selectedActivitiesHistory && selectedActivitiesHistory.duration && selectedActivitiesHistory.duration !== '-' ? selectedActivitiesHistory.duration : '0s'
		const pauseDuration = achievementDetail && achievementDetail.pauseDuration ? achievementDetail.pauseDuration : null
		const actualDuration = pauseDuration ? this.parseDuration(achievementDuration, pauseDuration) : null
		console.log('actualDuration-->', actualDuration)
		if (selectedActivitiesHistory === null) {
			return (
				<RctCollapsibleCard
					colClasses=""
					heading={<div className="d-flex align-items-center justify-content-between text-white">
						{fullScreen ?
							// <IconButton
							// 	className="ml-auto"
							// 	onClick={() => this.handleCloseHistoryPanel()}>
							// 	<CloseOutlinedIcon />
							// </IconButton>
							<></>
							:
							<React.Fragment>
								<div className="d-flex align-items-center">
									<IconButton title="Back To List" onClick={this.onBackPress.bind(this)}><ArrowBackIcon /></IconButton>
								</div>
								<div>{displayName}</div>
								<div style={{ width: "60px" }}>{" "}</div>
							</React.Fragment>
						}
					</div>
					}
					fullBlock
					customClasses="overflow-hidden theme-background activities-history-details-panel"
					headingCustomClasses={fullScreen ? "bg-lifesherpa p-0" : "bg-lifesherpa"}
				>
					<div className="text-center" style={{ marginTop: 20 + "%", minHeight: "500px" }}>
						<i className="zmdi zmdi-time-restore font-3x mb-2"></i>
						{!loadingDetail && <h4>No Achievement Details Found</h4>}
						<LifeSherpaLoading loading={loadingDetail} />
					</div>
				</RctCollapsibleCard>

			);
		}
		return (
			<RctCollapsibleCard
				colClasses=""
				heading={<div className="d-flex align-items-center justify-content-between text-white">
							{fullScreen ?
								// <IconButton
								// 	className="ml-auto"
								// 	onClick={() => this.handleCloseHistoryPanel()}>
								// 	<CloseOutlinedIcon />
								// </IconButton>
								<></>
								:
								<React.Fragment>
									<div className="d-flex align-items-center">
										<IconButton title="Back To List" onClick={this.onBackPress.bind(this)}><ArrowBackIcon /></IconButton>
									</div>
									<div>{displayName}</div>
									<div style={{ width: "60px" }}>{" "}</div>
								</React.Fragment>
							}
						</div>
						}
				fullBlock
				customClasses="overflow-hidden theme-background activities-history-details-panel"
				headingCustomClasses={fullScreen ? "bg-lifesherpa p-0" : "bg-lifesherpa"}
			>
				<div className="px-3 py-2 list-card sub-header-list-card screen-content-background-color">
					<div className=" d-flex align-items-center mb-1">
						<div className="mr-10" >
							<Avatar
								onClick={this.handleOpenClientDetail.bind(this)}
								width="40"
								height="40"
								title={this.getActivityStatusDetails(selectedActivitiesHistory.status)}
								className={`align-self-center  ${selectedActivitiesHistory.status === 'confirmed' ?
									"achievementStatusConfirmed"
									: selectedActivitiesHistory.status === 'cancelled' ?
										"achievementStatusCancelled"
										: selectedActivitiesHistory.status === 'inProgress' ?
											"achievementStatusInProgress"
											: selectedActivitiesHistory.status === 'confirmedSkippedSteps' ?
												"achievementStatusConfirmedSkippedSteps"
												: selectedActivitiesHistory.status === 'completedConfirmationNeeded' ?
													"achievementStatusCompletedConfirmationNeeded"
													: selectedActivitiesHistory.status === 'incomplete' ?
														"achievementStatusIncomplete"
														: selectedActivitiesHistory.status === 'expired' ?
															"achievementStatusExpired"
															: selectedActivitiesHistory.status === 'confirmedNegative' ?
																"achievementStatusConfirmedNegative"
																: selectedActivitiesHistory.status === 'notStarted' ?
																	"achievementStatusNotStarted"
																	: selectedActivitiesHistory.status === 'paused' ?
																		"achievementStatusPaused"
																		: selectedActivitiesHistory.status === 'completedNoConfirmationNeeded' ?
																			"achievementStatusCompletedNoConfirmationNeeded"
																			: "achievementStatusNotFound"}`}
							>
								{selectedActivitiesHistory.status === 'confirmed' || selectedActivitiesHistory.status === 'confirmedSkippedSteps' || selectedActivitiesHistory.status === 'confirmedNegative' || selectedActivitiesHistory.status === 'completedNoConfirmationNeeded' ?
									<DoneRoundedIcon style={{ fontSize: "2rem" }} />
									: selectedActivitiesHistory.status === 'cancelled' || selectedActivitiesHistory.status === 'incomplete' ?
										<ClearRoundedIcon style={{ fontSize: "2rem" }} />
										: selectedActivitiesHistory.status === 'inProgress' || selectedActivitiesHistory.status === 'paused' ?
											<MoreHorizRoundedIcon style={{ fontSize: "2rem" }} />
											: selectedActivitiesHistory.status === 'expired' || selectedActivitiesHistory.status === 'notStarted' ?
												<AccessAlarmRoundedIcon style={{ fontSize: "2rem" }} />
												: selectedActivitiesHistory.status === 'completedSkippedStepsNoConfirmationNeeded' ?
													<div style={{ fontSize: "2rem" }}>{'S'}</div>
													: selectedActivitiesHistory.status === 'completedConfirmationNeeded' ?
														<div style={{ fontSize: "2rem" }}>{'?'}</div>
														:
														<div style={{ fontSize: "2rem" }}>{'A'}</div>
								}
							</Avatar>
						</div>
						<h2 className="mb-0 mt-0 heading-text">{selectedActivitiesHistory.title}</h2>
						<div className="ml-auto sub-text activity-time-duration">
							{actualDuration ? actualDuration : selectedActivitiesHistory.duration !== '-' ? selectedActivitiesHistory.duration : "-:-"}
						</div>
					</div>
					{achievementDetail &&
						<div className="d-flex justify-content-between">
							<div className="activity-status-details heading-text">
								{achievementDetail.status &&
									<div className="d-flex align-items-center">
										<div className="mr-1">Status:</div><div>{this.getActivityStatusDetails() || achievementDetail.status}</div>
									</div>
								}
								{achievementDetail.startDate &&
									<div className="d-flex align-items-center">
										<div className="mr-1">Start: </div><div>{moment(achievementDetail.startDate, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}</div>
									</div>
								}
								{(achievementDetail.endDate || achievementDetail.cancelDate) &&
									<div className="d-flex align-items-center">
										<div className="mr-1">End: </div><div>{moment(achievementDetail.endDate || achievementDetail.cancelDate, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}</div>
									</div>
								}
								{(achievementDetail.endDate || achievementDetail.confirmation) &&
									<div className="d-flex align-items-center">
										<div className="mr-1">Confirmation: </div>
										<div>
											{achievementDetail.confirmation && achievementDetail.confirmation.confirmationDate ? moment(achievementDetail.confirmation.confirmationDate, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A') : "Pending"}
										</div>
									</div>
								}
							</div>
							{(selectedActivitiesHistory.hasEvaluationForm && auth_uid !== uid) && <div className=" d-flex align-items-center text-right justify-content-end">
								{/* <div className="fs-xs text-muted text-center mr-25"><h2 className="mb-0">{time}</h2><strong>{daysAgo}</strong></div> */}
								<IconButton title="Evaluation Form" className="" size="small" onClick={() => this.handleOpenEvaluationForm()} >
									<AssignmentRoundedIcon style={achievementDetail && achievementDetail.routine.evaluationId && achievementDetail.evaluationResponseId ? { color: 'orange', cursor: 'pointer' } : { color: '#A5A7B2', cursor: 'pointer' }} />
								</IconButton>
							</div>}
						</div>
					}
				</div>
				<div className="chat-main-body">
					{apiError &&
						<div className="col-12 p-4">
							<SweetAlert
								warning
								btnSize="sm"
								customClass="warningText"
								confirmBtnText="OK"
								confirmBtnBsStyle="warning"
								title={apiError && apiError.response ? apiError.response.data.message : apiError.message}
								onConfirm={() => this.props.removeAPIError(null)}
							>
								<div className="sweet-alert-text"></div>
							</SweetAlert>
						</div>
					}

					<Scrollbars
						className="rct-scroll"
						autoHide
						ref="chatScroll"
						style={{ height: this.getScrollHeight(this.state.activeIndex) }}
					>
						<div className="chat-body">
							<AchievementStepsDetail
								selectedActivity={selectedActivitiesHistory}
								achievementDetail={achievementDetail}
								loading={this.props.loadingDetail}
								getPokeForm={this.props.getPokeForm}
								handleOpenForm={this.props.handleOpenForm}
								selectedMemberId={this.props.selectedMemberId}
								getUpdatedEvaluationForm={this.props.getUpdatedEvaluationForm}
								confirmAchevement={confirmAchevement}
								popupThemeClass={this.props.popupThemeClass}
							/>
						</div>
					</Scrollbars>
				</div>
			</RctCollapsibleCard>
		);
	}
}

const mapStateToProps = ({ chatAppReducer, activitiesHistoryList, activitiesList, clientsList, settings }) => {
	const { achievementDetail, confirmedAchevement, showUnconfirmedAcheivemts, loading, allActivitiesHistory, pageCount } = activitiesHistoryList
	const { isChatOpen } = chatAppReducer;
	const { apiError, selectedActivity } = activitiesList;
	let { clientPanel } = clientsList;
	const { popupThemeClass } = settings;
	let { loadingDetail } = activitiesHistoryList;
	loadingDetail = loadingDetail || (allActivitiesHistory === null && loading);

	return { achievementDetail, selectedActivity, loadingDetail, isChatOpen, confirmedAchevement, apiError, clientPanel, popupThemeClass, showUnconfirmedAcheivemts, loading, pageCount };
}

export default withRouter(connect(mapStateToProps, {
	sendMessageToUser,
	activitiesOpen,
	chatOpen,
	showActivityHistoryDetail,
	getPokeForm,
	getUpdatedEvaluationForm,
	confirmAchevement,
	clearHistoryData,
	removeAPIError,
	setSelectedMemberId,
	getSelectedActivity,
	changeRole,
	getResourceList,
	getcategories,
	getKeywords,
	getMyCourses,
	showClientPanel,
	setHistoryListPageCount,
	getHistoryList
})(ActivityHistoryDetail));
