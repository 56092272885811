/**
 * Recent Chat Users
 */
import React, { Component } from 'react';
import List from '@mui/material/List';
import { connect } from 'react-redux';

// components
import NewUserListItem from './NewUserListItem';
import { withRouter } from 'react-router';
import SherpaPopup from '../../../components/SherpaPopup'
import ReactCharts from '../../../components/Charts'
import { getToken } from "../../../firebase"
import config from '../../../config/config'
import {scrollMaincontainerToTop, openPopup} from "../../../helpers/helpers"
// actions
import { hideGroupListLoading, 
	networkActivity, 
	getRealtimeCommonActivityDetailN, 
	chatWithSelectedUser, changeRole, 
	getChartsList, getRecentChatUsers,
	getActivitiesList, 
	clearUnconfirmData, 
	getUnConfirmtActivitiesList, 
	setUserSelected, 
	setSelectedMemberId, 
	getPokeForm, 
	setsavePokeForGroup,
	clearHistoryData,
	showActivityHistoryDetail,
	getClientUnconfirmedAchievementsList,
	handleHideActivityDetailsPanel

	} from '../../../actions';
import WidgetServices from "../../../services/widgetServices";


class RecentChatUsers extends Component {
	state = {
		selectedRoutine: null,
		openForm: false,
		selUser: null,
		id: null, openChart: false, chartParams: {},

	}
	
	componentDidMount() {
		let { selectedGroup, groups, groupMembers } = this.props;
		//this.props.getRealtimeCommonActivityDetailN(selectedGroup,"",[]);
		// this.props.networkActivity();
		if (groupMembers) {
			this.props.hideGroupListLoading();
		}
	}

	/**
	 * Fetch Recent User
	 */
	fetchRecentChatUsers() {
		this.props.getRecentChatUsers();
	}

	/**
	 * Swicth Chat With User
	 * @param {*object} user
	 */
	switchChatWithUser(user) {
		//this.props.chatWithSelectedUser(user);
		const {userId, organization, groupId} = user;
		const widgetServices = new WidgetServices()
		if(widgetServices.isWidgetApp()) {
			const data = {clientId: userId};
			widgetServices.openClientDetailsHandler(data);
		} else {
			this.props.setUserSelected(user);
			localStorage.setItem('uid', userId);
			localStorage.setItem("selectedOrgId", organization)
			this.props.setSelectedMemberId(userId)
			this.props.changeRole({ role: 'client', id: userId })
			//this.props.handleOpenPopup();
			this.props.handleHideActivityDetailsPanel();
			scrollMaincontainerToTop();
			this.props.showUserDetails(true);
		}	
	}

	handleOpenForm() {
		this.props.handleOpenForm()
		this.props.getPokeForm()
		this.props.setsavePokeForGroup()
		this.setState({ openForm: true })
	}

	handleOpenUnconfirmAchievements = (userId, key, groupMembers, orgId) => {
        this.props.clearHistoryData();
		const widgetServices = new WidgetServices()
		if(widgetServices.isWidgetApp()) {
			const data = {clientId: userId};
			widgetServices.openUnconfirmedAchievementsHandler(data);
		} else {
			const activityConfirmation = localStorage.getItem("activityConfirmation");
			if(activityConfirmation) {
				localStorage.removeItem("activityConfirmation");
			}
			this.props.setSelectedMemberId(userId);
			localStorage.setItem("uid",userId);
			localStorage.setItem("selectedOrgId", orgId)
			scrollMaincontainerToTop();
			this.props.getClientUnconfirmedAchievementsList(userId, null); 
			this.props.showActivityHistoryDetail(true);
		}
		
		// console.log(" Open unconfirmed achievments for user ---> ", user);
		// this.props.setSelectedMemberId(user)
		// this.props.getClientUnconfirmedAchievementsList(user, 1);
		// this.props.handleOpenSherpaPopup()
		// this.setState({ selUser: groupMembers, id: user })
	}
	

	handleCloseSherpaPopup = () => {
		this.props.setSelectedMemberId(null);
		this.props.handleOpenSherpaPopup()
	}

	handleOpenPopup = () => {
		// this.props.clearUnconfirmData()
		//this.props.handleOpenPopup()
	}

	handleSelectedUser(user) {

	}

	handleClose(activity) {
		this.setState({ selectedRoutine: activity ? activity.title : '' })
		this.props.handleOpenForm(activity)
		this.props.getPokeForm()
		this.setState({ openForm: false })
	}


	handleChart = (user) => {
		let idToken = localStorage.getItem('idToken')
		const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId")
		this.props.getChartsList(user.id, orgId, idToken, 'users')
		let param = { id: user, orgId: orgId, idToken: idToken, module: 'users' }
		this.setState({ openChart: true, chartParams: param })
		//this.props.history.push('/react/charts?id='+activity.id+'&idToken='+idToken+'&orgId=Test&module=activities')
		//http://configurator.lifesherpa.local:8000/react/charts?id=-LLcH5J_JYBRfzNS_yap&idToken=eyJhbGciOiJSUzI1NiIsImtpZCI6IjJjOGUyYjI5NmM2ZjMyODRlYzMwYjg4NjVkNzI5M2U2MjdmYTJiOGYiLCJ0eXAiOiJKV1QifQ.eyJEZWxldGVJbWFnZXMiOnRydWUsIm9yZ2FuaXphdGlvbiI6IlRlc3QiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbHMtY29uZmlnLW1pZ3JhdGlvbiIsImF1ZCI6ImxzLWNvbmZpZy1taWdyYXRpb24iLCJhdXRoX3RpbWUiOjE2MTg5ODgzMjIsInVzZXJfaWQiOiIwSTFhSklrZUhQT2RHNFY4OTZ4bm12anJTdHExIiwic3ViIjoiMEkxYUpJa2VIUE9kRzRWODk2eG5tdmpyU3RxMSIsImlhdCI6MTYxOTAxMTcyNSwiZXhwIjoxNjE5MDE1MzI1LCJlbWFpbCI6ImxpZmVzaGVycGErYXV0aDB8NWZjZTBkYjZhNTFmMzYwMDZlNGUyYjU4QDNyYmVoYXZpb3JhbHNvbHV0aW9ucy5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibGlmZXNoZXJwYSthdXRoMHw1ZmNlMGRiNmE1MWYzNjAwNmU0ZTJiNThAM3JiZWhhdmlvcmFsc29sdXRpb25zLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.bMAWFavZY068WUPLuIsTN1Q8JxEEMgwpkD1qJK47H1cTUg9drqCCdyv52Wb5XEbNo8sk0cJJ3fsM2fcndb8jrrnMwvF4pr5VIJVcY7i6rb3RnGpI1GjHibu8N7913lMfZzQ5XOJ08klZxDY9dg-AiToHVASuATfp5O6QobdZWEQfun8sBu1AFR3EJR7XKhNwoHdXffQpDWHz9Q-Q3N5zES9kUzMB6ycU_KfPpDFzSJhZSC_-Wa9pkQVQGygi0ZXOfNWLoFyvhwfAZji4OdrSdenbr0ASr1SWvDM429dEuMLMEWxHf3S8FNJ53q0f_NKIVNG7Ze_qxRKJhu9aRpwxtg&orgId=Test&module=activities
	}
	chartClose = () => {
		this.setState({ openChart: false })
	}

	async handleOnView(userId, orgId) {
		const result = await getToken()
		if (result) {
			// window.open(`${config.configurator}/#/login?ssotoken=${result}`, '_blank');
			// window.open(`${config.configurator}/#/lifesherpa/user?ssotoken=${result}&orgId=${orgId}&id=${userId}#user`, '_blank');
			// window.open(`${config.configurator}/#/lifesherpa/user?ssotoken=${result}#user`, '_blank');
			var windowReference = window.open();
			windowReference.location = `${config.configurator}/#/lifesherpa/user?ssotoken=${result}#user`;
			windowReference.location = url;
		}
	}

	getAllActivitiesOfUser = (userId, groupId) => {
		let userData = (this.props.groupMembers[groupId] && this.props.groupMembers[groupId][userId]) || {};
		let inprogessActivities = userData.achievementsInProgress;
		let userActivities = [];
		if (inprogessActivities) {
            // console.log("inprogessActivities ---->",inprogessActivities);
			Object.keys(inprogessActivities).map((objKey, key) => {
				let inprogressAchievement = inprogessActivities[objKey]
				inprogressAchievement['id'] = objKey
				let activity = inprogessActivities[objKey];
				let data = {};
				data.id = objKey;
				data.title = activity.routine ? activity.routine.title : "";
				data.state = activity.state;
				data.status = activity.status;
				data.preview = activity.preview;
                data.count = activity.stepsCount;
				// data.completed = activity.StepsCompletion ? Object.keys(activity.StepsCompletion).length : 0
				let stepsCompletion = activity.StepsCompletion  ? activity.StepsCompletion  : {};
				let stepsCompletionList = [];
				if (stepsCompletion) {
				  Object.keys(stepsCompletion).map((stepCompId, key) => {
					   stepsCompletionList.push(stepsCompletion[stepCompId]);
				  });
				  stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" )
				}
				data.completed = stepsCompletionList.length;
				if(data.completed > 0 && stepsCompletionList[data.completed - 1].step) {
					data.nextStep = stepsCompletionList[data.completed - 1].step.title;
				}
				data.detailedDescription = activity.routine ? activity.routine.detailedDescription || "" : "";
				if(!data.preview && data.count && (data.state === "paused" || data.state === "inProgress" || data.state === undefined)) {
					userActivities.push(data);
				}
			})

		}
		return userActivities;
	}

	render() {
		const { groupMembers, selectedMemberId, groupMembersDetails, groupMembersList, realtimeUpdate, loading} = this.props;
		
		if (loading) {
			return (
				<div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
					<h4>Fetching Members...</h4>
				</div>
			);
		} else if (groupMembers === null || groupMembersList.length === 0 && !loading) {
			return (
				<div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
					<h4>No Group member Found</h4>
				</div>
			);
		}

		return (<React.Fragment>
				{groupMembersList.map((user, key) => (
					<div data-testid={`my-clients-memebers-list${key}`} className="mx-2 mb-2" key={key}>
						<NewUserListItem
							selectedUser={selectedMemberId}
							user={user}
							chatUser ={user.chatUser}
							memberDetails={groupMembersDetails && groupMembersDetails.filter(gmember => gmember.userId === user.userId)[0]}
							userId={user.userId}
							allActivities={this.getAllActivitiesOfUser(user.userId, user.groupId)}
							itemIndex={key}
							handleOpenForm={() => this.handleOpenForm()}
							handleOpenUnconfirmAchievements={() => this.handleOpenUnconfirmAchievements(user.userId, key, groupMembers[user.userId], user.organization)}
							onClickListItem={() => this.switchChatWithUser(user)}
							handleOnView={() => this.handleOnView(user.userId, groupMembers[user.userId]["user"]["organization"])}
                            handleOpenAssingActivity={this.props.handleOpenAssingActivity}
							showChatPanelForSelectedUser={this.props.showChatPanelForSelectedUser}
							/>

					</div>
				))}
				{this.props.open && <SherpaPopup
					open={this.props.open}
					value={'ChatUSers'}
					handleClose={() => this.handleCloseSherpaPopup()}
					selectedUser={this.state.selUser ? this.state.selUser.user : ''}
					selectedMemberId={this.state.id}
					profileImage={this.state.selUser && this.state.selUser.user.profileImageURL ? this.state.selUser.user.profileImageURL : require('Assets/avatars/user-15.jpg')}
					displayName={this.state.selUser && this.state.selUser.user.displayName ? this.state.selUser.user.displayName : 'Display Name'}
					length={Object.keys(this.state.selUser.unconfirmedAchievements).length}
					/>}
			</React.Fragment>
		);
	}
}


const mapStateToProps = ({ emailApp, chatAppReducer, activitiesList, PokeForm, activitiesHistoryList, GroupList, clientsList, Contacts }) => {
	const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
	const { selectedUser, displayName, profileImage, selectedMemberId } = chatAppReducer
	const { allActivities, privateActivities, loading, allUserActivities, realtimeUpdate } = activitiesList
	const { selectedActivitiesHistory, loadingDetail, selectedStepId } = activitiesHistoryList

	// let pokeForm = PokeForm.pokeForm
	const { pokeForm, isPokeForGroup } = PokeForm
	let pokeFormLoading = PokeForm.loading
	const { selectedGroup, groups, groupMembers } = GroupList
	let { clientPanel } = clientsList;
	let {contactList} = Contacts;
	return {contactList, clientPanel, allUserActivities, currentEmail, sendingEmail, showActivityHistory, selectedMemberId, showConfirmation, selectedUser, allActivities, privateActivities, loading, pokeFormLoading, pokeForm, displayName, profileImage, selectedActivitiesHistory, loadingDetail, selectedStepId, selectedGroup, isPokeForGroup, groups, groupMembers, realtimeUpdate };
}

export default withRouter(connect(mapStateToProps, {
	chatWithSelectedUser,
	getRecentChatUsers,
	getActivitiesList,
	setUserSelected,
	setSelectedMemberId,
	changeRole,
	getPokeForm, getChartsList,
	setsavePokeForGroup, getUnConfirmtActivitiesList, clearUnconfirmData,
	getRealtimeCommonActivityDetailN,
	networkActivity,
	hideGroupListLoading,
	clearHistoryData,
	showActivityHistoryDetail,
	getClientUnconfirmedAchievementsList,
	handleHideActivityDetailsPanel
})(RecentChatUsers));