/**
 * Rct Page Loader
 */
 import React from 'react';
 import CircularProgress from '@mui/material/CircularProgress';
 
 const LifeSherpaPageLoader = () => (
      <div className="d-flex justify-content-center loader-overlay">
        <CircularProgress className="theame-text"/>
     </div>
 );
 
 export default LifeSherpaPageLoader;
 