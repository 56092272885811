import {
     GET_BUY_REWARDS,
     GET_PENDING_REWARDS,
     GET_RECIVED_REWARDS,
     BUY_NEW_REWARD,
     BUY_NEW_REWARD_SUCCESS,
     GET_REWARD_DATA_LOADING,
     HIDE_REWARD_DATA_LOADING,
     CONFIRM_REWARDS,
     CONFIRM_REARDS_SUCCESS,
     CLEAR_REWARDS_DATA
} from '../actions/types';

const INIT_STATE = {
    
    buyReward:null,
    pendingReward:null,
    recivedReward:null,
    loading:false,
    confirmedRewards: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REWARD_DATA_LOADING:
            return { ...state, loading:true};
        case GET_BUY_REWARDS:
            return { ...state, buyReward:action.payload, loading:false };
        case GET_PENDING_REWARDS:
            return { ...state, pendingReward:action.payload, loading:false };
        case GET_RECIVED_REWARDS:
            return { ...state,recivedReward:action.payload, loading:false};
        case BUY_NEW_REWARD:
            return {...state,loading:true} 
        case BUY_NEW_REWARD_SUCCESS:
            return {...state,loading:false} 
        case HIDE_REWARD_DATA_LOADING:
            return { ...state, loading:false};
        case CONFIRM_REWARDS:
            return { ...state, loading: true};
        case CONFIRM_REARDS_SUCCESS:
            return { ...state, confirmedRewards: action.payload, loading: false};
        case CLEAR_REWARDS_DATA:
            return { ...state, buyReward:null, pendingReward:null, recivedReward:null};
        default: 
            return { ...state };
        }
}
