/**
 * User List Item
 */
import React, { useEffect } from 'react';
import {
    Label,
} from 'reactstrap';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const TimerOptionItem = ({ obj, handleMinus, handlePlus, section, sectionObj, showError, updateform }) => {
    useEffect(() => {
        if(!obj.value) {
            if(obj.value === 0) {
                return;
            }
            obj.value = obj.minValue || 0;
            updateform(null, obj, section);
        }
       
    }, []);
    return (
        <div data-testid={`timer-option-item-component-for-number-${section}`} key={section}>
            {obj.heading ? <Label className="mb-0 form-label">{`${obj.heading}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></Label> : <Divider className="my-2" />}
            <div className="d-flex align-items-center theme-background form-margin">
                <p className="mb-0">{obj.text}</p>
                <IconButton onClick={() => handleMinus(obj, section, sectionObj)} color="primary"><RemoveIcon /></IconButton>
                <Label className="mb-0 timer-value-color">{obj.value ? obj.value : obj.minValue}</Label>
                <IconButton onClick={() => handlePlus(obj, section, sectionObj)} color="primary"><AddIcon /></IconButton>
            </div>
            {showError && obj.isMandatory && (obj.value === undefined || obj.value === null) && <div className="input-options-error">{`${obj.heading || obj.text || ""} section is required`}</div>}

            {/*<Divider className="mb-2"/>*/}
        </div>
    )
};

export default TimerOptionItem;
