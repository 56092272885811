import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from "@auth0/auth0-react";
import { Form, FormGroup } from 'reactstrap';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SweetAlert from 'react-bootstrap-sweetalert';
import QueueAnim from 'rc-queue-anim';
import config from '../../config/config';
import { configureStore } from '../../store';
import LifeSherpaLoading from '../LifeSherpaLoading';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton';
import { resetReduxData } from '../../actions';
import queryString from 'query-string';

class Auth0RedirectSignInPage extends Component {
   constructor(props) {
      super(props);
      this.interval = null
      this.state = {
         getTokenAccessFailed: "",
         error: "",
         error_description: ""
      };
      this.onConfirm = this.onConfirm.bind(this);
      this.validateAuthentication = this.validateAuthentication.bind(this);
   }

   componentDidMount() {
      this.interval = setInterval(this.validateAuthentication, 5000)
   }

   validateAuthentication() {
      const { user, isAuthenticated, getAccessTokenSilently} = this.props.auth0;
      const { userOrgDetail, userOrgFromToken, privacyPolicy } = this.props;
      const region = userOrgDetail?.region || userOrgFromToken?.region || "";
      if (privacyPolicy && privacyPolicy.publicSettings) {
         this.getAuth0AccessToken(privacyPolicy.publicSettings.audience, getAccessTokenSilently, user, isAuthenticated, region);
      }
   }

   componentWillUnmount(){
      clearInterval(this.interval)
   }

   async getAuth0AccessToken(audience, getAccessTokenSilently, user, isAuthenticated, region) {
      try {
         const token = await getAccessTokenSilently({ audience: audience, scope: 'read:posts' });
         if (isAuthenticated) {
            clearInterval(this.interval)
            const authResult = { accessToken: token };
            const profileResult = user;
            localStorage.setItem("user_id", "user-id");
            const store = configureStore();
            store.dispatch({ type: 'LOGIN_USER_SUCCESS', payload: authResult });
            store.dispatch({ type: 'GET_USER_INFO', payload: profileResult });
            window.location.replace(`/loginsuccess?region=${region}`);
         } 
      } catch (error) {
         clearInterval(this.interval)
         const params = queryString.parse(this.props.location.search);
         console.log(error);
         let errorMessage = "Something went wrong, try again!";
         let errorTitle = params && params.error ? params.error.charAt(0).toUpperCase() + params.error.slice(1) : "Auth0 Error";
         let errorDescription = params && params.error_description ? params.error_description : "Something went wrong, try again!";
         if (!params || !params.error) {
               errorMessage = "Auth0 Error";
               errorTitle = "Something went wrong, try again!";
               errorDescription = "Something went wrong, try again!";
         }
         this.setState({ getTokenAccessFailed: errorMessage, error: errorTitle, error_description: errorDescription });
      }
   }

   onConfirm() {
      this.setState({ getTokenAccessFailed: "", error: "", error_description: "" });
      this.props.resetReduxData();
      this.props.auth0.logout({ returnTo: window.location.origin, federated: true });
   }

   render() {
      const { screenThemeClass, themeLogo, activeTheme, themeData } = this.props;
      return (
         <QueueAnim type="bottom" duration={2000}>
               <div className={`${config.rctSessionWrapper} ${screenThemeClass}`}>
                  <LifeSherpaLoading loading={true} />
                  <div className='login-screen'>
                     <AppBar position="static" className="session-header">
                           <Toolbar>
                              <HeaderWithCancelButton activeTheme={activeTheme} themeLogo={themeLogo} screenThemeClass={screenThemeClass} themeData={themeData} location={this.props.location} href={`/success`} />
                           </Toolbar>
                     </AppBar>
                     <div className="session-inner-wrapper">
                           <div className="container">
                              <div className="row row-eq-height">
                                 <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                 <div className="col-sm-12 col-md-6 col-lg-6">
                                       <div className="session-body text-center pt-2">
                                          <Form>
                                             <FormGroup className="mb-10">
                                                   <p className="mb-0 loader-text">Please wait fetching user info...</p>
                                             </FormGroup>
                                          </Form>
                                       </div>
                                 </div>
                                 <div className="col-sm-12 col-md-3 col-lg-3"></div>
                              </div>
                           </div>
                     </div>
                  </div>
               </div>
               {
                  this.state.getTokenAccessFailed !== "" &&
                  <SweetAlert
                     warning
                     btnSize="sm"
                     customClass="warningText"
                     show={this.state.getTokenAccessFailed !== ''}
                     confirmBtnText="Back To Launch Screen"
                     confirmBtnBsStyle="warning"
                     title={this.state.error}
                     onConfirm={this.onConfirm}
                  >
                     {this.state.error_description}
                  </SweetAlert>
               }
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings }) => {
   const { screenThemeClass, themeLogo, activeTheme } = settings;
   const { user, loading, privacyPolicy, userOrgDetail, userOrgFromToken, themeData } = authUser;
   return { user, loading, privacyPolicy, userOrgDetail, userOrgFromToken, screenThemeClass, themeLogo, activeTheme, themeData };
}

export default connect(mapStateToProps, { resetReduxData })(withAuth0(Auth0RedirectSignInPage));