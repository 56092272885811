/**
* Main App
*/
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserProvider from "../src/providers/UserProvider"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// css
import './lib/reactifyCss';
// app component
import App from './container/App';
// import Clientslider from "./routes/about-us/components/client-slider"
import { configureStore } from './store';

const MainApp = () => (
	<Provider store={configureStore()}>
		<UserProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Router>
					<Switch>
						<Route path="/" component={App} />
					</Switch>
				</Router>
			</LocalizationProvider>
		</UserProvider>
	</Provider>
);

export default MainApp;