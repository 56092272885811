/**
 * React-Chartjs2
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Doughnut from './doughnut';
import LineChart from './line-chart';
import Bubble from './bubble';
import Bar from './bar';
import Polar from './polar';
import Pie from './pie';
import Radar from './radar';
import HorizontalBar from './horizontal-bar';
import ScatterChartComponent from './scatter'
import StackedChartComponent from './stackedChart'
import {Label} from 'reactstrap'
import DatePickers from '../../components/Pickers/DatePickers'
import moment from 'moment'
import IntlMessages from "../../util/IntlMessages"
import ChartConfig from '../../constants/chart-config';
import queryString from 'query-string';
import LifeSherpaLoading from '../LifeSherpaLoading';
//actions
import {getChartsList, getChartById, getFilterChartById, showActivityChartsLoading} from 'Actions'

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

class ReactCharts extends Component {
	constructor(props){
		super(props)
		this.state = {
			open: true,
			fromDate: moment().subtract(1, 'years').format('MMM DD YYYY'),
			toDate: moment().format('MMM DD YYYY'),
		}
		this.getChartData = this.getChartData.bind(this)
		this.handleFromDateChange = this.handleFromDateChange.bind(this)
		this.handleToDateChange = this.handleToDateChange.bind(this)
		this.getParams = this.getParams.bind(this)
		this.getChartDataById = this.getChartDataById.bind(this)
	}

	componentDidMount(){
		const params = this.getParams()
		this.props.getChartsList(params.id, params.orgId, params.idToken, params.module)
	}

	getChartData(chartData){
		const labels = chartData.labels
		const datasets = []
		chartData.series.map((item, key) => {
			const innerData = {
				label: item,
				fill: false,
				backgroundColor: ChartConfig.colorArray[key],
				borderColor: ChartConfig.colorArray[key],
				borderWidth: 1,
				hoverBackgroundColor: ChartConfig.colorArray[key],
				hoverBorderColor: ChartConfig.colorArray[key],
				data: chartData.data[key]
			}
			datasets.push(innerData)
		})
		return {labels, datasets}
	}

	handleFromDateChange(date){
		const filterData = {fromDate: date.format('YYYY-MM-DD HH:mm:ss'), toDate: moment(this.state.toDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss')}
		this.setState({fromDate: date, filterData})
		this.props.usersCharts && this.props.usersCharts.map(async item => {
			await this.filterChartData(item.chartId, filterData)
		})
	}
	handleToDateChange(date){
		const filterData = {fromDate: moment(this.state.fromDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss'), toDate: date.format('YYYY-MM-DD HH:mm:ss')}
		this.setState({toDate: date, filterData})
		this.props.usersCharts && this.props.usersCharts.map(async item => {
			await this.filterChartData(item.chartId, filterData)
		})	
	}

	getParams(){
        let params = null
        if (this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (getCookies('chartCookieData')) {
            params = JSON.parse(getCookies('chartCookieData'))
        } 
        return params
	}
	async filterChartData(chartId, filterData){
		const params = this.getParams()
		await this.props.getFilterChartById(params.id, params.orgId, params.idToken,  params.module, chartId, filterData) 
	}
	async getChartDataById(chartId){
		const filterData = {fromDate: moment(this.state.fromDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss'), toDate: moment(this.state.toDate, 'MMM DD YYYY').format('YYYY-MM-DD HH:mm:ss')}
		const params = this.getParams()
		await this.props.getFilterChartById(params.id, params.orgId, params.idToken,  params.module, chartId, filterData)
	}

	render() {
		const {popupThemeClass, usersCharts, loading, loadingComponent} = this.props
		const chartData = usersCharts 
		console.log("chart data of user",chartData);
		return (
			<Dialog
                    fullScreen={true}
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth="xl"
                    aria-labelledby="responsive-dialog-title"
                    className={`${popupThemeClass} chart`}
                >
				<AppBar position="static" className="session-header">
					<Toolbar>
						<div className="d-flex template-header align-items-center justify-content-between p-2 pt-4 pb-4 w-100" >
							<Label data-testid="title-name" className="listItemTitle mb-0">Charts</Label>
							<div data-testid="close-button" className="backbutton" /*onClick={this.handleClose}*/><a className="linkButton" target="_self" href="/success" ><CloseRoundedIcon /></a></div>
						</div>
					</Toolbar>
				</AppBar>
				<DialogContent className="pl-2 pr-2">
				<LifeSherpaLoading loading={loading && !usersCharts}/>
				<div className="chartjs-wrapper">
					<RctCollapsibleCard heading="Filter  Chart By Date Range">
						<div className="d-flex align-items-center">
							<div className="pr-2"><DatePickers value={this.state.fromDate} label={<IntlMessages id="chart.fromDate"/>} testid={"DatePicker"} handleOnDateChange={this.handleFromDateChange.bind(this)}/></div>
							<DatePickers value={this.state.toDate} label={<IntlMessages id="chart.toDate"/>} testid={"DatePicker"} handleOnDateChange={this.handleToDateChange.bind(this)}/>
						</div>
					</RctCollapsibleCard>
					{ chartData && chartData.length > 0 ? chartData.map((userChart, key) => (
						<div key={key}>
							
								{userChart.class === 'doughnut' && <RctCollapsibleCard heading={userChart.title}>
									<Doughnut data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false } />
								</RctCollapsibleCard>}
								{userChart.class === 'line' && <RctCollapsibleCard heading={userChart.title}>
									<LineChart data={this.getChartData(userChart)}  getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'bubble' && <RctCollapsibleCard heading={userChart.title}>
									<Bubble data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'bar' && !userChart.options && <RctCollapsibleCard heading={userChart.title}>
									<Bar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'bar' && userChart.options &&  userChart.options.scales && userChart.options.scales.xAxes && userChart.options.scales.yAxes && userChart.options.scales.xAxes[0].stacked && userChart.options.scales.yAxes[0].stacked && <RctCollapsibleCard heading={userChart.title}>
									<StackedChartComponent data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false} />
								</RctCollapsibleCard>}
								{userChart.class === 'polar' && <RctCollapsibleCard heading={userChart.title}>
									<Polar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'pie' && <RctCollapsibleCard heading={userChart.title}>
									<Pie data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'radar' && <RctCollapsibleCard heading={userChart.title}>
									<Radar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'horizontalbar' && <RctCollapsibleCard heading={userChart.title}>
									<HorizontalBar data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
								{userChart.class === 'scatter' && <RctCollapsibleCard heading={userChart.title}>
									<ScatterChartComponent data={this.getChartData(userChart)} getChartData={this.getChartDataById} chartId={userChart.chartId} loading={loadingComponent[userChart.chartId] ? true : false}/>
								</RctCollapsibleCard>}
						</div>
					)) :
						<RctCollapsibleCard>
							<Label className="text-center">There is no chart data available in this module, please contact to administrator for more info.</Label>
						</RctCollapsibleCard>
					}
				</div>
			</DialogContent>
		</Dialog>
		);
	}
}

// map state to props
const mapStateToProps = ({settings, Charts }) => {
	const {popupThemeClass} = settings
	const {usersCharts, loading, loadingComponent} = Charts
	let datas = []
	usersCharts && usersCharts.length > 0 ? usersCharts.map(item => {datas.push(item.data)}) : []
   return {popupThemeClass,usersCharts, loading, datas, loadingComponent}
}

export default connect(mapStateToProps,{
	getChartsList, 
	getChartById,
	getFilterChartById,
	showActivityChartsLoading 
})(ReactCharts);

