import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Scrollbars from "react-custom-scrollbars"
import LifeSherpaLoading from '../LifeSherpaLoading';
import {
    getNoticesList,
} from '../../actions';

class NoticesList extends Component {
    state = {}
    componentDidMount() {
        const { selectedUserId } = this.props;
        this.props.getNoticesList(selectedUserId);
    }

    getScrollHeight() {
        const { widgetApp } = this.props;
        if (widgetApp) {
            return '100%';
        } else {
            return 'calc(100vh - 140px)';
        }
    }

    render() {
        const { notices, noticeLoading } = this.props;
        console.log("notices :", notices);
        return (
            <Scrollbars
                className="rct-scroll "
                autoHide
                style={{ height: this.getScrollHeight() }}
            >
                <div className='px-2 pt-0 pb-3'>
                    <LifeSherpaLoading loading={this.props.noticeLoading} />
                    {notices && notices.length > 0 ?
                        <div data-testid="notices-list">
                            {
                                notices.map((notice, key) =>
                                    <li key={`${key}-notice`} data-testid={`notices-list-item-${key}`} className="d-flex align-items-center contacts-list-item list-card mb-1 list-item p-2 my-2 border-bottom" style={{ cursor: 'pointer' }} onClick={() => this.props.handleOpenNoticeForm(notice)}>

                                        <div className="alert-description mx-2 w-80">
                                            <h4 className='my-0'>{notice.title}</h4>
                                            {notice.description ? <div className='font-sm alert-desc-truncate'>{notice.description}</div> : <></>}
                                        </div>
                                        <IconButton className=" ml-auto"><ArrowRightIcon color="primary" /></IconButton>
                                    </li>
                                )
                            }
                        </div>

                        :
                        <div className='d-flex align-items-center my-4 py-4 mx-auto justify-content-between'>
                            <div className='text-center w-100 mt-4 pt-4'>
                                {noticeLoading ? "Fetching Notices..." : "Notices Not Found"}
                            </div>
                        </div>

                    }
                </div>
            </Scrollbars>
        );
    }
}

const mapStateToProps = ({ noticesReducer }) => {
    const { notices, noticeLoading, selectedUserId } = noticesReducer;
    return { notices, noticeLoading, selectedUserId };
}

export default connect(mapStateToProps, { getNoticesList })(NoticesList);
