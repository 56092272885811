import {
    CREATE_ACTIVITY,
    OPEN_LMS_COURSE,
    OPEN_ASSIGN_ACTIVITY,
    OPEN_CLIENT_CHAT,
    OPEN_UNCONFIRMED_ACHIEVEMENTS,
    OPEN_CLIENT_DETAILS,
    ACTIVITY_PREVIEW,
    OPEN_LAUNCHPAD_URL,
    TOUCHPOINT_ACTIVITIES,
    AGENDA_VIEW_ACTIVITY,
    ID_TOKEN_EXPIRED,
    OPEN_ACTIVITY_HISTORY,
    OPEN_EDIT_ACTIVITY,
    EXECUTE_ACTIVITY,
    RESUME_ACTIVITY,
    DOWNLOAD_FROM_URL,
    CLIENT_ALERTS,
    SHARE_CLIENT_NOTICE,
    OPEN_CLIENT_NOTICE_PDF
} from "./widgetActionsType";

class WidgetServices {

    constructor() {
        // const widget = localStorage.getItem("widgetApp");
        try {
            if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
                this.widgetApp = window.webkit.messageHandlers.toggleMessageHandler;
            } else if(JSBridge) {
                this.widgetApp = JSBridge
            } else {
                this.widgetApp = null;
            }
        } catch(error) {
            //console.log("Error coccured while fetching message handler app:", error);
            this.widgetApp = null;
           // this.widgetApp = window;
        }
    }

    isWidgetApp() {
        if(this.widgetApp || localStorage.getItem("widgetApp")) {
            const themeObj = (localStorage.getItem("theme") && JSON.parse(localStorage.getItem("theme"))) || {name :"Default"};
			const theme = themeObj && themeObj.name ?  themeObj.name.charAt(0).toUpperCase() + themeObj.name.slice(1) : "Default";
			const orgId = localStorage.getItem("orgId")
			const userId = localStorage.getItem("auth_uid");
            const selectedGroup = localStorage.getItem("selectedGroup");
            this.user = {userId, orgId, theme, selectedGroup};
            return true;
        } else {
            return false;
        }
       
    }
    
    isWidgetOnIOSApp() {
        try {
            if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
                console.log("Widget is running on IOS App");
                return true;
            } else {
                return false;
            } 
        } catch(error) {
            console.log("Failed to get widget app info: ", error);
           return false;
        }
    }


    addRoutineHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": CREATE_ACTIVITY,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", CREATE_ACTIVITY)
    }
    openLMSCourseHandler(data) {
        const payload = {...data};
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_LMS_COURSE,
                "data": payload
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_LMS_COURSE)
    }
    openAssignActivityHandler(data) {
        if(!data.groupId) {
            data.groupId = this.user.selectedGroup;
        }
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_ASSIGN_ACTIVITY,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_ASSIGN_ACTIVITY)
    }
    openClientChatHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_CLIENT_CHAT,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_CLIENT_CHAT, data)
    }

    openUnconfirmedAchievementsHandler(data) {
        if(!data.groupId) {
            data.groupId = this.user.selectedGroup;
        }

        if ( this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_UNCONFIRMED_ACHIEVEMENTS,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        
        console.log("post message sent : ", OPEN_UNCONFIRMED_ACHIEVEMENTS, data)
    }

    openClientDetailsHandler(data) {
        if(!data.groupId) {
            data.groupId = this.user.selectedGroup;
        }
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_CLIENT_DETAILS,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_CLIENT_DETAILS)
    }

    activityPreviewHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": ACTIVITY_PREVIEW,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", ACTIVITY_PREVIEW)
    }

    launchpadUrlHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_LAUNCHPAD_URL,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_LAUNCHPAD_URL)
    }

    openEncounterActivitiesHandler(data) {
        if(!data.groupId) {
            data.groupId = this.user.selectedGroup;
        }
        if (this.widgetApp ) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": TOUCHPOINT_ACTIVITIES,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", TOUCHPOINT_ACTIVITIES)
    }

    agendaViewActivity() {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": AGENDA_VIEW_ACTIVITY
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", AGENDA_VIEW_ACTIVITY)
    }

    idTokenExpiredHandler() {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": ID_TOKEN_EXPIRED,
                "message": "Token has been expired, please re-authenticate token and refresh the widget"
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", ID_TOKEN_EXPIRED)
    }

    activityHistoryHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_ACTIVITY_HISTORY,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_ACTIVITY_HISTORY, data)
    }

    activityEditHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_EDIT_ACTIVITY,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_EDIT_ACTIVITY)
    }

    executeActivityHandler(data) {
        if (this.widgetApp) {
            this.widgetApp.postMessage(JSON.stringify({
                "action": EXECUTE_ACTIVITY,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", EXECUTE_ACTIVITY)
    }

    resumeActivityHandler(data) {
        if (this.widgetApp) {
                this.widgetApp.postMessage(JSON.stringify({
                "action": RESUME_ACTIVITY,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", RESUME_ACTIVITY)
    }

    downloadPostHandler(data) {
        if (this.widgetApp) {
                this.widgetApp.postMessage(JSON.stringify({
                "action": DOWNLOAD_FROM_URL,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", DOWNLOAD_FROM_URL)
    }

    handleOpenNoticePanel(data) {
        if (this.widgetApp) {
                this.widgetApp.postMessage(JSON.stringify({
                "action": CLIENT_ALERTS,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", CLIENT_ALERTS, data)
    }

    handleShareNotice(data) {
        if (this.widgetApp) {
                this.widgetApp.postMessage(JSON.stringify({
                "action": SHARE_CLIENT_NOTICE,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", SHARE_CLIENT_NOTICE, data)
    }

    handleOpenNoticePDF(data) {
        if (this.widgetApp) {
                this.widgetApp.postMessage(JSON.stringify({
                "action": OPEN_CLIENT_NOTICE_PDF,
                "data": data
            }));
        } else {
            console.log("widgetApp : ", this.widgetApp)
        }
        console.log("post message sent : ", OPEN_CLIENT_NOTICE_PDF, data)
    }

}

export default WidgetServices;