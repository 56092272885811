/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux';
//Actions
import {handleShowPhotoCamera} from 'Actions'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import SweetAlert from 'react-bootstrap-sweetalert'
import {firebase} from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Moment from 'moment'
import LifeSherpaLoading from '../LifeSherpaLoading';
import CameraComponent from "./CameraComponent";

 class SherpaCamera extends Component {

    state = {
        stream:null,
        showCamera: true,
        dataUri:"",
        cameraLoading:true,
        cameraSupportWarning:false,
        warningText: "Camera Not Supported"
    }
    
    handleTakePhoto = (dataUri)  => {
        // Do stuff with the photo...
        console.log('  takePhoto url -->', dataUri);
        this.setState({dataUri: dataUri, showCamera:false, cameraLoading:false});
    }

    handleCameraStart = (stream) => {
        console.log(" Camera Started --> ", stream);
        this.setState({stream: stream, cameraLoading:false});
    }

    handleCameraStop = () => {
        console.log(" camera stopped ");
    }

    handleRetake = () => {
        this.setState({showCamera:true, cameraLoading: true});
    }

    handleUsePhoto = () => {
        let {dataUri} = this.state;
        if (this.props.handleUploadImage) {
            this.props.handleUploadImage(dataUri)
        } else {
            this.handleUploadPicture(dataUri)
        }
        //this.props.handleShowPhotoCamera(false)
    }
    handleCameraSupportError = (e) => {
        console.log(" camera not supported --> ", e);
        if((e+"").includes("Permission denied")) {
            this.setState({cameraSupportWarning: true, showCamera: false, warningText: "Camera Permission denied" })
        } else {
            this.setState({cameraSupportWarning: true, showCamera: false})
        }
    }
    handleClose = () => {
        this.props.handleClosePictureCamera("Camera Not Supported");
    }
    handleUploadPicture = (dataUri) => {
    const {userName} = this.props;
    var filename = `${Moment().format('YYYY-MM-DD-HH-mm-ss')}-${userName}.jpeg`;
    this.setState({loading:true});
    fetch(dataUri).then(res => {
        return res.blob();
        }).then(blob => {
            //uploading blob to firebase storage
        const userId = localStorage.getItem("auth_uid");
        const storage = getStorage(firebase)
        const imgStorageRef = ref(storage, `RoutinesConfirmationPictures/${userId}/${filename}`)
        uploadBytes(imgStorageRef, blob).then( async function(snapshot) {
            return await getDownloadURL(imgStorageRef)
        }).then(url => {
            console.log("Firebase storage image uploaded : ", url); 
            this.setState({loading: false})
            this.props.handleClosePictureCamera(url);
        }).catch(error => {
            this.setState({loading: false});
            this.props.handleClosePictureCamera(filename);
            console.error(error);
        })
    }).catch(error => {
        this.setState({loading: false});
        this.props.handleClosePictureCamera(filename);
        console.error(error);
    });
    }
    render() {
        let {showCamera, cameraSupportWarning, loading, dataUri} = this.state;
        const {takePictureMessage} = this.props;
        console.log(" takePictureMessage --> ",takePictureMessage);
        return (
            <div className={this.props.screenThemeClass}>
            {showCamera ? 
                <CameraComponent takePictureMessage={this.props.takePictureMessage || "takePictureMessage"} handleTakePhoto={this.handleTakePhoto} handleCameraSupportError={this.handleCameraSupportError}  handleClosePictureCamera={this.props.handleClosePictureCamera}/>
                : dataUri &&
                <Dialog
                    open={true}
                    className={`take-photo-camera-dialog-popup p-0`}
                    onClose={() => this.handleClose()}
                >
                    <LifeSherpaLoading loading={loading} /> 
                    <DialogContent className="m-0 p-0 camera-dialog-content ">                          
                        <img src={dataUri} width="100%" height="100%"/>
                    </DialogContent>
                    <DialogActions className="d-flex justify-content-center">
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Button color="primary" variant="outlined" className="  mb-3 mr-2 retake-photo" onClick={() => this.handleRetake()}>Retake</Button>
                            <Button color="primary" variant="contained"  className=" mb-3 ml-2 use-photo"  onClick={() => this.handleUsePhoto()}>Use Photo</Button>
                        </div>
                    </DialogActions> 
                </Dialog>
            }
                {cameraSupportWarning &&
                <div className="col-12 p-4">
                    <SweetAlert
                        warning
                        btnSize="sm"
                        customClass="warningText"
                        confirmBtnText="OK"
                        confirmBtnBsStyle="warning"
                        title={this.state.warningText}
                        onConfirm={() => this.handleClose()}
                        >
                    <div className="sweet-alert-text"></div>
                    </SweetAlert>
                </div>
            }
            </div>
        )
    }
}
 
const mapStateToProps = ({ activitiesList, settings}) => {
    const { selectedId, showCamera } = activitiesList;
    const { screenThemeClass, popupThemeClass } = settings; 
    return { screenThemeClass, popupThemeClass, selectedId}
}
 
export default connect(mapStateToProps, {handleShowPhotoCamera})(SherpaCamera)
 