/**
 * Add Notification and Activity 
 */

import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import ActivityEditorSidebar from "../AppMyActivity/components/ActivityEditorSidebar";

class AddActivity extends Component {
    state = {
        showOptions: false
    }
    handleAddActivity = (type) => {
        this.setState({ showOptions: false }, () =>  this.props.handleOpenObjectEditor(type))
    }

    handleAddActivityOptions = (bool) => {
        this.setState({ showOptions: bool })
    }

    render() {
        const { openEditor, activityId, editorType } = this.props;
        const { showOptions } = this.state;
        return (
            <React.Fragment>
                {openEditor &&
                    <ActivityEditorSidebar
                        handleCloseObjectEditor={this.props.handleCloseObjectEditor}
                        openEditor={openEditor}
                        activityId={activityId}
                        editorType={editorType}
                        scope={this.props.scope}
                    />
                }

                {showOptions ?
                    <div className="fixed-overlay">
                        <div className="fixed-bottom">
                            <div className="add-activity-options-bottom-container d-flex align-items-center justify-content-end ml-auto  mb-3 mr-3" >
                                <div className="d-flex  flex-column">
                                    <div onClick={() => this.handleAddActivity("notification")} className="d-flex align-items-center justify-content-end mb-3 cursor-pointer">
                                        <div className="activity-type mr-3">
                                            Notification
                                        </div>
                                        <IconButton title="Notification Type" className="option-button  cps" data-testid="add-new-notification-type-activity"><NotificationsIcon /></IconButton>
                                    </div>
                                    <div onClick={() => this.handleAddActivity("general")} className="d-flex align-items-center justify-content-end mb-3 cursor-pointer">
                                        <div className="activity-type mr-3">
                                            Activity
                                        </div>
                                        <IconButton title="Activity Type" className="option-button cps" data-testid="add-new-routine-type-activity"><ListIcon /></IconButton>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <IconButton onClick={() => this.handleAddActivityOptions(false)} title="Close Routine Options" className="close-activity-options-button  cps" data-testid="close-add-new-activity-menu-item">
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="add-routine-button">
                        <div className="fixed-bottom">
                            <div className=" add-activity-bottom-container d-flex align-items-center  ml-auto  mb-3 mr-3 " >
                                <IconButton onClick={() => this.handleAddActivityOptions(true)} title="Add Routine" className="ml-auto" data-testid="add-new-routine">
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                }


            </React.Fragment>
        )
    }
}

export default AddActivity
