/**
 * User List
 */
import React, { Component, Suspense } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import  {AsyncCourseLMSiFrame} from '../AsyncComponent/AsyncComponent'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import LifeSherpaPageLoader from "../RctPageLoader/LifeSherpaPageLoader"

class CoursesComponent extends Component {

    handleClose(){
       this.props.handleCloseCourse()
   }

    render() {
        const {title, tag } = this.props;
        return (
           <div data-testid="open-course-component-iframe" className="row ">
                <div className="d-flex w-100 lifesherp-breadrumb-header justify-content-between align-items-center">
                    <div data-testid="open-course-breadcrumb-title-url"  className="d-flex align-items-left">
                        <Breadcrumb className="mb-0 tour-step-7" tag="nav">
                            <BreadcrumbItem 
                            tag={"span"}>Home</BreadcrumbItem>
                            <BreadcrumbItem 
                            tag={"span"}>{tag}</BreadcrumbItem>
                            <BreadcrumbItem 
                            tag={"span"}>{title ? title : 'Info'}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="d-flex d-flex  justify-content-end  align-items-right">
                        <IconButton data-testid="course-iframe-close-icon"  onClick={() => this.handleClose()} className="" title="Back To Home">
                            <CloseIcon color="primary"/>
                        </IconButton>
                    </div>
                </div>
                <div data-testid="open-my-course-iframe" className="w-100 col-12">
                <Suspense fallback={<LifeSherpaPageLoader/>}>
                    <AsyncCourseLMSiFrame />
                </Suspense>
                </div>
           </div> 
        );
    }
}

export default CoursesComponent
