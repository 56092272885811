import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Label } from 'reactstrap'
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogTitle from "@mui/material/DialogTitle";

class NoticesFilter extends Component {
    state = {
        noticeTypeToCollectionNameMap :[
            {
                id: "Client",
                name: "Client Alerts"
            }, {
                id: 'General',
                name: "General Alerts"
            }
        ],
        collection: '',

    }
    handleFilter = (id) => {
        this.props.handelFilterOpitonsChange(id);
        this.setState({ collection: id });
    }
    render() {
        let { filterOptions, popupThemeClass } = this.props;
        const {noticeTypeToCollectionNameMap} = this.state;

        // console.log(Resources);
        return (
            <Dialog
                fullScreen={false}
                open={this.props.showFilter}
                onClose={() => this.props.handleCloseFilter()}
                maxWidth="xl"
                className={`${popupThemeClass}`}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <div data-testid="resource-filter-dialog" className="d-flex justify-content-between align-items-center py-2 px-3 bg-lifesherpa">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Alerts Filter</Label>
                        <IconButton data-testid="close-icon-for-filter-dialog" className="text-white" onClick={() => this.props.handleCloseFilter()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="px-4 activities-filter-dialog-content">
                    <div className="text-secondary filter-heading  fw-semi-bold mt-2">Alerts Type</div>
                    <hr className="my-2" />
                    <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                    >
                        {noticeTypeToCollectionNameMap && noticeTypeToCollectionNameMap.map((list, key) => (
                            <Grid item key={key}>
                                {filterOptions.find(gid => gid == list.id) ?
                                    <Chip
                                        key={key}
                                        className={" mr-1 my-1 chipSelected "}
                                        avatar={<CheckCircleOutlinedIcon />}
                                        label={list.name}
                                        style={{ fontSize: "16px" }}
                                        onClick={() => this.handleFilter(list.id)} />
                                    :
                                    <Chip
                                        key={key}
                                        className={" mr-1 my-1  chipStyle"}
                                        label={list.name}
                                        style={{ fontSize: "16px" }}
                                        onClick={() => this.handleFilter(list.id)} />
                                }
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions className="px-4 d-flex align-items-center justify-content-center filter-buttons-styles">
                    <Button color="primary" variant="outlined" data-testid="cancel-filter-button" className="filter-button-width filter-button-cancel" onClick={() => this.props.clearFilter()}>
                        CLEAR FILTERS
                    </Button>
                    <Button color="primary" variant="contained" data-testid="apply-filter-button" className="filter-button-width filter-button-apply" onClick={() => this.props.applyFilter()}>
                        APPLY
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }
}
const mapStateToProps = ({ settings }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    return { screenThemeClass, popupThemeClass };
}

export default connect(mapStateToProps, {})
    (NoticesFilter);