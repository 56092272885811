import React, { Component } from 'react';
import moment from 'moment';

class ActivityStepTimer extends Component {
    state = {
        timerTime: 0,
        elapsedTime: 0,
        intervalID: null,
        achievementDelayed: false,
        expectedDurationSeconds: 0
    }

    componentDidMount() {
        const { stepDetails } = this.props;
        let { timerTime, expectedDurationSeconds, achievementDelayed } = this.state;
        const stepStartDate = this.getStartDateOfStep();
        if (stepStartDate && stepDetails && stepDetails.expectedDuration) {
            let dateObject = moment(stepStartDate, "YYYY-MM-DD HH:mm:ss Z").toDate()
            timerTime = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            // Getting expected duration
            expectedDurationSeconds = this.getExpectedDurationSeconds(stepDetails.expectedDuration);
            // console.log("expectedDurationSeconds --> ", expectedDurationSeconds, "totalTimeTaken --> ", timerTime);
            if (expectedDurationSeconds >= timerTime) {
                achievementDelayed = false;
            } else {
                timerTime = timerTime - expectedDurationSeconds;
                achievementDelayed = true;
            }
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID);
            }
            let intervalID = setInterval(
                this.hanldeUpdateActivityTimer.bind(this)
                , 1000);
            this.setState({ timerTime: timerTime, expectedDurationSeconds: expectedDurationSeconds, achievementDelayed: achievementDelayed, intervalID: intervalID })
        } 
    }

    componentWillUnmount() {
        if (this.state.intervalID) {
            clearInterval(this.state.intervalID);
        }
    }

    getStartDateOfStep = () => {
        const { stepDetails, stepsCompletionList, achievement, viewType } = this.props;
        let stepStartDate = null;
        let stepPosition = stepDetails && stepDetails.position ? stepDetails.position : 0;
        if(viewType == "Flow") {
            if(stepsCompletionList) {
                stepPosition = stepPosition - 1;
                const lastEndStep = stepsCompletionList.find(completedStep => completedStep.step.position === stepPosition);
                stepStartDate = lastEndStep && lastEndStep.endDate ? lastEndStep.endDate : null
            } 
            if(!stepStartDate && achievement && achievement.startDate) {
                stepStartDate = achievement.startDate;
            }
        }
        return stepStartDate;
    }
    hanldeUpdateActivityTimer = () => {
        let { timerTime, expectedDurationSeconds, achievementDelayed } = this.state;
        const stepStartDate = this.getStartDateOfStep();
        if (stepStartDate) {
            let dateObject = moment(stepStartDate, "YYYY-MM-DD HH:mm:ss Z").toDate()
            timerTime = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            if (expectedDurationSeconds >= timerTime) {
                achievementDelayed = false;
            } else {
                timerTime = timerTime - expectedDurationSeconds;
                achievementDelayed = true;
            }
            this.setState({ timerTime: timerTime, expectedDurationSeconds: expectedDurationSeconds, achievementDelayed: achievementDelayed })
        } 
    }

    componentDidUpdate(prevProps) {
        const { stepDetails } = this.props;
        let { timerTime, expectedDurationSeconds, achievementDelayed } = this.state;
        let currentExpectedDuration = stepDetails && stepDetails.expectedDuration ? this.getExpectedDurationSeconds(stepDetails.expectedDuration) : 0;
        const stepStartDate = this.getStartDateOfStep();
        if(currentExpectedDuration !== expectedDurationSeconds && stepStartDate && stepDetails && stepDetails.expectedDuration) {
            let dateObject = moment(stepStartDate, "YYYY-MM-DD HH:mm:ss Z").toDate()
            timerTime = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            if (currentExpectedDuration >= timerTime) {
                achievementDelayed = false;
            } else {
                timerTime = timerTime - expectedDurationSeconds;
                achievementDelayed = true;
            }
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID);
            }
            let intervalID = setInterval(
                this.hanldeUpdateActivityTimer.bind(this)
                , 1000);
            this.setState({ timerTime: timerTime, expectedDurationSeconds: currentExpectedDuration, achievementDelayed: achievementDelayed, intervalID: intervalID })
        }
    }


    getFormatedDuration = (duration) => {
        var timeleft = (+duration) * 1000;
        let seconds = (Math.floor(timeleft / 1000) % 60)
        let minutes = (Math.floor(timeleft / 60000) % 60)
        let hours = Math.floor(timeleft / 3600000);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        hours = hours < 10 ? "0" + hours : hours;
        return `${hours}:${minutes}:${seconds}`;
    }

    getExpectedDurationSeconds = (formatedString) => {
        let total = 0;
        if (formatedString) {
            const dateArray = formatedString.split(":");
            let hr = dateArray[0];
            let min = dateArray[1];
            let sec = dateArray[2];
            if (!sec) {
                if (min) {
                    sec = min;
                    min = hr;
                    hr = 0
                } else {
                    sec = hr;
                    min = 0;
                    hr = 0;
                }
            }
            let totalhrs = hr ? Number(hr) * 3600 : 0;
            let totalmin = min ? Number(min) * 60 : 0;
            total = totalhrs + totalmin + Number(sec);
        }
        return total;
    }


    render() {
        let { achievementDelayed, timerTime } = this.state
        let { stepDetails, stepsCompletionList } = this.props;
        const expectedDurationString = stepDetails && stepDetails.expectedDuration ? `${stepDetails.expectedDuration}` : ""
        return (
            <React.Fragment>
                {expectedDurationString && expectedDurationString.split(":")[1] &&
                    <div className="p-2">
                        <span className="mr-1">{expectedDurationString}</span>[<span style={ achievementDelayed ? {color:"red"}:{}}>+{this.getFormatedDuration(timerTime)}</span>]
                    </div>
                }
            </React.Fragment>
        );
    }
}



export default ActivityStepTimer;
