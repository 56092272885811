import React, { Component } from 'react';

// Material UI Components
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Label, FormFeedback } from 'reactstrap';
import Button from '@mui/material/Button';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import Slide from '@mui/material/Slide';

class UserWorkflowPendingTasks extends Component {

    componentDidMount() {
    }

    handleBackbutton() {
        this.props.handleBackbutton()
    }

    handleNotifyProceed(pendingTask) {
        const {showProceedButton} = this.props
        if (showProceedButton) {
            this.props.startExecutingTask(pendingTask)
        } else {
            this.props.notifyClientForTask(pendingTask)
        }
    }

    render() {
        const {selectedUserWorkflow, showProceedButton, userRole} = this.props
        return (
            <React.Fragment>
                <DialogTitle>
                    <div className={`d-flex align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                        <IconButton className="text-white mr-2" onClick={() => this.handleBackbutton()}><ArrowBackIcon /></IconButton>
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{`${selectedUserWorkflow.executionName} pending tasks`}</Label>
                    </div>
                </DialogTitle>
                <DialogContent className="m-0 p-0 start-workflow-container ">
                    <LifeSherpaLoading loading={false} />
                    <Slide direction={"left"} timeout={500} appear={this.props.pageMounted} in={true}>
                        <ul className='pt-3 pl-2 pb-3 pr-2 list-unstyled'>
                        { selectedUserWorkflow && selectedUserWorkflow.tasks && selectedUserWorkflow.tasks.length > 0 ?  selectedUserWorkflow.tasks.map((pendingTask, key) => (
                                <li key={key} className='p-3 list-card d-flex align-items-center justify-content-between'>
                                    <div className=''>
                                        <Label className='mb-0'>{pendingTask.name}</Label>
                                        <FormFeedback className='d-block m-0'>{pendingTask.description}</FormFeedback>
                                    </div>
                                    {showProceedButton ?
                                        <Button color="primary" onClick={() => this.handleNotifyProceed(pendingTask)} variant="contained" className=" ml-2 primary-button">START</Button>
                                       : userRole && (userRole != 'Client') ? 
                                        <Button color="primary" onClick={() => this.handleNotifyProceed(pendingTask)} variant="contained" className=" ml-2 primary-button">NOTIFY</Button>
                                        : <></>
                                    }
                                </li>
                        ))
                        :
                        <li className='p-3 list-card d-flex align-items-center justify-content-between'>No workflow tasks are pending</li>
                        }
                        </ul>
                    </Slide>
                </DialogContent>
            </React.Fragment>
        );
    }
}

export default UserWorkflowPendingTasks