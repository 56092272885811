import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import config from '../../config/config'
import WarningDialog from "../WarningDialog";

import { 
    hideChatPanel, 
    setSelectedChatUser, 
    showFullChatPanelForSelectedUser,
    showVideoRoomPanel,
    hideMissedCallConfirmation,
    showVideoCallConfirmation
} from 'Actions';
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

class MissedCallConfirmation extends Component
{
    state={
        timeOut:0,
    }
    handleOpenVideo = () => {
        let {videoCallMessage} = this.props;
        const roomname = uuidv4() //`${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
        if(videoCallMessage && videoCallMessage.callerId) {
          const orgId = videoCallMessage.organization || localStorage.getItem('orgId');
          localStorage.setItem('selectedOrgId', orgId);
           let selectedVideoChat = {name: roomname, userId: videoCallMessage.callerId};
           let videoCallRoomDetails = JSON.stringify(selectedVideoChat);
           localStorage.setItem("videoCallRoomDetails",  videoCallRoomDetails)
           let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${roomname}`;
           this.props.hideMissedCallConfirmation(openVideoCallRoom);
        } else {
            this.props.hideMissedCallConfirmation();
        }
    }
    handleClose = () => {
        this.props.hideMissedCallConfirmation();
    }
    render() {
      const {showMissedCallConfirmationPanel, videoCallMessage } = this.props;
      console.log(" videoCallMessage --> ", videoCallMessage);
      console.log(" showMissedCallConfirmationPanel --> ", showMissedCallConfirmationPanel);
      let callerName = "Unknown Caller"
      if(videoCallMessage) {
          if(videoCallMessage.message) {
            callerName = videoCallMessage.message.split(" request")[0];
          } else if(videoCallMessage.name) {
            callerName = videoCallMessage.name;
          }
      }
        return (
            <div> { showMissedCallConfirmationPanel && videoCallMessage && 
                        <WarningDialog
                            showWarning = {showMissedCallConfirmationPanel}
                            warningText = {videoCallMessage.message}
                            cancelText = {"CANCEL"}
                            acceptText = {"CALL BACK"}
                            handleCancel = {()=> this.handleClose()}
                            handleAccept = {()=> this.handleOpenVideo()}
                        />
                    //     <Dialog
                    //         fullScreen={false}
                    //         open={showMissedCallConfirmationPanel}
                    //         fullWidth={true}
                    //         maxWidth="sm"
                    //         aria-labelledby="responsive-dialog-title"
                    //         className={this.props.popupThemeClass}
                    //         > 
                    //         <DialogContent className="p-0">
                    //             <div className="container text-center">
                    //                 <h2 className=" exitDialog text-center my-4">{videoCallMessage.message}</h2>
                    //                 <div className="d-flex mx-4 px-4 align-items-center justify-content-center">
                    //                 <button className="btn btn-grey mb-3 mr-2" onClick={()=> this.handleClose()}>CANCEL</button>
                    //                 <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleOpenVideo()}>CALL BACK</button>
                    //                 {/* <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}><a className="text-white" target="_self" href="/success">ok</a></button>*/}
                    //                 </div>
                    //             </div>
                    //         </DialogContent>
                    // </Dialog>
                }
            </div>
        );
    }
}
const mapStateToProps = ({ settings, Contacts, chatAppReducer }) => {
	const { screenThemeClass, popupThemeClass  } = settings; 
  let {contactList, showMissedCallConfirmationPanel, videoCallMessage} = Contacts;
	return {contactList, showMissedCallConfirmationPanel , screenThemeClass, popupThemeClass, videoCallMessage};
};

export default withRouter(connect(mapStateToProps, 
  {
    hideChatPanel, 
    setSelectedChatUser, 
    showFullChatPanelForSelectedUser, 
    showVideoRoomPanel,
    hideMissedCallConfirmation,
    showVideoCallConfirmation
     })(MissedCallConfirmation));
