import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
// reactstrap components
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogActions from '@mui/material/DialogActions';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Slide from '@mui/material/Slide';

class UsersToAssingList extends Component {

    state = {
        search: "",
        showSearch: false,
        itemsList: [],
        selectedKey: null
    };

    componentDidMount() {
        let { itemsList, selectedItem } = this.props;
        
        if (itemsList) {
            itemsList = itemsList.map(item => {
                item.key = item.userId || item.groupId
                return item;
            });
            console.log("itemsList, selectedItem", itemsList, selectedItem)
            this.setState({ itemsList, selectedKey:  selectedItem && (selectedItem.userId || selectedItem.groupId)});
        }
    }


    handleCloseAddUsersPage = () => {
        let { itemsList , selectedKey} = this.state;
        let selectedItem = null;
        if(itemsList) {
            selectedItem = itemsList.find(item => item.key === selectedKey);
        }
        
        this.props.handleClose(selectedItem);
    }

    handelSearch = (e) => {
        console.log(e);
        this.setState({ search: e.target.value });
    }

    handelCloseSearch = () => {
        this.setState({ search: "", showSearch: false });
    }


    handleContactClick = (key) => {
        this.setState({selectedKey: key})
    }

    getIncludeUsersDescription() {
		const {includeUsers, assignmentType} = this.props;
		
		if(assignmentType === "Group" || assignmentType === "Group Static") {
			if(includeUsers === "onlyAdmins") {
				return `Only Admin role users from selected group will notified`;
			} else if(includeUsers === "onlyClients") {
				return `Only General role users from selected group will be notified`;
			} else {
				return `All General and Admin users from selected group will be notified`;
			}
		} 
        return "";
	}

    render() {
        const { search, showSearch, selectedKey } = this.state;
        const {assignmentType, includeUsers} = this.props;
        let { itemsList } = this.state;
        itemsList = search && itemsList && itemsList.length > 0 ? itemsList.filter((li) => li.name && li.name.toLowerCase().includes(search.toLowerCase())) : itemsList;
        
        return (
            <React.Fragment>

                <DialogTitle>
                    <div className="d-flex align-items-center template-header justify-content-between all-clients-for-grooup p-2 pr-3">
                        <div className='d-flex align-items-center'>
                            <IconButton className="" onClick={() => this.handleCloseAddUsersPage()} ><ArrowBackIcon /></IconButton>
                            {!showSearch ? 
                                <h4 className='m-0'>
                                    {assignmentType === "Group" || assignmentType === "Group Static" ? "Assign Group" : "Assign User"}
                                    
                                </h4> 
                            : ''}
                        </div>

                        <div className="d-flex align-items-center">
                            {showSearch ?
                                <form className="mr-3 w-100">
                                    <TextField id="standard-search"
                                        onChange={(e) => this.handelSearch(e)}
                                        type="search"
                                        value={search}
                                        placeholder="Search Name"
                                        autoFocus={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </form>
                                :
                                <IconButton className=" mx-4 " data-testid="search-button-on-add-contacts" onClick={() => this.setState({ showSearch: true })}><SearchIcon /></IconButton>
                            }
                            {/* <IconButton onClick={(e) => this.selectAll()}>
                                            {selectAll ?
                                                <RadioButtonCheckedIcon  /> :
                                                <RadioButtonUncheckedIcon />}
                                        </IconButton> */}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="p-0 py-0 start-workflow-container">
                   {
                            assignmentType === "Group" || assignmentType === "Group Static" ? 
                            <h5 className='mt-2 mx-2'>
                                {
                                    includeUsers === "onlyAdmins" ? 
                                         "Only Admin role users from selected group will notified":
                                    includeUsers === "onlyClients" ? 
                                        "Only General role users from selected group will be notified"
                                         : "All General and Admin users from selected group will be notified"
                                }
                                
                            </h5>
                            : ""
                        }
                    <Slide direction={"left"} timeout={500} in={true}>
                        <ul className="list-unstyled m-0 list-sherpa-activity add-group-contact-list-popup px-0 pt-1 mb-4" data-testid="all-contacts-list">
                            {itemsList ? itemsList.map((user, index) => (
                                <li className={`d-flex list-item align-items-center p-2 cps border-bottom ${index % 2 == 0 ? "alternate-color" : ""}`} key={index + 'user'} onClick={(e) => this.handleContactClick(user.key)} data-testid={`contacts-list-item-${index}`}>
                                    <div className="avatar-wrap w-15 align-self-center" >
                                        {user && user.profileImageURL && user.profileImageURL !== '' ?
                                            <img src={user.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" />
                                            : <Avatar className="align-self-center user-later-avatar">{user.name.charAt(0)}</Avatar>
                                        }
                                    </div>
                                    <div className="actvity-routine my-auto mx-2">
                                        <h4>{user.name}</h4>
                                    </div>
                                    <IconButton className="ml-auto mr-2" >
                                        {user.key === selectedKey ?
                                            <RadioButtonCheckedIcon color="primary" /> :
                                            <RadioButtonUncheckedIcon color="primary" />}

                                    </IconButton>
                                </li>
                            ))
                                : <li className='d-flex align-items-center my-0 container-border py-0 mx-auto justify-content-between'>
                                    <div className='text-center w-100 my-4 py-4'>
                                        {`No assignable members available!`}
                                    </div>
                                </li>
                            }
                        </ul>

                    </Slide>

                </DialogContent>
                <DialogActions className="p-2 d-flex justify-content-center filter-buttons-styles">
                    <Button color="primary" variant="contained" className=" w-40 filter-button-apply" onClick={() => this.handleCloseAddUsersPage()}>
                        Save
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}


export default UsersToAssingList;