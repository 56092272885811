import React, { Component } from "react";
import LIfeSherpaBlankPageLoading from '../LIfeSherpaBlankPageLoading';
import { connect } from 'react-redux';
import Iframe from "./iframe.js";

class ActivityEditorIframe extends Component {
    state = {
        loading: true,
    }

    hideSpinner = (e) => {
        this.setState({ ...this.state, loading: false });
        console.log("%c Assign activity app loaded successfully............", "color:green");
        var iframe = document.getElementById("assignActivityIFrame");
        // var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        window.clsEditorRef = this
        window.onmessage = function(e) {
            if (e.data == 'close_iframe') {
                window.clsEditorRef.setState({ ...this.state, loading: true });
                window.clsEditorRef.props.handleCloseObjectEditor();
            }
        };

        // if (innerDoc.location) {
        //     var pathName = innerDoc.location.pathname;
        //     if (pathName == "/success") {
        //         this.setState({ ...this.state, loading: true });
        //         this.props.handleCloseObjectEditor();
        //     }
        // }
    }

    render() {
        let { editorURL } = this.props;
        let { loading } = this.state;
        return (
            <React.Fragment>
                <LIfeSherpaBlankPageLoading loading={loading} />
                <div style={{ visibility: loading ? "hidden" : "visible" , height:"100%"}}>
                    <iframe
                        src={editorURL ? editorURL : ''}
                        onLoad={this.hideSpinner}
                        scrolling="yes"
                        id="assignActivityIFrame"
                        className="w-100 "
                        display="initial"
                        position="relative"
                        height="100%"
                        data-testid="activity-editor-iframe"
                        allow="microphone;camera *;"
                    ></iframe>
                </div>
            </React.Fragment>
        )
    }
}

export default ActivityEditorIframe;
