import React, { Component } from 'react';
// import { Bubble } from 'react-chartjs-2';
import ChartConfig from '../../constants/chart-config';
import LifeSherpaLoading from '../LifeSherpaLoading';
import {
	Chart as ChartJS,
	LinearScale,
	PointElement,
	Tooltip,
	Legend,
  } from 'chart.js';
import {
	Bubble
} from 'react-chartjs-2';

ChartJS.register(
	LinearScale,
	PointElement,
	Tooltip,
	Legend,
);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	redraw: true,
	legend: {
		display: false
	},
	scales: {
		x: {
			gridLines: {
				color: ChartConfig.chartGridColor
			},
			ticks: {
				fontColor: ChartConfig.axesColor
			}
		},
		y: {
			gridLines: {
				color: ChartConfig.chartGridColor
			},
			ticks: {
				fontColor: ChartConfig.axesColor
			}
		}
	}
};

export default class BuubleChart extends Component {
	componentDidMount() {
		this.props.getChartData(this.props.chartId)
	}

	render() {
		return (
			<article className="canvas-container">
				{
					this.props.loading ?
						<LifeSherpaLoading loading={this.props.loading}/>
					:
					<Bubble data={this.props.data} options={options} />
				}
			</article>

		);
	}
}
