/**
 * Email List Item
 */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListIcon from '@mui/icons-material/List';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '../SVG/PeopleIcon'
import { Badge } from 'reactstrap';

import "../../assets/scss/custom/_activitypreview.scss"

const UserAllActivitiesListItem = ({ hideRewards, activity, handleStartActivity, handleResumeActivity, selectedMenuActivity, handleEditGeneralActivity, handleDeletePop, handleShowActivityHistoryList, itemKey, handleReminderButton, handleCalenderButton, anchorEl, handleClickMenu, handleCloseMenu, handleShowActivityDetails, handleActivityTitleClick, handleResetActivity, handleAdjustActivityTimer, handleExecuteActivity, handlePokeForm, pokeFormId, showStartOrResumeButton, allowCreateGeneralActivity, handleUnassignActivity  }) => (
    <div className="mb-2" key={itemKey} data-testid={`user-activities-list-item-${itemKey}`}>
        {activity.state === "paused" || activity.state === "inProgress" ||  (activity.type === "Touchpoint" && activity.workflowId) || (activity.type == "Touchpoint" && activity.scheduleKey && activity.scheduleNotExpired) ? <div className="d-flex justify-content-between align-items-center ml-1">
            <div className="col-4 col-md-4  col-lg-4 text-center text-white activities-list-inprogress-title" /*px-2 py-2 mt-1*/ >
                {activity.state === "inProgress" || activity.state === "paused"  ? "In Progress" : "Pending"}</div>
            </div> : <></>
        }
        {hideRewards ?
            <div className="row mx-auto align-items-center justify-content-center  mb-auto text-secondary position-relative   font-lg" style={{ width: 50 + 'px', height: "46px" }}>

            </div>
            :
            <div className="row mx-auto align-items-center justify-content-center  mb-auto text-secondary position-relative   font-lg" style={{ width: 50 + 'px' }}>
                <img className="mt-1 mx-auto d-block" style={{ zIndex: "1" }} src={require("Assets/img/diamond_icon.png")} alt="project logo" width="43" height="43" />
                {activity.rewards && activity.rewards > 99 ?
                    <h2 className="text-white reward-style " >{activity.rewards && activity.confirmationRewards ? activity.rewards + activity.confirmationRewards : activity.rewards ? activity.rewards : activity.confirmationRewards ? activity.confirmationRewards : 0}</h2>
                    : <h1 className="text-white reward-style " >{activity.rewards && activity.confirmationRewards ? activity.rewards + activity.confirmationRewards : activity.rewards ? activity.rewards : activity.confirmationRewards ? activity.confirmationRewards : 0}</h1>
                }
            </div>
        }

        <li key={itemKey} className={"d-flex  list-card justify-content-between align-items-center list-item " + `${activity.state === "paused" || activity.state === "inProgress" || (activity.type === "Touchpoint" && activity.workflowId) || (activity.type == "Touchpoint" && activity.scheduleKey && activity.scheduleNotExpired) ? "activities-list-item-border-inprogress" : "activities-list-item-border"}`}
            style={{ backgroundImage: `url(${activity.backgroundImageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain', backgroundPosition:"center" }}>
            <div className="scheduleButton px-1">
                {activity.type === "QT-Step" || activity.type === "QT-Form" ?
                    <IconButton title={activity.type}>
                        <FlashOnIcon color="primary" />
                    </IconButton>
                    : activity.type === "QT-Reminder" ?
                        <IconButton title="Notification">
                            <NotificationsIcon color="primary" />
                        </IconButton>
                        : activity.type === "Touchpoint" ?
                            <IconButton title={activity.type}>
                                <PeopleIcon width="33px" height="33px" className="people-left-right-icon" />
                            </IconButton>
                            :
                            <IconButton title={activity.type}>
                                <ListIcon fontSize="large" color="primary" />
                            </IconButton>
                }
                {activity.sortedSchedules && activity.sortedSchedules.length > 0 &&
                    <IconButton title="Calendar" onClick={activity.newSchedule && activity.newSchedule !== '' ? handleCalenderButton : null}>
                        <CalendarTodayIcon color="primary" />
                    </IconButton>
                }
                {activity.isPrivateActivity === true &&
                    <IconButton title="Private Activity">
                        <LockOutlinedIcon color="primary" />
                    </IconButton>
                }
            </div>
            {activity.isPrivateActivity === true && ((activity.type === "Routine") || !(activity.type) || (activity.type === "QT-Step") || (activity.type === "QT-Form") || (activity.type === "QT-Set-Status") || (activity.type === "Touchpoint")) ?
                <div className="moreButton">
                    <div className="w-4 d-flex justify-content-end align-items-center px-1 ml-1" style={{ borderRadius: 12 + "px" }}>
                        <IconButton data-testid={`client-activity-more-actions-${itemKey}`} onClick={handleClickMenu}>
                            <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                        </IconButton>
                    </div>
                    {selectedMenuActivity && selectedMenuActivity.id === activity.id &&
                        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                            {selectedMenuActivity && selectedMenuActivity.isTimerEnabled && (selectedMenuActivity.state == "paused" || selectedMenuActivity.state == "inProgress") && <MenuItem className="menuEditItem" onClick={handleAdjustActivityTimer}>Adjust Timer</MenuItem>}
                            <MenuItem className="menuEditItem" onClick={handleShowActivityDetails}>Show Details</MenuItem>
                            <MenuItem className="menuEditItem" onClick={handleShowActivityHistoryList}>Activity History</MenuItem>
                            {selectedMenuActivity && selectedMenuActivity.achievementId && (selectedMenuActivity.state == "paused" || selectedMenuActivity.state == "inProgress") && <MenuItem className="menuEditItem" onClick={handleResetActivity}>Reset Activity</MenuItem>}
                            <MenuItem className="menuEditItem" onClick={handleStartActivity}>Preview Activity</MenuItem>
                            {pokeFormId && (activity.type !== "Touchpoint") && (activity.state === "paused" || activity.state === "inProgress") && <MenuItem className="menuEditItem" onClick={handlePokeForm}>Poke</MenuItem>}
                            {/* {allowCreateGeneralActivity && <MenuItem className="menuEditItem" onClick={handleEditGeneralActivity}>Edit</MenuItem>}
                            {allowCreateGeneralActivity && <MenuItem className="menuEditItem" onClick={handleDeletePop}>Delete</MenuItem>} */}
                            <MenuItem className="menuEditItem" onClick={handleCloseMenu}>Cancel</MenuItem>
                        </Menu>
                    }
                </div>
                : ((activity.type === "Routine") || !(activity.type) || (activity.type === "QT-Step") || (activity.type === "QT-Form") || (activity.type === "QT-Set-Status") || (activity.type === "Touchpoint") || activity.type === "QT-Reminder") ?
                    <div className="moreButton d-flex items-center">
                    { activity.type === "Touchpoint" && activity.workflowId ? 
                        <div><Badge className='font-md mt-2'>Workflow</Badge></div>
                        : activity.type == "Touchpoint" && activity.scheduleKey && activity.scheduleNotExpired ?
                        <div><Badge className='font-md mt-2'>Scheduled</Badge></div>
                        : <></>
                    }
                        <div className="w-4 d-flex justify-content-end align-items-center" style={{ borderRadius: 12 + "px" }}>
                            <IconButton data-testid={`client-activity-more-actions-${itemKey}`} onClick={handleClickMenu}>
                                <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                            </IconButton>
                        </div>
                        {selectedMenuActivity && selectedMenuActivity.id === activity.id &&
                            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                {activity.type !== 'QT-Reminder' && selectedMenuActivity && selectedMenuActivity.isTimerEnabled && (selectedMenuActivity.state == "paused" || selectedMenuActivity.state == "inProgress") && <MenuItem className="menuEditItem" onClick={handleAdjustActivityTimer}>Adjust Timer</MenuItem>}
                                {activity.type !== 'QT-Reminder' && <MenuItem className="menuEditItem" onClick={handleShowActivityDetails}>Show Details</MenuItem>}
                                {<MenuItem className="menuEditItem" onClick={handleShowActivityHistoryList}>Activity History</MenuItem>}
                                {selectedMenuActivity && selectedMenuActivity.achievementId && (selectedMenuActivity.state == "paused" || selectedMenuActivity.state == "inProgress") && <MenuItem className="menuEditItem" onClick={handleResetActivity}>Reset Activity</MenuItem>}
                                {activity.type !== 'QT-Reminder' && <MenuItem className="menuEditItem" onClick={handleStartActivity}>Preview Activity</MenuItem>}
                                {pokeFormId && (activity.type !== "Touchpoint") && (activity.state === "paused" || activity.state === "inProgress") && <MenuItem className="menuEditItem" onClick={handlePokeForm}>Poke</MenuItem>}
                                {activity.type !== 'QT-Reminder' && (activity.state !== "paused" && activity.state !== "inProgress") && activity.type !== 'Touchpoint' && <MenuItem className="menuEditItem" onClick={handleReminderButton}>Notify to Start</MenuItem>}
                                {activity.showEditButton && <MenuItem data-testid={`client-activity-edit-${itemKey}`} className="menuEditItem" onClick={handleEditGeneralActivity}>Edit</MenuItem>}
                                {activity.showDeleteButton && <MenuItem data-testid={`client-activity-delete-${itemKey}`} className="menuEditItem" onClick={handleDeletePop}>Delete</MenuItem>}
                                <MenuItem data-testid={`client-activity-unassign-${itemKey}`} className="menuEditItem" onClick={handleUnassignActivity}>Unassign Activity</MenuItem>
                                <MenuItem className="menuEditItem" onClick={handleCloseMenu}>Cancel</MenuItem> 
                            </Menu>
                        }
                    </div>
                    :
                    <></>
            }

            <div className="w-100">
                <div onClick={handleActivityTitleClick} className={showStartOrResumeButton ? "col-12 pl-4 pt-4" : "col-12 pl-4 pt-4 pb-3"}>
                    <div className=" text-center pt-4 pb-2" style={{ cursor: "pointer" }}>
                        <h4 className="mb-1" data-testid={"activity-title-" + itemKey}>{activity && activity.title ? activity.title.toUpperCase() : ''}</h4>
                        <div className="font-sm d-block">{activity.detailedDescription}</div>
                    </div>
                </div>
                {showStartOrResumeButton &&
                    <div className={(activity.state === "paused" || activity.state === "inProgress") ? "col-12 pb-3" : "col-12 pb-3"}>
                        {(activity.state === "paused" || activity.state === "inProgress") && activity.type === "Touchpoint" ?
                            <button disabled={!activity.showResumeButton} className={activity.showResumeButton ? "start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained primary-button MuiButton-containedPrimary cps" : "start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained disabled-button MuiButton-containedPrimary"} onClick={handleResumeActivity}>RESUME</button>
                            : activity.showStartButton && activity.type === "Touchpoint" ?
                                <button className="start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained primary-button MuiButton-containedPrimary cps" onClick={handleExecuteActivity}>START</button>
                                : <></>
                        }
                        {activity.state === "paused" || activity.state === "inProgress" ?
                            <div className="d-flex w-100 align-items-center mt-4 font-sm" style={{ whiteSpace: 'nowrap' }}>
                                <div className="align-items-center"> <div className={"rounded-circle  mr-1 " + (activity.state === "paused" ? "activity-icon-progress" : "activity-icon-progressa")}>
                                    {activity.state === "paused" ? "P" : "A"}</div></div>
                                <div className="activity-step">{activity.nextStep ? activity.nextStep : 'Step : Start'}</div>
                            </div>
                            :
                            <></>
                        }
                        {activity.state === "paused" || activity.state === "inProgress" ?
                            <div className="row m-2">
                                <div style={{ height: 5 + 'px', borderRadius: 2 + 'px' }} className={`activity-completed w-${Math.round(Math.round(activity.completed / activity.count * 100) / 10) * 10}`}></div>
                                <div className={`w-${100 - Math.round(Math.round(activity.completed / activity.count * 100) / 10) * 10}`} style={{ background: 'lightgray', height: 5 + 'px', borderBottomRightRadius: 2 + 'px', borderTopRightRadius: 2 + 'px' }}></div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                }
            </div>
            <div className="d-none w-4 justify-content-end align-items-center text-right"><ArrowRightIcon /></div>

        </li>
    </div>
);

export default UserAllActivitiesListItem;