import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
// reactstrap components
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogActions from '@mui/material/DialogActions';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { checkCompletedInTasks } from '../../../helpers/helpers';

class SkipWorkflowTask extends Component {

    state = {
        search: "",
        showSearch: false,
        workflowTasks: [],
        selectAll: false,
        selectedWorkflowItem: null,
    };

    componentDidMount() {
        let { selectedWorkflowItem } = this.props;
        const { workflowTasks } = checkCompletedInTasks(selectedWorkflowItem?.tasks) || {workflowTasks: null}
        if (workflowTasks) {
            console.log("workflowTasks", workflowTasks)
            let workflowTasksArray = Object.keys(workflowTasks).map((taskId, key)=> {
                let task = workflowTasks[taskId]
                task["taskId"] = taskId
                return task
            })
            this.setState({ workflowTasks: workflowTasksArray,  selectedWorkflowItem});
        }
    }


    handleSubmitTasks = () => {
        const { workflowTasks } = this.state;
        const selectedTasks = workflowTasks.filter(task => task.selected);
        if(selectedTasks.length) {
            this.setState({workflowTasks: [], selectAll: false, showSearch: false, search: ""}, () => {
                this.props.handleSubmit(selectedTasks);
            })
        }
        
    }

    isSubmitDisabled() {
        const { workflowTasks } = this.state;
        const selectedTasks = workflowTasks.filter(task => task.selected);
        if(!selectedTasks.length) {
            return true;
        }
        return false
    }

    handelSearch = (e) => {
        console.log(e);
        this.setState({ search: e.target.value });
    }

    handelCloseSearch = () => {
        this.setState({ search: "", showSearch: false, selectAll: false});
    }


    handleWorkflowTaskClick = (e, i, taskId, task) => {
        let { workflowTasks, selectedWorkflowItem } = this.state;
        let dependentTasks = [];
        if(task?.parameters?.type === "groupRoleAssignment" && selectedWorkflowItem) {
            const role = task?.parameters?.activityAssignedTo?.role;
            const groupId = task?.parameters?.activityAssignedTo?.groupId;
            if(role && groupId) {
                const {taskKeys} =  this.getDependentTasksByAssignedGroup(groupId, role, taskId, selectedWorkflowItem["tasks"], selectedWorkflowItem["roles"]);
                dependentTasks = taskKeys;
            }
        }

        let taskSelectedValue = false;
        let assignGroupTaskId;
      
        workflowTasks = workflowTasks.map(task => {
            if(task.taskId == taskId && task.status != "completed") {
                task.selected = !task.selected;
                taskSelectedValue = task.selected;
                if(task.assignGroupTaskId && !taskSelectedValue) {
                    assignGroupTaskId = task.assignGroupTaskId;
                }
            }
            
            return task;
        });
        workflowTasks = workflowTasks.map(task => {
            if(dependentTasks.includes(task.taskId) && task.status != "completed") {
                task.selected = taskSelectedValue;
                task.assignGroupTaskId = taskId;
            }
            return task;
        });
        if(assignGroupTaskId) {
            workflowTasks = workflowTasks.map(task => {
                if(task.taskId === assignGroupTaskId && task.status != "completed") {
                    task.selected = false;
                }
                return task;
            });
        }
        this.setState({ workflowTasks });
    }
    getDependentTasksByAssignedGroup = (groupId, role, currentTaskId, tasks, roles, currentTaskFound = false) =>{
		let taskKeys = [];
		let hasAnotherAssignGroupTask = false;
		for(const taskId in tasks){
			const task = tasks[taskId];
			const {parameters = {}, status} = task;
			const {activityAssignedTo = {}, activityExecutedBy = {}, type, subWorkflowRoles = {}} = parameters;
			if(taskId === currentTaskId) {
				currentTaskFound = true;
				continue;
			}
			if(currentTaskFound && status !== "completed") {
				if(type === "groupRoleAssignment"){
					const assignedRole = activityAssignedTo.role;
					const assignedRoleObj = roles[assignedRole] || {}
					if(assignedRole === role && assignedRoleObj.groupId === groupId) {
						hasAnotherAssignGroupTask = true;
						break;
					}
				}
				if(type === "activityAssignment") {
					const assignedRole = activityAssignedTo.role;
					const executedRole = activityExecutedBy.role;
					const assignedRoleObj = roles[assignedRole] || {};
					const executedRoleObj = roles[executedRole] || {}
					if(!assignedRoleObj.userId && assignedRole === role && assignedRoleObj.groupId === groupId) {
						taskKeys.push(taskId);
					} else if(!executedRoleObj.userId && executedRole === role && executedRoleObj.groupId === groupId) {
						taskKeys.push(taskId);
					}
				}
				if(type === "resourceAssignment") {
					const assignedRole = activityAssignedTo.role;
					const assignedRoleObj = roles[assignedRole] || {}
					if(!assignedRoleObj.userId && assignedRole === role && assignedRoleObj.groupId === groupId) {
						taskKeys.push(taskId);
					} 
				}

				if(type === "subWorkflow") {
					for(const subWorkflowRole in subWorkflowRoles){
						const parentWorkflowRole = subWorkflowRoles[subWorkflowRole].parentWorkflowRole;
						const parentRoleObj = roles[parentWorkflowRole] || {}
						if(!parentRoleObj.userId && parentWorkflowRole === role && parentRoleObj.groupId === groupId) {
							taskKeys.push(taskId);
						} 
					}
				}
			}

			
			if(task.tasks && Object.keys(task.tasks).length) {
				const {taskKeys: subTaskKeys, hasAnotherAssignGroupTask: hasAnotherAssignGroupSubTask} = this.getDependentTasksByAssignedGroup(groupId, role, currentTaskId, task.tasks, roles, currentTaskFound);
				taskKeys = taskKeys.concat(subTaskKeys);
				if(hasAnotherAssignGroupSubTask) {
					hasAnotherAssignGroupTask = hasAnotherAssignGroupSubTask;
					break;
				}

			} 	
		}
		return {taskKeys, hasAnotherAssignGroupTask};
	}

    selectAll() {
        let {workflowTasks, selectAll} = this.state
        selectAll = !this.state.selectAll
        workflowTasks.map(task => {
            if (selectAll && task.status != "completed") {
                task.selected = true;
            } else {
                task.selected = false;
            }
            return task;
        });
        this.setState({selectAll, workflowTasks});
    }

    handleClose() {
        this.setState({search: "", showSearch: false, workflowTasks: [], selectAll: false}, () => {
            this.props.handleClose()
        })
    }

    render() {
        const {popupThemeClass, openSkipTaskview, isMobileView} = this.props
        const { search, showSearch, selectAll } = this.state;
        let { workflowTasks } = this.state;
        workflowTasks = search && workflowTasks && workflowTasks.length > 0 ? workflowTasks.filter((li) => li?.name && li?.name.toLowerCase().includes(search.toLowerCase())) : workflowTasks;
        const AllTasksSelected = workflowTasks.filter(task => task.selected).length == workflowTasks.length
        const partialSelected = workflowTasks.filter(task => task.selected).length > 0 && workflowTasks.filter(task => task.selected).length != workflowTasks.length ? true : false
        return (
            <Dialog
                open={openSkipTaskview}
                fullScreen={isMobileView}
                maxWidth="xs"
                aria-labelledby="responsive-dialog-title"
                className={`${popupThemeClass} notices-list-panel start-workflow-popup`}
                >
                <DialogTitle>
                    <div className="d-flex align-items-center template-header justify-content-between all-clients-for-grooup p-2 pr-3">
                        <div className='d-flex align-items-center'>
                            <IconButton className="" onClick={() => this.handleClose()} ><ArrowBackIcon /></IconButton>
                            {!showSearch ? <h4 className='m-0'>Skip Tasks</h4> : ''}
                        </div>

                        <div className="d-flex align-items-center">
                            {showSearch ?
                                <form className="mr-3 w-100">
                                    <TextField id="standard-search"
                                        onChange={(e) => this.handelSearch(e)}
                                        type="search"
                                        value={search}
                                        placeholder="Search Contacts"
                                        autoFocus={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </form>
                                :
                                <IconButton className=" mx-4 " data-testid="search-button-on-add-contacts" onClick={() => this.setState({ showSearch: true })}><SearchIcon /></IconButton>
                            }
                            <IconButton onClick={(e) => this.selectAll()}>
                                {(selectAll && AllTasksSelected) || AllTasksSelected ?
                                    <CheckBoxRoundedIcon  /> 
                                    : partialSelected ?
                                        <IndeterminateCheckBoxRoundedIcon />
                                    :
                                    <CheckBoxOutlineBlankRoundedIcon />
                                }
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="p-0 py-0 start-workflow-container">
                    <ul className="list-unstyled m-0 list-sherpa-activity add-group-contact-list-popup px-0 pt-1 mb-4" data-testid="all-contacts-list">
                        {workflowTasks ? workflowTasks.map((task, index) => (
                            <li className={`d-flex list-item align-items-center p-2 cps border-bottom ${index % 2 == 0 ? "alternate-color" : ""}`} key={index + 'user'} onClick={(e) => this.handleWorkflowTaskClick(e, index, task.taskId, task)} data-testid={`contacts-list-item-${index}`}>
                                <div className="avatar-wrap w-15 align-self-center" >
                                    {task && task.profileImageURL && task.profileImageURL !== '' ?
                                        <img src={task.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" />
                                        : <Avatar className="align-self-center user-later-avatar">{task.name ? task?.name.charAt(0).toUpperCase() : ""}</Avatar>
                                    }
                                </div>
                                <div className="actvity-routine my-auto mx-2">
                                    <h4>{task.name ? task?.name : ""}</h4>
                                    {task.selected && task?.parameters?.type === "groupRoleAssignment" ? 
                                        <span className='text-danger skip-dependent-task-text'>{`Tasks that depend on ${task?.parameters?.activityAssignedTo?.role || "this"} role will also be skipped.`}</span> 
                                    : ""}
                                    
                                </div>
                                {task.status === "completed"? 
                                        <></>
                                    :
                                    <IconButton className="ml-auto mr-2" >
                                        {task.selected ?
                                            <CheckBoxRoundedIcon color="primary" /> :
                                            <CheckBoxOutlineBlankRoundedIcon color="primary" />}

                                    </IconButton>
                                }
                            </li>
                        ))
                            : <li className='d-flex align-items-center my-0 container-border py-0 mx-auto justify-content-between'>
                                <div className='text-center w-100 my-4 py-4'>
                                    {`No assignable members available!`}
                                </div>
                            </li>
                        }
                    </ul>
                </DialogContent>
                <DialogActions className="p-2 d-flex justify-content-center filter-buttons-styles">
                    <Button color="primary" variant="contained" className=" w-40 filter-button-apply" onClick={() => this.handleSubmitTasks()} disabled={this.isSubmitDisabled()}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default SkipWorkflowTask;