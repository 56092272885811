/**
 * User List Item
 */
import React from 'react';
import classnames from 'classnames';
import Avatar from '@mui/material/Avatar';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import moment from 'moment'
import {Label} from "reactstrap"
import ErrorIcon from '@mui/icons-material/Error';

const WorkflowHistoryListItem = ({ workflow, selectedWorkflowItem, onClickListItem, alternativeColor, userRole }) => (
    <div
        key={workflow.id}
        onClick={onClickListItem}
        className={classnames('d-flex align-items-center list-card list-item  my-2 border-bottom cps  justify-content-between',
            { 'item-active': selectedWorkflowItem && selectedWorkflowItem.id === workflow?.id },
            { 'alternative-user-list-item': alternativeColor }
        )}
    >
        <div className="media align-items-center ">
            <div className="media-left position-relative mr-10">
                <Avatar
                    width="40"
                    height="40"
                    title={workflow?.status}
                    className={`align-self-center ${workflow.status === "completed" ? "achievementStatusConfirmed"  : workflow?.status === "open" ? "achievementStatusInProgress" : "achievementStatusCancelled"}`}
                >
                    {workflow?.status === "completed" ? 
                        <DoneRoundedIcon style={{ fontSize: "2rem" }} />
                        : workflow?.status === "open" ?
                            <PauseCircleOutlineIcon style={{ fontSize: "2rem" }} />
                        : workflow?.status === "error" ? 
                            <ErrorIcon style={{ fontSize: "2rem" }}/>
                        : <ClearRoundedIcon style={{ fontSize: "2rem" }} />
                    }
                </Avatar>
            </div>
            <div className="media-body">
                <h4 className="heading-text mb-0 mt-0">{workflow?.executionName}</h4>
                <div className="sub-text">{`${workflow?.status === "completed" ? "Completed: " : "Last Updated:"} ${moment(workflow?.lastUpdated, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}`}</div>
            </div>
        </div>
        {workflow?.tatalTasks ? 
            <div className='text-right'>
                <Label className="align-self-center heading-text mb-0">
                    {`${workflow.completedTasks}/${workflow.tatalTasks}`}
                </Label>
            </div>
            : <></>
        }
    </div>
);

export default WorkflowHistoryListItem;
