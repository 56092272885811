import {
    GET_MY_COURSES,
    GET_SUCCESS_MY_COURSES,
    GET_MY_COURSE_LAUNCH_URL,
    GET_SUCCESS_MY_COURSES_LAUNCH_URL,
    SHOW_LOADING_INDICATOR_MY_COURSES,
    HIDE_LOADING_INDICATOR_MY_COURSES,
    SHOW_LMS_MODULE,
    HIDE_LMS_MODULE,
    SELECT_COURSE_ITEM,
    CLOSE_COURSE_IFRAME,
    GET_USER_COURSE_PROGRESS,
    GET_USER_COURSE_PROGRESS_SUCCESS,
    GET_USER_COURSE_PROGRESS_FAILED,
    GET_COURSE_GOTO_URL,
    GET_COURSE_GOTO_URL_SUCCESS,
    GET_COURSE_GOTO_URL_SUCCESS_FAILED,
    CLEAR_COURSE_DATA,
    HANDLE_SHOW_ACTIVITY_COURSE,
    SHOW_RESET_COURSE_ALERT,
    OPEN_LAUNCH_PAD_URL
 } from '../actions/types';

const INITIAL_STATE = {
    myCourses: null,
    myCourseLoading: false,
    launchURL: null,
    showLMSWidget: false,
    selectedCourse: null,
    openCourseIframe: false,
    courseLoading: false,
    courseProgress: null,
    courseGotoURL: null,
    showActivityCourse: false,
    seletedCourseId: null,
    courseProgressLoading: false,
    selectedLanuchPad: null,
    openLaunchPad: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_MY_COURSES:
            return { ...state, myCourseLoading: true };
        case GET_SUCCESS_MY_COURSES:
            return { ...state, myCourses: action.payload, myCourseLoading: false };
        case GET_MY_COURSE_LAUNCH_URL:
            return { ...state, myCourseLoading: true };
        case GET_SUCCESS_MY_COURSES_LAUNCH_URL:
            return { ...state, launchURL: action.payload, myCourseLoading: false };
        case SHOW_LOADING_INDICATOR_MY_COURSES:
            return { ...state, myCourseLoading: true };
        case HIDE_LOADING_INDICATOR_MY_COURSES:
            return { ...state, myCourseLoading: false };
        case SHOW_LMS_MODULE:
            return { ...state, showLMSWidget: true };
        case HIDE_LMS_MODULE:
            return { ...state, showLMSWidget: false };
        case GET_USER_COURSE_PROGRESS:
            return { ...state, courseProgressLoading: true , showResetCourseAlert: false};
        case GET_USER_COURSE_PROGRESS_SUCCESS:
            return { ...state, courseProgressLoading: false, courseProgress: action.payload };
        case GET_USER_COURSE_PROGRESS_FAILED:
            return { ...state, courseProgressLoading: false };
        case GET_COURSE_GOTO_URL:
            return { ...state, courseLoading: true, showResetCourseAlert: false };
        case GET_COURSE_GOTO_URL_SUCCESS:
            return { ...state, courseLoading: false, courseGotoURL: action.payload };
        case GET_COURSE_GOTO_URL_SUCCESS_FAILED:
            return { ...state, courseLoading: false };
        case CLEAR_COURSE_DATA:
            return { ...state, courseLoading: false, openCourseIframe: false, courseProgress: null, courseGotoURL: null, showActivityCourse: false, seletedCourseId: null, showResetCourseAlert: false, courseProgressLoading: false, openLaunchPad: false, selectedLanuchPad: null };
        case HANDLE_SHOW_ACTIVITY_COURSE:
            return { ...state, showActivityCourse: action.payload.show, seletedCourseId: action.payload.courseId};
        case SELECT_COURSE_ITEM: 
            return { ...state, selectedCourse: action.payload, openCourseIframe: true, selectedLanuchPad: null};
        case CLOSE_COURSE_IFRAME: 
            return { ...state, openCourseIframe: false, myCourseLoading: false, openLaunchPad: false, selectedCourse: null, selectedLanuchPad: null };
        case SHOW_RESET_COURSE_ALERT: 
            return { ...state, showResetCourseAlert: action.payload};
        case OPEN_LAUNCH_PAD_URL: 
            return { ...state, selectedLanuchPad: action.payload.data, openLaunchPad: action.payload.show, selectedCourse: null, launchURL: null};
        default:
            return { ...state};
    }
}

 