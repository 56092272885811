import React, { Component } from "react";
import config from '../../config/config'
import LifeSherpaLoading from '../LifeSherpaLoading';
import Iframe from "../LifesherpaiFrame/iframe";
import {connect} from "react-redux";
import Dialog from '@mui/material/Dialog';

class LSPortalLogoutPopup extends Component {
  state = {
    configuratorLoading:true,
    lmsLoading:true,
    analyticsLoading:true,
  }

  configuratorIfrmaeLoaded = () => {
    setTimeout(()=> this.handleConfiguratorIframe(), 5000);   
  }

  handleConfiguratorIframe = () => {
    console.log("configurator iframe loaded ......");
    this.setState({configuratorLoading:false, analyticsLoading:false}, () => {
      this.handelCloseLogoutPopup(); 
    });
  }

  analyticsPortalLogout = () => {
    console.log("analytics iframe loaded ...");
    this.setState({analyticsLoading:false}, () => {
      this.handelCloseLogoutPopup(); 
    });
  }

  lmsIfrmaeLoaded = () => {
    console.log("lms iframe loaded ...");
    this.setState({lmsLoading:false}, ()=>{
      this.handelCloseLogoutPopup();
    });
  }

  handelCloseLogoutPopup = () => {
    const {configuratorLoading, lmsLoading, analyticsLoading} = this.state;
    const {isConfiguratorOpened, isLMSOpened, isAnalyticsLoggedIn, workspaceURL} = this.props;
    if(workspaceURL && isLMSOpened){
      if(!configuratorLoading && !lmsLoading && !analyticsLoading) {
        this.props.handleCloseLogoutPopup()
      }
    } else if(workspaceURL && !isLMSOpened) {
      if(!configuratorLoading && !analyticsLoading) {
        this.props.handleCloseLogoutPopup()
      }
    } else if(isLMSOpened && !workspaceURL) {
      if(!configuratorLoading && !lmsLoading) {
        this.props.handleCloseLogoutPopup()
      }
    } else {
      if (!configuratorLoading) {
        this.props.handleCloseLogoutPopup()
      }
    }
  }

  render() {
    let {configuratorLoading, lmsLoading , analyticsLoading} = this.state;
    let {openLogoutPopup, isConfiguratorOpened, isLMSOpened, launchURL, lmsUrl, isAnalyticsLoggedIn, workspaceURL} = this.props;
    let lmsLogoutUrl = lmsUrl ? lmsUrl.replace("autologin", "logout") : launchURL && launchURL.url ? launchURL.url.replace("gotocourse", "logout") : "https://test.lms.3rbehavioralsolutions.com/index/logout";
    let configuratorUrl = `${config.configurator}/#/logout`;
    let analyticsUrl = config.analyticsUrl;
    // console.log("analyticLogoutUrl -------> ", analyticsUrl);
    return (
      <Dialog		
        open={openLogoutPopup}
        aria-labelledby="responsive-dialog-title"
        className={` setcurrent-status-dialog-popup ${this.props.popupThemeClass}`}
        >
        <LifeSherpaLoading loading={lmsLoading || configuratorLoading || analyticsLoading} />
        <div className="d-flex align-items-center justify-content-center my-4 pt-2 pb-4">
          <div className="mb-4 pb-4">Please wait, logging out.......</div>
          </div>
          <div style={{visibility:"hidden"}} className="mt-2">
            <div id="configurator-logout-url">
              <Iframe
                url={configuratorUrl}
                onLoad={this.configuratorIfrmaeLoaded}
                height="2"
                width="300"
                scrolling="yes"
                id="myId"
                display="initial"
                position="relative"
                />
            </div>
            <div id="analytics-logout-url">
              {workspaceURL ? <Iframe
                url={analyticsUrl}
                onLoad={this.analyticsPortalLogout}
                height="2"
                width="300"
                scrolling="yes"
                id="myId"
                display="initial"
                position="relative"
                />
                : ""
              }
            </div>
            <div id="configurator-logout-url">
              {isLMSOpened ? 
                <Iframe
                url={lmsLogoutUrl}
                onLoad={this.lmsIfrmaeLoaded}
                height="2"
                width="300"
                scrolling="yes"
                id="myId"
                display="initial"
                position="relative"
                />: ""
              }   
            </div>
          </div>
      </Dialog>
    )
  }
}
const mapStateToProps = ({settings, authUser, MyCourses}) => {
	const { popupThemeClass } = settings; 
  const { myCourseLoading, launchURL } = MyCourses
  const {isConfiguratorOpened, isLMSOpened, lmsUrl, isAnalyticsLoggedIn, workspaceURL } = authUser;
	return {popupThemeClass, isConfiguratorOpened, isLMSOpened, lmsUrl, launchURL, isAnalyticsLoggedIn, workspaceURL};
}

export default connect(mapStateToProps,{})(LSPortalLogoutPopup);
