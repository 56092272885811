/**
 * Acivity Detail Reducers
 */
import {
    GET_ACTIVITIES_LIST_DETAIL,
    GET_SUCCESS_ACTIVITIES_LIST_DETAIL,
    SHOW_PRIVATE_ACTIVITY_LOADING,
    HIDE_PRIVATE_ACTIVITY_LOADING,
    ADD_PRIVATE_ACTIVITY,
    ADD_PRIVATE_ACTIVITY_SUCCESS,
    UPDATE_PRIVATE_ACTIVITY,
    UPDATE_PRIVATE_ACTIVITY_SUCCESS,
    GET_PRIVATE_ACTIVITY,
    GET_PRIVATE_ACTIVITY_SUCCESS,
    CHECK_DUPLICATE_ACTITY_TITLE,
    CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE,
    CHECK_FAILURE_DUPLICATE_ACTITY_TITLE,
    CLEAR_ACTIVITY_DATA,
    REMOVE_API_ERROR_MESSAGE,
    SHOW_ACTIVITY_CRETAED_MESSAGE,
    OPEN_EDITOR,SELECT_ACTIVTY,
 } from '../actions/types';
 
 /**
  * initial auth user
  */
 const INIT_STATE = {
     activity: null,
     schedules: [],
     steps: [],
     loading: false,
     allSteps: null,
     activityExist: false,
     activityTitle: '',
     open:false,
     errorMessage: '',
     activityState: null,
     successMessage: null,
     errorTitle: null,
     selected:null,
 };
 
 export default (state = INIT_STATE, action) => {
     switch (action.type) {
 
        case GET_ACTIVITIES_LIST_DETAIL:
            return { ...state, loading: true };
 
        case GET_SUCCESS_ACTIVITIES_LIST_DETAIL:
            return { ...state, activity: action.payload, loading: false };
 
        case ADD_PRIVATE_ACTIVITY:
            return { ...state, loading: true };

        case ADD_PRIVATE_ACTIVITY_SUCCESS:
            return { ...state, activityState: action.payload, loading: false, errorMessage: '', errorTitle: '',open:false,selected:null };

        case UPDATE_PRIVATE_ACTIVITY:
            return { ...state, loading: true };

        case UPDATE_PRIVATE_ACTIVITY_SUCCESS:
            return { ...state, activityState: action.payload, loading: false, errorMessage: '', errorTitle: '',open:false,selected:null};

        case GET_PRIVATE_ACTIVITY:
            return { ...state, loading: true };

        case GET_PRIVATE_ACTIVITY_SUCCESS:
            return { ...state, activity: action.payload,errorMessage: '', loading: false };

        case CHECK_DUPLICATE_ACTITY_TITLE:
            return { ...state, loading: true };

        case CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE:
            const {isExist, title } = action.payload
            return { ...state, loading: false, activityExist: isExist, activityTitle: title, errorMessage: ''};

        case CHECK_FAILURE_DUPLICATE_ACTITY_TITLE:
            return { ...state, loading: false, errorMessage: action.payload.message, errorTitle: action.payload.title};

        case CLEAR_ACTIVITY_DATA: 
            return{...state, loading: false, errorMessage: '', errorTitle: '', activity: null, schedules: [], steps: [], activityExist: false, activityTitle: '', activityState: null}
        
        case REMOVE_API_ERROR_MESSAGE:
            return { ...state, errorMessage: '', errorTitle: '' };

        case SHOW_PRIVATE_ACTIVITY_LOADING: 
            return{...state, loading: true}

        case HIDE_PRIVATE_ACTIVITY_LOADING:
            return{...state, loading: false}
        
        case SHOW_ACTIVITY_CRETAED_MESSAGE: 
            return{...state, successMessage: action.payload}
        case OPEN_EDITOR:
            return {...state,open: action.payload}
        case SELECT_ACTIVTY:
            return {...state,selected:action.payload}  
        default: 
            return { ...state };
     }
 }
 