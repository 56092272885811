import React, { Component } from 'react';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import {database} from '../../firebase';
import { ref, onValue} from "firebase/database"
import Tooltip from '@mui/material/Tooltip';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { NotificationManager } from 'react-notifications';
import MicIcon from '@mui/icons-material/Mic';
import PeopleIcon from '@mui/icons-material/People';
import Badge from '@mui/material/Badge'
import ParticipantsList from "./ParticipantsList";
// import components 
import Participant from "./Participant";

class VideoChatRoom extends Component {

  constructor(props) {
    super(props)

    this.state = { 
      participants:[],
      mute:'',
      videoMute:'',
      layoutFlow:"row",
      containerCols:2,
      containerRows:1,
      room:null,
      date:new Date().toISOString(),
      dateIntervel:0,
      creatingNewTrack:false,
      pingParticipant:null,
      maxMember:30,
      realTimeData:null,
      p2pTimeOut: null,
      showParticipantListPopup:false,
      mobileViewParticipant:0,
      isGroupCall: false
    }

  }
   
  componentDidMount() {
    let {roomName, room , mediaAvailable} = this.props; 
    let {mute, videoMute} = this.state;
    console.log(" room ---> ", room);
    if(room) {
      if(room.localParticipant) {
        let userData = this.getClientNameUsingIdentity(room.localParticipant);
        console.log(" got user data --> ", userData);
        if(userData) {
          room.localParticipant.name = userData.name;
          room.localParticipant.userId = userData.userId;
          room.localParticipant.profileImageURL = userData.profileImageURL;
          room.localParticipant.localUser = "localUser"
        }
      }
           
      if(room.participants) {
        let participantsList = Array.from(room.participants.values());
          participantsList && participantsList.length > 0 && participantsList.map(participant => {
            this.participantConnected(participant);
        })
      }
      const that = this;
      this.props.room.on('participantConnected', participant => this.participantConnected(participant));
      this.props.room.on('participantDisconnected', participant => this.participantDisconnected(participant));
    }

    if(!mediaAvailable.audio) {
      mute = "mute";
    }

    if(!mediaAvailable.video) {
      videoMute = "mute";
    }
    const dateIntervel = setInterval(() => this.setState({dateIntervel: new Date().toISOString()}),60000);
    this.setState({dateIntervel:dateIntervel, mute: mute, videoMute: videoMute});
    this.roomDetailsFromRealtime(roomName);
  }

  componentWillUnmount() {
    const {dateIntervel, p2pTimeOut} = this.state;
    let { room} = this.props; 
    if(p2pTimeOut) {
      clearTimeout(p2pTimeOut);
    }
    room.off("participantConnected", this.participantConnected);
    room.off("participantDisconnected", this.participantDisconnected);
    clearInterval(dateIntervel);
  }

  onDominantSpeakerChanged(participant) {
    localStorage.setItem("dominantSpeakerIdentity",participant.identity);
    consle.log(" dominant speaker changed -->", participant, participant.identity);
    if(participant && participant.identity) {
      this.setState({dominantSpeakerIdentity : participant.identity})
    }
  }

  roomDetailsFromRealtime = (roomname) =>  {
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');
    let userId = localStorage.getItem('auth_uid');
    const realtimePath = `Realtime/${orgId}/Users/${userId}/groupCallsInProgress/${roomname}`;
    console.log( " getting realtime roomDetails  --> ",realtimePath);
    onValue(ref(database, realtimePath), (snapshot) => {
      const details = snapshot.val();
      console.log( " roomDetails from realtime --> ",details);
      if(details) {
        if(details.group) {
          this.handleGroupPaticipant(details);
        } else {
          this.handleP2PParticipant(details);
        }
        this.setState({realTimeData: details, isGroupCall: details.group});
      } else {
        this.props.handleLogout(this.state.isGroupCall);
      }
    });
    let p2pTimeOut = setTimeout(() => this.handleP2PTimeOut(), 30000);
    this.setState({p2pTimeOut:p2pTimeOut});
  }; 

  handleGroupPaticipant = (details) => {
    console.log(" handling group participant --> ");
    let {room } = this.props; 
    details && details.members && details.members.length > 0 && details.members.map((user) => {
      console.log(" group user --> ", user)
      if(user.status && (user.status == "cancel" || user.status == "removed")) {
        this.handleCancelMember(user);
      } 
      this.realtimeLocalParticipantActions(user);
    });
  }

  handleP2PParticipant = (details) => {
    console.log(" handling P2P participant --> ")
    details && details.members && details.members.length > 0 && details.members.map((user) => {  
      console.log(" P2P user --> ", user)
      if(user.status && (user.status == "cancel" || user.status == "declined" || user.status == "removed" || user.status == "unanswered")) {
        this.props.handleLogout(this.state.isGroupCall);
      } else {
        //this.realtimeLocalParticipantActions(user);
      }  
    })
  }
    
  handleP2PTimeOut = () => {
    let {realTimeData, participants} = this.state;
    if(realTimeData && !realTimeData.group) {
      let isMemberAccepted = false;
      realTimeData && realTimeData.members && realTimeData.members.length > 0 && realTimeData.members.map((user) => {  
        if(user.status && user.status == "accepted") {
          isMemberAccepted = true;  
        } 
      });
      if(participants && participants.length >= 1) {
        isMemberAccepted = true; 
      }
      console.log(" Call accepted --> ",isMemberAccepted)
      if(!isMemberAccepted) {
        this.props.handleLogout(this.state.isGroupCall);
      }
    } 
  }

  realtimeLocalParticipantActions = (user) => {
    let { room} = this.props; 
    let localParticipantUserId = room && room.localParticipant ? room.localParticipant.identity.split("--")[0]:"";
    if(user.userId == localParticipantUserId) {
      console.log(" realtime local participant status action --> ", user)
      if(user.status && user.status == "removed") {
        this.props.handleLogout(this.state.isGroupCall);
      }
      console.log(" realtime local participant mute action --> ", user.mute)
      if(user.mute) {
        console.log(' localparticipant muted');
        this.handleRealtimeMute();
      } else if(user.mute == false) {
        console.log(" localparticpant unmuted");
        this.handleRealtimeUnmute();
      }
    } 
  }
     
  handleCancelMember = (user) => {
    console.log(" Removing from participant list --> ", user.userId);
    let {participants} = this.state;
    participants.map(participant => {
      let userId = participant ?  participant.identity.split("--")[0]:"";
      if(userId == user.userId) {
        const disconnedtedMessage = `${participant.name || "Member"} disconnnected`;
        NotificationManager.warning(disconnedtedMessage); 
        this.participantDisconnected(participant);
      }
    })
  }
     
  participantConnected(participant)  {
    let { room} = this.props; 
    let userData = this.getClientNameUsingIdentity(participant);
    //console.log(" got user data --> ", userData);
    let localParticipantUserId = room && room.localParticipant ? room.localParticipant.identity.split("--")[0]:"";
      if(userData) {
        participant.name = userData.name;
        participant.userId = userData.userId;
        participant.profileImageURL = userData.profileImageURL;
      } 
    let connedtedMessage = null;
    let {participants} = this.state;
    console.log(" participant connected --->",participants);
    console.log(" userId --->",participant.userId);
    console.log(" localParticipantUserId --->",localParticipantUserId);
    if(participant.userId != localParticipantUserId) {
        let index = participants ? participants.findIndex(parti => {
            let id = parti.identity ?   parti.identity.split("--")[0]:"";
            return id == participant.userId;
          }): -1 ; 
      if(index >= 0) {
        console.log(" participnat added to room")
        participants.splice(index, 1, participant);
      } else {
        console.log(" participnat added ")
        participants.push(participant);
        connedtedMessage = `${participant.name || "Member"} Joined Call`;
      }
    }
    if(connedtedMessage) {
      NotificationManager.success(connedtedMessage); 
    }
    this.setState({participants:participants})
    this.updateParticipantsLayoutOnConOrDisCon();
  }
      
  participantDisconnected = (participant) => {
    let {participants, pingParticipantIdentitiy} = this.state;
    let updatedParticipants = participants.filter((p) => p.identity != participant.identity)
    console.log(" participant disconnected --->",participant);
    if(participant.identity == pingParticipantIdentitiy) {
      this.setState({participants: updatedParticipants, pingParticipantIdentitiy: null})
    } else {
      this.setState({participants: updatedParticipants})
    }
      this.updateParticipantsLayoutOnConOrDisCon();
  };
     
  participantConnectedTest = (participant) => {
    const {participants} = this.state;
    let fakeParticipant = participant;
    let userData = this.getClientNameUsingIdentity(fakeParticipant);
    if(userData) {
      fakeParticipant.name = userData.name;
      fakeParticipant.userId = userData.userId;
      fakeParticipant.profileImageURL = userData.profileImageURL;
    } 
    fakeParticipant.userIndex = participants.length + Math.random().toString(36).substr(2, 5);
    participants.push(fakeParticipant);
    console.log(" participant connected --->",participants);
    this.setState({participants:participants})
    this.updateParticipantsLayoutOnConOrDisCon();
  };
     
  participantDisconnectedTest = () => {
    let {participants} = this.state;
    participants.pop();
    console.log(" participant disconnected --->",participants);
    this.setState({ participants:participants});
    this.updateParticipantsLayoutOnConOrDisCon();
  }
     
  getClientNameUsingIdentity = (participant) => {
    let {contactList, videoCallDetails} = this.props;
    let videoMembers = videoCallDetails && videoCallDetails.members ? videoCallDetails.members : [];
    console.log(" videoCallDetails members -->", videoMembers);
    console.log(" contactList --->",contactList);
    let userId = participant && participant.identity ?   participant.identity.split("--")[0]:"";
    console.log(" gotUserId --->",userId);
    let userData = null;
    if(videoMembers && videoMembers.length > 0) {
      userData = videoMembers.find(user => user.userId == userId);
      console.log(" found participant details from vidoe call details --> ", userData)
    } 
    if(contactList && contactList.length > 0 && !userData) {
      userData = contactList.find(user => user.userId == userId);
      console.log(" found participant details from contact list --> ", userData)
    }
    if(!userData) {
        userData = {userId: userId, name:"", profileImageURL:""};
    }
    return userData;
  }
     
  updateParticipantsLayoutOnConOrDisCon = () => {
    let {windowWidth} = this.props;
    let {pingParticipantIdentitiy} = this.state;
    let containerCols = 2;
    let containerRows = 2;
    let layoutFlow = "row";
    if(windowWidth <= 470 || pingParticipantIdentitiy) {
      containerCols = 1;
      containerRows = 2;
      layoutFlow = "col";
    } 
    this.updateParticipantLayout(containerCols, containerRows, layoutFlow);
  }
    
  updateParticipantLayout = (cols, rows, flow) => {
    let {participants, maxMember, pingParticipantIdentitiy} = this.state; 
    let containerCols = cols;
    let containerRows = rows;
    let layoutFlow = flow;
    let totalParticipant = participants.length + 1;
    if(pingParticipantIdentitiy) {
        totalParticipant = participants.length;
    }
    for(let i = 1; i <= maxMember; i++) {
      let scopeParticipant = containerCols * containerRows;
      let availableScope = scopeParticipant - totalParticipant;
      if(availableScope < 0) {
        let posScope = availableScope * (-1);
        if(layoutFlow == "row") {
          containerCols = containerCols +1;
          layoutFlow = "col";
          if(posScope <= containerRows) {
            break
          }
        } else {
          containerRows = containerRows +1;
          layoutFlow = "row";
          if(posScope <= containerCols) {
            break
          }
        }
      } else {
        break;
      }
    }
    console.log(" totalParticipant -->", totalParticipant);
    console.log(" containerCols -->", containerCols);
    console.log(" containerRows -->", containerRows);
    console.log(" layoutFlow -->", layoutFlow);
    this.setState({containerCols:containerCols, containerRows:containerRows, layoutFlow:layoutFlow});
  }
    
  handleRemoteParticipantMute = (userId) => {
    let { room} = this.props; 
    let {realTimeData, isGroupCall} = this.state;
    console.log(" clicked on unmute audio icon --> ",userId)
    if(realTimeData && userId) {
      let localParticipantUserId = room && room.localParticipant ? room.localParticipant.identity.split("--")[0]:"";
      let user = realTimeData.members.find(user => user.userId == localParticipantUserId);
      if(user && user.host) {
        this.props.sendVidoeCallResponse("mute", [userId], isGroupCall);
        console.log(" local participant muted user --> ",user.name)
      }
    }
  }
    
  handleRemoteParticipantUnMute = (userId) => {
    let { room} = this.props; 
    let {realTimeData, isGroupCall} = this.state;
    console.log(" clicked on mute audio icon -->  ", userId)
    if(realTimeData && userId) {
      let localParticipantUserId = room && room.localParticipant ? room.localParticipant.identity.split("--")[0]:"";
      let user = realTimeData.members.find(user => user.userId == localParticipantUserId);
      if(user && user.host) {
        this.props.sendVidoeCallResponse("unmute", [userId], isGroupCall);
        console.log(" local participant unmuted user --> ",user.name)
      }
    }
  }
    
  handleRemoteParticipantRemove = (userId) => {
    let { room} = this.props; 
    let {realTimeData, isGroupCall} = this.state;
    console.log(" clicked on remove participant icon --> ", userId)
    if(realTimeData && userId) {
      let localParticipantUserId = room && room.localParticipant ? room.localParticipant.identity.split("--")[0]:"";
      let user = realTimeData.members.find(user => user.userId == localParticipantUserId);
      if(user && user.host) {
        this.props.sendVidoeCallResponse("removeParticipant", [userId], isGroupCall);
        console.log(" local participant removed user --> ",user.name)
      }
    }
  }
    
  handleRealtimeMute= () => {
    let {room} = this.props;
    let {mute} = this.state;
    if(mute != "mute") {
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable()
      }) 
      this.setState({mute:"mute"});
    }  
  }

  handleRealtimeUnmute = async () => {
    let {room} = this.props;
    let {mute} = this.state;
    if(mute == "mute") {
      room.localParticipant.audioTracks.forEach(publication => publication.track.enable());
      this.setState({mute:""});
    }
  };

  handleMute= () => {
    let {room} = this.props;
    room.localParticipant.audioTracks.forEach(publication =>{
      publication.track.disable()
    })
    this.setState({mute:"mute"});
    this.props.sendVidoeCallResponse("mute", null, this.state.isGroupCall);
  }
     
  handleUnmute= () => {
    let {room, mediaAvailable} = this.props;
    if(mediaAvailable.audio) {
      room.localParticipant.audioTracks.forEach(publication =>{
        publication.track.enable()
      })
      this.setState({mute:""});
      this.props.sendVidoeCallResponse("unmute", null, this.state.isGroupCall);
    }
  };
    
  handleVideoMute= () => {
    let {room} = this.props;
    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.disable();
    })
    this.setState({videoMute:"mute"});
  }
      
  handleVideoUnmute =  async () => {
    let {room, mediaAvailable} = this.props;
    if(mediaAvailable.video) {
      room.localParticipant.videoTracks.forEach( publication => {
        publication.track.enable();
      });
      this.setState({videoMute:""});
    }
  }
     
  getDisableVideoAvatarStyle = (identity) => {
    let videoContainer = document.getElementById(identity);
    let avatarStyle = {height: "80px" , width: "80px", fontSize: "37px"}
    if(videoContainer) {
      let containerHeight =  videoContainer.clientHeight;
      let avatarHeight = Number.parseInt(containerHeight/3);
      let avatarFontSize = Number.parseInt(avatarHeight/3);
      avatarStyle = {height: avatarHeight, width: avatarHeight, fontSize: avatarFontSize}
    } 
    //console.log(videoContainer, avatarStyle);
    return avatarStyle;
  }
     
  setPingWindowLayoutFlow = () => {
    let containerRows = 2;
    let containerCols = 1;
    let layoutFlow = "col";
    this.updateParticipantLayout(containerCols, containerRows, layoutFlow);
  }
     
  setDefaultWindowLayoutFlow = () => {
    let {windowWidth} = this.props;
    let containerCols = 2;
    let containerRows = 2;
    let layoutFlow = "row";
    if(windowWidth <= 470) {
      containerCols = 1;
      containerRows = 2;
      layoutFlow = "col";
    } 
    this.updateParticipantLayout(containerCols, containerRows, layoutFlow);
  } 
     
  handlePingParticipantToMainScreen = (identity) => {
    let {windowWidth} = this.props;
    if(windowWidth > 470) {
      this.handlePingParticipant(identity);
    } else {
      this.handleSetMobileViewMember(identity);
    }
  } 
     
  handleSetMobileViewMember = (identity) => {
    let {participants} = this.state;
    let participantIndex = participants.findIndex((p) => p.identity == identity);
    if(participantIndex >= 0) {
      this.setState({mobileViewParticipant: participantIndex});
    }
  }
     
  handlePingParticipant = (identity) => {
    let {pingParticipantIdentitiy} = this.state;
    if(pingParticipantIdentitiy && identity == pingParticipantIdentitiy) {
      console.log(" ping participant removed -->", identity)
      this.setState({pingParticipantIdentitiy: null})
      this.setDefaultWindowLayoutFlow();
    }  else {
      console.log(" ping participant added -->", identity)
      this.setState({pingParticipantIdentitiy: identity})
      this.setPingWindowLayoutFlow();
    }
  }
     
  handleParticipantListPopup = (bool) => [
    this.setState({showParticipantListPopup: bool})
  ]

  getSingleParticipant = (participant,keyIndex) => {
    let {room, dominantSpeakerIdentity} = this.props;
    const {mute, videoMute , creatingNewTrack, participants, pingParticipantIdentitiy, realTimeData} = this.state;
    let localParticipantUserId = room && room.localParticipant ? room.localParticipant.identity.split("--")[0]:"";
    let isLocalHostOfGroup = false;
    if(realTimeData && realTimeData.group) {
      let localUser = realTimeData.members.find(user => user.userId == localParticipantUserId);
      if(localUser && localUser.host) {
        isLocalHostOfGroup = true;
      }
      console.log(" host of group --> ", isLocalHostOfGroup, room)
    }
    // console.log(" room --> ", room)
    return (
      <Participant 
        key={participant.sid} 
        keyIndex={keyIndex}
        dominantSpeakerIdentity={dominantSpeakerIdentity}
        participant={participant} 
        realTimeData={realTimeData}
        videoMuteLocal={keyIndex == "localParticipant" ? videoMute : ""}  
        isLocalHostOfGroup={isLocalHostOfGroup}
        muteLocal={keyIndex == "localParticipant" ? mute : ""} 
        handlePingParticipant={() => this.handlePingParticipant(participant.identity)} 
        getDisableVideoAvatarStyle={this.getDisableVideoAvatarStyle}
        totalRemoteParticipant={participants.length}
        pingParticipantIdentitiy={pingParticipantIdentitiy}
        handleRemoteParticipantMute={() => this.handleRemoteParticipantMute(participant.userId)}
        handleRemoteParticipantUnMute={() => this.handleRemoteParticipantUnMute(participant.userId)}
        handleRemoteParticipantRemove={() => this.handleRemoteParticipantRemove(participant.userId)}
      />
    );
  }

  render() { 
		const { room, roomName, windowWidth } = this.props;
		const {participants, mute, containerRows,containerCols , videoMute,date, pingParticipantIdentitiy, realTimeData, showParticipantListPopup, mobileViewParticipant } = this.state;
    // console.log(" totalRemoteParticipant --> ",totalRemoteParticipant); 
    let  pingParticipant = null;
    const remoteParticipants = participants.map((participant, index) => { 
      if(participant.identity == pingParticipantIdentitiy) {
      pingParticipant = participant;
      return "";
      } else {
        return (
        <div key={index} className="child-video-participant" >
          {this.getSingleParticipant(participant, index)}
        </div>
        )
      }
    });

    if(room.localParticipant.identity == pingParticipantIdentitiy) {
      pingParticipant = room.localParticipant;
    }

    return (
      <div className="room py-4" style={{height:'calc(100vh - 0px)'}}>
          {remoteParticipants.length == 1 ?
            <React.Fragment>
              <div className="video-participant mx-auto">
                  {this.getSingleParticipant(participants[0], "singleParticipant")}
              </div>
              <div className="mini-video-participant" >
                {this.getSingleParticipant(room.localParticipant,"localParticipant")}
              </div>
            </React.Fragment>
            : remoteParticipants.length == 0 ?
              <div className="video-participant mx-auto">
                  {this.getSingleParticipant(room.localParticipant, "localParticipant")}
              </div> 
            : windowWidth > 470 ?
            <React.Fragment>
              {pingParticipant && 
                  <div className="video-participant ml-1" style={{width:"70%"}}>
                  {this.getSingleParticipant(pingParticipant, "pinParticipant")}
              </div>}  
              <div className="group-video-participant" style={{gridAutoRows: (100/containerRows)+'%',gridTemplateColumns:`repeat(${containerCols}, 1fr)`, width: pingParticipant ? "30%" : "100%", height:"90%"}}>
                      {remoteParticipants}
                  {room.localParticipant.identity == pingParticipantIdentitiy ? ""
                  : 
                    <div key={"lcoalparticipant"} className="child-video-participant" style={!pingParticipantIdentitiy && remoteParticipants.length == 2 && windowWidth > 470 ? {left:"52%", position:"relative"}:{}}>
                        {this.getSingleParticipant(room.localParticipant, "localParticipant")}
                    </div>
                  }
              </div>
            </React.Fragment>
            :
            <React.Fragment>
                <div className="video-participant mx-auto">
                    {this.getSingleParticipant(participants[mobileViewParticipant], "singleParticipant")}
                </div>
                <div className="mini-video-participant" >
                  {this.getSingleParticipant(room.localParticipant,"localParticipant")}
                </div>
            </React.Fragment>
          }
          <div>
            
          </div>
      <div className="d-flex  align-items-center w-100  fixed-bottom mb-2" style={{height:'auto'}}>
        <div className="col-lg-4 col-md-4  d-none mx-2 d-md-block">
          <div className="d-none d-md-flex" /*onClick={() => this.props.handleParticipantCancel()}*/>
              {moment(date).format("hh:mm A")/*+" | "+roomName*/}
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12  d-flex justify-content-center">
          {participants && participants.length >= 1 && realTimeData && realTimeData.group &&
              <Badge className="badgeon-chatlist" badgeContent={participants.length} color="primary">
                <Tooltip title={`${participants.length} Participants Connnected`} arrow>
                  <IconButton className={'inactive-button mb-2 '} 
                    onClick= {() => this.handleParticipantListPopup(true)}
                  >
                      <PeopleIcon/>
                  </IconButton>
                </Tooltip>
              </Badge>
              }
          <Tooltip title={`${videoMute==='mute'? "Unmute Video":"Mute Video"}`} arrow><IconButton className={videoMute==='mute'?'active-button mb-2  mr-3 ml-3':'inactive-button mb-2 mr-3 ml-3'} 
                onClick={() => videoMute==='mute'? this.handleVideoUnmute():this.handleVideoMute()}>
                {videoMute==='mute'?<VideocamOffOutlinedIcon />:<VideocamOutlinedIcon />}</IconButton></Tooltip>
          <Tooltip title={`${mute==='mute'? "Unmute Audio":"Mute Audio"}`} arrow>
            <IconButton className={mute==='mute'?'active-button mb-2  mr-3':'inactive-button mb-2 mr-3'} 
              onClick={() => mute==='mute'? this.handleUnmute(): this.handleMute()}
            >
              {mute==='mute' ? <MicOffRoundedIcon /> : <MicIcon/>}
            </IconButton>
          </Tooltip>
          <Tooltip title={"Leave Call"} arrow>
          <IconButton className='active-button leave-call-button mb-2' onClick={() => this.props.handleLogout(this.state.isGroupCall)}>
                <CallEndIcon />
          </IconButton>
          </Tooltip>
        </div>
        <div className="col-lg-4 col-md-4  d-none mx-2 d-md-block"></div>
      </div>
      {participants && participants.length >= 1 && showParticipantListPopup && 
        <ParticipantsList
          showParticipantListPopup={showParticipantListPopup}
          participants={participants}
          popupThemeClass={this.props.popupThemeClass}
          handleParticipantListPopup={this.handleParticipantListPopup}
          handleRemoteParticipantMute={this.handleRemoteParticipantMute}
          handleRemoteParticipantUnMute={this.handleRemoteParticipantUnMute}
          handleRemoteParticipantRemove={this.handleRemoteParticipantRemove}
          realTimeData={realTimeData}
          localParticipant={room.localParticipant}
          handlePingParticipantToMainScreen= {this.handlePingParticipantToMainScreen}
        />}
    </div>
    );
  }
}

export default VideoChatRoom;
