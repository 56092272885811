/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'

// rct collapsible card
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
//Actions
import {
    changeRole,
    getUserById,
} from '../../../actions'

import {
    BolletinBoard,
} from "../../../components/Widgets";

import { withAuth0 } from "@auth0/auth0-react";

class Resources extends Component {
    componentDidMount() {
        const { widgetApp } = this.props;
        const auth_uid = localStorage.getItem('auth_uid');
        const uid = localStorage.getItem('uid');
        const idToken = localStorage.getItem('idToken')
        const orgId = localStorage.getItem("orgId")
        if (uid != auth_uid) {
            localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            this.props.changeRole({ role: 'admin', id: auth_uid });
        }
        if (idToken && auth_uid) {
            this.props.getUserById(auth_uid, idToken, widgetApp)
        }
    }

    render() {
        return (
            <div className="w-100 " data-testid="ls-dashboard">
                <RctCollapsibleCard
                    heading={<div className="d-flex align-items-center justify-content-between text-white">
                        <div>My Resources</div>
                        <div >
                            {this.props.categories ? "(" + this.props.categories.reduce((acc, curr) => acc + curr.totalCount, 0) + ")" : ""}
                        </div>
                    </div>
                    }
                    fullBlock
                    customClasses="overflow-hidden theme-background "
                    headingCustomClasses="bg-lifesherpa"
                >
                    <BolletinBoard />
                </RctCollapsibleCard>
            </div>
        )
    }
}

const mapStateToProps = ({ MyCourses, Resources }) => {
    const { categories } = Resources;
    const { myCourseLoading, myCourses } = MyCourses;
    return { categories, myCourseLoading, myCourses }
}

export default connect(mapStateToProps, {
    changeRole,
    getUserById,
})(Resources)
