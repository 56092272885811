import React, { Component } from 'react';
// import { Line} from 'react-chartjs-2';
import ChartConfig from '../../constants/chart-config';
import LifeSherpaLoading from '../LifeSherpaLoading';
import {
	Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  } from 'chart.js';
import {
	Line
} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);


// defaults.global.maintainAspectRatio = false
const options = {
  responsive: true,
  maintainAspectRatio: false,
  redraw: true,
  legend: {
    labels: {
      fontColor: ChartConfig.legendFontColor
    }
  },
  scales: {
    x: {
      gridLines: {
        color: ChartConfig.chartGridColor
      },
      ticks: {
        fontColor: ChartConfig.axesColor
      }
    },
    y: {
      gridLines: {
        color: ChartConfig.chartGridColor
      },
      ticks: {
        fontColor: ChartConfig.axesColor
      }
    }
  }
};

export default class LineChartComponent extends Component {

  componentDidMount() {
    if(this.props.getChartData) {
      this.props.getChartData(this.props.chartId)
    } 
  }
  
  render() {
    return (
      <article className="canvas-container">
        {
					this.props.loading ?
              <LifeSherpaLoading loading={this.props.loading}/>
          :
          <Line data={this.props.data} options={options} /*width={400} height={300} options={{ maintainAspectRatio: false }}*/ />
				}
      </article>
    );
  }
}
