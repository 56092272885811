/**
 * Signin Firebase
 */

 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import Button from '@mui/material/Button';
 import AppBar from '@mui/material/AppBar';
 import Toolbar from '@mui/material/Toolbar';
 import { Link } from 'react-router-dom';
 import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
 import QueueAnim from 'rc-queue-anim';
 import { withRouter } from 'react-router-dom';
 import SweetAlert from 'react-bootstrap-sweetalert'
 
 // app config
 import AppConfig from '../constants/AppConfig';
 
 // redux action
 import {
    validateInviteCode,
    getPrivacyPlocy,
    getUserDetailFromToken,
    clearValidateInviteCodeError
 } from '../actions';
 
 const checkBoxStyle = {
    position: 'absolute',
    marginTop: '0.1rem',
    marginLeft: '-1.7rem',
    height: 20,
    width: 20
 }
 
 class SignInFirebaseToken extends Component {
 
    state = {
       email: 'ajayspecial0502@gmail.com', //'demo@example.com',
       password: 'Abcd1234', //'test#123',
       isTokenEmpty: false,
       token: '',
       enabledPrivacy: null,
       showError: null,
       loginAlert:false
    }
 
    componentDidMount(){
       this.props.getPrivacyPlocy("org");
       const showTokenInvalid = this.props.status && this.props.status === 'failed'  ? true : false;
       this.props.clearValidateInviteCodeError();
       //    let params = null
       //    if (this.props.location.search) {
       //        params = queryString.parse(this.props.location.search)
       //        if(params.debug){}
       //        else this.setState({loginAlert:true})
       //        //this.props.history.push('/')
       //   }else this.setState({loginAlert:true})
         //this.props.history.push('/')
    }
 
    handlePrivacypolicy (e) {
       this.setState({enabledPrivacy: e.target.checked, showError: !e.target.checked})
    }
 
     /**
      * On User Login
      */
    /*onTokenVerify(e) {
       e.preventDefault();
       if (this.state.token === ''){
          this.setState({isTokenEmpty: true})
       } else if(!this.state.enabledPrivacy){
         this.setState({showError: true})
       }
       else {
          this.props.validateInviteCode(this.state.token, this.props.history);
       //   this.props.history.push('/signuplifesherpa'); 
       }
    }*/
    onTokenVerify(e) {
       e.preventDefault();
       if (this.state.token === ''){
          this.setState({isTokenEmpty: true})
       } else if(!this.state.enabledPrivacy){
         this.setState({showError: true})
       }
          else {
            this.props.validateInviteCode(this.state.token, this.props.history);
             this.props.getUserDetailFromToken(this.state.token, this.props.history);
          }
       }
       
    handleBack=()=>{
       this.setState({loginAlert:false})
           this.props.history.push('/')
    }
    onChangeTokenField =(event) => {
      //  this.props.handelTokenSubmit()
        this.setState({ token: event.target.value, isTokenEmpty: event.target.value !== '' ? false : true })
    }
    render() {
       const { token, isTokenEmpty, enabledPrivacy, showError } = this.state;
       const { loading } = this.props;
       const isContinueEnabled = (isTokenEmpty === null || showError === null || (token === null || token === '') ) ? true :  isTokenEmpty || showError
       const showTokenInvalid = this.props.status && this.props.status === 'failed' && this.props.inviteCode && (this.props.inviteCode === token) ? true : false
      /*
      Migration Token : -MGCULdEx4x4bok96Mer
      Enterprise Token : 5Mhbt8 , username = harshit30@lspragma.onmicrosoft.com
      */
       return (
             <div>
             {this.state.loginAlert&&
                   <div className="col-12 p-4">
                      <SweetAlert
                         warning
                         btnSize="sm"
                         customClass="warningText"
                         confirmBtnText="OK"
                         confirmBtnBsStyle="warning"
                         title="Login"
                         onConfirm={()=>this.handleBack()}
                      >
                         <div style={{fontFamily:"'Lato'",fontWeight:400}}>Not a valid user!</div>
                      </SweetAlert>
                   </div>}
                        <FormGroup className="has-wrapper">
                            <Input
                            type="text"
                            value={token}
                            name="user-token"
                            autoCapitalize="none"
                            id="user-token"
                            className="has-input input-lg text-center text-dark"
                            placeholder="Enter Token Here"
                            style={{fontSize:18+"px"}}
                            invalid={(isTokenEmpty ? true : false) || showTokenInvalid}
                            onKeyPress={e => {
                                if (e.key === 'Enter') this.onTokenVerify(e);
                                }}
                            onChange={(event) => this.onChangeTokenField(event)}
                            />
                            {isTokenEmpty ?
                                <FormFeedback style={{fontSize:18+"px"}}>You must supply a valid invitation token to continue</FormFeedback>
                                : showTokenInvalid &&
                                <FormFeedback className="d-block" style={{fontSize:18+"px"}}>The invitation token provided is not valid</FormFeedback>
                            }
                        </FormGroup>
                        <FormGroup className={`has-wrapper border p-2 text-gray border-primary`}>
                            <Label check className="pl-3" style={{fontSize:18+"px",color:"gray"}}>
                                <Input checked={enabledPrivacy ? enabledPrivacy : false} type="checkbox" id="checkbox1" style={checkBoxStyle} onChange={(e) => this.handlePrivacypolicy(e)} />{' '}
                                {'I have reviewed & accepted the '}<a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a>
                            </Label>
                            {showError && <FormFeedback className="d-block" style={{fontSize:18+"px"}}>Please acknowledge your consent to the Privacy Policy to continue</FormFeedback>}
                        </FormGroup>
                        <FormGroup>
                        <Button
                            className="btn-block text-white w-100"
                            variant="contained"
                            size="large"
                            onClick={(e) => this.onTokenVerify(e)}
                            style={isContinueEnabled ? {backgroundColor: 'gray', fontSize: 18+'px'} : {backgroundColor:'#008C95',fontSize: 18+'px'}}
                            disabled={isContinueEnabled}
                        >
                            Continue
                            </Button>
                        </FormGroup>
             </div>
         
       );
    }
 }
 
 // map state to props
 const mapStateToProps = ({ authUser }) => {
    const { user, loading, inviteCode, status, privacyPolicy } = authUser;
    return { user, loading, inviteCode, status, privacyPolicy }
 }
 
 export default withRouter(connect(mapStateToProps, {
    validateInviteCode,
    getPrivacyPlocy,
    getUserDetailFromToken,
    clearValidateInviteCodeError
 })(SignInFirebaseToken));
 