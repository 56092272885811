/**
 * App.js Layout Start Here
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import {requireSignIn, scrollFunctionByClass} from '../helpers/helpers'
// rct theme provider
import RctThemeProvider from './RctThemeProvider';
import SuccessComponent from '../components/LifesherpaiFrame/SuccessComponent'
import UIFallbackScreen from '../components/UIFallbackScreen';
import RctSectionLoader from '../components/RctSectionLoader/RctSectionLoader';
//Main App
import RctDefaultLayout from './DefaultLayout';

// app signin
import Signin from './SigninFirebase';

// async components
import {
   AsyncLifesherpaDashboardComponent,
   AsyncResourcesPage,
   AsyncUserModuleCharts,
   AsyncLifesherpaiFrame,
   AsyncAnalyticsiFrame,
   AsyncMyActivityPreview,
   AsyncAuthoLogin,
   AsyncAuth0RedirectSignIn,
   AsyncLifesherpaVideoCall
} from '../components/AsyncComponent/AsyncComponent';

import {
   clearActivityDataOnClose,
   hideStartWorkflowPanel,
   handleUserWorkflowPanel 
} from '../actions'

import AppSignUpLifesherpa from './LifesherpaSignup';
import AppSignInWithToken from './SignInFirebaseToken'
import AppConfirmation from '../components/LoginComponent/LoginConfirmation'
import AppInitialLaunchScreen from '../components/LaunchScreen/InitialLaunchScreen'
import IDPInitiatedSSOComponent from '../components/LaunchScreen/IDPInitiatedSSOComponent'
import AppSuccess from '../components/SuccessComponent/SuccessScreen'
import Auth0logout from './Auth0logout';
import AnalyticsLogoutScreen from "./AnalyticsLogout";

import AppLoginSuccess from '../components/LoginComponent/LoginSuccess'
import Callback from "../components/Callback/Callback";
import ReactCharts from '../components/Charts'

import Alerts from '../components/Alerts';

const handleAuthentication = ({ location }) => {
   if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleAuthentication();
   }
}

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) =>
   <Route
      {...rest}
      render={props =>
         authUser
            ? <Component {...props} />
            : <Redirect
               to={{
                  pathname: '/launchscreen',
                  state: { from: props.location }
               }}
            />}
   />;

class App extends Component {
   state = {
      userAuthenticated: undefined,
      hasError:false,
   }

   static getDerivedStateFromError(error) {
      return { hasError: true };
   }

   componentDidCatch(error, info) {
      console.log("%cThis is something that's need to be fixed","color:blue");  
      console.log({ error, info });
   }
   
   componentDidMount(){
      this.removeWidgetConfigration();
      this.props.handleUserWorkflowPanel(false, null)
      requireSignIn().then(value => {
         this.setState({userAuthenticated: "AUTHENTICATED"})
      }).catch(e => {
         this.setState({userAuthenticated: 'AUTH_REQUIRED'})
      })
      window.onscroll = function() {scrollFunctionByClass("rct-scroll")};
      this.props.clearActivityDataOnClose()
      this.props.hideStartWorkflowPanel()
   }

   removeWidgetConfigration = () => {
      localStorage.removeItem("widgetApp");
   }

   render() {
      const { location, match, user, userPrivacyPolicyData } = this.props;
      const {userAuthenticated} = this.state;
      if(this.state.hasError) {
          return (<UIFallbackScreen />)
      }
      
      if (userAuthenticated) {
         if (location.pathname === '/') {
            if (user && userPrivacyPolicyData && userPrivacyPolicyData.status) {
               return (<Redirect to={'/app/lsdashboard'} />);
            } else {
               return (<Redirect to={'/launchscreen'} />);
            }
         }
      } else {
         return(
            <RctSectionLoader/>
         );
      }
      return (
         <RctThemeProvider>
            <NotificationContainer />
            <InitialPath
               path={`${match.url}app`}
               authUser={user && userPrivacyPolicyData && userPrivacyPolicyData.status}
               component={RctDefaultLayout}
            />
           <Route path="/privacy-policy" component={Alerts} />
            <Route path="/launchscreen" component={AppInitialLaunchScreen}/>
            <Route path="/idp-initiated-sso" component={IDPInitiatedSSOComponent}/>
            <Route path="/auth0loginsuccess" component={AppSuccess}/>
            <Route path="/auth0login" component={AsyncAuthoLogin}/>
            <Route path="/signin" component={AsyncAuth0RedirectSignIn}/>
            <Route path="/activity/preview" component={AsyncMyActivityPreview}/>
            <Route path="/react/charts" component={ReactCharts}/>           
            <Route path="/signinEmail" component={Signin}/>
            <Route path="/loginsuccess" component={AppLoginSuccess}/>
            <Route path="/signinwithtoken" component={AppSignInWithToken} />
            <Route path="/signuplifesherpa" component={AppSignUpLifesherpa}/>
            <Route path="/welcome" component={AppConfirmation} />
            <Route path="/logout" component={Auth0logout} />
            <Route path="/lsdashboard" component={AsyncLifesherpaDashboardComponent} />
            <Route path="/resources" component={AsyncResourcesPage} />
            <Route path="/usercharts" component={AsyncUserModuleCharts} />
            <Route path="/configurator" component={AsyncLifesherpaiFrame} />
            <Route path="/lms" component={AsyncLifesherpaiFrame} />
            <Route path="/lms_course" component={AsyncLifesherpaiFrame} />
            <Route path="/zoho_analytics" component={AsyncAnalyticsiFrame}/>
            <Route path="/success" component={SuccessComponent}/>
            <Route path="/analyticslogout" component={AnalyticsLogoutScreen}/>
            <Route path="/lifesherpa/videocall" component={AsyncLifesherpaVideoCall}/>
            <Route path="/callback" render={(props) => {
               handleAuthentication(props);
               return <Callback {...props} />
            }} />
          
         </RctThemeProvider>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user, userPrivacyPolicyData } = authUser;
   return { user, userPrivacyPolicyData };
};

export default connect(mapStateToProps, {
   clearActivityDataOnClose,
   hideStartWorkflowPanel,
   handleUserWorkflowPanel
})(App);