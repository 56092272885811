/**
 * App Header
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
import SetCurrentStatus from '../SetCurrentStatus';
// actions
import {getLSThemeLogo,setFormStatusData, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusById, realTimeStatus, getWorkspaceDetails, isWorkspaceAdminStatus, getUserChangePassword, getPrivacyPlocy } from '../../actions';
import AppConfig from '../../constants/AppConfig';
import SherpaUserProfile from './SherpaUserProfile'
import {auth, database} from '../../firebase'
import { ref, onValue} from "firebase/database"
import {onIdTokenChanged} from 'firebase/auth'

class Header extends Component {

	state = {
		customizer: false,
		isMobileSearchFormVisible: false,
		showStatus: false,
		status2: ["Red", "Yellow", "Green"],
	
	}
	componentDidMount() {
		let idToken = localStorage.getItem('idToken')
		//let uid=localStorage.getItem('uid')
		let uid = localStorage.getItem('auth_uid')
		let organization = localStorage.getItem('orgId')
		this.props.getWorkspaceDetails(organization);
		if(this.props.profile && this.props.profile.email) {
			this.props.getUserChangePassword(this.props.profile.email);
		}
		
		let data = this.props.privateActivities
		//this.props.getLoading()
		console.log("%c[Header]%c Rendered LS-Portal Header","color:black","color:green");
		onValue(ref(database, `Realtime/${organization}/Users/${uid}/lastUserStatusSets`), (snapshot) => {
			const realtimeData = snapshot.val();
			this.props.realTimeStatus(realtimeData);
			this.setState({ ...this.state })
		})
		if (!idToken) {
			this.props.history.push('/launchscreen')
		} else {
			this.props.getUserStatusById(uid,idToken);
			// this.props.getPrivacyPlocy({organization});
		}
		this.props.isWorkspaceAdminStatus(uid)
		
		onIdTokenChanged(auth, (userAuth) => {
			if(userAuth == null) {
				this.props.history.push('/launchscreen');
			} 
		});
	}
	// function to change the state of collapsed sidebar
	onToggleNavCollapsed = (event) => {
		const val = !this.props.navCollapsed;
		this.props.collapsedSidebarAction(val);
	}

	// open dashboard overlay
	openDashboardOverlay(e) {
		var el = document.getElementsByClassName('dashboard-overlay')[0];
		el.classList.toggle("d-none");
		el.classList.toggle("show");
		if (el.classList.contains('show')) {
			document.body.style.overflow = "hidden";
		}
		else {
			document.body.style.overflow = "";
		}
		e.preventDefault();
	}

	// close dashboard overlay
	closeDashboardOverlay() {
		var e = document.getElementsByClassName('dashboard-overlay')[0];
		e.classList.remove('show');
		e.classList.add('d-none');
		document.body.style.overflow = "";
	}

	// mobile search form
	openMobileSearchForm() {
		this.setState({ isMobileSearchFormVisible: true });
	}
	handleStatus = () => {
		let idToken = localStorage.getItem('idToken');
		if (!idToken) this.props.history.push('/launchscreen');
		let uid = localStorage.getItem('auth_uid');
		console.log(" Clicked on updated status button");	
		this.props.getUserStatusById(uid, idToken);
		this.setState({ showStatus: !this.state.showStatus})
	}
	updateStatus = (statusList) => {
		console.log(" Clicked on submit set status form");	
		let data = this.props.Status.status
		data && data.sections ?
			Object.keys(data.sections).map((obj, key) => {
				data.sections[obj] = statusList[key]
			}) : ''
		let uid = localStorage.getItem('auth_uid')
		this.props.updateStatus(uid, data)
		this.setState({ showStatus: !this.state.showStatus})
	}

	handelStatusEditClose = () => {
		this.setState({ showStatus: false});

	}
	render() {
		const { isMobileSearchFormVisible } = this.state;
		let { statusData, themeData, showStatus } = this.props
		let {themeLoading} = this.props;
		let activeThemeId = localStorage.getItem('activeTheme');
		let themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
		let localLogo = activeThemeId == 9 || activeThemeId == 8 || activeThemeId == 5 || activeThemeId == 10 ?  AppConfig.appLogoWhite : AppConfig.appLogo;
        let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
		return (
			<AppBar data-testid="landing-page-header" position="static" id="ls-header-appbar" className="rct-header ls-portal-header">
				<Toolbar className="pl-0 pr-0">
					<div className="row header-content-box w-100 align-items-center">
						<div className="">
							<div className="d-inline-flex align-items-center">
								{activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
									<Link to="/" className="logo-mini lifesherpa-logo">
										{!themeLoading && 
										<img data-testid="ls-logo"  src={themeLogo || localLogo} alt="site logo" width="auto" height="auto" className='auto-resize-img' />
										}
									</Link>
								: !themeLoading &&
								 <Link to="/" className={`lifesherpa-theme-logo text-center ${themeData && (themeData.logoBackgroundColor) ? "header-theme-logo-background" : ""}`} >
									<img data-testid="ls-logo"  src={ themeLogo || localLogo} alt="site logo" width="auto" height="auto" className='auto-resize-img'/>
									<div className="d-flex  justify-content-center lifesherpa-poweredby-logo">
									{themeData && themeData.loginLifeSherpaImage &&
										<img src={themeData.loginLifeSherpaImage} alt="session-logo" width="auto" height="30" />
									}
									</div>
								</Link>
								}					
							</div>
						</div>
						<div className="ml-auto text-right align-items-center pr-0">
							<ul className="list-inline mb-0">
							    { showStatus ? statusData && statusData.length > 0 ? statusData.map((status , key) => {
									let {direction, value, color , score } = status;
										return (
											<li data-testid={`client-status-list${key}`}  key={key} className={"w-auto list-inline-item text-white p-2 px-3 "+(key < 2 ? "d-inline-block": key == 2 ? "d-none d-md-inline-block":"d-none d-xl-inline-block")} style={{ borderRadius: 20 + "px", background: color ? color : '', color: color === '#ffff00' ? '#677080' : 'white' }}>
												<div className="d-flex">
													{direction && direction === 'positive' ?
														<div className='mr-1'>
															<i className="zmdi zmdi-trending-up zmdi-hc-lg"></i>
														</div>
														: direction && direction === 'neutral' ?
															<div className='mr-1'>
																<i className="zmdi zmdi-long-arrow-right zmdi-hc-lg"></i>
															</div>
															: direction && direction === 'negative' &&
															<div className='mr-1'>
																<i className="zmdi zmdi-trending-down zmdi-hc-lg"></i>
															</div>
													}
													{value}
												</div>
											</li>
										)
									}) : 
										<li data-testid={`client-status-list${0}`}  key={0} className={"w-auto list-inline-item p-2 px-3"} style={{ borderRadius: 20 + "px", background: themeData && themeData.tabBarButtonsColor ? themeData.tabBarButtonsColor : '#000', color: themeData && themeData.tabBarColor ? themeData.tabBarColor : "white" }}>
											No Status
										</li> 
								: <></>
								}
								<li  className="list-inline-item setting-icon">
								{showStatus &&
									<IconButton className="edit-status-button" title="Status" aria-label="settings" onClick={() => this.handleStatus()} >
										<BorderColorRoundedIcon data-testid="client-status-edit-button" />
									</IconButton>
								}
								</li>
								<li data-testid="client-profile-image-and-dropdown-icon" title="Profile" className="list-inline-item">
									<SherpaUserProfile />
								</li>
							</ul>
						</div>
					</div>
				</Toolbar>
			
				{this.state.showStatus &&
					<SetCurrentStatus
						showStatus={this.state.showStatus}
						profile={this.props.profile}
						handelStatusEditClose={this.handelStatusEditClose}
						updateStatus={this.updateStatus}
						/>}
			</AppBar>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings, authUser, Status, chatAppReducer }) => {
	const { selectedRole, profileImage, clientStatus, selectedMemberId } = chatAppReducer
	let statusList = []
	Status.status && Status.status.sections ?
		Object.keys(Status.status.sections).map((obj, key) => {
			statusList.push(Status.status.sections[obj])
		}) : '';
	let {status,loading} = Status;
	const {statusData, profile, credit,themeLoading , themeData, usersInfo} = authUser;
	const { screenThemeClass , themeLogo } = settings; 
	const {showStatus} = usersInfo ? usersInfo : {showStatus: false}
	return {themeLoading,screenThemeClass, loading,statusData, settings, status, profile, credit, statusList, Status, selectedRole, profileImage, clientStatus, selectedMemberId, themeLogo, themeData, showStatus };
};

export default withRouter(connect(mapStateToProps, {
	getLSThemeLogo, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusById, realTimeStatus,setFormStatusData, getWorkspaceDetails, isWorkspaceAdminStatus, getUserChangePassword, getPrivacyPlocy
})(Header));
