import React, { Component } from 'react';
import { connect } from 'react-redux';
import { } from 'Actions';
import Avatar from '@mui/material/Avatar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PeopleIcon from '@mui/icons-material/People';

class InProgressGroup extends Component {
    state = { 

     }
    componentDidMount = async () =>  {

    }

    handleOpenVideoGroupRoom=(roomname)=>{
        this.props.handleOpenVideoGroupRoom(roomname);
    }
    render() { 
        let {inProgressGroup} = this.props;
      // console.log(" lastMessage --->",lastMessage);
        return ( 
            <React.Fragment>
                <li className="d-flex align-items-center contacts-list-item list-card mb-1 list-item p-2 my-2 border-bottom">
                    <div className="avatar-wrap w-15 align-self-center" >
                    {/*<Avatar className="align-self-center">{activity.title.charAt(0)}</Avatar>*/}
                     <Avatar className="align-self-center user-later-avatar"> <PeopleIcon className="rounded-circle align-self-center group-icon"/></Avatar>
                    </div>
                    <div className="actvity-routine mx-2 ml-3">
                        <h4 >{inProgressGroup.groupName}</h4>
                        {inProgressGroup.hostName ? <div className="status-text">{`Hosted by ${inProgressGroup.hostName}`}</div> : ""}
                        {inProgressGroup.totalMembers ? <div className="status-text members-count-details">{`${inProgressGroup.totalMembers} members`}</div> : ""}
                    </div>
                    <Button className=" ml-auto mr-3 join-group-call-button" onClick={()=>this.handleOpenVideoGroupRoom(inProgressGroup.roomname)}>Join Call</Button>
                </li>
            </React.Fragment>
            );
    }
}
const mapStateToProps = ({Contacts}) => {
    let {contactList, loading, chatToken} = Contacts;
	return {contactList,loading, chatToken};
};

export default connect(mapStateToProps, {})(InProgressGroup);