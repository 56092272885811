/**
 * Chat App Reducers
 */
import update from 'react-addons-update';

// actions types
import {
    CHAT_WITH_SELECTED_USER,
    SEND_MESSAGE_TO_USER,
    UPDATE_USERS_SEARCH,
    SEARCH_USERS,
    GET_RECENT_CHAT_USERS,
    SET_CHAT_OPEN,
    SET_ACTIVITIES_OPEN,
    SET_OPEN_CLIENT_DETAIL,
    SET_USER_SELECTED,
    SET_SELECTED_USER_ID,
    CHANGE_ROLE,
    GET_CLIENT_STATUS,
    SHOW_CHAT_PANEL_FOR_SELCTED_USER,
    HIDE_CHAT_PANEL,
    ADD_SELECTED_CHAT_USER,
    REMOVE_SELECTED_CHAT_USER,
    SELECTED_CHAT_USERS_UPDATE,
    SET_SELECTED_USER,
    REMOVE_SELECTED_USER,
    SET_SELECTED_USER_INFO,
    RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST,
    HANDLE_SHOW_HELP_PANEL
} from '../actions/types';

const INITIAL_STATE = {
    admin_photo_url: require('Assets/avatars/user-15.jpg'),
    recentChatUsers: null,
    allRecentChatUsers: null,
    allChatUsers: null,
    selectedUser: null,
    selectedMember: null,
    searchUsers: '',
    isChatOpen: false,
    isActivitiesOpen: false,
    selectedMemberId: localStorage.getItem('uid'),
    displayName: '',
    unconfirmAchievements: 0,
    profileImage: '',
    status: '',
    clientStatus:'',
    checkIn: '',
    routineTitle: '',
    selectedRole:'admin',
    email: null,
    openChatPanel:false,
    selectedChatUsers:[],
    userUpdatedTolist:0,
    selectedUserInfo: null,
    showHelpPanel: false,
    currentStep: null

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // get recent chat user
        case GET_RECENT_CHAT_USERS:
            return { ...state, recentChatUsers: null };
        // get recent chat user
        case SET_CHAT_OPEN:
            return { ...state, isChatOpen: action.payload, isActivitiesOpen: false };
        // get recent chat user
        case SET_SELECTED_USER_ID:
            return { ...state, selectedMemberId: action.payload/*,selectedRole:'client'*/};
        // get recent chat user
        case SET_USER_SELECTED:
            let displayName = ''
            let unconfirmAchievements = ''
            let profileImage = ''
            let status = ''
            let checkIn = ''
            let email = null
            if (action.payload && action.payload !== '' && action.payload.user) {
                displayName = action.payload.user && action.payload.user.displayName ? action.payload.user.displayName :action.payload.user.firstName? `${action.payload.user.firstName} ${action.payload.user.lastName}`:'Display Name'
                unconfirmAchievements = action.payload.unconfirmedAchievements && Object.keys(action.payload.unconfirmedAchievements).length
                profileImage = action.payload.user && action.payload.user.profileImageURL ?  action.payload.user.profileImageURL :  require('Assets/avatars/user-15.jpg')
                status = action.payload.lastUserStatus && action.payload.lastUserStatus && action.payload.lastUserStatus.status
                checkIn = action.payload.lastCheckIn && action.payload.lastCheckIn && action.payload.lastCheckIn.checkInStatus
                email = action.payload.user && action.payload.user.email ? action.payload.user.email : null
            }
            return { ...state, selectedMember: action.payload, displayName, unconfirmAchievements, profileImage,status, checkIn, email };
        case SET_SELECTED_USER_INFO: 
            return { ...state, selectedUserInfo: action.payload };
        case GET_CLIENT_STATUS:
            return {...state,clientStatus:action.payload}
            // get recent chat user
        case SET_ACTIVITIES_OPEN:
            return { ...state, isChatOpen: false, isActivitiesOpen: action.payload};
         // get recent chat user
         case SET_OPEN_CLIENT_DETAIL:
            return { ...state, isChatOpen: false, isActivitiesOpen: false };
        // chat with selected user
        case CHAT_WITH_SELECTED_USER:
            let indexOfSelectedUser;
            indexOfSelectedUser = state.recentChatUsers.indexOf(action.payload);
            return update(state, {
                selectedUser: { $set: action.payload },
                recentChatUsers: {
                    [indexOfSelectedUser]: {
                        isSelectedChat: { $set: true },
                        new_message_count: { $set: 0 }
                    }
                }
            });

        // send message to user
        case SEND_MESSAGE_TO_USER:
            let adminReplyData = {
                isAdmin: action.payload.isAdmin,
                message: action.payload.message,
                sent: action.payload.time
            };
            let pos = state.selectedUser.previousChats.length;
            return update(state, {
                selectedUser: { previousChats: { $splice: [[pos, 0, adminReplyData]] } }
            })

        // update search
        case UPDATE_USERS_SEARCH:
            return { ...state, searchUsers: action.payload };

        // search user
        case SEARCH_USERS:
            if (action.payload === '') {
                return { ...state, recentChatUsers: state.allChatUsers };
            } else {
                const searchUsers = state.allRecentChatUsers.filter((user) =>
                    user.first_name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
                return { ...state, recentChatUsers: searchUsers }
            }
        case CHANGE_ROLE:
            return {...state,selectedRole:action.payload.role,selectedMemberId:action.payload.id}
        case ADD_SELECTED_CHAT_USER:
            return {...state, selectedChatUsers:action.payload}
        case REMOVE_SELECTED_CHAT_USER:
            return {...state, selectedChatUsers:action.payload}
        case SHOW_CHAT_PANEL_FOR_SELCTED_USER:
            return {...state,selectedUser:action.payload,openChatPanel:true}
        case HIDE_CHAT_PANEL:
            return {...state,openChatPanel:false, selectedUser:null}
        case SET_SELECTED_USER: 
            return {...state, selectedUser:action.payload}
        case REMOVE_SELECTED_USER: 
            return {...state, selectedUser:null}
        case SELECTED_CHAT_USERS_UPDATE:
            return {...state,userUpdatedTolist:action.payload}
        case HANDLE_SHOW_HELP_PANEL:
            return {...state, showHelpPanel: action.payload.show, currentStep: action.payload.currentStep }
        case RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST:
            return INITIAL_STATE
            
        default: return { ...state };
    }
}
