/**
 * Chat App Reducers
 */

// actions types
import {
    GET_RECENT_GROUP,
    GET_SELECTED_GROUP,
    UPDATE_GROUP_SEARCH,
    SEARCH_GROUP,
    SET_SELECTED_GROUP,
    SET_SUCCESS_SELECTED_GROUP,
    GET_GROUP_MEMBERS,
    GET_SUCCESS_GROUP_MEMBERS,
    GET_GROUP_LIST,
    GET_SUCCESS_GROUP_LIST,
    SHOW_LOADING_INDICATOR_GROUP_LIST,
    HIDE_LOADING_INDICATOR_GROUP_LIST,
    GET_GROUP_MEMBERS_FROM_API,
    GET_GROUP_MEMBERS_FROM_API_SUCCESS,
    RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST
} from '../actions/types';


const INITIAL_STATE = {
    groups: null,
    selectedGroup: null,
    groupMembers: null,
    loading: false,
    groupMembersDetails: null

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // get recent chat user
        case GET_RECENT_GROUP:
            return { ...state, groups };
        // get recent chat user
       
        case GET_GROUP_MEMBERS:
            return { ...state, loading: true };
        case GET_SUCCESS_GROUP_MEMBERS:
            let groupMembers = state.groupMembers || {};
            const orgRealTimeGroupMembers = action.payload.members;
            const groupId = action.payload.groupId;
            if (orgRealTimeGroupMembers) {
                for(const memberId in orgRealTimeGroupMembers) {
                    if(!groupMembers[groupId]) {
                        groupMembers[groupId] = {};
                    }
                    groupMembers[groupId][memberId] = orgRealTimeGroupMembers[memberId]
                }
            } 
            return { ...state, groupMembers, loading: false };
        case GET_SELECTED_GROUP:
            return { ...state, selectedGroup: action.payload };
        case SET_SELECTED_GROUP:
            return { ...state, loading: true,  groupMembersDetails: null, groupMembers: null};
        case SET_SUCCESS_SELECTED_GROUP:
            return { ...state, loading: false };
        // get recent chat user
        case GET_GROUP_LIST:
            return { ...state, loading: true };
        // get recent chat user
        case GET_SUCCESS_GROUP_LIST:
            let groups = state.groups
            const orgGroups = action.payload
            if (groups && orgGroups) {
                orgGroups.forEach(org_group => {
                    const index = groups.findIndex(gp_ele => gp_ele.id === org_group.id)
                    if (index === -1) {
                        groups.push(org_group)
                    } 
                });
            } else {
                groups = orgGroups
            }
            return { ...state, groups, loading: false };
        // get groups members user details
        case GET_GROUP_MEMBERS_FROM_API:
            return { ...state, loading: true };
        // get recent chat user
        case GET_GROUP_MEMBERS_FROM_API_SUCCESS:
            return { ...state, groupMembersDetails: action.payload, loading: false };

        // show loading 
        case SHOW_LOADING_INDICATOR_GROUP_LIST:
            return { ...state, loading: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_GROUP_LIST:
            return { ...state, loading: false };
        case RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST:
            return INITIAL_STATE
        default: return { ...state };
    }
}
