import React, { Component } from 'react';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded'
import LifeSherpaLoading from '../../LifeSherpaLoading';

class PreviewLinksIframe extends Component {
    state = {
       loading:true
    }
    hideSpinner = () => {
        console.log("%c Configurator loaded successfully............","color:green");
        this.setState({ ...this.state, loading: false });
      }
    render() {
        let {loading} = this.state;
        let { url} = this.props;
        return (
             <div  className="row">
                  <div data-testid="back-button" title="Back To Activity Preview" className="backbuttonForPreviewLinkIframe" onClick={() => this.props.goBackToActivityPreviw()}>
                     <ArrowBackRounded />
                </div>
             <div className="w-100 col-12">
                <LifeSherpaLoading loading={loading} />
                <iframe    
                    src={url}  
                    height="600"
                    id="myId"
                        width="100%"
                    className="activity-preview-iframe"
                    display="initial"
                    position="relative"
                    onLoad={this.hideSpinner}>
                </iframe>
             </div>
        </div> 
                   
        );
    }
}


export default PreviewLinksIframe;