import React, { Component } from 'react';
// import { Polar } from 'react-chartjs-2';
import ChartConfig from '../../constants/chart-config';

import {
	Chart as ChartJS,
	RadialLinearScale,
	ArcElement,
	Tooltip,
	Legend,
  } from 'chart.js';
import {
	PolarArea
} from 'react-chartjs-2';

ChartJS.register(
	RadialLinearScale,
	ArcElement,
	Tooltip,
	Legend,
);



const options = {
	responsive: true,
	maintainAspectRatio: false,
	redraw: true,
	legend: {
		labels: {
			fontColor: ChartConfig.legendFontColor
		}
	}
};

export default class PolarChart extends Component {

	componentDidMount() {
		this.props.getChartData(this.props.chartId)
	}

	render() {
		return (
			<article className="canvas-container">
				<PolarArea data={this.props.data} options={options} />
			</article>
		);
	}
}
