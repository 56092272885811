/**
 * User List Item
 */
import React from 'react';
import {
	Label,
} from 'reactstrap';
import Divider from '@mui/material/Divider';
import CustomInput from '../CustomInput'


const SingleSelectionItem = ({ obj, updateform, section, showError, }) => (
    <div data-testid="single-selection-item-conponent" key={section}>
         {obj.heading ? <Label className="form-label">{`${obj.heading}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></Label> : <Divider className="my-2"/>}
        <Divider/>
        {obj.itemsList&&obj.itemsList.map((label, key) => (
           <div className="preveiw-options" key={key}> <CustomInput key={key} className="bg-white" onChange={(e) => updateform(e, obj, section)} checked={label.value} color="primary"  type="radio" id={`radio-${obj.heading}-${key}-${section}`} name={obj.heading} label={label.name} /> </div>
        ))}
        {showError && obj.isMandatory && obj.itemsList && obj.itemsList.findIndex(item => item.value) == -1 && <div className="input-options-error">{`${obj.heading} section is required`}</div>}
       {/*  <Divider className="mb-2"/>*/}
  </div>
);

export default SingleSelectionItem;
