/**
 * Sign Up With Firebase
 */
import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import LifeSherpaLoading from '../components/LifeSherpaLoading';
// import PhoneInput from 'react-phone-input-2'
import QueueAnim from 'rc-queue-anim';
import HeaderComponent from '../components/Header/HeaderComponent'
import config from '../config/config'

// app config
import AppConfig from '../constants/AppConfig';

// redux action
import {
   signupUserInFirebase,
   createUser,
   clearCreateUserFailedError
   // getGeoLocation,
} from '../actions';

class LifesherpaSignup extends Component {

   state = {
      name: '',
      firstname: '',
      lastname: '',
      phone: null,
      email: '',
      password: '',
      verifyPassword: '',
      firstnameEmpty: null,
      lastnameEmpty: null,
      emailEmpty: null,
      passwordEmpty: null,
      verifyPasswordEmpty: null,
      inValidEmail: null,
      invalidPassword: null,
      invalidPhone: null,
      open: false,
      anchorEl: null,
      message: null,
      username: '',
      usernameEmpty: null,
      invalidUsername: null
   }

   // componentDidMount(){
   //    this.props.getGeoLocation()
   // }
   componentDidMount() {
      // if ("geolocation" in navigator) {
      //   console.log("Available");
      //   let location = null
      //   let this2 = this
      //   navigator.geolocation.getCurrentPosition(function(position) {
      //    console.log("Latitude is :", position.coords.latitude);
      //    console.log("Longitude is :", position.coords.longitude);
      //    location = {lat: position.coords.latitude, lng: position.coords.longitude}
      //    console.log(location);
      //    this2.props.getGeoLocation(location)
      //  }, failure => {
      //    if (failure.message.startsWith("Only secure origins are allowed")) {
      //      // Secure Origin issue.
      //      console.log("Only secure origins are allowed");
      //    }
      // })
      // }
      const { createUserFailed} = this.props;
      if(createUserFailed) {
         this.props.clearCreateUserFailedError();
      }
    }

   handleClick (event) {
      this.setState({anchorEl: event.currentTarget, open: !this.state.open})
    };
  
   handleClose () {
      this.setState({open: !this.state.open})
    };

   //validating the email address
   validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    //validating country code
   validatePhone(phone) {
      if (phone !== '') {
         return phone.startsWith('+')
      } else {
         return true
      }
   }

 checkPassword(str) {
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
      return re.test(str);
   }

    //validating the password
   validatePassword(password) {
      // let valid = password.length > 5 ? true : false
      // return valid;
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
      return re.test(password);
    }

	/**
	 * On User Signup
	 */
   onSubmit(e) {
      e.preventDefault();
      const { firstname, lastname, phone, email, password, verifyPassword, firstnameEmpty, lastnameEmpty, emailEmpty, passwordEmpty, verifyPasswordEmpty, inValidEmail, invalidPassword, username, usernameEmpty } = this.state;
      // console.log('inviteCode', this.props.userOrgFromToken.inviteCode)
      const userDataWithPhone = {
         "firstName": firstname,
         "lastName": lastname,
         "email": email,
         "phoneNumber": phone ? `+${phone}` : null,
         "inviteCode": this.props.inviteCode ? this.props.inviteCode : this.props.userOrgFromToken.inviteCode,
         "password": password,
         "username": username
      }

      const userDataWithoutPhone = {
         "firstName": firstname,
         "lastName": lastname,
         "email": email,
         "inviteCode": this.props.inviteCode ? this.props.inviteCode : this.props.userOrgFromToken.inviteCode,
         "password": password,
         "username": username
      }

      const userData = phone ? userDataWithPhone : userDataWithoutPhone

    if (firstname === ''){
         this.setState({firstnameEmpty: true})
    } else if (lastname === ''){
      this.setState({lastnameEmpty: true})
    } else if (username === ''){
      this.setState({usernameEmpty: true})
    } else if (username.length < 3 || username.length > 50){
      this.setState({invalidUsername: true})
    } else if (email === ''){
      this.setState({emailEmpty: true})
    } else if (!(this.validateEmail(email))){
      this.setState({inValidEmail: true})
    } /*else if (phone && phone !== '' && !this.validatePhone(phone)){
      this.setState({invalidPhone: true})
    }*/ else if (password === ''){
      this.setState({passwordEmpty: true})
    } else if (!this.validatePassword(password)){
      this.setState({invalidPassword: true})
    } else if (verifyPassword === ''){
      this.setState({verifyPasswordEmpty: true})
    } else if (verifyPassword !== password){
    } else {
      // this.props.history.push('/welcome');
      this.props.createUser(userData, this.props.history);

    }
      
   }

   render() {
      const { message, firstname, lastname, phone, email, password, verifyPassword, firstnameEmpty, lastnameEmpty, phoneEmpty, emailEmpty, passwordEmpty, verifyPasswordEmpty, inValidEmail, invalidPassword, open, anchorEl, invalidPhone, usernameEmpty, username, invalidUsername } = this.state;
      const { loading ,createUserFailed} = this.props;
      const id = open ? 'simple-popover' : undefined;
      let phoneAlreadyExist = false
      let emailAlreadyExist = false
      let showServerError = false
      let phoneNumberInvalid = false
      let usernameExist = false
      if (this.props.user && this.props.user.code) {
         if (typeof(this.props.user.code) !== 'number' && this.props.user.code.includes('phone') && (this.props.userData && this.props.userData.phoneNumber === `+${phone}`)){
            phoneAlreadyExist = true
         } if (typeof(this.props.user.code) === 'number' && this.props.user.code === 400 && this.props.user.detail[0].param === 'phoneNumber' && (this.props.userData && this.props.userData.phoneNumber === `+${phone}`)){
            phoneNumberInvalid = true
         } else if(typeof(this.props.user.code) !== 'number' && this.props.user.code.includes('username') && (this.props.userData && this.props.userData.username === username)){
            usernameExist = true
         } else if(typeof(this.props.user.code) !== 'number' && this.props.user.code === 'E1016' && (this.props.userData && this.props.userData.username === username)){
            usernameExist = true
         } else if(typeof(this.props.user.code) !== 'number' && this.props.user.code.includes('email') && (this.props.userData && this.props.userData.email === email)){
            emailAlreadyExist = true
         }/* else if(typeof(this.props.user.code) !== 'number' && this.props.user.code === 'E1016' && (this.props.userData && this.props.userData.email === email)){
            emailAlreadyExist = true
         }*/ else {
            if (this.props.userData && (this.props.userData.email === email) && (this.props.userData.firstName === firstname) && (this.props.userData.lastName === lastname) && (this.props.userData.password === password)){
               showServerError = true
            }
         }
      }
     // console.log("createUserFailed",createUserFailed,showRegistrationFailedError);
   //  console.log("userOrgFromToken-->",this.props.userOrgFromToken);
     const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 

      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            <LifeSherpaLoading loading={loading} />
            </div>
            <div className="rct-session-wrapper">
               <AppBar position="static" className="session-header">
                     <Toolbar>
                        <div className="container">
                           <div className="d-flex justify-content-between">
                              <div className="session-logo">
                                 <Link to="/">
                                    <img src={applogo} alt="session-logo" className="img-fluid" width="220" height="80" />
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </Toolbar>
                  </AppBar>
               <div className="session-inner-wrapper" data-testid="signup-lifesherpa-registration">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className={`session-body text-center ${config.headerTextcolor}`}>
                              <div className="session-head mb-15">
                                 <h2>Create Your Account</h2>
                              </div>
                              <Form onKeyPress={e => {
                                 if (e.key === 'Enter') this.onSubmit(e);
                                 if (createUserFailed) this.props.clearCreateUserFailedError();
                               }}>
                                <FormGroup className="has-wrapper">
                                    <Input
                                       type="text"
                                       value={firstname}
                                       name="user-firstname"
                                       id="user-firstname"
                                       style={{fontSize:18+"px"}}
                                       className="has-input input-lg text-center"
                                       placeholder="First Name"
                                       data-testid="first-name-text-field"
                                       // onKeyPress={e => {
                                       //    if (e.key === 'Enter') this.onSubmit(e);
                                       //  }}
                                       invalid={firstnameEmpty ? true : false}
                                       onChange={(e) => this.setState({ firstname: e.target.value, firstnameEmpty: e.target.value !== '' ? false : true })}
                                    />
                                    {firstnameEmpty && <FormFeedback data-testid="first-name-text-field-error" style={{fontSize: 18+"px"}}>Please enter First Name. It is a required field</FormFeedback>}
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="text"
                                       value={lastname}
                                       name="user-lastname"
                                       id="user-lastname"
                                       style={{fontSize:18+"px"}}
                                       className="has-input input-lg text-center"
                                       placeholder="Last Name"
                                       data-testid="last-name-text-field"
                                       // onKeyPress={e => {
                                       //    if (e.key === 'Enter') this.onSubmit(e);
                                       //  }}
                                       invalid={lastnameEmpty ? true : false}
                                       onChange={(e) => this.setState({ lastname: e.target.value, lastnameEmpty: e.target.value !== '' ? false : true })}
                                    />
                                    {lastnameEmpty && <FormFeedback data-testid="last-name-text-field-error" style={{fontSize: 18+"px"}}>Please enter Last Name. It is a required field</FormFeedback>}
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="text"
                                       value={username}
                                       name="username"
                                       id="username"
                                       style={{fontSize:18+"px"}}
                                       className="has-input input-lg text-center"
                                       placeholder="Username"
                                       data-testid="username-text-field"
                                       invalid={(usernameEmpty ? true : false) || usernameExist}
                                       onChange={(e) => this.setState({ username: e.target.value, usernameEmpty: e.target.value === '', invalidUsername: (e.target.value.length < 3 || e.target.value.length > 50)})}
                                    />
                                    {usernameEmpty ? <FormFeedback data-testid="username-empty-error" style={{fontSize: 18+"px"}}>Please enter username. It is a required field</FormFeedback>
                                       : invalidUsername ?
                                          <FormFeedback className="d-block" data-testid="invalid-username-error" style={{fontSize: 18+"px"}}>Please enter a valid username of length between 3 to 50 characters.</FormFeedback>
                                       :  usernameExist &&
                                       <FormFeedback className="d-block" style={{fontSize: 18+"px"}}>Either username or email is already used by another user.</FormFeedback>}
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="email"
                                       value={email}
                                       autoCapitalize="none"
                                       name="user-mail"
                                       id="user-mail"
                                       style={{fontSize:18+"px"}}
                                       className="has-input input-lg text-center"
                                       placeholder="Email"
                                       data-testid="email-text-field"
                                       // onKeyPress={e => {
                                       //    if (e.key === 'Enter') this.onSubmit(e);
                                       //  }}
                                       invalid={(emailEmpty ? true : false) || emailAlreadyExist}
                                       onChange={(e) => this.setState({ email: e.target.value, emailEmpty: e.target.value !== '' ? false : true, inValidEmail: !this.validateEmail(e.target.value) })}
                                    />
                                    {emailEmpty ? 
                                       <FormFeedback data-testid="empty-email-error" style={{fontSize: 18+"px"}}>Please enter a valid email address. It is a required field.</FormFeedback>
                                       : inValidEmail ?
                                       <FormFeedback data-testid="invalid-email-error" className="d-block" style={{fontSize: 18+"px"}}>Invalid email address format.  Please provide a valid email address</FormFeedback>
                                       : emailAlreadyExist &&
                                       <FormFeedback className="d-block" style={{fontSize: 18+"px"}}>This email is already used by another user.</FormFeedback>
                                    }
                                 </FormGroup>
                                 {/* <FormGroup className="has-wrapper">
                                    <PhoneInput
                                       country={this.props.geoData ? this.props.geoData['country_code'].toLowerCase() : 'au'}
                                       placeholder="+61 (12) 3456 7890"
                                       value={this.state.phone}
                                       enableSearch={true}
                                       onChange={phone => this.setState({phone})}
                                       // onKeyPress={e => {
                                       //    if (e.key === 'Enter') this.onSubmit(e);
                                       //  }}
                                    />
                                    {phoneAlreadyExist ?
                                        <FormFeedback className="d-block" style={{fontSize: 18+"px"}}>{this.props.user.message}</FormFeedback>
                                        : phoneNumberInvalid &&
                                        <FormFeedback className="d-block" style={{fontSize: 18+"px"}}>Phone number provided is not a valid phone number.  Please enter valid phone number.</FormFeedback>
                                       }
                                 </FormGroup> */}
                                 <FormGroup className="has-wrapper">
                                    <h2 className="mb-0">Supply a password meeting this</h2>
                                    <p onClick={this.handleClick.bind(this)} style={{fontSize: 18+"px",color:"gray"}}><a>criteria</a></p>
                                    <Popover
                                       id={id}
                                       open={open}
                                       anchorEl={anchorEl}
                                       onClose={this.handleClose.bind(this)}
                                       anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'center',
                                       }}
                                       transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'center',
                                       }}
                                    >
                                       <Typography className="p-3">The password should be a minimum of 6 characters in length, containing at least one uppercase letter, one lowercase letter, and one number.</Typography>
                                    </Popover>
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       value={password}
                                       type="Password"
                                       name="user-pwd"
                                       id="pwd"
                                       style={{fontSize:18+"px"}}
                                       autoCapitalize="none"
                                       className="has-input input-lg text-center"
                                       placeholder="Password"
                                       data-testid="password-text-field"
                                       // onKeyPress={e => {
                                       //    if (e.key === 'Enter') this.onSubmit(e);
                                       //  }}
                                       invalid={passwordEmpty ? true : false}
                                       onChange={(e) => this.setState({ password: e.target.value, invalidPassword: !this.validatePassword(e.target.value), passwordEmpty: e.target.value !== '' ? false : true })}
                                    />
                                    {passwordEmpty ? 
                                       <FormFeedback data-testid="empty-password-error" style={{fontSize: 18+"px"}} >Please enter a valid password of a minimum length of 6 characters.</FormFeedback>
                                       : invalidPassword && password.length < 6 ?
                                       <FormFeedback data-testid="invalid-password-error" className="d-block" style={{fontSize: 18+"px"}}>Please enter a valid password of a minimum length of 6 characters.</FormFeedback>
                                       : invalidPassword && password.length > 5 &&
                                       <FormFeedback data-testid="invalid-password-error"  className="d-block" style={{fontSize: 18+"px"}}>Your password does not meet <a onClick={this.handleClick.bind(this)} style={{textDecoration: 'underline'}}>required criteria</a></FormFeedback>
                                    }
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       value={verifyPassword}
                                       type="Password"
                                       autoCapitalize="none"
                                       name="user-verifyPassword"
                                       id="verifyPassword"
                                       style={{fontSize:18+"px"}}
                                       className="has-input input-lg text-center"
                                       placeholder="Verify Password"
                                       // onKeyPress={e => {
                                       //    if (e.key === 'Enter') this.onSubmit(e);
                                       //  }}
                                       data-testid="verify-password-field" 
                                       invalid={(verifyPasswordEmpty ? true : false || verifyPassword ? true : false) && password && (verifyPassword !== password) ? true : false }
                                       valid={verifyPassword && password && (verifyPassword === password) ? true : false}
                                       onChange={(e) => this.setState({ verifyPassword: e.target.value, verifyPasswordEmpty: e.target.value !== '' ? false : true })}
                                    />
                                    {verifyPasswordEmpty ? 
                                       <FormFeedback data-testid="empty-verify-password-error"  style={{fontSize: 18+"px"}}>Verify password should not be empty </FormFeedback>
                                       : verifyPassword && password && verifyPassword !== password ?
                                       <FormFeedback data-testid="passwords-donot-match" style={{fontSize: 18+"px"}}>Passwords do not match </FormFeedback>
                                       : verifyPassword && password && verifyPassword === password &&
                                       <FormFeedback data-testid="passwords-match" valid style={{fontSize: 18+"px"}}>Passwords match </FormFeedback>
                                    }
                                   
                                 </FormGroup>
                                 <FormGroup className="mb-15">
                                    <Button
                                       className="btn btn-primary text-white btn-block w-100"
                                       variant="contained"
                                       size="large"
                                       data-testid="submit-button" 
                                       onClick={(e) => this.onSubmit(e)}>
                                       Submit
                                     </Button>
                                     {createUserFailed && <FormFeedback className="d-block" style={{fontSize: 18+"px"}}>{createUserFailed.message}</FormFeedback>}
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, Geolocation }) => {
   const { loading, inviteCode, user, userData, userOrgFromToken,createUserFailed } = authUser;
   // const {geoData} = Geolocation
   return { loading, inviteCode, user, userData, userOrgFromToken ,createUserFailed};
};

export default connect(mapStateToProps, {
   signupUserInFirebase,
   createUser,
   clearCreateUserFailedError
   // getGeoLocation,
})(LifesherpaSignup);
