/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'

// rct collapsible card
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
//Actions
import {
    getUserById,
} from '../../../actions'


import { withAuth0 } from "@auth0/auth0-react";
import RewardsPage from "../../../components/SherpaActivities/RewardsPage"

class Rewards extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null,
            selected: '',
            assingnActivityPanel: false,
        }
    }
    componentDidMount() {
        const { widgetApp } = this.props;
        const auth_uid = localStorage.getItem('auth_uid');
        const idToken = localStorage.getItem('idToken')
        if (idToken && auth_uid) {
            this.props.getUserById(auth_uid, idToken, widgetApp)
        }
    }

    render() {
        window["auth0"] = this.props.auth0
        const { hideRewards } = this.props;
        return (
            <div className="w-100 " data-testid="ls-dashboard">
                {
                    <div className="row">
                        {!hideRewards ?
                            <RctCollapsibleCard
                                colClasses="col-12 w-xs-full"
                                //heading="My Rewards"
                                fullBlock
                                customClasses="overflow-hidden theme-background "
                                headingCustomClasses="bg-lifesherpa"
                            >
                                <RewardsPage />
                            </RctCollapsibleCard>
                            :
                            <></>
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ authUser, Charts, settings }) => {
    const { hideRewards } = authUser
    const { loading } = Charts
    const { assingnActivityPanel } = settings;
    return { loading, assingnActivityPanel, hideRewards }
}

export default withAuth0(connect(mapStateToProps, {
    getUserById,
})(Rewards))
