/**
* Chat
*/
import React, { Component } from 'react';
import Drawer from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import config from '../../../config/config';

// components
import { connect } from 'react-redux';
import { } from "../../../actions"
import ActivityEditorIframe from "../../LifesherpaiFrame/ActivityEditorIframe";
import { reauthenticateIfTokenExpireWithIn30Min } from '../../../helpers/helpers';
import LifeSherpaLoading from "../../LifeSherpaLoading";
import { logoutCallbackForAuth0Expired } from 'Actions'

const drawerWidth = 400;

const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 'auto',
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: 340,
		height: '100%',
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
		},
		backgroundColor: '#fff',
		overflow: "hidden"
	},
	drawerHeight: {
		height: '100%',
	},
	content: {
		flexGrow: 1
	},
});

class ActivityEditorSidebar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			editorURL:""
		}
	}
	async componentDidMount() {
        const tokenAuthentication = await reauthenticateIfTokenExpireWithIn30Min();
		if(tokenAuthentication) {
            const { activityId, editorType, scope } = this.props;
			const themeName = this.props.activeTheme && this.props.activeTheme.name ? this.props.activeTheme.name : "default"
			const theme = themeName.charAt(0).toUpperCase() + themeName.slice(1);
			const idToken = localStorage.getItem("idToken")
			const orgId = localStorage.getItem("selectedOrgId")
			const userId = localStorage.getItem("uid");
			let editorURL = `${config.objectEditorUrl}/v2/activity/${editorType}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
			if (scope) {
				editorURL += `&scope=${scope}`
			}
			if (activityId) {
				editorURL += `&activityId=${activityId}`
			}
			this.setState({editorURL, loading:false});
		} else {
			console.log("Token authentication failed -->", tokenAuthentication)
			this.setState({loading:false})
			this.props.handleCloseObjectEditor();
			logoutCallbackForAuth0Expired()
		}
	}
	handleClickOnOutSide(){
		console.log("Please click on back button to dismiss sidebar!")
	}

	render() {
		const { classes } = this.props;
		const { loading, editorURL } = this.state;
		console.log("editorURL  -> ", editorURL, loading)
		return (
			<>
				<Drawer
					anchor='right'
					className={classes.drawerHeight}
					classes={{
						paper: classes.drawerPaper,
					}}
					open={this.props.openEditor}
					onClose={process.env.SERVER_MODE === 'development' ? this.props.handleCloseObjectEditor : this.handleClickOnOutSide()}
					ModalProps={{
						keepMounted: true,
					}}
				>
					<LifeSherpaLoading loading={loading} />
					{!loading &&
						<ActivityEditorIframe
							editorURL={editorURL}
							handleCloseObjectEditor={this.props.handleCloseObjectEditor}
							scope={this.props.scope}

						/>
					}
				</Drawer>

			</>
		);
	}
}
const mapStateToProps = ({ settings }) => {
	const { activeTheme } = settings;

	return { activeTheme }

}

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(ActivityEditorSidebar));
