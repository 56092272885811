/**
 * Workflow List
 */
import React from "react";

import { connect } from 'react-redux';
import LifeSherpaLoading from '../../../components/LifeSherpaLoading';
// actions
import {    
    getWorkflowList,
    getUserWorkflowList,
    clearWorkflowExcResponseData,
    startWorkflow,
    showStartWorkflowPanel,
    openWorkflowHistoryPanel
} from '../../../actions';

// components
import WorkflowListItem from '../../../components/SherpaActivities/WorkflowListItem';

import "../../../assets/scss/custom/app.scss"
import { Scrollbars } from 'react-custom-scrollbars';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import IconButton from "@mui/material/IconButton";
import OrganizationFilter from "../../../components/Widgets/OrganizationFilter";

class LSWorkflowList extends React.Component {

    state = {
        mobileOpen: false,
        search: '',
        filter: '',
        update: '',
        loading: true,
        activityId: null,
        openOrgFilter: false,
        workflowPanelOrgFiltered: null

    };

    componentDidMount() {
        console.log("%c[Workflows]%c Rendered all workflows panel","color:black","color:green");
        const {workflowMenu, clientPanel} = this.props;
        const {workflowPanelOrgFiltered} = this.state;
        const userId = localStorage.getItem("uid");
        const orgId = localStorage.getItem("selectedOrgId");
        const auth_uid = localStorage.getItem("auth_uid");
        if(workflowMenu) {
            this.props.getWorkflowList(workflowPanelOrgFiltered);
        } else if(clientPanel) {
            this.props.getUserWorkflowList(userId, [orgId])
        } else {
            this.props.getUserWorkflowList(auth_uid);
        }
    }

    
    handelSearch = (e) => {
        this.setState({...this.state,search:e.target.value});
    }

    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }

    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh - 160px)';
        } else {
            return '540px';
        }
        
    }

    handleStartWorkflow = (selectedWorkflow) => {
        console.log("workflow :", selectedWorkflow)
        if(selectedWorkflow.organization) {
            localStorage.setItem("selectedOrgId", selectedWorkflow.organization);
        }
        this.setState({ anchorEl: null}, () => {
            this.props.showStartWorkflowPanel({workflow: selectedWorkflow, openWorkflowPendingList: false});
        });
    }

    openWorkflowExecutionHistory(workflow){
        console.log("workflow :", workflow)
        if(workflow.organization) {
            localStorage.setItem("selectedOrgId", workflow.organization);
        }
        this.setState({anchorEl: null})
        this.props.openWorkflowHistoryPanel(workflow, true);
    }

    handleOpenWorkflowPendingRoleList = (workflow) => {
        console.log("workflow :", workflow)
        if(workflow.organization) {
            localStorage.setItem("selectedOrgId", workflow.organization);
        }
        this.setState({anchorEl: null}, () => {
            this.props.showStartWorkflowPanel({workflow, openWorkflowPendingList: true});
        })
    }

    handleCancelWorkflow = (selectedWorkflow) => {
        if(selectedWorkflow.organization) {
            localStorage.setItem("selectedOrgId", selectedWorkflow.organization);
        }
        console.log("selectedWorkflow :", selectedWorkflow)
        this.setState({ anchorEl: null })
    }

    handleCalenderButton(activity, key) {
        this.setState({ calenderActivity: activity, calenderBox: true, editScheduleKey: key })
    }

    handleClickMenu(event, key, activity) {
        console.log('selected activity --->', activity)
        this.setState({ anchorEl: event.currentTarget, editScheduleKey: key, selectedMenuActivity: activity });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null, editScheduleKey: -1, selectedMenuActivity: null });
    };

    handleClickOnStatus(workflow, workflowStatus) {
        this.setState({anchorEl: null}, () => {
            if(workflow.organization) {
                localStorage.setItem("selectedOrgId", workflow.organization);
            }
            this.props.openWorkflowHistoryPanel(workflow, true, workflowStatus);
        })
    }

    handleOpenFilter(){
        this.setState({openOrgFilter: true})
    }

    handleCloseFilter(){
        this.setState({openOrgFilter: false})
    }

    clearOrgFilter() {
        const {workflowMenu} = this.props;
        const auth_uid = localStorage.getItem("auth_uid");
        this.setState({workflowPanelOrgFiltered: null, openOrgFilter: false}, () => {
            if(workflowMenu) {
                this.props.getWorkflowList();
            } else {
                this.props.getUserWorkflowList(auth_uid);
            }
        })
    }

    applyOrgFilter(filterOrgOptions) {
        const {workflowMenu} = this.props;
        const auth_uid = localStorage.getItem("auth_uid");
        this.setState({workflowPanelOrgFiltered: filterOrgOptions, openOrgFilter: false}, () => {
            if(workflowMenu) {
                this.props.getWorkflowList(filterOrgOptions);
            } else {
                this.props.getUserWorkflowList(auth_uid, filterOrgOptions);
            }
        })
    }

    render() {
        const { workflowExecution, workflowLoading, showViewStatus, organizations, userRole, popupThemeClass } = this.props;
        let {search, openOrgFilter, workflowPanelOrgFiltered} = this.state;
        const workflowslist = search && search.length > 2 && this.props.workflowslist ? this.props.workflowslist.filter(wokflow => wokflow.name && wokflow.name.toLowerCase().includes(search.toLowerCase())) : this.props.workflowslist
        const orgId = localStorage.getItem("orgId");
        const filterApplied = workflowPanelOrgFiltered && workflowPanelOrgFiltered.length > 0 ? true : false
        const orgIds = localStorage.getItem("orgIds")?.split(",");
        const workflowsSupportOrgIds = organizations ? organizations.filter(org => org.enableWorkflows): [];
        const isAdminUser = localStorage.getItem("auth_uid") == localStorage.getItem("uid")

        return (
            <div data-testid="my-workflows-panel">
                <div className="rct-mail-wrapper">
                    <div>
                        <LifeSherpaLoading loading={workflowLoading} />
                        <div className="d-flex p-3 list-card align-items-center justify-content-between">
                            {workflowsSupportOrgIds.length > 1 && isAdminUser ? 
                                <div className={`d-flex ${filterApplied ? "activity-filter-icon" : "activity-filter-icon-applied"} mr-2`}>
                                    <IconButton data-testid="orgId-filter-icon-button" title="Filter By OrgId" className="text-white rounded-circle mr-2" onClick={() => this.handleOpenFilter()}>
                                        <FilterAlt data-testid="orgId-filter-enabled" className="filter-icon" fontSize="large"/>
                                    </IconButton>
                                </div>
                                : <></>
                            }
                            <div className="mx-2 search-activity-field">
                                <form noValidate autoComplete="off">
                                    <TextField data-testid="my-workflows-search-text-field-disabled" id="standard-search" 
                                        onChange={(e) => this.handelSearch(e)}
                                        label="Search Workflow"
                                        type="search" 
                                        value={search} 
                                        InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary"/>
                                        </InputAdornment>
                                        ),
                                        endAdornment: ( search && search.length > 0 ?
                                            <InputAdornment position="end">
                                            <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                            </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                        )
                                        }}
                                        variant="standard" 
                                    />
                                </form>
                            </div>
                        </div>
                        <Scrollbars
                            className="rct-scroll list-sherpa-activity"
                            autoHide
                            style={{ height: this.getScrollHeight() }}
                            >   
                            { workflowslist && workflowslist.length > 0 ? workflowslist.map((workflow, key) => (
                                    <WorkflowListItem
                                        key={key}
                                        selected={this.props.selected}
                                        role={this.props.selectedRole}
                                        workflow={workflow}
                                        selectWorkflowId={this.props.selectedId}
                                        selectedMenuWorkflow={this.state.selectedMenuActivity}
                                        itemKey={key}
                                        anchorEl={this.state.anchorEl}
                                        handleClickMenu={(e) => this.handleClickMenu(e, key, workflow)}
                                        handleCloseMenu={() => this.handleCloseMenu()}
                                        handleCancelWorkflow={(e) => this.handleCancelWorkflow(workflow)}
                                        handleStartWorkflow={(e) => this.handleStartWorkflow(workflow)}
                                        hideRewards={true}
                                        showStartOrResumeButton={true}
                                        handleOpenWorkflowPendingRoleList={() => this.handleOpenWorkflowPendingRoleList(workflow)}
                                        userRole={userRole}
                                        openWorkflowExecutionHistory={() => this.openWorkflowExecutionHistory(workflow)}
                                        showViewStatus={showViewStatus}
                                        showRolePendingAssignment={this.props.showRolePendingAssignment}
                                        handleClickOnStatus={this.handleClickOnStatus.bind(this)}
                                        organization={ isAdminUser ? organizations && orgIds && orgIds.length > 1 ? organizations.find(org => org.id === (workflow.organization || orgId)) : null : null}
                                    />
                                ))
                                : workflowLoading ?
                                    <div className="d-flex justify-content-center align-items-center py-50">
                                        <h4>Fetching Workflows</h4>
                                    </div>
                                :
                                <div data-testid="workflow-not-found" className="d-flex justify-content-center align-items-center py-50 no-data-found">
                                    <h4>Workflows Not Found</h4>
                                </div>
                            }
                        </Scrollbars>

                    </div>
                </div>
                {openOrgFilter ?
                    <OrganizationFilter
                        title="Organization Filter"
                        showFilter={openOrgFilter}
                        handleCloseFilter={this.handleCloseFilter.bind(this)}
                        organizations={organizations}
                        clearOrgFilter={this.clearOrgFilter.bind(this)}
                        applyOrgFilter={this.applyOrgFilter.bind(this)}
                        popupThemeClass={popupThemeClass}
                        filterOrgOptions={workflowPanelOrgFiltered}
                    />
                    : <></>
                }
            </div>
        )
    }
}

// map state to props
const mapStateToProps = ({ chatAppReducer, authUser, workflowData, clientsList, settings}) => {
    const { selectedUser, displayName, profileImage, selectedMemberId, selectedRole } = chatAppReducer
    const { user, userRole, privacyPolicy } = authUser
    const {workflowLoading, startWorkflowPanel, workflowExecution, assignedUsers, workflowslist} = workflowData;
    const {organizations, workflowPanelOrgFiltered, clientPanel} = clientsList;
    const { selectedMenuItem, popupThemeClass } = settings;

    return { selectedUser, displayName, profileImage, selectedMemberId, selectedRole, user, userRole, privacyPolicy, workflowLoading, startWorkflowPanel, workflowExecution, assignedUsers, workflowslist, organizations, workflowPanelOrgFiltered, selectedMenuItem, clientPanel, popupThemeClass };
}

export default connect(mapStateToProps, {
    getWorkflowList,
    getUserWorkflowList,
    clearWorkflowExcResponseData,
    startWorkflow,
    showStartWorkflowPanel,
    openWorkflowHistoryPanel
})(LSWorkflowList);