/**
 * App Config File
 */
const AppConfig = {
   appLogo: require('Assets/img/life-sherpa-logo-retina.png'),          // App Logo
   appLogoWhite: require('Assets/img/LS-Logo-Horizontal-W-300x109.png'),          // App Logo
   brandName: 'LifeSherpa Portal',                                    // Brand Name
   lsIconLogo: require('Assets/img/favicon.png'),   
   lsVerticalIcon:  require('Assets/img/ls-vertical-icon.png'),           // LS vertical icon
   navCollapsed: false,                                      // Sidebar collapse
   darkMode: false,                                          // Dark Mode
   boxLayout: false,                                         // Box Layout
   rtlLayout: false,                                         // RTL Layout
   miniSidebar: false,                                       // Mini Sidebar
   enableSidebarBackgroundImage: true,                      // Enable Sidebar Background Image
   sidebarImage: require('Assets/img/sidebar-1.jpg'),     // Select sidebar image
   isDarkSidenav: true,                                   // Set true to dark sidebar
   enableThemeOptions: true,                              // Enable Theme Options
   locale: {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'en',
   },
   enableUserTour: process.env.NODE_ENV === 'production' ? true : false,  // Enable / Disable User Tour
   copyRightText: 'Reactify © 2020-21 All Rights Reserved.',      // Copy Right Text
   // light theme colors
   themeColors: {
      'primary': '#008C95',//'#5D92F4',
      'secondary': '#677080',
      'success': '#00D014',
      'danger': '#FF3739',
      'warning': '#FFB70F',
      'info': '#00D0BD',
      'dark': '#464D69',
      'default': '#FAFAFA',
      'greyLighten': '#A5A7B2',
      'grey': '#677080',
      'white': '#FFFFFF',
      'purple': '#896BD6',
      'yellow': '#D46B08',
      'theme1': "#5F249F",
      'theme2': "#134266",
      'theme3': "#134266",
      'theme5': '#0F594E',
      'brandedTheme': '#40C0E4'
   },
   // dark theme colors
   darkThemeColors: {
      darkBgColor: '#424242'
   }
}

export default AppConfig;
