/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import queryString from 'query-string';
import LifeSherpaLoading from '../LifeSherpaLoading'
import { withAuth0 } from "@auth0/auth0-react";
// redux action
import {
   getUserFirebaseToken,
   resetReduxData
} from '../../actions';

class LoginSuccess extends Component {

   state = {
      email: '', //'demo@example.com',
      password: '', //'test#123',
      emailValid: true,
      passwordValid: true,
      isTokenEmpty: false,
      token: ''
   }

   /**
    * On User Login
    */
   onContinue() {
      this.props.history.push('/success');
   }

   componentDidMount() {
      console.group("%c[LoginSuccess] %c Redirected on login success screen", "color:black", "color:green");
      const {logout} = this.props.auth0;
      let params = queryString.parse(this.props.location.search);
      let { inviteCode } = this.props;
      if (params && this.props.user && this.props.user.accessToken) {
         const userData = { token: this.props.user.accessToken, profile: this.props.profile, region: params.region, loginCode: inviteCode }
         this.props.getUserFirebaseToken(userData, this.props.history, logout)
      }
   }
   onConfirm() {
      this.props.resetReduxData();
   }

   render() {
      let loading = true;
      let {screenThemeClass } = this.props;
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={screenThemeClass}>
               <LifeSherpaLoading loading={loading} />
            </div>

         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings }) => {
   const { screenThemeClass, popupThemeClass } = settings; 
   const { user, loading, firebaseToken, profile, inviteCode } = authUser;
   return { user, loading, firebaseToken, profile, inviteCode, screenThemeClass }
}

export default withAuth0(connect(mapStateToProps, {
   getUserFirebaseToken,
   resetReduxData
})(LoginSuccess));
