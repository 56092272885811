/**
 * InitialLaunchScreen
 */

import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import config from '../../config/config'
import { connect } from 'react-redux';
import AppConfig from '../../constants/AppConfig';
import { withAuth0 } from "@auth0/auth0-react";
import { configureStore } from '../../store';
import LifeSherpaLoading from '../LifeSherpaLoading';
import {delay, openPopup} from "../../helpers/helpers";
// redux action
import {
   removeFirebaseErrorMessage,
   clearUserOrgAndRegion
} from '../../actions';

import queryString from 'query-string';
import SweetAlert from 'react-bootstrap-sweetalert'
import InitialScreenForm from './InitialScreenForm';
import AppToolBar from './AppToolBar';

class InitialLaunchScreen extends Component {
   state = {
      australia:true,
      us:true,
      defaultAuth0Login:false,
      count:0,
      maximanRetry: 3,
   }

   componentDidMount() {
      console.group("%c[InitialLaunchScreen] %c Lifesherpa lauchscreen displayed","color:black","color:green");
      let testing = localStorage.getItem("testing");
      this.props.clearUserOrgAndRegion();
      if (!testing) {
         this.setState({defaultAuth0Login:true}, () => {
            this.getAuth0AccessToken()
         })
      } 
   }

   getAuth0AccessToken = () => {
      const {getAccessTokenSilently} = this.props.auth0;
      let defaultAudiance = config.defaultAuth0DataForForUSA.audience;
      let defaultRegion = config.defaultAuth0DataForForUSA.region;
      this.getAuth0DefaultAcceesToken(defaultAudiance, getAccessTokenSilently, defaultRegion);
      
   }

   getAuth0DefaultAcceesToken = async(audience, getAccessTokenSilently, region) => {
      console.log("Getting auth0 acess token for "+region+" region.....");  
      try {
         const token = await getAccessTokenSilently({audience: audience});
         const { user, isAuthenticated} = this.props.auth0;
         if (isAuthenticated){
            const authResult = {accessToken: token}
            const profileResult = user
            localStorage.setItem("user_id", "user-id");
            localStorage.setItem("region",region);
            let fakeData = {}
            localStorage.setItem("auth0ClientConfig",JSON.stringify(config.defaultAuth0DataForForUSA));
            fakeData =  {auth0ClientConfig: config.defaultAuth0DataForForUSA};
            const store = configureStore();
            store.dispatch({ type: 'LOGIN_USER_SUCCESS', payload: authResult })
            store.dispatch({ type: 'GET_USER_INFO', payload: profileResult });
            store.dispatch({ type: 'GET_SUCCESS_USER_ORGANIZATION', payload: fakeData });
            console.log("%cGot auth0acess token ","color:green");
            console.log("Redirecting on login success screen....."); 
            console.groupEnd();
            this.setState({...this.state,defaultAuth0Login:false}, () => {
               window.location.replace(`/loginsuccess?region=${region}`);
            }) 
         } else {
            const {count, maximanRetry} = this.state;
            console.log("Auth0 not authenticated");
            if(count < maximanRetry) {
               await delay(5000)
               console.log("Retying auth0 authentication: ", count + 1);
               this.setState({...this.state, count: count + 1});
               this.getAuth0AccessToken(region);
            } else {
               throw new Error("Auth0 is not authenticated");
            } 
         }
      } catch(e) {
         console.log("%cError occured while fetching auth0 access token for US region ------>","color:red",e);
         this.setState({...this.state,defaultAuth0Login:false}) 
      }
   }

   onSignInWithToken() {
      const theme = JSON.parse(localStorage.getItem('theme'))
      console.log("Redirecting to registration app ");
      if (theme && theme.name === 'Theme4'){
         var windowReference = window.open();
         windowReference.location = `${config.tokenRegistrationURL}`;
         openPopup(windowReference)
      } else if (theme && theme.name) {
         var windowReference = window.open();
         windowReference.location = `${config.registraionappurl}&theme=${theme.name}`;
         openPopup(windowReference)
      } else {
         var windowReference = window.open();
         windowReference.location = `${config.registraionappurl}&theme=Default`;
         openPopup(windowReference)
      }
      console.log("%cRedirected on registration app ","color:green");
   }

   //handle returning success url
   onSignInWithUsernameAndPassord(){
      console.log("Redirecting on signin with email screen ",);
      this.props.history.push('/signinEmail');
      console.groupEnd();
   }

   onConfirm() {
      localStorage.removeItem("testing")
      this.props.removeFirebaseErrorMessage();
   }
   
   render() {
      const { loading,screenThemeClass, themeData , themeLoading, getFirebaseTokenFailed} = this.props;
      let params = queryString.parse(this.props.location.search);
      const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo;
      const themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
      let {defaultAuth0Login} = this.state;
      let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={`rct-session-wrapper ${screenThemeClass}`}>
               <div className='login-screen' style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
                  {getFirebaseTokenFailed &&
                     <SweetAlert
                        warning
                        btnSize="sm"
                        customClass="warningText"
                        show={getFirebaseTokenFailed.message !== ''}
                        confirmBtnText="OK"
                        confirmBtnBsStyle="warning"
                        title={`Warning`}
                        onConfirm={this.onConfirm.bind(this)}
                     >
                        {getFirebaseTokenFailed.message}
                     </SweetAlert>
                  }
                  <LifeSherpaLoading loading={(!themeLoading && defaultAuth0Login) || loading}/>
                  <AppToolBar
                     activeTheme={activeTheme}
                     themeLogo={themeLogo}
                     applogo={applogo}
                     themeData={themeData}
                  />
                  <div  data-testid="initial-launch-screen" className="session-inner-wrapper">
                     <div className="container">
                        <div className="row row-eq-height">
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              <InitialScreenForm
                                 params={params}
                                 onSignInWithToken={() => this.onSignInWithToken()}
                                 onSignInWithUsernameAndPassord={() => this.onSignInWithUsernameAndPassord()}
                              />
                           </div>
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser , settings}) => {
   const { user, loading, themeData, defaultAuth0LoginStatus, setAustralia, setUSA, themeLoading, getFirebaseTokenFailed} = authUser; 
   const { screenThemeClass, popupThemeClass, themeLogo } = settings; 
   return { user, loading, screenThemeClass, popupThemeClass, themeLogo, themeData, defaultAuth0LoginStatus, setAustralia, setUSA, themeLoading, getFirebaseTokenFailed}
}

export default connect(mapStateToProps, {
   removeFirebaseErrorMessage,
   clearUserOrgAndRegion
})(withAuth0(InitialLaunchScreen));


