/**
 * Email Listing
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

// redux action
import {
    onSelectEmail,
    setActivityType,
    markAsStarEmail,
    getActivitiesList,
    StartActivity,
    ResumeActivity,
    showActivityHistoryDetail,
    selectActivity,
    showActivityConfirmation,
    getHistoryList,
    getSelectedActivity,
    setActivityReminder, getLoading, selectActivityId,
    deletePrivateActivity, getChartsList,
    clearHistoryData,
    handleShowActivityDetailsPanel,
    setActivityReset, 
    adjustAchievementTimer,
    executeActivity,
    getClientPokeForm,
    deleteGeneralActivity,
    assingCommonActivityToClient
} from '../../actions';

// component
import UserAllActivitiesListItem from './UserAllActivitiesListItem';

import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
//Intl Message
import IntlMessages from 'Util/IntlMessages';
import SweetAlert from 'react-bootstrap-sweetalert'
import { IconButton } from '@mui/material';
import Moment from 'moment';
import ReactCharts from '../Charts'
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import ActivityTimerUpdate from "./ActivityTimerUpdate";
import {scrollMaincontainerToTop} from "../../helpers/helpers";
import UserAgendaViewList from './UserAgendaViewList'
import ActivityConfirmForm from '../ActivityForm/ActivityConfirmForm'

class UserAllActivitiesList extends Component {
    state = {
        anchorEl: null,
        editScheduleKey: -1,
        deletePopup: false,
        previewPopup: false,
        stepNo: 0,
        calenderActivity: null,
        calenderBox: false,
        activity: '',
        notifyBox: false,
        selActivity: {},
        openChart: false,
        chartParams: {},
        selectId: -1,
        showPopup: false,
        showTimer: false,
        selectedMenuActivity:null,
        openForm: false
    }

    handlePokeForm() {
        const {pokeFormId} = this.props
        if (!this.state.openForm){
            this.setState({openForm: true, anchorEl: null})
            this.props.getClientPokeForm(pokeFormId)
        } else {
            this.setState({openForm: false, anchorEl: null})
        }
    }
    
    handleCalenderButton(activity, key) {
        this.setState({ calenderActivity: activity, calenderBox: true, editScheduleKey: key })
    }

    handleOpenConfirmForm(activity) {
        this.props.handleClose(activity)
    }

    /**
     * On Select Email
     */
    onSelectEmail(e, email) {
        e.stopPropagation();
        this.props.onSelectEmail(email);
    }

    handleShowActivityHistoryList = () => {
        let { selectedMenuActivity } = this.state;
        let activity = selectedMenuActivity;
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if(activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.props.getSelectedActivity(activity);
        const userId = localStorage.getItem("uid");
        this.props.getHistoryList(userId, activity.id);
        scrollMaincontainerToTop();
        this.props.showActivityHistoryDetail(true);
    }
    /**
     * Function to return task label name
     */
    getTaskLabelNames = (taskLabels) => {
        let elements = [];
        const { labels } = this.props;
        for (const taskLabel of taskLabels) {
            for (const label of labels) {
                if (label.value === taskLabel) {
                    let ele = <span key={label.value}
                        className={classnames('badge badge-pill', { 'badge-success': label.value === 1, 'badge-primary': label.value === 2, 'badge-info': label.value === 3, 'badge-danger': label.value === 4 })}
                    >
                        <IntlMessages id={label.name} />
                    </span>;
                    elements.push(ele);
                }
            }
        }
        return elements;
    }
    handleClickMenu(event, key, activity) {
        console.log("selected activity -->", activity)
        // this.props.selectActivity(true)
        // this.props.selectActivityId(activity.id)
        this.setState({ anchorEl: event.currentTarget, editScheduleKey: key, selectedMenuActivity: activity });
    };
    handleCloseMenu = () => {
        //this.props.selectActivityId(null)
        this.setState({ anchorEl: null, editScheduleKey: -1, selectedMenuActivity:null });
    };
    handleDeletePop = () => {
        if (!this.state.deletePopup) this.setState({ deletePopup: true, anchorEl: null, anchorEl1: null, });
        else this.setState({ deletePopup: false })
    }
    handleDeleteActivity = () => {
        let userId = localStorage.getItem('uid');
        let { selectedMenuActivity } = this.state;
        let activityId = selectedMenuActivity.id
        this.props.deletePrivateActivity(userId, activityId)
        this.setState({ anchorEl: null, anchorEl1: null, deletePopup: false, editScheduleKey: -1, selectedMenuActivity:null })
    }
   
    EditActivity = () => {
        let { selectedMenuActivity } = this.state;
        let activity = { activityId: selectedMenuActivity.id, type: selectedMenuActivity.type }
        this.props.EditActivity(activity)
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    handleEditGeneralActivity = () => {
        let { selectedMenuActivity } = this.state;
        this.props.handleObjectEditor(selectedMenuActivity.id, selectedMenuActivity.type)
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    handleUnassignActivity = () => {
        const {selectedGroup, clientActivitiesType } = this.props
        const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
        let { selectedMenuActivity } = this.state;
        const activityId = [selectedMenuActivity.id]
        this.setState({ anchorEl1: null, anchorEl: null, deletePopup: false }, () => {
            this.props.assingCommonActivityToClient("", selectedGroup, activityType, activityId)
        })
    }

    handleDeleteGeneralActivity = () => {
        const userId = localStorage.getItem("uid")
        const {selectedGroup, clientActivitiesType } = this.props
        const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
        let { selectedMenuActivity } = this.state;
        const activityId = selectedMenuActivity.id
        this.setState({ anchorEl1: null, anchorEl: null, deletePopup: false }, () => {
            this.props.deleteGeneralActivity(userId, activityId, activityType, selectedGroup)
        })
    }

    handleStartActivity = () => {
        let { selectedMenuActivity } = this.state;
        let activity = { activityId: selectedMenuActivity.id, ...selectedMenuActivity };
        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }
        let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let activityType = selectedMenuActivity.isPrivateActivity ? 'private' : 'common';
        this.props.setActivityType(activityType)
        let uid = localStorage.getItem('uid');
       this.props.StartActivity(activity.activityId, date, uid)
        this.props.handleStartActivity(activity)
        this.setState({ anchorEl1: null, anchorEl: null })
    }
    handleShowActivityDetails = (key) => {
        let { selectedMenuActivity } = this.state;
        let activity = selectedMenuActivity;
        scrollMaincontainerToTop();
        this.props.handleShowActivityDetailsPanel(activity)
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    handleAdjustActivityTimer = () => {
        let { selectedMenuActivity } = this.state;
        let activity = selectedMenuActivity;
        this.setState({ anchorEl1: null, anchorEl: null, selectedActivity: activity, showTimer: true, duration: activity.expectedDuration });
    }
    handleCloseTimerUpdate = () => {
        this.setState({ selectedActivity: null, showTimer: false });
    }

    handleUpdateTimer = (hr, min, sec) => {
        let {selectedActivity} = this.state;
        console.log("hr, min, sec --> ", hr, min, sec);
        let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let duration = `${hr}:${min}:${sec}`
        this.props.adjustAchievementTimer(selectedActivity.achievementId, duration, date)
        this.setState({ selectedActivity: null, showTimer: false });
    }

    handleActivityTitleClick = (selctedActivity) => {
        let activity = selctedActivity;
        this.props.handleShowActivityDetailsPanel(activity)
        scrollMaincontainerToTop();
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    handleResetActivity = () => {
        let { selectedMenuActivity } = this.state;
        let activity = selectedMenuActivity;
        let userId = localStorage.getItem('uid');
        this.setState({ anchorEl1: null, anchorEl: null })
        console.log("selectedMenuActivity: ", selectedMenuActivity)
        this.props.setActivityReset(userId, activity);
    }

    handleResumeActivity = (selectedActivity, scheduleKey) => {
        let activity = { activityId: selectedActivity.id, ...selectedActivity };
        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let activityType = selectedActivity.isPrivateActivity ? 'private' : 'common';
        this.props.setActivityType(activityType)
        let uid = localStorage.getItem('uid');

        this.props.executeActivity(activity.activityId, date, uid, scheduleKey)
        this.props.handleStartActivity(activity)
        
        this.setState({ anchorEl1: null, anchorEl: null })
    }
    handleReminderButton = (key) => {
        let {selectedMenuActivity} = this.state;
        let sel = selectedMenuActivity
        this.setState({ activity: sel, notifyBox: true, anchorEl1: null, anchorEl: null })
    }
    handleNotify = (activity) => {
        const id = activity ? activity.id : null
        const orgId = activity && activity.organization ? activity.organization  : localStorage.getItem('selectedOrgId')
        const { selectedMemberId } = this.props;
        let userId = localStorage.getItem("uid");
        this.props.setActivityReminder(userId, id, orgId)
        this.setState({ activity: '', notifyBox: false, anchorEl1: null, anchorEl: null })
    }
    selectedActivity = (e) => {
        let { selActivity } = this.state
        if (selActivity.id === e.id) this.setState({ selActivity: {} })
        else this.setState({ selActivity: e })
    }
    handleChart = (activity) => {
        let idToken = localStorage.getItem('idToken')
        const orgId = activity && activity.organization ? activity.organization  : localStorage.getItem('selectedOrgId')
        this.props.getChartsList(activity.id, orgId, idToken, 'activities')
        let param = { id: activity.id, orgId: orgId, idToken: idToken, module: 'activities' }
        this.setState({ openChart: true, chartParams: param })
    }

    chartClose = () => {
        this.setState({ openChart: false })
    }

    handleStart = (activity) => {
        let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let activityType = activity.isPrivateActivity ? 'private' : 'common';
        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }
        this.props.setActivityType(activityType)
        let uid = localStorage.getItem('uid');
        this.props.StartActivity(activity.id, date, uid)
        this.props.handleStartActivity(activity)
        this.setState({ anchorEl1: null, anchorEl: null })
    }
    
    /* Execute Activity*/
    handleExecuteActivity = (selectedActivity, scheduleKey) => {
        let activity={activityId:selectedActivity.id, ...selectedActivity}
        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let activityType = selectedActivity.isPrivateActivity?'private':'common';
        this.props.setActivityType(activityType)
        let uid=localStorage.getItem('uid');

        if (selectedActivity.state && selectedActivity.state === 'paused') {
            console.log(" paused activity started ->")
        } else {
            console.log(" activity executed ->")
        } 

        this.props.executeActivity(selectedActivity.id, date, uid, scheduleKey)
        this.props.handleStartActivity(activity)

        this.setState({ anchorEl: null})
    }

    /* Execute Activity From Option*/
    handleExecuteActivityFromOption = () => {
        let { selectedMenuActivity } = this.state;
        let activity = { activityId: selectedMenuActivity.id, ...selectedMenuActivity };

        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let date=Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let activityType = selectedMenuActivity.isPrivateActivity?'private':'common';
        this.props.setActivityType(activityType)
        let uid=localStorage.getItem('uid');

        this.props.executeActivity(activity.activityId,date,uid)
        this.props.handleStartActivity(activity)

        this.setState({ anchorEl: null})
    }
   
    render() {
        const { allActivities, selectedClient, clientActivitiesType, summaryLoading, pokeFormId, allowCreateGeneralActivity, clientUserDetail } = this.props;
        const inProgressActivities = allActivities && allActivities.length > 0 ? allActivities.filter(activity => activity.state === "paused" || activity.state === "inProgress") : []
        let { editScheduleKey, stepNo } = this.state
        let userId = localStorage.getItem('uid');
        let idToken = localStorage.getItem('idToken')
        const auth_uid = localStorage.getItem('auth_uid')
        let list = []
        let calenderList = []
        editScheduleKey > -1 && allActivities[editScheduleKey] && allActivities[editScheduleKey].steps && Object.keys(allActivities[editScheduleKey].steps).length > 0 ? Object.keys(allActivities[editScheduleKey].steps).map((dateObjKey, key) => {
            list.push(allActivities[editScheduleKey].steps[dateObjKey])
        }) : ""

        if (this.state.calenderActivity && this.state.calenderActivity.sortedSchedules && this.state.calenderActivity.sortedSchedules.length > 0) {
            calenderList = this.state.calenderActivity.sortedSchedules
            // console.log('sortedSchedules', this.state.calenderActivity.sortedSchedules)
        }
        //console.log(" selectedClient ---> ", selectedClient);
        let hideRewards = false;
        if(clientUserDetail && ("hideRewards" in clientUserDetail)) {
            hideRewards = clientUserDetail.hideRewards;
        }
        // console.log("selected member=>",this.props.selectedMember.user);
        return (
            <div className='w-100' data-testid="client-activities-list-component">
                {this.state.openChart && <ReactCharts openChart={this.state.openChart} chartParams={this.state.chartParams} onClose={() => this.chartClose()} />}
                <ul className="list-unstyled m-0 list-sherpa-activity mt-2 mb-4 pt-0 pl-2 pr-2" >
                    <div>
                        {clientActivitiesType === "Summary" ?
                              summaryLoading ?
                               <div className="d-flex justify-content-center align-items-center py-50">
                                  <h4>Fetching activities Summary</h4>
                               </div>
                              :
                                <UserAgendaViewList
                                    editScheduleKey={this.state.editScheduleKey}
                                    selected={this.props.selected}
                                    selectedMenuActivity={this.state.selectedMenuActivity}
                                    allActivities={allActivities}
                                    selectedRole={this.props.selectedRole}
                                    selectActivityId={this.props.selectedId}
                                    handleShowActivityHistoryList={this.handleShowActivityHistoryList.bind(this)}
                                    anchorEl={this.state.anchorEl}
                                    calenderBox={this.state.calenderBox}
                                    handleClickMenu={this.handleClickMenu.bind(this)}
                                    handleCloseMenu={this.handleCloseMenu.bind(this)}
                                    handleReminderButton={this.handleReminderButton.bind(this)}
                                    handleCalenderButton={this.handleCalenderButton.bind(this)}
                                    handleDeletePop={this.handleDeletePop.bind(this)}
                                    EditActivity={this.EditActivity.bind(this)}
                                    handleStartActivity={this.handleStartActivity.bind(this)}
                                    handleExecuteActivityFromOption={this.handleExecuteActivityFromOption.bind(this)}
                                    handleResumeActivity={this.handleResumeActivity.bind(this)}
                                    handleExecuteActivity={this.handleExecuteActivity.bind(this)}
                                    hideRewards={hideRewards}
                                    handleActivityTitleClick={this.handleActivityTitleClick.bind(this)}
                                    handleResetActivity={this.handleResetActivity.bind(this)}
                                    handleAdjustActivityTimer={this.handleAdjustActivityTimer.bind(this)}
                                    handleShowActivityDetails={this.handleShowActivityDetails.bind(this)}
                                    handlePokeForm={() => this.handlePokeForm()}
                                    pokeFormId={pokeFormId}
                                    allowCreateGeneralActivity={allowCreateGeneralActivity}
                                    handleEditGeneralActivity={(e) => this.handleEditGeneralActivity()}
                                    handleUnassignActivity={() => this.handleUnassignActivity()}
                                />
                         : 
                        allActivities.length > 0 ? allActivities.map((activity, key) => (
                            <UserAllActivitiesListItem
                                key={key}
                                selected={this.props.selected}
                                allActivities={allActivities}
                                role={this.props.selectedRole}
                                activity={activity}
                                selectActivityId={this.props.selectedId}
                                editScheduleKey={this.state.editScheduleKey}
                                selectedMenuActivity={this.state.selectedMenuActivity}
                                userId={userId}
                                idToken={idToken}
                                handleShowActivityHistoryList={(e) => this.handleShowActivityHistoryList()}
                                itemKey={key}
                                anchorEl={this.state.anchorEl}
                                calenderBox={this.state.calenderBox}
                                handleChart={() => this.handleChart(activity)}
                                handleClickMenu={(e) => this.handleClickMenu(e, key, activity)}
                                handleCloseMenu={() => this.handleCloseMenu()}
                                onSelectEmail={(e) => this.onSelectEmail(e, activity)}
                                getTaskLabelNames={() => this.getTaskLabelNames(activity.title)}
                                handleReminderButton={() => this.handleReminderButton()}
                                handleCalenderButton={() => this.handleCalenderButton(activity, key)}
                                handleOpenConfirmForm={() => this.handleOpenConfirmForm(activity)}
                                handleDeletePop={() => this.handleDeletePop()}
                                handleEditGeneralActivity={(e) => this.handleEditGeneralActivity()}
                                handleStartActivity={(e) => this.handleStartActivity()}
                                handleResumeActivity={(e) => this.handleResumeActivity(activity)}
                                hideRewards={hideRewards}
                                handleShowActivityDetails={(e) => this.handleShowActivityDetails(key)}
                                handleActivityTitleClick={(e) => this.handleActivityTitleClick(activity)}
                                handleResetActivity={() => this.handleResetActivity()}
                                handleAdjustActivityTimer={() => this.handleAdjustActivityTimer()}
                                handleExecuteActivityFromOption={(e) => this.handleExecuteActivityFromOption()}
                                handleExecuteActivity={(e)=> this.handleExecuteActivity(activity)}
                                handlePokeForm={() => this.handlePokeForm()}
                                pokeFormId={pokeFormId}
                                showStartOrResumeButton={true}
                                allowCreateGeneralActivity={allowCreateGeneralActivity}
                                handleUnassignActivity={() => this.handleUnassignActivity()}
                            />
                        ))
                            :
                            <div className="d-flex justify-content-center align-items-center py-50">
                                <h4>No In Progress activity available</h4>
                            </div>
                        }
                    </div>
                </ul>
                {this.state.deletePopup &&
                    <div className="col-12 p-4">
                        <SweetAlert
                            warning
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Delete"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Delete"
                            onConfirm={() => this.handleDeleteGeneralActivity()}
                            onCancel={() => this.setState({ deletePopup: false, anchorEl: null })}
                        >
                            <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>Do you want to delete this Activity?</div>
                        </SweetAlert>
                    </div>}
                {this.state.calenderBox &&
                    <Dialog
                        fullScreen={false}
                        open={this.state.calenderBox}
                        onClose={() => this.setState({ calenderBox: false, editScheduleKey: -1, selectedMenuActivity:null })}
                        fullWidth={true}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                        className={`${this.props.popupThemeClass} schedules-list-dialog-popup`}
                        PaperProps={
                            { style: { maxWidth: "450px", borderRadius: 8, margin: 'auto' } }
                        }
                    >
                        <DialogTitle>
                            <div className="row py-2 px-3 border-bottom bg-lifesherpa">
                                <CalendarTodayIcon className="my-auto ml-1 text-white" /><h2 className="text-white my-auto ml-2">{this.state.calenderActivity != null ? this.state.calenderActivity.title : ''}</h2>
                                <IconButton className="ml-auto text-white my-auto" style={{ cursor: "pointer" }} onClick={() => this.setState({ calenderBox: false, editScheduleKey: -1, selectedMenuActivity:null })}><CloseIcon /></IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div className="container" >
                                <div className="my-5 py-5 mx-3" style={{ height: "300px" }}>
                                    {calenderList.length > 0 ? calenderList.map((list, index) => (
                                        <div className="row mb-2 pb-2 border-bottom" key={index}>{list.text}</div>
                                    )) : ""}
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions className="m-0 p-0">
                            <div className="col-12 text-center my-2" >
                                <div className="helpbutton mx-auto" style={{ width: 80 + 'px', fontSize: 16 + "px" }} onClick={() => this.setState({ calenderBox: false, editScheduleKey: -1 , selectedMenuActivity:null})}>OK</div>
                            </div>
                        </DialogActions>
                    </Dialog>
                }
                {this.state.showTimer &&
                    <ActivityTimerUpdate showTimer={this.state.showTimer}
                        handleCloseTimerUpdate={this.handleCloseTimerUpdate}
                        handleUpdateTimer={this.handleUpdateTimer}
                        details={this.state.selectedActivity}
                        duration={this.state.duration}
                    />
                }
                {
                    <ActivityConfirmForm
                        open={this.state.openForm ? this.state.openForm  : this.props.clientPokeFormLoading}
                        handleClose={this.handlePokeForm.bind(this)}
                        loading={this.props.clientPokeFormLoading}
                        pokeForm={this.props.clientPokeForm}
                        isPokeForGroup={false}
                        formId={pokeFormId}
                        displayName={this.props.displayName}
                        profileImage={this.props.profileImage}
                        title={this.props.pokeForm && this.props.pokeForm.formName ?  this.props.pokeForm.formName : ""}
                        selectedMemberId={this.props.selectedMemberId}
                        isPokeForClient={true}
                        selectedActivity={this.state.selectedMenuActivity}
                    />
                }
                {this.state.notifyBox &&
                    <div className="col-12 p-4">
                        <SweetAlert
                            custom
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Confirmation"
                            customIcon={require('../../assets/img/help-logo.png')}
                            onConfirm={() => this.handleNotify(this.state.activity)}
                            onCancel={() => this.setState({ notifyBox: false, activity: '', editScheduleKey: -1, selectedMenuActivity:null})}
                        >
                            <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>{`Would you like to notify ${selectedClient ? selectedClient.name : "Client"} to start ${this.state.activity.title}?`}</div>
                        </SweetAlert>
                    </div>}
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ emailApp, GroupList, chatAppReducer, activitiesList, settings, PokeForm, authUser, clientsList }) => {
    const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
    const { selectedGroup, groupMembersDetails } = GroupList
    const { activitySummery, privateActivities, selectedId, userActivities , summaryLoading, allowCreateGeneralActivity} = activitiesList
    const { selectedMemberId, selectedRole } = chatAppReducer;
    const { popupThemeClass } = settings;
    const { pokeForm, isPokeForGroup, clientPokeForm, clientPokeFormLoading } = PokeForm
    const {pokeFormId} = authUser;
    const {clientUserDetail} = clientsList;
    const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] : null;
    const profileImage = selectedClient && selectedClient.profileImageURL ? selectedClient.profileImageURL: null;
    const displayName = selectedClient && selectedClient.name ? selectedClient.name : '';
    return { popupThemeClass, userActivities, currentEmail, selectedRole, privateActivities, selectedId, sendingEmail, showActivityHistory, activitySummery, showConfirmation, selectedGroup, selectedMemberId, summaryLoading, pokeForm, isPokeForGroup, clientPokeFormLoading,clientPokeForm, pokeFormId, profileImage, displayName, allowCreateGeneralActivity, clientUserDetail };
}

export default withRouter(connect(mapStateToProps, {
    onSelectEmail,
    markAsStarEmail,
    showActivityHistoryDetail,
    showActivityConfirmation,
    getHistoryList,
    getSelectedActivity,
    setActivityReminder,
    deletePrivateActivity,
    selectActivity,
    getActivitiesList,
    StartActivity,
    ResumeActivity,
    setActivityType, selectActivityId,
    getLoading,
    getChartsList,
    clearHistoryData,
    handleShowActivityDetailsPanel,
    setActivityReset,
    adjustAchievementTimer,
    executeActivity,
    getClientPokeForm,
    deleteGeneralActivity,
    assingCommonActivityToClient
})(UserAllActivitiesList));