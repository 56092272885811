// activities widget actions
export const CREATE_ACTIVITY = "create_activity";

// courses widget actions
export const OPEN_LMS_COURSE = "open_lms_course";

// clients widget actions
export const OPEN_ASSIGN_ACTIVITY = "open_assign_activity";
export const OPEN_CLIENT_CHAT = "open_client_chat";
export const OPEN_UNCONFIRMED_ACHIEVEMENTS = "open_unconfirmed_achievements";
export const OPEN_CLIENT_DETAILS = "open_client_details"

// activities widget actions
export const ACTIVITY_PREVIEW = "activity_preview";
export const OPEN_ACTIVITY_HISTORY = "activity_history";
export const OPEN_EDIT_ACTIVITY = "edit_activity";
export const EXECUTE_ACTIVITY = "execute_activity";
export const RESUME_ACTIVITY = "resume_activity";



// dashboard-summay widget urls

export const OPEN_LAUNCHPAD_URL = "open_lauchpad_url";
export const TOUCHPOINT_ACTIVITIES = "touchpoint_activities";
export const AGENDA_VIEW_ACTIVITY = "agenda_view_activity"


// Authentication actions
export const ID_TOKEN_EXPIRED = "id_token_expired"


// Bulletin Posts
export const DOWNLOAD_FROM_URL = "donwload_from_url"

// Notices

export const CLIENT_ALERTS = "open_client_alerts"
export const SHARE_CLIENT_NOTICE = "share_client_notice"
export const OPEN_CLIENT_NOTICE_PDF = "open_client_notice_pdf"