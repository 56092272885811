import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LifeSherpaLoading from '../../../components/LifeSherpaLoading';
import {
	getUsersNotActiveOnWorkflows,
	handleUserWorkflowPanel,
	getWorkflowNotices,
	getUserPendingWorkflowList
} from '../../../actions'
import { connect } from 'react-redux';

class WorkflowDashboard extends Component {

	componentDidMount() {
		const orgId = localStorage.getItem("orgId")
		const auth_uid = localStorage.getItem("auth_uid")
        this.props.getUsersNotActiveOnWorkflows(orgId)
		this.props.getWorkflowNotices(orgId)
		this.props.getUserPendingWorkflowList({userId: auth_uid, orgId})
	}

	handleWorkflow = () => {
		const userId = localStorage.getItem("auth_uid")
		this.props.handleUserWorkflowPanel(true, userId)
	}

	getScrollHeight() {
		if (localStorage.getItem("widgetApp")) {
			return 'calc(100vh - 0px)';
		} else {
			return '630px';
		}
	}
	
	render() {
		const { userRole, noActiveWorkflowLoading, userHasNoActiveWorkflow, workflowNotices, userWorkflows, userWorkflowLoading, widgetVersion} = this.props;
        
		return (
			<div>
				<Scrollbars
					className="rct-scroll "
					autoHide
					style={{ height: this.getScrollHeight() }}
				>
					<div data-testid="workflow_dashbaord_summary_panel" className=" dashboard-summary-panal-content lifesherpa-session-theme-support">
						<LifeSherpaLoading loading={noActiveWorkflowLoading || userWorkflowLoading} />
                        <div data-testid="workflow_notices_container" className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2">
							<h3 className="d-flex  mx-2 my-2 heading-text">Workflow Notices</h3>
							<hr className="mt-1 mb-2" />
							<div data-testid="workflow_completed_container" className="d-flex align-items-center">
								<span className="badge-secondary px-2 mx-2 my-auto" data-testid="workflow-completed-count" >{(workflowNotices && workflowNotices.completed) || 0}</span>
								<div className="heading-text">Workflows Completed</div>
							</div>
							<hr className="my-2" />
							<div data-testid="workflow_open_container" className="d-flex align-items-center">
								<span className="badge-success px-2 mx-2 my-auto" data-testid="workflow-open-count">{(workflowNotices && workflowNotices.open) || 0}</span>
								<div className="heading-text">Workflows Open</div>
							</div>
							<hr className="my-2" />
							<div data-testid="workflow_cancelled_container" className="d-flex align-items-center">
								<span className="badge-danger px-2 mx-2 my-auto" data-testid="workflow-cancelled-count">{(workflowNotices && workflowNotices.cancelled) || 0}</span>
								<div className="heading-text">Workflows Cancelled</div>
							</div>
							{!widgetVersion && 
							<>
								<hr className="my-2" />
								<div data-testid="workflow_tasks_awaiting_completion_container" className="d-flex align-items-center justify-content-between cps" onClick={() => this.handleWorkflow()}>
									<div className='d-flex align-items-center'>
										<span className="badge-orange px-2 mx-2 my-auto" data-testid="workflow-awaiting-task-count">{userWorkflows ? userWorkflows.count : 0}</span>
										<div className="heading-text">Tasks Awaiting Completion in Workflows</div>
									</div>
									<ArrowRightIcon/>
								</div>
							</>
							}
                            <hr className="mt-1 mb-2" />
                            <div data-testid="applicants_without_active_workflow_container" className="d-flex align-items-center">
                                <span className="badge-primary px-2 mx-2 my-auto" data-testid="applicant-without-active-workflow-count" >{userHasNoActiveWorkflow && userHasNoActiveWorkflow.count}</span>
                                <div className="heading-text">Applicants not having any active workflow</div>
                            </div>
						</div>
					</div>
				</Scrollbars>
			</div>
		);
	}
}
const mapStateToProps = ({ authUser, workflowData }) => {
	const { userRole } = authUser;
	const { noActiveWorkflowLoading,userHasNoActiveWorkflow, workflowNotices, userWorkflows, userWorkflowLoading} = workflowData
	return {userRole,userHasNoActiveWorkflow, noActiveWorkflowLoading, workflowNotices, userWorkflows, userWorkflowLoading };
}

export default withRouter(connect(mapStateToProps, {
	getUsersNotActiveOnWorkflows,
	handleUserWorkflowPanel,
	getWorkflowNotices,
	getUserPendingWorkflowList
})(WorkflowDashboard));