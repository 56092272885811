import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import "../../assets/scss/custom/_activitypreview.scss"
import { getLoading, UpdateSummeryList } from "../../actions"
import LandingPageAllItem from './LandingPageAllItem';
import IconButton from '@mui/material/IconButton';
import {database} from '../../firebase';
import { ref, onValue, orderByChild, query, equalTo, off} from "firebase/database"

class LandingpageAgendaView extends Component {
    state = {
        show: false,
        index: -1
    }

    componentDidMount() {
        let organization = localStorage.getItem('orgId')
        let uid = localStorage.getItem('auth_uid');
        const refValue = ref(database, `Realtime/${organization}/Users/${uid}/achievementsInProgress`)
        const topRef = query(refValue, orderByChild('preview'), equalTo(false))
        const listenerValue = onValue(topRef, (snapshot) => {
            const realtimeData = snapshot.val();
            console.log("Real time all activities data at component mount ", realtimeData);
            let inProgressList = []
            realtimeData && Object.keys(realtimeData).length > 0 ? Object.keys(realtimeData).map((dateObjKey, key) => {
                realtimeData[dateObjKey].achievementId = dateObjKey
                inProgressList.push(realtimeData[dateObjKey])

            }) : "";
            this.updateActivitiesSummary(inProgressList)
        })
        this.setState({listenerValue:listenerValue})
    }
    componentWillUnmount() {
        let organization = localStorage.getItem('orgId')
        let uid = localStorage.getItem('auth_uid');
        const {listenerValue} = this.state;
        console.log("listenerValue -->", listenerValue);
        const removeListener = ref(database, `Realtime/${organization}/Users/${uid}/achievementsInProgress`)
        off(removeListener)
    }

    updateActivitiesSummary = (inProgressList) => {
        let scheduled = this.props.activitySummery && this.props.activitySummery.scheduled ? this.props.activitySummery.scheduled:[];
        let unscheduled = this.props.activitySummery && this.props.activitySummery.unscheduled ? this.props.activitySummery.unscheduled :[];
        scheduled.map((schedule, index) => {
            let activity = schedule.activity || null; 
            if(activity) {
                activity.state = '';
                activity.nextStep = null;
                activity.completed = 0;
                schedule.activity = activity
            }
        })
        unscheduled.map((schedule, index) => {
            let activity = schedule.activity || null; 
            if(activity) {
                activity.state = '';
                activity.nextStep = null;
                activity.completed = 0;
                schedule.activity = activity
            }
        })
        scheduled.map(schedule => {
            let activity = schedule.activity; 
            if(activity) {
                let add = {}
                inProgressList.find((li) => {
                    if (li.routineId === activity.id && (li.achievementId === schedule.achievementId || li.scheduleKey === schedule.scheduleKey)) {
                        add.count = li.stepsCount
                        add.state = li.state 
                        let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                        let stepsCompletionList = [];
                        if (stepsCompletion) {
                            Object.keys(stepsCompletion).map((stepCompId, key) => {
                                stepsCompletionList.push(stepsCompletion[stepCompId]);
                            });
                            stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped")
                        }
                        add.completed = stepsCompletionList.length;
                        if (add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                            add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                        }

                        add.achievementId = li.achievementId;
                        if (li.routine && li.routine.activityGroupId) {
                            add.activityGroupId = li.routine.activityGroupId
                        }
                        activity = { ...activity, ...add }
                    }
                })
            } 
            schedule.activity = activity
        })
        unscheduled.map(schedule => {
            let activity = schedule.activity || null; 
            if(activity) {
                let add = {}
                inProgressList.find((li) => {
                    if (li.routineId === activity.id) {
                        add.count = li.stepsCount
                        add.state = li.state 
                        let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                        let stepsCompletionList = [];
                        if (stepsCompletion) {
                            Object.keys(stepsCompletion).map((stepCompId, key) => {
                                stepsCompletionList.push(stepsCompletion[stepCompId]);
                            });
                            stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped")
                        }
                        add.completed = stepsCompletionList.length;
                        if (add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                            add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                        }

                        add.achievementId = li.achievementId;
                        if (li.routine && li.routine.activityGroupId) {
                            add.activityGroupId = li.routine.activityGroupId
                        }
                        activity = { ...activity, ...add }
                    }
                })
            }
            schedule.activity = activity
        })
        let summary={scheduled:scheduled,unscheduled:unscheduled}
        this.props.UpdateSummeryList(summary)
        this.setState({update: true})
    }


    selectActivity = (value) => {
        let { index } = this.state
        if (index === value) this.setState({ index: -1 })
        else this.setState({ index: value })
    }

    render() {
        const {activitySummery, organizations } = this.props
        const orgId = localStorage.getItem("orgId")
        let scheduled = [];
        let unscheduled = [];
        if (activitySummery && (activitySummery.scheduled || activitySummery.unscheduled)) {
            scheduled = activitySummery.scheduled ? activitySummery.scheduled : []
            unscheduled = activitySummery.unscheduled ? activitySummery.unscheduled : []
        } else {
            return (
                <div data-testid="agendaview-activity-empty-list" className="d-flex justify-content-center align-items-center py-50">
                    <h4>Activities Summary Not Found</h4>
                </div>
            )
        }
        return (
            <div data-testid="landingpage-agendaview-activity-container">
                {scheduled.map((act, key) => (
                    <div data-testid={"agendaview-scheduled-activity-"+key} key={key} className="w-100 justify-content-between summary-list-item lifesherpa-session-theme-support list-item pl-3 agenda-view-list-item">
                        <div className="d-flex justify-content-between align-items-center">
                            {act.activity.newSchedule && act.activity.newSchedule !== '' ?
                                <div className="w-60 heading-text"  >
                                    {act.startTime + ' - ' + act.endTime}
                                </div>
                                :
                                <span className="w-60 heading-text" ></span>
                            }
                            <div className="mx-auto ml-auto" style={{ color: act.statusColor }}>{act.statusText}</div>
                            <IconButton className="p-1" title={this.state.index === key ? "Expend Less": "Expend More"} onClick={() => this.selectActivity(key)}>{this.state.index === key ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</IconButton>
                        </div>
                        <h4 className="mb-0 heading-text mb-2" >{act.activity.title}</h4>
                        <div className="pr-3 pb-1">
                            {this.state.index === key ?
                                <LandingPageAllItem
                                    key={key}
                                    allActivities={this.props.allActivities}
                                    activity={{...act.activity, scheduleKey: act.scheduleKey}}
                                    editScheduleKey={this.props.editScheduleKey}
                                    selectedMenuActivity={this.props.selectedMenuActivity}
                                    handleOpenHistory={() => this.props.handleShowActivityHistoryList()}
                                    itemKey={key}
                                    anchorEl={this.props.anchorEl}
                                    calenderBox={this.state.calenderBox}
                                    handleClickMenu={(e) => this.props.handleClickMenu(e, key, {organization: act.organization, ...act.activity})}
                                    handleCloseMenu={() => this.props.handleCloseMenu()}
                                    handleReminderButton={() => this.props.handleReminderButton({organization: act.organization, ...act.activity})}
                                    handleCalenderButton={() => this.props.handleCalenderButton({organization: act.organization, ...act.activity}, key)}
                                    handleDeletePop={() => this.props.handleDeletePop()}
                                    handlePreviewDialod={(e) => this.props.handlePreviewDialod()}
                                    handleShowDetails={(e) => this.props.handleShowDetails()}
                                    EditActivity={(e) => this.props.EditActivity()}
                                    handleStartActivity={(e) => this.props.handleStartActivity()}
                                    handleExecuteActivityFromOption={(e) => this.props.handleExecuteActivityFromOption()}
                                    handleResumeActivity={(e) => this.props.handleResumeActivity({organization: act.organization, ...act.activity}, act.scheduleKey)}
                                    handleStartActivity1={(e) => this.props.handleStartActivity1(key)}
                                    handleExecuteActivity={(e) => this.props.handleExecuteActivity({organization: act.organization, ...act.activity}, act.scheduleKey)}
                                    hideRewards={this.props.hideRewards}
                                    showStartOrResumeButton={act.status !== "expired" && act.status !== "cancelled" && act.status !== "completed" && act.status !== "incomplete"}
                                    organization={organizations ? organizations.find(org => org.id === (act.organization || orgId)): null}
                                />
                                : ''
                            }
                        </div>

                    </div>
                ))
                }
                {unscheduled && unscheduled.length > 0 &&
                    <div data-testid="agendaview-unscheduled-activity" key={this.props.itemKey} className="w-100 justify-content-between summary-list-item lifesherpa-session-theme-support py-2 list-item pl-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="w-60 cps"  onClick={() => this.setState({show: !this.state.show })}>
                                <h4 className="my-2 heading-text" >Unscheduled Activities</h4>
                            </div>
                            <IconButton className="p-1" title={this.state.show ? "Expend Less": "Expend More"} onClick={() => this.setState({ show: !this.state.show })}>{this.state.show ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</IconButton>
                        </div>
                        <div className="pr-3 pb-1">
                            {this.state.show ? unscheduled.map((act, key) => (
                                <LandingPageAllItem
                                    key={key}
                                    allActivities={this.props.allActivities}
                                    activity={act.activity}
                                    selectedMenuActivity={this.props.selectedMenuActivity}
                                    editScheduleKey={this.props.editScheduleKey}
                                    handleOpenHistory={() => this.props.handleShowActivityHistoryList()}
                                    itemKey={key}
                                    anchorEl={this.props.anchorEl}
                                    calenderBox={this.state.calenderBox}
                                    handleClickMenu={(e) => this.props.handleClickMenu(e, key, {organization: act.organization, ...act.activity})}
                                    handleCloseMenu={() => this.props.handleCloseMenu()}
                                    handleReminderButton={() => this.props.handleReminderButton({organization: act.organization, ...act.activity})}
                                    handleCalenderButton={() => this.props.handleCalenderButton({organization: act.organization, ...act.activity}, key)}
                                    handleDeletePop={() => this.props.handleDeletePop()}
                                    handlePreviewDialod={(e) => this.props.handlePreviewDialod()}
                                    handleShowDetails={(e) => this.props.handleShowDetails()}
                                    EditActivity={(e) => this.props.EditActivity()}
                                    handleStartActivity={(e) => this.props.handleStartActivity()}
                                    handleExecuteActivityFromOption={(e) => this.props.handleExecuteActivityFromOption()}
                                    handleResumeActivity={(e) => this.props.handleResumeActivity({organization: act.organization, ...act.activity})}
                                    handleStartActivity1={(e) => this.props.handleStartActivity1(key)}
                                    handleExecuteActivity={(e) => this.props.handleExecuteActivity({organization: act.organization, ...act.activity})}
                                    hideRewards={this.props.hideRewards}
                                    showStartOrResumeButton={true}
                                    organization={organizations ? organizations.find(org => org.id === (act.organization || orgId)): null}
                                />
                            ))
                                : ''
                            }
                        </div>

                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ activitiesList, clientsList }) => {
    const { activitySummery } = activitiesList
    const {organizations} = clientsList
    return { activitySummery, organizations }
}

export default withRouter(connect(mapStateToProps, {
    getLoading, UpdateSummeryList
})(LandingpageAgendaView));