/**
 * Helpers Functions
 */
import moment from 'moment';
import { firebase, auth, database } from "../firebase";
import {ref, child, push} from "firebase/database"
import {onIdTokenChanged, getIdToken, signInWithCustomToken} from 'firebase/auth'
import jwt from 'jwt-decode';
import {getFirebaseCustomToken, getTwilioChatToken, getClientChatRoomDetails, logoutCallbackForAuth0Expired} from "../actions";
import config from '../config/config';
import { v4 as uuidv4 } from 'uuid';

export const getPushkey = () => {
    const newPostKey = push(child(ref(database), 'APIRetryLog')).key;
    return newPostKey
}

const axiosRetry = require("axios-retry");

import { axiosClient } from '../sherpaApi';

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}


/**
 * Function to return current app layout
 */




/**
 * Helpers Functions
 */
 
 import { RRule, RRuleSet, rrulestr } from 'rrule'
 
 /**
  * Function to convert hex to rgba
  */
 
 
 /**
  * Text Truncate
  */
 
 
 /**
  * Get Date
  */

 
 /**
  * Convert Date To Timestamp
 */

 
 /**
  * Function to return current app layout
  */
 
 export function getAppLayout(url) {
    let location = url.pathname;
    let path = location.split('/');
    console.log("getAppLayout",path)
    if(path[1]==='user') return "app"
    else return path[1];
}
 /**
  * Function to return rrule
  */
 export function  generateRRule(recurrenceText, rrulroptions, currentSchedule) {
     const date = currentSchedule.startDate.toString()
     const time = currentSchedule.objStartTime.format('HH:mm').toString()
     const dateStr = getDateAndTime(date, time)
     const weekday = getWeekDay(currentSchedule.startDate)
     const weekDayStart = weekday.substring(0, 2).toUpperCase()
     const rruleDic = {
         'rrule': '',
         'recurrenceMethod': '',
         'text': '',
         "nextSchedules": []
     }
 
     switch(recurrenceText) {
         case "Does not repeat":
             // code block
             // Create a rule:
             const norepeatRRule = new RRule({
                 freq: RRule.DAILY,
                 interval: 1,
                 count: 1,
                 dtstart: new Date(dateStr),
                 byweekday: [RRule[weekDayStart]],
             })
             rruleDic.rrule = norepeatRRule.toString()
             rruleDic.recurrenceMethod = 'norepeat'
             rruleDic.text = `Once at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate.toString()}`
             break;
         case "Daily":
             // Create a rule:
             const dailyRRule = new RRule({
                 freq: RRule.DAILY,
                 interval: 1,
                 count: 1,
                 dtstart: new Date(dateStr),
             })
             rruleDic.rrule = dailyRRule.toString()
             rruleDic.recurrenceMethod = 'days'
             rruleDic.text = `Daily until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate.toString()}`
             break;
         case `Monthly on the ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)}`:
             // Create a rule:
             const occurences = getDayOccurences(currentSchedule.startDate)
             const dayOfMonthRRule = new RRule({
                 freq: RRule.MONTHLY,
                 interval: 1,
                 count: 1,
                 dtstart: new Date(dateStr),
                 bysetpos: occurences === 'First' ? 1 : occurences === 'Second' ? 2 : occurences === 'Third' ? 3 : occurences === 'Fourth' ? 4 : 5,
                 byweekday: [RRule[weekDayStart]],
             })
             rruleDic.rrule = dayOfMonthRRule.toString()
             rruleDic.recurrenceMethod = 'dayOfMonth'
             rruleDic.text = `Monthly on the ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)} until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             break;
         case `Monthly on day ${getDateOfMonth(currentSchedule.startDate)}`:
             // Create a rule:
             const monthDate = getDateOfMonth(currentSchedule.startDate)
             const dateOfMonthRRule = new RRule({
                 freq: RRule.MONTHLY,
                 interval: 1,
                 count: 1,
                 dtstart: new Date(dateStr),
                 bymonthday: monthDate,
             })
             rruleDic.rrule = dateOfMonthRRule.toString()
             rruleDic.recurrenceMethod = 'dateOfMonth'
             rruleDic.text = `Monthly on day ${getDateOfMonth(currentSchedule.startDate)} until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             break;
         case `Every ${getMonthName(currentSchedule.startDate)} on ${getDateOfMonth(currentSchedule.startDate)} (Annually)`:
             // Create a rule:
             const mDay = getMonthOfYear(currentSchedule.startDate)
             const mdate = getDateOfMonth(currentSchedule.startDate)
             const yearlyRRule = new RRule({
                 freq: RRule.YEARLY,
                 interval: 1,
                 count: 1,
                 dtstart: new Date(dateStr),
                 bymonth: mDay,
                 bymonthday: mdate,
             })
             rruleDic.rrule = yearlyRRule.toString()
             rruleDic.recurrenceMethod = 'years'
             rruleDic.text = `Every ${getMonthName(currentSchedule.startDate)} on the ${mdate}th (Annually) until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             break;
         case `Every day on ${getWeekDay(currentSchedule.startDate)}`:
             // Create a rule:
             const weekdayRRule = new RRule({
                 freq: RRule.DAILY,
                 interval: 1,
                 count: 1,
                 dtstart: new Date(dateStr),
                 byweekday: [RRule[weekDayStart]],
             })
             rruleDic.rrule = weekdayRRule.toString()
             rruleDic.recurrenceMethod = 'weekday'
             rruleDic.text = `Every day on ${getWeekDay(currentSchedule.startDate)} until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             break;
         case 'Every Weekday (Monday to Friday)':
             // Create a rule:
             const weeksRRule = new RRule({
                 freq: RRule.WEEKLY,
                 interval: 1,
                 count: 1,
                 byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
                 dtstart: new Date(dateStr),
             })
             rruleDic.rrule = weeksRRule.toString()
             rruleDic.recurrenceMethod = 'weeks'
             rruleDic.text = `Every Weekday (Monday to Friday) until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             break;
         case 'Custom':
            // Create a rule:
             const customRRule = new RRule({
                 freq: RRule.DAILY,
                 interval: 1,
                 dtstart: new Date(dateStr),
             })
             rruleDic.rrule = customRRule.toString()
             rruleDic.recurrenceMethod = 'custom'
             rruleDic.text = `${customRRule.toText()} until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             break;
         default:
             let options = rrulroptions
             options['dtstart'] = new Date(dateStr)
             const defaultRRule = new RRule(options)
             const rruleText = defaultRRule.toText()
             rruleDic.rrule = defaultRRule.toString()
             rruleDic.recurrenceMethod = 'custom'
             if (rruleText.includes('until')){
                 rruleDic.text = `${rruleText} at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             } else {
                 rruleDic.text = `${rruleText} until forever at ${currentSchedule.startTime.toString()} start on ${currentSchedule.startDate}`
             }
       }
 
       return rruleDic
    }
 
    /**
      * Function to return day occurence
      */
     export function getDayOccurences(date) {
         // const dateIn2digit  = moment(date).format('D')
         const dateIn2digit = getDateOfMonth(date);
         var quotient = Math.floor(dateIn2digit/7)
         var remainder = dateIn2digit % 7;
         if (remainder !== 0){
             if (quotient === 0) {
                 return 'First'
             } else if (quotient === 1) {
                 return 'Second'
             } else if (quotient === 2) {
                 return 'Third'
             } else if (quotient === 3) {
                 return 'Fourth'
             }else if (quotient === 4) {
                 return 'Fifth'
             }
         } else {
             if (quotient === 1) {
                 return 'First'
             } else if (quotient === 2) {
                 return 'Second'
             } else if (quotient === 3) {
                 return 'Third'
             } else if (quotient === 4) {
                 return 'Fourth'
             } else if (quotient === 5) {
                 return 'Fifth'
             }
         } 
     }
     /**
      * Function to return Month occurence
      */
     export function getMonthOccurence(currentSchedule, option){
         if (option.split('=')[0] === 'BYSETPOS') {
             return `Monthly on ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)}`
         } else if (option.split('=')[0] === 'BYMONTHDAY' && option.split('=')[1] === '-1') {
             return 'Last Day of Month'
         } else {
             return `Monthly on day ${getDateOfMonth(currentSchedule.startDate)}`
         }
     }
 
     /**
      * Function to return Week occurence
      */
     export function parseCustomWeekDay(weekDay, currentValue){
         let weekDayWithCheck = currentValue
         weekDayWithCheck[0].checked = false
         const days = weekDay.split('=')[1].split(',')
         days.map((day, key) => {
             if (day === 'MO') {
                 weekDayWithCheck[0].checked = true
             } else if (day === 'TU') {
                 weekDayWithCheck[1].checked = true
             } else if (day === 'WE') {
                 weekDayWithCheck[2].checked = true
             } else if (day === 'TH') {
                 weekDayWithCheck[3].checked = true
             } else if (day === 'FR') {
                 weekDayWithCheck[4].checked = true
             } else if (day === 'SA') {
                 weekDayWithCheck[5].checked = true
             } else if (day === 'SU') {
                 weekDayWithCheck[6].checked = true
             }
         })
         return weekDayWithCheck
     }
 
      /**
      * Function to return freq occurence
      */
     export function parseCustomFreq(freq){
         if(freq.split('=')[1] === 'DAILY'){
             return {freq: 'Day', weekSelected: false, monthSelected: false}
         } else if(freq.split('=')[1] === 'WEEKLY') {
             return {freq: 'Week', weekSelected: true, monthSelected: false}
         } else if(freq.split('=')[1] === 'MONTHLY') {
             return {freq: 'Month', weekSelected: false, monthSelected: true}
         } else {
             return {freq: 'Year', weekSelected: false, monthSelected: false}
         }
     }
 
     /**
      * Function to return fresh schedule object
      */
     export function refreshCurrentSchedule(){
         const currentSchedule =  {
             startDate: moment().format('MMM DD YYYY'),
             startTime: moment().format('hh:mm A'),
             endTime: moment().format('hh:mm A'),
             text: "Daily",
             recurrenceMethod: '',
             rrule: '',
             objStartDate: moment(),
             objStartTime: moment(),
             objEndTime: moment().add(5, 'minutes'),
             nextSchedules: []
         }
         const rruleDic =  generateRRule('Daily', null, currentSchedule)
         currentSchedule['rrule'] = rruleDic.rrule
         currentSchedule['text'] = rruleDic.text
         currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
         return currentSchedule
      }
 
     /**
      * Function to return fresh schedule object
      */
     export function handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, classRef) {
         let currentSchedule = classRef.state.currentSchedule
         const monthDate = getDateOfMonth(classRef.state.currentSchedule.startDate)
         const occurences = getDayOccurences(classRef.state.currentSchedule.startDate)
         const monthDay = getMonthOfYear(classRef.state.currentSchedule.startDate)
         const weekDayStart = getWeekDay(classRef.state.currentSchedule.startDate).substring(0, 2).toUpperCase()
         let options = {}
         let ends = repeatType.filter(item => item.selected)
         if(ends[0].type === 'On' && ends[0].selected) {
             options['until'] = new Date(customUntill) //.toISOString()
         } else if (ends[0].type === 'After' && ends[0].selected) {
             options['count'] = customCount
         }
         if (customRepeatValue === 'Week') {
             options['freq'] =  RRule.WEEKLY 
             options['interval'] = customInterval
             let byweekday =  []
             weekDayWithCheck.map((item, key) => {
                 if (item.checked) {
                     byweekday.push(RRule[item.day])
                 }
             })
             options['byweekday'] = byweekday
             const rruleDic =  generateRRule('CustomWeek', options, classRef.state.currentSchedule)
             currentSchedule['text'] = rruleDic.text
             currentSchedule['rrule'] = rruleDic.rrule
             currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
             currentSchedule['nextSchedules'] = rruleDic.nextSchedules
             classRef.setState({weekSelected: true, monthSelected: false, currentSchedule, repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError})
         } else if (customRepeatValue === 'Month') {
             options['freq'] =  RRule.MONTHLY 
             options['interval'] = customInterval
             if (monthlyOccurenceDay === '' || monthlyOccurenceDay === `Monthly on day ${getDateOfMonth(classRef.state.currentSchedule.startDate)}`) {
                 options['bymonthday'] = monthDate
             } else if (monthlyOccurenceDay === `Monthly on ${getDayOccurences(classRef.state.currentSchedule.startDate)} ${getWeekDay(classRef.state.currentSchedule.startDate)}`) {
                 options['bysetpos'] = occurences === 'First' ? 1 : occurences === 'Second' ? 2 : occurences === 'Third' ? 3 : occurences === 'Fourth' ? 4 : 5
                 options['byweekday'] = [RRule[weekDayStart]]
             } else {
                 options['bymonthday'] = -1
             }
             const rruleDic =  generateRRule('CustomMonth', options, classRef.state.currentSchedule)
             currentSchedule['text'] = rruleDic.text
             currentSchedule['rrule'] = rruleDic.rrule
             currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
             currentSchedule['nextSchedules'] = rruleDic.nextSchedules
             classRef.setState({weekSelected: false, monthSelected: true, currentSchedule, repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError})
          } else if (customRepeatValue === 'Year') {
             options['freq'] =  RRule.YEARLY 
             options['interval'] = 1
             options['bymonth']= monthDay
             options['bymonthday'] = monthDate
             const rruleDic =  generateRRule('CustomYear', options, classRef.state.currentSchedule)
             currentSchedule['text'] = rruleDic.text
             currentSchedule['rrule'] = rruleDic.rrule
             currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
             currentSchedule['nextSchedules'] = rruleDic.nextSchedules
             classRef.setState({weekSelected: false, monthSelected: false, currentSchedule, repeatType, customRepeatValue, customCount, customInterval: 1, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError})
         } else {
             options['freq'] =  RRule.DAILY 
             options['interval'] = customInterval
             const rruleDic =  generateRRule('CustomDay', options, classRef.state.currentSchedule)
             currentSchedule['text'] = rruleDic.text
             currentSchedule['rrule'] = rruleDic.rrule
             currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
             currentSchedule['nextSchedules'] = rruleDic.nextSchedules
             classRef.setState({weekSelected: false, monthSelected: false, currentSchedule, repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError})
          }
     }
 
     /**
      * Function to return name of week day 
      */
     export function getWeekDay(date){
         const gsDayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
         const dateObj = new Date(date)
         return gsDayNames[dateObj.getDay()]
     }
 
     /**
      * Function to return date of month in digit 
      */
     export function getDateOfMonth(date){
         const dateObj = new Date(date)
         return dateObj.getDate();
     }
 
     /**
      * Function to return Name of month of Year
      */
     export function getMonthName(date) {
         const monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"]
         const dateObj = new Date(date)
         return monthNames[dateObj.getMonth()]
     }
 
     /**
      * Function to return Name of month of Year
      */
     export function getMonthOfYear(date) {
         const dateObj = new Date(date)
         return dateObj.getMonth()
     }
 
     /**
      * Function to return date and time
      */
     export function getDateAndTime(date, time) {
         var dateObj = new Date(date)
         const hours = parseInt(time.split(":")[0])
         const minutes = parseInt(time.split(":")[1])
         dateObj.setHours(hours)
         dateObj.setMinutes(minutes)
         return dateObj
     }
 
     /**
      * Function to return fresh week day
      */
     export function getweekDayWithCheck() {
         const weekDay =  [
             {
                 day: 'MO',
                 checked: true,
                 name: 'MON'
             },
             {
                 day: 'TU',
                 checked: false,
                 name: 'TUE'
             },
             {
                 day: 'WE',
                 checked: false,
                 name: 'WED'
             },
             {
                 day: 'TH',
                 checked: false,
                 name: 'THU'
             },
             {
                 day: 'FR',
                 checked: false,
                 name: 'FRI'
             },
             {
                 day: 'SA',
                 checked: false,
                 name: 'SAT'
             },
             {
                 day: 'SU',
                 checked: false,
                 name: 'SUN'
             },
         ]
         return weekDay
     }
 
     /**
      * Function to return fresh week day
      */
     export function getISODateString(date) {
        return date ? new Date(date).toISOString() : new Date().toISOString()
     }
 
      /**
      * Function to return cookies
      */
     export function getCookies(name) {
         //const data = {"serId":"Xyz","idToken":"dsncbnCVXVCXVZCZCVXCVBJHHGascgcgXZCoVcZXCHGhggCXZCgcCZX","orgId":"Test","activityId":"xyz","theme":"theme2"}
        // document.cookie = `mycookie=${JSON.stringify(data)}`
         const value = `; ${document.cookie}`;
         const parts = value.split(`; ${name}=`);
         //console.log('cache value-->',value)
         if (parts.length === 2) return parts.pop().split(';').shift();
       }
 
     /**
      * Function to return truncated string
      */
     export function ellipsify (str) {
         let newstring = JSON.parse(JSON.stringify(str))
         if (newstring.length > 80) {
             return (newstring.substring(0, 80) + "...");
         }
         else {
             return newstring;
         }
     }

     export function getFakeProfile(email){
        const fakeProfile = {
            "displayName": email.split('@')[0],
            "hash": "b462915e02ea11b6285721e5dc9b8e5f",
            "id": "188659808",
            "name": [],
            "photos": [{value: "https://secure.gravatar.com/avatar/b462915e02ea11b6285721e5dc9b8e5f", type: "thumbnail"}],
            "preferredUsername": email.split('@')[0],
            "profileUrl": `http://gravatar.com/${email.split('@')[0]}`,
            "requestHash": email,
            "thumbnailUrl": "https://secure.gravatar.com/avatar/b462915e02ea11b6285721e5dc9b8e5f",
            "urls": []
        }
        return fakeProfile
    }

    export async function requireSignIn() {
        console.time("checkLogin");
        await new Promise((resolve, reject) => {
        const unsubscribe = onIdTokenChanged(auth, function(firebaseUser) {
            if(unsubscribe) {
                unsubscribe();
            }
            resolve();
        })});
        const user = auth.currentUser || null;
        if ( !user) {
            console.log('user Is not logged In rejecting');
            throw("AUTH_REQUIRED");  
        }
    }

    export function scrollFunction(elementId) {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			document.getElementById(elementId).style.top = "0";
		} else {
			document.getElementById(elementId).style.top = "-50px";
		}
    }
    
    export function scrollFunctionByClass(classname) {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			document.getElementsByClassName(classname).style.top = "0";
		} else {
			document.getElementsByClassName(classname).style.top = "-50px";
		}
    }
    
    //Firebase Wrapper
    export function FirebaseWrapper({firebaseClient, getFirebaseCustomToken, logoutCallback}){
        const references = [];
        let tokenUpdatePromise;
        let lastTokenUpdated = Date.now();

        function Reference(path) {
            this._ref = firebaseClient.database().ref(path);
        }
        
        Reference.prototype = {
            once: function(eventType) {
                return this._ref.once(eventType)
                    .catch(async error => {
                        try {
                            if (error.code === "PERMISSION_DENIED") {
                                const reAuthenticated = await reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken);
                                if (reAuthenticated) {
                                    console.log("retrying request", this._ref.toString());
                                    return this.once(eventType);
                                }
                            }
                        } catch (error) {
                            logoutCallback(false, error.message);
                        }
                        throw error;
                    });
            },
            on: function(eventType, onSuccess, onError) {
                const that = this;
    
                function customOnFn(eventType, onSuccess, onError) {
                    return that._ref.on(eventType, onSuccess, async (error) => {
                        try {
                            if (error.code === "PERMISSION_DENIED") {
                                try{
    
                                    const reAuthenticated = await reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken);
                                    if (reAuthenticated) {
                                        console.log("restarting on listener");
                                        customOnFn(eventType, onSuccess, onError);
                                        return;
                                    }
                                } catch(error){
                                    logoutCallback(false, error.message);
                                }
                            }
                        } catch (err) {
                            console.error(err);
                        }
                        if (onError) {
                            onError(error);
                        }
                    });
                }
    
                references.push(this._ref);
                return customOnFn(eventType, onSuccess, onError);
            },
            off: function(val) {
                return this._ref.off(val);
            },
            set: function(val) {
                return this._ref.set(val)
                    .catch(async error => {
                        try {
                            if (error.code === "PERMISSION_DENIED") {
                                const reAuthenticated = await reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken);
                                if (reAuthenticated) {
                                    console.log("retrying request", this._ref.toString());
                                    return this.set(val);
                                }
                            }
                        } catch (error) {
                            logoutCallback(false, error.message);
                        }
                        throw error;
                    });
            },
            update: function(val) {
                return this._ref.update(val)
                    .catch(async error => {
                        try {
                            if (error.code === "PERMISSION_DENIED") {
                                const reAuthenticated = await reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken);
                                if (reAuthenticated) {
                                    console.log("retrying request", this._ref.toString());
                                    return this.update(val);
                                }
                            }
                        } catch (error) {
                            logoutCallback(false, error.message);
                        }
                        throw error;
                    });
            },
            remove: function() {
                return this._ref.remove()
                    .catch(async error => {
                        try {
                            if (error.code === "PERMISSION_DENIED") {
                                const reAuthenticated = await reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken);
                                if (reAuthenticated) {
                                    console.log("retrying request", this._ref.toString());
                                    return this.remove();
                                }
                            }
                        } catch (error) {
                            logoutCallback(false, error.message);
                        }
                        throw error;
                    });
            },
            transaction: function(callback, fn, flag) {
                return this._ref.remove(callback, fn, flag);
            },
            push: function(val) {
                if(typeof val === "undefined"){
                    return this._ref.push();
                }
                return this._ref.push(val)
                    .catch(async error => {
                        try {
                            if (error.code === "PERMISSION_DENIED") {
                                const reAuthenticated = await reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken);
                                if (reAuthenticated) {
                                    console.log("retrying request", this._ref.toString());
                                    return this.push(val);
                                }
                            }
                        } catch (error) {
                            logoutCallback(false, error.message);
                        }
                        throw error;
                    });
            },
            child: function(val){
                this._ref = this._ref.child(val);
                return this;
            },
            orderByKey: function() {
                this._ref = this._ref.orderByKey();
                return this;
            },
            orderByChild: function(val) {
                this._ref = this._ref.orderByChild(val);
                return this;
            },
            orderByValue: function() {
                this._ref = this._ref.orderByValue();
                return this;
            },
            isEqual: function(val) {
                this._ref = this._ref.isEqual(val);
                return this;
            },
            limitToLast: function(num) {
                this._ref = this._ref.limitToLast(num);
                return this;
            },
            limitToFirst: function(num) {
                this._ref = this._ref.limitToFirst(num);
                return this;
            },
            endAt: function(val) {
                this._ref = this._ref.endAt(val);
                return this;
            },
            startAt: function(val) {
                this._ref = this._ref.startAt(val);
                return this;
            },
            equalTo: function(val) {
                this._ref = this._ref.equalTo(val);
                return this;
            },
            getKey: () => {
                return this._ref.getKey();
            }
        };
    
        const result = {
            database: () => {
                return {
                    ref: (path) => {
                        return new Reference(path);
                    }
                }
            },
            auth: () => firebaseClient.auth(),
            storage: () => firebaseClient.storage()
        };
        result.database.ServerValue = {
            TIMESTAMP: firebaseClient.database.ServerValue.TIMESTAMP
        };
        return result;
    } 

    export async function reauthenticateIfTokenExpiredWrapper(firebaseClient, tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken) {
        if (tokenUpdatePromise) {
            return tokenUpdatePromise;
        }
        let promise = tokenUpdatePromise = Promise.resolve().then(async () => {
            const {claims: {authExp} = {}, token} = (await firebaseClient.auth().currentUser.getIdTokenResult()) || {};
            const tokenUpdatedTenSecondsAgo = (Date.now() - lastTokenUpdated) < 10000;
            if (authExp && authExp < Date.now()) {
                const auth0ClientConfig = JSON.parse(localStorage.getItem('auth0ClientConfig'));
                const {getAccessTokenSilently, isAuthenticated} =  window.auth0
                const auth0AccessToken = await getAccessTokenSilently({
                    audience: auth0ClientConfig.audience
                });
                const response = await getFirebaseCustomToken(auth0AccessToken);
                const firebaseToken = response.data && response.data.token
                await firebaseClient.auth().signInWithCustomToken(firebaseToken);
                const {token} = await firebaseClient.auth().currentUser.getIdTokenResult();
                localStorage.setItem("idToken", token)
                lastTokenUpdated = Date.now();
                tokenUpdatePromise = null;
                return true;
            } else {
                tokenUpdatePromise = null;
                return tokenUpdatedTenSecondsAgo;
            }
        });
        return promise;
    }
    
    export async function reauthenticateIfTokenExpired(tokenUpdatePromise, lastTokenUpdated, getFirebaseCustomToken) {
        if (tokenUpdatePromise) {
            return tokenUpdatePromise;
        }
        let promise = tokenUpdatePromise = Promise.resolve().then(async () => {
            const {claims: {authExp} = {}} = (auth.currentUser ? await getIdToken(auth.currentUser) : {}) || {};
            const tokenUpdatedTenSecondsAgo = (Date.now() - lastTokenUpdated) < 10000;
            if (authExp && authExp < Date.now()) {
                const auth0ClientConfig = JSON.parse(localStorage.getItem('auth0ClientConfig'));
                const {getAccessTokenSilently, isAuthenticated} = window.auth0
                const auth0AccessToken = await getAccessTokenSilently({
                    audience: auth0ClientConfig.audience
                });
                const response = await getFirebaseCustomToken(auth0AccessToken);
                const firebaseToken = response.data && response.data.token
                await signInWithCustomToken(auth, firebaseToken);
                const token = await getIdToken(auth.currentUser);
                localStorage.setItem("idToken", token)
                lastTokenUpdated = Date.now();
                tokenUpdatePromise = null;
                return true;
            } else {
                tokenUpdatePromise = null;
                return tokenUpdatedTenSecondsAgo;
            }
        });
        return promise;
    }
    export async function reauthenticateIfTokenWillExpire() {
        try {
            const auth0ClientConfig = JSON.parse(localStorage.getItem('auth0ClientConfig'));
            const {getAccessTokenSilently, isAuthenticated} = window.auth0
            const auth0AccessToken = await getAccessTokenSilently({
                audience: auth0ClientConfig.audience
            });
            const response = await getFirebaseCustomToken(auth0AccessToken);
            const firebaseToken = response.data && response.data.token
            await signInWithCustomToken(auth, firebaseToken);
            const token = await getIdToken(auth.currentUser);
            localStorage.setItem("idToken", token);
            console.log("token reauthenticated -------------->");
            return token;
        } catch(e) {
            console.error("token reauthenticated failed -------------->", e);
        }
    }

    export async function reauthenticateIfTokenExpireWithIn30Min() {
        try {
            const isValidToken = checkTokenExpire();
            if(isValidToken == "available") {
              return true;
            }
            else if(isValidToken == "invalid") {
                return false;
            }else if(isValidToken == "expired") {
                const reauthenticated = await reauthenticateIfTokenWillExpire();
                console.log("authentication result : ", reauthenticated)
                if(reauthenticated) {
                   return true;
                } else {
                  return false;
                }
          }
        } catch(e) {
            console.error("token reauthenticated failed -------------->", e);
        }
    }

    export async function isAuth0SessionExpired() {
        let loggedIn = false;
        try {
            const auth0ClientConfig = JSON.parse(localStorage.getItem('auth0ClientConfig')) || config.defaultAuth0DataForForUSA;
            if(window.auth0 && auth0ClientConfig) {
                const {getAccessTokenSilently} = window.auth0;
                await getAccessTokenSilently({
                    audience: auth0ClientConfig.audience
                });
                loggedIn = true;
                console.log("Auth0 Session Not Expired ......");
            }
        } catch(e) {
            console.log("Failed to authenticate Auth0 session : ", e);
        }
        if(!loggedIn) {
            console.log("Auth0 Session Expired ......");
            window.location.href = '/logout'
        } 
    }

    export async function signInFirebaseWithAuth0Token(getFirebaseCustomToken, auth0Token) {
        let promise = new Promise(async (resolve,reject) => {
               try {
                const auth0AccessToken = auth0Token;
                const response = await getFirebaseCustomToken(auth0AccessToken);
                const firebaseToken = response.data && response.data.token
                await signInWithCustomToken(auth, firebaseToken);
                const token = await getIdToken(auth.currentUser);
                localStorage.setItem("idToken", token);
                resolve(token)      
               } catch(e) {
                   reject(e);
               }
        });
        return promise;
    }

    export function checkEnterpriseConnection(setting, orgId){
        let connectionString = null
        const publicSettings = setting.publicSettings
        if (publicSettings && Object.keys(publicSettings).length > 0) {
           if (publicSettings.enterpriseConnections) {
               const enterpriseConnections = publicSettings.enterpriseConnections
               const connectionKeys = Object.keys(enterpriseConnections)
               connectionKeys.map(enterprise => {
                   if (enterpriseConnections[enterprise][orgId]){
                       connectionString = enterprise
                   }
               })
           } else {
               connectionString = null
           }
        } 
        return connectionString
    }

    export function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

     export const scrollMaincontainerToTop = () => {
		let mainContainer = document.getElementsByClassName("lifesherpa-main-container");
		let headerElement = document.getElementById("ls-header-appbar")
		if(mainContainer && mainContainer.length > 0) {
			if(headerElement && headerElement.clientHeight) {
				mainContainer[0].firstChild.scrollTop = headerElement.clientHeight;
			} else {
				mainContainer[0].firstChild.scrollTop = 0;
			}
			
		}
	}

    export function delay(delay = 0) {
        return new Promise(resolve => setTimeout(resolve, delay));
    } 

    export function getFibonacciBackoff(count = 0, initialDelay = 1000, maximum_backoff = 30000) {
        let delay1 = initialDelay, delay2 = initialDelay, delay = 0;
        for (let i = 0; i < count; i++) {
            delay = delay1 + delay2;
            delay1 = delay2;
            delay2 = delay;
        }
        return Math.min(delay, maximum_backoff);
    }

    export function retryableRequestError(error) {
        function isRetryableError(error) {
            if (!error.config) {
              // Cannot determine if the request can be retried
              return false;
            }
            const responseBody = (error && error.response && error.response.data) || {};
            let isAPICustomError = false;
            if(typeof responseBody === "object"){
                isAPICustomError = ("errorCode" in responseBody) && ("errorId" in responseBody);
            }
            return axiosRetry.isRetryableError(error) && !isAPICustomError;
        } 
        return axiosRetry.isNetworkError(error) || isRetryableError(error);
    }

    export function getRetryDelay(retryCount) {
        const delay =  getFibonacciBackoff(retryCount);
        console.log('Retrying request : ', retryCount , ` wait ${delay} milliseconds`);
        return delay;
    }
    

    export async function getTwilioChatInfo(userId) {
        try {
            const promises = [
                getTwilioChatToken(),
                getClientChatRoomDetails(userId)
            ];
            const [token, room] = await Promise.all(promises);
            return {token, room};
        } catch(e) {
            console.error("Failed to get twilio chat info -------------->", e);
        }
    }


    const checkTokenExpire = () => {
        const idToken = localStorage.getItem("idToken");
        const tokenDetails = jwt(idToken); 
        if (tokenDetails && (tokenDetails.exp || tokenDetails.authExp)) {
          const expireTime = new Date(tokenDetails.exp * 1000);
          const authExpireTime = new Date(tokenDetails.authExp || "");
          const currentTime = new Date();
          const minTime = 30 * 60 * 1000;
          const availableExpireTime = expireTime - currentTime;
          const availableAuthExpTime = authExpireTime - currentTime;
          // console.log("availableExpireTime -->",this.msToTime(availableExpireTime));
          // console.log("availableAuthExpTime --> ",this.msToTime(availableAuthExpTime));
          const isValidToken = tokenDetails.authExp ? availableExpireTime >= minTime && availableAuthExpTime >= minTime : availableExpireTime >= minTime;
          if(isValidToken) {
            console.log("%c We have availble time to token expire","color:green");
            return "available"
          }
          else {
            console.log("%c Token will expire within 30 mins","color:red");
            return "expired";
          }
        }
        else {
          return "invalid";
        } 
      }
      const msToTime = (duration) => {
        var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
      }

    export const openPopup = (popUp) => {
        try {
            popUp.focus();   
        } catch (e) {
            alert("Pop-up Blocker is enabled! Please disable your pop-up blocker.");
        }
    }

    export const lsTextSpeech = async (text) => {
        const hasAllFeatures = !!window.speechSynthesis || !!window.SpeechSynthesisUtterance

        if (!hasAllFeatures) {
            console.log('browser misses text speech features');
            return; 
        }
        const utterance = new window.SpeechSynthesisUtterance();
        let voices = window.speechSynthesis.getVoices();
        if(!voices || voices.length === 0) {
            window.speechSynthesis.speak(utterance);
            await new Promise((r) => setTimeout(r, 1000));
            voices = window.speechSynthesis.getVoices();
        }
        const voice = voices.find(voice => voice.name === "Fred");
        console.log({voices, voice});
        if(!voice || voice === undefined) {
            console.log('speech voice not found');
            return;
        }
        utterance.voice = voice;
        if(text) {
            utterance.text = text.replace(/\n/g, '');
        }
        utterance.lang = 'en-US';
        utterance.pitch = 1;
        utterance.rate =  1;
        utterance.volume = 1;
        window.speechSynthesis.speak(utterance);
    }

    export const saveRetrylogs = async(retryLogData, retryCount, pushKey) => { 
        const idToken = localStorage.getItem("idToken")
        const url = `/api-retry-log`
        const apiURL = `${retryLogData.config.baseURL.replace(config.serverURL, "")}${retryLogData.config.url}`
        const data = {
            "apiURL": apiURL,
            "x-cloud-trace-context": retryLogData.response.headers["x-cloud-trace-context"],
            "function-execution-id": retryLogData.response.headers["function-execution-id"],
            "response": retryLogData.response.data,
            "httpStatusCode": retryLogData.response.status,
            "attemptNumber": retryCount,
            "appName": "LS Portal",
            "attemptKey": pushKey,
            "timestamp": moment().format('YYYY-MM-DD HH:mm:ss Z') //"2021-03-22 19:13:31 +05:30"
        }
        console.log("save retry log data: ", data)
        await axiosClient.post(url, data, {headers: {'Authorization': `Bearer ${idToken}`}})
    }

    export function onRetry(retryCount, error, requestConfig){
        let pushKey = ""
        if (requestConfig.headers["pushKey"]) {
            pushKey = requestConfig.headers["pushKey"]
        } else {
            pushKey = getPushkey()
        }

        saveRetrylogs(error, retryCount, pushKey)
        requestConfig.headers["pushKey"] = pushKey
        console.log("onRetry: ", pushKey)
        
        return requestConfig
    }

    export function checkErrorInTasks(tasks) {
        let errorMessage = null
        let pendingRoles = {}
        if(tasks) {
            for(const taskId in tasks) {
                const task = tasks[taskId];
                const {errorMsg, parameters} = task;

                const {activityAssignedTo = {}, activityExecutedBy = {}} = parameters || {};
                if(!activityAssignedTo.userId) {
                    pendingRoles[activityAssignedTo.role] = true
                }
                if(!activityExecutedBy.userId) {
                    pendingRoles[activityExecutedBy.role] = true
                }

                if(errorMsg) {
                    errorMessage = errorMsg
                }
    
                if('tasks' in task) {
                    const {errorMessage: subErrorMessage, pendingRoles: subPendingRoles} = checkErrorInTasks(task['tasks']);
                    errorMessage = subErrorMessage || errorMessage;
                    pendingRoles = {...pendingRoles, ...subPendingRoles}
                } 
                if(errorMessage) {
                    break;
                }
            }
        }
        return {errorMessage, pendingRoles};
    }

    export function getNewlyCreatedMembers(groupMembersDetails, contactList) {
        let groupMembersList = [];
        const newUserDetails = groupMembersDetails && groupMembersDetails.length > 0 ? groupMembersDetails.filter((user) => {
            return user.createdOn && ((moment().diff(moment(moment(user.createdOn).utc()), 'days')) < 8) ? true : false
        }) : []

		if (newUserDetails && newUserDetails.length > 0) {
            for(let client of newUserDetails) {
                if (contactList && contactList.length > 0) {
                    const index = contactList.findIndex(contact => contact.userId === client.userId)
                    client["chatUser"] = index != -1 ? contactList[index] : null
                    client.name = client.name || (client.chatUser && client.chatUser.name) || "Display Name";
                }
                groupMembersList.push(client);
            }
            groupMembersList.sort((details1, details2) => {
                return details1.name.localeCompare(details2.name);
            })
		}
        
        return groupMembersList
    }

    export function getFormatedCallTime(lasteUpdated) {
        let formatedTime = null
        if (lasteUpdated) {
            const days = moment().diff(moment(moment(lasteUpdated).utc().local()), 'days')
            if (days == 0) {
                formatedTime = moment(moment(lasteUpdated).utc()).local().format("hh:mm A")
            } else if (days == 1) {
                formatedTime = "Yesterday"
            } else if(days < 8) {
                formatedTime = getWeekDay(lasteUpdated)
            } else {
                formatedTime = moment(moment(lasteUpdated).utc()).local().format("MMM DD YYYY")
            }
        }
        
        return formatedTime
    }

    export function doVideoCall(callItem) {
        const roomname = uuidv4() //`${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
        if(callItem && callItem.userId && !callItem.group) {
			const orgId = callItem.organization || localStorage.getItem('orgId');
			localStorage.setItem('selectedOrgId', orgId);
			const selectedVideoChat = {name: roomname, userId: callItem.userId};
			const videoCallRoomDetails = JSON.stringify(selectedVideoChat);
			localStorage.setItem("videoCallRoomDetails",  videoCallRoomDetails)
			const openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${roomname}`;
			var windowReference = window.open();
            windowReference.location = openVideoCallRoom;
            openPopup(windowReference)
		}
	}
    
    export function checkCompletedInTasks(tasks) {
        let total = 0
        let completed = 0
        let workflowTasks = {}
        let totalTasksCompletionDuration = 0
        if(tasks) {
            for(const taskId in tasks) {
                const task = tasks[taskId];
                const {status, totalDuration} = task;
        
                if('tasks' in task) {
                    const {total: subTotal, completed: subCompleted, workflowTasks: workflowSubTasks, totalTasksCompletionDuration: totalSubTasksCompletionDuration} = checkCompletedInTasks(task['tasks']);
                    total += subTotal
                    completed += subCompleted
                    totalTasksCompletionDuration += totalSubTasksCompletionDuration
                    workflowTasks = {...workflowTasks, ...workflowSubTasks} 
                } else if (status && status === "completed") {
                    completed += 1
                    workflowTasks[taskId] = task
                    total += 1
                    totalTasksCompletionDuration+=totalDuration||0
                } else {
                    workflowTasks[taskId] = task
                    total += 1
                    totalTasksCompletionDuration+=totalDuration||0
                }

            }
        }
        return {total, completed, workflowTasks, totalTasksCompletionDuration};
    }

    export function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    export function detectPlatform() {
        const userAgent = navigator.userAgent.toLowerCase();
        let platform = "Unknown"
        if (/android/.test(userAgent)) {
            platform = 'Android';
        } else if (/iphone|ipad|ipod/.test(userAgent)) {
            platform = 'iOS';
        } else if (/windows phone/.test(userAgent)) {
            platform = 'Windows Phone';
        } else if (/mac/.test(userAgent)) {
            platform = 'Mac';
        } else if (/windows/.test(userAgent)) {
            platform = 'Windows';
        } else if (/linux/.test(userAgent)) {
            platform = 'Linux';
        } else {
            platform = 'Unknown';
        }
        console.log("Device Platform: ", platform)
        return platform
    }

    export function getURLFromText(text) {
        if(!text) {
            return "";
        }
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const newLineRegex = /\n/g;
        let urlsInText = [];
        for(const line of text.split(newLineRegex)) {
            for(const str of line.split(urlRegex)) {
                if(urlRegex.test(str)) {
                    urlsInText.push(str);
                }

            }

        }
        return urlsInText[0];
    }

    export function isAchievementCreatedByToday(dateStr) {
        // Parse the date string to a Date object
        const inputDate = new Date(dateStr);

        // Extract the timezone offset from the input date
        const inputTimezoneOffset = inputDate.getTimezoneOffset() * 60000;

        // Get the current date and time
        const currentDate = new Date();

        // Adjust the current date to the same timezone offset as the input date
        const currentDateInInputTimezone = new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000 + inputTimezoneOffset);

        // Calculate the time difference in milliseconds
        const timeDifference = currentDateInInputTimezone - inputDate;

        // Convert time difference from milliseconds to days
        const timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24);
        // Check if the input date is not older than 1 day
        if (timeDifferenceInDays < 1) {
           return true
        } else {
            return false
        }
    }
