/**
 * User Block Component
 */
 import React, { Component } from 'react';
 import { IconButton } from '@mui/material';
 import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
 import Dialog from '@mui/material/Dialog';
 import DialogTitle from "@mui/material/DialogTitle";
 import DialogContent from '@mui/material/DialogContent';
 import CloseIcon from '@mui/icons-material/Close';
 import moment from 'moment'

 class RewardsProvidedTimes extends Component {

    getISOStrig(date){
        const dateString = new Date(date).toISOString()
        return dateString
    }
    
     render() {
         const {showProvidedTimes, selectedReward} = this.props;


         return (
             <Dialog 
                 fullScreen={false}
                 open={showProvidedTimes}
                 onClose={() => this.props.handleCloseProvidedTimes()}
                 fullWidth={true}
                 maxWidth="xl"
                 aria-labelledby="responsive-dialog-title"
                 className={`setcurrent-status-dialog-popup ${this.props.popupThemeClass}`}
                 PaperProps={
                         { style: { maxWidth: "450px", minHeight: "300px", borderRadius: 8, margin: 'auto' } }
                     }
                 >
                 <DialogTitle>
                     <div className="  d-flex align-items-center py-2 pl-3 pr-1 justify-content-between border-bottom bg-lifesherpa">
                         <h2 className="m-0" data-testid="provided-times-popup-title">{selectedReward.title}</h2>
                         <IconButton className="header-close-button" title="Close Provided Times" onClick={() => this.props.handleCloseProvidedTimes()}><CloseIcon /></IconButton>
                     </div>
                 </DialogTitle>
                 <DialogContent>
                     <ul className="list-unstyled dropdown-list mb-0 lifesherpa-session-theme-support">
                         {selectedReward.shopping.map((shoped,index) => (
                             <li key={index} className="border-bottom m-0 list-item">
                                 <div className='d-flex align-items-center px-3 py-2'>
                                     <AccessTimeOutlinedIcon fontSize="large" className="mr-3" />
                                     <div>
                                        <h4 className='heading-title'>{moment(shoped.timeStamp, "YYYY-MM-DD HH:mm:ss Z").format("MMM DD, YYYY")}</h4>
                                        <div className="sub-text">{moment(shoped.timeStamp, "YYYY-MM-DD HH:mm:ss Z").format("hh:mm A")}</div>
                                     </div>
                                     
                                 </div>
                             </li>
                             )
                             )
                         }
                     </ul>
                 </DialogContent>
             </Dialog>
         );
     }
 }
  
 export default RewardsProvidedTimes