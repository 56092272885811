import React, { Component } from 'react';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import Speech from 'react-speech';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {setFormStatusData,  updateStatus, getUserStatusById } from '../../../actions';
import { isFirstDayOfMonth } from 'date-fns/fp';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SweetAlert from 'react-bootstrap-sweetalert'
import { FormGroup, Input, Label } from 'reactstrap';
import '../../../assets/scss/custom/startActivityDetails.scss';
import SetCurrentStatus from '../../SetCurrentStatus';

class SetCurrentStatusForm extends Component {
	state = {
		StatusPopup: false,
		statusList: [],

	}
	componentDidMount() {
		let { statusData } = this.props;
		let { statusList } = this.state;
		if (statusData) {
			Object.keys(statusData.sections).map((obj, key) => {
				statusList.push(statusData.sections[obj])
			})
				this.props.setFormStatusData( statusList)
			this.setState({ StatusPopup: true, statusList })
		}
		
	}
	updateStatus = (statusList) => {
		if(this.props.executeActivity) {
			console.log(" Clicked on submit set status form");
			let { statusData } = this.props;	
			let data = statusData;
			data && data.sections ?
				Object.keys(data.sections).map((obj, key) => {
					data.sections[obj] = statusList[key]
				}) : ''
			let uid = localStorage.getItem('auth_uid')
			this.props.updateStatus(uid, data);
			this.props.handleUpdateStatus();
		}
		this.handelStatusEditClose();
		
	}
	handelStatusEditClose = () => {
		this.setState({ StatusPopup: false, statusList: [] })
		this.props.handelStatusFormClose();
	}
	render() {	
		return (
			<div>
				{this.state.StatusPopup &&
					<SetCurrentStatus
						showStatus={this.state.StatusPopup}
						image={this.props.image}
						user={this.props.user}
						name={this.props.name}
						profile={this.props.profile}
						handelStatusEditClose={this.handelStatusEditClose}
						updateStatus={this.updateStatus}
						configuratorPreview={this.props.configuratorPreview}
						/>
					}

			</div>
		);
	}
}
const mapStateToProps = ({ settings, authUser, Status, activitiesList }) => {
	const { coures, pinMsg, wpqLink, } = activitiesList
	//console.log("mapStateToProps",coures)
	const { user, profile, credit } = authUser
	return { settings, user, profile, credit, Status, coures, pinMsg, wpqLink };
};

export default withRouter(connect(mapStateToProps, {
	setFormStatusData,updateStatus, getUserStatusById
})(SetCurrentStatusForm));