/**
 * Change user status
 */
import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { withRouter } from 'react-router-dom';
import LifeSherpaLoading from '../LifeSherpaLoading';
import moment from 'moment';
import {setFormStatusData} from '../../actions';
import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
// import {getISODateString} from "../../helpers/helpers";
import DialogContent from '@mui/material/DialogContent';
import CustomInput from '../CustomInput'

class SetCurrentStatus extends Component {

	state = {
		statusList:[],
	}
    componentDidMount() {
		console.log("%c[SetCurrentStatus]%c Rendered set current status dialog popup","color:black","color:green");
	}
	componentWillUnmount() {
		console.log("%c[SetCurrentStatus]%c SetCurrentStatus dialog popup closed","color:black","color:red");
		this.props.setFormStatusData(null);
	}

	handleStatusChange = (e, position , i) => {
		let { statusList } = this.props;
		// let statusIndex = statusList ? statusList.findIndex(status => status.userStatusSetId == e.currentTarget.name) : -1;
		let statusIndex = statusList ? statusList.findIndex(status => status.position == position) : -1;
        const index = statusIndex;
		statusList[index].itemsList.map((li) => li.value = false)
		statusList[index].itemsList[i].value = true
		let name = statusList[index].itemsList[i].name
		Object.keys(statusList[index].items).map((obj, key) => {
			statusList[index].items[obj] = false
		})
		statusList[index].items[e.currentTarget.value] = true
		this.props.setFormStatusData(statusList);
		this.setState({statusList:[]})
	}
	
	render() {
		const { showStatus, image, name , statusList, profile, status,loading } = this.props;
		let statusListTemp = statusList && statusList.length > 0 ? [...statusList] : []
		const displayName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name ) : 'Display Name'
		return (
			<>
				<Dialog
					fullScreen={false}
					open={showStatus}
					onClose={() => this.props.handelStatusEditClose()}
					fullWidth={true}
					maxWidth="xl"
					aria-labelledby="responsive-dialog-title"
					className={` setcurrent-status-dialog-popup ${this.props.popupThemeClass}`}
					PaperProps={
							{ style: { maxWidth: "450px", borderRadius: 8, margin: 'auto', overflow:'hidden' } }
						}
					>
					<DialogTitle>
					    <div className="row px-3 py-3 border-bottom bg-lifesherpa">
							<h2 className="text-white pl-2  my-auto">Set your current status</h2>
							{!this.props.configuratorPreview && 
							<IconButton data-testid="close-status-dialog"  className="ml-auto my-auto text-white" onClick={() => this.props.handelStatusEditClose()}><CloseIcon /></IconButton>
							}
						</div>
					</DialogTitle>
					<DialogContent>
					<div data-testid="set-current-status-form" id="dialog-content" className="col-12">
						<div className="row user-status-profile-background ">
							<div className="row px-4 py-2">
								<img data-testid="set-status-dialog-client-image" className=" rounded-circle mr-2" src={image ? image : this.props.profile ? this.props.profile.picture : this.props.user && this.props.user.thumbnailUrl ? this.props.user.thumbnailUrl : require('Assets/avatars/user-15.jpg')} alt='' width={40} height={40} style={{ borderRadius: 10 + "px" }} />
								<h3 data-testid="set-status-dialog-client-name" className="text-secondary my-auto">{name ? name : displayName}</h3>
							</div>
							
						</div>
						 <LifeSherpaLoading loading={this.props.loading} />
						{statusList && statusList.length && status && status.timeStamp ? 
							<div className="row px-2 py-2 border-top theme-header-background-style" >
								<h3 className="text-secondary ml-1 my-auto">{`Updated: ${moment(status.timeStamp, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}`}</h3>
							</div> : ""
						}
						{statusListTemp && statusListTemp.length > 0 ? statusListTemp.sort((a, b) => a.position - b.position).map((status,statusIndex) => {
							return (
								<div key={statusIndex} className="user-status-list-form" data-testid={`client-status-form${statusIndex}`}>
									<div className="row px-4 py-2 border-top border-bottom theme-header-background-style" ><h3 className="text-secondary my-auto">{statusList.length > 0 && status ? status.heading : ''}</h3></div>
									{
										 status.itemsList && status.itemsList.map((st, key) => (
											<div className="row border-bottom user-status-list-options" key={key+statusIndex}>
													<CustomInput
													 className="my-auto ml-4 status-list-input-options"
													 id={`selStatus${status.userStatusSetId+key}`}
													 name={status.userStatusSetId}
													 onChange={(e) => this.handleStatusChange(e,status.position, key)} 
													 type="radio" 
													 label={st.name} 
													 value={st.name}
													 checked={st.value}
													 />
											</div>))
									}
								</div>
							)
						}) :
							<div className="activity-details-height d-flex justify-content-center align-items-center">
								<div className=" no-data-found">
									<h4>{loading ? "":"Status Data Not Found"}</h4>
								</div>
							</div>
						}
						{statusList && statusList.length > 0 && 
							<div className="row d-flex align-items-center justify-content-center mt-4">
								 <Button color="primary" variant="outlined" data-testid="cancel-status-form" className="  mb-3 mr-2 form-cancel-button" onClick={() => this.props.handelStatusEditClose()}>Cancel </Button>
								 <Button color="primary" variant="contained" data-testid="submit-status-form" className=" mb-3 ml-2  primary-button"  onClick={() => this.props.updateStatus(statusList)}>Submit </Button>
							</div>
						}
						
					</div>
					</DialogContent>
				</Dialog>
			</>
		);
	}
}

// map state to props
const mapStateToProps = ({ Status, settings}) => {
	const {loading, statusList, status} = Status;
	const { popupThemeClass } = settings; 
	return {loading, statusList, popupThemeClass, status};
};

export default withRouter(connect(mapStateToProps, {setFormStatusData})(SetCurrentStatus));

