import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getResourceList,getcategories,getKeywords} from '../../actions'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Label} from 'reactstrap'
import {IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import Badge from '@mui/material/Badge'
import moment from 'moment'
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';
import LifesherpaLayout from '../LifesherpaLayout'

class ResourceComponent extends Component {
    state = { 
        showFilter:false,
        sort:'asc',
        collection:'',
        showImg:false,list:null,
        selList:null
     }
    componentDidMount() {
        this.props.getResourceList('','')
        this.props.getcategories()
        this.props.getKeywords()

    }
    handleReport=(i)=>{
        //this.props.Resources.categories[i].selected=true
        if(this.props.Resources.categories[i].selected) this.props.Resources.categories[i].selected=false
        else this.props.Resources.categories[i].selected=true
        this.setState({collection:this.props.Resources.categories[i].selected})
    }
    handleKeywords=(i)=>{
        if(this.props.Resources.keywords[i].selected) this.props.Resources.keywords[i].selected=false
        else this.props.Resources.keywords[i].selected=true
        this.setState({collection:this.props.Resources.keywords[i].selected})
    }
    handleSort=(value)=>{
        if(this.state.sort===value) this.setState({sort:''})
        else this.setState({sort:value})
    }
    applyFilter=()=>{
        let collection=''
        let keywords=''
        this.props.Resources.keywords.map((cat,i)=>{
            if(cat.selected) keywords=keywords+cat.name+','
        })
        this.props.Resources.categories.map((cat,i)=>{
            if(cat.selected) collection=collection+cat.id+','
        })
        this.props.getResourceList(this.state.sort,collection,keywords)
        this.setState({showFilter:''})
    }
    clearFilter=()=>{
        this.props.Resources.categories.map((cat,i)=>{
            this.props.Resources.categories[i].selected=false
        })
        this.props.Resources.keywords.map((cat,i)=>{
            this.props.Resources.keywords[i].selected=false
        })
        this.props.getResourceList('','')
        this.setState({showFilter:'',sort:'',collection:''})
    }
    handleList=(value)=>{
        this.props.getResourceList('',value)
        if(value==='') this.setState({selList:null})
        else {
            let list=this.props.Resources.categories.find((cat)=>cat.id===value)
            this.setState({selList:list})
        }
    }
    showImg=(value)=>{
        this.setState({showImg:!this.state.showImg,list:value})
    }
    render() { 
        let{Resources}=this.props
        return ( 
            <LifesherpaLayout>
                <RctCollapsibleCard>
                    <div className="w-100 resource-scroll">
                    {this.state.showFilter&&
                            <Dialog
                                fullScreen={false}
                                open={this.state.showFilter}
                                onClose={()=>this.setState({showFilter:false})}
                                fullWidth={true}
                                maxWidth="xl"
                                aria-labelledby="responsive-dialog-title"
                        >
                        <div  className="d-flex align-items-center template-header justify-content-between p-4" style={{background:'#008C95'}}>
                            <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Filters</Label>
                            <div className="text-white" onClick={()=>this.setState({showFilter:false})}><CloseIcon/></div>
                        </div>
                        <DialogContent className="pl-4 pr-4">
                            <div className="text-secondary font-lg my-1">Sort</div>
                            <div className="d-flex my-1">
                                <div className="d-flex px-2 py-auto m-2" style={this.state.sort==='asc'?{borderRadius: '16px',background: '#008C95',color:'#fff',height: '25px'}:{borderRadius: '16px',height: '25px',background: '#fff',cursor: 'pointer',color: '#008C95',border: '2px solid #008C95'}} onClick={()=>this.handleSort('asc')}>{this.state.sort==='asc'?<CheckCircleSharpIcon className="my-auto"/>:''}<div className="my-auto">Latest</div></div>
                                <div className="d-flex px-2 py-auto m-2" style={this.state.sort==='desc'?{borderRadius: '16px',background: '#008C95',color:'#fff',height: '25px'}:{borderRadius: '16px',height: '25px',background: '#fff',cursor: 'pointer',color: '#008C95',border: '2px solid #008C95'}} onClick={()=>this.handleSort('desc')}>{this.state.sort==='desc'?<CheckCircleSharpIcon className="my-auto"/>:''}<div className="my-auto">Oldest</div></div>
                            </div>
                            <div className="text-secondary font-lg my-1">Show Collection</div>
                            <div className="row my-1">
                            {Resources.categories&&Resources.categories.map((list,key)=>(
                            <div className="d-flex px-2 py-auto m-2" key={key} style={list.selected?{borderRadius: '16px',background: '#008C95',color:'#fff',height: '25px'}:{borderRadius: '16px',height: '25px',background: '#fff',cursor: 'pointer',color: '#008C95',border: '2px solid #008C95'}} onClick={()=>this.handleReport(key)}>{list.selected?<CheckCircleSharpIcon className="my-auto"/>:''}<div className="my-auto"/>{list.name}</div>
                            ))}
                            </div>
                            <div className="text-secondary font-lg my-1">Filter by Tags</div>
                            <div className="row my-1">
                            {Resources.keywords&&Resources.keywords.map((list,key)=>(
                            <div className="d-flex px-2 py-auto m-2" key={key} style={list.selected?{borderRadius: '16px',background: '#008C95',color:'#fff',height: '25px'}:{borderRadius: '16px',height: '25px',background: '#fff',cursor: 'pointer',color: '#008C95',border: '2px solid #008C95'}} onClick={()=>this.handleKeywords(key)}>{list.selected?<CheckCircleSharpIcon className="my-auto"/>:''}<div className="my-auto"/>{list.name}</div>
                            ))}
                            </div>
                        </DialogContent>
                        <DialogActions className="p-4">
                            <button className="btn btn-primary w-50" style={{borderRadius:16+"px",background: "white",color: "#008C95",border: "2px solid #008C95"}} onClick={()=>this.clearFilter()}>
                                CLEAR FILTERS
                            </button>
                            <button className="btn btn-primary w-50" style={{borderRadius:16+"px",background:'#008C95'}} onClick={()=>this.applyFilter()}>
                                APPLY
                            </button>
                        </DialogActions>
                    </Dialog>    
                    }
                    {this.state.showImg&&
                        <Dialog
                        fullScreen={true}
                        open={this.state.showImg}
                        onClose={()=>this.setState({showImg:false})}
                        fullWidth={true}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                        
                    >
                        <div  className="d-flex align-items-center template-header justify-content-between p-4" style={{background:'#008C95'}}>
                            <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white" onClick={()=>this.setState({showImg:false})}>Done</Label>
                            
                        </div>
                        <DialogContent className="pl-4 pr-4">
                            <div><img className="ing-fluid w-100" src={this.state.list.thumbnailURL} alt="" /></div>
                        </DialogContent>
                    </Dialog>    
                    }
                        <div className="d-flex mt-1">
                        <IconButton className="text-white rounded-circle font-lg ml-1" style={{background:'#008C95'}} onClick={()=>this.setState({showFilter:true})}>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"></path></svg> 
                            </IconButton>
                            <div className="text-secondary font-lg ml-auto mr-1">{this.state.selList?this.state.selList.name+" ("+this.state.selList.totalCount+")":Resources.resourceList?'All new ('+Resources.resourceList.length+')':''}</div>
                        </div>
                        <div className="d-md-none" style={{width:'350px',overflowX:'scroll'}}>
                        <div className="d-flex mt-3" >
                            {Resources.resourceList&&Resources.resourceList.map((list,key)=>(
                                <div className="list-card p-3 mx-4" style={{borderRadius:16+"px",width:300+'px',height: '250px'}}  key={key} onClick={()=>this.showImg(list)}>
                                    {list.thumbnailURL&&<div className="mx-auto mt-1 text-center" style={{width:'200px',height:'150px'}}><img className="img-fluid" style={{maxHeight:'150px'}} src={list.thumbnailURL} alt="" /></div>}
                                    <div className="col-12 text-secondary my-1" style={{width:220+'px',fontSize:"15px"}}>{list.title}</div>
                                    <div className="col-12 font-md">{moment(list.createdOn).format('DD MMMM, YYYY')}</div>
                                    {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary my-1">{list.description}</div>}
                                </div>
                            ))}
                        </div>                
                        <h2 className="text-secondary font-lg ml-1">Collection</h2>
                        <div className="d-flex mt-3" style={{minHeight:110+'px'}}>
                        <div className="btn h-10 text-white mx-2" style={{borderRadius:5+"px",background:'#008C95'}} onClick={()=>this.handleList('')}>All Reports</div>
                            {Resources.categories&&Resources.categories.map((list,key)=>(
                                <div className="btn h-10 text-white mx-2" key={key} style={{borderRadius:5+"px",background:'#008C95'}} onClick={()=>this.handleList(list.id)}><Badge className="mx-1 mr-2" badgeContent={list.totalCount} color="error">{list.name}</Badge></div>
                            ))}
                        </div>
                        </div>
                        <div className="d-none d-md-block">
                        <div className="row mt-3 mx-4 px-4">
                            {Resources.resourceList&&Resources.resourceList.map((list,key)=>(
                                <div className="list-card p-3 mx-2" style={{borderRadius:16+"px",width:220+'px',height: '250px'}}  key={key} onClick={()=>this.showImg(list)}>
                                    {list.thumbnailURL&&<div className="mx-auto mt-1 text-center" style={{width:'200px',height:'150px'}}><img className="img-fluid" style={{maxHeight:'150px'}} src={list.thumbnailURL} alt="" /></div>}
                                    <div className="col-12 text-secondary my-1" style={{width:220+'px',fontSize:"15px"}}>{list.title}</div>
                                    <div className="col-12 font-md">{moment(list.createdOn).format('DD MMMM, YYYY')}</div>
                                    {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary my-1">{list.description}</div>}
                                </div>
                            ))}
                        </div>                
                        <h2 className="text-secondary font-lg ml-1">Collection</h2>
                        <div className="d-flex mt-3" style={{minHeight:110+'px'}}>
                        <div className="btn h-10 text-white mx-2" style={{borderRadius:5+"px",background:'#008C95'}} onClick={()=>this.handleList('')}>All Reports</div>
                            {Resources.categories&&Resources.categories.map((list,key)=>(
                                <div className="btn h-10 text-white mx-2" key={key} style={{borderRadius:5+"px",background:'#008C95'}} onClick={()=>this.handleList(list.id)}><Badge className="mx-1 mr-2" badgeContent={list.totalCount} color="error">{list.name}</Badge></div>
                            ))}
                        </div>
                        </div>
                    </div>
                </RctCollapsibleCard>
            </LifesherpaLayout>
        );
    }
}
const mapStateToProps = ( Resources) => {
	return Resources;
}

export default connect(mapStateToProps,{getResourceList,getcategories,getKeywords})(ResourceComponent);