/**
 * Clients List Actions
 */

import {axiosClient} from '../sherpaApi'
import {database} from '../firebase'
import { ref, onValue, off  } from "firebase/database"

import {
    GET_RECENT_GROUP,
    GET_SELECTED_GROUP,
    UPDATE_GROUP_SEARCH,
    SEARCH_GROUP,
    GET_GROUP_LIST,
    GET_SUCCESS_GROUP_LIST,
    SET_SELECTED_GROUP,
    SET_SUCCESS_SELECTED_GROUP,
    GET_GROUP_MEMBERS,
    GET_SUCCESS_GROUP_MEMBERS,
    SHOW_LOADING_INDICATOR_GROUP_LIST,
    HIDE_LOADING_INDICATOR_GROUP_LIST,
    GET_GROUP_MEMBERS_FROM_API,
    GET_GROUP_MEMBERS_FROM_API_SUCCESS,
    SET_SELECTED_USER_ID,
    REALTIME_UPDATE_ACTIVITIES,
    FETCH_USER_ORGANIZATIONS,
    FETCH_USER_ORGANIZATIONS_SUCCESS
} from './types';

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */

export const setSelectedGroup = (groupId, orgIds) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let url = ""
    if (orgIds && orgIds.length > 0) {
        url = `/users/${uid}/${groupId}/select-group?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } else {
        url = `/users/${uid}/${groupId}/select-group`
    }
    const data = {}
    dispatch({type: SET_SELECTED_GROUP})
    axiosClient.post(url, data, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: SET_SUCCESS_SELECTED_GROUP, payload: response.data });
    }).catch(async(error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_GROUP_LIST });
    })
};

export const getSelectedGroup = (group, selectedGroup) => (dispatch) => {
    console.log("group: ", group, "selectedGroup: ", selectedGroup);
    dispatch({type: GET_SELECTED_GROUP, payload: group})
    dispatch(getGroupMembers(group, selectedGroup))
};

export const getGroupMembers = (groupId, selectedGroup, orgIds) => (dispatch) => {
    const organization = localStorage.getItem('organization');
    console.log("getGroupMembers Run :", {groupId, selectedGroup});

    dispatch({type: GET_GROUP_MEMBERS})
    dispatch(getAllGroupMembers(groupId, orgIds))

    //subscribe a group
    function initOnGroupListener(groupId, orgId) {
        return onValue(ref(database, `Realtime/${orgId}/Groups/${groupId}`), (snapshot) => {
            const members = snapshot.val();
            console.log("Fetched realtime group members for : ",{orgId, groupId, members})
            dispatch({ type: GET_SUCCESS_GROUP_MEMBERS, payload: {members, groupId} });
            if(members){
                dispatch({ type: HIDE_LOADING_INDICATOR_GROUP_LIST });
            }
            dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
        })
    }
    //unsubscribe already subscribed group
    function initOffGroupListener(groupId, orgId) {
        return off(ref(database, `Realtime/${orgId}/Groups/${groupId}`));
    }

    var promises = [];
    if(selectedGroup) {
        if(Array.isArray(selectedGroup)) {
            for(const group of selectedGroup) {
                promises.push(initOffGroupListener(group.id, group.orgId));
            }
        } else {
            promises.push(initOffGroupListener(selectedGroup, organization));
        }

    }

    if(groupId) {
        if(Array.isArray(groupId)) {
            for(const group of groupId) {
                promises.push(initOnGroupListener(group.id, group.orgId));
            }
        } else {
            promises.push(initOffGroupListener(groupId, organization));
        }
    }
     
    return Promise.all(promises)
    
};

export const getAllGroupMembers = (groups, orgIds) => (dispatch) => {
    const orgId = localStorage.getItem("orgId")
    let filtergroup = []
    if(Array.isArray(groups)) {
        for(const group of groups) {
            filtergroup.push({organization: group.orgId, groupId: group.id})
        }
    } else if(typeof groups === 'string') {
        filtergroup.push({organization: orgId, groupId: groups});
    }
    console.log(" Fetching group members :", groups);
    const idToken = localStorage.getItem('idToken')
    dispatch({type: GET_GROUP_MEMBERS_FROM_API})
    let  url = `/groups/members?groups=${encodeURIComponent(JSON.stringify(filtergroup))}`
    if (orgIds && orgIds.length > 0) {
        url += `&organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    }

    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        const data = response.data || [];
        const usersList = [];
        for(const user of data) {
            user.organization = user.groupsByOrg[0].organization;
            user.groupId = user.groupsByOrg[0].groups[0].groupId;
            user.groupsName = user.groupsByOrg[0].groups.map(group => group.groupName)
            usersList.push(user);
        }
        console.log("usersList: ", usersList)
        dispatch({ type: GET_GROUP_MEMBERS_FROM_API_SUCCESS, payload: usersList });
    }).catch(async(error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("Error Occured while fetching selected group members list :",{groups}, errorMsg);
        return [];
     })
}


export const getGroupList = (orgIds) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    const url = `/users/${uid}/admin-groups`
    // if (orgIds && orgIds.length > 0){
    //     url = `/users/${uid}/admin-groups?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    // } else {
    //     url = `/users/${uid}/admin-groups`
    // }
    console.log(" Fetching group list by userId......");
    dispatch({ type: GET_GROUP_LIST });
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        const groupsData = response.data || [];
        dispatch({ type: GET_SUCCESS_GROUP_LIST, payload: groupsData });
        console.log("Fetched group list successfully :", groupsData);
        dispatch({type: GET_SELECTED_GROUP, payload:[]})
        if (groupsData.length > 0) {
            dispatch(getGroupMembers(groupsData));
        }
        
    }).catch(async(error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching users gorup list","color:red", errorMsg);
        dispatch({ type: HIDE_LOADING_INDICATOR_GROUP_LIST });
        dispatch({type: GET_SELECTED_GROUP, payload:[]})
     })
}

/**
 * Redux Action To Update Client Search
 */
export const updateGroupSearch = (value) => ({
    type: UPDATE_GROUP_SEARCH,
    payload: value
});

/**
 * export const to search Clients
 */
export const onSearchGroup = (value) => ({
    type: SEARCH_GROUP,
    payload: value
});

/**
 * Redux Action To Update Client Search
 */
export const showGroupListLoading = () => ({
    type: SHOW_LOADING_INDICATOR_GROUP_LIST,
});

/**
 * export const to search Clients
 */
export const hideGroupListLoading = () => ({
    type: HIDE_LOADING_INDICATOR_GROUP_LIST,
});

export const getAllGroupMembersFromWidget = () => async(dispatch) => {
    const userId = localStorage.getItem("auth_uid")
    const idToken = localStorage.getItem("idToken")
    //fetching user organizations
    dispatch({ type: FETCH_USER_ORGANIZATIONS });
    const orgResponse = await axiosClient.get(`/users/${userId}/organizations`, {headers: {'Authorization': `Bearer ${idToken}`}}) 
    dispatch({ type: FETCH_USER_ORGANIZATIONS_SUCCESS, payload: orgResponse.data});
    const orgIds = orgResponse.data && orgResponse.data.length > 0 ? orgResponse.data.map(org => {return org.id}) : null
    localStorage.setItem("orgIds", orgIds);
    //fetching user all groups
    dispatch({ type: GET_GROUP_LIST });
    const groupsResponse = await axiosClient.get(`/users/${userId}/admin-groups`, {headers: {'Authorization': `Bearer ${idToken}`}})
    dispatch({ type: GET_SUCCESS_GROUP_LIST, payload: groupsResponse.data || [] });
    //fetching all group members
    dispatch({type: GET_GROUP_MEMBERS})
    dispatch(getAllGroupMembers(groupsResponse.data || [], orgIds))
}


