import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Label} from 'reactstrap'
import moment from 'moment' 
import LifeSherpaLoading from '../LifeSherpaLoading';
import DialogTitle from "@mui/material/DialogTitle";
import Linkify from './Linkify';

class PostView extends Component {
    state = { 
        iframeLoading: true
     }

    render() { 
        let {open, post } = this.props;
        const { iframeLoading} = this.state;
        return ( 
                <Dialog
                    fullScreen={true}
                    open={open}
                    // disableBackdropClick={true}
                    onClose={()=>this.props.handleClose()}
                    maxWidth="xl"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} bullietin-post-viewer`}
                    >
                    <DialogTitle>
                        <div className="d-flex justify-content-between align-items-center py-3 px-3 bg-lifesherpa">
                        <Label data-testid="show-img-dialog-done-button" className="listItemTitle mb-0 text-white cps" onClick={()=>this.props.handleClose()}>Done</Label>   
                        </div>
                    </DialogTitle>
                    <DialogContent className="p-4">
                        <div className='row'>
                            <div data-testid="show-resource-imgage-dialog" className='col-sm-12 col-md-8 clo-xl-9 col-lg-9'>
                                {/* <img className="ing-fluid w-100" src={post.thumbnailURL} alt="" /> */}
                                {post.contentType && post.contentType.includes("image") ?
                                    <div className='image-container'>
                                        <img className="ing-fluid w-100" src={post.fileURL} alt="" />
                                    </div>
                                    :post.contentType && post.contentType.includes("video") ?
                                        <video width="100%" controls autoPlay loop muted>
                                            <source src={post.fileURL} type="video/mp4"/>
                                        </video>
                                    : post.fileURL ?
                                        <>
                                        <LifeSherpaLoading loading={iframeLoading}/>
                                        <iframe width="100%" src={post.fileURL} onError={() => this.setState({iframeLoading: false})} onLoad={() => this.setState({iframeLoading: false})} loading={<LifeSherpaLoading/>} />
                                        </>
                                    : <></>
                                }
                            </div>
                            <div className='col-sm-12 col-md-4 clo-xl-3 col-lg-3'>
                                <div>
                                    <div className="font-lg text-secondary">{post.title}</div>
                                    <div className="text-muted font-md mb-3">{moment(post.createdOn).format('MMM DD, YYYY')}</div>
                                    {post.description ? <div className="font-md text-secondary mb-3"> <Linkify text={post.description}/> </div> : ""}
                                    
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>    
            
        );
    }
}
const mapStateToProps = ({settings}) => {
    const { screenThemeClass, popupThemeClass } = settings; 
    return { screenThemeClass, popupThemeClass };
}

export default connect(mapStateToProps,{})(PostView);
