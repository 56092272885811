/**
 * User List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActivityDetails from "./components/ActivityDetails";
// component's
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// redux actions
import {
    chatWithSelectedUser,
    getMyCourses,
    getActivitiesListN,
    getActivityGroupsList,
    setClientActivitiesType,
    getClientMuteNotification,
    getUserById,
    getClientDetailById,
    checkGeneralActivityPermission,
    logoutCallbackForAuth0Expired,
    clearHistoryData,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    hideStartWorkflowPanel
} from '../../actions';

import { reauthenticateIfTokenExpireWithIn30Min, scrollMaincontainerToTop } from '../../helpers/helpers';
import LifeSherpaLoading from '../LifeSherpaLoading';

import UserResourcesPage from "../SherpaActivities/UserResourcesPage";
import RewardsPage from '../SherpaActivities/RewardsPage';
import UsersAllActivities from '../SherpaActivities/UsersAllActivities';
import UserBlock from './components/UserBlock';
import UserCourses from './components/UserCourses';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';
import Dashboard from './components/Dashboard';
import ActivityEditorSidebar from '../AppMyActivity/components/ActivityEditorSidebar';
import config from '../../config/config';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListIcon from '@mui/icons-material/List';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import WaterRoundedIcon from '@mui/icons-material/WaterRounded';
import LSWorkflowList from '../../routes/lsworkflow/components/LSWorkflowList';

class UserDetails extends Component {

    state = {
        select: "activity",
        openEditor: false,
        activityId: null,
        editorType: null,
        anchorEl: null,
        openMenu: false
    }

    switchChatWithUser(user) {
        this.props.chatWithSelectedUser(user);
    }

    handleRoutineType(type){
		this.setState({openEditor: true, activityId: null, editorType: type, openMenu: !this.state.openMenu})
    }

    handleActivityMenu(event){
        console.log("openActivity menu--->", event.currentTarget)
        this.setState({anchorEl: event.currentTarget, openMenu: !this.state.openMenu})
    }

    componentDidMount() {
        const { groups, selectedGroup, loading, selectedMember, userActivities, clientActivitiesType } = this.props;
        console.log("[UserDetails] selectedMember :", selectedMember);
        let {organization: orgId, groupId} = selectedMember || {};
        this.props.getMyCourses();
        let userId = localStorage.getItem('uid');
        const adminUserId = localStorage.getItem("auth_uid")
        const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
        this.props.checkGeneralActivityPermission()
        this.props.getClientDetailById(userId)
        this.props.getClientMuteNotification(adminUserId, userId);
        this.props.getActivitiesListN({userId, orgId, groupId, filter:'', activityType});
        this.props.getActivityGroupsList(userId, [orgId], activityType);
        this.props.hideStartWorkflowPanel()
    }

    handleClose() {
        // this.setState({popupOpen: !this.state.popupOpen})
        //this.props.handleOpenPopup()
    }

    getCourseLaunchURL = (courseId) => {
        this.props.getCourseLaunchURL(courseId);
        let key = 3;
        localStorage.setItem('selectedIndex', JSON.stringify(key))
        this.props.history.push(`/app/lms_course`);
    }

    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh)';
        } else {
            return '630px';
        }
    }

    setSelect = (value) => {
        this.setState({ ...this.state, select: value });
    }

    handleCloseClientDetailsPanel = () => {
        this.props.setClientActivitiesType('Normal');
        this.props.showUserDetails(false)
    }

    handleOpenObjectEditor = (type) => {
		console.log("add icon clicked -->", type)
		this.setState({openEditor: true, activityId: null, editorType: type})
	}

	handleCloseObjectEditor = () => {
        const {clientActivitiesType, selectedMember } = this.props
        const {organization: orgId, groupId} = selectedMember || {};
        const userId = localStorage.getItem('uid');
        const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
		this.setState({openEditor: false, activityId: null, editorType: null}, () => {
            const params = {userId, orgId, groupId, filter:'', activityType};
            this.props.getActivitiesListN(params);
        })
	}

	handleEditActivity = (activityId, activityType) => {
		console.log("edit activity -->", activityId, activityType)
        const editorType = activityType === 'QT-Reminder' ? "notification" : "general"
		this.setState({openEditor: true, activityId: activityId, editorType})
	}

    //assign| unassign activity
    async handleOpenAssignActivity(userId, userName) {
        const themeName = this.props.activeTheme && this.props.activeTheme.name ? this.props.activeTheme.name : "default"
        const theme = themeName.charAt(0).toUpperCase() + themeName.slice(1);
        let idToken = localStorage.getItem("idToken")
        const orgId = localStorage.getItem("selectedOrgId")
        let openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
        let assingActivityInfo = { assignActivityUrl: openAssignActivityAppUrl, userTitle: userName }
        this.setState({ ...this.state, loading: true, anchorEl: null });
        const reauthenticated = await reauthenticateIfTokenExpireWithIn30Min();
        if (reauthenticated) {
            console.log("%c Token reauthenticated --------> ", "color:green");
            idToken = localStorage.getItem("idToken");
            openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
            assingActivityInfo = { assignActivityUrl: openAssignActivityAppUrl, userTitle: userName };
            this.setState({ ...this.state, loading: false });
            this.props.handleOpenAssingActivity(assingActivityInfo)
            // window.open(openAssignActivityAppUrl,"_blank");
        }
        else {
            this.setState({ ...this.state, loading: false });
            console.log("%c Token reauthentication failed ------->", "color:red");
            logoutCallbackForAuth0Expired();
        }

    }

    //Unconfirmed activities achievements
    handleOpenUnconfirmAchievements = (userId) => {
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if(activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.setState({ anchorEl: null });
        this.props.getClientUnconfirmedAchievementsList(userId);
        scrollMaincontainerToTop()
        this.props.showActivityHistoryDetail(true);
        
    }

    render() {
        const { selectedMemberId, groupMembersDetails, userActivities, showActivityDetailsPanel, hideRewards, selectedUserInfo, showLMSWidget, allowCreateGeneralActivity, networkActivities, orgFilterOptions, resourceList, organizations} = this.props;
        const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] || selectedUserInfo || null : selectedUserInfo || null
        const { launchpad, myCourseLoading, myCourses, workflowslist } = this.props;
        const {anchorEl, openMenu} = this.state
        let {unconfirmAchievements} = this.props
        const selectedOrgId = localStorage.getItem("selectedOrgId");
        let enableWorkflows = false;
        if(organizations) {
            const selectedOrg = organizations.find(org => org.id == selectedOrgId);
            enableWorkflows = (selectedOrg && selectedOrg.enableWorkflows) || false;

        }

        let networkData = networkActivities ? networkActivities.find(ele => ele.userId == selectedMemberId) || {} : {};
        unconfirmAchievements = networkData && networkData.unconfirmedAchievements ? networkData.unconfirmedAchievements : unconfirmAchievements;

        return (
            <div data-testid="my-client-details-panel" className="row ">
                <div className="d-flex w-100 mb-1  justify-content-between align-items-center breadcumb-for-user-details lifesherp-breadrumb-header">
                    <div className="d-flex align-items-left">
                        <Breadcrumb className="mb-0 tour-step-7 breadcomp-for-panel" tag="nav">
                            <BreadcrumbItem
                                tag={"span"}>Home</BreadcrumbItem>
                            <BreadcrumbItem
                                tag={"span"}>Clients</BreadcrumbItem>
                            <BreadcrumbItem
                                tag={"span"}>{selectedClient ? selectedClient.name : 'Info'}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="d-flex d-flex  justify-content-end  align-items-right">
                        <IconButton data-testid="details-panel-close-button" onClick={() => this.handleCloseClientDetailsPanel()} className="" title="Back To Home">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="col-12 mb-2 mt-0 pt-0">
                    <div className="list-card  pt-3 pb-3 pr-3">
                        <UserBlock
                            selectedUser={this.props.selectedUser}
                            selectedMember={this.props.selectedMember}
                            onMenuIconPress={this.props.onMenuIconPress}
                            selectedMemberId={this.props.selectedMemberId}
                            handleClose={this.handleClose.bind(this)}
                            setSelect={this.setSelect}
                            select={this.state.select}
                            handleOpenAssingActivity={this.props.handleOpenAssingActivity}
                            enableWorkflows={enableWorkflows}
                        />
                    </div>
                </div>
                {showActivityDetailsPanel ?
                    <ActivityDetails handleStartActivity={this.props.handleStartActivity}/>
                    :
                    <React.Fragment>
                        <RctCollapsibleCard
                            colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                            heading="Dashboard"
                            fullBlock
                            customClasses="overflow-hidden theme-background"
                            headingCustomClasses="bg-lifesherpa "
                        >

                            <Dashboard enableWorkflows={enableWorkflows}/>
                        </RctCollapsibleCard>
                        <RctCollapsibleCard
                            colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                            heading={<div className="d-flex align-items-center justify-content-between text-white">
                                <div >{`Activities ${userActivities && userActivities.length > 0 ? "(" + userActivities.length + ")" : ""}`}</div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <IconButton title="Create Exclusive Activity" className="p-0" data-testid={`client-activity-mor-icon`} onClick={(event) => this.handleActivityMenu(event)}>
                                        <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'create-activity-menu' : null} aria-haspopup="true" />
                                    </IconButton>
                                </div>
                            </div>
                            }
                            fullBlock
                            customClasses="overflow-hidden theme-background"
                            headingCustomClasses="bg-lifesherpa "
                        >

                            <UsersAllActivities
                                previewId={this.props.previewId}
                                handleStartActivity={this.props.handleStartActivity}
                                selectedClient={selectedClient}
                                handleObjectEditor={this.handleEditActivity.bind(this)}
                            />

                        </RctCollapsibleCard>
                        {enableWorkflows ? 
                            <RctCollapsibleCard
                                colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                                heading={workflowslist && workflowslist.length > 0 ? `Workflows(${workflowslist.length})` : "Workflows"}
                                fullBlock
                                customClasses="overflow-hidden theme-background "
                                headingCustomClasses="bg-lifesherpa "
                                >
                                    <LSWorkflowList 
                                        showViewStatus={true}
                                        userId={localStorage.getItem("uid")}
                                        orgId={localStorage.getItem("selectedOrgId")}
                                        />
                            </RctCollapsibleCard>
                        : ""
                        }
                        {showLMSWidget && <RctCollapsibleCard
                            colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                            heading={<div className="d-flex align-items-center justify-content-between text-white">
                                <div>{`Courses ${myCourses && myCourses.courses && myCourses.courses.length > 0 ? "(" + myCourses.courses.length + ")" : ""}`}</div>
                            </div>
                            }
                            fullBlock
                            customClasses="overflow-hidden theme-background "
                            headingCustomClasses="bg-lifesherpa "
                        >
                            <UserCourses filterApplied={orgFilterOptions && orgFilterOptions.length > 0 ? true : false} getCourseLaunchURL={this.getCourseLaunchURL} myCourseLoading={myCourseLoading} myCourses={myCourses && myCourses.courses ? myCourses.courses : null} launchpad={launchpad} />
                        </RctCollapsibleCard>
                        }
                        <RctCollapsibleCard
                            colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                            heading={<div className="d-flex align-items-center justify-content-between text-white">
                                <div>{`Resources ${this.props.categories ? "(" + this.props.categories.reduce((acc, curr) => acc + curr.totalCount, resourceList && resourceList.length || 0) + ")" :  ""}`}</div>
                            </div>
                            }
                            fullBlock
                            customClasses="overflow-hidden theme-background"
                            headingCustomClasses="bg-lifesherpa"
                        >     
                            <UserResourcesPage />
                        </RctCollapsibleCard>
                        {hideRewards === null || hideRewards === undefined || !hideRewards ?
                            <RctCollapsibleCard
                                colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                                //heading="Rewards"
                                fullBlock
                                customClasses="overflow-hidden theme-background"
                                headingCustomClasses="bg-lifesherpa"
                            >     
                                <RewardsPage />
                            </RctCollapsibleCard>
                            : <></>
                        }
                        {this.state.openEditor ? 
                            <ActivityEditorSidebar
                                handleCloseObjectEditor={this.handleCloseObjectEditor.bind(this)}
                                openEditor={this.state.openEditor}
                                activityId={this.state.activityId}
                                editorType={this.state.editorType}
                                scope={"general"}
                            /> : <></>
                        }
                        <Menu id="create-activity-menu" anchorEl={anchorEl} open={openMenu} onClose={(event) => this.handleActivityMenu(event)}>
                            {allowCreateGeneralActivity && <MenuItem data-testid="add-new-routine-type-activity" title="Activity Type" className="menuEditItem" onClick={() => this.handleRoutineType("general")}><ListIcon className='mr-2'/>Create New Activity</MenuItem>}
                            {allowCreateGeneralActivity && <MenuItem data-testid="add-new-notification-type-activity" title="Notification Type" className="menuEditItem" onClick={() => this.handleRoutineType("notification")}><NotificationsIcon className='mr-2' />Create New Notification</MenuItem>}
                            <MenuItem className="menuEditItem" onClick={() => this.handleOpenAssignActivity(selectedMemberId, selectedClient && selectedClient.name)}><AssignmentRoundedIcon className='mr-2'/>Assign | Unassign Activity</MenuItem>
                            {unconfirmAchievements && unconfirmAchievements !== 0 ? <MenuItem className="menuEditItem" onClick={() => this.handleOpenUnconfirmAchievements(selectedMemberId)}><WaterRoundedIcon className='mr-2'/>Unconfirmed Activities</MenuItem> :""}
                        </Menu>
                        <LifeSherpaLoading loading={this.state.loading} />
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ GroupList, chatAppReducer, MyCourses, Resources, activitiesList, clientsList, settings, workflowData, authUser }) => {
    let { groups, selectedGroup, loading, groupMembersDetails } = GroupList
    const { selectedMemberId, selectedUserInfo, unconfirmAchievements, selectedMember } = chatAppReducer
    const { myCourseLoading, myCourses, showLMSWidget } = MyCourses
    const { categories, resourceList } = Resources;
    const { activeTheme } = settings;
    const { userActivities, showActivityDetailsPanel, clientActivitiesType, allowCreateGeneralActivity, networkActivities } = activitiesList;
    const {clientUserDetail, orgFilterOptions, organizations} = clientsList
    const {hideRewards} = clientUserDetail ? clientUserDetail : {hideRewards: false}
    const {workflowslist} = workflowData
    return { categories, userActivities, myCourseLoading, myCourses, selectedMemberId, groups, selectedGroup, loading, selectedMember, groupMembersDetails, showActivityDetailsPanel, clientActivitiesType, hideRewards , selectedUserInfo, showLMSWidget, allowCreateGeneralActivity, activeTheme, unconfirmAchievements, networkActivities, orgFilterOptions, selectedMember, resourceList, workflowslist, organizations};
};

export default withRouter(connect(mapStateToProps, {
    chatWithSelectedUser,
    getMyCourses,
    getActivitiesListN,
    getActivityGroupsList,
    setClientActivitiesType,
    getClientMuteNotification,
    getUserById,
    getClientDetailById,
    checkGeneralActivityPermission,
    clearHistoryData,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    hideStartWorkflowPanel
})(UserDetails));
;
