import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import VideocamIcon from '@mui/icons-material/Videocam';
import { addNewVideoGroup, editVideoGroupDetails, setSelectedGroupVideoCallDetails } from "Actions"
import { Button } from '@mui/material';
import DialogTitle from "@mui/material/DialogTitle";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckIcon from '@mui/icons-material/Check';
import LifeSherpaLoading from "Components/LifeSherpaLoading";
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import WarningDialog from "../WarningDialog";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class NewGroup extends Component {
    state = {
        selected: -1,
        selectedVideo: { description: "", name: "", members: [] },
        addContactBox: false,
        contacts: [],
        selectAll: false,
        save: false,
        search: "",
        showBackWarning: false,
        popoverTest: "",
        showNameWarning: false,
        selectedOrgId: localStorage.getItem("selectedOrgId")
    }
    componentDidMount(){
        const orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
        this.getOrgMembers(orgId)
    }

    getOrgMembers(orgId) {
        let { selectedVideo, action, contactList } = this.props;
        const clientsList = contactList ? contactList.filter(user => (orgId in user.assignedOrganizations)) : [];
        let contacts = [];
        if (action == "newGroup") {
            contacts = clientsList ? clientsList.map((list) => { return { ...list, selected: false } }) : [];
            this.setState({ contacts: contacts});
            this.props.setSelectedGroupVideoCallDetails(null);
        } else {
            let selectAll = true;
            contacts = clientsList ? clientsList.map((user) => {
                let updatedUser = { ...user, selected: false };
                if (selectedVideo && selectedVideo.members.findIndex(member => member.userId === user.userId) >= 0) {
                    updatedUser.selected = true;
                } else {
                    selectAll = false;
                }
                return updatedUser;
            }) : [];
            this.setState({ selectedVideo: { ...selectedVideo }, contacts: contacts, selectAll: selectAll });
            this.props.setSelectedGroupVideoCallDetails(selectedVideo);
        }

    }

    handleClick = (i) => {
        this.setState({ selected: i })
    }

    handleChange = (e) => {
        let { selectedVideo } = this.state;
        selectedVideo[e.currentTarget.name] = e.currentTarget.value;
        if (this.checkVideoDetailsForVideoCall(selectedVideo)) {
            this.setState({ selectedVideo, save: true })
        } else {
            this.setState({ selectedVideo, save: false })
        }
    }

    checkVideoDetailsForVideoCall = (selectedVideo) => {
        const { selectedGroupDetails, action } = this.props;
        let save = true;
        if (selectedGroupDetails && selectedGroupDetails.name == selectedVideo.name && selectedGroupDetails.description == selectedVideo.description && selectedGroupDetails.members.length == selectedVideo.members.length) {
            let index = selectedGroupDetails.members.findIndex((contact, index) => selectedVideo.members[index].userId != contact.userId);
            if (index >= 0) {
                save = true;
            } else {
                save = false;
            }
        } else if (selectedVideo.name == "" && selectedVideo.description == "" && selectedVideo.members.length == 0) {
            if (action == "newGroup") {
                save = false;
            } else {
                save = true;
            }
            console.log(" there is no group detils--> ");
        }
        return save;
    }

    handleContactClick = (e, i, userId) => {
        let { contacts } = this.state;
        contacts = contacts.map(user => {
            if (user.userId == userId) {
                user.selected = user.selected ? false : true;
            }
            return user;
        });
        let selectAll = this.isAllContactsSelected(contacts);
        this.setState({ contacts: contacts, selectAll: selectAll})
    }

    isAllContactsSelected = (contacts) => {
        let selectAll = true;
        if (contacts && contacts.length > 0) {
            contacts.map(user => {
                if(user.selected !== true) {
                    selectAll = false
                } 
            })
            return selectAll;
        } else {
            return false
        }
    }

    handleCloseContactsList = () => {
        const { contacts, selectedVideo } = this.state;
        let updatedList = [];
        contacts.map(contact => {
            if (contact.selected) {
                updatedList.push(contact)
            }
        });
        selectedVideo.members = updatedList;
        if (this.checkVideoDetailsForVideoCall(selectedVideo)) {
            this.setState({ selectedVideo: selectedVideo, save: true, addContactBox: false, search: "" })
        } else {
            this.setState({ selectedVideo: selectedVideo, save: false, addContactBox: false, search: "" })
        }

    }

    selectAll = (e) => {
        let { contacts, search , selectAll} = this.state;
        contacts = contacts.map((user) => {
                        user.selected = selectAll ? false : true
                        return user
                    });
        this.setState({ contacts: contacts, selectAll: !selectAll });
    }

    handleSave = (e) => {
        const { action, selectedGroupDetails } = this.props;
        const { selectedVideo } = this.state;
        const orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
        if (action == "newGroup" && selectedVideo.name) {
            if (selectedGroupDetails) {
                this.props.editVideoGroupDetails(selectedGroupDetails.id, selectedVideo, orgId);
            } else {
                this.props.addNewVideoGroup(selectedVideo, orgId);
            }
            this.setState({ save: false });
        } else if (selectedVideo.name) {
            this.props.editVideoGroupDetails(selectedVideo.id, selectedVideo, orgId);
            this.setState({ save: false });
        } else {
            this.setState({ showNameWarning: true, popoverTest: "Please provide a group name",  anchorEl: e.currentTarget  });
        }

    }
    handleOpenVideoRoom = (e) => {
        const { action, selectedGroupDetails } = this.props;
        const { selectedVideo } = this.state;
        console.log("selectedGroupDetails ==> ", selectedGroupDetails);
        console.log("selectedVideo ==> ", selectedVideo)
        let messageWarning = ""
        if (action == "newGroup") {
            if (selectedGroupDetails) {
                if(selectedGroupDetails.members && selectedGroupDetails.members.length > 0) {
                    this.props.selectedVideoGroupDetails(selectedGroupDetails);
                } else {
                    messageWarning = "Please add contacts before attempting the call";
                }   
            } else {
                messageWarning = "Please save group before attempting the call";
            }
        } else {
            if(selectedVideo.members && selectedVideo.members.length > 0) {
                this.props.selectedVideoGroupDetails(selectedVideo);
            } else {
                messageWarning = "Please add contacts before attempting the call";
            }  
        }
        if(messageWarning) {
            this.setState({ showNameWarning: true, popoverTest: messageWarning, anchorEl: e.currentTarget});
        }
    }
    handelSearch = (e) => {
        console.log(e);
        this.setState({ search: e.target.value });
    }
    handelCloseSearch = () => {
        this.setState({ search: "", showSearch: false });
    }
    handleGoToBack = () => {
        let { save } = this.state;
        if (save) {
            this.setState({ showBackWarning: true });
        } else {
            this.props.handleBack();
        }
    }
    goToVideoGrupsList = () => {
        this.setState({ showBackWarning: false });
        this.props.handleBack();
    }

    handleSelectOrganization(e) {
        const selectedOrgId = e.target.value
        localStorage.setItem("selectedOrgId", selectedOrgId)
        this.setState({selectedOrgId})
        this.getOrgMembers(selectedOrgId)
    }

    render() {
        let { selectedVideo, save, search, contacts, showSearch, showBackWarning, showNameWarning, selectAll, selectedOrgId } = this.state;
        const { selectedGroupDetails, groupDetailsLoading, action, organizations } = this.props;
        console.log(" selectedGroupDetails -> ", selectedGroupDetails);
        console.log(" groupDetailsLoading -> ", groupDetailsLoading);
        console.log(" selectedVideo -> ", selectedVideo);
        contacts = search && contacts && contacts.length > 0 ? contacts.filter((li) => li.name && li.name.toLowerCase().includes(search.toLowerCase())) : contacts;

        return (
            <div className="add-video-group-panel" data-testid="add-new-video-group-component">
                <div className="d-flex add-group-header align-items-center justify-content-between py-1" >
                    <IconButton className="mx-1" onClick={() => this.handleGoToBack()} data-testid="new-group-back-button"><ArrowBackIcon /></IconButton>
                    <h2 className="mx-auto my-auto" data-testid="add-groups-header-title">{'Video Group'}</h2>
                    {save ? <IconButton className="save-button mx-1" onClick={(e) => this.handleSave(e)} data-testid="save-new-video-group">SAVE</IconButton> : <IconButton className="text-white font-lg mx-1" onClick={(e) => this.handleOpenVideoRoom(e)} datak-testid="groups-video-icon-button"><VideocamIcon /></IconButton>}
                </div>
                <div className="align-items-center justify-content-center mx-1">
                    <div className={`col-12 list-card w-auto my-3 ${this.state.selected === 1 ? "selected-input-container " : ""}`} onClick={() => this.handleClick(1)}>
                        <div className={`mt-1 ${this.state.selected === 1 ? "selected-text " : ""}`} data-testid="group-name-input-field-label">Group Name</div>
                        <input className="videoInput border-0 w-100 mb-1" type='text' name='name' id='name' value={selectedVideo.name} onChange={(e) => this.handleChange(e)} data-testid="group-name-input-field" />
                    </div>
                    <div className={`col-12 list-card w-auto my-3 ${this.state.selected === 2 ? "selected-input-container " : ""}`} onClick={() => this.handleClick(2)}>
                        <div className={`mt-1 ${this.state.selected === 2 ? "selected-text " : ""}`} data-testid="group-description-input-field-label">Group Description</div>
                        <input className="videoInput border-0 w-100 mb-1" type='text' name='description' id='description' value={selectedVideo.description} onChange={(e) => this.handleChange(e)} data-testid="group-description-input-field" />
                    </div>
                    <div className='list-card p-3'>
                        <FormControl fullWidth variant="standard">
                            <h4 htmlFor="age-simple">Organization</h4>
                            <Select data-testid="select-group-dropdown"
                                value={selectedOrgId}
                                onChange={(e) => this.handleSelectOrganization(e)}
                                inputProps={{ name: 'Select Organization', id: 'selct-Organization'}}
                                disabled={action !== "newGroup"}
                                >
                                <MenuItem disabled value=""><em>Select Organization</em></MenuItem>
                                {organizations && organizations.length > 0 && organizations.map((obj, key) => (
                                    <MenuItem key={key} value={obj.id}>{obj.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Popover
                        open={showNameWarning}
                        onClose={() => this.setState({ showNameWarning: false })}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography className="p-3 base-font-color">{this.state.popoverTest}</Typography>
                    </Popover>
                    <h4 className='heading-text'>Group's Members</h4>
                    <ul className="list-unstyled m-0 list-sherpa-activity p-0 mb-4">
                        {selectedVideo.members && selectedVideo.members.map((members, index) => (
                            <li className={`d-flex list-item p-2 border-bottom cps ${index % 2 == 0 ? "alternate-color" : ""}`} key={index}>
                                <div className="avatar-wrap w-15 align-self-center" >
                                    {/*<Avatar className="align-self-center">{activity.title.charAt(0)}</Avatar>*/}
                                    {members.profileImageURL && members.profileImageURL !== '' ?
                                        <img src={members.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" style={{ border: '3px solid #008C95' }} data-testid={`group-member-image-${index}`} />
                                        : <Avatar className="align-self-center user-later-avatar" data-testid={`group-member-avatar-${index}`}>{members.name.charAt(0)}</Avatar>
                                    }
                                </div>
                                <div className="actvity-routine my-auto mx-2">
                                    <h4 data-testid={`group-member-name-${index}`}>{members.name}</h4>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <LifeSherpaLoading loading={groupDetailsLoading} />
                    <div className="d-flex fixed-bottom mb-3 mr-lg-4" >
                        {/* <Button title="Add Contacts" className="btn text-white ml-auto mt-auto mr-lg-5 mr-2 primary-button" onClick={() => this.setState({ addContactBox: true, showSearch: false })} data-testid={`add-contacts-button`}>ADD CONTACTS</Button> */}
                        <IconButton title="Add Contacts" className="add-group-button ml-auto mr-3 mb-2" onClick={() => this.setState({ addContactBox: true, showSearch: false })} data-testid={`add-contacts-button`}><PeopleAltRoundedIcon/></IconButton>
                    </div>
                    {this.state.addContactBox &&
                        <Dialog
                            fullScreen={true}
                            open={this.state.addContactBox}
                            onClose={() => this.handleCloseContactsList()}
                            fullWidth={true}
                            maxWidth="xl"
                            aria-labelledby="responsive-dialog-title"
                            className={this.props.popupThemeClass}
                            data-testid={`add-contacts-popup`}
                        >
                            <DialogTitle>
                                <div className="d-flex align-items-center template-header justify-content-between all-clients-for-grooup p-2 pr-3">
                                    <IconButton className="" onClick={() => this.handleCloseContactsList()} data-testid={`back-button-on-add-contacts`}><ArrowBackIcon /></IconButton>
                                    <div className="d-flex align-items-center">
                                        {showSearch ?
                                            <form className="mr-3 w-100">
                                                <TextField id="standard-search"
                                                    onChange={(e) => this.handelSearch(e)}
                                                    type="search"
                                                    value={search}
                                                    placeholder="Search Contacts"
                                                    autoFocus={true}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard" 
                                                />
                                            </form>
                                            :
                                            <IconButton className=" mx-4 " data-testid="search-button-on-add-contacts" onClick={() => this.setState({ showSearch: true })}><SearchIcon /></IconButton>
                                        }
                                        <IconButton onClick={(e) => this.selectAll()}>
                                            {selectAll ?
                                                <CheckBoxIcon data-testid="unselect-all-contacts" /> :
                                                <CheckBoxOutlineBlankIcon data-testid="select-all-contacts" />}
                                        </IconButton>
                                    </div>
                                </div>
                            </DialogTitle>
                            <DialogContent className="p-0 py-0">
                                <ul className="list-unstyled m-0 list-sherpa-activity add-group-contact-list-popup px-0 pt-1 mb-4" data-testid="all-contacts-list">
                                    {contacts.map((list, index) => (
                                        <li className={`d-flex list-item align-items-center p-2 cps border-bottom ${index % 2 == 0 ? "alternate-color" : ""}`} key={index} onClick={(e) => this.handleContactClick(e, index, list.userId)} data-testid={`contacts-list-item-${index}`}>
                                            <div className="avatar-wrap w-15 align-self-center" >
                                                {/*<Avatar className="align-self-center">{activity.title.charAt(0)}</Avatar>*/}
                                                {list && list.profileImageURL && list.profileImageURL !== '' ?
                                                    <img src={list.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" data-testid={`contact-image-${index}`} />
                                                    : <Avatar className="align-self-center user-later-avatar" data-testid={`contact-avatar-${index}`}>{list.name.charAt(0)}</Avatar>
                                                }
                                            </div>
                                            <div className="actvity-routine my-auto mx-2">
                                                <h4 data-testid={`contact-name-${index}`}>{list.name}</h4>
                                            </div>
                                            <IconButton className="ml-auto mr-2" >
                                                {list.selected ?
                                                    <CheckBoxIcon color="primary" data-testid={`contact-checked-${index}`} /> :
                                                    <CheckBoxOutlineBlankIcon color="primary" data-testid={`contact-unchecked-${index}`} />}
                                                {/* <input className="my-auto text-white font-lg" style={{height:20+"px",width:20+"px",background:'#008C95',border:'2px solid white',}} type='checkbox' checked={list.selected} onChange={(e)=>this.handleContact(e,index)} /> */}

                                            </IconButton>
                                        </li>
                                    ))}
                                </ul>
                            </DialogContent>
                        </Dialog>
                    }
                    <WarningDialog
                        showWarning={showBackWarning}
                        warningText={"Are you sure you want to discard your changes?"}
                        cancelText={"NO"}
                        acceptText={"YES"}
                        handleCancel={() => this.setState({ showBackWarning: false })}
                        handleAccept={() => this.goToVideoGrupsList()}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ Contacts, settings, clientsList }) => {
    const { popupThemeClass } = settings;
    const { contactList, selectedGroupDetails, groupDetailsLoading } = Contacts;
    const {organizations} = clientsList
    return { contactList, popupThemeClass, selectedGroupDetails, groupDetailsLoading, organizations };
};

export default connect(mapStateToProps, {
    addNewVideoGroup,
    editVideoGroupDetails,
    setSelectedGroupVideoCallDetails
})(NewGroup);