import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import {
    handleActivityCompletionConfirmation,
    showActivityHistoryDetail,
    getSelectedActivity,
    getHistoryList,
    setSelectedMemberId,
    clearHistoryData,
    getHistoryListForNotification,
    setHistoryListPageCount
} from 'Actions';
import AppConfig from "../../constants/AppConfig";

class ActivityCompletionConfirmation extends Component {
    handleConfirm = () => {
        let { activityCompletionMessage, selectedMemberId } = this.props;
        if (activityCompletionMessage.achievementId) {
            console.log(" Activity History for notification --> ", activityCompletionMessage);
            const userId = activityCompletionMessage.userId;
            const orgId = activityCompletionMessage.organization || localStorage.getItem('orgId');
            // if(selectedMemberId !== userId && localStorage.getItem("uid") !== userId) {
                localStorage.setItem("uid",userId);
                localStorage.setItem("activityConfirmation", userId);
                this.props.setSelectedMemberId(userId)
            // } 
            localStorage.setItem("selectedOrgId", orgId)
            let activity = {id: activityCompletionMessage.routineId, title: activityCompletionMessage.routineTitle};
            this.props.clearHistoryData();
            this.props.showActivityHistoryDetail(true);
            this.props.getSelectedActivity(activity);
           // this.props.getHistoryList(userId, activity.id); 
            this.props.setHistoryListPageCount(0)
            this.props.getHistoryListForNotification(userId, activity.id, activityCompletionMessage.achievementId); 

        }
        this.handleClose();
    }

    handleClose = () => {
        this.props.handleActivityCompletionConfirmation(null);
    }

    render() {
        const { activityCompletionMessage } = this.props;
        console.log(" activityCompletionMessage --> ", activityCompletionMessage);
        // activityCompletionMessage &&  activityCompletionMessage.userId ? localStorage.setItem("uid", activityCompletionMessage.userId) : ""
        return (
            <div> {activityCompletionMessage && activityCompletionMessage.title &&
                <Dialog
                    fullScreen={false}
                    open={activityCompletionMessage && activityCompletionMessage.title ? true : false}
                    fullWidth={true}
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} warning-dialog-popup`}
                >
                    <DialogContent className="p-0">
                        <div className="text-center mb-3 mx-4 mt-4 execute-activity-alert">
                            <div className="d-flex align-items-center justify-content-center" style={{height: 80}}>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={require('../../assets/img/help-logo.png')}
                                    style={{height: 80, width: 80, border: '0px solid #fff'}}
                                />
                            </div>
                            <h2 className=" exitDialog text-center">{activityCompletionMessage.title}</h2>
                            <h2 className=" subHeading text-center mb-4">{activityCompletionMessage.message}</h2>
                            <div className="d-flex pt-3  align-items-center justify-content-between">
                                <button className="btn btn-warning mr-2" onClick={() => this.handleClose()}>NO</button>
                                <button className="btn btn-grey ml-2" onClick={() => this.handleConfirm()}>OK</button>
                                {/* <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}><a className="text-white" target="_self" href="/success">ok</a></button>*/}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            }
            </div>
        );
    }
}
const mapStateToProps = ({ settings, activitiesList, authUser , chatAppReducer}) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const { activityCompletionMessage } = activitiesList
    const { userRole } = authUser;
    const {selectedMemberId} = chatAppReducer

    return { screenThemeClass, popupThemeClass, activityCompletionMessage, userRole , selectedMemberId};
};

export default withRouter(connect(mapStateToProps, {
    handleActivityCompletionConfirmation,
    showActivityHistoryDetail,
    getSelectedActivity,
    getHistoryList,
    setSelectedMemberId,
    clearHistoryData,
    getHistoryListForNotification,
    setHistoryListPageCount
})(ActivityCompletionConfirmation));
