import React, { Component } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Badge } from 'reactstrap';
import Scrollbars from "react-custom-scrollbars"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

function HandleScrollbar({ windowWidth, children }) {
    const getScrollHeight = () => {
        return 'calc(100vh - 114px)';
    }

    return (
        <div>
            {windowWidth > 700 ?
                <Scrollbars
                    className="rct-scroll "
                    autoHide
                    style={{ height: getScrollHeight() }}
                >
                    {children}
                </Scrollbars>
                :
                children
            }
        </div>

    )
}


class PostMembers extends Component {
    state = {
        members: []
    }


    render() {
        const { members, windowWidth, clientContactsLoading } = this.props;
        return (
            <HandleScrollbar
                windowWidth={windowWidth}
            >
                <div className={windowWidth <= 700 ? "px-3 pb-3" : ""}>
                    <List className="list-unstyled p-0" data-testid="client-contacts-list">
                        {members && members.length > 0 ? members.map((client, key) => (
                            <ListItem data-testid={`client-contact-${key}`} key={key} onClick={() => this.props.handleSelectClient(client)} className={`${key % 2 === 0 ? "styleToListItem" : " defaultStyleToListItem"} px-15 py-2 cps d-flex justify-content-between align-content-center`}>
                                <div className={`d-flex w-100 align-items-center`}>
                                    <div className="avatar-wrap w-15 align-self-center position-relative" >
                                        {client.profileImageURL ?
                                            <img src={client.profileImageURL} data-testid="client-image" className="rounded-circle align-self-center" width="40" height="40" />
                                            : <Avatar className="align-self-center user-later-avatar" data-testid="client-avatar">{client.name ? client.name.charAt(0) : "D"}</Avatar>
                                        }
                                    </div>
                                    <div className=" ml-3">
                                        <h4 className="mb-0">{client.name || "Display Name"}</h4>
                                    </div>
                                    <div className="d-flex align-items-center ml-auto">
                                        <ArrowRightIcon/>
                                    </div>
                                </div>
                            </ListItem>
                        ))
                            :
                            <div style={{ height: windowWidth > 700 ? 'calc(100vh - 114px)' : "auto" }} className='d-flex align-items-center my-0 container-border py-0 mx-auto justify-content-between'>
                                <div className='text-center w-100 my-4 py-4'>
                                    {clientContactsLoading ? "Please wait fetching contacts data..." : "Contacts Not Found"}
                                </div>
                            </div>
                        }
                    </List>
                </div>
            </HandleScrollbar>
        );
    }
}
export default PostMembers;



