import React, { Component } from "react";
import LifesherpaLayout from '../LifesherpaLayout'
import LifeSherpaLoading from '../LifeSherpaLoading';
import { getLmsLoginUrl, setLmsLogoutUrl } from "../../actions";
import { connect } from 'react-redux';
import Iframe from "./iframe.js";
class AnalyticsiFrame extends Component {
    state = {
        loading: true,


    }

    hideSpinner = () => {
        console.log("hello analytics iframe of .....................................s");
        this.setState({ ...this.state, loading: false });
    }

    getZogoUrl(region) {
        const userEmail = localStorage.getItem("user_email");
        if (region === "australia") {
            return `https://accounts.zoho.com.au/signin?LOGIN_ID=${encodeURIComponent(userEmail)}&servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`
        } else {
            return `https://accounts.zoho.com/signin?LOGIN_ID=${encodeURIComponent(userEmail)}&servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`
        }
    }

    render() {

        let { orgDetail, userData } = this.props;
        // console.log(orgDetail, userData)
        const { loading } = this.state;
        const url = this.getZogoUrl(orgDetail.region)
        return (
            <>
                <LifeSherpaLoading loading={loading} />
                <div style={{ visibility: loading ? "hidden" : "visible" }}>
                    <Iframe
                        url={url}
                        onLoad={this.hideSpinner}
                        height="600"
                        scrolling="yes"
                        id="myId"
                        className="w-100"
                        display="initial"
                        position="relative"
                        allowFullScreen
                        />
                </div>
            </>
        )
    }
}
const mapStateToProps = ({ authUser }) => {
    const { orgDetail, userData } = authUser
    return { orgDetail, userData }
}

export default connect(mapStateToProps, {
    getLmsLoginUrl, setLmsLogoutUrl
})(AnalyticsiFrame)
