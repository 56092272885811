import React from 'react';
const DontsLoader = () => {
    return (
        <div className="loading-dots">
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
        </div>
    )
}
export default DontsLoader;