/**
* My Courses Actions
*/

import {axiosClient} from '../sherpaApi'

import {
   GET_MY_COURSES,
   GET_SUCCESS_MY_COURSES,
   GET_MY_COURSE_LAUNCH_URL,
   GET_SUCCESS_MY_COURSES_LAUNCH_URL,
   SHOW_LOADING_INDICATOR_MY_COURSES,
   HIDE_LOADING_INDICATOR_MY_COURSES,
   SHOW_LMS_MODULE,
   HIDE_LMS_MODULE,
   SELECT_COURSE_ITEM,
   CLOSE_COURSE_IFRAME,
   GET_USER_COURSE_PROGRESS,
   GET_USER_COURSE_PROGRESS_SUCCESS,
   GET_USER_COURSE_PROGRESS_FAILED,
   GET_COURSE_GOTO_URL,
   GET_COURSE_GOTO_URL_SUCCESS,
   GET_COURSE_GOTO_URL_SUCCESS_FAILED,
   CATCH_API_ERROR,
   CLEAR_COURSE_DATA,
   HANDLE_SHOW_ACTIVITY_COURSE,
   SHOW_RESET_COURSE_ALERT,
   OPEN_LAUNCH_PAD_URL
} from './types';

export const showMyCoursesLoading = () => ({
   type: SHOW_LOADING_INDICATOR_MY_COURSES,
});

export const hideMyCoursesLoading = () => ({
   type: HIDE_LOADING_INDICATOR_MY_COURSES
})

export const getMyCourses = (orgIds) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const userId = localStorage.getItem('uid')
    // console.log("user id=>",userId)
    console.log(" Fetching user courses by userId......",userId);
    let url = null
    if (orgIds) {
        url = `/users/${userId}/lms/courses?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } else {
        url = `/users/${userId}/lms/courses`
    }

   dispatch({type: GET_MY_COURSES})
   axiosClient.get(url, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
      if (response.data && response.data.status === "active" ){
          console.log("%c Fetched user courses with active status for userId:"+userId,"color:green");
            dispatch({type: SHOW_LMS_MODULE})
        } else {
          console.log("%c Fetched user courses with inactive status for userId:"+userId,"color:red");
            dispatch({type: HIDE_LMS_MODULE})
        }
        if(response.data && response.data.courses && response.data.courses.length > 0) {
            let consoleList = [];
            response.data.courses.map(course => {
                consoleList.push({name:course.name, courseId:course.id, role:course.role});
            });
            console.table(consoleList);
        }
        dispatch({ type: GET_SUCCESS_MY_COURSES, payload: response.data });
    }).catch((error) => {
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user courses for userId:"+userId,"color:red", errorMsg);
        if (error.response && error.response.data && (error.response.data.errorCode === "E1200" || error.response.data.errorCode === "E1201")){
            dispatch({type: HIDE_LMS_MODULE})
        }
        dispatch({ type: GET_SUCCESS_MY_COURSES, payload: null });
        dispatch({ type: HIDE_LOADING_INDICATOR_MY_COURSES });
     })
}

export const getCourseLaunchURL = (courseId, WidgetServices = null, resetCourseProgress) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const userId = localStorage.getItem('uid')
    const url = `/users/${userId}/lms/courses/${courseId}/goto-url?resetCourseProgress=${resetCourseProgress}`
    const data = null
    console.log(" Fetching LMS Course Launch URL for courseId:",courseId,"......");
   dispatch({type: GET_MY_COURSE_LAUNCH_URL})
   axiosClient.post(url, data, {
        headers: { 
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched LMS Course Launch URL for courseId:"+courseId,"color:green");
        dispatch({ type: GET_SUCCESS_MY_COURSES_LAUNCH_URL, payload: response.data });
        if(WidgetServices) {
            if(response.data && response.data.url) {
                const data = {url: response.data.url};
                WidgetServices.openLMSCourseHandler(data)
            }
        }
       // window.open(response.data.url)
    }).catch((error) => {
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching LMS Course Launch URL for courseId:"+courseId,"color:red", errorMsg);
        dispatch({ type: HIDE_LOADING_INDICATOR_MY_COURSES });
    })
}

export const selectMyCourseItem = (course) => (dispatch) =>{
    dispatch({type: SELECT_COURSE_ITEM, payload: course})
}

export const closeCorseIframe = () => (dispatch) =>{
    dispatch({type: CLOSE_COURSE_IFRAME})
}

export const getCourseGotoURL=(userId, courseId)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    //dispatch({type:REMOVE_COURES})
    console.log(" Fetching course goto-url for courseId:",courseId);
    dispatch({type:GET_COURSE_GOTO_URL})
    let url =`/users/${userId}/lms/courses/${courseId}/goto-url?organization=${orgId}`
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched course goto-url for courseId:"+courseId,"color:green");
        dispatch({type:GET_COURSE_GOTO_URL_SUCCESS,payload:response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error occured while fetching course goto-url for courseId:"+courseId,"color:red",errorMsg);
        dispatch({ type: GET_COURSE_GOTO_URL_SUCCESS_FAILED}); 
        dispatch({type: CATCH_API_ERROR, payload: error})    
    })
}


export const resetUserCourse=(userId, courseId)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    //dispatch({type:REMOVE_COURES})
    dispatch({type:GET_COURSE_GOTO_URL})
    let url =`/users/${userId}/lms/courses/${courseId}/reset?organization=${orgId}`
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("reset response :", response.data);
        dispatch(getCourseGotoURL(userId, courseId));
        //dispatch({type:GET_COURSE_GOTO_URL_SUCCESS,payload:response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        dispatch({ type: GET_COURSE_GOTO_URL_SUCCESS_FAILED}); 
        dispatch({type: CATCH_API_ERROR, payload: error})    
    })
}

export const clearCourseData=()=> (dispatch) => {
     dispatch({ type: CLEAR_COURSE_DATA}); 
}

export const handleShowActivityCourse = (data) => (dispatch) => {
    dispatch({type: HANDLE_SHOW_ACTIVITY_COURSE, payload: data});
};

export const handleOpenLauncPadUrl = (data) => (dispatch) => {
    dispatch({type: OPEN_LAUNCH_PAD_URL, payload: data});
};


