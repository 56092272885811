/**
 * Client Alerts
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'

import ViewPage from '../../components/Notices/ViewPage'
//Actions
import {
    clearNoticesData
} from '../../actions'
import NoticeForm from '../../components/Notices/NoticeForm'
class CreateAlert extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formId:'', noticeId:''
        }
    }
    componentDidMount() {
        const { formId, noticeId} = this.props.params;
        this.setState({formId, noticeId});
        this.props.handleOpenURL('form');
        console.log("Create Notice Form Rendered");
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.url != prevProps.match.url) {
          console.log("url changed : ",  prevProps.match.url,this.props.match.url );
          const { formId, noticeId} = this.props.params;
           this.setState({formId, noticeId});
        }
    }

    handleclosePanel = () => {
        this.props.clearNoticesData();
        window.location.href = window.location.origin + "/success";
    }
 
    render() {
        const { widgetApp, clientName, selectedUserId, clientImage} = this.props;
        let {selectedNotice} = this.props;
        let {formId, noticeId} = this.state;
        if(!selectedNotice) {
            selectedNotice = {formId, noticeId}
        } else {
            selectedNotice.formId = formId || selectedNotice.formId;
            selectedNotice.noticeId = noticeId || selectedNotice.noticeId;
        }

        return (
            <div className='notice-form-page'>
                {selectedUserId && selectedNotice ?
                    <NoticeForm
                        clientName={clientName}
                        clientImage={clientImage}
                        notice={selectedNotice}
                        handleclosePanel={this.handleclosePanel}
                        widgetApp={widgetApp}
                    />
                    :
                    <div className='d-flex align-items-center my-4 py-4 mx-auto justify-content-between'>
                        <div className='text-center w-100 mt-4 pt-4'>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ authUser, noticesReducer }) => {
    const { themeLoading, userLoading } = authUser;
    const { selectedUserId, selectedNotice } = noticesReducer;
    return { themeLoading, userLoading, selectedUserId , selectedNotice}
}

export default connect(mapStateToProps, {
    clearNoticesData
})(CreateAlert)
