import React, {  Component } from "react";
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import MicIcon from '@mui/icons-material/Mic';
import Avatar from '@mui/material/Avatar';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import IconButton from '@mui/material/IconButton';
//import PushPinIcon from '@mui/icons-material/PushPin';
 import MaximizeIcon from '@mui/icons-material/Maximize';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';

class ParticipantsListItem extends Component {
    state = {
        audioStatus : ""
    }
    getRealTimeUser = () => {
        const {realTimeData, participant} = this.props;
        if(realTimeData && realTimeData.members) {
            let user = realTimeData.members.find( user => user.userId == participant.userId);
            return user
        } else {
            return null
        }
    }
    handleMuteOrUnmute = (realtimeUser) => {
        const {participant, isLocalHostOfGroup} = this.props;
        if(realtimeUser && isLocalHostOfGroup) {
            if(realtimeUser.mute) {
                this.props.handleRemoteParticipantUnMute(participant.userId)
            } else {
                this.props.handleRemoteParticipantMute(participant.userId)
            }
        }
        
    }
    handleRemoteParticipantRemove = () => {
        const {participant} = this.props;
        this.props.handleRemoteParticipantRemove(participant.userId)
    }

    render() {
        let { participant,  keyIndex, isLocalHostOfGroup} = this.props;
        const realtimeUser = this.getRealTimeUser();
        const audioStatus = realtimeUser && realtimeUser.mute ? "mute" : "";
        return (
        <li className={`d-flex list-item align-items-center p-2 cps border-bottom ${keyIndex % 2 == 0 ? "alternate-color":""}`} >
           <div className="avatar-wrap w-15 align-self-center" onClick={() => this.props.handleOpenScreen(participant.identity)} >
               {participant.profileImageURL && participant.profileImageURL  !== '' ?
                   <img src={participant.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" />
                   : <Avatar className="align-self-center user-later-avatar">{participant.name ? participant.name.charAt(0) : "U"}</Avatar>
               }
           </div>
           <div className="actvity-routine my-auto mx-2">
               <h4 onClick={() => this.props.handleOpenScreen(participant.identity)}>{participant.name}</h4>
           </div>
           <IconButton className="ml-auto mr-2 patcipant-list-button" onClick={() => this.handleMuteOrUnmute(realtimeUser)}>
              {audioStatus ? <MicOffRoundedIcon/>  : <MicIcon/> }
           </IconButton>
           {isLocalHostOfGroup && 
                <IconButton className=" mr-2 patcipant-list-button" onClick={() => this.handleRemoteParticipantRemove()}>
                    <RemoveCircleOutlineIcon/>
                </IconButton>
            }

       </li>
        );
    }
}

export default ParticipantsListItem;
