/**
 * Recent Orders
 */
 import React, { Component } from 'react';
 // components
import ClientsList from '../../routes/chat/components/ClientsList';
 
class MyClientsPanel extends Component {

    state = {
        openPopup: false,
        mobileOpen: false,
        openForm: false,
        openPopup1: false
    };

    openPopup() {
        this.setState({ openPopup: !this.state.openPopup })
    }

    handleOpenPopup = () => {
        this.setState({ openPopup: !this.state.openPopup })
    }

    handleOpenForm = (activity) => {
        this.setState({ openForm: !this.state.openForm })
    }

    handleClose() {
        this.setState({ openPopup: !this.state.openPopup })
    }
    handleClose1() {
        this.setState({ openPopup1: !this.state.openPopup1 })
    }

    render() {
        return (
            <ClientsList
                openPopup={this.state.openPopup}
                handleClose={this.handleOpenPopup}
                handleOpenForm={this.handleOpenForm}
                handleOpenPopup={this.handleOpenPopup}
                showUserDetails={this.props.showUserDetails}
                handleOpenAssingActivity={this.props.handleOpenAssingActivity}
                />
        );
    }
}

export default MyClientsPanel;