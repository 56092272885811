/**
 * User List Item
 */
import React from 'react';
import {
	Form,
	FormGroup,
	Label,
	Input,
	Col,
} from 'reactstrap';
import Divider from '@mui/material/Divider';

const SpacerItem = ({ obj ,section}) => (
    <div key={section+"spaceritem"} className="" style={{marginBottom: "35px"}}>
		{obj.heading ? <Label className="form-label">{`${obj.heading}${obj.isMandatory ? " *" : ""}`}</Label> : <Divider className="mb-4 mt-2"/>}
		{/*<Divider className="mb-2 mt-4"/>*/}
  </div>
);

export default SpacerItem;
