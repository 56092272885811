/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';
import Moment from 'moment';

// rct collapsible card
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
import LandingPageAllActivity from '../../../components/SherpaActivities/LandingPageAllActivity';
import SidebarEditorMain from '../../../components/SherpaActivities/SidebarEditorMain';
import WidgetServices from "../../../services/widgetServices";

//Actions
import {
    selectActivityId,
    selectActivity,
    handlEditor,
    changeRole,
    showClientPanel,
    getlaunchpad,
    getActivitiesList,
    getUserById,
    selectMenuItem,
    showAssignActivityIFramePanel,
    getActivityGroupsList,
    executeActivityWithAchievementId,
    networkActivity,
    hideActivityHistoryDetail,
    handleHideActivityDetailsPanel,
    getactivitiesCalendar
} from '../../../actions'


import AssignActivityComponent from "../../../components/AssignActivity";
import { withAuth0 } from "@auth0/auth0-react";
import AddActivity from "../../../components/SherpaActivities/AddActivity";

import AchievementHistoryList from "../../../components/AchievementHistoryList"
class Activities extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null,
            openChart: false,
            firebaseToken: null,
            selected: '',
            activityId: '',
            activityType: '',
            previewId: null,
            showDetails: '',
            coursePanel: false,
            assingnActivityPanel: false,
            openEditor: false,
            editorType: null
        }
    }
    showUserDetails = (isShowClientDetail) => {
        if (isShowClientDetail) {
            console.group("%c[UserDetails]%c Rendered user details panel", "color:black", "color:green");
        } else {
            console.log("%c[UserDetails]%c Closed user details panel", "color:black", "color:red");
            console.groupEnd();
        }
        let auth_uid = localStorage.getItem('auth_uid');
        const orgId = localStorage.getItem("orgId")
        if (this.props.clientPanel) {
            localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            this.props.changeRole({ role: 'admin', id: auth_uid });
            this.props.getActivityGroupsList(auth_uid, [orgId]);
            this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
            this.props.getactivitiesCalendar(auth_uid);
            this.props.handleHideActivityDetailsPanel();
        }
        this.props.showClientPanel(isShowClientDetail);
    }

    componentDidMount() {
        this.handleOpenNotificationStartActivity();
        this.props.hideActivityHistoryDetail()
        // localStorage.setItem('selectedIndex', 0)
        const { widgetApp } = this.props;
        this.props.selectMenuItem(0)
        const auth_uid = localStorage.getItem('auth_uid');
        const uid = localStorage.getItem('uid');
        const idToken = localStorage.getItem('idToken')
        const orgId = localStorage.getItem('orgId');
        // this.props.networkActivity();
        if (uid != auth_uid) {
            localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            this.props.showClientPanel(false);
            this.props.changeRole({ role: 'admin', id: auth_uid });
            this.props.handleHideActivityDetailsPanel();
        }
        if (this.props.assingnActivityPanel) {
            this.props.showAssignActivityIFramePanel(false)
        }
        if (idToken && auth_uid) {
            this.props.selectActivity(false)
            this.props.getUserById(auth_uid, idToken, widgetApp)
            this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
            this.props.getactivitiesCalendar(auth_uid);
            this.props.getActivityGroupsList(auth_uid, [orgId]);
        }

        if (this.props.location && this.props.location.search) {
            const params = queryString.parse(this.props.location.search)
            this.setState({ role: params.role })
        }
        this.setState({ firebaseToken: idToken });
    }
    handleOpenNotificationStartActivity = () => {
        const params = this.getParams();
        if (params && params.achievementId) {
            console.log(" params.achievementId --> ", params.achievementId);
            let date = Moment().format('YYYY-MM-DD HH:mm:ss Z');
            let uid = localStorage.getItem('auth_uid');
            this.props.executeActivityWithAchievementId(params.achievementId, date, uid);
            this.props.selectActivityId(params.achievementId);
            this.props.handlEditor(true);
            this.props.history.push("/app/lsdashboard");
        }
    }

    getParams() {
        let params = null
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        return params
    }
    handleStartActivity = (e) => {
        this.props.handlEditor(true)
        this.setState({ previewId: null, mobileOpen: true, selected: 'start', activityId: e.activityId, activityType: e.type })
    }

    handleRewards = () => {
        this.props.selectActivity(null)
        this.props.handlEditor(false)
        let uid = localStorage.getItem('uid')
        let idToken = localStorage.getItem('idToken')
        this.props.getUserById(uid, idToken)
        this.setState({ previewId: null, mobileOpen: false, selected: '', activityId: '', activityType: '', showRewards: true })
    }

    handleClose3 = () => {
        this.props.selectActivityId(null)
        this.props.selectActivity(null)
        this.props.handlEditor(false)
        let uid = localStorage.getItem('uid')
        this.setState({ previewId: null, mobileOpen: false, selected: '', activityId: '', activityType: '' })
    }

    handleClose2 = () => {
        this.props.selectActivityId(null)
        this.props.selectActivity(null)
        this.props.handlEditor(false)
        this.setState({ previewId: null, mobileOpen: false, selected: '', activityId: '', activityType: '', openForm: true })
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    previewId = (e) => {
        this.props.selectActivity(true)
        this.props.selectActivityId(e);
        localStorage.setItem('selectedIndex', JSON.stringify(1))
        localStorage.setItem('previewId', e);
        this.props.history.push(`/app/user/my_activities`);
    }

    handleCloseCourse() {
        console.log("%c[LMSCourse]%c Closed LMS iFrame", "color:black", "color:red");
        console.groupEnd();
        this.setState({ coursePanel: false, assingnActivityPanel: false, selectedCourse: '' })
    }
    handleCloseAssignActivity() {
        this.setState({ assingActivityInfo: {} });
        this.props.showAssignActivityIFramePanel(false)
    }
    handleOpenAssingActivity = (assingActivityInfo) => {
        // console.log("assingnActivity data --->", assingActivityInfo)
        console.group("%c[AssignActivityiFrame]%c Rendered Assign Activity iFrame", "color:black", "color:green");
        this.setState({ assingActivityInfo: assingActivityInfo });
        this.props.showAssignActivityIFramePanel(true)
    }

    handleOpenObjectEditor = (type) => {
        console.log("add icon clicked -->", type)
        const widgetServices = new WidgetServices()
        console.log("WidgetServices :", widgetServices.isWidgetApp())
        if (widgetServices.isWidgetApp()) {
            const activityType = type == "notification" ? "Notification" : "General";
            const data = {
                type: activityType
            }
            widgetServices.addRoutineHandler(data)
         } else {
            this.setState({ openEditor: true, activityId: null, editorType: type })
         } 
    }

    handleCloseObjectEditor = () => {
        this.setState({ openEditor: false, activityId: null, editorType: null })
        const auth_uid = localStorage.getItem('auth_uid');
        this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
        this.props.getactivitiesCalendar(auth_uid);
    }

    handleEditActivity = (activityId, editorType) => {
        console.log("edit activity -->", activityId, editorType)
        this.setState({ openEditor: true, activityId: activityId, editorType })
    }
    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh)';
        } else {
            return '630px';
        }
    }

    render() {
        window["auth0"] = this.props.auth0
        let { selected, activityId, activityType, assingActivityInfo } = this.state;
        const {assingnActivityPanel, showActivityHistory, widgetApp } = this.props;
        let list = this.props.selectedRole === 'admin' ? this.props.privateActivities : this.props.allActivities;
        return (
            <div className="w-100 " data-testid="ls-dashboard">
                {
                    showActivityHistory ?
                        <AchievementHistoryList popupThemeClass={this.props.popupThemeClass} />
                        : assingnActivityPanel ?
                            <AssignActivityComponent
                                handleCloseAssignActivity={this.handleCloseAssignActivity.bind(this)}
                                assingActivityInfo={assingActivityInfo}
                            />
                            :
                            <div className="row">
                                <RctCollapsibleCard
                                    colClasses="col-12 w-xs-full"
                                    heading={<div className="d-flex align-items-center justify-content-between text-white">
                                        <div>My Activities</div>
                                        <div   /*onClick={() => this.openActivity("clients")}*/>
                                            {list && list.length > 0 ? "(" + list.length + ")" : ""}
                                        </div>
                                    </div>
                                    }
                                    fullBlock
                                    customClasses="overflow-hidden theme-background "
                                    headingCustomClasses="bg-lifesherpa "
                                >
                                    <LandingPageAllActivity
                                        previewId={this.previewId}
                                        handleStartActivity={this.handleStartActivity}
                                        handleEditActivity={this.handleEditActivity}
                                    />
                                </RctCollapsibleCard>
                                <AddActivity
                                    handleOpenObjectEditor={this.handleOpenObjectEditor}
                                    handleCloseObjectEditor={this.handleCloseObjectEditor}
                                    openEditor={this.state.openEditor}
                                    editorType={this.state.editorType}
                                    activityId={this.state.activityId}
                                />
                            </div>
                }
                {selected &&
                    <SidebarEditorMain
                        selected={selected}
                        previewId={this.state.previewId}
                        showDetails={this.state.showDetails}
                        activityType={activityType}
                        activityId={activityId}
                        handleClose3={this.handleClose3}
                        handleClose2={this.handleClose2}
                        handleRewards={this.handleRewards}
                        handleDrawerToggle={this.handleDrawerToggle}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = ({ authUser, activitiesList, Charts, MyCourses, clientsList, GroupList, Resources, chatAppReducer, settings, emailApp }) => {
    let { clientPanel } = clientsList;
    const { userRole, allowedModules, displayPushNotiAlert, displayOnce, hideRewards } = authUser
    const { allActivities, privateActivities, launchpad, loading1, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails } = activitiesList
    const { usersCharts, loading } = Charts
    const { selectedRole } = chatAppReducer
    let { groupMembers } = GroupList
    const { resourceList, categories } = Resources;
    const { myCourseLoading, myCourses } = MyCourses;
    const { screenThemeClass, popupThemeClass, assingnActivityPanel } = settings;
    const { showActivityHistory } = emailApp;
    return { selectedRole, categories, groupMembers, resourceList, allActivities, privateActivities, userRole, launchpad, loading1, loading, myCourseLoading, myCourses, clientPanel, allowedModules, screenThemeClass, popupThemeClass, assingnActivityPanel, displayPushNotiAlert, displayOnce, showActivityHistory, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, hideRewards }
}

export default withAuth0(connect(mapStateToProps, {
    getlaunchpad,
    selectActivityId,
    selectActivity,
    handlEditor,
    changeRole,
    showClientPanel,
    getActivitiesList,
    getUserById,
    selectMenuItem,
    showAssignActivityIFramePanel,
    getActivityGroupsList,
    executeActivityWithAchievementId,
    networkActivity,
    hideActivityHistoryDetail,
    handleHideActivityDetailsPanel,
    getactivitiesCalendar
})(Activities))
