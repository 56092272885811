import {
    GET_Resource_List,
    GET_categories,
    GET_KEYWORDS,
    GET_RESOURCES_DATA_LOADING,
    HIDE_RESOURCES_DATA_LOADING,
    SET_NEW_REPORT_NOT_FOUND,
    SET_RESOURCES_SELECTED_LIST,
    CLEAR_RESOURCES_REPORTS_DATA,
    GET_RESOURCES_FILTERS,
    HANDLE_CLOSE_POST_VIEWER,
    HANDLE_SHOW_POST_VIEWER,
    SHOW_POST_LOADING,
    HIDE_POST_LOADING,
    SET_RESOURCES_FILTER_ITEMS_COUNT
} from '../actions/types';

const INIT_STATE = {
   
    resourceList:null,
    categories:null,
    loading:false,
    newReportAvailable:true,
    selectedList:null,
    keywords:null,
    resourceFilters: null,
    showPostViewer: false, 
    selectedPost: null,
    postLoading: false,
    filterItemsCount: 0
};

export default (state = INIT_STATE, action) => {
   switch (action.type) {
    case GET_RESOURCES_DATA_LOADING:
        return { ...state, loading: true };
    case GET_Resource_List:
        return { ...state, resourceList:action.payload, loading: false };
    case GET_categories:
        return { ...state, categories:action.payload ,loading: false }; 
    case GET_KEYWORDS:
        return {...state,keywords:action.payload ,loading:false }
    case HIDE_RESOURCES_DATA_LOADING:
        return { ...state, loading: false };
    case SET_NEW_REPORT_NOT_FOUND:
        return { ...state, newReportAvailable: action.payload };
    case SET_RESOURCES_SELECTED_LIST:
        return { ...state, selectedList: action.payload };
    case CLEAR_RESOURCES_REPORTS_DATA:
        return { ...state, selectedList: null, newReportAvailable:true,resourceList:null,categories:null,keywords:null};
    case GET_RESOURCES_FILTERS:
        return { ...state, loading: false, resourceFilters: action.payload };
    case HANDLE_CLOSE_POST_VIEWER:
        return { ...state, showPostViewer: false, selectedPost: null };
    case HANDLE_SHOW_POST_VIEWER:
        return { ...state, showPostViewer: true, selectedPost: action.payload, postLoading: false };
    case SHOW_POST_LOADING:
        return { ...state, postLoading: true };
    case HIDE_POST_LOADING:
        return { ...state, postLoading: false };
    case SET_RESOURCES_FILTER_ITEMS_COUNT:
        return { ...state, filterItemsCount: action.payload};
    default:
        return { ...state };
    }
}