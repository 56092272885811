/**
 * Clients List Actions
 */

import {axiosClient} from '../sherpaApi'
import {getUpdatedEvaluationForm, getPokeForm, setSelectedStep} from '../actions'

import {
    SET_SELECTED_UNCONFIRM_ACTIVITIES,
    SET_UNCONFIRM_ACTIVITIES_LIST,
    SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST,
    SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL,
    SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL,
    SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA,
    SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT,
    SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR,
    SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST,
    HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST,
} from './types';

export const clearUnconfirmData = () => ({
    type: SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR
})
export const setUnconfirmPageCount = (page) => ({
    type: SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT,
    payload: page
});
export const setUnconfirmHasMoreData = (hasMore) => ({
    type: SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA,
     payload: hasMore
 })

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const setSelectedUnConfirmActivity = (activity) => ({
    type: SET_SELECTED_UNCONFIRM_ACTIVITIES,
    payload: activity
});

export const getUnConfirmtActivitiesList = (memberId, page) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${memberId}/unconfirmed-achievements` ///users/{userId}/unconfirmed-achievements
    const params = {
        'page': page ? page : 1,
        'limit': 20,
        'order': 'desc',
        'summary': true
    }
    dispatch({ type: SET_UNCONFIRM_ACTIVITIES_LIST });
    axiosClient.get(url, {params: params, headers: {
        'Authorization': `Bearer ${idToken}`
     }
    }).then((response) => {
        dispatch({ type: SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST, payload: response.data });
        dispatch
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST });
     })
}

export const getUnConfirmActivityDetail = (userId, activityId, type) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/achievements/${activityId}?organization=${orgId}` ///users/{userId}/achievements/{achievementId}
    dispatch({ type: SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if (type === 'evaluationForm' && response.data) {
            if(response.data.evaluationResponseId) {
                dispatch(getUpdatedEvaluationForm(userId, response.data.evaluationResponseId))
            } else {
                dispatch(getPokeForm(response.data.routine.evaluationId))
            }
            dispatch(setSelectedStep(null))
        } else if (type === 'stepNotesForm' && response.data.routine) {
            if(response.data.StepsCompletion) {
                let stepNotesResponseId = null
                Object.keys(response.data.StepsCompletion).map((obj, key) => {
                    if (response.data.StepsCompletion[obj]) {
                        stepNotesResponseId = response.data.StepsCompletion[obj].stepNotesResponseId
                    }
                })
                dispatch(getUpdatedEvaluationForm(userId, stepNotesResponseId))
            } else {
                dispatch(getPokeForm(response.data.routine.stepNotesId))
            }
            dispatch(setSelectedStep(response.data.routine && Object.keys(response.data.routine.steps)[0]))
        }else {
            dispatch({ type: SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL, payload: response.data });
        }
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST });
     })
}

/**
 * Redux Action To Update Client Search
 */
export const showUnConfirmActivitiesListLoading = () => ({
    type: SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST,
});

/**
 * export const to search Clients
 */
export const hideUnConfirmActivitiesListLoading = () => ({
    type: HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST,
});


