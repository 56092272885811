/**
 * Charts Reducers
 */
import {
    GET_CHARTS_LIST,
    GET_SUCCESS_CHARTS_LIST,
    GET_CHART_BY_CHART_ID,
    GET_SUCCESS_CHART_BY_CHART_ID,
    FILTER_CHART_DATA,
    FILTER_SUCCESS_CHART_DATA,
    SHOW_CHARTS_LOADING,
    HIDE_CHARTS_LOADING,
 } from '../actions/types';
 
 /**
  * initial Charts data
  */
 const INIT_STATE = {
     usersCharts: null,
     activitiesCharts: null,
     loading: false,
     loadingComponent: {}
 };
 
 export default (state = INIT_STATE, action) => {
     switch (action.type) {
 
        case GET_CHARTS_LIST:
            return { ...state, loading: true, usersCharts: null };
 
        case GET_SUCCESS_CHARTS_LIST:
            const loadingComponent = {}
            const rawData = action.payload
            if (rawData.length > 0){
                rawData.map(item => {
                    loadingComponent[item.chartId] = true
                })
            }
            return { ...state, usersCharts: action.payload, loading: false, loadingComponent };

        case GET_CHART_BY_CHART_ID:
            return { ...state, loading: true };

        case GET_SUCCESS_CHART_BY_CHART_ID:
            const {data, chartId} = action.payload
            let {usersCharts} = state
            usersCharts.map(chartItem => {
                if (chartItem.chartId === chartId){
                    chartItem.labels = data.labels
                    chartItem.data = data.data
                }
            })
            return { ...state, usersCharts, loading: false};

        case FILTER_CHART_DATA:
            let clearUsersCharts = state.usersCharts
            const loadingComponentFilter = {}
            clearUsersCharts.map(chartItem => {
                    chartItem.labels = []
                    chartItem.data = []
                    loadingComponentFilter[chartItem.chartId] = true
            })
            return { ...state, usersCharts: clearUsersCharts, loadingComponent: loadingComponentFilter};

        case FILTER_SUCCESS_CHART_DATA:
            const {filterData, filterChartId} = action.payload
            const usersChartsData = state.usersCharts
            const updatedLoadingComponent = state.loadingComponent
            usersChartsData && usersChartsData.map(chartItem => {
                if (chartItem.chartId === filterChartId){
                    chartItem.labels = filterData.labels
                    chartItem.data = filterData.data
                    updatedLoadingComponent[chartItem.chartId] = false
                }
            })
            return { ...state, usersCharts: usersChartsData, loadingComponent: updatedLoadingComponent};

        case SHOW_CHARTS_LOADING: 
            return{...state, loading: true}

        case HIDE_CHARTS_LOADING:
            return{...state, loading: false, loadingComponent: {}}
        

        default: 
            return { ...state };
     }
 }
 