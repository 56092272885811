/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';
import AppConfig from 'Constants/AppConfig';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert'
import LoginWithToken from './LoginWithToken';
// redux action
import {
    getUserOrgAndRegion,
    getUserDetailFromToken,
    clearUserOrgAndRegion,
    clearValidateInviteCodeError,
} from '../actions';

import LSInput from "../components/CustomInput/LSInput"

class Signin extends Component {

   state = {
      email: '',
      token: '',
      emailEmpty: null,
      inValidEmail: null,
      emailExist: true,
      tokenEmpty: null,
      isContinueEnabled: null,
      loginAlert:false,
      showErrorAlert: false,
   }

   componentDidMount() {
      this.props.clearUserOrgAndRegion();
      this.props.clearValidateInviteCodeError();
      console.group("%c[SigninFirebase] %c Redirected on signin with email screen","color:black","color:green");
   }

   validateUsername(username){
      if(username && username.length > 2 && username.length < 100){
         return true
      } else {
         return false
      }
   }
     
   getUserOrganizationFromEmail(e) {
      e.preventDefault();
      if (this.state.email === ''){
         this.setState({emailEmpty: true});
         console.log("%cPlease enter username/email ","color:red");
      } else if (!this.validateUsername(this.state.email)) {
         console.log("%cPlease enter username/email of length between 3 to 100 characters ","color:red");
         this.setState({emailEmpty: true})
      }/*else if (!(this.validateEmail(this.state.email))){
         this.setState({inValidEmail: true})
      }*/ else {
         console.log("Authenticating username/email.....");
         this.props.getUserOrgAndRegion(this.state.email, this.props.history)
      }
   }

   getUserOrganizationFromToken(e) {
      console.log("%cClinking on submit button ","color:green");
      e.preventDefault();
      if (this.state.token === ''){
         this.setState({tokenEmpty: true});
         console.log("%cPlease enter email/username ","color:red");
      } else {
         this.props.getUserDetailFromToken(this.state.token, this.props.history)  
      }

   }

   //validating the email address
   validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
   }

   handleBack=()=>{
      this.setState({loginAlert:false})
      this.props.history.push('/')
   }

   handleChange = (e) => {
      const { userOrgDetail, userOrgFromToken } = this.props;
      const showTokenfield = userOrgDetail && (userOrgDetail.region  === "" || userOrgDetail.message) ? true : false 
      if(showTokenfield) {
         this.props.clearUserOrgAndRegion();
      }
      const emailEmpty = e.target.value === '' || !this.validateUsername(e.target.value) ? true : false
      this.setState({ email: e.target.value, emailEmpty});
   }

   handleAlertMessage(){
      localStorage.removeItem('error-message')
      this.setState({showErrorAlert: false})
   }

   render() {

      const { loading, userOrgDetail, userOrgFromToken, screenThemeClass, themeData } = this.props;
      const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 
      const showTokenfield = userOrgDetail && (userOrgDetail.region  === "" || userOrgDetail.message) ? true : false 
      const showTokenInvalid = userOrgFromToken && userOrgFromToken.status && userOrgFromToken.status === 'failed' && userOrgFromToken.inviteCode && (userOrgFromToken.inviteCode === this.state.token) ? true : false
      let themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
      let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};

      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={`rct-session-wrapper ${screenThemeClass}`}>
               <div className='login-screen' style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
                  {loading &&
                     <LinearProgress />
                  }
                  {this.state.loginAlert ?
                     <div className="col-12 p-4">
                        <SweetAlert
                           warning
                           btnSize="sm"
                           customClass="warningText"
                           confirmBtnText="OK"
                           confirmBtnBsStyle="warning"
                           title="Login"
                           onConfirm={()=>this.handleBack()}
                        >
                           <div style={{fontFamily:"'Lato'",fontWeight:400}}>Not a valid user!</div>
                        </SweetAlert>
                     </div>
                     : localStorage.getItem('error-message') &&
                     <div className="col-12 p-4">
                        <SweetAlert
                           danger
                           btnSize="sm"
                           customClass="dangerText"
                           confirmBtnText="OK"
                           confirmBtnBsStyle="danger"
                           title="Alerts!"
                           onConfirm={() => this.handleAlertMessage()}
                        >
                           <div style={{fontFamily:"'Lato'",fontWeight:600}}>{localStorage.getItem("error-message")}</div>
                        </SweetAlert>
                     </div>
                     }
                  <AppBar position="static" className="session-header">
                     <Toolbar>
                        <div className="container">
                           <div className="d-flex justify-content-center">
                              <div className="session-logo">
                              {activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
                                    <Link to="/">
                                       <img src={themeLogo || applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img' />
                                    </Link>
                                    :
                                    <Link to="/" className="text-center">
                                       <img src={themeLogo || applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img'/>
                                       <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                                       {themeData && themeData.loginLifeSherpaImage &&
                                          <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                                       }
                                    </div>
                                    </Link>
                                 }
                              </div>
                           </div>
                        </div>
                     </Toolbar>
                  </AppBar>
                  <div className="session-inner-wrapper">
                     <div className="container">
                        <div className="row row-eq-height">
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className={"session-body text-center text-white my-auto"}>
                                 <div className="session-head mb-30">
                                    <p className="base-font-color">Login</p>
                                 </div>
                                 <Form onKeyPress={e => {
                                    if (this.props.status && this.props.status === 'failed') this.props.clearValidateInviteCodeError();
                                    }}>
                                    <FormGroup className="has-wrapper">
                                       <LSInput
                                          datatestid="signin-email-text-field"
                                          type="text"
                                          value={this.state.email}
                                          autoCapitalize="none"
                                          name="user-mail"
                                          id="user-mail"
                                          className="has-input input-lg text-center w-100"
                                          placeholder="Username/Email"
                                          invalid={(this.state.emailEmpty ? true : false) || showTokenfield}
                                          onChange={(e) => this.handleChange(e)}
                                          onKeyPress={e => {
                                             if (e.key === 'Enter') this.getUserOrganizationFromEmail(e);
                                          }}
                                       />
                                       {this.state.emailEmpty ?
                                          <FormFeedback data-testid="empty-signin-email-address" style={{ fontSize: 'larger' }}>Please enter a valid username/email of length between 3 to 50 characters. It is a required field.</FormFeedback>
                                          : this.state.inValidEmail ?
                                             <FormFeedback data-testid="invalid-signin-email-format" className="d-block" style={{ fontSize: 'larger' }}>Invalid email address format.  Please provide a valid email address</FormFeedback>
                                             : showTokenfield &&
                                             <FormFeedback data-testid="signin-email-does-not-exist" className="d-block" style={{ fontSize: 'larger' }}>{userOrgDetail && userOrgDetail.message != "Invalid email/username or user doesn't exist." ? userOrgDetail.message : "This username/email is not registered with us. You may use an invitation token to register with us."}</FormFeedback>
                                       }
                                    </FormGroup>
                                    {/*showTokenfield && <LoginWithToken/>*/}
                                    <FormGroup>
                                       <Button
                                          data-testid="signin-continue"
                                          className="btn btn-block w-100 login-screen-button "
                                          variant="contained"
                                          size="large"
                                          onClick={(e) => this.getUserOrganizationFromEmail(e)}
                                          style={this.state.isContinueEnabled ? { backgroundColor: 'gray', fontSize: 'large' } : { fontSize: 'large' }}
                                          disabled={this.state.isContinueEnabled}
                                       >
                                          Continue
                                       </Button>
                                    </FormGroup>
                                 </Form>
                              </div>
                           </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </QueueAnim>
      );
   }
}

const mapStateToProps = ({ authUser,settings }) => {
   const { user, loading, userOrgDetail, userOrgFromToken ,inviteCode, status, themeData} = authUser;
   const { screenThemeClass, themeLogo} = settings; 
   return { user, loading, userOrgDetail, userOrgFromToken, status,screenThemeClass, themeLogo, themeData}
}

export default withRouter(connect(mapStateToProps, {
   getUserOrgAndRegion,
   getUserDetailFromToken,
   clearUserOrgAndRegion,
   clearValidateInviteCodeError,
})(Signin));


