import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import IconButton from '@mui/material//IconButton';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollMaincontainerToTop, openPopup, doVideoCall, getFormatedCallTime } from "../../helpers/helpers";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PeopleIcon from '../SVG/PeopleIcon'
import MissedVideoCallRoundedIcon from '@mui/icons-material/MissedVideoCallRounded';
import LifeSherpaLoading from '../../components/LifeSherpaLoading';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Avatar } from '@mui/material';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import {
	networkActivity,
	setSelectedMemberId,
	changeRole,
	getlaunchpad,
	getUnConfirmtActivitiesList,
	clearUnconfirmData, getSelectedGroup,
	handleListStyle,
	handleHideActivityDetailsPanel,
	showActivityHistoryDetail,
	getClientUnconfirmedAchievementsList,
	clearHistoryData,
	addSelectedChatUser,
	setClientActivitiesType,
	gettouchpointActivitiesSummary,
	getUserInfo,
	setSelectedUserInfo,
	handleNoticesPanel,
	handleOpenLauncPadUrl,
	setUserSelected,
	getMissedVideoCallSummary,
	handleUserWorkflowPanel,
	getUserPendingWorkflowList
} from '../../actions'
import { connect } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SherpaPopup from '../SherpaPopup'
import WidgetServices from "../../services/widgetServices";
import config from '../../config/config'
import VideoCallHistoryPopup from './components/VideoCallHistoryPopup';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WorkflowNotices from './components/WorkflowNotices';

class CenterScreen extends Component {
	state = {
		showList: false,
		openForm: false,
		selUser: null,
		id: null,
		encounterActivities: "",
		showAlertsList: false,
		showReports: false,
		missedCallSection: "",
		openCallHistory: false
	}
	componentDidMount() {
		let uid = localStorage.getItem('auth_uid')
		const {widgetVersion} = this.props
		const orgId = localStorage.getItem("orgId")
		if ( widgetVersion && (widgetVersion === 1 || widgetVersion === 2)) {
			this.props.networkActivity([orgId])
			this.props.getlaunchpad([orgId])
			this.props.gettouchpointActivitiesSummary(uid, [orgId])
		} else {
			this.props.networkActivity()
			this.props.getlaunchpad()
			this.props.gettouchpointActivitiesSummary(uid)
			this.props.getMissedVideoCallSummary()
		}
		
	}
	
	handleActivity = () => {
		// scrollMaincontainerToTop();
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp()) {
			widgetServices.agendaViewActivity();
		} else {
			this.props.handleListStyle("summary")
		}
	}

	handleWorkflow = () => {
		const userId = localStorage.getItem("auth_uid")
		this.props.handleUserWorkflowPanel(true, userId)
	}

	switchChatWithUser(user) {
		let groupId = '';
		let userGroups = [];
		const {groupsByOrg = []} = user;
		const {organization, groups = []} = groupsByOrg[0] || {};
		if (groups && groups.length > 0) {
			userGroups = groups.map(group => ({id: group.groupId, name: group.groupName, orgId: organization}));
			groupId = groups[0].groupId;
			// Object.keys(user.groups[0]).map((dateObjKey, key) => {
			// 	groupId = dateObjKey
			// })
		}
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp() && !!groupId) {
			const data = { clientId: user.userId, groupId: groupId, groups: userGroups, organization};
			widgetServices.openClientDetailsHandler(data);
		} else if (!!groupId) {
			user.groupId = groupId;
			this.props.getSelectedGroup(userGroups, this.props.selectedGroup)
			// } else {
			// 	this.props.getUserInfo(user.userId).then(result => {
			// 		const userData = result || {};
			// 		const { displayName, firstName, lastName } = userData;
			// 		userData.name = displayName || `${firstName || 'Client'} ${lastName || ''}`;
			// 		this.props.setSelectedUserInfo(userData);
			// 	})
			// }
			localStorage.setItem('uid', user.userId);
			localStorage.setItem("selectedOrgId", organization);
			this.props.setUserSelected(user);
			this.props.changeRole({ role: 'client', id: user.userId })
			this.props.setSelectedMemberId(user.userId)
			this.props.handleHideActivityDetailsPanel();
			scrollMaincontainerToTop();
			this.props.showUserDetails(true);
		}
		
	}


	hnadleOpenUnconfirmAchievements = (user) => {
		this.props.clearHistoryData();
		let groupId = '';
		let userGroups = [];
		const {groupsByOrg = []} = user;
		const {organization, groups = []} = groupsByOrg[0] || {};
		if (groups && groups.length > 0) {
			userGroups = groups.map(group => ({id: group.groupId, name: group.groupName, orgId: organization}));
			groupId = groups[0].groupId;
			// Object.keys(user.groups[0]).map((dateObjKey, key) => {
			// 	groupId = dateObjKey
			// })
		}
		const activityConfirmation = localStorage.getItem("activityConfirmation");
		if (activityConfirmation) {
			localStorage.removeItem("activityConfirmation");
		}
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp()) {
			const data = { clientId: user.userId, groupId: groupId, groups: userGroups, organization};
			widgetServices.openUnconfirmedAchievementsHandler(data);
		} else {
			if (groupId) {
				this.props.getSelectedGroup(userGroups, this.props.selectedGroup)
			}
			localStorage.setItem('uid', user.userId);
			localStorage.setItem("selectedOrgId", organization)
			this.props.changeRole({ role: 'client', id: user.userId })
			this.props.setSelectedMemberId(user.userId)
			this.props.getClientUnconfirmedAchievementsList(user.userId);
			scrollMaincontainerToTop();
			this.props.showActivityHistoryDetail(true);
		}

	}

	openClientEncounterActivities = (user) => {
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp() && !!user.groupId) {
			const data = { clientId: user.userId, groupId: user.groupId, organization: user.organization };
			widgetServices.openEncounterActivitiesHandler(data);
		} else if (!!user.groupId) {
			//this.props.getSelectedGroup(user.groupId, this.props.selectedGroup);
			localStorage.setItem('uid', user.userId);
			localStorage.setItem("selectedOrgId", user.organization)
			this.props.changeRole({ role: 'client', id: user.userId })
			this.props.setSelectedMemberId(user.userId)
			this.props.setUserSelected(user);
			this.props.handleHideActivityDetailsPanel();
			this.props.setClientActivitiesType("Touchpoint")
			scrollMaincontainerToTop();
			this.props.showUserDetails(true);
		}

	}

	handleOpenUserChatPanel = (user) => {
		const { contactList } = this.props;
		let groupId = '';
		let userGroups = [];
		const {groupsByOrg = []} = user;
		const {organization, groups = []} = groupsByOrg[0] || {};
		if (groups && groups.length > 0) {
			userGroups = groups.map(group => ({id: group.groupId, name: group.groupName, orgId: organization}));
			groupId = groups[0].groupId;
			// Object.keys(user.groups[0]).map((dateObjKey, key) => {
			// 	groupId = dateObjKey
			// })
		}
	
		let chatUser = contactList ? contactList.find(contact => contact.userId == user.userId) : null;
		user = chatUser || user;
		user['organization'] = organization;
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp()) {
			const data = { clientId: user.userId, organization };
			widgetServices.openClientChatHandler(data)
		} else {
			this.showChatPanelForSelectedUser(user);
		}
	}

	showChatPanelForSelectedUser = (chatUser) => {
		let { selectedChatUsers } = this.props;
		console.log(" Chat opening for user ->", chatUser);
		if(chatUser.organization) {
			localStorage.setItem('selectedOrgId', chatUser.organization);
		}
		if (selectedChatUsers && selectedChatUsers.length > 0) {
			let index = selectedChatUsers.findIndex(user => user && user.userId == chatUser.userId);
			if (index >= 0) {
				// selectedChatUsers.splice(index,1)
				// this.props.addSelectedChatUser(selectedChatUsers);
				// selectedChatUsers.push(chatUser);  
			} else {
				selectedChatUsers.push(chatUser);
			}
		} else {
			let usersList = [];
			usersList.push(chatUser);
			selectedChatUsers = usersList;
		}

		console.log(" Chat opened ->", selectedChatUsers);
	
		this.props.addSelectedChatUser(selectedChatUsers);
	}

	handleOpenClientAlerts = async(user) => {
		let groupId = '';
		let userGroups = [];
		const {groupsByOrg = []} = user;
		const {organization, groups = []} = groupsByOrg[0] || {};
		if (groups && groups.length > 0) {
			userGroups = groups.map(group => ({id: group.groupId, name: group.groupName, orgId: organization}));
			groupId = groups[0].groupId;
			// Object.keys(user.groups[0]).map((dateObjKey, key) => {
			// 	groupId = dateObjKey
			// })
		}
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp()) {
			const data = { clientId: user.userId, organization };
			widgetServices.handleOpenNoticePanel(data);
		} else {
			if(organization) {
				localStorage.setItem('selectedOrgId', organization);
			}
			if (groupId) {
				this.props.getSelectedGroup(userGroups, this.props.selectedGroup);
				this.props.handleNoticesPanel(true, user.userId);
			} else {
				await this.props.getUserInfo(user.userId)
				this.props.handleNoticesPanel(true, user.userId);
			}
		}
	}

	handleOpenPopup = () => {
		this.props.clearUnconfirmData()
		this.setState({ openForm: !this.state.openForm })
	}

	getScrollHeight() {
		if (localStorage.getItem("widgetApp")) {
			return 'calc(100vh - 0px)';
		} else {
			return '630px';
		}
	}
	openLaunchpadUrl = (e, launch) => {
		e.stopPropagation();
		e.preventDefault();
  		e.nativeEvent.stopImmediatePropagation();
		const widgetServices = new WidgetServices()
		if (widgetServices.isWidgetApp()) {
			const data = { url: launch.url }
			widgetServices.launchpadUrlHandler(data)
		} else {
			// window.open(url, "_blank");
			this.props.handleOpenLauncPadUrl({data:launch, show: true})
			// var windowReference = window.open();
			// if(windowReference) {
			// 	windowReference.location = launch.url;
			// 	openPopup(windowReference)
			// }
		}
	}

	handleOpencallHistory(){
		this.setState({openCallHistory: true})
	}

	handleCloseCallHistory() {
		this.setState({openCallHistory: false});
	}

	handleVideoCall(callItem) {
        doVideoCall(callItem)
	}

	render() {
		const { networkActivityLoading, userRole, summaryLoading, touchpointActivitiesSummary, themeLoading, userLoading, widgetVersion, noActiveWorkflowLoading,userHasNoActiveWorkflow, popupThemeClass, videoCallSummary, videoCallSummaryDetail, videoCallSummaryLoading, missedP2PVideoCallCount, missedGroupVideoCallCount, missedVCallClientCount, userWorkflows, userWorkflowLoading, enableWorkflows } = this.props;
		const {openCallHistory} = this.state
		let unconfirmedAchievements = 0;
		let msg = 0;
		let unreadClientAlerts = 0;
		const unreadClientAlertsList = [];
		const clientActivitiesList = [];
		this.props.networkActivities && this.props.networkActivities.map((act) => {
			unconfirmedAchievements = unconfirmedAchievements + act.unconfirmedAchievements;
			msg = act.unreadMessages ? msg + act.unreadMessages : msg;
			unreadClientAlerts = act.unreadClientAlerts ? unreadClientAlerts + act.unreadClientAlerts : unreadClientAlerts;
			if (act.unreadClientAlerts) {
				unreadClientAlertsList.push(act)
			}
			if(act.unreadMessages || act.unconfirmedAchievements) {
				clientActivitiesList.push(act);
			}
		})
		let totalEncoounterActivitiesInprogress = 0;
		if (touchpointActivitiesSummary && touchpointActivitiesSummary.length) {
			totalEncoounterActivitiesInprogress = touchpointActivitiesSummary.reduce((acc, curr) => curr.touchpointsInProgressCount ? acc + curr.touchpointsInProgressCount : acc, 0);
		}
		const assignActivityUrl = config.zohoReportsUrl

		return (
			<div>
				<Scrollbars
					className="rct-scroll "
					autoHide
					style={{ height: this.getScrollHeight() }}
				>
					<div className=" dashboard-summary-panal-content lifesherpa-session-theme-support">
						<LifeSherpaLoading loading={ (themeLoading || userLoading) ? false : (summaryLoading ||  networkActivityLoading || noActiveWorkflowLoading || userWorkflowLoading)} />
						<div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2">
							<h3 className="d-flex  mx-2 my-2 heading-text">My Notices</h3>
							<hr className="mt-1 mb-2" />
							<div className="d-flex align-items-center" onClick={() => this.handleActivity()}>
								<span className="badge-primary px-2 mx-2 my-auto" data-testid="expiring-activity-count" >{this.props.activityCalender ? this.props.activityCalender.expiredActivity : 0}</span>
								<div className="heading-text cps">Expiring Activity</div>
							</div>
							<hr className="my-2" />
							<div className="d-flex align-items-center" onClick={() => this.handleActivity()}>
								<span className="badge-primary px-2 mx-2 my-auto" data-testid="activity-started-soon-count">{this.props.activityCalender ? this.props.activityCalender.startedActivity : 0}</span>
								<div className="heading-text cps">Activity should be started soon</div>
							</div>
						</div>
						{!widgetVersion && missedVCallClientCount && missedVCallClientCount > 0 ?
							<div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2">
								<div className='cursor-pointer' onClick={() => this.setState({ missedCallSection: !this.state.missedCallSection })}>
									<div className="d-flex mx-2 my-1 align-items-center justify-content-between" >
										<h3 className="heading-text" >Missed Video Calls</h3>
										{missedVCallClientCount > 0 &&
											<IconButton title={this.state.missedCallSection ? "Missed Call User Less" : "Missed Call User Expend More"} className="cps p-1 m-0">{this.state.missedCallSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
										}
									</div>
									<hr className="mt-1 mb-2" />
									<div className="d-flex sub-text mx-2 mt-2">
										{missedVCallClientCount + ' People in your contact attempting to get in touch with you.'}
									</div>
									<hr className="my-2" />
									<div className="d-flex align-items-center mx-2 mt-2">
										<MissedVideoCallRoundedIcon className="mr-2 text-danger" color='danger' width="24px" height="24px" />
										<div className="heading-text text-danger">{`${missedP2PVideoCallCount} Missed Video Calls (Personal call)`}</div>
									</div>
									{missedGroupVideoCallCount && missedGroupVideoCallCount > 0 ?
										<>
											<hr className="my-2" />
											<div className="d-flex align-items-center mx-2 mt-2">
												<MissedVideoCallRoundedIcon className="mr-2 text-danger" color='danger' width="24px" height="24px" />
												<div className="heading-text text-danger">{`${missedGroupVideoCallCount} Missed Video Calls (Group)`}</div>
											</div>
										</>
										:<></>
									}
								</div>
								{this.state.missedCallSection && videoCallSummary && videoCallSummary.length > 0 && videoCallSummary.map((missedUser, index) =>
									<div className="mx-2" key={`${missedUser.name}-${missedUser.userId}-${index}`} >
										<hr className="my-2" />
										<div className="d-flex align-items-center justify-content-between cps" data-testid={"client-missed-call-details-" + index}>
											<div className="avatar-wrap align-self-center position-relative" onClick={() => this.handleOpencallHistory()} >
												<div className='d-flex align-items-center'>
													{missedUser.group ? 
														<Avatar alt="Remy Sharp" className="align-self-center user-later-avatar" ><GroupRoundedIcon />{/*missedUser.name.charAt(0)*/}</Avatar>
														: missedUser.profileImageURL ?
														<img src={missedUser.profileImageURL} data-testid="missed-call-client-image" className="rounded-circle align-self-center" width="40" height="40" />
														: <Avatar width="40" height="40" className="align-self-center user-later-avatar" data-testid="mmissed-call-client-avatar">{missedUser.name ? missedUser.name.charAt(0) : "D"}</Avatar>
													}
													<div>
														<div className="ml-2 text-danger text-underline" >{missedUser.name}</div>
														<p className='ml-2 mb-0 text-danger'>{getFormatedCallTime(missedUser?.lastUpdated)}</p>
													</div>
												</div>
											</div>
											<div className='d-flex align-items-center' onClick={() => this.handleVideoCall(missedUser)}>
												<div className="text-danger" >{missedUser.videoCallsCount}</div>
												<IconButton className='ml-2 bg-danger'><MissedVideoCallRoundedIcon className="text-white" /></IconButton>
											</div>
										</div>
									</div>
								)}
							</div>
							: <></>

						}
						{enableWorkflows ? <WorkflowNotices widgetVersion={widgetVersion} enableWorkflows={enableWorkflows}/> : ""}
						{userRole && userRole !== 'Client' && totalEncoounterActivitiesInprogress ?
							<div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2">
								<div className='cursor-pointer' onClick={() => this.setState({ encounterActivities: !this.state.encounterActivities })}>
									<div className="d-flex mx-2 my-1 align-items-center justify-content-between" >
										<h3 className="heading-text" >Client Touchpoints</h3>
										{touchpointActivitiesSummary.length > 0 &&
											<IconButton title={this.state.encounterActivities ? "Touchpoints Expend Less" : "Touchpoints Expend More"} className="cps p-1 m-0">{this.state.encounterActivities ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
										}
									</div>
									<hr className="mt-1 mb-2" />
									<div className="d-flex sub-text mx-2 mt-2">
										{touchpointActivitiesSummary.length + ' People in your network require your attention'}
									</div>
									<hr className="my-2" />
									<div className="d-flex align-items-center mx-2 mt-2">
										{/* <CallMergeIcon className='mr-2 color-primary' /> */}
										{/* <img className="mr-2 color-primary" width="24" height="24" src={require('../../assets/img/people-arrows-left-right-solid.svg')} /> */}
										<PeopleIcon className="people-left-right-icon mr-2" width="24px" height="24px" />
										<div className="heading-text">{`${totalEncoounterActivitiesInprogress} Pending activities`}</div>
									</div>
								</div>
								{this.state.encounterActivities && touchpointActivitiesSummary.length > 0 && touchpointActivitiesSummary.map((encounter, index) =>
									<div className="mx-2" key={encounter.userId} >
										<hr className="my-2" />
										<div className="d-flex align-items-center justify-content-between cps" onClick={() => this.openClientEncounterActivities(encounter)} data-testid={"client-touchpoint-details-" + index}>
											<div className={encounter.groupId ? "color-primary text-underline w-70 " : "color-primary w-70 "} >{encounter.name}</div>
											{/* {encounter.touchpointsInProgressCount &&
												<Badge color={"warning"} className="px-2 font-md">{`${encounter.touchpointsInProgressCount} In progress`}</Badge>
											} */}
											{encounter.touchpointsInProgressCount > 0 &&
												<div className="d-flex align-items-center ml-auto cps">
													<div className="my-auto heading-text">{encounter.touchpointsInProgressCount}</div>
													{/* <CallMergeIcon className="my-auto color-primary ml-1" /> */}
													{/* <img className="ml-2 color-primary" width="24" height="24" src={require('../../assets/img/people-arrows-left-right-solid.svg')} /> */}
													<PeopleIcon className="people-left-right-icon ml-2" width="24px" height="24px" />
												</div>
											}
										</div>
									</div>
								)}
							</div>
							: <></>
						}

						{userRole && userRole !== 'Client' && clientActivitiesList && clientActivitiesList.length > 0 ?
							<div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2" >
								<div className='cursor-pointer' onClick={() => this.setState({ showList: !this.state.showList })}>
									<div className="d-flex mx-2 my-1 align-items-center justify-content-between" >
										<h3 className="heading-text" >Client Activities</h3>
										<IconButton className="cps p-1 m-0" title={this.state.showList ? "Client Activities Expend Less" : "Client Activities Expend More"}>{this.state.showList ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
									</div>
									<hr className="mt-1 mb-2" />
									<div className="d-flex sub-text mx-2 mt-2" >
										{clientActivitiesList.length + ' People in your network require your attention'}
									</div>
									<hr className="my-2" />
									<div className="d-flex align-items-center mx-2 mt-2" >
										<AssignmentTurnedInOutlinedIcon className='mr-2 color-primary' />
										<div className="heading-text">{unconfirmedAchievements + " Unconfirmed activities"}</div>
									</div>
									<hr className="my-2" />
									<div className="d-flex align-items-center mx-2 mt-2" >
										<ChatOutlinedIcon className='mr-2 color-primary' />
										<div className="heading-text">{msg > 1 ? msg + ' New messages' : msg + ' New message'}</div>
									</div>
								</div>
								{this.state.showList && clientActivitiesList.map((act, index) => (
									<div className="mx-2" key={index} data-testid={"client-network-details-" + index}>
										<hr className="my-2" />
										<div className="row">
											<div className={act.groups && act.groups.length > 0 ? "col-6 color-primary text-underline cps" : "col-6 color-primary"} onClick={() => this.switchChatWithUser(act)}>{act.name}</div>
											<div className="col-6 text-right my-auto">
												<div className="d-flex ml-auto">
													{act.unreadMessages > 0 &&
														<div className="d-flex align-items-center ml-auto cps" onClick={() => this.handleOpenUserChatPanel(act)}>
															<div className="my-auto heading-text">{act.unreadMessages}</div>
															<ChatOutlinedIcon className="my-auto color-primary ml-1" />
														</div>
													}
													{act.unconfirmedAchievements > 0 &&
														<div className="d-flex align-items-center ml-2 cps" data-testid={"unconfirmed-achievements-" + index} onClick={() => this.hnadleOpenUnconfirmAchievements(act)}>
															<div className="my-auto heading-text">{act.unconfirmedAchievements}</div>
															<AssignmentTurnedInOutlinedIcon className="my-auto color-primary ml-1" />
														</div>
													}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
							: <></>
						}

						{(!widgetVersion || (widgetVersion && (widgetVersion === 2 || widgetVersion === 3))) && userRole && userRole !== 'Client' && unreadClientAlerts > 0 ?
							<div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2" >
								<div className='cursor-pointer' onClick={() => this.setState({ showAlertsList: !this.state.showAlertsList })}>
									<div className="d-flex mx-2 my-1 align-items-center justify-content-between" >
										<h3 className="heading-text" >Client Alerts</h3>
										<IconButton className="cps p-1 m-0" title={this.state.showAlertsList ? "Client Activities Expend Less" : "Client Activities Expend More"}>{this.state.showAlertsList ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
									</div>
									<hr className="mt-1 mb-2" />
									<div className="d-flex sub-text mx-2 mt-2" >
										{unreadClientAlertsList.length + ' People in your network require your attention'}
									</div>
									<hr className="my-2" />
									<div className="d-flex align-items-center mx-2 mt-2" >
										<WarningRoundedIcon className='mr-2 color-primary' />
										<div className="heading-text">{unreadClientAlerts + " Alerts"}</div>
									</div>
								</div>
								{this.state.showAlertsList && unreadClientAlertsList.map((act, index) => (
									<div className="mx-2" key={index + "alert"} data-testid={"client-alert-details-" + index}>
										<hr className="my-2" />
										<div className="row">
											<div className={ act.groups && act.groups.length > 0 ? "col-6 color-primary text-underline cps" : "col-6 color-primary"} onClick={() => this.switchChatWithUser(act)}>{act.name}</div>
											<div className="col-6 text-right my-auto">
												<div className="d-flex ml-auto">
													{act.unreadClientAlerts > 0 &&
														<div className="d-flex align-items-center ml-auto cps" onClick={() => this.handleOpenClientAlerts(act)}>
															<div className="my-auto heading-text">{act.unreadClientAlerts}</div>
															<WarningRoundedIcon className="my-auto color-primary ml-1" />
														</div>
													}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
							: <></>
						}
						{/*
							<div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2" >
								<div className='cursor-pointer' onClick={() => this.setState({ showReports: !this.state.showReports })}>
									<div className="d-flex mx-2 my-1 align-items-center justify-content-between" >
										<h3 className="heading-text" >Client Reports</h3>
										<IconButton className="cps p-1 m-0" title={this.state.showReports ? "Client Reports Expend Less" : "Client Reports Expend More"}>{this.state.showReports ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
									</div>
									<hr className="mt-1 mb-2" />
									<div className="d-flex sub-text mx-2 mt-2" >
										{'Some People in your network require your attention'}
									</div>
									<hr className="my-2" />
								</div>
								{this.state.showReports &&
									<div className="row">
										<div className="col-12 my-auto">
											<ZohoReports assignActivityUrl={assignActivityUrl}/>
										</div>
										<hr className="my-2" />
										<div className="col-12 my-auto">
											<ZohoReports assignActivityUrl={assignActivityUrl}/>
										</div>
										<hr className="my-2" />
										<div className="col-12 my-auto">
											<ZohoReports assignActivityUrl={assignActivityUrl}/>
										</div>
									</div>
								}
							</div>
						*/}
						{this.props.launchpad && this.props.launchpad.length > 0 ? <div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2">
							<div className="d-flex mx-2 my-2 ">
								<h3 className="heading-text">Launchpad</h3>
							</div>
							<hr className="mt-1 mb-3" />
							<div className="d-flex  mx-2 mt-2 mb-2  align-items-center justify-content-center">
								{this.props.launchpad && this.props.launchpad.map((launch, index) => (
									<a href={launch.url} className=" text-secondary mr-4 mt-2" onClick={(e) => this.openLaunchpadUrl(e, launch)} key={index} data-testid={"lunchpad-item-" + index}>
										<div className="rounded-circle text-center mx-auto my-auto" style={{ width: 40 + 'px', height: 40 + 'px', background: '#ebf2f5' }}><LinkOutlinedIcon className="my-2" /></div>
										<div className=" text-center mx-auto heading-text">{launch.label}</div>
									</a>
								))}
							</div>
						</div> 
						: <></>
						}
					</div>
					{this.state.openForm && <SherpaPopup
						open={this.state.openForm}
						value={'ChatUSers'}
						handleClose={() => this.handleOpenPopup()}
						selectedUser={this.state.selUser ? this.state.selUser.user : ''}
						selectedMemberId={this.state.id}
						profileImage={this.state.selUser && this.state.selUser.user ? this.state.selUser.user.profileImageURL : require('Assets/avatars/user-15.jpg')}
						displayName={this.state.selUser && this.state.selUser.user ? this.state.selUser.user.displayName : 'Display Name'}
						length={Object.keys(this.state.selUser.unconfirmedAchievements).length}
					/>}
				</Scrollbars>
				<VideoCallHistoryPopup 
					openCallHistory={openCallHistory}
					handleClose={() => this.handleCloseCallHistory()}
					popupThemeClass={popupThemeClass}
					handleVideoCall={this.handleVideoCall.bind(this)}
				/>
			</div>
		);
	}
}
const mapStateToProps = ({ activitiesList, GroupList, authUser, Contacts, chatAppReducer, workflowData, settings, videoCallHistory }) => {
	const { userRole } = authUser;
	const {themeLoading, userLoading, enableWorkflows} = authUser;
	let { selectedMemberId, groups, groupMembers, groupMembersDetails  } = GroupList
	let { networkActivities, launchpad, networkActivityLoading, activityCalender, summaryLoading, touchpointActivitiesSummary } = activitiesList;
	let { contactList } = Contacts;
	const { selectedChatUsers } = chatAppReducer;
	const { noActiveWorkflowLoading,userHasNoActiveWorkflow, userWorkflows, userWorkflowLoading} = workflowData
	const {popupThemeClass} = settings
	const {videoCallSummary, videoCallSummaryDetail, loading: videoCallSummaryLoading, missedP2PVideoCallCount, missedGroupVideoCallCount, missedVCallClientCount} = videoCallHistory
	return { networkActivities, groups, launchpad, networkActivityLoading, activityCalender, userRole, contactList, selectedChatUsers, summaryLoading, touchpointActivitiesSummary, themeLoading, userLoading, groupMembers, groupMembersDetails, noActiveWorkflowLoading,userHasNoActiveWorkflow, popupThemeClass, videoCallSummary, videoCallSummaryDetail, videoCallSummaryLoading, missedP2PVideoCallCount, missedGroupVideoCallCount, missedVCallClientCount, userWorkflows, userWorkflowLoading, enableWorkflows };
}

export default withRouter(connect(mapStateToProps, {
	networkActivity,
	setSelectedMemberId,
	changeRole,
	getlaunchpad,
	getUnConfirmtActivitiesList,
	clearUnconfirmData,
	handleListStyle, getSelectedGroup,
	handleHideActivityDetailsPanel,
	showActivityHistoryDetail,
	getClientUnconfirmedAchievementsList,
	clearHistoryData,
	addSelectedChatUser,
	setClientActivitiesType,
	gettouchpointActivitiesSummary,
	getUserInfo,
	setSelectedUserInfo,
	handleNoticesPanel,
	handleOpenLauncPadUrl,
	setUserSelected,
	getMissedVideoCallSummary,
	handleUserWorkflowPanel,
	getUserPendingWorkflowList
})(CenterScreen));