import React, { Component } from 'react';
// redux method to subscribe class
import { connect } from 'react-redux';

import ActivityForm from '../ActivityForm';
import LifeSherpaLoading from '../LifeSherpaLoading';
import { NotificationManager } from 'react-notifications';
import Scrollbars from "react-custom-scrollbars"
import { openPopup } from '../../helpers/helpers';
// Actions
import {
    getNoticeForm,
    submitNotice
} from '../../actions'

class NoticeForm extends Component {
    state = {

        showForm: false,
        evaluationData: null,
    }

    componentDidMount() {
        const { notice, selectedUserId } = this.props;
        this.props.getNoticeForm(selectedUserId, notice.formId);
        this.setState({ showForm: true });
    }

    handlePlus = (obj, section, sectionObj) => {
        console.log(obj, section, sectionObj);
        let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
        if (value < obj.maxValue) {
            obj.value = value + 1;
        }
        this.updateEvaluationForm(obj, sectionObj);
    }

    handleMinus = (obj, section, sectionObj) => {
        console.log(obj, section, sectionObj);
        let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
        if (value > obj.minValue) {
            obj.value = value - 1;
        }
        this.updateEvaluationForm(obj, sectionObj);
    }

    handleThumbUp = (obj, sectionObj) => {
        obj.value = "up"
        console.log(" thumb down --> ", sectionObj)
        this.updateEvaluationForm(obj, sectionObj);
    }
    handleThumDown = (obj, sectionObj) => {
        obj.value = "down"
        console.log(" thumb down --> ", sectionObj);
        this.updateEvaluationForm(obj, sectionObj);
    }
    handleDateAndTimeSection = (obj, sectionObj) => {
        console.log(" date changed --> ", sectionObj, obj)
        this.updateEvaluationForm(obj, sectionObj);
    }

    handleImageInputSection = (obj, sectionObj) => {
        this.updateEvaluationForm(obj, sectionObj);
    }

    updateEvaluationForm = (obj, sectionObj) => {
        let { evaluationData } = this.state;
        if (!evaluationData) {
            console.log(" updating poke form data for changes ....")
            evaluationData = this.props.noticeFormData
        }
        evaluationData.sections[sectionObj] = obj;
        this.setState({ evaluationData: evaluationData })
    }

    handleEvaluationForm = (e, obj, section) => {
        let evaluationSectionData = obj
        ////console.log('target value-->', e.target.value, e.target.label, e.target.id, e.target.name, section)
        if (obj.type === 'checklist' && !obj.isMultiValue) {
            evaluationSectionData.itemsList.map((lbl, key) => {
                if (e.target.id === `radio-${obj.heading}-${key}-${section}`) {
                    lbl.value = e.target.checked
                } else {
                    lbl.value = !e.target.checked
                }
            })

        } else if (obj.type === 'checklist') {
            evaluationSectionData.itemsList.map((lbl, key) => {
                if (e.target.id === `checkbox-${key}-${lbl.name}-${section}`) {
                    lbl.value = e.target.checked
                }
            })

        } else if (obj.type === 'longText') {
            evaluationSectionData['text'] = e.target.value

        } else if (obj.type === 'text') {
            evaluationSectionData['text'] = e.target.value
        } else if (obj.type === 'spacer') {

        } else {

        }
        this.updateform(evaluationSectionData, section);
    }

    updateform = (obj, section) => {
        let { evaluationData } = this.state;
        if (!evaluationData) {
            console.log(" updating poke form data for changes ....")
            evaluationData = this.props.noticeFormData
        }
        Object.keys(evaluationData.sections).sort((a, b) => evaluationData.sections[a].position - evaluationData.sections[b].position).map((evaluatioObj, key) => {
            if (section === key) {
                evaluationData.sections[evaluatioObj] = obj
            }
        })
        ////console.log('updated value-->', evaluationData)
        this.setState({ evaluationData: evaluationData })
    }

    handleSaveAndContinue = () => {
        let { evaluationData } = this.state;
        const { noticeFormData, authUserName, selectedUserId, notice } = this.props;
        const payloadData = evaluationData ? evaluationData : noticeFormData;
        this.props.submitNotice({ 
            userId: selectedUserId, 
            noticeId: notice.noticeId, 
            formId: notice.formId, 
            formData: payloadData
        }, (responseData) => {
            if(responseData) {
                NotificationManager.success(responseData.message)
                //NotificationManager.success(`Congratulations ${authUserName || ""}, Notice submitted successfully!`);
                this.props.handleclosePanel();
            }
        })
  
    }



    handleOpenPokeFormHelpURL = (helpURL) => {
        if (helpURL == "lifesherpapp.com") {
            helpURL = "https://lifesherpapp.com"
        }
        // window.open(helpURL, '_blank');
        var windowReference = window.open();
        windowReference.location = helpURL;
        openPopup(windowReference)
    }
    getScrollHeight() {
        const { widgetApp } = this.props;
        if (widgetApp) {
            return '100%';
        } else {
            return 'calc(100vh - 140px)';
        }
    }

    render() {
        const { clientName, clientImage,  noticeFormData, formLoading, notice } = this.props;
        const { showForm } = this.state;
        return (
            <Scrollbars
            className="rct-scroll "
            autoHide
            style={{ height: this.getScrollHeight() }}
        >
            <div className='notice-form-container' data-testid="notice-form-component" >
                <LifeSherpaLoading loading={formLoading} />
                {showForm && noticeFormData && !formLoading &&
                    <ActivityForm
                        image={clientImage}
                        name={clientName}
                        pokeForm={noticeFormData}
                        notice={notice}
                        handleSaveAndContinue={this.handleSaveAndContinue}
                        handleEvaluationForm={this.handleEvaluationForm}
                        handleMinus={this.handleMinus}
                        handlePlus={this.handlePlus}
                        handleThumbUp={this.handleThumbUp}
                        handleThumDown={this.handleThumDown}
                        evaluationData={this.state.evaluationData}
                        executeActivity={true}
                        handleOpenPokeFormHelpURL={this.handleOpenPokeFormHelpURL}
                        handleDateAndTimeSection={this.handleDateAndTimeSection}
                        handleImageInputSection={this.handleImageInputSection}
                    />
                }
            </div>
            </Scrollbars>
        );
    }
}

const mapStateToProps = ({ noticesReducer, authUser }) => {
    const { noticeFormData, formLoading, selectedUserId } = noticesReducer;
    const { profile } = authUser
    let authUserName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name) : 'Display Name'
    return {  noticeFormData, formLoading , authUserName, selectedUserId};
}

export default connect(mapStateToProps, {
    getNoticeForm,
    submitNotice
})(NoticeForm);
