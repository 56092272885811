import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import "../../assets/scss/custom/_activitypreview.scss"
import { getLoading, UpdateSummeryList } from "../../actions"
import UserAllActivitiesListItem from './UserAllActivitiesListItem';
import IconButton from '@mui/material/IconButton';
import {database} from '../../firebase';
import { ref, onValue, query, orderByChild, equalTo, off} from "firebase/database"

class UserAgendaViewList extends Component {
    state = {
        show: false,
        index: -1,
        listenerValue:null
    }

    componentDidMount() {
        let organization = localStorage.getItem('selectedOrgId')
        const { selectedMember } = this.props;
        const {groupId} = selectedMember || {};
        let userId = localStorage.getItem('uid');
        const refValue = ref(database, `Realtime/${organization}/Groups/${groupId}/${userId}/achievementsInProgress`)
        const topRef = query(refValue, orderByChild('preview'), equalTo(false))
        const listenerValue = onValue(topRef, (snapshot) => {
            const realtimeData = snapshot.val();
            console.log("Real time user all activities data at component mount ", realtimeData);
            let inProgressList = []
            realtimeData && Object.keys(realtimeData).length > 0 ? Object.keys(realtimeData).map((dateObjKey, key) => {
                realtimeData[dateObjKey].achievementId = dateObjKey
                inProgressList.push(realtimeData[dateObjKey])

            }) : "";
            this.updateActivitiesSummary(inProgressList)
        })
        this.setState({listenerValue:listenerValue})
    }
    componentWillUnmount() {
        let organization = localStorage.getItem('selectedOrgId')
        const { selectedMember } = this.props;
        const {groupId} = selectedMember || {};
        let userId = localStorage.getItem('uid');
        const {listenerValue} = this.state;
        console.log("listenerValue -->", listenerValue);
        const removeListener = ref(database, `Realtime/${organization}/Groups/${groupId}/${userId}/achievementsInProgress`)
        off(removeListener)
    }

    updateActivitiesSummary = (inProgressList) => {
        const auth_uid = localStorage.getItem('auth_uid');
        let scheduled = this.props.activitySummery && this.props.activitySummery.scheduled ? this.props.activitySummery.scheduled : [];
        let unscheduled = this.props.activitySummery && this.props.activitySummery.unscheduled ? this.props.activitySummery.unscheduled : [];
        scheduled.map((schedule, index) => {
            let activity = schedule.activity || null;
            if (activity) {
                activity.state = '';
                activity.nextStep = null;
                activity.completed = 0;
                schedule.activity = activity
            }
        })
        unscheduled.map((schedule, index) => {
            let activity = schedule.activity || null;
            if (activity) {
                activity.state = '';
                activity.nextStep = null;
                activity.completed = 0;
                schedule.activity = activity
            }
        })
        scheduled.map(schedule => {
            let activity = schedule.activity || null;
            if (activity) {
                let add = {}
                inProgressList.find((li) => {
                    if (li.routineId === activity.id && (li.achievementId === schedule.achievementId || li.scheduleKey === schedule.scheduleKey)) {
                        add.count = li.stepsCount
                        add.state = li.state;
                        add.startDate = li.startDate;
                        add.routineUserId = li.routineUserId;
                        add.showResumeButton = li.routineUserId === auth_uid ? true : false
                        if (li.routine) {
                            if (li.routine.steps && Object.keys(li.routine.steps).length > 0) {
                                add.steps = li.routine.steps;
                            }
                            if (li.routine.expectedDuration) {
                                add.expectedDuration = li.routine.expectedDuration
                            }
                        }
                        let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                        let stepsCompletionList = [];
                        if (stepsCompletion) {
                            Object.keys(stepsCompletion).map((stepCompId, key) => {
                                stepsCompletionList.push(stepsCompletion[stepCompId]);
                            });
                            stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped")
                        }
                        add.stepsCompletionList = stepsCompletionList;
                        add.completed = stepsCompletionList.length;
                        if (add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                            add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                        }

                        add.achievementId = li.achievementId;
                        if (li.routine && li.routine.activityGroupId) {
                            add.activityGroupId = li.routine.activityGroupId
                        }

                        activity = { ...activity, ...add }
                    }
                })
            }
            schedule.activity = activity
        })
        unscheduled.map(schedule => {
            let activity = schedule.activity || null;
            if (activity) {
                let add = {}
                inProgressList.find((li) => {
                    if (li.routineId === activity.id) {
                        add.count = li.stepsCount
                        add.state = li.state;
                        add.startDate = li.startDate;
                        add.routineUserId = li.routineUserId;
                        add.showResumeButton = li.routineUserId === auth_uid ? true : false
                        if (li.routine) {
                            if (li.routine.steps && Object.keys(li.routine.steps).length > 0) {
                                add.steps = li.routine.steps;
                            }
                            if (li.routine.expectedDuration) {
                                add.expectedDuration = li.routine.expectedDuration
                            }
                        }
                        let stepsCompletion = li.StepsCompletion ? li.StepsCompletion : {};
                        let stepsCompletionList = [];
                        if (stepsCompletion) {
                            Object.keys(stepsCompletion).map((stepCompId, key) => {
                                stepsCompletionList.push(stepsCompletion[stepCompId]);
                            });
                            stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped")
                        }
                        add.stepsCompletionList = stepsCompletionList;
                        add.completed = stepsCompletionList.length;
                        if (add.completed > 0 && stepsCompletionList[add.completed - 1].step) {
                            add.nextStep = stepsCompletionList[add.completed - 1].step.title;
                        }

                        add.achievementId = li.achievementId;
                        if (li.routine && li.routine.activityGroupId) {
                            add.activityGroupId = li.routine.activityGroupId
                        }

                        activity = { ...activity, ...add }
                    }
                })
            }
            schedule.activity = activity
        })
        let summary = { scheduled: scheduled, unscheduled: unscheduled }
        this.props.UpdateSummeryList(summary)
        this.setState({ update: true })
    }

    selectActivity = (value) => {
        let { index } = this.state
        if (index === value) this.setState({ index: -1 })
        else this.setState({ index: value })
    }

    render() {
        const { activitySummery } = this.props
        let scheduled = [];
        let unscheduled = [];
        if (activitySummery && (activitySummery.scheduled || activitySummery.unscheduled)) {
            scheduled = activitySummery.scheduled ? activitySummery.scheduled : []
            unscheduled = activitySummery.unscheduled ? activitySummery.unscheduled : []
        } else {
            return (
                <div className="d-flex justify-content-center align-items-center py-50" data-testid="agendaview-activity-empty-list">
                    <h4>Activities Summary Not Found</h4>
                </div>
            )
        }
        return (
            <div data-testid="user-agendaview-activity-container">
                {scheduled.map((act, key) => (
                    <div key={key} className="w-100 justify-content-between summary-list-item lifesherpa-session-theme-support list-item pl-3 agenda-view-list-item" data-testid={"user-scheduled-activity-"+key}>
                        <div className="d-flex justify-content-between align-items-center">
                            {act.activity.newSchedule && act.activity.newSchedule !== '' ?
                                <div className="w-60 heading-text"  >
                                    {act.startTime + ' - ' + act.endTime}
                                </div>
                                :
                                <span className="w-60 heading-text" ></span>
                            }
                            <div className="mx-auto ml-auto" style={{ color: act.statusColor }}>{act.statusText}</div>
                            <IconButton className="p-1" title={this.state.index === key ? "Expend Less": "Expend More"} onClick={() => this.selectActivity(key)}>{this.state.index === key ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</IconButton>
                        </div>
                        <h4 className="mb-0 heading-text mb-2" >{act.activity.title}</h4>
                        <div className="pr-3 pb-1">
                            {this.state.index === key ?
                                <UserAllActivitiesListItem
                                    key={key}
                                    allActivities={this.props.allActivities}
                                    role={this.props.selectedRole}
                                    activity={{...act.activity, scheduleKey: act.scheduleKey}}
                                    editScheduleKey={this.props.editScheduleKey}
                                    selectedMenuActivity={this.props.selectedMenuActivity}
                                    handleShowActivityHistoryList={() => this.props.handleShowActivityHistoryList()}
                                    itemKey={key}
                                    anchorEl={this.props.anchorEl}
                                    calenderBox={this.state.calenderBox}
                                    handleClickMenu={(e) => this.props.handleClickMenu(e, key, act.activity)}
                                    handleCloseMenu={() => this.props.handleCloseMenu()}
                                    handleReminderButton={() => this.props.handleReminderButton(act.activity)}
                                    handleCalenderButton={() => this.props.handleCalenderButton(act.activity, key)}
                                    handleDeletePop={() => this.props.handleDeletePop()}
                                    handleShowActivityDetails={(e) => this.props.handleShowActivityDetails()}
                                    EditActivity={(e) => this.props.EditActivity()}
                                    handleStartActivity={(e) => this.props.handleStartActivity()}
                                    handleExecuteActivityFromOption={(e) => this.props.handleExecuteActivityFromOption()}
                                    handleResumeActivity={(e) => this.props.handleResumeActivity(act.activity, act.scheduleKey)}
                                    handleExecuteActivity={(e) => this.props.handleExecuteActivity(act.activity, act.scheduleKey)}
                                    hideRewards={this.props.hideRewards}
                                    handleActivityTitleClick={(e) => this.props.handleActivityTitleClick(act.activity)}
                                    handleResetActivity={() => this.props.handleResetActivity()}
                                    handleAdjustActivityTimer={() => this.props.handleAdjustActivityTimer()}
                                    handlePokeForm={this.props.handlePokeForm}
                                    pokeFormId={this.props.pokeFormId}
                                    showStartOrResumeButton={act.status != "expired" && act.status != "cancelled" && act.status !== "completed" && act.status !== "incomplete"}
                                    handleEditGeneralActivity={() => this.props.handleEditGeneralActivity()}
                                    allowCreateGeneralActivity={this.props.allowCreateGeneralActivity}
                                    handleUnassignActivity={this.props.handleUnassignActivity}
                                />
                                : ''
                            }
                        </div>

                    </div>
                ))
                }
                {unscheduled && unscheduled.length > 0 &&
                    <div key={this.props.itemKey} className="w-100 justify-content-between summary-list-item lifesherpa-session-theme-support py-2 list-item pl-3" data-testid="user-unscheduled-activity">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="w-60 cps" onClick={() => this.setState({show: !this.state.show })} >
                                <h4 className="my-2 heading-text" >Unscheduled Activities</h4>
                            </div>
                            <IconButton className="p-1" title={this.state.show ? "Expend Less": "Expend More"} onClick={() => this.setState({ show: !this.state.show })}>{this.state.show ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</IconButton>
                        </div>
                        <div className="pr-3 pb-1">
                            {this.state.show ? unscheduled.map((act, key) => (
                                <UserAllActivitiesListItem
                                    key={key}
                                    allActivities={this.props.allActivities}
                                    role={this.props.selectedRole}
                                    activity={act.activity}
                                    selectedMenuActivity={this.props.selectedMenuActivity}
                                    editScheduleKey={this.props.editScheduleKey}
                                    handleShowActivityHistoryList={() => this.props.handleShowActivityHistoryList()}
                                    itemKey={key}
                                    anchorEl={this.props.anchorEl}
                                    calenderBox={this.state.calenderBox}
                                    handleClickMenu={(e) => this.props.handleClickMenu(e, key, act.activity)}
                                    handleCloseMenu={() => this.props.handleCloseMenu()}
                                    handleReminderButton={() => this.props.handleReminderButton(act.activity)}
                                    handleCalenderButton={() => this.props.handleCalenderButton(act.activity, key)}
                                    handleDeletePop={() => this.props.handleDeletePop()}
                                    handleShowActivityDetails={(e) => this.props.handleShowActivityDetails()}
                                    EditActivity={(e) => this.props.EditActivity()}
                                    handleStartActivity={(e) => this.props.handleStartActivity()}
                                    handleExecuteActivityFromOption={(e) => this.props.handleExecuteActivityFromOption()}
                                    handleResumeActivity={(e) => this.props.handleResumeActivity(act.activity)}
                                    handleExecuteActivity={(e) => this.props.handleExecuteActivity(act.activity)}
                                    hideRewards={this.props.hideRewards}
                                    handleActivityTitleClick={(e) => this.props.handleActivityTitleClick(act.activity)}
                                    handleResetActivity={() => this.props.handleResetActivity()}
                                    handleAdjustActivityTimer={() => this.props.handleAdjustActivityTimer()}
                                    handlePokeForm={this.props.handlePokeForm}
                                    pokeFormId={this.props.pokeFormId}
                                    showStartOrResumeButton={true}
                                    handleEditGeneralActivity={() => this.props.handleEditGeneralActivity()}
                                    allowCreateGeneralActivity={this.props.allowCreateGeneralActivity}
                                />
                            ))
                                : ''
                            }
                        </div>

                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ activitiesList, GroupList, chatAppReducer }) => {
    const { activitySummery } = activitiesList;
    let { selectedGroup } = GroupList;
    const { selectedMember } = chatAppReducer
    return { activitySummery, selectedGroup, selectedMember }
}

export default withRouter(connect(mapStateToProps, {
    getLoading,
    UpdateSummeryList
})(UserAgendaViewList));