
import {
    OPEN_EDITOR,
    SELECT_ACTIVTY,
    HANDEL_EXECUTE_ACTIVITY_CONFIRMATION
 } from './types';


export const clearActivityData = () => (dispatch) => {
    dispatch({type: CLEAR_ACTIVITY_DATA})
}

export const handlEditor=(value)=>(dispatch)=>{
    dispatch({type:OPEN_EDITOR,payload: value})
}
export const selectActivity=(value)=>(dispatch)=>{
    dispatch({type:SELECT_ACTIVTY,payload: value})
}

export const handleActivityCompletionConfirmation = (value)=>(dispatch)=>{
    dispatch({type:HANDEL_EXECUTE_ACTIVITY_CONFIRMATION , payload: value})
}