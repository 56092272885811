/**
 * User List
 */
 import React, { Component, Suspense } from 'react';
 import IconButton from '@mui/material/IconButton';
 import CloseIcon from '@mui/icons-material/Close';
 import  {AsyncAssingActivitiesIFrame} from '../AsyncComponent/AsyncComponent'
 import LifeSherpaLoading from '../LifeSherpaLoading';
 import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
 
 class AssignActivityComponent extends Component {
     handleClose(){
        console.log("%c[AssignActivityiFrame]%c Closed assign activity iframe","color:black","color:red");
        console.groupEnd();
        this.props.handleCloseAssignActivity()
    }
 
     render() {
         const {userTitle, assignActivityUrl} = this.props.assingActivityInfo ? this.props.assingActivityInfo : {};
         //console.log("assing activity data--->",userTitle, assignActivityUrl);
         return (
            <div data-testid="open-assign-activity-component-iframe" className="row mb-4 assign-activity-container-layout">
                {/* <div className="d-flex w-100  justify-content-between align-items-center">
                     <div data-testid="open-course-breadcrumb-title-url"  className="d-flex align-items-left">
                         <Breadcrumb className="mb-0 tour-step-7" tag="nav">
                             <BreadcrumbItem 
                             tag={"span"}>Home</BreadcrumbItem>
                             <BreadcrumbItem 
                             tag={"span"}>Users</BreadcrumbItem>
                             <BreadcrumbItem 
                             tag={"span"}>{userTitle ? userTitle : 'Display Name'}</BreadcrumbItem>
                         </Breadcrumb>
                     </div>
                     <div className="d-flex d-flex  justify-content-end  align-items-right">
                         <IconButton data-testid="course-iframe-close-icon"  onClick={() => this.handleClose()} className="" title="Back To Home">
                             <CloseIcon/>
                         </IconButton>
                     </div>
                 </div> */}
                 <div data-testid="open-my-course-iframe" className="w-100 col-12">
                    <Suspense fallback={<LifeSherpaLoading/>}>
                        <AsyncAssingActivitiesIFrame handleCloseAssignActivity={this.props.handleCloseAssignActivity} assignActivityUrl={assignActivityUrl}/>
                    </Suspense>
                 </div>
            </div> 
         );
     }
 }
 
 export default AssignActivityComponent
 