/**
 * Email Listing
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// redux action
import {
    onSelectEmail,
    setActivityType,
    getActivitiesList,
    StartActivity,
    ResumeActivity,
    showActivityHistoryDetail,
    selectActivity,
    showActivityConfirmation,
    getHistoryList,
    getSelectedActivity,
    getLoading, selectActivityId,
    getRealtimeActivityDetail,
    deletePrivateActivity, getChartsList
} from '../../../actions';

// component
import LandingPageItem from './LandingPageItem';

class LandingPageList extends Component {
    state = {
        anchorEl: null,
        editScheduleKey: -1,
        deletePopup: false,
        previewPopup: false,
        stepNo: 0,
        calenderActivity: null,
        calenderBox: false,
        activity: '',
        notifyBox: false,
        selActivity: {},
        openChart: false,
        chartParams: {},
        selectId: -1
    }


    render() {
        const { inProgressActivities, userId } = this.props;

        return (
            <div style={{ borderTop: "1px solid lightgrey", marginTop: "12px" }} className='w-100 ml-0 mr-0 mb-0   p-0 '>
                {inProgressActivities.length > 0 ?
                    <div style={{ paddingLeft: "7px" }} className="mb-0 pb-0 mt-2">

                        <span  className={`activities-inprogress-title`}>
                            Activities In Progress
                        </span>

                    </div>
                    :
                    ""}
                <ul className="list-unstyled m-0 list-sherpa-activity mt-1 mb-4 p-0 " >
                    <div>
                        {inProgressActivities.length > 0 ? inProgressActivities.map((activity, key) =>    
                               key != 0  ?
                               <LandingPageItem
                                key={key + "landingpageitem"}
                                allActivities={inProgressActivities}
                                itemKey={key}
                                activity={activity}
                                userId={userId}
                            /> : "" )
                            :
                            <div  className="d-flex justify-content-center align-items-center py-50">
                                <h4>No In Progress activity available</h4>
                            </div>
                        }
                    </div>
                </ul>


            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ emailApp, GroupList, chatAppReducer, activitiesList }) => {
    // const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
    // const {selectedGroup}=GroupList
    // const {activitySummery,privateActivities,selectedId} = activitiesList
    // const {selectedMemberId,selectedRole}=chatAppReducer
    //  return {currentEmail,selectedRole,privateActivities,selectedId, sendingEmail, showActivityHistory,activitySummery, showConfirmation,selectedGroup,selectedMemberId};
    return {};
}

export default withRouter(connect(mapStateToProps, {
    onSelectEmail,
    showActivityHistoryDetail,
    showActivityConfirmation,
    getHistoryList,
    getSelectedActivity,
    getRealtimeActivityDetail,
    deletePrivateActivity,
    selectActivity,
    getActivitiesList,
    StartActivity,
    ResumeActivity,
    setActivityType, selectActivityId,
    getLoading,
    getChartsList
})(LandingPageList));
