/**
 * ARL Form Download Page
 */

//import React, { Component } from 'react';
import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { openPopup, detectPlatform } from '../helpers/helpers';
import { IconButton } from '@mui/material';
import useDownloader from 'react-use-downloader';

const ARLFormDownload = ({ propsData}) => {

    const closePage = () => {
        let closeURL = '/success';
        const platform = detectPlatform();
        if(platform == "Android") {
            closeURL = "lifesherpa-registration://";
        } else if(platform == "iOS") {
            closeURL = "lifesherpa://registration"
        } else {
            try {
                window.close();
            } catch(e) {
                console.log("Close window failed", e);
            }
        }
        let element = document.createElement('a');
        element.target = "_self";
        element.href = closeURL;
        element.click();
        element.remove();
    }
    function getFilename(url) {
        if (url)
        {
            var m = url.toString().match(/.*\/(.+?)\./);
            if (m && m.length > 1)
            {
                return m[1];
            }
        }
        return "";
    }

    function getFileType(url) {
        if (url)
        {
            return url.split(/[#?]/)[0].split('.').pop().trim();
        }
        return "";
    }

    const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader();
    const {url = ""} = propsData || {};
    const decodedURL = decodeURIComponent(url);
    
    let fileName = getFilename(decodedURL) || "melwood-document";
    let fileType = getFileType(decodedURL) || "pdf";
    const downloadFileName = `${fileName}.${fileType}`;


    console.log("URL :", url);
    console.log("File Name  :", downloadFileName);
    return (
        <div className={`rct-session-wrapper arl-form-download-screen rct-session-wrapper-ls`}>

            <div className='header'>
                <div className="row align-items-center" style={{ height: "50px", }}>
                    <div className="col-12 text-white text-right">
                        <div className='pr-3'>
                            <IconButton onClick={() => closePage()}>
                                <CloseIcon />
                            </IconButton>
                            
                        </div>


                    </div>
                </div>
            </div>
            <div className='session-inner-wrapper'>
                <div className='session-body'>
                    <div className="container">


                        <div className="row row-eq-height">
                            <div className="col-12 ">
                                <img src={require('../assets/img/unsupported-icon.jpeg')} alt="session-logo" width="auto" height="auto" className='auto-resize-img' />
                                <br />
                            </div>


                            <div className="col-12 ">
                                <h4>
                                   Your PDF viewer may not be able to open this type of document. 
                                   <br/>
                                    It can be opened in latest version of Adobe Acrobat Reader for
                                    Windows, macOS or Linux. (Android and iOS are not supported)
                                </h4>
                                <br />
                            </div>
                            <div className="col-12">
                                <h4>
                                    Click on the button given below to download the form.
                                </h4>
                                <br />
                            </div>

                        </div>
                        {/* <a className='btn login-screen-button download-button' href={downlaodURL} target="_blank" download>
                            Download

                        </a> */}
                        <button onClick={() => download(url, downloadFileName)} className='btn login-screen-button download-button'> Download</button>


                        <div className="row row-eq-height">
                            <div className="col-12">
                                <br /><br />

                                <p>
                                    For more information about the form, please contact your
                                    administrator.
                                </p>
                            </div>

                            <div className="col-12 ">
                                <p>
                                    Thank you and have a nice day!
                                </p>
                                <br />
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
}

export default ARLFormDownload;
