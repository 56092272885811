/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppConfig from '../../constants/AppConfig';
class HeaderWithCancelButton extends Component {

   render() {
       let {themeData} = this.props;
        const themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
       // console.log("activeTheme", this.props.activeTheme);
        let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
        activeTheme = this.props.activeTheme || activeTheme;
        if (process.env.REACT_APP === 'DES' || this.props.screenThemeClass == "rct-session-wrapper-aus") {
            return (
                <div className="container">
                    <p className="text-right" style={{fontSize:"large" ,position: 'absolute', right: 12, top: -30}}><a target="_self" href={this.props.href} className="text-white">Cancel</a></p>
                    <h1 className="text-white text-center">{'Autism@Work'}</h1>
                    <div className="d-flex align-items-center justify-content-center mb-2">
                        <p className="mb-0" style={{color: 'gray', marginRight: 10}}>Powered by LifeSherpa</p>
                        <div className="session-logo">
                            <Link to="/">
                                <img src={require("Assets/img/life-sherpa-logo-retina.png")} alt="session-logo" className="img-fluid" width="30" height="30" />
                            </Link>
                        </div>
                    </div>
                </div>
            );
        } else if(activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default')) { 
            return (
                <div className="container text-center pt-3">
                    {/* <p className="text-right" style={{fontSize:"large" ,position: 'absolute', right: 12, top: -30}}><a target="_self" href={this.props.href} style={{color: 'gray'}}>Cancel</a></p> */}
                    <img src={themeLogo || require("Assets/img/life-sherpa-logo-retina.png")} alt="session-logo" width="auto" height="auto" className='auto-resize-img' />
                </div>
            );
        } else {
            return (
            <div className="container text-center pt-3 ">   
                <img src={themeLogo || require("Assets/img/life-sherpa-logo-retina.png")} alt="session-logo" width="auto" height="auto" className='auto-resize-img'/>
                <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                    {themeData && themeData.loginLifeSherpaImage &&
                    <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                    }
                </div>
            </div>
            )
        }
        
    }
}
export default HeaderWithCancelButton
