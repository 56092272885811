import React from 'react'
//Material UI Components
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import LifeSherpaLoading from '../../LifeSherpaLoading';
//Components
import SingleSelectionItem from '../../ActivityForm/SingleSelectionItem'
import MultiSelectionItem from '../../ActivityForm/MultiSelectionItem'
import TextAreaItem from '../../ActivityForm/TextAreaItem'
import TextInpuItem from '../../ActivityForm/TextInpuItem'
import TimerOptionItem from '../../ActivityForm/TimerOptionItem'
import OverallEvaluation from '../../ActivityForm/OverallEvaluation'
import SpacerItem from '../../ActivityForm/SpacerItem'


class UnconfirmForm extends React.Component {

  state = {
    formData: null,
    labelValue: 0,
  }

  handlePlus(obj, section) {
    let formData = obj
    let  value = typeof obj.value === 'number' ? obj.value : 0
    formData.value =  value > obj.maxValue ? obj.maxValue : (value + 1)
    this.updateform(formData, section)
  }
  handleMinus(obj, section) {
    let formData = obj
    let  value = typeof obj.value === 'number' ? obj.value : 0
    formData.value = value - 1 < obj.minValue ? obj.minValue :  value - 1
    this.updateform(formData, section)

  }

  handleDown(){

  }

  handleUp(){
    
  }


  handleEvaluationForm(e, obj, section) {
    let evaluationSectionData = obj
    if (obj.type === 'checklist' && !obj.isMultiValue) {
      Object.keys(evaluationSectionData.items).map((lbl, key) =>  {
        if (e.target.id === `radio${obj.heading}${key}`) {
          evaluationSectionData.items[lbl] = e.target.checked
        } else {
          evaluationSectionData.items[lbl] = !e.target.checked
        }
      })
      evaluationSectionData.itemsList.map((lbl, key) =>  {
        if (e.target.id === `radio${obj.heading}${key}`) {
          lbl.value = e.target.checked
        } else {
          lbl.value = !e.target.checked
        }
      })

    } else if (obj.type === 'checklist') {
      Object.keys(evaluationSectionData.items).map((lbl, key) =>  {
        if (e.target.name === lbl) {
          evaluationSectionData.items[lbl] = e.target.checked
        }
      })
      evaluationSectionData.itemsList.map((lbl, key) =>  {
        if (e.target.name === lbl.name) {
          lbl.value = e.target.checked
        } 
      })

    } else if (obj.type === 'longText') {
      evaluationSectionData['text'] = e.target.value

    } else if (obj.type === 'text') {
      evaluationSectionData['text'] = e.target.value
    } else if (obj.type === 'spacer') {

    } else {

    }
    this.updateform(evaluationSectionData, section)
  }

  updateform(obj, section){
    let formData = this.props.pokeForm
    Object.keys(formData.sections).sort((a, b) => formData.sections[a].position - formData.sections[b].position).map((evaluatioObj, key) => {
      if (section === key) {
        formData.sections[evaluatioObj] = obj
      }
    })
    this.setState({formData})
  }

    handleSaveAndContinue(){
        if (this.props.selectedStepId) {
          this.props.postStepsNotesForm(this.props.selectedMemberId, this.props.selectedUnConfirmActivity.id, this.state.formData ? this.state.formData : this.props.pokeForm, this.props.selectedStepId, true)
        } else {
          this.props.postEvaluationForm(this.props.selectedMemberId, this.props.selectedUnConfirmActivity.id, this.state.formData ? this.state.formData : this.props.pokeForm, true)    

        }
      this.props.handleClose()
      
    }

  render() {
    
    return (
      
        <div style={{backgroundColor: '#f5f5f5'}}>
            <div className="bg-white">
                <IconButton onClick={() => this.props.handleClose()}><ArrowBackIcon /></IconButton>
            </div>
            <div className="pl-3 pr-3 pb-2">
              <LifeSherpaLoading loading={this.props.loading} />
              { this.props.pokeForm && this.props.pokeForm.sections && Object.keys(this.props.pokeForm.sections).sort((a, b) => this.props.pokeForm.sections[a].position - this.props.pokeForm.sections[b].position).map((obj, key) => (
                this.props.pokeForm.sections[obj].type === 'checklist' && !this.props.pokeForm.sections[obj].isMultiValue ? <SingleSelectionItem section={key} updateform={this.handleEvaluationForm.bind(this)} obj={this.props.pokeForm.sections[obj]} />
                : this.props.pokeForm.sections[obj].type === 'checklist' ? <MultiSelectionItem obj={this.props.pokeForm.sections[obj]} section={key} updateform={this.handleEvaluationForm.bind(this)} />
                : this.props.pokeForm.sections[obj].type === 'number' ? <TimerOptionItem obj={this.props.pokeForm.sections[obj]} section={key} handleMinus={this.handleMinus.bind(this)} handlePlus={this.handlePlus.bind(this)} labelValue={this.state.labelValue}/> 
                : this.props.pokeForm.sections[obj].type === 'longText' ? <TextAreaItem obj={this.props.pokeForm.sections[obj]} section={key} updateform={this.handleEvaluationForm.bind(this)}/>
                : this.props.pokeForm.sections[obj].type === 'text' ? <TextInpuItem obj={this.props.pokeForm.sections[obj]} section={key} updateform={this.handleEvaluationForm.bind(this)}/>
                : this.props.pokeForm.sections[obj].type === 'spacer' ? <SpacerItem obj={this.props.pokeForm.sections[obj]} section={key} updateform={this.handleEvaluationForm.bind(this)}/>
                : <OverallEvaluation obj={this.props.pokeForm.sections[obj]} handleUp={this.handleUp.bind(this)} section={key} handleDown={this.handleDown.bind(this)}/>
              ))
              }
            </div>
            <div className="d-flex align-items-center justify-content-center w-100 pb-3">
                 <Button variant="contained" size="small" color="primary" onClick={() => this.handleSaveAndContinue()} >
                    {'Submit'}
                </Button>
            </div>
        </div>
    )
  }
}

export default UnconfirmForm;
