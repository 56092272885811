/**
 * User Block
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    activitiesOpen,
    chatOpen,
    getUnConfirmtActivitiesList,
    clearUnconfirmData,
    addSelectedChatUser,
    clearHistoryData,
    showActivityHistoryDetail,
    getClientUnconfirmedAchievementsList,
    postClientMuteNotification,
    handleNoticesPanel,
    handleShowClientDashboard,
    handleUserWorkflowPanel
} from '../../../actions'

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatSharpIcon from '@mui/icons-material/TrendingFlatSharp';

import config from '../../../config/config'
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { reauthenticateIfTokenExpireWithIn30Min } from 'Helpers/helpers';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import { logoutCallbackForAuth0Expired } from 'Actions'
import {scrollMaincontainerToTop} from "../../../helpers/helpers";

class UserBlock extends Component {

    state = {
        windowWidth: window.innerWidth,
        mobileOpen: false,
        open: false,
        selectedRoutine: null,
        select: "activity",
        search: '',
        addDilogBox: false,
        showFilter: false,
        filter: '',
        activityFilter: [],
        listStyle: 'normal',
        update: '',
        loading: false,
        anchorEl: null,


    }
    componentDidMount() {
        console.log("%c[UserBlock]%c Rendered selected user panel block", "color:black", "color:green");
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    handleOpenActivities() {
        this.props.activitiesOpen(!this.props.isActivitiesOpen)
    }
    handleOpenChat() {
        this.props.chatOpen(!this.props.isChatOpen)
    }
    handleLogout() {

    }

    handleActivity = (value) => {
        //	this.props.hideRewards()
        //	this.setState({select:value})
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        if (!idToken) this.props.history.push('/launchscreen')
        if (value === "myactivity") {
            let userId = localStorage.getItem('uid')
        }
        this.props.setSelect(value);
    }
    handleRewards = () => {
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        if (!idToken) this.props.history.push('/launchscreen')
        //this.setState({select:'rewards'});
        this.props.setSelect('rewards');
    }

    handleResources = () => {
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        if (!idToken) this.props.history.push('/launchscreen')
        //this.setState({select:'Resources'});
        this.props.setSelect('Resources');
    }

    handleClickMoreIcon(event) {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    async handleOpenAssignActivity(userId, userName) {
        const themeName = this.props.activeTheme && this.props.activeTheme.name ? this.props.activeTheme.name : "default"
        const theme = themeName.charAt(0).toUpperCase() + themeName.slice(1);
        let idToken = localStorage.getItem("idToken")
        const orgId = localStorage.getItem("selectedOrgId")
        let openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
        let assingActivityInfo = { assignActivityUrl: openAssignActivityAppUrl, userTitle: userName }
        this.setState({ ...this.state, loading: true, anchorEl: null });
        const reauthenticated = await reauthenticateIfTokenExpireWithIn30Min();
        if (reauthenticated) {
            console.log("%c Token reauthenticated --------> ", "color:green");
            idToken = localStorage.getItem("idToken");
            openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
            assingActivityInfo = { assignActivityUrl: openAssignActivityAppUrl, userTitle: userName };
            this.setState({ ...this.state, loading: false });
            this.props.handleOpenAssingActivity(assingActivityInfo)
            // window.open(openAssignActivityAppUrl,"_blank");
        }
        else {
            this.setState({ ...this.state, loading: false });
            console.log("%c Token reauthentication failed ------->", "color:red");
            logoutCallbackForAuth0Expired();
        }

    }

    showChatPanelForSelectedUser = (chatUser) => {
        let { selectedChatUsers } = this.props;
        console.log(" Chat opening for user ->", chatUser);
        if (chatUser) {
            if (selectedChatUsers && selectedChatUsers.length > 0) {
                let index = selectedChatUsers.findIndex(user => user && user.userId == chatUser.userId);
                if (index >= 0) {
                    // selectedChatUsers.splice(index,1)
                    // this.props.addSelectedChatUser(selectedChatUsers);
                    // selectedChatUsers.push(chatUser);  
                } else {
                    selectedChatUsers.push(chatUser);
                }
            } else {
                let usersList = [];
                usersList.push(chatUser);
                selectedChatUsers = usersList;
            }

            console.log(" Chat opened ->", selectedChatUsers);
            this.props.addSelectedChatUser(selectedChatUsers);
            this.setState({ ...this.state, anchorEl: null });
        }

    }

    handleOpenNoticePanel = (userId) => {
        this.props.handleNoticesPanel(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }

    handleOpenUserWorkflowPanel = (userId) => {
        this.props.handleUserWorkflowPanel(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }

    handleOpenDashboardPanel = (userId) => {
        this.props.handleShowClientDashboard(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }


    handleOpenUnconfirmAchievements = (userId) => {
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if(activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.setState({ anchorEl: null });
        this.props.getClientUnconfirmedAchievementsList(userId);
        scrollMaincontainerToTop()
        this.props.showActivityHistoryDetail(true);
        
    }

    getScrollHeight() {
        return "235px";
    }

    handleMuteNotification() {
        const {muteNotifications} = this.props
        const enabled = !muteNotifications
        const adminUserId = localStorage.getItem("auth_uid")
        const clientUserId = localStorage.getItem("uid")
        this.setState({ anchorEl: null });
        this.props.postClientMuteNotification(adminUserId, clientUserId, enabled)
    }
    
    render() {
        const { networkActivities, selectedMember, clientUserDetail, selectedMemberId, groupMembersDetails, enableWorkflows, contactList, groupMembers, muteNotifications, muteLoading , selectedUserInfo, userWorkflows} = this.props
        const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] || selectedUserInfo || null   : selectedUserInfo || null;
        // console.log("user datails of user block",selectedMember,userActivities);
        //  let networkData = networkActivities ? networkActivities.find(ele => ele.userId == selectedMemberId) : {};
        let {unconfirmAchievements} = this.props;
        let { select, anchorEl } = this.state;
        let statusList = [];

        if(groupMembers && selectedMemberId && selectedMember) {
            let  selectedMemberDetails = groupMembers[selectedMember.groupId] && groupMembers[selectedMember.groupId][selectedMemberId];
            if (selectedMemberDetails && selectedMemberDetails.lastUserStatusSets) {
                 Object.keys(selectedMemberDetails.lastUserStatusSets).map((status, key) => {
                     let obj = { color: selectedMemberDetails.lastUserStatusSets[status].color, status: selectedMemberDetails.lastUserStatusSets[status].status, direction: selectedMemberDetails.lastUserStatusSets[status].direction };
                     statusList.push(obj);
                 })
            }
         }
        let chatUser = contactList ? contactList.find(userInfo => userInfo.userId == selectedMemberId) : null;
        let networkData = networkActivities ? networkActivities.find(ele => ele.userId == selectedMemberId) || {} : {};
        const totalNetworkActivityCount = (networkData.unconfirmedAchievements || 0) + (networkData.unreadMessages || 0) + (networkData.unreadClientAlerts || 0) + (userWorkflows && userWorkflows.count || 0);
        unconfirmAchievements = networkData && networkData.unconfirmedAchievements ? networkData.unconfirmedAchievements : unconfirmAchievements;
        let displayName = "Display Name";
        if(selectedClient && selectedClient.name) {
            displayName = selectedClient.name;
        } else if(clientUserDetail && clientUserDetail.displayName) {
            displayName = clientUserDetail.displayName;
        }
         return (
            <div >
                <LifeSherpaLoading loading={this.state.loading || muteLoading} />
                {this.props.selectedRole == 'admin' ?
                    <div className="row user-block-height" >
                        <div className="col-12">
                            <div className="media">
                                <h1 className="mt-50 mx-auto ">No Data Available</h1>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row" /*style={{height:'calc(100vh-100px)'}}*/>
                        <div className="col-12  " data-testid="details-panel-client-block">
                            <div className="media ">
                                <div className="media-left pl-3 position-relative ">
                                    <img data-testid="details-panel-client-block-image" src={selectedClient && selectedClient.profileImageURL && selectedClient.name !== "Test User" ? selectedClient.profileImageURL : require('Assets/avatars/user-15.jpg')} alt="user profile" className="cps rounded-circle" width="140" height="140"  /*onClick={() => this.handleOpenDashboardPanel(selectedMemberId)}*/ />
                                    {totalNetworkActivityCount ? <div title="Unconfirmed Activities" style={{ cursor: 'pointer' }} onClick={() => this.handleOpenDashboardPanel(selectedMemberId)} className="badge-extension badge-danger rounded-circle">{totalNetworkActivityCount}</div> :""}
                                    {muteNotifications ? 
                                        <></>
                                        : 
                                        <div data-testid={`my-client-detail-mute-icon`} title="Muted" className="badge-extension-mute ">
                                            <VolumeOffOutlinedIcon fontSize='large'/>
                                        </div>
                                    }
                                </div>
                                <div className="media-body mr-3" >
                                    <div className="user-block-body">
                                        <div className="d-flex align-items-center  ">
                                            <h1 data-testid="details-panel-client-title" className="user-name-font">{displayName}</h1>
                                            <IconButton className="p-1 user-more-icon-button" title="Client More Actions" onClick={(e) => this.handleClickMoreIcon(e)}>
                                                <MoreVertIcon aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                                            </IconButton>    
                                        </div>
                                        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.setState({ anchorEl: null })}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            getcontentanchorel={null}
                                        >
                                            <MenuItem className="menuEditItem" onClick={() => this.handleOpenDashboardPanel(selectedMemberId)}>Dashboard</MenuItem>
                                            {chatUser ? <MenuItem className="menuEditItem" onClick={() => this.showChatPanelForSelectedUser(chatUser)}>Chat</MenuItem> : ""}
                                            <MenuItem className="menuEditItem" onClick={() => this.handleMuteNotification()}>{muteNotifications ? "Mute Notifications" : "Unmute Notifications"}</MenuItem>
                                            <MenuItem className="menuEditItem" onClick={() => this.handleOpenNoticePanel(selectedMemberId)}>Alerts</MenuItem>
                                            {enableWorkflows ? <MenuItem className="menuEditItem" onClick={() => this.handleOpenUserWorkflowPanel(selectedMemberId)}>Workflows</MenuItem> : ""}
                                        </Menu>
                                        <div className="row user-block-status ">
                                            <div className="col-12  mt-1">
                                                <ul className="list-inline mb-0">
                                                    <li className="text-white text-right" >
                                                        <div className="row d-none d-md-flex">
                                                            {statusList.map((info, key) =>
                                                                <div data-testid={`details-panel-client-status-list${key}responsive`} key={key + "statuslist"} className="d-flex w-auto mt-1 py-1  mr-1 px-3" style={{ borderRadius: 20 + "px", background: info.color }}>
                                                                    {info.direction == "neutral" ? <TrendingFlatSharpIcon className="my-auto" />
                                                                        : info.direction == "positive" ? <TrendingUpIcon className="my-auto" />
                                                                            : info.direction == "negative" ? <TrendingDownIcon className="my-auto" /> : ""}
                                                                    <div className="my-auto">{info.status}</div>
                                                                </div>
                                                            )}

                                                        </div>
                                                        <div className="row mr-1 d-block d-md-none">
                                                            {statusList.map((info, key) =>
                                                                <div data-testid={`details-panel-client-status-list${key}small`} key={key + "statuslist"} className="d-flex w-auto mt-1 py-1  mr-1 px-3 justify-content-between" style={{ borderRadius: "6px", background: info.color, width: "auto" }}>
                                                                    {info.direction == "neutral" ? <TrendingFlatSharpIcon className="my-auto" />
                                                                        : info.direction == "positive" ? <TrendingUpIcon className="my-auto" />
                                                                            : info.direction == "negative" ? <TrendingDownIcon className="my-auto" /> : <div style={{ width: "1.5rem" }}></div>}
                                                                    <div className="my-auto">{info.status}</div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = ({ chatAppReducer, activitiesList, GroupList, settings, Contacts, clientsList, workflowData, authUser }) => {
    const { isActivitiesOpen, displayName, unconfirmAchievements, profileImage, status, checkIn, isChatOpen, selectedUser, selectedMemberId, selectedRole, email, selectedMember, selectedChatUsers, selectedUserInfo } = chatAppReducer
    const { activeTheme } = settings;
    const { allInprogressActivities, loading, userActivities, networkActivities } = activitiesList
    const { groupMembers, groupMembersDetails } = GroupList
    let { contactList } = Contacts;
    const {muteNotifications, clientUserDetail} = clientsList
    const muteLoading = clientsList ? clientsList.loading : null
    const {userWorkflows} = workflowData
    return { networkActivities, selectedMember, userActivities, isActivitiesOpen, selectedUser, displayName, profileImage, selectedMemberId, unconfirmAchievements, status, checkIn, isChatOpen, selectedRole, allInprogressActivities, loading, email, groupMembers, groupMembersDetails, activeTheme, contactList, selectedChatUsers, muteNotifications, muteLoading , selectedUserInfo, clientUserDetail, userWorkflows};
}

export default connect(mapStateToProps, {
    activitiesOpen,
    chatOpen, getUnConfirmtActivitiesList, clearUnconfirmData,
    addSelectedChatUser,
    clearHistoryData,
    showActivityHistoryDetail,
    getClientUnconfirmedAchievementsList,
    postClientMuteNotification,
    handleNoticesPanel,
    handleShowClientDashboard,
    handleUserWorkflowPanel
})(UserBlock);
