/**
 * InitialScreenForm
 */

import React from 'react';
import Button from '@mui/material/Button';
import { Form, FormGroup } from 'reactstrap';

const InitialScreenForm = ({params, onSignInWithToken, onSignInWithUsernameAndPassord}) => (
    <div  className="session-body text-center">
        {!params.email && !params.token && <div className="session-head mb-10">
            <p className="mb-0 base-font-color">If you have been given an invitation token, please tap the button below to complete your one-time registration.</p>
        </div>}
        <Form>
            {!params.email && !params.token && <FormGroup>
            <Button
                className="btn btn-block w-100 login-screen-button"
                variant="contained"
                size="large"
                onClick={onSignInWithToken}
                data-testid="i-have-a-token"
            >
                I Have a Token
                </Button>
            </FormGroup>}
            <FormGroup className="mb-10">
            { params.email ? <p className="mb-0 base-font-color">Configuration, please tap the button below to continue:</p>
            : params.token ? <p className="mb-0 base-font-color">Configuration, please tap the button below to continue:</p>
            : <p className="mb-0 base-font-color">If you already have a username, please tap the button below:</p>
            }
            </FormGroup>
            <FormGroup>
            <Button
                className="btn btn-block w-100 login-screen-button"
                variant="contained"
                size="large"
                onClick={onSignInWithUsernameAndPassord}
                data-testid="i-have-my-username-and-password"
            >
            {!params.email && !params.token ? "I have my Username" : "Continue"}
                </Button>
            </FormGroup>
        </Form>
    </div>
)


export default InitialScreenForm