import React from 'react';

const Linkify = (props) => {
  const text = props.text;
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const newLineRegex = /\n/g;

  return (
    <span style={{ height: 'auto' }}>
      {text.split(newLineRegex).map((line, i) => {
        return (
          <span key={i}>
            {line.split(urlRegex).map((str, j) => {
              if (urlRegex.test(str)) {
                return <a key={j} href={str} style={{textDecoration: "underline"}} target="_blank">{str.length > 50 ? str.substring(0, 50)+"..." : str}</a>
              }
              return str;
            })}
            <br />
          </span>
        );
      })}
    </span>
  );
};


export default Linkify;
