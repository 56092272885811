/**
 * Auth User Reducers
 */
import {
   GET_USER_GEOLOCATION,
   GET_SUCCESS_USER_GEOLOCATION,
   SHOW_LOCATION_LOADING,
   HIDE_LOCATION_LOADING,
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
    geoData: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_USER_GEOLOCATION:
            return { ...state, loading: true };

        case GET_SUCCESS_USER_GEOLOCATION:
            return { ...state, geoData: action.payload, loading: false };

        case SHOW_LOCATION_LOADING:
            return { ...state, loading: true };

        case HIDE_LOCATION_LOADING:
            return { ...state, loading: false };

        default: return { ...state };
    }
}
