import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
// reactstrap components
import { Label } from 'reactstrap';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewPage from './ViewPage';

class AlertView extends Component {

    render() {
        const { title, widgetApp } = this.props;
        return (
            <div>
                <Dialog
                    open={true}
                    fullScreen={true}
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} notices-list-panel`}
                >
                    <DialogTitle>
                        <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>

                            <IconButton title='Back' className="text-white" onClick={() => this.props.handleBackToList()}><ArrowBackIcon /></IconButton>
                            <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{title}</Label>

                            <IconButton className="text-white mr-2 hide-element" onClick={() => this.props.handleclosePanel()}><CloseIcon /></IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className="m-0 p-0 activity-details-height">
                        <ViewPage
                            widgetApp={widgetApp}
                            postId={this.props.postId}
                            categoryId={this.props.categoryId}
                            organization={this.props.organization}
                        />
                    </DialogContent>
                </Dialog>
            </div>


        );
    }
}

const mapStateToProps = ({ noticesReducer, settings }) => {
    const { noticePost, noticePostLoading } = noticesReducer;
    const { popupThemeClass } = settings;
    return { noticePost, noticePostLoading, popupThemeClass };
}

export default connect(mapStateToProps, { })(AlertView);
