/**
 * Chat Area Component
 */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment'
import { Button } from '@mui/material';
import {checkCompletedInTasks, capitalizeFirstLetter} from "../../../helpers/helpers"
import AddIcon from '@mui/icons-material/Add';
//components
import RctCollapsibleCard from '../../RctCollapsibleCard/RctCollapsibleCard';
import { Label } from 'reactstrap';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

class SubWorkflowDetail extends Component {

	state = {
		message: '',
        anchorEl: null
	}

    componentDidMount() {
        const {selectedSubWorkflow, userId} = this.props
        const {taskId, subWorkflowExecutionId} = selectedSubWorkflow || {taskId: null,  subWorkflowExecutionId: null}
		const {subWorkflowId, organization} = selectedSubWorkflow?.parameters || {subWorkflowId: null, organization: null}
        if (userId) {
            this.props.getUserSubWorkflowHistoryDetail({subWorkflowId, userId, orgId: organization, executionId: subWorkflowExecutionId})
        } else {
            this.props.getSubWorkflowHistoryDetail({subWorkflowId, orgId: organization, executionId: subWorkflowExecutionId})
        }
    }

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	
	getScrollHeight() {
		return '555px';
	}

	onBackPress(){
		this.props.handleCloseWorkflowHistory()
	}

	getFormatedDuration(duration){
		var timeleft = (+duration) /** 1000;*/
        let seconds = Math.round((Math.floor(timeleft / 1000) % 60));
        let minutes = Math.round((Math.floor(timeleft / 60000) % 60));
        let hours = Math.round((Math.floor(timeleft / 3600000) % 24));
		let days = Math.round(Math.floor(timeleft / 86400000));
		return days > 0 ? `${days}d ${hours}h ${minutes}m ${seconds}s` : hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
	}

	getUserFromRole(workflowtask, roleObj) {
		const users = workflowtask?.users || {};
		
		const assignedUserId = roleObj.userId;
		if(users[assignedUserId]) {
			return users[assignedUserId].name;
		}

		const role = roleObj.role;
		const roles = workflowtask?.roles || {};
		
		if (roles[role] && roles[role].userId === assignedUserId && roles[role]?.user) {
			return roles[role].user['name'];
		} 

		return roleObj.role || "";
		
	}

	getAssigedUserGroupName(execution, roleObj) {
		const {userId, groupId, role} = roleObj || {};
		const users = execution?.users || {};
		const groups = execution?.groups || {};
		return users[userId]?.name || groups[groupId]?.name || role;
	}

	getAssignedRoleTootipTitle(execution, roleObj) {
		const roles = execution?.roles || {};
		const {userId, groupId, role} = roleObj || {};
		const {includeUsers, assignmentType} = roles[role] || {};
		const users = execution?.users || {};
		const groups = execution?.groups || {};
		let assigedUserOrGroupName = users[userId]?.name || groups[groupId]?.name;
		if(!assigedUserOrGroupName) {
			return "";
		}
		if(!userId && groupId && (assignmentType === "Group" || assignmentType === "Group Static")) {
			if(includeUsers === "onlyAdmins") {
				return `${role}(Admin role users of group - ${assigedUserOrGroupName})`;
			} else if(includeUsers === "onlyClients") {
				return `${role}(General role users of group - ${assigedUserOrGroupName})`;
			} else {
				return `${role}(All General and Admin role users of group - ${assigedUserOrGroupName})`;
			}
		} else {
			return `${role}(${assigedUserOrGroupName})`;

		}
	}

	getAssignedRoleText(execution, role, roleObj) {
		const {includeUsers, assignmentType, userId, groupId} = roleObj || {};
		const users = execution?.users || {};
		const groups = execution?.groups || {};
		let assigedUserOrGroupName = users[userId]?.name || groups[groupId]?.name;
		if(!assigedUserOrGroupName) {
			return "Not Assigned";
		}
		if(!userId && groupId && (assignmentType === "Group" || assignmentType === "Group Static")) {
			if(includeUsers === "onlyAdmins") {
				return `Admin role users of group - ${assigedUserOrGroupName}`;
			} else if(includeUsers === "onlyClients") {
				return `General role users of group - ${assigedUserOrGroupName}`;
			} else {
				return `All General and Admin role users of group - ${assigedUserOrGroupName}`;
			}
		} else {
			return assigedUserOrGroupName;

		}
	}


	isGroupAssignmentRole(execution, roleObj) {
		const {userId, groupId, role} = roleObj || {};
		const roles = execution?.roles || {};
		const {assignmentType} = roles[role] || {};
		if(!userId && groupId && (assignmentType === "Group" || assignmentType === "Group Static")) {
			return true;
		} 
		return false;
	}

	getUserNameByUserId(workflowtask, userId) {
		const users = workflowtask?.users || {};
		if(users[userId]) {
			return users[userId].name;
		}
		return "Not Assigned";
		
	}

	showProccedButton(task) {
		const {userRole} = this.props
		const {activityExecutedBy, type} = task.parameters
		const { role, userId, groupId, executedByUserId } = activityExecutedBy || {}
		const auth_uid = localStorage.getItem("auth_uid");
		if(type === "groupRoleAssignment") {
			if(groupId && auth_uid === executedByUserId) {
				return true;
			}
		}
		if(type === "activityAssignment") {
			if (auth_uid == userId) {
				return true 
			} 
		}
		return false;
	}

	handleProceedOrNotify(task, taskId) {
		const {userRole} = this.props
		const {activityExecutedBy, activityId, organization, type} = task.parameters || {}
		const { role, userId, groupId, executedByUserId } = activityExecutedBy || {}
		const auth_uid = localStorage.getItem("auth_uid");
		if(type === "groupRoleAssignment") {
			if(groupId && auth_uid === executedByUserId) {
				this.props.handleExecuteActivity({activity: task, taskId, isSubWorkflowOpened: true});
				return;
			}
		}
		if(type === "activityAssignment") {
			if (auth_uid == userId) {
				this.props.handleExecuteActivity({activity: task, taskId, isSubWorkflowOpened: true});
				return;
			} 
		}
		this.props.showNotifyAlert({task, taskId})
	}

	render() {

		const {fullScreen, userRole, subWorkflowLoading, subWorkflowDetailData} = this.props
		const {completed, total, workflowTasks, totalTasksCompletionDuration} = checkCompletedInTasks(subWorkflowDetailData?.tasks) || {completed: 0, total: 0, workflowTasks: null}
		
		return (
			<RctCollapsibleCard
				colClasses=""
				heading={
                    <div className="d-flex align-items-center justify-content-between text-white">
                        <React.Fragment>
                            <div className="d-flex align-items-center">
                                <IconButton title="Back To List" onClick={this.onBackPress.bind(this)}><ArrowBackIcon /></IconButton>
                            </div>
                            <div>{subWorkflowDetailData?.executionName}</div>
                            <div style={{ width: "60px" }}>{" "}</div>
                        </React.Fragment>
                    </div>
                }
				fullBlock
				customClasses="overflow-hidden theme-background activities-history-details-panel"
				headingCustomClasses={fullScreen ? "bg-lifesherpa p-0" : "bg-lifesherpa"}
			>
				<LifeSherpaLoading loading={subWorkflowLoading}/>
				<div className="px-3 py-2 list-card sub-header-list-card screen-content-background-color">
					<div className=" d-flex align-items-center mb-1">
						<div>
							<h2 className="mb-0 mt-0 heading-text">{subWorkflowDetailData?.executionName}</h2>
							<p className="mb-0 mt-0 sub-text">{subWorkflowDetailData?.description}</p>
						</div>
						<span className={subWorkflowDetailData?.status == "open" ? "ml-2 px-3 font-md badge bg-success" : subWorkflowDetailData?.status == "completed" ? "ml-2 px-3 font-md badge bg-secondary" : "ml-2 px-3 font-md badge bg-danger"}>{ subWorkflowDetailData && subWorkflowDetailData.status && subWorkflowDetailData?.status.charAt(0).toUpperCase() + subWorkflowDetailData?.status.slice(1)}</span>
						<div className="ml-auto sub-text activity-time-duration">
							{totalTasksCompletionDuration > 0 ? this.getFormatedDuration(totalTasksCompletionDuration) : "-:-"}
						</div>
					</div>
					{subWorkflowDetailData &&
						<>
						<div className="d-flex justify-content-between">
							<div className="activity-status-details heading-text">
								<div className="d-flex align-items-center">
									<div className="mr-1">Tasks Completion:</div><div>{`${completed}/${total}`}</div>
								</div>
								{subWorkflowDetailData.status &&
									<div className="d-flex align-items-center">
										<div className="mr-1">Status:</div><div>{subWorkflowDetailData?.status}</div>
									</div>
								}
								{subWorkflowDetailData.lastUpdated &&
									<div className="d-flex align-items-center">
										<div className="mr-1">Last Updated: </div><div>{moment(subWorkflowDetailData?.lastUpdated, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}</div>
									</div>
								}
							</div>
							{/*(subWorkflowDetailData?.status == "open" || subWorkflowDetailData?.status == "error")  && userRole && (userRole !== 'Client') ?
								<div className='text-right'>
									<Button title="Skip Task" className='bg-primary text-white skipButton' onClick={() => this.props.handleSkipWorkflowTask(subWorkflowDetailData)}>SKIP TASKS</Button>
									<Button title="Cancel Workflow" className='bg-danger text-white' onClick={() => this.props.handleCancelWorkflow(subWorkflowDetailData)}>CANCEL WORKFLOW</Button>
								</div>
								: <></>
							*/}
						</div>
						<div className=''>
							<Label className="mb-0 mr-1 font-weight-bold">Roles:</Label>
							{subWorkflowDetailData.roles && Object.keys(subWorkflowDetailData.roles).length > 0 ? Object.keys(subWorkflowDetailData.roles).map((role, key) => (
								<React.Fragment key={key+"role"}>
									<span className="mb-0 mt-0 sub-text pl-1">{role}</span>
									<Label className="mb-0 font-weight-bold">({this.getAssignedRoleText(subWorkflowDetailData, role, subWorkflowDetailData.roles[role])})</Label>
									{`${key < Object.keys(subWorkflowDetailData.roles).length-1 ? ", " : ""}`}
								</React.Fragment>
							))
								:<></>
							}
						</div>
					</>
					}
				</div>
				<div className="chat-main-body">
					<Scrollbars
						className="rct-scroll"
						autoHide
						ref="chatScroll"
						style={{ height: this.getScrollHeight(this.state.activeIndex) }}
					>
						<div className="chat-body">
							{workflowTasks && Object.keys(workflowTasks).length > 0 ?
								<div className="achievement-steps-list-panel" style={{ width: '100%' }}>
									<div className="list-unstyled mb">
										{Object.keys(workflowTasks).map((taskId, key) => (
											<div key={key} className="list-item border-bottom  list-card">
												<div className="d-flex align-items-center">
													<Avatar
														title={workflowTasks[taskId]?.parameters?.state == "Skipped" ? workflowTasks[taskId]?.parameters?.state : workflowTasks[taskId]?.status}
														className={`mr-10 align-self-center  ${workflowTasks[taskId]?.parameters?.state == "Skipped" ? "achievementStatusNotFound" : workflowTasks[taskId]?.status === 'waiting' ?
															"achievementStatusCancelled"
															: workflowTasks[taskId]?.status === 'paused' ?
																"achievementStatusInProgress"
															: workflowTasks[taskId]?.status === 'completed' ?
																"achievementStatusConfirmed"
															: workflowTasks[taskId]?.status === "executing" ?
																"achievementStatusInProgress"
															: "achievementStatusCancelled"
															}`}
													>
														{workflowTasks[taskId]?.parameters?.state == "Skipped" ? 
															<DoneRoundedIcon style={{fontSize: "2rem"}}/>
															: workflowTasks[taskId]?.status === 'waiting' ?
															<AccessAlarmRoundedIcon style={{fontSize: "2rem"}}/>
															: workflowTasks[taskId]?.status === 'paused' ?
																<PauseCircleOutlineIcon style={{ fontSize: "2rem" }} />
															: workflowTasks[taskId]?.status === 'completed' ?
																<DoneRoundedIcon style={{fontSize: "2rem"}}/>
															: workflowTasks[taskId]?.status === "executing" ?
																<DonutLargeRoundedIcon style={{ fontSize: "2rem" }}/>
															: workflowTasks[taskId]?.status === "error" ?
															<ErrorIcon style={{ fontSize: "2rem" }} />
															: <ClearRoundedIcon style={{ fontSize: "2rem" }} />
														}
													</Avatar>
													<div className='row w-90'>
														<h3 className="col-12 mb-0 mt-0 heading-text">{`[${key + 1}] ${workflowTasks[taskId]?.parameters?.name || workflowTasks[taskId]?.name}`}</h3>
														{workflowTasks[taskId]?.parameters?.activityAssignedTo &&
															<div className='col-sm-12 col-md-6 col-xl-6 col-lg-6 d-flex' title={this.getAssignedRoleTootipTitle(subWorkflowDetailData, workflowTasks[taskId]?.parameters?.activityAssignedTo)}>
																<Label className="mb-0 mt-0 sub-text font-weight-bold">Assigned To</Label>
																{/* <p className="mb-0 mt-0 sub-text">{`: ${workflowTasks[taskId]?.parameters?.activityAssignedTo?.role || ""}`}</p> */}
																<p className="mb-0 mt-0 sub-text">{`: ${this.getAssigedUserGroupName(subWorkflowDetailData, workflowTasks[taskId]?.parameters?.activityAssignedTo)}`}</p>
																{this.isGroupAssignmentRole(subWorkflowDetailData, workflowTasks[taskId]?.parameters?.activityAssignedTo) ?
																		<ErrorOutlineIcon className='ml-1 role-warning-icon cps'/>
																	: ""}
															</div>
														}
														{workflowTasks[taskId]?.parameters?.activityExecutedBy &&
															<div className='col-sm-12 col-md-6 col-xl-6 col-lg-6 d-flex' title={this.getAssignedRoleTootipTitle(subWorkflowDetailData, workflowTasks[taskId]?.parameters?.activityExecutedBy)}>
																<Label className="mb-0 mt-0 sub-text font-weight-bold">Executed By</Label>
																{/* <p className="mb-0 mt-0 sub-text">{`: ${workflowTasks[taskId]?.parameters?.activityExecutedBy?.role || ""}`}</p> */}
																<p className="mb-0 mt-0 sub-text">{`: ${this.getAssigedUserGroupName(subWorkflowDetailData, workflowTasks[taskId]?.parameters?.activityExecutedBy)}`}</p>
																{this.isGroupAssignmentRole(subWorkflowDetailData, workflowTasks[taskId]?.parameters?.activityExecutedBy) ?
																		<ErrorOutlineIcon className='ml-1 role-warning-icon cps'/>
																	: ""}
															</div>
														}
														{workflowTasks[taskId]?.parameters?.type &&
															<div className='col-sm-12 col-md-6 col-xl-6 col-lg-6 d-flex'>
																<Label className=" mb-0 mt-0 sub-text font-weight-bold">Type</Label>
																<p className="mb-0 mt-0 sub-text">{`: ${capitalizeFirstLetter(workflowTasks[taskId]?.parameters?.type.replace(/([a-z0-9])([A-Z])/g, '$1 $2'))}`}</p>
															</div>
														}
														{workflowTasks[taskId]?.parameters?.phase &&
															<div className='col-sm-12 col-md-6 col-xl-6 col-lg-6 d-flex'>
																<Label className="mb-0 mt-0 sub-text font-weight-bold">Phase</Label>
																<p className="mb-0 mt-0 sub-text">{`: ${workflowTasks[taskId]?.parameters?.phase}`}</p>
															</div>
														}
														{workflowTasks[taskId]?.status === 'error' && workflowTasks[taskId]?.errorMsg &&
															<div className='col-12 d-flex'>
																<Label className="mb-0 mt-0 sub-text font-weight-bold">Error</Label>
																<p className="mb-0 mt-0 sub-text text-danger overflow-hidden">{`: ${workflowTasks[taskId]?.errorMsg}`}</p>
															</div>
														}
													</div>
													<div className="d-flex align-items-center justify-content-end w-20">
														{workflowTasks[taskId]?.status === 'paused' && ["activityAssignment", "groupRoleAssignment"].includes(workflowTasks[taskId]?.parameters?.type) && this.showProccedButton(workflowTasks[taskId]) ?
															<Button color="primary" onClick={() => this.handleProceedOrNotify(workflowTasks[taskId], taskId)} variant="contained" className=" ml-2 primary-button">START</Button>
															: workflowTasks[taskId]?.status === 'paused' && ["activityAssignment", "groupRoleAssignment"].includes(workflowTasks[taskId]?.parameters?.type) && !this.showProccedButton(workflowTasks[taskId]) && userRole && (userRole != 'Client') ?
																<Button color="primary" onClick={() => this.handleProceedOrNotify(workflowTasks[taskId], taskId)} variant="contained" className=" ml-2 primary-button">NOTIFY</Button>
															: workflowTasks[taskId]?.status === 'error' && (subWorkflowDetailData?.status == "open" || subWorkflowDetailData?.status == "error") && subWorkflowDetailData?.roleAssignmentPending && userRole && (userRole != 'Client') ?
																<Button  color="primary" title='Assign Pending Roles' className='ml-2 primary-button' onClick={() => this.props.handleOpenWorkflowPendingRoleList(subWorkflowDetailData)} variant="contained">Assign Roles</Button>
															: workflowTasks[taskId]?.totalDuration ?
																<div className="activity-time-duration">
																	{workflowTasks[taskId]?.totalDuration > 60 ? `${this.getFormatedDuration(workflowTasks[taskId]?.totalDuration)}` : `${workflowTasks[taskId]?.totalDuration}s`}
																</div>
															: <></>
														}
													</div>
												</div>
											</div>
										))
										}
									</div>
								</div>
								: !subWorkflowLoading &&
								<p className="text-center m-3">No Detail available</p>
							}
						</div>
					</Scrollbars>
				</div>
			</RctCollapsibleCard>
		);
	}
}

export default SubWorkflowDetail