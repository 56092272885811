/**
 * Auth User Reducers
 */
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    GET_USER_PROFILE,
    SET_USER_DETAIL,
    GET_VALIDATE_INVITE_CODE,
    GET_SUCCESS_VALIDATE_CODE,
    POST_LIFE_SHERPA_CREATE_USER,
    POST_LIFE_SHERPA_SUCCESS_CREATE_USER,
    SHOW_FIREBASE_AUTH_LOADING,
    HIDE_FIREBASE_AUTH_LOADING,
    RESET_USER_PASSWORD,
    RESET_USER__PASSWORD_SUCCESS,
    VERIFY_USER_ACCOUNT_VIA_MAIL,
    VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL,
    RESEND_VERIFICATION_LINK,
    RESEND_VERIFICATION_LINK_SUCCESS,
    GET_ORGANIZATION_PRIVACY_POLICY,
    GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY,
    POST_LIFE_SHERPA_LOGIN,
    POST_LIFE_SHERPA_LOGIN_SUCCESS,
    GET_USER_FIREBASE_TOKEN,
    GET_SUCCESS_USER_FIREBASE_TOKEN,
    GET_USER_INFO,
    GET_USER_ORGANIZATION,
    GET_SUCCESS_USER_ORGANIZATION,
    GET_USER_ORGANIZATION_FROM_TOKEN,
    GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN,
    UPDATE_USER_STATUS,
    SET_STATUS_COLOR,
    GET_SUCCESS_USER_BY_ID,
    CREDIT_AFTER_BUY_REWARD,
    BUY_NEW_REWARD,GET_STATUS_SECTIONS,
    GET_LMS_URL,
    GET_LMS_URL_SUCCESS,
    GET_LMS_URL_FAILED,
    SET_LOGOUT_LMS_URL,
    GET_ALLOWED_MODULE,
    GET_ALLOWED_MODULE_SUCCESS,
    GET_SUPER_ADMIN_DATA,
   GET_SUPER_ADMIN_DATA_SUCCESS,
   GET_GLOBAL_ADMIN_DATA,
   GET_GLOBAL_ADMIN_DATA_SUCCESS,
   SHOW_UPDATE_PASSWORD_LOADING,
   HIDE_UPDATE_PASSWORD_LOADING,
   SHOW_PASSWORD_UPDATE_DIALOG,
   PASSWORD_UPDATED_SUCCESSFULLY,
   PASSWORD_UPDATE_UNSUCCESSFULL,
   CLEAR_UPDATE_PASSWORD_ERROR,
   CLEAR_PUBLIC_SETTING,
   POST_LIFE_SHERPA_FAILED_CREATE_USER,
   HIDE_CREATE_USER_FAILED_DATA,
   CLEAR_GET_VALIDATE_INVITE_CODE_RESPONSE,
   GET_FAILED_USER_FIREBASE_TOKEN,
   GET_THEME_LS_LOGO,
   GET_THEME_LS_LOGO_SUCCESS,
   GET_THEME_LS_LOGO_FAILED,
   OPENED_CONFIGURATOR_IN_LSPORTAL,
   OPENED_LMS_IN_LSPORTAL,
   ENABLE_DFAULT_AUTH0_LOGIN_STATUS,
   DISABLE_DFAULT_AUTH0_LOGIN_STATUS,
   SET_USA_AUTH0_ENABLED,
   SET_AUSTRALIA_AUTH0_ENABLED,
   GET_WORKSPACE_IS_AVAILABLE,
   HIDE_ANALYTICS_MENU,
   GET_WORKSPACE_ADMIN_DATA,
   GET_WORKSPACE_ADMIN_DATA_SUCCESS,
   ANALYTICS_LOGIN,
   SHOW_PUSH_NOTIFICATION_ALERT,
   HIDE_PUSH_NOTIFICATION_ALERT,
   GET_USER_BY_ID,
   GET_USER_BY_ID_FAILED,
   SET_CURRENT_USER_TIMEZONE,
   SET_SUCCESS_CURRENT_USER_TIMEZONE,
   SAVE_USER_HAVING_NO_GROUP,
   HIDE_THEME_LOADING,
   FETCH_PRIVACY_POLICIES,
   FETCH_PRIVACY_POLICIES_SUCCESS,
   SAVED_PRIVACY_POLICIES,
   SAVED_PRIVACY_POLICIES_SUCCESS,
   SAVED_PRIVACY_POLICIES_FAILED,
   GET_ALLOW_CHANGE_PASSWORD,
   SAVE_WORKFLOW_ENABLE_STATUS
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
    user: null, //localStorage.getItem('user_email'),
    uid: null,
    idToken: null,
    loading: false,
    userData: null,
    inviteCode: null,
    status: null,
    status1:null,
    statusColor:null,
    statusColor1:null,
    score:null,
    score1:null,
    status2: null,
    status3:null,
    statusColor2:null,
    statusColor3:null,
    score2:null,
    score3:null,
    resetPassword: null,
    emailVerified: null,
    resendverifyEmail: null,
    resetPasswordStatus: null,
    resendverifyLinkStatus: null,
    confirmCode: null,
    resetPassResponseData: null,
    privacyPolicy: null,
    firebaseToken: null,
    profile: null,
    userOrgDetail: null,
    userOrgFromToken: null,
    credit:null,
    sections:null,
    userRole:null,
    selectedMenuItem: 0,
    orgDetail: null,
    lmsUrl:'',
    lmsLogoutUrl:"",
    lmsUrlLoading:true,
    allowedModules: null,
    hideRewards:false,
    direction: null,
    direction1: null,
    direction2: null,
    direction3: null,
    notifications: null,
    isSuperAdmin: null,
    isGlobalAdmin: null,
    statusData : null,
    updatePasswordLoading:false,
    passwordDialog:false,
    updateError:null,
    createUserFailed:null,
    getFirebaseTokenFailed:null,
    usersInfo:null,
    themeLoading:false,
    themeData:null,
    isConfiguratorOpened:false,
    isLMSOpened:false,
    defaultAuth0LoginStatus:false,
    setAustralia:true,
    setUSA:false,
    workspaceDetails:"",
    workspaceURL: null,
    hideAnltMenu: true,
    isWorkspaceAdmin: false,
    isAnalyticsLoggedIn: false,
    displayPushNotiAlert: false,
    displayOnce: false,
    pokeFormId: null,
    userLoading: false,
    timezone: null,
    user_no_group: null,
    userPrivacyPolicyData: null,
    allowChangePassword: false,
    enableWorkflows: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

      
        case LOGIN_USER:
            return { ...state, loading: true };
        case ENABLE_DFAULT_AUTH0_LOGIN_STATUS:
            return { ...state, defaultAuth0LoginStatus: true };
        case DISABLE_DFAULT_AUTH0_LOGIN_STATUS:
            return { ...state, defaultAuth0LoginStatus: false, setUSA: false,  setAustralia:false };
        case SET_USA_AUTH0_ENABLED:
            return { ...state, setUSA: true,  setAustralia:false};
        case SET_AUSTRALIA_AUTH0_ENABLED:
            return { ...state, setUSA: false,  setAustralia:true};
        case GET_USER_PROFILE:
            return { ...state, loading: true };

        case SET_USER_DETAIL:
            const {uid, idToken} = action.payload
            return { ...state, uid, idToken};

        case GET_ALLOWED_MODULE:
            return { ...state, loading: false };
            
        case GET_ALLOWED_MODULE_SUCCESS:
            return { ...state, allowedModules: action.payload ,loading: false };

        case GET_LMS_URL:
             return { ...state, lmsUrlLoading: true };
        case GET_LMS_URL_SUCCESS:
             return { ...state, lmsUrl: action.payload ,lmsUrlLoading:false};
        case GET_LMS_URL_FAILED:
             return { ...state, lmsUrlLoading: true};
        case SET_LOGOUT_LMS_URL:
            return { ...state, lmsUrlLoading: true , lmsLogoutUrl:action.payload};

        case LOGIN_USER_SUCCESS:
            const updatedUser = {...state.user, ...action.payload} 
            return { ...state, loading: false, user: updatedUser};

        case LOGIN_USER_FAILURE:
            return { ...state, loading: false };

        case LOGOUT_USER:
            return { ...state, user: null };

        case SIGNUP_USER:
            return { ...state, loading: true };

        case SIGNUP_USER_SUCCESS:
            const modefiedUser = {...state.user, ...action.payload} 
            return { ...state, loading: false, user: modefiedUser };

        case SIGNUP_USER_FAILURE:
            return { ...state, loading: false };

        case GET_VALIDATE_INVITE_CODE:
            return { ...state, loading: true};
        case CLEAR_GET_VALIDATE_INVITE_CODE_RESPONSE:
            return { ...state, status:null,inviteCode:null};
        case  GET_USER_ORGANIZATION_FROM_TOKEN:
            return { ...state, loading: true };

        case GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN:
            return { ...state, loading: false , userOrgFromToken: action.payload, orgDetail: action.payload};

        case GET_USER_ORGANIZATION:
            return { ...state, loading: true };

        case GET_SUCCESS_USER_ORGANIZATION:
            return { ...state, loading: false , userOrgDetail: action.payload, orgDetail: action.payload};
        case GET_ALLOW_CHANGE_PASSWORD:
            return { ...state, allowChangePassword: action.payload};

        case GET_SUCCESS_VALIDATE_CODE:
            const {inviteCode, status} = action.payload
            return { ...state, loading: false, inviteCode, status };

        case GET_ORGANIZATION_PRIVACY_POLICY:
            return { ...state, loading: true};

        case GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY:
            return { ...state, loading: false, privacyPolicy: action.payload};
        case SAVE_WORKFLOW_ENABLE_STATUS: 
            return { ...state, enableWorkflows: action.payload};
        case GET_USER_FIREBASE_TOKEN: 
            return { ...state, loading: true,getFirebaseTokenFailed:null };

        case GET_SUCCESS_USER_FIREBASE_TOKEN:
            return { ...state, loading: false, firebaseToken: action.payload };

        case RESEND_VERIFICATION_LINK:
            return { ...state, loading: true };

        case RESEND_VERIFICATION_LINK_SUCCESS:
            return { ...state, loading: false, resendverifyEmail: action.payload.email, resendverifyLinkStatus: action.payload.status };

        case VERIFY_USER_ACCOUNT_VIA_MAIL: 
            return { ...state, loading: true };

        case VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL:
            return { ...state, loading: false, emailVerified: action.payload.status,  confirmCode: action.payload.code};

        case RESET_USER_PASSWORD:
            return { ...state, loading: true };

        case RESET_USER__PASSWORD_SUCCESS: 
            return { ...state, loading: false, resetPassword: action.payload.resetPassword, resetPasswordStatus: action.payload.status, resetPassResponseData: action.payload.data};

        case POST_LIFE_SHERPA_CREATE_USER:
            return {...state, loading: true}

        case POST_LIFE_SHERPA_SUCCESS_CREATE_USER:
            const {user, userData} = action.payload
            return {...state, loading: false, user, userData}
        case POST_LIFE_SHERPA_FAILED_CREATE_USER:
            return {...state, loading: false, createUserFailed:action.payload}
        case HIDE_CREATE_USER_FAILED_DATA:
            return {...state, loading: false, createUserFailed:null}
        case SHOW_FIREBASE_AUTH_LOADING:
            return { ...state, loading: true };

        case HIDE_FIREBASE_AUTH_LOADING:
            return { ...state, loading: false };

        case POST_LIFE_SHERPA_LOGIN:
            return { ...state, loading: true };

        case POST_LIFE_SHERPA_LOGIN_SUCCESS:
            return { ...state, loading: false , userData: action.payload};
        
        case GET_USER_INFO:
            return { ...state, profile: action.payload };

        case UPDATE_USER_STATUS:
            const direction =  action.payload.direction
            const direction1 = action.payload.direction1
            const direction2 = action.payload.direction2
            const direction3 = action.payload.direction3
            return {...state, statusData : action.payload}
           // return {...state, direction, direction1, direction2, direction3, status: action.payload.value,statusColor:action.payload.color,score:action.payload.score,status1: action.payload.value2,statusColor1:action.payload.color2,score1:action.payload.score2,status2: action.payload.value3,statusColor2:action.payload.color3,score2:action.payload.score3,status3: action.payload.value4,statusColor3:action.payload.color4,score3:action.payload.score4}
        
        case SET_STATUS_COLOR:
            return {...state, status1:action.payload}

        case GET_SUCCESS_USER_BY_ID:
            const notifications = action.payload.notifications && action.payload.notifications.users ? action.payload.notifications.users:null;
            const pokeFormId = action.payload.pokeFormId ? action.payload.pokeFormId : null
            return {...state,notifications,hideRewards:action.payload.hideRewards, credit:action.payload.credit,userRole:action.payload.role,usersInfo:action.payload, pokeFormId, userLoading:false}  

        case CREDIT_AFTER_BUY_REWARD:
            return {...state,credit:action.payload} 
        case GET_USER_BY_ID:
            return {...state, userLoading: true}
        case GET_USER_BY_ID_FAILED:
                return {...state, userLoading: false}
        case GET_STATUS_SECTIONS:
            return {...state,sections:action.payload}   
        
        case GET_SUPER_ADMIN_DATA:
            return { ...state, loading: true }

        case GET_SUPER_ADMIN_DATA_SUCCESS:
            return { ...state, loading: false, isSuperAdmin: action.payload };

        case GET_GLOBAL_ADMIN_DATA:
            return { ...state, loading: true }

        case GET_GLOBAL_ADMIN_DATA_SUCCESS:
            return { ...state, loading: false, isGlobalAdmin: action.payload };

        case SHOW_UPDATE_PASSWORD_LOADING:
            return { ...state, updatePasswordLoading:true};

        case HIDE_UPDATE_PASSWORD_LOADING:
            return { ...state, updatePasswordLoading:false};

        case SHOW_PASSWORD_UPDATE_DIALOG:
            return { ...state, passwordDialog:action.payload,updateError: null  };

        case PASSWORD_UPDATED_SUCCESSFULLY:
            return { ...state, updatePasswordLoading:false,passwordDialog:false };

        case PASSWORD_UPDATE_UNSUCCESSFULL:
            return { ...state, updatePasswordLoading:false,updateError: action.payload };

        case CLEAR_UPDATE_PASSWORD_ERROR:
            return { ...state, updateError: null };
        case GET_FAILED_USER_FIREBASE_TOKEN:
            return { ...state, getFirebaseTokenFailed: action.payload};
        case CLEAR_PUBLIC_SETTING:
            let updatedsetting = state.privacyPolicy
            updatedsetting["publicSettings"] = null
            return { ...state, privacyPolicy: updatedsetting, loading: false };
        case GET_THEME_LS_LOGO:
            return {...state,themeLoading:true}; 
        case GET_THEME_LS_LOGO_SUCCESS:
            return {...state,themeLoading:false,themeData:action.payload};  
        case GET_THEME_LS_LOGO_FAILED:
            return {...state,themeLoading:false, themeData:null};  
        case HIDE_THEME_LOADING: 
            return {...state, themeLoading: false};
        case OPENED_CONFIGURATOR_IN_LSPORTAL:
            return {...state,isConfiguratorOpened:true};  
        case OPENED_LMS_IN_LSPORTAL:
            return {...state,isLMSOpened: true};   
        case HIDE_ANALYTICS_MENU: 
            return {...state, hideAnltMenu: true} 
        case GET_WORKSPACE_ADMIN_DATA: 
            return {...state, loading: true} 
        case GET_WORKSPACE_ADMIN_DATA_SUCCESS: 
            return {...state, isWorkspaceAdmin: action.payload, loading: false} 
        case GET_WORKSPACE_IS_AVAILABLE:
             const workspaceDetails = action.payload ? action.payload.status : ""
             const workspaceURL = action.payload ? action.payload.workspaceURL : ""
            return {...state,workspaceDetails, workspaceURL, hideAnltMenu: false};    
        case ANALYTICS_LOGIN:
            return {...state, isAnalyticsLoggedIn: true}
        case SHOW_PUSH_NOTIFICATION_ALERT:
            return {...state, displayPushNotiAlert: action.payload}
        case HIDE_PUSH_NOTIFICATION_ALERT:
            return {...state, displayPushNotiAlert: action.payload, displayOnce: true }
        case SET_CURRENT_USER_TIMEZONE :
            return {...state, loading: true }
        case SET_SUCCESS_CURRENT_USER_TIMEZONE:
            return {...state, timezone: action.payload, loading: false }
        case SAVE_USER_HAVING_NO_GROUP: 
            return {...state, user_no_group: action.payload, loading: false}
        case FETCH_PRIVACY_POLICIES:
            return {...state, loading: true}
        case FETCH_PRIVACY_POLICIES_SUCCESS:
            return {...state, loading: false, userPrivacyPolicyData: action.payload}
        case SAVED_PRIVACY_POLICIES:
            return {...state, privacyPolicyLoading: true}
        case SAVED_PRIVACY_POLICIES_SUCCESS:
            return {...state, privacyPolicyLoading: false,userPrivacyPolicyData: action.payload}
        case SAVED_PRIVACY_POLICIES_FAILED:
            return {...state, privacyPolicyLoading: false}
        default: return { ...state };
        
    }
}
