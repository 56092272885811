/**
 * Univesal logout
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup } from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../components/Header/HeaderWithCancelButton'
import config from '../config/config'
import { withAuth0 } from "@auth0/auth0-react";
import LifeSherpaLoading from '../components/LifeSherpaLoading'
import { connect } from 'react-redux';
import {logoutUserFromFirebase} from 'Actions'
import queryString from 'query-string';
import LSPortalLogoutPopup from '../components/Header/LSPortalLogoutPopup'

class Auth0logout extends Component {

   state = {
      openLogoutPopup: true
   }
   componentDidMount(){
      console.log("%c[Auth0Logout] %c Redirected on auth0logout screen","color:black","color:green");
      // const {logout} = this.props.auth0;
      // this.props.logoutUserFromFirebase(this.props.history, logout)
   }

   handleCloseLogoutPopup= ()=> {
		const {logout} = this.props.auth0
		this.setState({openLogoutPopup:false});
      const params = queryString.parse(this.props.location.search);
      params && params.region ? localStorage.setItem("region", params.region) : console.log("Please add region to query param");
      if (params && params.redirectUrl){
         this.props.logoutUserFromFirebase(this.props.history, logout, params.redirectUrl);
      } else {
         this.props.logoutUserFromFirebase(this.props.history, logout, params.redirectUrl);
      }
	}

   render() {
      const {openLogoutPopup} = this.state
      return (
         <QueueAnim type="bottom" duration={2000}> 
            <div className={`${config.rctSessionWrapper} ${this.props.screenThemeClass}`}>
            <div className='login-screen'>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton activeTheme={this.props.activeTheme} themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} themeData={this.props.themeData} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <LifeSherpaLoading loading={true}/>
               {openLogoutPopup && <LSPortalLogoutPopup openLogoutPopup={openLogoutPopup} handleCloseLogoutPopup={this.handleCloseLogoutPopup}/>}
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center pt-2">
                              <Form>
                                 <FormGroup className="mb-10">
                                 <p className="mb-0 loader-text">Please wait logging out...</p>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}
const mapStateToProps = ({ authUser , settings}) => {
   const { screenThemeClass, popupThemeClass, themeLogo, activeTheme } = settings; 
   const { themeData} = authUser; 
   return { screenThemeClass, popupThemeClass, themeLogo, activeTheme, themeData}
}
export default withAuth0(connect(mapStateToProps, {logoutUserFromFirebase})(Auth0logout));
