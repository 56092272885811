/**
 * Firebase Login
 * Lifesherpa Portal comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */

// import *  as firebaseOriginal from 'firebase/app';
import "regenerator-runtime/runtime";
import { initializeApp } from 'firebase/app';
import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence } from 'firebase/auth';
import { getDatabase } from "firebase/database";

// import {FirebaseWrapper} from '../helpers/helpers'
// import {getFirebaseCustomToken, logoutCallback} from '../actions'

let config = null
// Initialize Firebase 
if (process.env.SERVER_MODE === 'production') {
   config = {
      //Production:
      apiKey: "AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ",
      authDomain: "project-5436648267978138178.firebaseapp.com",
      databaseURL: "https://project-5436648267978138178.firebaseio.com",
      projectId: "project-5436648267978138178",
      storageBucket: "project-5436648267978138178.appspot.com",
      messagingSenderId: "229253583067",
      appId: "1:229253583067:web:35056fbe9bf7987073fcfe",
      australiaBucket: "project-5436648267978138178-aus", 
    };
} else if (process.env.SERVER_MODE === 'development') {
   config = {
      // Migration:
      apiKey: "AIzaSyD1W3xHpt6qG6TJRssf03DpWPyArecfUZY",
      authDomain: "ls-config-migration.firebaseapp.com",
      databaseURL: "https://ls-config-migration.firebaseio.com",
      projectId: "ls-config-migration",
      storageBucket: "ls-config-migration.appspot.com",
      messagingSenderId: "312883358718",
      appId: "1:312883358718:web:092cf861073444dfc1aeaf",
      australiaBucket: "ls-config-migration-aus"  
   };
   
} else {
   config = {
      // Migration:
      apiKey: "AIzaSyD1W3xHpt6qG6TJRssf03DpWPyArecfUZY",
      authDomain: "ls-config-migration.firebaseapp.com",
      databaseURL: "https://ls-config-migration.firebaseio.com",
      projectId: "ls-config-migration",
      storageBucket: "ls-config-migration.appspot.com",
      australiaBucket: "ls-config-migration-aus" ,
      messagingSenderId: "312883358718",
      appId: "1:312883358718:web:092cf861073444dfc1aeaf"
     
    }
}
const regionKey = localStorage.getItem("region");
if(regionKey && regionKey === "australia") {
   config.storageBucket = config.australiaBucket
}

// firebaseOriginal.initializeApp(config);
const firebase = initializeApp(config);

// initializing firebase wrapper
// const firebase = new FirebaseWrapper({firebaseClient: firebaseOriginal, getFirebaseCustomToken, logoutCallback})

const auth = initializeAuth(firebase, {
   persistence: [indexedDBLocalPersistence, browserLocalPersistence],
 });

// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
// const githubAuthProvider = new firebase.auth.GithubAuthProvider();
// const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const database = getDatabase(firebase);

const getToken =  async () => {
   return new Promise((resolve, reject) => {
      const baseKey = `firebase:authUser:${config.apiKey}:[DEFAULT]`
      let db;
      let request = indexedDB.open("firebaseLocalStorageDb");

      request.onerror = function(event) {
         console.log("Why didn't you allow my web app to use IndexedDB?!");
         const data = localStorage.getItem(baseKey);
         console.log("local data", data);
         this.loadingForRedirect = false
         if(data){
            resolve(encodeURIComponent(data));
         } else {
            console.error("error 0")
            reject(event);
         }
      };

      request.onsuccess = function(event) {
         db = event.target.result;
         let transaction = db.transaction(["firebaseLocalStorage"], "readwrite");
         let objectStore = transaction.objectStore("firebaseLocalStorage");
         let request = objectStore.get(baseKey);

         request.onerror = function(event) {
            // Handle errors!
            console.error("error 1")
            reject(event);
         };

         request.onsuccess = function(event) {
            // console.log("result", request.result);
            if(request.result){
               resolve(encodeURIComponent(JSON.stringify(request.result.value)));
            } else {
            console.error("error 2")
            reject(new Error("No token found"));
            }
         };
         request.onerror = function(event){
            console.error("error 3")
            reject(event);
         }
      };
  });
}

// const getToken =  async () => {
//    return new Promise((resolve, reject) => {
//       const firebaseApiKey = config.apiKey
//       const auth0ClientConfig = JSON.parse(localStorage.getItem("auth0ClientConfig"))
//      const baseKey = `firebase:authUser:${firebaseApiKey}:[DEFAULT]`;
//      console.log("path", `@@auth0spajs@@::${auth0ClientConfig.clientId.configurator}::${auth0ClientConfig.audience}::openid profile email`);
//      const auth0Data = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${auth0ClientConfig.clientId.configurator}::${auth0ClientConfig.audience}::openid profile email`));
//      let db;
//      let request = indexedDB.open("firebaseLocalStorageDb");
//      request.onerror = function(event) {
//        console.log("Why didn't you allow my web app to use IndexedDB?!");
//        const data = JSON.parse(localStorage.getItem(baseKey));
//        console.log("local data", data);
//        if(data){
//          resolve(encodeURIComponent(JSON.stringify({fb:data, auth0: auth0Data, auth0ClientConfig})));
//        } else {
//          console.error("error 0")
//          reject(event);
//        }
//      };
//      request.onsuccess = function(event) {
//        db = event.target.result;
//        let transaction = db.transaction(["firebaseLocalStorage"], "readwrite");
//        let objectStore = transaction.objectStore("firebaseLocalStorage");
//        let request = objectStore.get(baseKey);
//        request.onerror = function(event) {
//          // Handle errors!
//          console.error("error 1")
//          reject(event);
//        };
//        request.onsuccess = function(event) {
//          console.log("result", request.result);
//          if(request.result){
//            resolve(encodeURIComponent(JSON.stringify({fb:request.result.value,auth0: auth0Data, auth0ClientConfig})));
//          } else {
//            console.error("error 2")
//            reject(new Error("No token found"));
//          }
//        };
//        request.onerror = function(event){
//          console.error("error 3")
//          reject(event);
//        }
//      };
//    });
//  }

// const getToken = async() => {
//   const organization = localStorage.getItem("orgId")
//   return encodeURIComponent(JSON.stringify({organization}));
// }

export {
   auth,
   // googleAuthProvider,
   // githubAuthProvider,
   // facebookAuthProvider,
   // twitterAuthProvider,
   database,
   getToken,
   firebase,
};
