import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import Moment from 'moment';
import {
    hideExecuteActivityConfirmation,
    executeActivityWithAchievementId,
    selectActivityId,
    handlEditor,
    getUserById,
    selectActivity,
    setSelectedMemberId,
	changeRole,
	getResourceList,
	getcategories,
	getKeywords,
	getMyCourses,
	showClientPanel,
    clearHistoryData,
    getSelectedActivity,
    showActivityHistoryDetail,
    handleHideActivityDetailsPanel,
    setClientActivitiesType,
    showExecuteActivityPannel
} from 'Actions';
import { IconButton } from '@mui/material';
import AppConfig from "../../constants/AppConfig";
import SidebarEditorMain from 'Components/SherpaActivities/SidebarEditorMain';
import { scrollMaincontainerToTop } from "../../helpers/helpers";

class ExecuteActivityConfirmation extends Component {
    state = {
        selected: '',
        activityId: '',
        activityType: '',
        previewId: null,
        showDetails: ''
    }

    handleRewards = () => {
        this.props.selectActivity(null)
        this.props.handlEditor(false)
        let uid = localStorage.getItem('uid')
        let idToken = localStorage.getItem('idToken')
        this.props.getUserById(uid, idToken)
        this.setState({ previewId: null, mobileOpen: false, selected: '', activityId: '', activityType: '', showRewards: true })
    }

    handleStartActivity = (e) => {
		this.setState({ previewId: null, mobileOpen: true, activityId: e.activityId, activityType: e.type }, () => {
            this.props.showExecuteActivityPannel('start')
        })
    }
    
    handleClose3 = () => {
        this.props.selectActivityId(null)
        this.props.selectActivity(null)
        this.props.handlEditor(false)
        let uid = localStorage.getItem('uid')
        this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '' }, () => {
            this.props.showExecuteActivityPannel('')
        })
    }

    handleClose2 = () => {
        this.props.selectActivityId(null)
        this.props.selectActivity(null)
        this.props.handlEditor(false)
        this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '', openForm: true }, () => {
            this.props.showExecuteActivityPannel('')
        })
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    handleConfirm = () => {
        let { executeActivityNotification, clientPanel } = this.props;
        const {userId: clientUserId, achievementId, organization} = executeActivityNotification || {};
        if(!achievementId) {
            console.log('Achievement Not found')
            this.handleClose();
            return;
        }
        const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
        const orgId = organization || localStorage.getItem("orgId");
        const userId = clientUserId || auth_uid;

        const isUserSelected = uid != auth_uid;
        const isClientActivity = clientUserId && clientUserId != auth_uid;
        const isActivityClientSelected = isUserSelected && (clientUserId && clientUserId == uid);

        if(isActivityClientSelected) {
            this.handleExecuteActivity(achievementId, orgId, userId);
            return;
        }

		if(isUserSelected ) {
			localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
			this.props.setSelectedMemberId(null);
			this.props.changeRole({ role: 'admin', id: auth_uid });
            if(clientPanel) {
				this.props.getResourceList('','', '');
				this.props.getcategories();
				this.props.getKeywords();
				this.props.getMyCourses();
				this.props.showClientPanel(false);
			}
            const activityConfirmation = localStorage.getItem("activityConfirmation");
            if(activityConfirmation) {
                localStorage.removeItem("activityConfirmation");
            }
            this.props.clearHistoryData();
            this.props.getSelectedActivity(null);
            this.props.showActivityHistoryDetail(false)
		} 
        if(isClientActivity) {
            localStorage.setItem('uid',clientUserId);
            this.props.changeRole({ role: 'client', id: clientUserId })
            this.props.setSelectedMemberId(clientUserId)
            this.props.handleHideActivityDetailsPanel();
            this.props.setClientActivitiesType("Touchpoint");
            // this.props.showClientPanel(true);
        }

        console.log("clientUserId :", clientUserId, {achievementId, orgId, userId});
        this.handleExecuteActivity(achievementId, orgId, userId);
       
    }
    handleExecuteActivity = (achievementId, orgId, userId) => {
        const date = Moment().format('YYYY-MM-DD HH:mm:ss Z');
        localStorage.setItem("selectedOrgId", orgId)
        this.props.executeActivityWithAchievementId(achievementId, date, userId);
        this.props.selectActivityId(achievementId);
        this.props.handlEditor(true);
        this.props.hideExecuteActivityConfirmation();
        this.handleStartActivity(achievementId)
    }



    handleClose = () => {
        this.props.hideExecuteActivityConfirmation();
    }

    render() {
        const { executeActivityNotification, showExecuteActPanel } = this.props;
        let { activityId, activityType} = this.state;
        console.log(" executeActivityNotification --> ", executeActivityNotification);
        return (
            <div> {executeActivityNotification && executeActivityNotification.title &&
                <Dialog
                    fullScreen={false}
                    open={executeActivityNotification && executeActivityNotification.title ? true : false}
                    fullWidth={true}
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} warning-dialog-popup`}
                >
                    <DialogContent className="p-0">
                        <div className="text-center mb-3 mx-4 mt-4 execute-activity-alert">
                            <div className="d-flex align-items-center justify-content-center" style={{height: 80}}>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={require('../../assets/img/help-logo.png')}
                                    style={{height: 80, width: 80, border: '0px solid #fff'}}
                                />
                            </div>
                            <h2 className=" exitDialog text-center">{executeActivityNotification.title}</h2>
                            <h2 className=" subHeading text-center mb-4">{executeActivityNotification.message}</h2>
                            <div className="d-flex pt-3  align-items-center justify-content-between">
                                <button className="btn btn-warning mr-2" onClick={() => this.handleClose()}>NO</button>
                                <button className="btn btn-grey ml-2" onClick={() => this.handleConfirm()}>OK</button>
                                {/* <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}><a className="text-white" target="_self" href="/success">ok</a></button>*/}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            }
            {showExecuteActPanel &&
                <SidebarEditorMain
                    selected={showExecuteActPanel}
                    previewId={this.state.previewId}
                    showDetails={this.state.showDetails}
                    activityType={activityType}
                    activityId={activityId}
                    handleClose3={this.handleClose3}
                    handleClose2={this.handleClose2}
                    handleRewards={this.handleRewards}
                    handleDrawerToggle={this.handleDrawerToggle}
                    />
            }

            </div>
        );
    }
}
const mapStateToProps = ({ settings, activitiesList, authUser,clientsList }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const { executeActivityNotification, selectedId, showExecuteActPanel } = activitiesList
    const { userRole } = authUser;
    let { clientPanel } = clientsList;

    return { screenThemeClass, popupThemeClass, executeActivityNotification, selectedId, userRole, clientPanel, showExecuteActPanel };
};

export default withRouter(connect(mapStateToProps, {
    hideExecuteActivityConfirmation,
    executeActivityWithAchievementId,
    selectActivityId,
    handlEditor,
    getUserById,
    selectActivity,
    setSelectedMemberId,
	changeRole,
	getResourceList,
	getcategories,
	getKeywords,
	getMyCourses,
	showClientPanel,
    clearHistoryData,
    getSelectedActivity,
    showActivityHistoryDetail,
    handleHideActivityDetailsPanel,
    setClientActivitiesType,
    showExecuteActivityPannel
})(ExecuteActivityConfirmation));
