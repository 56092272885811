
import {axiosClient} from '../sherpaApi'
import { NotificationManager } from 'react-notifications';

import {
    GET_USER_NOTIFICATIONS_HISTORY,
    GET_SUCCESS_USER_NOTIFICATIONS_HISTORY,
    SHOW_USER_NOTIFICATIONS_HISTORY_LOADING,
    HIDE_USER_NOTIFICATIONS_HISTORY_LOADING,
    SHOW_NOTIFICATION_POPUP,
    HIDE_NOTIFICATION_POPUP,
    HIDE_NOTIFICATION_LOADING
 } from './types';

export const getNotificationsById = (userId, idToken) => (dispatch) => {
    let url = `/users/${userId}/notifications/history`
    dispatch({ type: GET_USER_NOTIFICATIONS_HISTORY });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.status === 200){
            // console.log('Notifications: ', response.data)
            dispatch({ type: GET_SUCCESS_USER_NOTIFICATIONS_HISTORY, payload: response.data });
        } else {
            dispatch({ type: HIDE_USER_NOTIFICATIONS_HISTORY_LOADING });
            NotificationManager.error(response.data)
        }
    }).catch(async(error) => {
        const errorData = error.response
        console.log(errorData)
        dispatch({ type: HIDE_USER_NOTIFICATIONS_HISTORY_LOADING });
     })
}



export const showNotificationLoading = () => (dispatch) =>{
    dispatch({type: SHOW_USER_NOTIFICATIONS_HISTORY_LOADING})
}

export const hideNotificationLoading = () => (dispatch) =>{
    dispatch({type: HIDE_USER_NOTIFICATIONS_HISTORY_LOADING})
}


export const showNotificationPopup = (notification) => (dispatch) => {
    dispatch({type: SHOW_NOTIFICATION_POPUP, payload: notification})
}

export const hideNotificationPopup = () => (dispatch) => {
    dispatch({type: HIDE_NOTIFICATION_POPUP})
}


export const hideNotificationRelatedData = () => (dispatch) =>  {
    dispatch({type: HIDE_NOTIFICATION_LOADING});
}
