import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getResourceList, getcategories, getKeywords, setResorcesSelectedList, clearResourcesData, setPostOpenHistory, setResourcesFilterItemsCount } from '../../actions'
import { IconButton, Button } from '@mui/material';
import Badge from '@mui/material/Badge'
import moment from 'moment'
import Slider from "react-slick";
import LifeSherpaLoading from '../LifeSherpaLoading';
import FileDownload from "js-file-download";
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from '@mui/styles';
import ResourcesFilter from '../Widgets/ResourcesFilter';
import Grid from '@mui/material/Grid';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PostView from "../Widgets/PostView";
import Linkify from '../Widgets/Linkify';

const styles = theme => ({
    tabsRoot: {
        minHeight: '40px',
        height: '40px',


    },
    tabRoot: {
        minHeight: '40px',
        height: '40px',
        backgroundColor: '#008C95',
        borderRadius: 6 + "px",
        marginRight: "3px",
        fontSize: "14px",
        minWidth: '50px'

    }

});
class UserResourcesPage extends Component {

    state = {
        showFilter: false,
        sort: 'asc',
        collection: '',
        showImg: false, list: null,
        activeIndex: 0,
        showFilteredButton: false,
        keywords: '',
        iframeLoading: true
    }

    componentDidMount() {
        console.log("%c[UserResources]%c Rendered user resources panel", "color:black", "color:green");
        const orgIds = [localStorage.getItem("selectedOrgId")]
        this.props.clearResourcesData()
        this.props.getResourceList('', 'new', '', orgIds);
        this.props.getcategories(orgIds);
        this.props.getKeywords(orgIds);

    }

    downloadFile = (url, name) => {
        FileDownload(url, name);
    }

    handleReport = (value) => {
        //this.props.Resources.categories[i].selected=true
        const i = this.props.Resources.categories.findIndex(cat => cat.id === value.id && cat.organization === value.organization)
        if (this.props.Resources.categories[i].selected) this.props.Resources.categories[i].selected = false
        else this.props.Resources.categories[i].selected = true
        this.setState({ collection: this.props.Resources.categories[i].selected })
    }
    handleKeywords = (value) => {
        const i = this.props.Resources.keywords.findIndex(ketyword => ketyword.name === value.name)
        if (this.props.Resources.keywords[i].selected) this.props.Resources.keywords[i].selected = false
        else this.props.Resources.keywords[i].selected = true
        this.setState({ collection: this.props.Resources.keywords[i].selected })
    }

    handleSort = (value) => {
        if (this.state.sort === value) this.setState({ sort: '' })
        else this.setState({ sort: value })
    }

    applyFilter = () => {
        const orgIds = [localStorage.getItem("selectedOrgId")]
        let collection = ''
        let keywords = ''
        let selectedCounts = 0
        let allCounts = 0
        this.props.Resources.keywords.map((cat, i) => {
            if (cat.selected) {
                keywords = keywords + cat.name + ','
                selectedCounts += cat.totalCount ? cat.totalCount : 0
            }
            allCounts += cat.totalCount ? cat.totalCount : 0
        })
        this.props.Resources.categories.map((cat, i) => {
            if (cat.selected) {
                collection = collection + cat.id + ','
                selectedCounts += cat.totalCount ? cat.totalCount : 0
            }
            allCounts += cat.totalCount ? cat.totalCount : 0
        })
        selectedCounts = collection === '' && keywords === '' ? allCounts : selectedCounts
        this.props.getResourceList(this.state.sort, collection, keywords, orgIds, true);
        //this.props.setResorcesSelectedList(list);
        this.setState({ showFilter: false, showFilteredButton: true, keywords })
    }

    clearFilter = () => {
        const orgIds = [localStorage.getItem("selectedOrgId")]
        this.props.Resources.categories.map((cat, i) => {
            this.props.Resources.categories[i].selected = false
        })
        this.props.Resources.keywords.map((cat, i) => {
            this.props.Resources.keywords[i].selected = false
        })
        let { newReportAvailable, categories } = this.props.Resources;

        if (!newReportAvailable && categories.length > 0) {
            this.props.getResourceList('', categories[0].id, '', orgIds);
            this.props.setResorcesSelectedList(categories[0]);
        }
        else {
            this.props.setResorcesSelectedList(null);
            this.props.getResourceList('', 'new', '', orgIds);
        }
        this.props.setResourcesFilterItemsCount(0);
        this.setState({ showFilter: false, sort: '', collection: '', showFilteredButton: false, keywords: '' })
    }

    handleList = (value) => {
        const orgIds = [localStorage.getItem("selectedOrgId")]
        this.props.getResourceList('', value, '', orgIds)
        if (value === '') this.props.setResorcesSelectedList(null);
        else {
            let list = this.props.Resources.categories.find((cat) => cat.id === value)
            this.props.setResorcesSelectedList(list);
        }
    }

    showImg = (value) => {
        const categories = this.props.Resources.categories;
        const categoryId = value.categoryId;
        const postId = value.id;
        const postTitle = value.title;
        const organization = value.organization;
        const catergoryDetails = categories && categories.find(category => category.id == categoryId);
        if (catergoryDetails && catergoryDetails.name) {
            const categoryName = catergoryDetails.name;
            this.props.setPostOpenHistory(categoryName, postId, postTitle, organization)
        } else {
            console.error("Post category name not found");
        }
        if (value.fileURL) {
            this.setState({ showImg: !this.state.showImg, list: value, iframeLoading: true });
        }
    }

    getNewAllReports = (value) => {
        const orgIds = [localStorage.getItem("selectedOrgId")]
        this.props.setResorcesSelectedList(null);
        this.props.getResourceList("", value, '', orgIds);
    }

    handleChange(e, value) {
        this.setState({ activeIndex: value });
    }

    handleCloseFilter = () => {
        this.setState({ showFilter: false })
    }

    handleShowFilter = () => {
        let { Resources } = this.props;
        let { categories, keywords } = Resources
        if (categories || keywords) {
            this.setState({ showFilter: true })
        }
    }

    getScrollHeight() {
        let container = document.getElementsByClassName("resources-list-panel")[0];
        let bulletinContent = document.getElementsByClassName("bulletin-posts-list")[0];
        if (container && bulletinContent) {
            const categoryPanelHeight = container.clientHeight - (bulletinContent.clientHeight + 35);
            return categoryPanelHeight ? `${categoryPanelHeight}px` : '220px';
        } else {
            return '220'
        }
    }

    render() {
        let { Resources } = this.props;
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: true,
            rtl: false
        };
        let { newReportAvailable, selectedList, filterItemsCount, categories, resourceList} = this.props;
        //console.log("resource  --> ",Resources)
        return (

            <div className="resources-panel resources-list-panel pt-1" data-testid="details-panel-client-resources">
                <div className="bulletin-posts-list px-3 pt-2">
                    {this.state.showFilter &&
                        <ResourcesFilter
                            showFilter={this.state.showFilter}
                            sort={this.state.sort}
                            collection={this.state.collection}
                            Resources={Resources}
                            handleCloseFilter={this.handleCloseFilter}
                            clearFilter={this.clearFilter}
                            applyFilter={this.applyFilter}
                            handleSort={this.handleSort}
                            handleReport={this.handleReport}
                            handleKeywords={this.handleKeywords}
                            panel="resources"
                        />
                    }
                    {this.state.showImg &&
                        <PostView
                            open={this.state.showImg}
                            post={this.state.list}
                            handleClose={() => this.setState({ showImg: false })}
                        />
                    }

                    <LifeSherpaLoading loading={Resources.loading} />

                    {resourceList && resourceList.length > 0 ?
                        <>
                            <div className={`d-flex align-items-center mt-1 ${selectedList && selectedList.name === 'Filtered' ? "resources-filters-header" : "resources-filters-header-filtered"}`}>
                                <div className="pr-2">
                                    <IconButton title="Filter" className="text-white rounded-circle font-lg ml-1" style={{ background: '#008C95' }} onClick={() => this.handleShowFilter()}>
                                        <FilterAltIcon className="filter-icon" fontSize="large" />
                                    </IconButton>
                                </div>
                                <div className="text-secondary font-lg ml-auto mr-1 text-right base-font-color">{selectedList ? selectedList.name + " (" + selectedList.totalCount + ")" : resourceList ? 'All New (' + resourceList.length + ')' : ''}</div>
                            </div>
                            <div className=" mx-3">

                                <Slider {...settings}>
                                    {resourceList && resourceList.map((list, key) => (
                                        <div className="list-card-slider mt-3 " style={{ borderRadius: 16 + "px", height: '250px' }} key={key}>
                                            <div onClick={() => this.showImg(list)} className="mx-auto my-auto text-center p-3 d-flex align-items-center justify-content-center" style={{ width: '200px', height: '175px' }}><img className="img-fluid" style={{ maxHeight: '150px' }} src={list.thumbnailURL} alt="" /></div>
                                            {list.fileURL ?
                                                <div className="d-flex  w-100 align-items-center justify-content-between font-md  mb-2" style={{ margin: "12px" }}>
                                                    <div className="text-muted  text-left align-items-left font-sm  w-50" >
                                                        {list.contentType ? list.contentType + " " : "Resource File "}
                                                    </div>
                                                    <div className="w-50 align-items-right  text-right">
                                                        {list.sharingOption && ["Sharable", "Shareable"].includes(list.sharingOption) ?
                                                            <a href={list.fileURL} target="_blank" download>
                                                                <div key={"statuslabel"}
                                                                    className="download-label ml-auto "
                                                                >
                                                                    Download
                                                                </div>
                                                            </a>
                                                            : ""
                                                        }
                                                    
                                                       
                                                    </div>
                                                </div>
                                                : ""}
                                            <div title={list.title} className="font-md text-secondary text-truncate">{list.title}</div>
                                            <div className="col-12 text-muted font-sm mb-1">{moment(list.createdOn).format('MMM DD, YYYY')}</div>
                                            {/* {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary mb-3">{list.description}</div>} */}
                                            {list.description && <div className="col-12 font-sm text-muted mb-3"> <Linkify text={list.description}/> </div>}
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                            <div className="d-none d-md-block">

                                {/*<div className="row mt-3 mx-4 px-4">Resources.resourceList&&Resources.resourceList.map((list,key)=>(
                                <div className="list-card p-3 mx-2" style={{borderRadius:16+"px",width:220+'px',height: '250px'}}  key={key} onClick={()=>this.showImg(list)}>
                                    {list.thumbnailURL&&<div className="mx-auto mt-1 text-center" style={{width:'200px',height:'150px'}}><img className="img-fluid" style={{maxHeight:'150px'}} src={list.thumbnailURL} alt="" /></div>}
                                    <div className="col-12 text-secondary my-1" style={{width:220+'px',fontSize:"15px"}}>{list.title}</div>
                                    <div className="col-12 font-md">{moment(list.createdOn).format('DD MMMM, YYYY')}</div>
                                    {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary my-1">{list.description}</div>}
                                </div>
                            )) </div>*/}


                            </div>

                        </>
                        : <>
                            <div className={`d-flex mt-1 ${selectedList && selectedList.name === 'Filtered' ? "resources-filters-header" : "resources-filters-header-filtered"}`}>
                                <IconButton title="Filter" className="text-white rounded-circle font-lg ml-1" onClick={() => this.handleShowFilter()} style={{ background: '#008C95' }} >
                                    <FilterAltIcon className="filter-icon" fontSize="large" />
                                </IconButton>
                                <div className="text-secondary font-lg ml-auto mr-1 base-font-color">{selectedList ? selectedList.name + " (" + selectedList.totalCount + ")" : resourceList ? 'All New (' + resourceList.length + ')' : ''}</div>
                            </div>
                            <div className=" mx-2">
                                <Slider {...settings}>

                                    <div className="list-card-slider mt-3 mb-4 " style={{ borderRadius: 16 + "px", height: '250px' }}  >
                                        <div className="mx-auto my-auto text-center p-3 d-flex align-items-center justify-content-center" style={{ width: '200px', height: '175px' }}>
                                            <img className="img-fluid" style={{ height: '150px' }} src={"my image"} alt="" />
                                        </div>
                                        <div className="col-12 mb-4 font-md text-center text-secondary">No Report Found</div>
                                        <div className="col-12 font-md text-secondary mb-2" style={{ fontSize: "12px" }}>{"  "}</div>

                                        <div className="col-12 font-md text-secondary">{"   "}</div>
                                        <div className="col-12 text-muted font-sm mb-3">{"  "}</div>
                                        {/* {!list.thumbnailURL&&<div className="col-12 font-sm text-secondary mb-3">{list.description}</div>} */}
                                    </div>

                                </Slider>
                            </div>

                        </>
                    }
                </div>
                <h2 className="text-secondary px-3 font-lg  base-font-color">Collection</h2>
                <Scrollbars
                    className="rct-scroll list-sherpa-activity resources-list-background"
                    id="bulletin-category-container"
                    autoHide
                    style={{ height: this.getScrollHeight() }}
                >
                    <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        className="resources-category-buttons-grid px-3"
                    >
                        {/* <Grid onClick={()=>this.handleList('')} item  >
                            <div className="btn h-30 resource-category-buttons mx-1 my-1">All Reports</div>
                        </Grid> */}
                        <Grid item >
                            <Button color="secondary" variant="contained" className={selectedList ? "h-30 resource-category-buttons mx-1 my-1" : "h-30 resource-category-buttons-selected mx-1 my-1"} disabled={!newReportAvailable} onClick={() => this.getNewAllReports('new')}>All New</Button>
                        </Grid>
                        {this.state.showFilteredButton &&
                            <Grid item  >
                                <Button color="secondary" variant="contained" className={selectedList && selectedList.name === 'Filtered' ? "h-30 resource-category-buttons-selected mx-1 my-1" : "h-30 resource-category-buttons mx-1 my-1"} onClick={() => this.applyFilter()}>
                                    <Badge className="mx-1 mr-2" badgeContent={filterItemsCount || 0} /*color="error"*/>Filtered</Badge>
                                </Button>
                            </Grid>
                        }
                        {categories && categories.map((list, key) => (
                            <Grid item key={key}>
                                <Button color="secondary" variant="contained" data-testid={`my-resources-${key + 1}-category-button`} className={selectedList && selectedList.id === list.id ? "h-30 resource-category-buttons-selected mx-1 my-1" : "h-30 resource-category-buttons mx-1 my-1"} key={key} onClick={() => this.handleList(list.id)}>
                                    <Badge className="mx-1 mr-2" badgeContent={list.totalCount} style={{ width: "fitContent" }} /*color="error"*/>{list.name.length > 45 ? list.name.substring(0, 45) + "..." : list.name}</Badge>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Scrollbars>
            </div>

        );
    }
}
const mapStateToProps = ({ Resources, settings }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const {newReportAvailable, selectedList, filterItemsCount, categories, resourceList} = Resources;
    return { Resources, screenThemeClass, popupThemeClass, newReportAvailable, selectedList, filterItemsCount, categories, resourceList};
}

export default connect(mapStateToProps, { getResourceList, getcategories, getKeywords, setResorcesSelectedList, clearResourcesData, setPostOpenHistory, setResourcesFilterItemsCount })
    (withStyles(styles, { withTheme: true })(UserResourcesPage));
