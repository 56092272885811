/**
* My Courses Actions
*/

import { axiosClient } from '../sherpaApi'
import { NotificationManager } from 'react-notifications';
import moment from 'moment';


import {
    GET_NOTICES_LIST,
    GET_NOTICES_LIST_SUCCESS,
    GET_NOTICES_LIST_FAILED,
    HANDLE_NOTICE_PANEL,
    FETCH_NOTICE_FORM,
    FETCH_NOTICE_FORM_SUCESS,
    FETCH_NOTICE_FORM_FAILED,
    CLEAR_NOTICES_DATA,
    FETCH_NOTICE_HISTORY,
    FETCH_NOTICE_HISTORY_SUCCESS,
    FETCH_NOTICE_HISTORY_FAILED,
    SUBMIT_NOTICE,
    SUBMIT_NOTICE_SUCCESS,
    SUBMIT_NOTICE_FAILED,
    GET_NOTICE_POST,
    GET_NOTICE_POST_SUCCESS,
    GET_NOTICE_POST_FAILED,
    SET_SELECTED_NOTICE
} from './types';


export const getNoticesList = (userId, type) => (dispatch) => {
    dispatch({ type: GET_NOTICES_LIST });
    let orgId = localStorage.getItem('selectedOrgId')
    const idToken = localStorage.getItem('idToken');
    let url = type ? `/notices?type=${type}&organization=${orgId}` : `/notices?organization=${orgId}`;
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("Fetched notices : ", response.data);
        dispatch({type:GET_NOTICES_LIST_SUCCESS, payload: response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("error occured while fetching notices : ", errorMsg);
        dispatch({ type: GET_NOTICES_LIST_FAILED}); 
        NotificationManager.error(errorMsg, "Failed to get notices");
        
    })
}

export const handleNoticesPanel = (show, userId) => (dispatch) => {
    dispatch({ type: HANDLE_NOTICE_PANEL, payload: { userId, show } });
}

export const setSelectedNotice = (notice) => (dispatch) => {
    dispatch({ type: SET_SELECTED_NOTICE, payload: notice });
}



export const getNoticeForm = (userId, formId) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/forms/${formId}?organization=${orgId}` ///users/{userId}/forms/{formId}
    dispatch({type: FETCH_NOTICE_FORM})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched poke form data for fromId:"+formId,"color:green");
        console.log("%c Poke form opened","color:green");
        const pokeFormData = response.data;
     
        if(pokeFormData && pokeFormData.sections) {
            const sections = pokeFormData.sections;
            let sortedSectionsKey = Object.keys(sections).sort((a, b) => sections[a].position - sections[b].position);
            const sortedSections = {};
            sortedSectionsKey.map((key, index) => {
              sortedSections[key] = sections[key];
            });
            pokeFormData.sections = sortedSections;
        }
        dispatch({ type: FETCH_NOTICE_FORM_SUCESS, payload: pokeFormData });
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching poke form data ","color:red", errorMsg);
        NotificationManager.error(errorMsg);
        dispatch({ type: FETCH_NOTICE_FORM_FAILED });
     })
 };

 
 export const clearNoticesData = () => (dispatch) => {
    dispatch({ type: CLEAR_NOTICES_DATA});
}


export const getNoticeHistory = (userId, type) => (dispatch) => {
   
    let orgId = localStorage.getItem('selectedOrgId');
    dispatch({ type: FETCH_NOTICE_HISTORY });
    const idToken = localStorage.getItem('idToken');
    const auth_uid = localStorage.getItem('auth_uid');
    let url = type ? `/notices/${userId}/posts?type=${type}&organization=${orgId}` : `/notices/${userId}/posts?organization=${orgId}`;
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("Fetched notices posts : ", response.data);
       
        const list = response.data ? response.data.map(post => {
                const {Members = {}} = post;
                if(Members[auth_uid]) {
                    return {...post, openedByAdmin: !!Members[auth_uid].viewDate}
                } else {
                    return post;
                }
        }) : [];
        const readList = list.filter(post => post.openedByAdmin !== false);
        const unreadList = list.filter(post => post.openedByAdmin === false);
        const postsList = [...unreadList, ...readList];
        dispatch({type: FETCH_NOTICE_HISTORY_SUCCESS, payload: postsList})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("error occured while fetching notices posts : ", errorMsg);
        NotificationManager.error(errorMsg, "Failed to get client alerts");
        dispatch({ type: FETCH_NOTICE_HISTORY_FAILED}); 
    })
}

export const getNoticePostById = ({userId, postId, categoryId, orgId}) => (dispatch) => {
    dispatch({ type: GET_NOTICE_POST });
    const idToken = localStorage.getItem('idToken');
    let url = `/bulletin/post/${postId}?categoryId=${categoryId}&organization=${orgId}&userId=${userId}`;
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("Fetched notice post by Id : ", response.data);
        const {Detail = {}, Members = {}} = response.data || {};
        const membersList = [];
        const totalMembersCount = Object.keys(Members).length;
        let readMembersCount = 0;
        for(const userId in Members) {
             const member = Members[userId];
             member.userId = userId;
             membersList.push(member);
             if(member.viewDate) {
                readMembersCount++;
             }
        }
        membersList.sort((p1, p2) => p1.viewDate ? -1 : 1);
        const post = {...Detail, totalMembersCount, readMembersCount, members: membersList};
        dispatch({type:GET_NOTICE_POST_SUCCESS, payload: post});
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("error occured while fetching notice post by Id : ", errorMsg);
        NotificationManager.error(errorMsg, "Failed to get post");
        dispatch({ type: GET_NOTICE_POST_FAILED}); 
    })
}

export const submitNotice = ({userId, noticeId, formId, formData}, callback) => (dispatch) =>{
    let bodyData = {
        formResponse : {
            "description": formData.description || "",
            "formName": formData.formName || "",
            "formID": formId,
            "userId": userId,
            "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z"),
            "sections": formData.sections,
        }  
    };
    const orgId = localStorage.getItem("selectedOrgId")
    let idToken = localStorage.getItem('idToken');
    let url = `/notices/${noticeId}/submit?organization=${orgId}`;
    dispatch({type: SUBMIT_NOTICE})
    axiosClient.post(url, bodyData, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("Notice submmitted : ", response.data);
        dispatch({ type: SUBMIT_NOTICE_SUCCESS, payload: response.data });
        callback(response.data, null);
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while saving notice form data ","color:red", errorMsg);
        dispatch({ type: SUBMIT_NOTICE_FAILED });
        NotificationManager.error(errorMsg, "Failed to submit notice");
    })
 };

 
