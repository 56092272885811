/**
 * User List Item
 */
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';


const OverallEvaluation = ({ obj, handleUp, handleDown, section, selectedThumb, sectionObj, showError}) => {
    const [value, setValue] = useState(true);
    return (
        <div data-testid="over-all-evaluation-component-thumbs" key={section}>
            <div className="text-center pb-2 pt-2 theme-background thumb-icon">
                {obj.heading || obj.text ? <p className="mb-1 form-label">{`${obj.heading || obj.text || ""}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></p>:  <Divider className="my-2" />}
                <IconButton data-testid="evaluation-thumb-up-button" onClick={() => handleUp(obj, sectionObj)} className={"text-white mr-3 " + (obj.value && obj.value == "up" ? "bg-success" : "bg-secondary")}>
                    <ThumbUpAltRoundedIcon /></IconButton>
                <IconButton data-testid="evaluation-thumb-down-button" onClick={() => handleDown(obj, sectionObj)} className={" text-white " + (obj.value && obj.value == "down" ? "bg-danger" : "bg-secondary")}>
                    <ThumbDownRoundedIcon /></IconButton>
                {showError && obj.isMandatory && !obj.value && <div className="input-options-error">{`${obj.heading || obj.text || ""} section is required`}</div>}
            </div>
         {/*  <Divider className="mb-2"/>*/}
        </div>
    );
}

export default OverallEvaluation;
