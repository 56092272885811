import React, { Component } from 'react';
import List from '@mui/material/List';
import { connect } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import Avatar from '@mui/material/Avatar';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import moment from 'moment'
import LifeSherpaLoading from '../../LifeSherpaLoading';

// actions
import { setSelectedUnConfirmActivity,getUnConfirmtActivitiesList,getUnConfirmActivityDetail } from '../../../actions';

class UnConfirmList extends Component {

	handleOpenEvaluationForm(achievement){
		this.props.handleOpenForm()
		this.props.setSelectedUnConfirmActivity(achievement)
		this.props.getUnConfirmActivityDetail(this.props.selectedMemberId, achievement.id, 'evaluationForm')
	}

	handleOpenStepNotesForm(achievement){
		this.props.handleOpenForm()
		this.props.setSelectedUnConfirmActivity(achievement)
		this.props.getUnConfirmActivityDetail(this.props.selectedMemberId, achievement.id, 'stepNotesForm')
	}

	/**
	 * Swicth Chat With User
	 * @param {*object} user
	 */
	switchChatWithUser(achievement) {
        this.props.handleClose()
		this.props.setSelectedUnConfirmActivity(achievement)
		this.props.getUnConfirmActivityDetail(this.props.selectedMemberId, achievement.id)
	}

	render() {
		const { allUnConfirmActivities, loading, selectedUnConfirmAchievemntId, unConfirmActivityDetail, selectedUnConfirmActivity} = this.props;
        if (allUnConfirmActivities === null) {
			return (
				<div className="no-found-user-wrap">
                    <h4>No data Found</h4>
                   
				</div>
			);
		}
		return (
            <div>
                <LifeSherpaLoading loading={loading} />
                <List className="p-0 mb-0">
                    {allUnConfirmActivities && allUnConfirmActivities.map((activity, key) => (
                        <ListItem className="user-list-item border-bottom"  key={key}>
                            <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="media align-items-center w-90" onClick={() => this.switchChatWithUser(activity)}>
                                    <div className="media-left position-relative mr-10">
                                        <Avatar 
                                            width="40" 
                                            height="40" 
                                            className="align-self-center" 
                                            style={activity.status === 'confirmed' ? 
                                            {backgroundColor: 'green'} 
                                            : activity.status === 'cancelled' || activity.status === 'incomplete' || activity.status === 'expired' || activity.status === 'confirmedNegative' ?
                                            {backgroundColor: 'red'}
                                            : activity.status === 'inProgress' || activity.status === 'notStarted' || activity.status === 'paused' ?
                                            {backgroundColor: 'blue'}
                                            : activity.status === 'confirmedSkippedSteps' ?
                                            {backgroundColor: 'yellow'}
                                            : activity.status === 'completedConfirmationNeeded' ?
                                            {backgroundColor: 'orange'}
                                            :
                                            {backgroundColor: '#bdbdbd'}
                                            }
                                            >
                                            {activity.status === 'confirmed' || activity.status === 'confirmedSkippedSteps' || activity.status === 'confirmedNegative' || activity.status === 'completedNoConfirmationNeeded' ? 
                                                <DoneRoundedIcon/>
                                                : activity.status === 'cancelled' || activity.status === 'incomplete' ?
                                                    <ClearRoundedIcon />
                                                : activity.status === 'inProgress' || activity.status === 'paused' ?
                                                    <MoreHorizRoundedIcon />
                                                : activity.status === 'expired' || activity.status === 'notStarted' ?
                                                    <AccessAlarmRoundedIcon />
                                                : activity.status === 'completedSkippedStepsNoConfirmationNeeded' ?
                                                    'S'
                                                : activity.status === 'completedConfirmationNeeded' ?
                                                    '?'
                                                : 
                                                    'A'
                                            }
                                        </Avatar>
                                    </div>
                                    <div className="media-body pt-5">
                                        <h5 className="mb-0">{activity.title}</h5>
                                        <span className="font-xs d-block">{moment(activity.date).format('LLL')}</span>
                                    </div>
                                </div>
                                <div className="text-right msg-count d-flex align-items-center flex-column">
                                    <IconButton title="Evaluation Form"  onClick={() => this.handleOpenEvaluationForm(activity)} variant="contained" size="small" color="primary">
                                        <AssignmentRoundedIcon style={activity.hasEvaluationForm && activity.hasEvaluationResponse ? {color: 'orange'} : {color: '#A5A7B2'}}/>
                                    </IconButton>
                                    <IconButton title="StepNotes Form" onClick={() =>this.handleOpenStepNotesForm(activity)} variant="contained" size="small" color="primary">
                                        <CommentIcon style={activity.hasStepNotesForm && activity.hasStepNotesResponse ? {color: 'orange'} : {color: '#A5A7B2'}}/>
                                    </IconButton>
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </div>
		);
	}
}

const mapStateToProps = ({ unConfirmAchievements }) => {
	const {allUnConfirmActivities, loading, selectedUnConfirmAchievemntId, unConfirmActivityDetail, selectedUnConfirmActivity} = unConfirmAchievements
	return {allUnConfirmActivities, loading, selectedUnConfirmAchievemntId, unConfirmActivityDetail, selectedUnConfirmActivity};
};

export default connect(mapStateToProps, {
	setSelectedUnConfirmActivity,
    getUnConfirmtActivitiesList,
    getUnConfirmActivityDetail,
})(UnConfirmList);
