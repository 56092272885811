import React, { Component } from 'react';
import { connect } from 'react-redux';
import {handleClosePostViewer, getResourceList,getcategories,getKeywords} from '../../actions'
import PostView from "../Widgets/PostView";
class BulletinPostNotification extends Component {
    state = {}
    closePostViewer = () => {
        //const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
        this.props.handleClosePostViewer();
        this.props.getResourceList('','new', '')
        this.props.getcategories()
        this.props.getKeywords()
    }
    render() { 
        const {showPostViewer, selectedPost} = this.props;
        return ( 
            <div>
                 {showPostViewer && selectedPost &&
                    <PostView
                      open={showPostViewer}
                      post={selectedPost}
                      handleClose={this.closePostViewer}
                    />
                } 
            </div>
            
        );
    }
}
const mapStateToProps = ({Resources}) => {
    const {showPostViewer, selectedPost, postLoading} = Resources;
    return {showPostViewer, selectedPost, postLoading};
}

export default connect(mapStateToProps,{handleClosePostViewer, getResourceList, getcategories, getKeywords})(BulletinPostNotification);
