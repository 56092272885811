/**
 * User Profile Page
 */
 import React, { Component } from 'react';
 import UnConfirmList from './component/UnConfirmList'
 import UnConfirmDetail from './component/UnConfirmDetail'
 import { connect } from 'react-redux';
 import UnconfirmForm from './component/UnconfirmForm'
 import LifeSherpaLoading from '../LifeSherpaLoading';
 import InfiniteScroll from "react-infinite-scroll-component";
 
 // actions
 import { setSelectedUnConfirmActivity,getPokeForm,getUpdatedEvaluationForm, setSelectedStep,postStepsNotesForm, postEvaluationForm, getUnConfirmtActivitiesList } from '../../actions';
 
  class UnConifrmChievements extends Component {
 
     constructor(props){
         super(props)
         this.interval = null
         this.state = {
             isDetail: false,
             open: false,
         }
     }
 
     // componentDidMount() {
     //     this.props.getUnConfirmtActivitiesList(this.props.selectedMemberId);
     // }
 
 handleloadmore = () => {
    this.props.getUnConfirmtActivitiesList(this.props.selectedMemberId, this.props.pageCount)
 }
 componentWillUnmount(){
     this.interval = null
 }
 
 handleOpenForm(activity){
     this.setState({open: !this.state.open})
 }
 
 handleClose(){
     this.setState({isDetail: !this.state.isDetail})
 }
 
    render() {
        return (
            <div>
                 {this.state.open ? 
                     (
                         <UnconfirmForm
                             handleClose={this.handleOpenForm.bind(this)}
                             loading={false}//this.props.pokeFormLoading || this.props.loading}
                             pokeForm={this.props.pokeForm}
                             selectedMemberId={this.props.selectedMemberId}
                             postStepsNotesForm={this.props.postStepsNotesForm}
                             postEvaluationForm={this.props.postEvaluationForm}
                             selectedUnConfirmAchievemntId={this.props.selectedUnConfirmAchievemntId}
                             selectedUnConfirmActivity={this.props.selectedUnConfirmActivity}
                             selectedStepId={this.props.selectedStepId}
 
                         />
                     )
                     : this.state.isDetail ? 
                     (
                         <UnConfirmDetail
                             achievementDetail={this.props.unConfirmActivityDetail}
                             selectedMemberId={this.props.selectedMemberId}
                             getPokeForm={this.props.getPokeForm}
                             loading={this.props.loading}
                             getUpdatedEvaluationForm={this.props.getUpdatedEvaluationForm}
                             selectedUnConfirmAchievemntId={this.props.selectedUnConfirmAchievemntId}
                             handleOpenForm={this.handleOpenForm.bind(this)}
                             handleClose={this.handleClose.bind(this)}
                             setSelectedStep={this.props.setSelectedStep}
                         />
                     )
                     :
                     (<InfiniteScroll
                         dataLength={this.props.allUnConfirmActivities && this.props.allUnConfirmActivities.length}
                         next={()=>this.handleloadmore()}
                         hasMore={this.props.hasMoreData}
                         loader={<div className="text-center"><LifeSherpaLoading loading={true}/></div>}
                         scrollableTarget='scrollable'
                         style={{overflow:"none"}}
                         >                   
                         <UnConfirmList 
                             handleClose={this.handleClose.bind(this)}
                             selectedMemberId={this.props.selectedMemberId}
                             handleOpenForm={this.handleOpenForm.bind(this)}
                             />
                      </InfiniteScroll>   
                     )
 
                 }
            </div>
        )
    }
 }
 
 // export default UnConifrmChievements;
 const mapStateToProps = ({ unConfirmAchievements, chatAppReducer, PokeForm, activitiesHistoryList }) => {
     const { selectedStepId} = activitiesHistoryList
     const {loading, allUnConfirmActivities, selectedUnConfirmAchievemntId, unConfirmActivityDetail, selectedUnConfirmActivity, hasMoreData, pageCount} = unConfirmAchievements
     const {selectedMemberId, displayName, profileImage} = chatAppReducer
     let pokeFormLoading  = PokeForm.loading
     let pokeForm  = PokeForm.pokeForm
     return {loading, allUnConfirmActivities, selectedUnConfirmAchievemntId, unConfirmActivityDetail, displayName, profileImage, pokeFormLoading, pokeForm, selectedUnConfirmActivity, selectedStepId, hasMoreData, pageCount};
 };
 
 export default connect(mapStateToProps, {
     setSelectedUnConfirmActivity,
     getPokeForm,
     getUpdatedEvaluationForm,
     setSelectedStep,
     postStepsNotesForm,
     postEvaluationForm,
     getUnConfirmtActivitiesList,
 })(UnConifrmChievements);
 