/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import HeaderComponent from '../Header/HeaderComponent'
import config from '../../config/config'

class SuccessScreen extends Component {

   render() {
      const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 

      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper">
               <AppBar position="static" className="session-header">
                     <Toolbar>
                        <div className="container">
                           <div className="d-flex justify-content-between">
                              <div className="session-logo">
                                 <Link to="/">
                                    <img src={applogo} alt="session-logo" className="img-fluid" width="220" height="80" />
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </Toolbar>
                  </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center text-white">
                              <div className="session-head mb-30">
                                 <p>Loading...</p>
                                </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

export default SuccessScreen;

