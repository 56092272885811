/**
 * Mail App
 */
import React from "react";

import { connect } from 'react-redux';
import LifeSherpaLoading from '../LifeSherpaLoading';
// actions
import { 
    getActivitiesListN, 
    getActivitiesList, 
    getRealtimeClienActivity, 
    getEmails, getPokeForm, 
    handleListStyle, 
    postStepsNotesForm, 
    removeFilteredActivitiesList,
    getFilteredActivitiesList,
    setClientActivitiesType,
    getactivitiesCalendar,
    getActivityGroupsList
 } from '../../actions';

// components
import UserAllActivitiesList from './UserAllActivitiesList';
import "../../assets/scss/custom/app.scss"
import { Scrollbars } from 'react-custom-scrollbars';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import {IconButton, Button} from '@mui/material/';
import ActivitiesFilterPopup from "../Widgets/ActivitiesFilterPopup";
import PeopleIcon from '../SVG/PeopleIcon'
import ViewAgendaOutlinedIcon from '@mui/icons-material//ViewAgendaOutlined';
import CalendarTodayIcon from '@mui/icons-material//CalendarToday';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddActivity from "./AddActivity";

const drawerWidth = 280;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 'auto',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: 380,
        overflowY: 'scroll'
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        backgroundColor: 'transparent',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});

class UsersAllActivities extends React.Component {

    state = {
        mobileOpen: false,
        open: false,
        selectedRoutine: null,
        select: "activity",
        search: '',
        addDilogBox: false,
        showFilter: false,
        filter: '',
        activityFilter: [],
        listStyle: 'normal',
        update: '',
        loading: true,
        filterOptions: [],
        showFilter: false,
        applyedFilterOptions: [],
        applyFiltersOnActivities: false,
        clientActivitiesType: 'Normal' ,
        openEditor: false,
        editorType: "general",
        activityId: null
    };

    componentDidMount() {
        console.log("%c[UserActivities]%c Rendered user activities panel","color:black","color:green");
        this.clearFilter();
    }
    clearFilter = () => {
        this.props.removeFilteredActivitiesList();
        this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: false  });
    }

    getFilteredActivityGroups(){
        const { filterOptions } = this.state;
        const {activityGroups} = this.props
        let filteredActivityGroups = [];
        if (filterOptions && filterOptions.length > 0) {
            activityGroups.map(ag => {
                if (filterOptions.includes(ag.id)) {
                    filteredActivityGroups.push({activityGroupId: ag.id, organization: ag.organization})
                }
            })
        } 
        return filteredActivityGroups
    }

    applyFilter = (inActiveActivities) => {
        let { filterOptions } = this.state;
        const {clientActivitiesType} = this.props;
        const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null
        const uid = localStorage.getItem('uid');
        if(filterOptions.length > 0) {
            const filtersOptions = this.getFilteredActivityGroups()//filterOptions.join(",");
            this.props.getFilteredActivitiesList(uid, filtersOptions, activityType);
            this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: true, applyedFilterOptions: [...filterOptions], showInactiveActivities: inActiveActivities});
        } else {
            this.props.removeFilteredActivitiesList();
            this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: inActiveActivities });
        }
    }

    handleCloseFilter = () => {
        this.setState({ ...this.state, showFilter: false });
    }

    handelShowFilter = () => {
        let { applyedFilterOptions } = this.state;
        this.setState({ ...this.state, showFilter: true, filterOptions: [...applyedFilterOptions] });
    }

    getFilterActivitiesList = (list) => {
        const {filteredActivities} = this.props;
        let filteredList = []
        filteredList = list && list.length > 0 ?  list.filter(activity => filteredActivities.findIndex(routine => routine.id === activity.id) >= 0) : [];
        return filteredList;
    }

    handelFilterOpitonsChange = (id) => {
        let tempState = { ...this.state };
        let index = tempState.filterOptions.findIndex(groupId => groupId === id);
        if (index >= 0) {
            tempState.filterOptions.splice(index, 1);
        } else {
            tempState.filterOptions.push(id);
        }
        console.log("tempState.filterOptions: ", tempState.filterOptions)
        this.setState(tempState);
    }

    setStateUpdate = () => {
        this.setState({ ...this.state, update: "" });
    }
    handleClose(activity) {
        this.setState({ selectedRoutine: activity ? activity.title : '' })
        this.props.handleOpenForm(activity)
        this.props.getPokeForm()
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    openEditor = (value) => {
        this.props.openEditor(value);
        this.setState({ addDilogBox: false })
    }
    handelSearch = (e) => {
        console.log(e);
        this.setState({...this.state,search:e.target.value});
    }
    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }
    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh)';
        } else {
            return '465px';
        }
    }
    getFilteredList = (list) => {
        let {applyedFilterOptions } = this.state;
        let filteredlist = [];
        if(list) {
            filteredlist =  list.filter((activity) => applyedFilterOptions.find(gid => {
                if(activity.ActivityGroups) {
                    return Object.keys(activity.ActivityGroups).findIndex(activityGroupId => activityGroupId == gid && activity.ActivityGroups[activityGroupId]) >= 0
                } else {
                    return false;
                }
            }))
        }
        return filteredlist;
    }

    handleNormalActivity(){
        this.clearFilter()
        const {selectedMember} = this.props;
        const {organization: orgId, groupId} = selectedMember || {};
        const userId = localStorage.getItem('uid');
        this.props.setClientActivitiesType('Normal');
        this.props.getActivityGroupsList(userId, [orgId], null);
        this.props.getActivitiesListN({userId, orgId, groupId});
    }

    handleEncounterActivity(){
        this.clearFilter()
        const {selectedMember} = this.props;
        const {organization: orgId, groupId} = selectedMember || {};
        const userId = localStorage.getItem('uid');
        this.props.setClientActivitiesType("Touchpoint");
        this.props.getActivityGroupsList(userId, [orgId], "Touchpoint");
        this.props.getActivitiesListN({userId, orgId, groupId, activityType: "Touchpoint"});
    }

    handleSummayActivity(){
        this.clearFilter()
        const {selectedMember} = this.props;
        const {organization: orgId, groupId} = selectedMember || {};
        const userId = localStorage.getItem('uid');
        this.props.setClientActivitiesType('Summary');
        this.props.getactivitiesCalendar(userId);
        this.props.getActivitiesListN({userId, orgId, groupId});
    }

    handleOpenObjectEditor = (type) => {
		console.log("add icon clicked -->", type)
		this.setState({openEditor: true, activityId: null, editorType: type})
	}

	handleCloseObjectEditor = () => {
        const {selectedMember, clientActivitiesType} = this.props;
        const {organization: orgId, groupId} = selectedMember || {};
        const userId = localStorage.getItem('uid');
		this.setState({openEditor: false, activityId: null, editorType: null}, () => {
            const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
            this.props.getActivitiesListN({userId, orgId, groupId, activityType});
        })
	}

    render() {
        const { showConfirmation,loading ,userActivities, activityGroupsLoading, activityGroups, filteredActivities, clientActivitiesType, realtimeUpdate, allowCreateGeneralActivity, summaryLoading} = this.props;
        let {search, showFilter, applyedFilterOptions, filterOptions, applyFiltersOnActivities, editorType, openEditor, activityId} = this.state;
        let list = userActivities;
        if(filteredActivities && filteredActivities.length > 0) {
            list = this.getFilterActivitiesList(list)
         }
        let newlist = search && search.length > 2 && list && list.length > 0 ? list.filter((li) => (li.title && li.title.toLowerCase().includes(search.toLowerCase())) || (li.detailedDescription && li.detailedDescription.toLowerCase().includes(search.toLowerCase()))) : list;
        //sort activity list
        newlist && newlist.sort((a, b)=> a.state === 'paused' || a.state === 'inProgress' ? -1 : b.state === 'paused' || b.state === 'inProgress' ? 1 : 0 /* a.workflowId ? -1 : b.workflowId ? 1  : a.scheduleKey ? -1 : b.scheduleKey ? 1 : 0*/)

        if (this.props.showRewards) select = 'rewards'
        let organization = localStorage.getItem('orgId');

        return (
            <div>
                <div className="rct-mail-wrapper" data-testid="details-panel-client-all-activities">
                    <div className={styles.root}>
                        <LifeSherpaLoading loading={loading || summaryLoading} />
                                <div className="d-flex align-items-center justify-content-end w-100 p-3">
                                    <div className="d-flex align-items-center justify-content-center ls-btn-tab-container">
                                        <div className={clientActivitiesType === "Normal" ? 'selected-btn' : 'unselected-btn'} data-testid="common-activity-tab">
                                            <Button title="Common Activities" onClick={()=> this.handleNormalActivity()}> 
                                                <ViewAgendaOutlinedIcon style={{width: "26.5px", height: "26.5px"}} />
                                            </Button>
                                        </div>
                                        <div className={clientActivitiesType === "Summary" ? 'selected-btn' : 'unselected-btn'} data-testid="summary-activity-tab">
                                            <Button title="Activities Summary" onClick={()=> this.handleSummayActivity()}> 
                                                <CalendarTodayIcon style={{width: "26.5px", height: "26.5px"}}/>
                                            </Button>
                                        </div>
                                        <div className={clientActivitiesType === "Touchpoint" ? 'selected-btn' : 'unselected-btn'} data-testid="touchpoint-activity-tab">
                                            <Button title="Touchpoints" onClick={()=> this.handleEncounterActivity()}>
                                                <PeopleIcon width="26.5px" height="26.5px" className="people-left-right-icon"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex p-3 list-card align-items-center">
                                    <div className={`d-flex ${applyFiltersOnActivities ? "activity-filter-icon" : "activity-filter-icon-applied"}`}>
                                        <div>
                                            <IconButton disabled={clientActivitiesType === "Summary"}  title="Filter By Activity Group" className="text-white rounded-circle mr-2" style={{background:'#008C95'}} onClick={()=>this.handelShowFilter()}>
                                                {(clientActivitiesType === "Summary") ? <FilterAltOffIcon className="filter-icon" fontSize="large"/> : <FilterAltIcon className="filter-icon" fontSize="large"/>}
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="mx-2 search-activity-field">
                                        <form noValidate autoComplete="off">
                                            <TextField id="standard-search" data-testid={clientActivitiesType === "Summary" ? "my-activity-search-text-field-disabled" : "my-activity-search-text-field"}
                                                onChange={(e) => this.handelSearch(e)}
                                                label="Search Activity" 
                                                type="search" 
                                                value={search} 
                                                disabled={clientActivitiesType === "Summary"}
                                                InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary"/>
                                                </InputAdornment>
                                                ),
                                                endAdornment: ( search && search.length > 0 ?
                                                    <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                                    </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                                )
                                                }}
                                                variant="standard"
                                            />
                                        </form>
                                     </div>
                                     {/* <div className="activity-filter-icon-applied">
                                        <IconButton title="Create exclusive activity" className="text-white rounded-circle" onClick={()=>this.handleOpenEditor()}>
                                           <AddRoundedIcon className="filter-icon" fontSize="large"/>
                                        </IconButton>
                                    </div> */}
                                </div>
                                {showFilter &&
                                    <ActivitiesFilterPopup
                                        activityGroups={activityGroups}
                                        showFilter={showFilter}
                                        filterOptions={this.state.filterOptions}
                                        clearFilter={this.clearFilter}
                                        applyFilter={this.applyFilter}
                                        handleCloseFilter={this.handleCloseFilter}
                                        handelFilterOpitonsChange={this.handelFilterOpitonsChange}
                                    />
                                }
                         <Scrollbars
                                 className="rct-scroll list-sherpa-activity"
                                autoHide
                                style={{ height: this.getScrollHeight() }}
                                 >
                         
                        {newlist && newlist.length > 0 && organization ?
                            <>
                                <UserAllActivitiesList
                                    listStyle={this.props.listStyle}
                                    selected={this.props.selected}
                                    handleClose={this.handleClose.bind(this)}
                                    allActivities={newlist}
                                    selectedUser={this.props.selectedUser}
                                    selectedMember={this.props.selectedMember}
                                    selectedMemberId={this.props.selectedMemberId}
                                    selectedGroup={this.props.selectedGroup}
                                    previewId={(e) => this.props.previewId(e)}
                                    showDetails={(e) => this.props.showDetails(e)}
                                    EditActivity={(e) => this.props.EditActivity(e)}
                                    handleObjectEditor={this.props.handleObjectEditor}
                                    handleStartActivity={(e) => this.props.handleStartActivity(e)}
                                    isEdit={true}
                                    showAddButton={() => this.props.showAddButton(false)}
                                    setStateUpdate={this.setStateUpdate}
                                    selectedClient={this.props.selectedClient}
                                    clientActivitiesType={clientActivitiesType}
                                    />
                            </>
                            :
                            <div className="d-flex justify-content-center align-items-center py-50 no-data-found" data-testid="activities-data-not-found">
                                <h4>No data available</h4>
                            </div>
                        }

                        </Scrollbars>
                    </div>
                    {allowCreateGeneralActivity ? 
                        <AddActivity
                            handleOpenObjectEditor={this.handleOpenObjectEditor}
                            handleCloseObjectEditor={this.handleCloseObjectEditor}
                            openEditor={openEditor}
                            editorType={editorType}
                            activityId={activityId} 
                            scope={"general"} 
                        />
                        : <></>
                    }
                </div>
            </div>
        )
    }
}

// map state to props
const mapStateToProps = ({ emailApp, chatAppReducer, activitiesList, PokeForm, activitiesHistoryList, GroupList, ActivityReducer, authUser }) => {
    const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
    const { selectedUser, displayName, profileImage, selectedMemberId, selectedRole, selectedMember } = chatAppReducer;
    const { allActivities, privateActivities, loading, activityFilter, listStyle ,userActivities, activityGroupsLoading, activityGroups, filteredActivities, clientActivitiesType = "Normal", summaryLoading, realtimeUpdate, allowCreateGeneralActivity} = activitiesList;
    const { selected } = ActivityReducer
    const { user } = authUser;
    const { selectedActivitiesHistory, loadingDetail, selectedStepId } = activitiesHistoryList
    const { pokeForm, isPokeForGroup } = PokeForm
    let pokeFormLoading = PokeForm.loading
    const { selectedGroup, groups } = GroupList

    return {realtimeUpdate, userActivities, currentEmail, user, selectedRole, sendingEmail, listStyle, showActivityHistory, selected, activityFilter, selectedMemberId, showConfirmation, selectedUser, allActivities, privateActivities, loading, pokeFormLoading, pokeForm, displayName, profileImage, selectedActivitiesHistory, loadingDetail, selectedStepId, selectedGroup, isPokeForGroup, groups, activityGroupsLoading, activityGroups,filteredActivities , clientActivitiesType, summaryLoading, allowCreateGeneralActivity, selectedMember };
}

export default connect(mapStateToProps, {
    getEmails,
    getPokeForm,
    postStepsNotesForm,
    getActivitiesList, handleListStyle,
    getRealtimeClienActivity,
    getActivitiesListN,
    removeFilteredActivitiesList,
    getFilteredActivitiesList,
    setClientActivitiesType,
    getactivitiesCalendar,
    getActivityGroupsList
})(UsersAllActivities);