import React, { Component } from "react";
import LifeSherpaLoading from "../LifeSherpaLoading";
import { connect } from 'react-redux';
import Iframe from "./iframe.js";

class AssingActivitiesIFrame extends Component {
    state = {
        loading: true,
    }
   
    hideSpinner = (e) => {
       // console.log("onloading --> ",e);
       // console.log("get element by id--> ",document.getElementById("assignActivityIFrame"));
        this.setState({ ...this.state, loading: false });
        console.log("%c Assign activity app loaded successfully............","color:green");
        // var iframe = document.getElementById("assignActivityIFrame");
        // var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    //    if(innerDoc.location) {
    //      var currentFrame = innerDoc.location.href;
    //      var pathName = innerDoc.location.pathname;
    //      if(pathName == "/success") {
    //         this.setState({ ...this.state, loading: true });
    //          this.props.handleCloseAssignActivity();
    //      }
    //    } 

    window.clsAssignRef = this

    window.onmessage = function(e) {
        if (e.data == 'close_iframe') {
            window.clsAssignRef.setState({ ...this.state, loading: true });
            window.clsAssignRef.props.handleCloseAssignActivity();
        }
    };
       
    }

    render() {
        let { assignActivityUrl } = this.props;
        let { loading } = this.state;
       // console.log(loading, assignActivityUrl);
        return (
            <React.Fragment>

                 <LifeSherpaLoading loading={loading}/>
                <div style={{ visibility: loading ? "hidden" : "visible" }}>
                    <iframe
                        src={assignActivityUrl ? assignActivityUrl : ''}
                        onLoad={this.hideSpinner}
                        scrolling="yes"
                        id="assignActivityIFrame"
                        className="w-100 assign-activity-iframe"
                        display="initial"
                        position="relative"
                    ></iframe>
                </div>
            </React.Fragment>
        )
    }
}

export default AssingActivitiesIFrame;
