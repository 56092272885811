/**
 * Mail App
 */
 import React from "react";
 import { connect } from 'react-redux';
 import { withStyles } from '@mui/styles';

 // actions
 import {  
    selectActivityId,
    selectActivity,
    handlEditor,
    getActivitiesList,
    getRealtimeClienActivity, 
    getPokeForm,handleListStyle, 
    postStepsNotesForm,
} from '../../actions'
import EditorPopup from "../AppMyActivity/components/EditorPopup";

const drawerWidth = 400;
const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 'auto',
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		 
        width: 360, 
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative',
			width: drawerWidth,
		},
		backgroundColor: '#fff'
	},
	drawerHeight: {
		height: '100%',
	},
	content: {
		flexGrow: 1
	},
});

class SidebarEditorMain extends React.Component {
    state = {
    
    };

    render() {
        const { classes, theme } = this.props;
        let{selected,activityId,activityType}=this.props;
        selected = selected || 'start';

        return (
            <div className={classes.root}>
            <EditorPopup 
                selected={selected} 
                previewId={this.props.previewId}
                showDetails={this.props.showDetails} 
                activityType={activityType}
                activityId={activityId}
                handleClose1={()=>this.props.handleClose3()}
                handleClose={()=>this.props.handleClose2()}
                handleRewards={()=>this.props.handleRewards()} 
            />         
            </div>
        )
    }
}
 
 // map state to props
 const mapStateToProps = ({ ActivityReducer}) => {
    const {selected,open}=ActivityReducer   
    return { open,selected};
 }
 
 export default connect(mapStateToProps, {
     getPokeForm,
     postStepsNotesForm,
     getActivitiesList,handleListStyle,
     getRealtimeClienActivity,handlEditor,
     selectActivityId,selectActivity,
 })(withStyles(styles, { withTheme: true })(SidebarEditorMain));