/**
 * Email List Item
 */
import React from 'react';


const LandingPageItem = ({ activity, userId, itemKey }) => (
    <div data-testid={`my-clients-inprogess-activities${itemKey}`} style={{ marginLeft: "15px" }} className="mb-1 " key={itemKey + "user5ListItem"}>
        <div className=" row actvity-routine-simple">
            <div className="col-12">
                <div style={{ marginLeft: "5px" }} className="pl-3  pt-2 mb-1 my-auto" >
                    <h5 className="" style={{ marginBottom: "0px", fontWeight: "400" }}>{activity.title}</h5>
                    <div style={{ fontSize: "12px", fontWeight: "400" }} className="mb-0 mt-0  text-muted">{activity.detailedDescription}</div>
                </div>
            </div>
            <div style={{ paddingLeft: "0px" }} className={activity.state === "paused" || activity.state === "inProgress" || activity.state === undefined ? "col-12 pr-0" : "col-12 pr-0"}>
                {activity.state === "paused" || activity.state === "inProgress" || activity.state === undefined ?
                    <div className="d-flex w-100 align-items-center   mt-2 font-sm" style={{ paddingLeft: '5px', whiteSpace: 'nowrap' }}>
                       <div className="align-items-center"> <div
                            className={"rounded-circle   mr-1 " + (activity.state === "paused" || activity.state === undefined ? "activity-icon-progress" : "activity-icon-progressa")}>
                            {activity.state === "paused" || activity.state === undefined ? "P" : "A"}</div></div>
                        <div className="activity-step">{activity.nextStep ? activity.nextStep : 'Step : Start'}</div>
                    </div> : ''}
                {activity.state === "paused" || activity.state === "inProgress" || activity.state === undefined ?
                    <div style={{ marginLeft: "33px" }} className="border-bottom row mt-1 pr-2  pb-3">
                        <div style={{ width: ((230 / activity.count) * activity.completed) + 'px', height: 5 + 'px', borderRadius: 2 + 'px' }} className="activity-completed"></div>
                        <div style={{ width: ((230 / activity.count) * (activity.count - activity.completed)) + 'px', background: 'lightgray', height: 5 + 'px', borderBottomRightRadius: 2 + 'px', borderTopRightRadius: 2 + 'px' }}></div>
                    </div> : ''}
            </div>
        </div>
    </div>
);

export default LandingPageItem;



/*

(activity.state==="paused"|| activity.state==="inProgress")?{background:'#fff',border:'2px solid #008C95',marginTop:"-23px"}:(itemKey===editScheduleKey&&role==='admin')?{background:'rgb(214,234,243)',marginTop:"-23px"}:role==='admin'?{background:'#fff',marginTop:"-23px"}:{background:'#fff'}
*/
