/**
 * Support Request
 */
import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import LifeSherpaLoading from '../LifeSherpaLoading';
import { Scrollbars } from 'react-custom-scrollbars';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ActivitiesFilterPopup from './ActivitiesFilterPopup';
import { FilterAlt } from '@mui/icons-material';

import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

class ActivitySupportCourses extends Component {

   state = {
      openOrgFilter: false,
      search: ""
   }

   componentDidMount() {
      console.log("%c[ActivitySupportCourses]%c Rendered user all courses panel at home page","color:black","color:green");
   }

   getScrollHeight() {
      if (localStorage.getItem("widgetApp")) {
         return "calc(100vh - 106px)";
     } else {
         return '550px';
     }
   }

   handleOpenFilter(){
      const {openOrgFilter} = this.state
      this.setState({openOrgFilter: !openOrgFilter})
  }

  handelSearch = (e) => {
      // console.log(e);
      this.setState({...this.state, search:e.target.value});
   }

   handelClearSearch = () => {
      this.setState({...this.state,search:""});
   }

   render() {
      let { launchpad, myCourseLoading, myCourses, getCourseLaunchURL, filterApplied } = this.props;
      const {openOrgFilter, search} = this.state
      let filterCourses = null
      if (search && search.length > 2 && myCourses && myCourses.length > 0) {
         filterCourses = myCourses.filter((course) => (course.name && course.name.toLowerCase().includes(search.toLowerCase())))
      } else {
         filterCourses = myCourses
      }
     // console.log(myCourses);
      return (
         <div data-testid="client-courses-panel" className="support-widget-wrap">
            <div>
               <LifeSherpaLoading loading={myCourseLoading} />
            </div>
            <div className='d-flex p-3 list-card align-items-center justify-content-between'>
               <div className={`d-flex ${filterApplied ? "activity-filter-icon" : "activity-filter-icon-applied"} mr-2`}>
                  <IconButton data-testid="orgId-filter-icon-button" title="Filter By OrgId" className="text-white rounded-circle mr-2" onClick={() => this.handleOpenFilter()}>
                     <FilterAlt data-testid="orgId-filter-enabled" className="filter-icon" fontSize="large"/>
                  </IconButton>
               </div>
               <div className="mx-2 search-activity-field">
                  <form noValidate autoComplete="off">
                     <TextField data-testid="my-course-search-text-field" id="standard-search" 
                        onChange={(e) => this.handelSearch(e)}
                        label="Search Course" 
                        type="search" 
                        value={search} 
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                              <SearchIcon color="primary"/>
                        </InputAdornment>
                        ),
                        endAdornment: ( search && search.length > 0 ?
                              <InputAdornment position="end">
                              <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                              </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                        )
                        }}
                        variant="standard" 
                     />
                  </form>
               </div> 
            </div>
            <Scrollbars
               className="rct-scroll list-sherpa-activity"
               autoHide
               style={{ height: this.getScrollHeight() }}
               >
               <List className="list-unstyled p-0">
                  {filterCourses && filterCourses.length > 0 ? filterCourses.map((course, key) => (
                     <ListItem key={key} className={`${key % 2 === 0 ? "styleToCorseItem" : " defaultStyleToCorseItem"} px-15 py-0 d-flex justify-content-between align-content-center`}>
                        {/* <p className="mb-0 content-title">{course.name}</p>
                           <Badge color={course.completion_status_formatted === "Completed" ? "success" : course.completion_status_formatted === "Not started" ? "danger" : "warning"} className="px-4">{course.completion_status_formatted}</Badge>
                           <IconButton color="default" onClick={() => getCourseLaunchURL(course.id)}>
                                 <i className="ti-eye"></i>
                           </IconButton> */}
                        <div data-testid={`client-course${key}`} className="d-flex justify-content-between w-100 align-items-center pt-2 pb-2">
                           <div className="media align-items-center w-90">
                              <div className="media-left position-relative mr-10">
                                 <img src={course.avatar ? course.avatar : 'https://d3j0t7vrtr92dk.cloudfront.net/images/unknown_small.png'} className="img-fluid rounded-circle" alt="pic" width="40" height="40" />
                              </div>
                              <div className="media-body">
                                 <h4 className="mb-0">{course.name}</h4>
                              </div>
                           </div>
                           <div className="text-right msg-count d-flex align-items-center">
                              <Badge color={course.completion_status_formatted === "Completed" ? "success" : course.completion_status_formatted === "Not started" ? "danger" : "warning"} className="px-4 font-md">{course.completion_status_formatted}</Badge>
                              <IconButton data-testid={`open-course-icon-button${key}`} color="default" onClick={() => getCourseLaunchURL(course)}>
                                 <VisibilityRoundedIcon />
                              </IconButton>
                           </div>
                        </div>
                     </ListItem>
                  ))
                     :
                     <div className="d-flex justify-content-center align-content-center MuiListItem-gutters p-3"><p className="mb-0 content-title font-md no-data-found">There is no course available!</p></div>
                  }
               </List>
            </Scrollbars>
            {openOrgFilter ?
               <ActivitiesFilterPopup
                  title={"Organization Filter"}
                  showFilter={openOrgFilter}
                  handleCloseFilter={this.handleOpenFilter.bind(this)}
                  panel="courses"
               />
               : <></>
            }
         </div>
      );
   }
}

export default ActivitySupportCourses;
