/**
 * Material UI Alert Dialog
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import LifeSherpaLoading from '../LifeSherpaLoading';

import {
  savedPPolicies
} from "../../actions"

import AlertDialog from './components/AlertDialog';

class AlertComponent extends Component {

	constructor(props) {
		super(props)
		this.state = {
			open: true
		}
	}

	handleCloseAlert() {
    	this.props.history.push('/logout');
	}

  handleIAgree() {
    const userId = localStorage.getItem("signedInUseruid")
    const idToken = localStorage.getItem("signedInIdToken")
    let {userPrivacyPolicyData, user} = this.props
	user.uid = userId
    this.props.savedPPolicies(user, idToken, userPrivacyPolicyData.version, this.props.history)
  }

	render() {
		const {userPrivacyPolicyData, privacyPolicyLoading, screenThemeClass} = this.props
    	const url = userPrivacyPolicyData && userPrivacyPolicyData.url ? userPrivacyPolicyData.url : "";
		return (
			<div className="w-100 " data-testid="privacy-policy-alert">
				{ userPrivacyPolicyData && userPrivacyPolicyData.status &&
					<div className={screenThemeClass}>
						<LifeSherpaLoading loading={true} />
					</div>
				}
				<AlertDialog 
					open={this.state.open && !(userPrivacyPolicyData && userPrivacyPolicyData.status)}
					loading={privacyPolicyLoading}
          			handleIAgree={this.handleIAgree.bind(this)}
					handleCloseAlert={this.handleCloseAlert.bind(this)}
					link={url}
				/>
			</div>
		)
	}
}

const mapStateToProps = ({ authUser, settings }) => {
	const {userPrivacyPolicyData, privacyPolicyLoading, user} = authUser;
	const { screenThemeClass, popupThemeClass } = settings; 
	return { userPrivacyPolicyData, privacyPolicyLoading, user, screenThemeClass}
}

export default connect(mapStateToProps, {
  savedPPolicies
})(AlertComponent)
