import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generateTwilioTokenForContacts } from 'Actions';
import Avatar from '@mui/material/Avatar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge'
import { Label } from 'reactstrap';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

class ContactListItem extends Component {
    state = {
        selectedChat: {},
        selelcted: false,
        lastMessage: "",
        unReadCount: 0,
        messages: [],
        conversation: null,
    }
    componentDidMount = async () => {
        const testing = localStorage.getItem("testing");
        if (!testing) {
            this.handleChatChannelFetch();
        }
    }
    componentWillUnmount() {
        let { client } = this.props;
        let { conversation } = client;
        if (client && conversation) {
            conversation.off("messageAdded", this.handleMessageAdded);
        }
    }

    handleChatChannelFetch = () => {
        let { client } = this.props;
        let { userId, conversation } = client;
        // console.log(" channel -> ", channel);
        if (conversation) {
            this.setState({ ...this.state, conversation: conversation });
            this.handleChanelSelected(conversation, userId);
        } else {

        }
    }
    handleChanelSelected = async (conversationProxy, userId) => {
        if (conversationProxy._internalState.status !== "joined") {
            await conversationProxy.join();
        }
        conversationProxy.getMessages().then(messages => {
            console.log("got client conversation messages -->", messages);
            if (messages.items && messages.items.length > 0) {
                this.setState({ ...this.state, messages: messages.items })
            }
        }).catch(e => {
            console.log(" error occured while fetching messages", e);

        });

        conversationProxy.getUnreadMessagesCount().then(count => {
            console.log(" unread Messages count --->", count);
            if (count) this.setState({ ...this.state, unReadCount: count })
        }).catch(e => {
            console.log(" error occured while fetching unread messages count", e);

        });
        conversationProxy.on("messageAdded", this.handleMessageAdded);
    }
    handleMessageAdded = (message) => {
        const { messages, conversation } = this.state;
        let { openChat } = this.props;
        console.log(" messages added --->", message, conversation);
        if (openChat == false) {
            this.setState({ ...this.state, messages: messages && messages.length > 0 ? [...messages, message] : [message] });
            if (conversation) {
                conversation.getUnreadMessagesCount().then(count => {
                    console.log(" unread Messages --->", count);
                    this.setState({ ...this.state, unReadCount: count });
                }).catch(errer => {
                    console.log(" error occured while fetching unread messages count", errer);
                });
            }
        }
    };
    handleSelectChat = (value) => {
        let { messages, unReadCount } = this.state;
        value.messages = messages;
        this.props.handleSelectChat(value);
    }
    render() {
        let { client, keyIndex, organizations } = this.props;
        let { messages, unReadCount } = this.state;
        const lastMessage = messages && messages.length > 0 ? messages[messages.length - 1].body : "";
        // console.log(" lastMessage --->",lastMessage);
        let organizationName = ''
        if (organizations && client.assignedOrganizations) {
            for(const orgId in client.assignedOrganizations) {
                if(!client.assignedOrganizations[orgId]) {
                    continue;
                }
                const index = organizations.findIndex(org => org.id === orgId);
                if (index !== -1) {
                    organizationName = organizationName ? organizationName+", "+ organizations[index].name : organizations[index].name;
                }
            }
        }
        return (
            <React.Fragment>
                <li data-testid={`contact-list-item-${keyIndex}`} className="contacts-list-item list-card mb-1 list-item p-2 my-2 border-bottom" style={{ cursor: 'pointer', opacity: client.status === "Unavailable" || !client.chat ? 0.7 : 1 }} onClick={() => this.handleSelectChat(client)}>
                    {organizationName && <Label className='mb-0 d-flex px-2 mb-1 align-items-center  form-label'><CorporateFareIcon className='mr-1' />{organizationName}</Label>}
                    <div className='d-flex align-items-center'>
                        <div className="avatar-wrap w-15 align-self-center position-relative" >
                            {/*<Avatar className="align-self-center">{activity.title.charAt(0)}</Avatar>*/}
                            {client.status === "Unavailable" && <div className="unavailable-cross-style"></div>}
                            {unReadCount && unReadCount > 0 ?
                                <Badge className="badgeon-chatlist" badgeContent={unReadCount} color="primary">
                                    {client.profileImageURL && client.profileImageURL !== '' ?
                                        <img src={client.profileImageURL} data-testid={`contact-image-${keyIndex}`} className="rounded-circle align-self-center" width="40" height="40" />
                                        : <Avatar data-testid={`contact-avatar-${keyIndex}`} className="align-self-center user-later-avatar">{client.name.charAt(0)}</Avatar>
                                    }
                                </Badge>
                                : client.profileImageURL && client.profileImageURL !== '' ?
                                    <img src={client.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" data-testid={`contact-image-${keyIndex}`} />
                                    : <Avatar className="align-self-center user-later-avatar" data-testid={`contact-avatar-${keyIndex}`} >{client.name.charAt(0)}</Avatar>
                            }
                        </div>
                        <div className="actvity-routine mx-2 ml-3">
                            <h4 data-testid={`contact-name-${keyIndex}`}>{client.name}</h4>
                            {client.status && lastMessage ? <div className="status-text">{lastMessage.length > 45 ? lastMessage.substring(0, 45) + "..." : lastMessage}</div> : ""}
                        </div>
                        <IconButton className=" ml-auto" data-testid={`contact-open-icon-${keyIndex}`}><ArrowRightIcon color="primary" /></IconButton>
                    </div>
                </li>
            </React.Fragment>
        );
    }
}
const mapStateToProps = ({ Contacts, clientsList }) => {
    let { contactList, loading, chatToken } = Contacts;
    const { organizations } = clientsList

    return { contactList, loading, chatToken, organizations };
};

export default connect(mapStateToProps, {
    generateTwilioTokenForContacts
})(ContactListItem);