import React, { Component } from 'react';
// import { Radar } from 'react-chartjs-2';
import ChartConfig from '../../constants/chart-config';
import LifeSherpaLoading from '../LifeSherpaLoading';

import {
	Chart as ChartJS,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
  } from 'chart.js';
import {
	Radar
} from 'react-chartjs-2';

ChartJS.register(
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	redraw: true,
	legend: {
		labels: {
			fontColor: ChartConfig.legendFontColor
		}
	}
};

export default class RadarChart extends Component {

	componentDidMount() {
		this.props.getChartData(this.props.chartId)
	}

	render() {
		return (
			<article className="canvas-container">
				{
					this.props.loading ?
						<LifeSherpaLoading loading={this.props.loading}/>
					:
					<Radar data={this.props.data} options={options} />
				}
			</article>

		);
	}
}
