/**
 * Client Alerts
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import queryString from 'query-string';

 import ViewPage from '../../components/Notices/ViewPage'
 //Actions
 import {
     clearNoticesData
 } from '../../actions'
 
 class ViewAlert extends Component {
 
     constructor(props) {
         super(props)
         this.state = {
             role: null
         }
     }
 
     componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        this.setState({postId: params.postId, categoryId: params.categoryId});
        this.props.handleOpenURL('view');
        console.log("view Page Rendered");
         //http://localhost:8000/widget/client-alerts?userId=0I1aJIkeHPOdG4V896xnmvjrStq1&orgId=Test&region=australia&idToken=
     }

     componentDidUpdate(prevProps) {
          if(this.props.match.url != prevProps.match.url) {
            const params = queryString.parse(this.props.location.search);
            this.setState({postId: params.postId, categoryId: params.categoryId});
          }
     }
 
     render() {
         const {widgetApp } = this.props;
         const {postId, categoryId} = this.state;
         return (
             <div className='alert-view-page'>
                 {postId && categoryId? 
                        <ViewPage
                            widgetApp={widgetApp}
                            postId={postId}
                            categoryId={categoryId}
                        />
                   :
                   <div className='d-flex align-items-center my-4 py-4 mx-auto justify-content-between'>
                        <div className='text-center w-100 mt-4 pt-4'>
                        </div>
                    </div>
                 } 
             </div>
         )
     }
 }
 
 const mapStateToProps = ({ authUser }) => {
     const { themeLoading, userLoading } = authUser;
     return { themeLoading, userLoading }
 }
 
 export default connect(mapStateToProps, {
     clearNoticesData
 })(ViewAlert)
 