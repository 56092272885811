/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import settings from './settings';
import chatAppReducer from './ChatAppReducer';
import authUserReducer from './AuthUserReducer';
import ClientsList from './ClientsList'
import ActivitiesList from './ActivitiesList'
import ActivityHistoryList from './ActivityHistoryList'
import GroupList from './GroupList'
import PokeForm from './PokeForm'
import Geolocation from './Geolocation';
import ActivityReducer from './ActivityReducer'
import ActivityTemplateReducer from './ActivityTemplateReducer'
import UnConfirmAchievements from './UnConfirmAchievements'
import StatusReducer from './StatusReducer'
import RewardReducer from "./RewardReducer"
import ResourcesReducer from './ResourcesReducer';
import ContactReducer from "./ContactReducer"
import ChartsReducer from './ChartsReducer'
import Notifications from './Notifications'
import MyCourses from './MyCourses'
import emailAppReducer from './EmailAppReducer'
import MyActivity from './MyActivity'
import ScreenTimerReducer from "./ScreenTimerReducer";
import NoticesReducer from "./NoticesReducer";
import WorkflowReducer from "./WorkflowReducer";
import VideoCallHistoryReducer from './VideoCallHistoryReducer';

import {
   USER_LOGGED_OUT_RESET_REDUX
} from 'Actions/types';

const appReducer = combineReducers({
   settings,
   chatAppReducer,
   emailApp: emailAppReducer,
   authUser: authUserReducer,
   clientsList: ClientsList,
   activitiesList: ActivitiesList,
   activitiesHistoryList: ActivityHistoryList,
   Geolocation: Geolocation,
   ActivityReducer: ActivityReducer,
   ActivityTemplateReducer: ActivityTemplateReducer,
   Status:StatusReducer,
   GroupList,
   PokeForm,
   unConfirmAchievements: UnConfirmAchievements,
   Reward:RewardReducer,
   Resources:ResourcesReducer,
   Charts:ChartsReducer,
   Contacts:ContactReducer,
   screenTimer:ScreenTimerReducer,
   Notifications,
   MyCourses,
   MyActivity,
   noticesReducer: NoticesReducer,
   workflowData: WorkflowReducer,
   videoCallHistory: VideoCallHistoryReducer
});

const rootReducer = (state, action) => {
   // when a logout action is dispatched it will reset redux state
   
   if (action.type == USER_LOGGED_OUT_RESET_REDUX) {
     // console.log("redux data cleared................");
     state = {};
     return appReducer(state, action);
   } else {
     return appReducer(state, action);
   }

 };

export default rootReducer;
