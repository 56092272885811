/**
 * Acivity Detail Reducers
 */
import {
    GET_ACTIVITY_TEMPLATE_LIST,
    GET_SUCCESS_ACTIVITY_TEMPLATE_LIST,
    GET_ACTIVITY_TEMPLATE_GROUPS,
    GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS,
    SHOW_ACTIVITY_TEMPLATE_LOADING,
    HIDE_ACTIVITY_TEMPLATE_LOADING,
    CLEAR_ACTIVITY_TEMPLATE_DATA,
    REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE,
    ACTIVITY_TEMPLATE_SERVER_ERROR,
    SELECT_ACTIVITY_TEMPLATE,
    FILTER_ACTIVITY_TEMPLATE,
    SELECT_ACTIVITY_TEMPLATE_GROUP,
 } from '../actions/types';
 
 /**
  * initial auth user
  */
 const INIT_STATE = {
     activityTemplates: null,
     groups: null,
     loading: false,
     errorMessage: '',
     selectedTemplate: null,
     filteredTemplates: null,
     selectedGroups: null,
 };
 
 export default (state = INIT_STATE, action) => {
     switch (action.type) {
 
        case GET_ACTIVITY_TEMPLATE_LIST:
            return { ...state, loading: true };
 
        case GET_SUCCESS_ACTIVITY_TEMPLATE_LIST:
            return { ...state, activityTemplates: action.payload, filteredTemplates: action.payload, loading: false };
 
        case GET_ACTIVITY_TEMPLATE_GROUPS:
            return { ...state, loading: true };

        case GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS:
            let groups = action.payload
            groups.map(group => {
                group['selected'] = false
            })
            return { ...state, groups, loading: false};

        case ACTIVITY_TEMPLATE_SERVER_ERROR:
            return { ...state, errorMessage: action.payload, loading: false};    

        case CLEAR_ACTIVITY_TEMPLATE_DATA: 
            return{...state, loading: false, errorMessage: '', activityTemplates: null, groups: null}
        
        case REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE:
            return { ...state, errorMessage: '' };

        case SHOW_ACTIVITY_TEMPLATE_LOADING: 
            return{...state, loading: true}

        case HIDE_ACTIVITY_TEMPLATE_LOADING:
            return{...state, loading: false}
        
        case SELECT_ACTIVITY_TEMPLATE:
            return {...state, selectedTemplate: action.payload}
        
        case FILTER_ACTIVITY_TEMPLATE:
            const {groupIds, activityTemplateGroups} = action.payload
            let filteredTemplates = []
            if (groupIds.length > 0) {
                groupIds.map(group => {
                    const filterItems = state.activityTemplates.filter(activityTemplate => activityTemplate.ActivityGroups && activityTemplate.ActivityGroups[group])
                    if (filterItems && filterItems.length > 0) {
                        filterItems.map(item => {
                            if (filteredTemplates.length > 0){
                                const duplicate =  filteredTemplates.filter(template => template.templateId === item.templateId)
                                if (!(duplicate.length > 0)) {
                                    filteredTemplates.push(item)
                                }
                            } else {
                                filteredTemplates.push(item)
                            }
                        })
                    }
                    filteredTemplates.concat(filterItems)
                })
            } else {
                filteredTemplates = state.activityTemplates
            }
            return {...state, selectedGroups: groupIds, filteredTemplates, groups: activityTemplateGroups }
        
        case SELECT_ACTIVITY_TEMPLATE_GROUP:
            return {...state, selectedGroups: action.payload}

        default: 
            return { ...state };
     }
 }
 