import React, { Component } from "react";
import LifeSherpaLoading from '../LifeSherpaLoading';

class SuccessComponent extends Component {
    componentDidMount() {
        console.log("success page --------->")
    }
    render() {
        return (
            <div style={{height:"100%",width:"100%"}}>
                <LifeSherpaLoading loading={true} />
            </div>
        )
    }
}
export default SuccessComponent;
