import React, { Component } from 'react';

import { connect } from 'react-redux';
// reactstrap components
// Material UI Components
import Dialog from '@mui/material/Dialog';
import SweetAlert from 'react-bootstrap-sweetalert';

import {
    getAssignableWorkflowUsers,
    startWorkflow,
    hideStartWorkflowPanel,
    getWorkflowHistory,
    assignUserToPendingWorkflow,
    clearWorkflowErrorResponse
} from '../../actions';
import UsersToAssign from './components/UsersToAssign';
import AssignedUsers from './components/AssignedUsers';
import WorkflowHistory from './components/WorkflowHistory';
import {checkErrorInTasks} from "../../helpers/helpers"

class StartWorkflowView extends Component {

    state = {
        addUsersPage: false,
        search: "",
        contacts: [],
        selectedIndex: 0,
        roleWiseAssignedUserList: null,
        selectedWorkflow: [],
        workflowname: null,
        userByRoles: {},
        usersToAssign: [],
        pageMounted: false,
        showAssignPage: false,
        pendingWorkflowExecution: null

    };

    componentDidMount() {
        const { selectedWorkflow, openWorkflowPendingList } = this.props;
        if (openWorkflowPendingList) {
            this.props.getWorkflowHistory({ workflowId: selectedWorkflow.id, orgId: selectedWorkflow.organization })
        }
        if (selectedWorkflow) {
            if(!openWorkflowPendingList) {
                this.props.getAssignableWorkflowUsers({ workflowId: selectedWorkflow.id, orgId: selectedWorkflow.organization });
            }
            
            if (selectedWorkflow.roles) {
                const userByRoles = {};
                let workflowname = selectedWorkflow.name || "";
                for (const role in selectedWorkflow.roles) {
                    userByRoles[role] = { ...selectedWorkflow.roles[role]};
                    if(userByRoles[role].addNameToExecutionTitle) {
                        workflowname = workflowname + ": ${"+role+"}";
                    }
                    if(userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static") {
                        userByRoles[role]["isRequired"] = true;
                    }
                }
                this.setState({ userByRoles, workflowname});
            }
        }

    }

    handleClosePanel() {
        this.props.hideStartWorkflowPanel()
    }


    handleRemoveMember(selectedRole) {
        let { userByRoles } = this.state;
        userByRoles[selectedRole]['data'] = null
        this.setState({ userByRoles });
    }

    handleCloseAddUsersPage = (data) => {
        let { selectedRole, userByRoles } = this.state;
        userByRoles[selectedRole]['data'] = data;
        this.setState({ addUsersPage: false, userByRoles})
    }

    handleSubmit = (ignoreMaxWorkflowsLimit = false) => {
        const { userByRoles, workflowname, pendingWorkflowExecution } = this.state
        const { selectedWorkflow, openWorkflowPendingList } = this.props;
        const orgId = selectedWorkflow.organization;
        const workflowId = selectedWorkflow.id;

        let assignmentsByRoles = {}
        Object.keys(userByRoles).map(role => {
            if (userByRoles[role]["data"]) {
                const userId = userByRoles[role]["data"].userId;
                const groupId = userByRoles[role]["data"].groupId;
                if(userId) {
                    assignmentsByRoles[role] = {userId}
                } else if(groupId) {
                    assignmentsByRoles[role] = {groupId}
                }
                
            }
        })
       
        if (openWorkflowPendingList) {
            for (const role in pendingWorkflowExecution.roles) {
                if (pendingWorkflowExecution.roles[role].userId || pendingWorkflowExecution.roles[role].groupId){
                    delete assignmentsByRoles[role]
                }
            }
            const data = { assignmentsByRoles};
            const executionId = pendingWorkflowExecution.id
            // console.log("assignUserToPendingWorkflow :", {orgId, workflowId, executionId, data, ignoreMaxWorkflowsLimit})
            this.props.assignUserToPendingWorkflow({orgId, workflowId, executionId, data, ignoreMaxWorkflowsLimit})
        } else {
            const data = { assignmentsByRoles, executionName: workflowname };
            // console.log("startWorkflow ", { userByRoles, selectedWorkflow, workflowId, orgId, data })
            this.props.startWorkflow({orgId, workflowId, data, ignoreMaxWorkflowsLimit});
        }
    }


    handleOpenAssignUsers(selectedRole) {
        this.setState({ addUsersPage: true, selectedRole, pageMounted: true  })
    }


    handleWorkflowNameChange(event) {
        this.setState({ workflowname: event.target.value })
    }

    handleCloseWorkflowHistory() {
        const {openWorkflowPendingList} = this.state
        this.setState({openWorkflowPendingList: !openWorkflowPendingList})
    }

    openAssignUserPanel(pendingWorkflowExecution) {
        const {pendingRoles} = checkErrorInTasks(pendingWorkflowExecution.tasks);
        const userByRoles = {};
        for (const role in pendingWorkflowExecution.roles) {
            userByRoles[role] = {...pendingWorkflowExecution.roles[role]};
            userByRoles[role].isRequired = pendingRoles && pendingRoles[role] ? true : false;
            if(userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static") {
                userByRoles[role]["isRequired"] = true;
            }
        }
        const executionId = pendingWorkflowExecution.id;
        this.props.getAssignableWorkflowUsers({ workflowId: pendingWorkflowExecution.workflowId, orgId: pendingWorkflowExecution.organization, executionId });
        this.setState({ showAssignPage: true, pageMounted: true, pendingWorkflowExecution, userByRoles })
    }

    cancelWarningAlert() {
        this.props.clearWorkflowErrorResponse();
    }

    render() {
        let { selectedWorkflow, workflowname, userByRoles, showAssignPage, pendingWorkflowExecution, selectedRole } = this.state;
        let { startWorkflowLoading, showWorkflowHistory, workflowHistory, workflowHistoryLoading, isMobileView, fetchAssigneeLoading, workflowErrorResponse, openWorkflowPendingList, assignableUsersByRoles } = this.props;
        console.log("workflowErrorResponse :", workflowErrorResponse);
        return (
            <div>
                <Dialog
                    open={Boolean(selectedWorkflow)}
                    fullScreen={isMobileView}
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} notices-list-panel start-workflow-popup`}
                >
                    { this.state.addUsersPage ?
                        <UsersToAssign
                            addUsersPage={this.state.addUsersPage}
                            itemsList={assignableUsersByRoles && assignableUsersByRoles[selectedRole] && (assignableUsersByRoles[selectedRole]['users'] || assignableUsersByRoles[selectedRole]['groups'])}
                            selectedItem={userByRoles[this.state.selectedRole]['data']}
                            handleClose={this.handleCloseAddUsersPage}  
                            assignmentType={userByRoles[this.state.selectedRole]['assignmentType']}
                            includeUsers={userByRoles[this.state.selectedRole]['includeUsers']}
                        
                        />
                        : openWorkflowPendingList && showAssignPage ?
                        <AssignedUsers
                            userByRoles={userByRoles}
                            workflowname={workflowname}
                            loading={startWorkflowLoading  || fetchAssigneeLoading || workflowHistoryLoading}
                            handleOpenAssignUsers={this.handleOpenAssignUsers.bind(this)}
                            handleRemoveMember={this.handleRemoveMember.bind(this)}
                            handleWorkflowNameChange={this.handleWorkflowNameChange.bind(this)}
                            handleClosePanel={this.handleClosePanel.bind(this)}
                            handleSubmit={this.handleSubmit.bind(this)}
                            pageMounted={this.state.pageMounted}
                            pendingWorkflowExecution={pendingWorkflowExecution}
                            openWorkflowPendingList={openWorkflowPendingList}
                            assignableUsersByRoles={assignableUsersByRoles}
                        /> 
                        : openWorkflowPendingList ? 
                        <WorkflowHistory 
                            openAssignUserPanel={this.openAssignUserPanel.bind(this)} 
                            open={openWorkflowPendingList} 
                            popupThemeClass={this.props.popupThemeClass} 
                            handleClosePanel={this.handleClosePanel.bind(this)}
                            workflowHistory={workflowHistory}
                            workflowHistoryLoading={workflowHistoryLoading || fetchAssigneeLoading}
                            />
                        
                        : <AssignedUsers
                            userByRoles={userByRoles}
                            workflowname={workflowname}
                            loading={startWorkflowLoading || fetchAssigneeLoading || workflowHistoryLoading}
                            handleOpenAssignUsers={this.handleOpenAssignUsers.bind(this)}
                            handleRemoveMember={this.handleRemoveMember.bind(this)}
                            handleWorkflowNameChange={this.handleWorkflowNameChange.bind(this)}
                            handleClosePanel={this.handleClosePanel.bind(this)}
                            handleSubmit={this.handleSubmit.bind(this)}
                            pageMounted={this.state.pageMounted}
                        />
                    }

                </Dialog>

                {workflowErrorResponse ? 
                    workflowErrorResponse.errorCode === "E1422" ?
                        <SweetAlert
                            warning
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Assigning Role(s) Warning!"
                            onConfirm={() => this.handleSubmit(true)}
                            onCancel={() => this.cancelWarningAlert()}
                            >
                                <div style={{ fontFamily: "'Lato'", fontWeight: 400, fontSize: 16 }}>
                                        <div>Failed to start workflow due to exceeding maximum workflows limit for the users assigned to roles: </div>
                                        <div style={{ margin: "10px 0px" }}>{Object.keys(workflowErrorResponse.userWorkflowsByRoles).join(", ")}</div>
                                        <div> Do you want to start the same workflow again?</div>
                                </div>
                        </SweetAlert>
                        : 
                            <SweetAlert
                                warning
                                btnSize="sm"
                                customClass="warningText"
                                confirmBtnText="OK"
                                confirmBtnBsStyle="warning"
                                cancelBtnBsStyle="default"
                                title="Assigning Role(s) Denied"
                                onConfirm={() => this.cancelWarningAlert()}
                                >
                                    {
                                        workflowErrorResponse.errorCode === "E1421" ?
                                        <div style={{ fontFamily: "'Lato'", fontWeight: 400, fontSize: 16 }}>
                                            <div>Failed to start workflow due to exceeding maximum workflows limit for the users assigned to roles: </div>
                                            <div style={{ margin: "10px 0px" }}>{Object.keys(workflowErrorResponse.userWorkflowsByRoles).join(", ")}</div>
                                        </div>
                                        : 
                                        <div style={{ fontFamily: "'Lato'", fontWeight: 400, fontSize: 16 }}>
                                            {workflowErrorResponse.message}
                                        </div>

                                    } 
                            </SweetAlert>
                    : <></>
                }
            </div>
            
        );
    }
}

const mapStateToProps = ({ settings, Contacts, workflowData }) => {
    const { popupThemeClass, isMobileView } = settings;
    const { contactList, selectedGroupDetails, groupDetailsLoading } = Contacts;
    const { assignableUsersByRoles, selectedWorkflow, workflowLoading: loading, startWorkflowLoading, showWorkflowHistory,  workflowHistory, workflowHistoryLoading, fetchAssigneeLoading, workflowErrorResponse, openWorkflowPendingList} = workflowData
    return { popupThemeClass, contactList, assignableUsersByRoles, selectedWorkflow , startWorkflowLoading, showWorkflowHistory, workflowHistory, workflowHistoryLoading, isMobileView, fetchAssigneeLoading, workflowErrorResponse, openWorkflowPendingList};
}

export default connect(mapStateToProps, {
    getAssignableWorkflowUsers,
    startWorkflow,
    hideStartWorkflowPanel,
    getWorkflowHistory,
    assignUserToPendingWorkflow,
    clearWorkflowErrorResponse
})(StartWorkflowView);