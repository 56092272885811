/**
 * Client Alerts
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'

import CourseViewPage from '../../../components/Courses/ActivityCourse/CourseViewPage';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Actions
import {
    clearCourseData
} from '../../../actions';

class CourseView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null
        }
    }

    componentDidMount() {
        console.log("Course View Rendered");
        this.props.clearCourseData();
        //http://localhost:8000/widget/course-view?courseId=113&userId=0I1aJIkeHPOdG4V896xnmvjrStq1&orgId=Test&region=australia&idToken=
    }

    handleclosePanel = () => {
        this.props.clearCourseData();
        window.location.href = window.location.origin + "/success";
    }



    render() {
        const { widgetApp, selectedUserId } = this.props;
        const {userId, courseId} = this.props.params;
        console.log("params : ", this.props.params);
        return (
            <div className='activity-course-viewer'>
                <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                    <IconButton title='Back To Home' className="text-white" onClick={() => this.handleclosePanel()}><ArrowBackIcon /></IconButton>
                    {/* <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{title || "LMS Course"}</Label>
                    <IconButton className="text-white mr-2" onClick={() => this.handleClosePanel()}><CloseIcon /></IconButton> */}
                </div>
                <CourseViewPage courseId={courseId} userId={userId} handleClose={this.handleclosePanel}/>
            </div>
        )
    }
}

const mapStateToProps = ({ authUser }) => {
    const { themeLoading, userLoading } = authUser;
    return { themeLoading, userLoading }
}

export default connect(mapStateToProps, {
    clearCourseData
})(CourseView)
