import {
    GET_STATUS,
    REMOVE_STATUS,
    GET_STATUS_LOADING,
    GET_CLIENT_STATUS_DATA,
    SET_FORM_STATUS_DATA,
    HIDE_STATUS_LOADING
} from '../actions/types';

const INIT_STATE = {
    status:null,
    loading:false,
    clientStatus:null,
    statusList:null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STATUS:
            return { ...state, status:action.payload,loading:false };
        case REMOVE_STATUS:
            return {...state,status:null,loading:true}
        case GET_CLIENT_STATUS_DATA:
            return { ...state,clientStatus:action.payload,loading:false };
        case SET_FORM_STATUS_DATA:
            return { ...state, statusList: action.payload};
        case GET_STATUS_LOADING:
            return {...state,loading:true}
        case HIDE_STATUS_LOADING:
            return {...state,loading:false}
        default: 
        return { ...state };
        }
}