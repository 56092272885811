import React, { Component } from 'react';
import { connect } from 'react-redux';
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import IconButton from '@mui/material/IconButton';
import { removeVideoToken, setTwilioClient, addChannelToContactList, generateTwilioTokenForContacts } from "Actions"
import ChatItem from "./ChatItem"
import LifeSherpaLoading from 'Components/LifeSherpaLoading';
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar';
import {
  List,
} from "@mui/material";
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send';

import config from '../../config/config'
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Client as ConversationsClient } from "@twilio/conversations";
import DotsLoader from "../DotsLoader";
import {getTwilioChatInfo} from "../../helpers/helpers";

const styles = {
  textField: {
    width: "100%",
    borderWidth: 0,
    borderColor: "transparent",
    height: 45,
    marginBottom: 3
  },
  textFieldContainer: {
    flex: 1,
    marginRight: 3,
    marginLeft: 2
  },
  gridItem: {
    paddingTop: 12,
    paddingBottom: 12
  },
  gridItemChatList: {
    overflow: "auto",
    height: "56vh"
  },
  gridItemChatList1: {
    overflow: "auto",
    height: "51vh"
  },
  gridItemMessage: {
    marginTop: 2,
    marginBottom: 2
  },
  sendButton: {
    backgroundColor: '#008C95',
    height: 45,
    marginLeft: 2
  },
  sendIcon: { color: "white" },
  mainGrid: { paddingTop: 100, borderWidth: 1 },
};

class UserChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      messages: [],
      loading: false,
      conversation: null,
      videoCallRoomName: null,
      textMenuOpen: null,
      bottomScroll: false,
      unReadMessagesCount: 0,
      showUnAvailabelAlert: false,
      alertMessage: "",
      isTyping: false
    }
    this.scrollDiv = React.createRef();
  }
  
  componentDidMount = async () => {
    this.props.removeVideoToken();
    const testing = localStorage.getItem("testing");
    if (!testing) {
      await this.handleChatChannelFetch();
    }
    const { selectedChat } = this.props;
    if (selectedChat && selectedChat.status == "Unavailable" && this.props.fromNotification) {
      this.setState({ showUnAvailabelAlert: true, alertMessage: "User is unavailable" })
    }
  }

  componentWillUnmount() {
    const { conversation } = this.state;
    if (conversation) {
      conversation.off("messageAdded", m => this.handleMessageAdded(m));
      conversation.off("typingStarted", () => this.handleTypingStarted());
      conversation.off("typingEnded", () => this.handleTypingEnded());
      this.setState({ text: "", messages: [], loading: false, conversation: null });
    }
  }

  handleChatChannelFetch = async () => {
    let { selectedChat, room } = this.props;
    console.log("[UserChat] selectedChat -->", selectedChat);
    if (selectedChat.conversation) {
      this.setState({ conversation: selectedChat.conversation, messages: selectedChat.messages, loading: true });
      this.joinConversation(selectedChat.conversation);
    } else {
      this.setState({ loading: true });
      const details = await getTwilioChatInfo(selectedChat.userId);
      console.log("Chat Details : ", details);
      if(details && details.token && details.room) {
        this.createTwilioClient(details);
      } else {
        this.setState({ loading: false });
      } 
    }
  }

  createTwilioClient = async ({token, room}) => {
    try {
      const client = new ConversationsClient(token);
      console.log("[UserChat] conversation client created --> ", client);
      client.on('stateChanged', (state) => {
        if (state === 'initialized') {
          console.log(" Client state initialized --> ")
          this.props.setTwilioClient(client);
          this.createConversationWithUniqueName(client, room);
        }
      })
    } catch (e) {
      console.error("[UserChat] error occured while creating channel -->", e);
      this.setState({ loading: false });
    }
  }
  createConversationWithUniqueName = async (client, room) => {
    try {
      const conversation = await client.getConversationByUniqueName(room.uniqueName);
      console.log("[UserChat] fetched conversaton by unique name -->", conversation);
      this.updateConversation(conversation);
    } catch (err) {
      console.error("[UserChat] Unable to get conversation by unique name --> ", room, err);
      try {
        const conversation = await client.createConversation({
          friendlyName: room.friendlyName,
          uniqueName: room.uniqueName,
        })
        await conversation.add(this.props.selectedChat.userId)
        console.log("[UserChat] conversaton created -->", conversation);
        this.updateConversation(conversation);
      } catch (error) {
        this.setState({ loading: false });
        console.error("[UserChat] Unable to create conversation, please reload this page --> ", error);
      }
      this.setState({ loading: false });
    }
  }

  updateConversation = (conversation) => {
    const { contactList, selectedChat } = this.props;
    this.setState({ conversation: conversation });
    this.joinConversation(conversation);
    this.props.addChannelToContactList(contactList, conversation, selectedChat.userId);
  }

  joinConversation = async (conversation) => {
    try {
      if (conversation._internalState.status !== "joined") {
        await conversation.join();
      }
      conversation.getMessages(50).then(messages => {
        console.log("[UserChat] got all recent messages -->", messages);
        if (messages.items && messages.items.length > 0) {
          this.setState({ messages: messages.items, clientMessages: messages });
        }
        this.scrollToBottom();
      }).catch(e => {
        this.setState({ loading: false });
        console.log("[UserChat] error occured while fetching messages", e);
      });
      //await conversation.setAllMessagesRead();
      conversation.setAllMessagesRead().then(status => {
        console.log("[UserChat] messages read -->", status);
      }).catch(e => {
        console.log("[UserChat] error occured while reading messages -->", e);
      })
      conversation.on("messageAdded", m => this.handleMessageAdded(m));
      conversation.on("typingStarted", () => this.handleTypingStarted());
      conversation.on("typingEnded", () => this.handleTypingEnded());
      this.setState({ loading: false });
      this.scrollToBottom();
    } catch (e) {
      this.setState({ loading: false });
      console.log("[UserChat] error occured with conversation", e);
    }
  };


  handleMessageAdded = (message) => {
    let { messages, bottomScroll, unReadMessagesCount, conversation } = this.state;
    let uid = localStorage.getItem('auth_uid');
    var objDiv = document.getElementById('chatlists1');
    if (conversation && objDiv) {
      console.log("[UserChat] conversation messages added --->", message);
      if (message.author === uid || !bottomScroll) {
        this.setState({ messages: messages && messages.length > 0 ? [...messages, message] : [message] });
        this.scrollToBottom();
      } else {
        unReadMessagesCount = unReadMessagesCount + 1;
        this.setState({ messages: messages && messages.length > 0 ? [...messages, message] : [message], unReadMessagesCount: unReadMessagesCount });
      }
    }
  };

  scrollToBottom = () => {
    var objDiv = document.getElementById('chatlists1');
    const { conversation } = this.state;
    if (objDiv) {
      // objDiv.scrollTop = objDiv.scrollHeight;
      if (conversation) {
        conversation.setAllMessagesRead().then(status => {
          console.log("[UserChat] messages Consumed -->", status);
          objDiv.scrollTop = objDiv.scrollHeight;
        }).catch(e => {
          console.log("[UserChat] error occured while reading messages -->", e);
        })
      }
      this.setState({ bottomScroll: false, unReadMessagesCount: 0 });
    }
  };


  handleScrollOnChat = (event) => {
    var objDiv = event.currentTarget;
    const { bottomScroll } = this.state;
    // console.log(" clientHeight --> ", objDiv.clientHeight);
    // console.log(" scrollTop --> ", objDiv.scrollTop);
    // console.log("scrollHeight ----> ", objDiv.scrollHeight);
    const clientHeight = objDiv.clientHeight;
    const scrollTop = objDiv.scrollTop + clientHeight;
    const scrollHeight = objDiv.scrollHeight;
    if (scrollTop < (scrollHeight - clientHeight)) {
      if (!bottomScroll) {
        this.setState({ bottomScroll: true })
      }
    } else if (bottomScroll) {
      const { conversation } = this.state;
      if (conversation) {
        conversation.setAllMessagesRead().then(status => {
          console.log("[UserChat] messages Consumed -->", status);
        }).catch(e => {
          console.log("[UserChat] error occured while reading messages -->", e);
        })
      }
      this.setState({ bottomScroll: false, unReadMessagesCount: 0 })
    }
    if (objDiv && objDiv.scrollTop === 0) {
      this.handleFetchPreMessages();
    }
  }

  handleFetchPreMessages = () => {
    const { clientMessages, loading, messages } = this.state;
    if (clientMessages && clientMessages.hasPrevPage && !loading) {
      this.setState({ loading: true });
      clientMessages.prevPage().then(preMessages => {
        if (preMessages.items && preMessages.items.length > 0) {
          console.log("[UserChat] Fetched previous page messages --> ");
          let updatedMessagesList = [...preMessages.items, ...messages];
          const objDiv = document.getElementById('chatlists1');
          const previousHeight = objDiv ? objDiv.scrollHeight : 0;
          this.setState({ messages: updatedMessagesList, clientMessages: preMessages, loading: false, bottomScroll: true });
          this.handleScrollFocusOnOldMessagesLoaded(previousHeight);
        }
      }).catch(error => {
        this.setState({ loading: false });
        console.log("[UserChat] error occured while fetching previous messages -->", error)
      })
    }
  }

  handleScrollFocusOnOldMessagesLoaded = (previousHeight) => {
    var objDiv = document.getElementById('chatlists1');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight - previousHeight;
    }
  }

  sendMessage = () => {
    const { text, conversation, videoCallRoomName } = this.state;
    let { selectedChat } = this.props;
    if (text && conversation) {
      if (text && String(text).trim()) {
        this.setState({ loading: true, text: "" });
        conversation.sendMessage(text).then(() => {
          this.setState({ loading: false });
        }).catch((error) => {
          console.log("[UserChat] error occured while sending message", error, error.message, error.data);
          this.setState({ loading: false, showUnAvailabelAlert: true, alertMessage: error.message });
        })
      }
    }
  };

  handleEnter = (evt) => {
    if (evt.keyCode == 13 && evt.shiftKey) {
      // evt.preventDefault();
      // this.setState({text: this.state.text+"\n"})
      //evt.preventDefault();
      this.activeTyping();
    } else if (evt.keyCode == 13) {
      evt.preventDefault();
      this.sendMessage(evt)
    } else {
      this.activeTyping();
    }
  }

  openTextMenuOptions = event => {
    this.setState({ textMenuOpen: event.currentTarget });
  }
  closeTextMenuOptions = () => {
    this.setState({ textMenuOpen: null });
  };
  sendVidoeLink = () => {
    let { selectedChat } = this.props;
    const selectedVideoChat = { ...selectedChat };
    const roomname = uuidv4() //`${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
    selectedVideoChat.name = roomname;
    let videoCallRoom = `Join Video Call: ${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}`;
    //let videoCallRoom = `Join Video Call: http://localhost:8000/lifesherpa/videocall?room=${selectedVideoChat.name}`;
    this.setState({ text: videoCallRoom, textMenuOpen: null, videoCallRoomName: selectedVideoChat.name });
  }
  handleVideo = (chat) => {
    this.props.handleVideo(chat);
  }
  getFilteredMessages = (messages, conversation) => {
    if (conversation && conversation.uniqueName) {
      return messages && messages.length > 0 ? messages.filter(msg => {
        let uniqueName = msg && msg.conversation && msg.conversation.uniqueName ? msg.conversation.uniqueName : "";
        return uniqueName === conversation.uniqueName
      }) : [];
    } else {
      return messages;
    }
  }
  activeTyping = () => {
    const { conversation } = this.state;
    if (conversation) {
      conversation.typing().then((s) => {
        console.log("[UserChat] typing enable", s)
      }).catch((error) => {
        console.log("[UserChat] error occured while enabling typing", error);
      })
    }
  }
  handleTypingStarted = () => {
    console.log("[UserChat] typing started --> ")
    let { bottomScroll } = this.state;
    if (!bottomScroll) {
      this.setState({ isTyping: true }, () => {
        this.scrollBottomToShowTypingIndicator()
      })
    }
  }

  scrollBottomToShowTypingIndicator = () => {
    var objDiv = document.getElementById('chatlists1');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    // this.setState({ isTyping: true })
  }

  handleTypingEnded = () => {
    console.log("[UserChat] typing ended --> ")
    this.setState({ isTyping: false })
  }

  render() {
    const { loading, text, messages, conversation, textMenuOpen, bottomScroll, unReadMessagesCount, showUnAvailabelAlert, alertMessage, isTyping } = this.state;
    const { selectedChat } = this.props;
    let uid = localStorage.getItem('auth_uid');
    let filteredMessages = [];
    filteredMessages = this.getFilteredMessages(messages, conversation);
    return (
      <div className="add-video-group-panel chat-panel-messges" data-testid="selected-contact-chat-panel">
        <div className="d-flex add-group-header lifesherpa-chat-panel-header align-items-center justify-content-between py-1">
          <div className="media align-items-center">
            <IconButton className="text-white font-lg" onClick={() => this.props.handleBack()} data-testid="chat-panel-back-button"><ArrowBackIcon /></IconButton>
            <div className="mr-2" >
              {selectedChat && selectedChat.profileImageURL ?
                <img src={selectedChat.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" />
                : <Avatar className="align-self-center user-later-avatar">{selectedChat.name.charAt(0)}</Avatar>
              }
            </div>
            <div className="media-body">
              <h2 className="m-0" data-testid="chat-panel-contact-name">{selectedChat.name}</h2>
              {isTyping && <div className="fs-12">Typing....</div>}
            </div>
          </div>
          {this.props.selectedChat.videoCall && <IconButton className=" mr-2" onClick={() => this.handleVideo(this.props.selectedChat)} disabled={selectedChat.status === "Unavailable"} data-testid="chat-panel-video-button"><VideocamIcon /></IconButton>}
        </div>
        <LifeSherpaLoading loading={loading} />
        {showUnAvailabelAlert ?
          <SweetAlert
            warning
            btnSize="sm"
            customClass="warningText"
            confirmBtnText="Close"
            confirmBtnBsStyle="warning"
            title={alertMessage}
            onConfirm={() => this.setState({ showUnAvailabelAlert: false })}
          >
            <div className="sweet-alert-text">Please try again later.</div>
          </SweetAlert> 
          :<></>
        }
        {bottomScroll && unReadMessagesCount ?
          <Badge className="scroll-to-bottom-badge" badgeContent={unReadMessagesCount} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
            <IconButton className="scroll-to-bottom-icon" onClick={() => this.scrollToBottom()}><ArrowDownwardIcon /></IconButton>
          </Badge>
          : bottomScroll ?
            <div className="scroll-to-bottom-box">
              <IconButton className="scroll-to-bottom-icon" onClick={() => this.scrollToBottom()}><ArrowDownwardIcon /></IconButton>
            </div>
            : ""
        }
        <Grid className="chat-item-list" item style={styles.gridItemChatList} onScroll={(e) => this.handleScrollOnChat(e)} id="chatlists1">
          <List dense={true}>
            {filteredMessages &&
              filteredMessages.map((message, index) => (
                <ChatItem
                  key={message.state.timestamp + index}
                  message={message}
                  email={uid}
                />
              ))}
          </List>
          {isTyping &&
            <div className="d-flex align-items-center mx-3">
              <div style={{ width: "50px" }}>
                <DotsLoader />
              </div>
            </div>
          }
        </Grid>

        <Grid item style={styles.gridItemMessage}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="px-3 py-1 chat-footer"
          >
            {/* <Grid item>
                  <IconButton 
                      aria-owns={textMenuOpen ? 'simple-menu-text' : null}
                      aria-haspopup="true"
                      onClick={this.openTextMenuOptions}
                      className="add-more-icon-button"
                        >
                      <AddIcon className="primary-icon"/>
                  </IconButton>
                  <Menu
                      id="simple-menu-text"
                      anchorEl={textMenuOpen}
                      open={Boolean(textMenuOpen)}
                      onClose={this.closeTextMenuOptions}
                      >
                          <MenuItem onClick={() => this.sendVidoeLink()}> <VideocamIcon className="primary-icon mr-1"/> Create Video Room</MenuItem>
                    </Menu>
               </Grid> */}
            <Grid item style={styles.textFieldContainer}>
              <div /*onSubmit={(event) => this.sendMessage(event)}*/ className="mr-3 w-100">
                {/* <FormGroup className="mb-0 w-100">
                    <Input
                      type="text"
                      id="search-msg"
                      placeholder={selectedChat.status === "Unavailable" ? "User is unavailable" : "Type your message"}
                      value={this.state.text}
                      className="msg-input"
                      onChange={(event) => this.setState({ text: event.target.value, })}
                      onKeyPress={(e) => {if (e.key === 'Enter') this.sendMessage(e)}}
                      disabled={selectedChat.status === "Unavailable"}
                    />
                  </FormGroup> */}
                <textarea
                  rows="2"
                  autoFocus
                  autoComplete="off"
                  className="chat-msg-input-field"
                  placeholder={selectedChat.status === "Unavailable" ? "User is unavailable" : (selectedChat.chat != undefined && !selectedChat.chat) ? "Chat is not supported" : "Type your message"}
                  onChange={(event) => this.setState({ text: event.target.value })}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onKeyDown={(e) => this.handleEnter(e)}
                  // onMouseEnter={e => this.handleMouseEnter()}
                  // onMouseLeave={() => this.handleMouseLeave()}
                  // onFocus={e => this.handleInputFocus()}
                  value={this.state.text}
                  disabled={selectedChat.status === "Unavailable" || (selectedChat.chat != undefined && !selectedChat.chat)}

                />
              </div>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => this.sendMessage(e)}
                className="send-icon-button"
                disabled={selectedChat.status == "Unavailable" || (selectedChat.chat != undefined && !selectedChat.chat) ? true : text && String(text).trim() ? false : true}
              >
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>);
  }
}

const mapStateToProps = ({ Contacts }) => {
  const { loading, chatToken, videoToken, channel, selectedVideoGroup, contactList, twilioClient } = Contacts
  return { loading, chatToken, videoToken, channel, selectedVideoGroup, contactList, twilioClient };
};
export default connect(mapStateToProps, { removeVideoToken, setTwilioClient, addChannelToContactList, generateTwilioTokenForContacts })(UserChat);