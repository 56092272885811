/**
 * App  Theme2
 */
import { createTheme } from '@mui/material/styles';
import AppConfig from '../../constants/AppConfig';

const theme = createTheme({
    palette: {
        primary: {
            main: AppConfig.themeColors.theme2
        },
        secondary: {
            main: AppConfig.themeColors.grey
        },
        progress: {
            main: AppConfig.themeColors.theme2
         }
    }
});

export default theme;