// Date Picker
import React, { Fragment, Component } from 'react';
import moment from 'moment';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
// import MobileDatePicker from "@mui/lab/MobileDatePicker"

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

export default class DatePickers extends Component {

	state = {
		selectedDate: null,
		open: false
	};
	
	handlePickerOpen = (bool) => {
		this.setState({...this.state, open: bool});
	}

	handleDateChange = (date) => {
		try {
			console.log("picker value --> ",date);
			let dateObj = new Date(date);
			let month = dateObj.getMonth();
			let day = dateObj.getDate();
			let year = dateObj.getFullYear();
			console.log("month value --> ",month);
			console.log("day value --> ",day);
			console.log("year value --> ",year);
			month = month >= 0 && month < 12 ? true : false;
			day = day >= 1 && day < 32 ? true : false;
			year = year > 1900 && year < 2050 ? true : false;
			if(month && day && year) {
				this.props.handleOnDateChange(date)
			}
		} catch(e) {
			console.log("Invalid date, please enter valid date", e)
		}
	};

	render() {
		const {open} = this.state;
        let datestring = null
		try {
			datestring =  new Date(this.props.value).toISOString()
		} catch(e) {
			datestring = new Date().toDateString();
		}
		const minDateString = this.props.minDate ? new Date(this.props.minDate).toISOString() : undefined
		return (
			<Fragment>
				<div className="rct-picker">
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						label={this.props.label}
						open={open}
						onOpen={() => this.handlePickerOpen(true)}
						onClose={() => this.handlePickerOpen(false)}
						value={datestring}
						inputFormat="MMM dd yyyy"
						//data-testid={this.props.testid}
						onChange={this.handleDateChange}
						animateYearScrolling={true}
						minDate={minDateString}
						minDateMessage={"Invalid Schedule!  Please ensure that the End Date is greater than Start Date"}
						leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
						rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
						fullWidth
						// InputProps={{"data-testid": "content-input",
						// 	startAdornment: (
						// 		<InputAdornment position="start" className="date-picker-icon">
						// 			<CalendarTodayRoundedIcon fontSize="small" />
						// 		</InputAdornment>
						// 	),
						// }}
						renderInput={(params) => <TextField data-testid={this.props.testid}  variant="standard" onClick={(e) => this.handlePickerOpen(true)}  {...params} inputProps={{...params.inputProps,readOnly: true}}/>}
						InputAdornmentProps={{ position: 'start' }}
						disableMaskedInput={true}
						components={{
							OpenPickerIcon: CalendarTodayRoundedIcon,
							LeftArrowIcon: ArrowBackRoundedIcon,
							RightArrowIcon: ArrowForwardRoundedIcon,

						}}
						showToolbar={false}
						toolbarFormat="MMM dd yyyy"
						// readOnly
						
					/>
					</LocalizationProvider>
				</div>
			</Fragment>
		)

	}
}