/**
 * AppToolBar
 */

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

const AppToolBar = ({activeTheme, themeLogo, applogo, themeData}) => (
    <AppBar position="static" className="session-header">
        <Toolbar>
            <div className="container">
            <div className="d-flex justify-content-center">
                <div className="session-logo">
                    {activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
                    <Link to="/">
                        <img src={themeLogo || applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img' />
                    </Link>
                    :
                    <Link to="/" className="text-center">
                        <img src={themeLogo || applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img'/>
                        <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                        {themeData && themeData.loginLifeSherpaImage &&
                        <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                        }
                        </div>
                    </Link>
                    }
                </div>
            </div>
            </div>
        </Toolbar>
    </AppBar>
)

export default AppToolBar