/**
 * User List Item
 */
import React from 'react';
import {
	Form,
	FormGroup,
	Label,
	Input,
	Col,
} from 'reactstrap';
import Divider from '@mui/material/Divider';
import CustomInput from '../CustomInput'


const MultiSelectionItem = ({obj, updateform , section, showError}) => (
    <div key={section+"multiselectionitem"}>
        {obj.heading ? <Label className="form-label">{`${obj.heading}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></Label> : <Divider className="my-2"/>}
        {obj.itemsList && obj.itemsList.map((label, key) => (
          <div className="preveiw-options" key={key}> <CustomInput key={key+"custominput"} className="bg-white" color="primary" checked={label.value}  type="checkbox" id={`checkbox-${key}-${label.name}-${section}`} name={label.name} label={label.name} onChange={(e) => updateform(e, obj, section)}/> </div>
        ))}
        {showError && obj.isMandatory && obj.itemsList && obj.itemsList.findIndex(item => item.value) == -1 && <div className="input-options-error">{`${obj.heading} section is required`}</div>}
        {/*  <Divider className="mb-2"/>*/}
  </div>
);

export default MultiSelectionItem;
