/**
 * Chat App Reducers
 */

// Notices types
import {
    GET_NOTICES_LIST,
    GET_NOTICES_LIST_SUCCESS,
    GET_NOTICES_LIST_FAILED,
    HANDLE_NOTICE_PANEL,
    FETCH_NOTICE_FORM,
    FETCH_NOTICE_FORM_SUCESS,
    FETCH_NOTICE_FORM_FAILED,
    CLEAR_NOTICES_DATA,
    FETCH_NOTICE_HISTORY,
    FETCH_NOTICE_HISTORY_SUCCESS,
    FETCH_NOTICE_HISTORY_FAILED,
    SUBMIT_NOTICE,
    SUBMIT_NOTICE_SUCCESS,
    SUBMIT_NOTICE_FAILED,
    GET_NOTICE_POST,
    GET_NOTICE_POST_SUCCESS,
    GET_NOTICE_POST_FAILED,
    SET_SELECTED_NOTICE
} from '../actions/types';

const INITIAL_STATE = {    
   noticeLoading: false,
   notices: [],
   noticePanel: false,
   selectedUserId: null,
   formLoading: false,
   noticeFormData: null,
   noticeHistoryLoading: false,
   noticesHistory: null,
   noticePost: null,
   noticePostLoading: false,
   selectedNotice:null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTICES_LIST:
            return { ...state, noticeLoading: true,  notices: []};
        case GET_NOTICES_LIST_SUCCESS:
            return { ...state, noticeLoading: false, notices: action.payload };
        case GET_NOTICES_LIST_FAILED:
                return { ...state, noticeLoading: false};
        case HANDLE_NOTICE_PANEL:
            return { ...state, selectedUserId: action.payload.userId, noticePanel: action.payload.show}; 
        case FETCH_NOTICE_FORM:
            return { ...state, formLoading: true}; 
        case FETCH_NOTICE_FORM_SUCESS:
            return { ...state, formLoading: false, noticeFormData: action.payload}; 
        case FETCH_NOTICE_FORM_FAILED:
            return { ...state, formLoading: false};
        case FETCH_NOTICE_HISTORY:
            return { ...state, noticeHistoryLoading: true, noticesHistory:null};  
        case FETCH_NOTICE_HISTORY_SUCCESS:
            return { ...state, noticeHistoryLoading: false, noticesHistory: action.payload};
        case FETCH_NOTICE_HISTORY_FAILED:
            return { ...state, noticeHistoryLoading: false};
        case SUBMIT_NOTICE:
            return { ...state, formLoading: true};
        case SUBMIT_NOTICE_SUCCESS:
            return { ...state, formLoading: false};
        case SUBMIT_NOTICE_FAILED:
            return { ...state, formLoading: false};
        case GET_NOTICE_POST:
            return { ...state, noticePostLoading: true, noticePost: null};
        case GET_NOTICE_POST_SUCCESS:
            return { ...state, noticePostLoading: false, noticePost: action.payload};
        case GET_NOTICE_POST_FAILED:
            return { ...state, noticePostLoading: false};
        case SET_SELECTED_NOTICE:
            return { ...state, selectedNotice: action.payload};
        case CLEAR_NOTICES_DATA:
            return { 
                ...state, 
                noticeLoading: false,
                notices: [],
                noticePanel: false,
                selectedUserId: null,
                formLoading: false,
                noticeFormData: null,
                noticeHistoryLoading: false,
                noticesHistory: null,
                noticePost: null,
                noticePostLoading: false,
            };      
        
        default: return { ...state };
      
    } 
}
