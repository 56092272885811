import React, { Component } from "react";
import LifeSherpaLoading from '../LifeSherpaLoading';
import { getLmsLoginUrl, setLmsLogoutUrl , lmsOpenedInIframe} from "../../actions";
import { connect } from 'react-redux';
import Iframe from "./iframe.js";

class CoursesLmsiFrame extends Component {
    state = {
        loading: true,
    }
   componentDidMount() {
       this.props.lmsOpenedInIframe();
   }
    hideSpinner = () => {
        let { launchURL, myCourseLoading, selectedLanuchPad } = this.props;
        if(launchURL && launchURL.url) {
            console.log("%c LMS with selected course loaded successfully............","color:green");
            this.setState({ ...this.state, loading: false });
        }
        if(selectedLanuchPad && selectedLanuchPad.url) {
            console.log("%c Lanuchpad loaded successfully............","color:green");
            this.setState({ ...this.state, loading: false });
        }
    }

    render() {
        let { launchURL, myCourseLoading, selectedLanuchPad } = this.props;
        const goToURL = (launchURL && launchURL.url) || (selectedLanuchPad && selectedLanuchPad.url) || "";
        let { loading } = this.state;
        //console.log({selectedLanuchPad, launchURL, goToURL});
        return (
            <React.Fragment>
                <LifeSherpaLoading loading={loading} />
                    <div style={{ visibility: loading ? "hidden" : "visible" }}>
                        <Iframe
                            url={goToURL}
                            onLoad={this.hideSpinner}
                            height="calc(100vh)"
                            scrolling="yes"
                            id="myId"
                            className="w-100"
                            display="initial"
                            position="relative"
                            allowFullScreen
                            />
                    </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = ({ MyCourses }) => {
    const { myCourseLoading, launchURL, selectedLanuchPad } = MyCourses;
    return { myCourseLoading, launchURL, selectedLanuchPad }
}

export default connect(mapStateToProps, {
    getLmsLoginUrl, setLmsLogoutUrl, lmsOpenedInIframe
})(CoursesLmsiFrame)
