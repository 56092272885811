/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import LifeSherpaLoading from '../components/LifeSherpaLoading';
import QueueAnim from 'rc-queue-anim';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert'

// app config
import AppConfig from '../constants/AppConfig';

// redux action
import {
   validateInviteCode,
   getPrivacyPlocy,
   getUserDetailFromToken,
   clearValidateInviteCodeError
} from '../actions';

const checkBoxStyle = {
   position: 'absolute',
   marginTop: '0.1rem',
   marginLeft: '-1.7rem',
   height: 20,
   width: 20
}

class SignInFirebaseToken extends Component {

   state = {
      email: 'ajayspecial0502@gmail.com', //'demo@example.com',
      password: 'Abcd1234', //'test#123',
      isTokenEmpty: false,
      token: '',
      enabledPrivacy: null,
      showError: null,
      loginAlert:false
   }

   componentDidMount(){
      this.props.getPrivacyPlocy("org")
      //    let params = null
      //    if (this.props.location.search) {
      //        params = queryString.parse(this.props.location.search)
      //        if(params.debug){}
      //        else this.setState({loginAlert:true})
      //        //this.props.history.push('/')
      //   }else this.setState({loginAlert:true})
        //this.props.history.push('/')
      const showTokenInvalid = this.props.status && this.props.status === 'failed' ? true : false;
      if(showTokenInvalid)
      this.props.clearValidateInviteCodeError();
   }

   handlePrivacypolicy (e) {
      this.setState({enabledPrivacy: e.target.checked, showError: !e.target.checked})
   }

	/**
	 * On User Login
	 */
   /*onTokenVerify(e) {
      e.preventDefault();
      if (this.state.token === ''){
         this.setState({isTokenEmpty: true})
      } else if(!this.state.enabledPrivacy){
        this.setState({showError: true})
      }
      else {
         this.props.validateInviteCode(this.state.token, this.props.history);
      //   this.props.history.push('/signuplifesherpa');
      }
   }*/
   onTokenVerify(e) {
      e.preventDefault();
      if (this.state.token === ''){
         this.setState({isTokenEmpty: true})
      } else if(!this.state.enabledPrivacy){
        this.setState({showError: true})
      }
         else {
            this.props.validateInviteCode(this.state.token, this.props.history);
            this.props.getUserDetailFromToken(this.state.token, this.props.history);
         }
      }
      
   handleBack=()=>{
      this.setState({loginAlert:false})
          this.props.history.push('/')
   }
   render() {
      const { token, isTokenEmpty, enabledPrivacy, showError } = this.state;
      const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 
      const { loading,screenThemeClass } = this.props;
      const isContinueEnabled = (isTokenEmpty === null || showError === null || (token === null || token === '') ) ? true :  isTokenEmpty || showError
      const showTokenInvalid = this.props.status && this.props.status === 'failed' && this.props.inviteCode && (this.props.inviteCode === token) ? true : false
     
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            <LifeSherpaLoading loading={loading} />
            {this.state.loginAlert&&
                  <div className="col-12 p-4">
                     <SweetAlert
                        warning
                        btnSize="sm"
                        customClass="warningText"
                        confirmBtnText="OK"
                        confirmBtnBsStyle="warning"
                        title="Login"
                        onConfirm={()=>this.handleBack()}
                     >
                        <div style={{fontFamily:"'Lato'",fontWeight:400}}>Not a valid user!</div>
                     </SweetAlert>
                  </div>}
            </div>
            <div className={`rct-session-wrapper ${screenThemeClass}`} data-testid="signin-with-token-screen">
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <div className="container">
                        <div className="d-flex justify-content-between">
                           <div className="session-logo">
                              <Link to="/">
                                 <img src={applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img' />
                              </Link>
                           </div>
                           <div>
                              <Link to="/launchscreen" className="mr-15 text-white">Already have an account?</Link>
                              <Button
                                 component={Link}
                                 to="/launchscreen"
                                 variant="contained"
                                 className="btn-light"
                                 data-testid="signin-button"
                              >
                                 Sign In
										</Button>
                           </div>
                        </div>
                     </div>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper" >
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className={"session-body text-center text-white"}>
                              <div className="session-head mb-30">
                                 <p style={{color:"gray",fontSize:18+"px"}}>The invitation token you have been given is unique to you and should not be shared with any other person.</p>
                                 <p style={{color:"gray",fontSize:18+"px"}}>{"Your use of the application is subject to your acceptance of our "} <a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a></p>
                                 <p style={{color:"gray",fontSize:18+"px"}}>Please enter the token below and press Continue to complete your one-time registration process and to create your login credentials.</p>
                                </div>
                              <Form onKeyPress={e => {
                                 if (e.key === 'Enter') this.onTokenVerify(e);
                                 if (this.props.status && this.props.status === 'failed') this.props.clearValidateInviteCodeError();
                               }}>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="text"
                                       value={token}
                                       data-testid="token-text-field"
                                       name="user-token"
                                       autoCapitalize="none"
                                       id="user-token"
                                       className="has-input input-lg text-center text-dark"
                                       placeholder="Enter Token Here"
                                       style={{fontSize:18+"px"}}
                                       invalid={(isTokenEmpty ? true : false) || showTokenInvalid}
                                       onKeyPress={e => {
                                          if (e.key === 'Enter') this.onTokenVerify(e);
                                        }}
                                       onChange={(event) => this.setState({ token: event.target.value, isTokenEmpty: event.target.value !== '' ? false : true })}
                                    />
                                    {isTokenEmpty ?
                                        <FormFeedback style={{fontSize:18+"px"}}>You must supply a valid invitation token to continue</FormFeedback>
                                        : showTokenInvalid &&
                                        <FormFeedback className="d-block" style={{fontSize:18+"px"}}>The invitation token provided is not valid</FormFeedback>
                                    }
                                 </FormGroup>
                                 <FormGroup>
                                    <Button
                                       className="btn-block text-white w-100 "
                                       variant="contained"
                                       data-testid="continue-botton"
                                       size="large"
                                       onClick={(e) => this.onTokenVerify(e)}
                                       style={isContinueEnabled ? {backgroundColor: 'gray', fontSize: 18+'px'} : {backgroundColor:'#008C95',fontSize: 18+'px'}}
                                       disabled={isContinueEnabled}
                                    >
                                       Continue
                                        </Button>
                                 </FormGroup>
                                 <FormGroup className={`has-wrapper border p-2 text-gray border-primary`}>
                                    <Label check className="pl-3" style={{fontSize:18+"px",color:"gray"}}>
                                        <Input data-testid="privacy-policy-checkbox" checked={enabledPrivacy ? enabledPrivacy : false} type="checkbox" id="checkbox1" style={checkBoxStyle} onChange={(e) => this.handlePrivacypolicy(e)} />{' '}
                                        {'I have reviewed & accepted the '}<a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a>
                                    </Label>
                                    {showError && <FormFeedback data-testid="privacy-policy-checkbox-error" className="d-block" style={{fontSize:18+"px"}}>Please acknowledge your consent to the Privacy Policy to continue</FormFeedback>}
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser,settings }) => {
   const { user, loading, inviteCode, status, privacyPolicy } = authUser;
   const { screenThemeClass, popupThemeClass } = settings; 
   return { user, loading, inviteCode, status, privacyPolicy,screenThemeClass}
}

export default withRouter(connect(mapStateToProps, {
   validateInviteCode,
   getPrivacyPlocy,
   getUserDetailFromToken,
   clearValidateInviteCodeError
})(SignInFirebaseToken));
