import {
    GET_WORKFLOW_LIST,
    GET_SUCCESS_WORKFLOW_LIST,
    START_WORKFLOW,
    START_SUCCESS_WORKFLOW,
    GET_ASSIGNABLE_WORKFLOW_USER,
    GET_SUCCESS_ASSIGNABLE_WORKFLOW_USER,
    SHOW_WORKFLOW_LIST_LOADING,
    HIDE_WORKFLOW_LIST_LOADING,
    SHOW_START_WORKFLOW_PANEL,
    HIDE_START_WORKFLOW_PANEL,
    GET_WORKFLOW_EXECUTION_HISTORY,
    GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY,
    ASSIGN_USER_TO_PENDING_WORKFLOW_EXECUTION,
    ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION,
    ASSIGN_USER_TO_PNDING_WORKFLOW_EXECUTION_FAILED,
    START_WORKFLOW_FAILED,
    GET_USER_LIST_NOT_ACTIVE_IN_WORKFLOW,
    GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW,
    SHOW_WORKFLOW_HISTORY_PANEL,
    SET_SELECTED_WORKFLOW_ITEM,
    HANDLE_USER_WORKFLOW_PANEL,
    GET_USER_PENDING_TASK_WORKFLOW_LIST,
    GET_SUCCESS_USER_PENDING_TASK_WORKFLOW_LIST,
    HIDE_WORKFLOW_LOADING_BY_USER,
    CLEAR_ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION,
    CLEAR_START_SUCCESS_WORKFLOW,
    CLEAR_WORKFLOW_ERROR_RESPONSE,
    HIDE_WORKFLOW_NOTICES,
    GET_WORKFLOW_NOTICES,
    GET_SUCCESS_WORKFLOW_NOTICES,
    CANCEL_WORKFLOW_INSTANCE,
    CANCEL_SUCCESS_WORKFLOW_INSTANCE,
    SKIP_WORKFLOW_INSTANCE_TASKS,
    SKIP_SUCCESS_WORKFLOW_INSTANCE_TASKS,
    HIDE_WORKFLOW_CANCEL_LOADING,
    HIDE_WORKFLOW_SKIP_TASK_LOADING,
    OPEN_CANCEL_WORKFLOW_CONFIRMATION,
    OPEN_SKIP_WORKFLOW_TASK,
    GET_WORKFLOW_EXECUTION_DEATIL,
    GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL,
    HIDE_WORKFLOW_HISTROY_DETAIL_LOADING,
    CLEAR_WORKFLOW_HISTORY_DETAIL_DATA,
    GET_WORKFLOW_CHART_DATA,
    GET_SUCCESS_WORKFLOW_CHART_DATA,
    HIDE_WORKFLOW_WORKFLOW_CHART_DATA_LOADING,
    SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL,
    GET_SUB_WORKFLOW_EXECUTION_DEATIL,
    GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL,
    SET_SELECTED_SUB_WORKFLOW_ITEM,
} from '../actions/types';

const INIT_STATE = {
    workflowLoading:false,
    workflowslist:null,
    workflowExecution: null,
    assignableUsersByRoles: null,
    startWorkflowLoading: false,
    startWorkflowPanel: false,
    selectedWorkflow: null,
    workflowHistory: null,
    workflowHistoryLoading: false,
    assignedUsers: null,
    showWorkflowHistory: false,
    noActiveWorkflowLoading: false,
    userHasNoActiveWorkflow: null,
    fetchAssigneeLoading:false,
    openWorkflowPendingList: false,
    selectedWorkflowItem: null,
    userWorkflowPanel: null,
    selectedUserId: null,
    userWorkflowLoading: false,
    userWorkflows: null,
    workflowErrorResponse: null,
    workflowNotices: null,
    cancelWorkflow: null,
    cancelWorkflowLoading: false,
    skipWorkflowTasks: null,
    skipWorkflowTasksLoading: false,
    openSkipTaskview: false, 
    workflowCancelConfrimation: false,
    selectedOrgId: null,
    workflowExecutionDetail: null,
    workflowDetailLoading: false,
    workflowChartLoading: false,
    workflowChartData: null,
    showPendingRolePanel: false,
    selectedWorkflowStatus: null,
    subWorkflowDetailData: null,
    selectedSubWorkflow: null,
    subWorkflowLoading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WORKFLOW_LIST:
            return { ...state,workflowLoading:true, workflowslist: null };
        case GET_SUCCESS_WORKFLOW_LIST:
            return {...state,workflowslist:action.payload, workflowLoading:false}
        case START_WORKFLOW:
            return { ...state, startWorkflowLoading:true, workflowErrorResponse: null };
        case START_SUCCESS_WORKFLOW:
            return { ...state, workflowExecution: action.payload, startWorkflowLoading:false, startWorkflowPanel: false, selectedWorkflow: null};
        case  GET_ASSIGNABLE_WORKFLOW_USER:
            return { ...state, fetchAssigneeLoading:true , assignableUsersByRoles: null};
        case GET_SUCCESS_ASSIGNABLE_WORKFLOW_USER:
            return { ...state, assignableUsersByRoles: action.payload, fetchAssigneeLoading:false};
        case SHOW_WORKFLOW_LIST_LOADING:
            return {...state, workflowLoading: true}
        case HIDE_WORKFLOW_LIST_LOADING:
            return {...state, workflowLoading: false, startWorkflowLoading: false, workflowHistoryLoading: false, noActiveWorkflowLoading: false, fetchAssigneeLoading: false}
        case SHOW_START_WORKFLOW_PANEL:
            const {workflow, openWorkflowPendingList} = action.payload
            return {...state, startWorkflowPanel: true, selectedWorkflow: workflow, openWorkflowPendingList, workflowErrorResponse: null}
        case HIDE_START_WORKFLOW_PANEL:
            return {...state,startWorkflowPanel: false, selectedWorkflow: null, workflowHistoryLoading: false, openWorkflowPendingList: false, showWorkflowHistory: false, selectedWorkflowItem: null, workflowHistory: null, noActiveWorkflowLoading: false, workflowErrorResponse: null, showPendingRolePanel: false, selectedWorkflowStatus: null}
        case GET_WORKFLOW_EXECUTION_HISTORY: 
            return {...state, workflowHistoryLoading: true, workflowExecutionDetail: null}
        case GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY: 
            return {...state, workflowHistory: action.payload, workflowHistoryLoading: false}
        case ASSIGN_USER_TO_PENDING_WORKFLOW_EXECUTION: 
            return {...state, workflowHistoryLoading: true, workflowErrorResponse: null}
        case ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION: 
            return {...state, assignedUsers: action.payload, workflowHistoryLoading: false}
        case ASSIGN_USER_TO_PNDING_WORKFLOW_EXECUTION_FAILED: 
            return {...state, workflowErrorResponse: action.payload, workflowHistoryLoading: false}
        case START_WORKFLOW_FAILED: 
            return {...state, workflowErrorResponse: action.payload, startWorkflowLoading: false,}
        case GET_USER_LIST_NOT_ACTIVE_IN_WORKFLOW:
            return {...state, noActiveWorkflowLoading: true}
        case GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW:
            return {...state, noActiveWorkflowLoading: false, userHasNoActiveWorkflow: action.payload}
        case SHOW_WORKFLOW_HISTORY_PANEL:
            const {selectedWorkflow, openPanel, selectedWorkflowStatus} = action.payload
            return {...state, showWorkflowHistory: openPanel, selectedWorkflow, selectedWorkflowStatus}
        case SET_SELECTED_WORKFLOW_ITEM:
            return {...state, selectedWorkflowItem: action.payload}
        case HANDLE_USER_WORKFLOW_PANEL: 
            const {userId, orgId, show } = action.payload || {userId: null, orgId: null, show: false}
            return { ...state, selectedUserId: userId, selectedOrgId: orgId, userWorkflowPanel: show}; 
        case GET_USER_PENDING_TASK_WORKFLOW_LIST:
            return {...state, userWorkflowLoading: true, userWorkflows: null}
        case GET_SUCCESS_USER_PENDING_TASK_WORKFLOW_LIST:
            return {...state, userWorkflows: action.payload, userWorkflowLoading: false}
        case HIDE_WORKFLOW_LOADING_BY_USER:
            return {...state, userWorkflowLoading: false}
        case CLEAR_ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION:
            return {...state, assignedUsers: null, workflowHistoryLoading: false, startWorkflowPanel: false}
        case CLEAR_START_SUCCESS_WORKFLOW:
            return { ...state, workflowExecution: null, startWorkflowLoading:false, startWorkflowPanel: false};
        case CLEAR_WORKFLOW_ERROR_RESPONSE:
            return { ...state, workflowErrorResponse: null};
        case HIDE_WORKFLOW_NOTICES: 
            return { ...state, workflowNoticeLoading: false};
        case GET_WORKFLOW_NOTICES: 
            return { ...state, workflowNoticeLoading: true};
        case GET_SUCCESS_WORKFLOW_NOTICES:
            return { ...state, workflowNoticeLoading: false, workflowNotices: action.payload};
        case CANCEL_WORKFLOW_INSTANCE:
            return { ...state, cancelWorkflowLoading: true};
        case CANCEL_SUCCESS_WORKFLOW_INSTANCE:
            return { ...state, cancelWorkflowLoading: false, cancelWorkflow: action.payload};
        case SKIP_WORKFLOW_INSTANCE_TASKS:
            return { ...state, skipWorkflowTasksLoading: true};
        case SKIP_SUCCESS_WORKFLOW_INSTANCE_TASKS:
            return { ...state, skipWorkflowTasksLoading: false, skipWorkflowTasks: action.payload};
        case HIDE_WORKFLOW_CANCEL_LOADING:
            return { ...state, cancelWorkflowLoading: false, };
        case HIDE_WORKFLOW_SKIP_TASK_LOADING:
            return { ...state, skipWorkflowTasksLoading: false};
        case OPEN_CANCEL_WORKFLOW_CONFIRMATION:
            return { ...state, workflowCancelConfrimation: action.payload};
        case OPEN_SKIP_WORKFLOW_TASK:
            return { ...state, openSkipTaskview: action.payload};
        case GET_WORKFLOW_EXECUTION_DEATIL: 
            return { ...state, workflowDetailLoading: true, workflowExecutionDetail: null};
        case GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL:
            return { ...state, workflowDetailLoading: false, workflowExecutionDetail: action.payload, subWorkflowDetailData: null };
        case HIDE_WORKFLOW_HISTROY_DETAIL_LOADING:
            return { ...state, workflowDetailLoading: false, subWorkflowLoading: false};
        case CLEAR_WORKFLOW_HISTORY_DETAIL_DATA:
            return { ...state, workflowDetailLoading: false, workflowExecutionDetail: null, selectedSubWorkflow: null, subWorkflowDetailData: null};
        case  GET_WORKFLOW_CHART_DATA:
            return { ...state, workflowChartLoading: true};
        case GET_SUCCESS_WORKFLOW_CHART_DATA:
            return { ...state, workflowChartLoading: false, workflowChartData: action.payload};
        case HIDE_WORKFLOW_WORKFLOW_CHART_DATA_LOADING:
            return { ...state, workflowChartLoading: false};
        case SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL:
            return {...state, showPendingRolePanel: action.payload}
        case GET_SUB_WORKFLOW_EXECUTION_DEATIL:
            return { ...state, subWorkflowLoading: true, subWorkflowDetailData: null};
        case GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL:
            return { ...state, subWorkflowLoading: false, subWorkflowDetailData: action.payload};
        case SET_SELECTED_SUB_WORKFLOW_ITEM:
            return { ...state, selectedSubWorkflow: action.payload};
        default: 
            return { ...state };
    }
}