/**
 * User List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import LifeSherpaLoading from '../../../components/LifeSherpaLoading';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

// components
import RecentChatUsers from './RecentChatUsers';
import TouchAppIcon from '@mui/icons-material/TouchApp';
// redux actions
import {
    chatWithSelectedUser,
    updateUsersSearch,
    onSearchUsers,
    hideGroupListLoading,
    getResourceList,
    getcategories, getKeywords,
    changeRole,
    addSelectedChatUser,
    networkActivity,
    getGroupPokeForm,
    setsavePokeForGroup,
} from '../../../actions';
import { IconButton } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import ActivityConfirmForm from '../../../components/ActivityForm/ActivityConfirmForm'
import ActivitiesFilterPopup from '../../../components/Widgets/ActivitiesFilterPopup';
import { Label } from 'reactstrap';

class ClientsList extends Component {

    /**
     * Swicth Chat With User
     * @param {*object} user
     */
    state = {
        openPopup: false,
        openForm: false,
        openOrgFilter: false,
        search: ''
    }

    handlePokeForm() {
        const {pokeFormId} = this.props
        this.props.setsavePokeForGroup(!this.state.openForm) 
        if (!this.state.openForm){
            this.setState({openForm: true})
            this.props.getGroupPokeForm(pokeFormId)
        } else {
            this.setState({openForm: false})
        }
    }

    switchChatWithUser(user) {
        this.props.chatWithSelectedUser(user);
    }

    showChatPanelForSelectedUser = (chatUser) => {
        let {selectedChatUsers} = this.props;
        console.log(" Chat opening for user ->",chatUser);
        if(selectedChatUsers && selectedChatUsers.length > 0) {
            let index = selectedChatUsers.findIndex(user => user && user.userId == chatUser.userId);
            if(index >= 0) {
                // selectedChatUsers.splice(index,1)
                // this.props.addSelectedChatUser(selectedChatUsers);
                // selectedChatUsers.push(chatUser);  
            } else {
                selectedChatUsers.push(chatUser);
            }
        } else {
            let usersList = [];
            usersList.push(chatUser);
            selectedChatUsers = usersList;
        }

        console.log(" Chat opened ->",selectedChatUsers);
        this.props.addSelectedChatUser(selectedChatUsers);
    }

    componentDidMount() {
        console.log("%c[ClientsList]%c Rendered all clients panel on Home Screen","color:black","color:green");
    }

    getScrollHeight() { 
        if (localStorage.getItem("widgetApp")) {
            return "calc(100vh - 150px)"
        } else {
            return '515px';
        }
    }

    handleOpenPopup = () => {
        let auth_uid = localStorage.getItem('auth_uid');
        const orgId = localStorage.getItem("orgId")
        //const orgIds = localStorage.getItem("orgIds")? localStorage.getItem("orgIds").split(",") : null
        if (this.state.openPopup) {
            localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            console.log("admin mode.......", auth_uid);
            this.props.changeRole({ role: 'admin', id: auth_uid });
            this.props.getResourceList('', '', '');
            this.props.getcategories();
            this.props.getKeywords();
        }
        this.setState({ openPopup: !this.state.openPopup });
    }

    handleOpenFilter(){
        const {openOrgFilter} = this.state
        this.setState({openOrgFilter: !openOrgFilter})
    }

    handelSearch = (e) => {
        // console.log(e);
        this.setState({...this.state, search:e.target.value});
    }

    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }

    getGroupMembersList(search) {
        let {groupMembersDetails, contactList, groupMembers} = this.props
        let groupMembersList = [];
		if (groupMembers !== null && groupMembersDetails) {
			contactList && contactList.map((contact) => {
				if(groupMembers[contact.userId]) {
					groupMembers[contact.userId].chatUser = contact;
				}
			});
            for(const client of groupMembersDetails) {
                const {userId, organization, groupId, name, profileImageURL, groupsName} = client;
                let data = groupMembers[groupId] && groupMembers[groupId][userId];
                if (data) {
                    data.userId = userId;
                    data.organization = organization;
                    data.groupId = groupId;
                    data.profileImageURL = profileImageURL;
                    data.groupsName = groupsName
                    data.name = name || (data.chatUser && data.chatUser.name) || "Display Name";
                    // if(!groupMembersList[groupId]) {
                    //     groupMembersList[groupId] = [];
                    // }
                    groupMembersList.push(data);
                }
            }
		
            // for(const groupId in groupMembersList) {
                groupMembersList.sort((details1, details2) => {
                    return details1.name.localeCompare(details2.name);
                })
                if (search && search.length > 2 && groupMembersList.length > 0) {
                    groupMembersList = groupMembersList.filter((member) => (member.name.toLowerCase().includes(search.toLowerCase())))
                } 
                // if(!groupMembersList.length) {
                //     delete groupMembersList[groupId];
                // }
            // }
		}
        
        return groupMembersList
    }
    
    render() {
        const { groups, loading, selectedMember, groupMembersDetails, pokeFormId, groupPokeForm, groupPokeFormLoading, organizations, clientPanelOrgFiltered, contactList, selectedGroup, groupMembers } = this.props;
        const {openOrgFilter, search} = this.state

        const groupMembersList = this.getGroupMembersList(search);
        console.log("groupMembers :", this.props.groupMembers);
        console.log("groupMembersDetails :", groupMembersDetails);
        const filterApplied = (clientPanelOrgFiltered && clientPanelOrgFiltered.length > 0) || (selectedGroup && selectedGroup.length > 0) ? true : false
        
        return (
            <div data-testid="my-clients-list-panel">
                {
                    <React.Fragment>
                        <div className="p-3 list-card">
                            {
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className={`d-flex ${filterApplied ? "activity-filter-icon" : "activity-filter-icon-applied"} mr-2`}>
                                        <IconButton data-testid="orgId-filter-icon-button" title="Filter By Groups" className="text-white rounded-circle mr-2" onClick={() => this.handleOpenFilter()}>
                                            <FilterAlt data-testid="orgId-filter-enabled" className="filter-icon" fontSize="large"/>
                                        </IconButton>
                                    </div>
                                    <div className="mx-2 search-activity-field">
                                        <form noValidate autoComplete="off">
                                            <TextField data-testid="my-client-search-text-field" id="standard-search" 
                                                onChange={(e) => this.handelSearch(e)}
                                                label="Search Client" 
                                                type="search" 
                                                value={search} 
                                                InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary"/>
                                                </InputAdornment>
                                                ),
                                                endAdornment: ( search && search.length > 0 ?
                                                    <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                                    </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                                )
                                                }}
                                                variant="standard" 
                                            />
                                            <Label className='mb-0 mt-2' style={{fontWeight: 'bold'}}>{`${filterApplied ? "Selected" : "Total"} Clients (${groupMembersDetails && groupMembersDetails.length > 0 ? groupMembersDetails.length : 0})`}</Label>
                                        </form>
                                     </div>
                                    {pokeFormId ?
                                        <div className='activity-filter-icon-applied'>
                                            <IconButton onClick={() => this.handlePokeForm()} title='Poke group members' className='ml-2'>
                                                <TouchAppIcon fontSize='large'/>
                                            </IconButton>
                                        </div>
                                        : <></>
                                    }
                                </div>
                            }
                        </div>
                      
                        <div className="chat-list ">
                            <LifeSherpaLoading loading={loading} />
                            <Scrollbars
                                className="rct-scroll"
                                autoHide
                                style={{ height: this.getScrollHeight() }}
                                >
                                <List className="p-0 mb-0 mt-1" style={{ minHeight: 410 + "px" }}>
                                    { groupMembersList.length ?
                                        <RecentChatUsers
                                            open={this.props.openPopup}
                                            openPopup={this.state.openPopup}
                                            handleClose={this.props.handleOpenPopup}
                                            groupMembersDetails={groupMembersDetails}
                                            selectedMember={selectedMember}
                                            openForm={this.props.openForm}
                                            handleOpenForm={this.props.handleOpenForm}
                                            handleOpenSherpaPopup={this.props.handleOpenPopup}
                                            handleOpenPopup={this.handleOpenPopup}
                                            showUserDetails={this.props.showUserDetails}
                                            handleOpenAssingActivity={this.props.handleOpenAssingActivity}
                                            showChatPanelForSelectedUser={this.showChatPanelForSelectedUser}
                                            groupMembersList={groupMembersList}
                                            loading={loading}
                                        />
                                        : !loading ?
                                        <div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
                                            <h4>No Group member Found</h4>
                                        </div>
                                        : 
                                        <div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
                                            <h4>Fetching Members...</h4>
                                        </div>
                                    }
                                </List>
                      
                            </Scrollbars>
                        </div>
                        {
                            <ActivityConfirmForm
                                open={this.state.openForm ? this.state.openForm : groupPokeFormLoading}
                                handleClose={this.handlePokeForm.bind(this)}
                                loading={groupPokeFormLoading}
                                pokeForm={groupPokeForm}
                                selectedGroup={this.props.selectedGroup}
                                isPokeForGroup={true}
                                formId={pokeFormId}
                                title={this.props.pokeForm && this.props.pokeForm.formName ? this.props.pokeForm.formName : null}
                                description={this.props.pokeForm && this.props.pokeForm.description ? this.props.pokeForm.description : null}
                            />
                        }
                        {openOrgFilter ?
                            <ActivitiesFilterPopup
                                title={"Clients Filter"}
                                showFilter={openOrgFilter}
                                handleCloseFilter={this.handleOpenFilter.bind(this)}
                                panel="client"
                            />
                            : <></>
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ GroupList, chatAppReducer, Contacts, PokeForm, authUser, clientsList}) => {
    let { groups, selectedGroup, loading, groupMembers, selectedMember, groupMembersDetails } = GroupList
    const {  selectedChatUsers } = chatAppReducer;
    const { pokeForm, isPokeForGroup, groupPokeForm, groupPokeFormLoading } = PokeForm
    let pokeFormLoading = PokeForm.loading
    const {pokeFormId} = authUser
    const {organizations, clientPanelOrgFiltered} = clientsList
    const {contactList} = Contacts;
    return { groups, groupPokeForm, groupPokeFormLoading, selectedGroup, loading, groupMembers, selectedMember, groupMembersDetails, selectedChatUsers, pokeFormLoading, pokeForm, isPokeForGroup, pokeFormId, organizations, clientPanelOrgFiltered, contactList };
};

export default withRouter(connect(mapStateToProps, {
    chatWithSelectedUser,
    updateUsersSearch,
    onSearchUsers,
    hideGroupListLoading,
    getResourceList,
    getcategories, getKeywords,
    changeRole,
    addSelectedChatUser,
    networkActivity,
    getGroupPokeForm,
    setsavePokeForGroup,
})(ClientsList));
;
