/**
 * Support Request
 */
import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
 import IconButton from '@mui/material/IconButton';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import { Scrollbars } from 'react-custom-scrollbars';
//  import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

class UserCourses extends Component {

   componentDidMount() {
      console.log("%c[UserCourses]%c Rendered selected user courses panel","color:black","color:green");
   }

   getScrollHeight() {
      if (localStorage.getItem("widgetApp")) {
         return 'calc(100vh)';
     } else {
         return '630px';
     }
   }

   render() {
      const { launchpad, myCourseLoading, myCourses, getCourseLaunchURL } = this.props;
      return (
         <div className="support-widget-wrap">
            <div>
               <LifeSherpaLoading loading={ myCourseLoading} />
            </div>
            <Scrollbars
               className="rct-scroll list-sherpa-activity"
               autoHide
               style={{ height: this.getScrollHeight() }}
               >
               <List data-testid="details-panel-user-courses" className="list-unstyled p-0">
                  {myCourses && myCourses.length > 0 ? myCourses.map((course, key) => (
                     <ListItem key={key} className={`${key % 2 === 0 ? "styleToCorseItem" : " defaultStyleToCorseItem"} px-15 py-0 d-flex justify-content-between align-content-center`}>
                        <div data-testid={`details-panel-user-course${key}`} className="d-flex justify-content-between w-100 align-items-center pt-2 pb-2">
                           <div className="media align-items-center w-90">
                              <div className="media-left position-relative mr-10">
                                 <img src={course.avatar ? course.avatar : 'https://d3j0t7vrtr92dk.cloudfront.net/images/unknown_small.png'} className="img-fluid rounded-circle" alt="pic" width="40" height="40" />
                              </div>
                              <div className="media-body">
                                 <h4 className="mb-0">{course.name}</h4>
                              </div>
                           </div>
                           <div className="text-right msg-count d-flex align-items-center">
                              <Badge color={course.completion_status_formatted === "Completed" ? "success" : course.completion_status_formatted === "Not started" ? "danger" : "warning"} className="px-4 font-md">{course.completion_status_formatted}</Badge>
                              { /*<IconButton color="default" onClick={() => getCourseLaunchURL(course.id)}>
                                       <VisibilityRoundedIcon />
                                 </IconButton>*/}
                           </div>
                        </div>
                     </ListItem>
                  ))
                     :
                     <div className="d-flex justify-content-center align-content-center MuiListItem-gutters p-3"><p className="mb-0 content-title font-md no-data-found">There is no course available!</p></div>
                  }
               </List>
            </Scrollbars>
         </div>
      );
   }
}

export default UserCourses;
