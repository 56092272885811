/**
 * App.js Layout Start Here
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {scrollFunctionByClass} from '../helpers/helpers';
import LifeSherpaLoading from '../components/LifeSherpaLoading';
// rct theme provider
import RctThemeProvider from '../container/RctThemeProvider';
import RctAppLayout from '../components/RctAppLayout';
import WidgetController from "./WidgetController";
import UIFallbackScreen from '../components/UIFallbackScreen';
import { eraseLocalStorage } from '../actions';
import jwt from 'jwt-decode';
import queryString from 'query-string';


class App extends Component {
   state = {
      userAuthenticated: undefined,
      hasError:false,
      loading:false,
   }
   static getDerivedStateFromError(error) {
      return { hasError: true };
   }

   componentDidCatch(error, info) {
      console.log("%cThis is something that's need to be fixed","color:blue");  
      console.log({ error, info });
   }

   UNSAFE_componentWillMount() {
     this.props.eraseLocalStorage();
   }
    
   componentDidMount() {
         this.setState({loading:true}, () => {
         const {userId, idToken, orgId, auth0Token, region, widget, selector} = this.props.propsData;
         console.log("props ->", userId, idToken, orgId, auth0Token, region, widget, selector)
         
         if(idToken) {
            localStorage.setItem("idToken", idToken);
            try {
               const decodedToken = jwt(idToken) || {}; 
               const loggedInUserId = decodedToken.user_id || "";
               localStorage.setItem("auth_uid", loggedInUserId)
            } catch(error) {
               console.log("invalid token provided : ", error);
               localStorage.setItem("auth_uid", userId);
            } 
         }
         if(auth0Token) {
            localStorage.setItem("auth0Token", auth0Token);
            localStorage.setItem("auth_uid", userId);
         }
         
         localStorage.setItem("uid", userId)
         localStorage.setItem("orgId", orgId)
         localStorage.setItem("selectedOrgId", orgId)
         localStorage.setItem("organization", orgId);
         localStorage.setItem("region", region);
         localStorage.setItem("widgetApp", widget)
         this.setState({loading:false});
      });
      
      window.onscroll = function() {scrollFunctionByClass("rct-scroll")};
      window.addEventListener('message', (event) => { 
         console.log("Message received from native app", event);
         if(event.data && typeof event.data === "string") {
            try {
               jwt(event.data); 
               const {pathname, search} = this.props.location;
               const params = queryString.parse(search);
               params.idToken = event.data;
               const stringified = queryString.stringify(params);
               const url = window.location.origin + pathname + "?" + stringified;
               console.log("url : ", url);
               window.location.href = url;
            } catch(error) {
               console.log("Invalid token provided : ", error);
            }
         }
      });

      
   }

   render() {
      const {widget} = this.props.propsData;
      console.log("widgetTitle -->", widget)
      const auth0Token = localStorage.getItem("auth0Token");
      const idToken = localStorage.getItem("idToken");
      if(this.state.hasError) {
          return (<UIFallbackScreen />)
      }
      if(this.state.loading) {
         <LifeSherpaLoading loading={this.state.loading}/>
      } else if(auth0Token || idToken) {
         return (
            <RctThemeProvider widgetApp={widget}>
                  <RctAppLayout widgetApp={widget}>
                     <WidgetController widgetApp={widget} params={this.props.propsData}/>  
                  </RctAppLayout>
            </RctThemeProvider>
         );
      } else {
         return "Something went wrong, Unable to load lifesherpa widget";
      }
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user } = authUser;
   return { user };
};

export default connect(mapStateToProps, {
   eraseLocalStorage
})(App);