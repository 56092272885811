/**
 * Recent Chat Users
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

// components
import WorkflowHistoryListItem from './WorkflowHistoryListItem';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

// actions
import { setSelectedWorkflowItem, setSelectedSubWorkflow } from 'Actions';

class WorkflowHistoryList extends Component {

    state = {
        search: ""
    }

    onClickListItem(workflow) {
        console.log("selected histroy item -->  ", workflow)
        this.props.setSelectedSubWorkflow()
        this.props.setSelectedWorkflowItem(workflow)
        this.props.handleOpenWorkflowDetails(workflow)
    }

    handelSearch = (e) => {
        this.setState({ search: e.target.value });
    }

    handelCloseSearch = () => {
        this.setState({ search: ""});
    }

    render() {
        const { workflowHistoryLoading, userRole, selectedTab, selectedWorkflowItem } = this.props;
        const {search} = this.state
        const workflowHistory = search && this.props.workflowHistory && this.props.workflowHistory.length > 0 ? this.props.workflowHistory.filter((item) => item.executionName && item.executionName.toLowerCase().includes(search.toLowerCase())) : this.props.workflowHistory;

        if (workflowHistory === null) {
            return (
                <div className="no-found-user-wrap text-center mt-4 pt-4">
                    {!workflowHistoryLoading && <h4>Workflow Open Execution History data Not Found</h4>}
                </div>
            );
        }

        return (
            <div className="">
                <form className="list-card list-item">
                    <TextField id="standard-search"
                        onChange={(e) => this.handelSearch(e)}
                        type="search"
                        value={search}
                        placeholder="Search Workfflow By Execution Name"
                        autoFocus={true}
                        className='w-100'
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                </form>
                {workflowHistory && workflowHistory.length > 0 ? workflowHistory.map((workflow, key) => (
                    <WorkflowHistoryListItem
                        selectedWorkflowItem={selectedWorkflowItem}
                        workflow={workflow}
                        key={key}
                        alternativeColor={key % 2 == 0 ? false : true}
                        onClickListItem={() => this.onClickListItem(workflow)}
                        userRole={userRole}
                    />
                )) : 
                    <div className="no-found-user-wrap text-center mt-4 pt-4">
                        {!workflowHistoryLoading && <h4>{`There are no ${selectedTab} workflows. You may check other data by selecting other tabs.`}</h4>}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ workflowData, authUser }) => {
    const { selectedWorkflow, showWorkflowHistory, workflowHistoryLoading, selectedWorkflowItem} = workflowData
    const { userRole } = authUser

	return { selectedWorkflow, showWorkflowHistory, workflowHistoryLoading, userRole , selectedWorkflowItem};
};

export default connect(mapStateToProps, {
   setSelectedWorkflowItem,
   setSelectedSubWorkflow
})(WorkflowHistoryList);
