import React, { Component } from 'react';
// import { Bar } from 'react-chartjs-2';
import ChartConfig from '../../constants/chart-config';
import LifeSherpaLoading from '../LifeSherpaLoading';
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip
  } from 'chart.js';
import {
	Bar
} from 'react-chartjs-2';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip
);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	redraw: true,
	legend: {
			labels: {
				fontColor: ChartConfig.legendFontColor
			}
		},
	scales: {
		x: {
			gridLines: {
				color: ChartConfig.chartGridColor
			},
			stacked: true,
			ticks: {
				fontColor: ChartConfig.axesColor,
				beginAtZero: true,
			},
		},
		y: {
			gridLines: {
				color: ChartConfig.chartGridColor
			},
			stacked: true,
			ticks: {
				fontColor: ChartConfig.axesColor,
				beginAtZero: false,
				stepSize: 1
			}
		},
		
	}
};

export default class BarChart extends Component {

	componentDidMount() {
		if(this.props.getChartData) {
			this.props.getChartData(this.props.chartId)
		  } 
	}

	render() {
		return (
			<article className="canvas-container">
				{
					this.props.loading ?
						<LifeSherpaLoading loading={this.props.loading}/>
					:
					<Bar data={this.props.data} options={options} />
				}
				
			</article>
		);
	}
}
