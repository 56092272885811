/**
 * Rct Section Loader
 */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';

const RctSectionLoader = ({themeData}) => (
    <div className="d-flex justify-content-center loader-overlay">
        <CircularProgress className='theame-text' style={{color: (themeData && themeData.progressBarColor) || "#008C95" }}/>
    </div>
);


// map state to props
const mapStateToProps = ({ authUser }) => {	 
	const {themeData} = authUser;
	return {themeData}
}
 
export default connect(mapStateToProps,{})(RctSectionLoader);
 
