/**
 * Client Alerts
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom';

import Notices from '../../Notices/index';
import ViewAlert from '../../Notices/ViewAlert';
import NoticesListPage from '../../Notices/NoticesListPage';
import CreateAlert from '../../Notices/CreateAlert';
import { Label } from 'reactstrap';

// Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import queryString from 'query-string';

//Actions
import {
    getUserById,
    handleNoticesPanel,
    clearNoticesData
} from '../../../actions'

class Alerts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null,
            openPanel: 'alerts',
            customOpen: false,
        }
    }


    componentDidMount() {

        //http://localhost:8000/widget/client-alerts?userId=0I1aJIkeHPOdG4V896xnmvjrStq1&orgId=Test&region=australia&idToken=
        const { widgetApp } = this.props;
        this.props.clearNoticesData();
        const uid = localStorage.getItem('uid');
        const idToken = localStorage.getItem('idToken')
        this.props.getUserById(uid, idToken, widgetApp)
        this.props.handleNoticesPanel(false, uid);
        this.props.history.listen((value) => {
            console.log('customOpen :', this.state.customOpen)
            if (!this.state.customOpen) {
                window.location.href = value.pathname + value.search;
            }
        });
    }

    handleBackToList = () => {
        if (queryString.parse(this.props.history.location.search).backClose) {
            this.handleclosePanel();
        }
        const { openPanel } = this.state;
        const { userId, idToken, orgId, theme, widget } = this.props.params;
        const queryUrl = `?userId=${userId}&orgId=${orgId}&theme=${theme}&idToken=${idToken}`;
        if (openPanel == 'form') {
            const baseURL = `/widget/${widget}/notices`;
            this.handleOpenURL("list", baseURL + queryUrl)
        } else if (openPanel == 'list' || openPanel == 'view') {
            let baseURL = `/widget/${widget}/history`;
            this.handleOpenURL("alerts", baseURL + queryUrl)
        } else {
            this.handleclosePanel();
        }
    }

    handleOpenURL = (panel, url) => {
        this.setState({ openPanel: panel, customOpen: url ? true : false }, () => {
            if (url) {
                this.props.history.push(url)
            }
        })
    }

    handleclosePanel = () => {
        this.props.clearNoticesData();
        window.location.href = window.location.origin + "/success";
    }

    render() {
        const { match, widgetApp, selectedUserId, clientImage, clientName, selectedNotice, params } = this.props;
        const { postId, categoryId, userId, idToken, orgId, theme, } = params;
        const queryUrl = `?userId=${userId}&orgId=${orgId}&theme=${theme}&idToken=${idToken}`;
        const { openPanel } = this.state;
        console.log("openPanel: ", openPanel);
        return (
            <div className='notices-panel'>
                <div className={`d-flex justify-content-between align-items-center notice-panel-header border-bottom bg-lifesherpa pl-2 py-2`}>
                    <IconButton className="text-white" onClick={() => this.handleBackToList()}><ArrowBackIcon /></IconButton>
                    <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{selectedNotice && openPanel === 'form' ? selectedNotice.title || 'Alert Form' : clientName}</Label>
                    <IconButton className="text-white mr-2 hide-element" onClick={() => this.handleclosePanel()}><CloseIcon /></IconButton>
                </div>
                <Switch>
                    <Redirect exact from={`${match.url}/`} to={postId && categoryId ? `${match.url}/view${queryUrl}&postId=${postId}&categoryId=${categoryId}&backClose=${true}` : `${match.url}/history${queryUrl}`} />
                    <Route path={`${match.url}/history`} render={(props) => <Notices {...props} params={this.props.params} widgetApp={widgetApp} handleOpenURL={this.handleOpenURL} />} />
                    <Route path={`${match.url}/view`} render={(props) => <ViewAlert {...props} params={this.props.params} widgetApp={widgetApp} handleOpenURL={this.handleOpenURL} />} />
                    <Route path={`${match.url}/notices`} render={(props) => <NoticesListPage {...props} params={this.props.params} widgetApp={widgetApp} handleOpenURL={this.handleOpenURL} />} />
                    <Route path={`${match.url}/form`} render={(props) => <CreateAlert {...props} params={this.props.params} widgetApp={widgetApp} clientImage={clientImage} clientName={clientName} handleOpenURL={this.handleOpenURL} />} />
                </Switch>

            </div>
        )
    }
}

const mapStateToProps = ({ authUser, noticesReducer }) => {
    const { themeLoading, userLoading, profile, usersInfo } = authUser;
    const { selectedUserId, selectedNotice } = noticesReducer;
    let clientImage = profile && profile.picture ? profile.picture : (usersInfo && usersInfo.profileImageURL) || require('Assets/avatars/user-15.jpg');
    let clientName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name) : 'Display Name'
    return { themeLoading, userLoading, clientImage, clientName, selectedNotice }
}

export default connect(mapStateToProps, {
    getUserById,
    handleNoticesPanel,
    clearNoticesData
})(Alerts)
