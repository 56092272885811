/**
 * Landing Page List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// redux action
import {
    setActivityType,
    StartActivity,
    ResumeActivity,
    showActivityHistoryDetail,
    selectActivity,
    getHistoryList,
    getSelectedActivity,
    setActivityReminder, getLoading, selectActivityId,
    deletePrivateActivity,
    executeActivity,
    setActivitiesAchievementsList,
    clearHistoryData,
    showStartWorkflowPanel,
    openWorkflowHistoryPanel
} from '../../actions';
import { scrollMaincontainerToTop } from "../../helpers/helpers";

// component
import LandingPageAllItem from './LandingPageAllItem';
import WorkflowListItem from './WorkflowListItem';


import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
//Intl Message
import SweetAlert from 'react-bootstrap-sweetalert'
import { IconButton } from '@mui/material';
import Moment from 'moment';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import LandingpageAgendaView from './LandingpageAgendaView'

import {database} from '../../firebase';
import { ref, query, orderByChild, equalTo, off} from "firebase/database"
import WidgetServices from "../../services/widgetServices";

class LandingPageAllList extends Component {
    state = {
        anchorEl: null,
        editScheduleKey: -1,
        deletePopup: false,
        previewPopup: false,
        stepNo: 0,
        calenderActivity: null,
        calenderBox: false,
        activity: '',
        notifyBox: false,
        selectedMenuActivity: {},
        openChart: false,
        chartParams: {},
        selectId: -1,
        showPopup: false,
    }

    componentWillUnmount() {
        let organization = localStorage.getItem('orgId')
        let uid = localStorage.getItem('auth_uid');
        const refValue = ref(database, `Realtime/${organization}/Users/${uid}/achievementsInProgress`)
        const topRef = query(refValue, orderByChild('preview') , equalTo(false))
        console.log("Removing Firebase listener--->", "achievementsInProgress")
        off(topRef)
    }

    handleCalenderButton(activity, key) {
        this.setState({ calenderActivity: activity, calenderBox: true, editScheduleKey: key })
    }

    handleClickMenu(event, key, activity) {
        console.log('selected activity --->', activity)
        this.setState({ anchorEl: event.currentTarget, editScheduleKey: key, selectedMenuActivity: activity });
    };

    handleCloseMenu = () => {
        //this.props.selectActivityId(null)
        this.setState({ anchorEl: null, editScheduleKey: -1, selectedMenuActivity: null });
    };

    handleDeletePop = () => {
        if (!this.state.deletePopup)
            this.setState({ deletePopup: true, anchorEl: null, });
        else this.setState({ deletePopup: false })
    }

    handleDeleteActivity = () => {
        let { selectedMenuActivity } = this.state;
        let userId = localStorage.getItem('auth_uid');
        let activityId = selectedMenuActivity.id
        this.props.deletePrivateActivity(userId, activityId)
        this.setState({ anchorEl: null, deletePopup: false, editScheduleKey: -1, selectedMenuActivity: null })
    }

    handlePreviewDialod = () => {
        // this.props.previewId(this.props.allActivities[this.state.editScheduleKey].id)
        this.props.selectActivity(true)
        //this.props.selectActivityId(this.props.allActivities[this.state.editScheduleKey].id);
        let { selectedMenuActivity } = this.state;

        let key = localStorage.getItem('selectedIndex');
        if (key == 0) {
            localStorage.setItem('selectedIndex', JSON.stringify(1))
            localStorage.setItem('previewId', selectedMenuActivity.id);
            this.props.history.push(`/app/user/my_activities`);
        } else {
            localStorage.removeItem('previewId');
            this.props.previewId(selectedMenuActivity.id);
            if (!this.state.previewPopup) this.setState({ previewPopup: true });
            else this.setState({ previewPopup: false });
            this.setState({ anchorEl: null });
        }
    }

    handlePreviewDialod1 = (id) => {
        this.props.previewId(id)
        if (!this.state.previewPopup) this.setState({ previewPopup: true });
        else this.setState({ previewPopup: false })
    }

    handleShowDetails = () => {
        let { selectedMenuActivity } = this.state;
        this.props.showDetails(selectedMenuActivity.id)
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    EditActivity = () => {
        let { selectedMenuActivity } = this.state;
        const activityId = selectedMenuActivity.id;
        const activityType = selectedMenuActivity.type === "QT-Reminder" ? "notification" : 'general';
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const typeOfActivity = activityType == "notification" ? "Notification" : "General";
            const data = { activityId: activityId, type: typeOfActivity };
            widgetServices.activityEditHandler(data)
        } else {
            if (this.props.allActivities[this.state.editScheduleKey])
            this.props.handleEditActivity(activityId, activityType)
        }
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    handleShowActivityHistoryList = () => {
        let { selectedMenuActivity } = this.state;
        let activity = selectedMenuActivity;
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { activityId: activity.id };
            widgetServices.activityHistoryHandler(data)
        } else {
            this.props.clearHistoryData()
            const activityConfirmation = localStorage.getItem("activityConfirmation");
            if (activityConfirmation) {
                localStorage.removeItem("activityConfirmation");
            }
            if(activity.organization) {
                localStorage.setItem("selectedOrgId", activity.organization);
            }
            this.props.getSelectedActivity(activity);
            const userId = localStorage.getItem("uid");
            this.props.getHistoryList(userId, activity.id);
            scrollMaincontainerToTop();
            this.props.showActivityHistoryDetail(true);
        }
        this.setState({ anchorEl1: null, anchorEl: null })
        // this.props.showAddButton()
    }

    /* Execute Activity*/
    handleExecuteActivity = (selectedActivity, scheduleKey) => {
        let activity = { activityId: selectedActivity.id, ...selectedActivity };

        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let activityType = selectedActivity.isPrivateActivity ? 'private' : 'common';
        const widgetServices = new WidgetServices()

        if (widgetServices.isWidgetApp()) {
            const data = { activityId: activity.activityId };
            widgetServices.executeActivityHandler(data)
        } else {
            this.props.setActivityType(activityType)
            let uid = localStorage.getItem('auth_uid');
    
            if (selectedActivity.state && selectedActivity.state === 'paused') {
                console.log(" paused activity started ->")
            } else {
                console.log(" activity executed ->")
            }

            this.props.executeActivity(selectedActivity.id, date, uid, scheduleKey)
            this.props.handleStartActivity(activity)
        }
        this.setState({ anchorEl: null })
    }

    /* Execute Activity From Option*/
    handleExecuteActivityFromOption = () => {
        let { selectedMenuActivity } = this.state;
        let activity = { activityId: selectedMenuActivity.id, ...selectedMenuActivity };

        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let activityType = selectedMenuActivity.isPrivateActivity ? 'private' : 'common';
        this.props.setActivityType(activityType)
        let uid = localStorage.getItem('auth_uid');

        this.props.executeActivity(selectedMenuActivity.id, date, uid)
        this.props.handleStartActivity(activity)

        this.setState({ anchorEl: null })
    }

    /* Preview Activity*/
    handleStartActivity = (selectedActivity) => {
        let { selectedMenuActivity } = this.state;
        let activity = { activityId: selectedMenuActivity.id, ...selectedMenuActivity };
        console.log("selectedActivity: ", selectedActivity)
        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { activityId: selectedMenuActivity.id };
            widgetServices.activityPreviewHandler(data)
        } else {
            let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
            let activityType = selectedMenuActivity.isPrivateActivity ? 'private' : 'common';
            this.props.setActivityType(activityType)
            let uid = localStorage.getItem('auth_uid');
            this.props.StartActivity(selectedMenuActivity.id, date, uid)
            this.props.handleStartActivity(activity)
        }
        this.setState({ anchorEl: null })
    }

    handleStartActivity1 = (key) => {
        let { selectedMenuActivity } = this.state;
        let activity = { activityId: selectedMenuActivity.id, ...selectedMenuActivity };

        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let activityType = selectedMenuActivity.isPrivateActivity ? 'private' : 'common';
        this.props.setActivityType(activityType)
        let userId = localStorage.getItem('auth_uid');

        this.props.StartActivity(selectedMenuActivity.id, date, userId)
        this.props.handleStartActivity(activity)

        this.setState({ anchorEl: null })
    }

    handleResumeActivity = (selectedActivity, scheduleKey) => {
        let activity = { activityId: selectedActivity.id, ...selectedActivity };
        if(activity.organization) {
            localStorage.setItem("selectedOrgId", activity.organization);
        }

        let activityType = selectedActivity.isPrivateActivity ? 'private' : 'common';
        const widgetServices = new WidgetServices()

        if (widgetServices.isWidgetApp()) {
            const data = { activityId: selectedMenuActivity.id };
            widgetServices.resumeActivityHandler(data)
        } else {
            this.props.setActivityType(activityType)
            let uid = localStorage.getItem('auth_uid');
            let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
            this.props.executeActivity(activity.activityId, date, uid)
            this.props.handleStartActivity(activity)
        }

        this.setState({ anchorEl: null })
    }

    handleReminderButton = (sel) => {
        this.setState({ activity: sel, notifyBox: true, anchorEl: null })
    }

    handleNotify = (activity) => {
        const id = activity ? activity.id : null
        const orgId = activity ? activity.organization : localStorage.getItem('selectedOrgId')
        const { selectedMemberId } = this.props;
        this.props.setActivityReminder(selectedMemberId, id, orgId)
        this.setState({ activity: '', notifyBox: false, anchorEl: null })
    }

    handleStartWorkflow = (selectedWorkflow, scheduleKey) => {
        const {workflowlist} = this.props
        if(selectedWorkflow.organization) {
            localStorage.setItem("selectedOrgId", selectedWorkflow.organization);
        }
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { workflowId: selectedWorkflow.id };
            widgetServices.resumeWorkflowHandler(data)
        } else {
            this.props.showStartWorkflowPanel({workflow: selectedWorkflow, openWorkflowPendingList: false});
            this.setState({ anchorEl: null});
        } 
    }

    handleCancelWorkflow = (selectedWorkflow) => {
        const {workflowlist} = this.props
        if(selectedWorkflow.organization) {
            localStorage.setItem("selectedOrgId", selectedWorkflow.organization);
        }
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { workflowId: selectedWorkflow.id };
            widgetServices.resumeWorkflowHandler(data)
        } else {
            console.log("selectedWorkflow :", selectedWorkflow)
            //this.props.setActivityType("workflow")
            // this.props.startWorkflow({orgId, workflowId})
        }
        this.setState({ anchorEl: null })
    }

    handleOpenWorkflowPendingRoleList = (workflow) => {
        console.log("workflow :", workflow)
        if(workflow.organization) {
            localStorage.setItem("selectedOrgId", workflow.organization);
        }
        this.setState({anchorEl: null})
        this.props.showStartWorkflowPanel({workflow, openWorkflowPendingList: true});
    }

    openWorkflowExecutionHistory(workflow){
        console.log("workflow :", workflow)
        const {showWorkflowHistory} = this.props
        if(workflow.organization) {
            localStorage.setItem("selectedOrgId", workflow.organization);
        }
        this.setState({anchorEl: null})
        this.props.openWorkflowHistoryPanel(workflow, true);
    }



    selectedActivity = (e) => {
        let { selActivity } = this.state
        if (selActivity.id === e.id) this.setState({ selActivity: {} })
        else this.setState({ selActivity: e })
    }

    getFilterActivitiesList = (list) => {
        const {filteredActivities} = this.props;
        let filteredList = [];
        if(filteredActivities && filteredActivities.length > 0 && list && list.length > 0) {
            filteredList =  list.filter(activity => filteredActivities.findIndex(routine => routine.id === activity.id) >= 0);
        } else {
            filteredList = list ? [...list] : [];
        }
        return [...filteredList];
    }

    applySearchFilter = (list) => {
        const {search} = this.props;
        if(search && search.length > 2 && list && list.length > 0) {
            return list.filter((li) => (li.title && li.title.toLowerCase().includes(search.toLowerCase())) || (li.detailedDescription && li.detailedDescription.toLowerCase().includes(search.toLowerCase())))
        }
        return list;
    }


    getSearchFilterWorkflowsList = (list) => {
        const {search} = this.props;
        if(search && search.length > 2 && list && list.length > 0) {
            return list.filter((li) => (li.name && li.name.toLowerCase().includes(search.toLowerCase())) || (li.description && li.description.toLowerCase().includes(search.toLowerCase())))
        }
        return list;
    }

    render() {
        const { allActivities, workflowLoading, summaryLoading, isEdit, organizations, enableWorkflows } = this.props;
       let activitiesList = this.getFilterActivitiesList(allActivities);
       activitiesList =  this.applySearchFilter(activitiesList)


       console.log('TT1 workflowLoading', workflowLoading);

       let workflowslist = this.getSearchFilterWorkflowsList(this.props.workflowslist);

       activitiesList && activitiesList.map((act, index) => {
            if (act.state === 'paused' || act.state === 'inProgress') {
                activitiesList.splice(index, 1)
                activitiesList.unshift(act)
            }
        });

        let calenderList = []
       
        if (this.state.calenderActivity && this.state.calenderActivity.sortedSchedules && this.state.calenderActivity.sortedSchedules.length > 0) {
            calenderList = this.state.calenderActivity.sortedSchedules
        }
        const orgId = localStorage.getItem("orgId");

        return (
            <div data-testid="landing-page-all-list-component" className='w-100'>
                <ul className="list-unstyled m-0 list-sherpa-activity mt-2 mb-4 pt-0 pl-2 pr-2" >
                    <div>
                        {this.props.listStyle === 'common' && activitiesList.length > 0 ? activitiesList.map((activity, key) => (
                            <LandingPageAllItem
                                key={key}
                                selected={this.props.selected}
                                allActivities={activitiesList}
                                role={this.props.selectedRole}
                                activity={activity}
                                selectActivityId={this.props.selectedId}
                                editScheduleKey={this.state.editScheduleKey}
                                selectedMenuActivity={this.state.selectedMenuActivity}
                                handleOpenHistory={() => this.handleShowActivityHistoryList()}
                                itemKey={key}
                                anchorEl={this.state.anchorEl}
                                calenderBox={this.state.calenderBox}
                                handleClickMenu={(e) => this.handleClickMenu(e, key, activity)}
                                handleCloseMenu={() => this.handleCloseMenu()}
                                handleReminderButton={() => this.handleReminderButton(activity)}
                                handleCalenderButton={() => this.handleCalenderButton(activity, key)}
                                handleDeletePop={() => this.handleDeletePop()}
                                handlePreviewDialod={(e) => this.handlePreviewDialod()}
                                handleShowDetails={(e) => this.handleShowDetails()}
                                EditActivity={(e) => this.EditActivity()}
                                handleStartActivity={(e) => this.handleStartActivity(activity)}
                                handleExecuteActivityFromOption={(e) => this.handleExecuteActivityFromOption()}
                                handleResumeActivity={(e) => this.handleResumeActivity(activity)}
                                handleStartActivity1={(e) => this.handleStartActivity1(key)}
                                handleExecuteActivity={(e) => this.handleExecuteActivity(activity)}
                                hideRewards={this.props.hideRewards}
                                showStartOrResumeButton={true}
                                organization={ organizations ? organizations.find(org => org.id === (activity.organization || orgId)) : null}
                            />
                        ))
                            : this.props.listStyle === 'summary' ?
                                summaryLoading ?
                                    <div data-testid="agendaview-loading-activity" className="d-flex justify-content-center align-items-center py-50">
                                        <h4>Fetching activities Summary</h4>
                                    </div>
                                    :
                                    <LandingpageAgendaView
                                        editScheduleKey={this.state.editScheduleKey}
                                        selected={this.props.selected}
                                        selectedMenuActivity={this.state.selectedMenuActivity}
                                        allActivities={activitiesList}
                                        selectedRole={this.props.selectedRole}
                                        selectActivityId={this.props.selectedId}
                                        handleShowActivityHistoryList={this.handleShowActivityHistoryList.bind(this)}
                                        anchorEl={this.state.anchorEl}
                                        calenderBox={this.state.calenderBox}
                                        handleClickMenu={this.handleClickMenu.bind(this)}
                                        handleCloseMenu={this.handleCloseMenu.bind(this)}
                                        handleReminderButton={this.handleReminderButton.bind(this)}
                                        handleCalenderButton={this.handleCalenderButton.bind(this)}
                                        handleDeletePop={this.handleDeletePop.bind(this)}
                                        handlePreviewDialod={this.handlePreviewDialod.bind(this)}
                                        handleShowDetails={this.handleShowDetails}
                                        EditActivity={this.EditActivity.bind(this)}
                                        handleStartActivity={this.handleStartActivity.bind(this)}
                                        handleExecuteActivityFromOption={this.handleExecuteActivityFromOption.bind(this)}
                                        handleResumeActivity={this.handleResumeActivity.bind(this)}
                                        handleStartActivity1={this.handleStartActivity1.bind(this)}
                                        handleExecuteActivity={this.handleExecuteActivity.bind(this)}
                                        hideRewards={this.props.hideRewards}
                                    />
                                :
                                this.props.listStyle === 'workflows' && enableWorkflows ?
                                    workflowslist && workflowslist.length > 0 ? workflowslist.map((workflow, key) => (
                                        <WorkflowListItem
                                            key={key}
                                            selected={this.props.selected}
                                            role={this.props.selectedRole}
                                            workflow={workflow}
                                            selectWorkflowId={this.props.selectedId}
                                            selectedMenuWorkflow={this.state.selectedMenuActivity}
                                            itemKey={key}
                                            anchorEl={this.state.anchorEl}
                                            handleClickMenu={(e) => this.handleClickMenu(e, key, workflow)}
                                            handleCloseMenu={() => this.handleCloseMenu()}
                                            handleCancelWorkflow={(e) => this.handleCancelWorkflow(workflow)}
                                            handleStartWorkflow={(e) => this.handleStartWorkflow(workflow)}
                                            hideRewards={true}
                                            showStartOrResumeButton={true}
                                            handleOpenWorkflowPendingRoleList={() => this.handleOpenWorkflowPendingRoleList(workflow)}
                                            userRole={this.props.userRole}
                                            openWorkflowExecutionHistory={() => this.openWorkflowExecutionHistory(workflow)}
                                            organization={ organizations ? organizations.find(org => org.id === (workflow.organization || orgId)) : null}
                                        />
                                    ))
                                    : workflowLoading ?
                                        <div className="d-flex justify-content-center align-items-center py-50">
                                            <h4>Fetching Workflows </h4>
                                        </div>
                                    :
                                    <div data-testid="landing-page-no-my-workflow" className="d-flex justify-content-center align-items-center py-50 no-data-found">
                                        <h4>Workflows Not Found</h4>
                                    </div>
                                   
                                :
                                <div data-testid="landing-page-no-inprogress-activity" className="d-flex justify-content-center align-items-center py-50 no-data-found">
                                    <h4>Activities Not Found</h4>
                                </div>
                        }
                    </div>
                </ul>
                {this.state.deletePopup &&
                    <div className="col-12 p-4">
                        <SweetAlert
                            warning
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Delete"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Delete"
                            onConfirm={() => this.handleDeleteActivity()}
                            onCancel={() => this.setState({ deletePopup: false, anchorEl: null })}
                        >
                            <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>Do you want to delete {this.state.selectedMenuActivity.title || "this"} Activity?</div>
                        </SweetAlert>
                    </div>}
                {this.state.calenderBox &&
                    <Dialog
                        fullScreen={false}
                        open={this.state.calenderBox}
                        onClose={() => this.setState({ calenderBox: false, editScheduleKey: -1, selectedMenuActivity: null })}
                        fullWidth={true}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                        className={`${this.props.popupThemeClass} schedules-list-dialog-popup`}
                        PaperProps={
                            { style: { maxWidth: "450px", borderRadius: 8, margin: 'auto' } }
                        }
                    >
                        <DialogTitle>
                            <div className="row py-2 px-3 border-bottom bg-lifesherpa" >
                                <CalendarTodayIcon className="my-auto ml-1 text-white" /><h2 className="text-white my-auto ml-2">{this.state.calenderActivity != null ? this.state.calenderActivity.title : ''}</h2>
                                <IconButton className="ml-auto text-white my-auto" style={{ cursor: "pointer" }} onClick={() => this.setState({ calenderBox: false, editScheduleKey: -1, selectedMenuActivity: null })}><CloseIcon /></IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div className="m-3" >
                                <div data-testid="scheduled-details-dialog" className="my-5 py-5 mx-3" style={{ minHeight: "300px" }}>
                                    {calenderList.length > 0 ? calenderList.map((list, index) => (
                                        <div className="row mb-2 pb-2 border-bottom" key={index}>{list.text}</div>
                                    )) : ""}
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className="w-100 text-center" >
                                <div data-testid="scheduled-details-dialog-close-button" className="helpbutton mx-auto" style={{ width: '150px', fontSize: '16px', borderRadius: '75px' }} onClick={() => this.setState({ calenderBox: false, editScheduleKey: -1, selectedMenuActivity: null })}>OK</div>
                            </div>
                        </DialogActions>
                    </Dialog>
                }

                {this.state.notifyBox &&
                    <div className="col-12 p-4">
                        <SweetAlert
                            warning
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Confirmation"
                            onConfirm={() => this.handleNotify(this.state.activity)}
                            onCancel={() => this.setState({ notifyBox: false, activity: '', editScheduleKey: -1 })}
                        >
                            <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>{"Would you like to notify Display Name to start " + this.state.activity.title + "?"}</div>
                        </SweetAlert>
                    </div>
                }
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ emailApp, GroupList, chatAppReducer, activitiesList, authUser, settings, clientsList, workflowData }) => {
    const { currentEmail, sendingEmail, showActivityHistory, showConfirmation } = emailApp;
    const { selectedGroup } = GroupList
    const { hideRewards, userRole } = authUser;
    const { activitySummery, privateActivities, selectedId, activitiesAchievementsList, summaryLoading, allActivities, filteredActivities } = activitiesList
    const { selectedMemberId, selectedRole } = chatAppReducer;
    const { popupThemeClass } = settings;
    const {organizations} = clientsList
    const {workflowLoading, workflowslist, showWorkflowHistory} = workflowData;
    return { hideRewards, currentEmail, selectedRole, privateActivities, selectedId, sendingEmail, showActivityHistory, activitySummery, showConfirmation, selectedGroup, selectedMemberId, popupThemeClass, activitiesAchievementsList, summaryLoading, organizations, workflowLoading, workflowslist, allActivities, filteredActivities, userRole, showWorkflowHistory };
}

export default withRouter(connect(mapStateToProps, {
    showActivityHistoryDetail,
    getHistoryList,
    getSelectedActivity,
    setActivityReminder,
    deletePrivateActivity,
    selectActivity,
    StartActivity,
    ResumeActivity,
    setActivityType, selectActivityId,
    getLoading,
    executeActivity,
    setActivitiesAchievementsList,
    clearHistoryData,
    showStartWorkflowPanel,
    openWorkflowHistoryPanel
})(LandingPageAllList));