import React, { Component } from 'react';
// import { Pie } from 'react-chartjs-2';
import ChartConfig from '../../constants/chart-config';
import LifeSherpaLoading from '../LifeSherpaLoading';
import {
	Chart as ChartJS, ArcElement, Tooltip, Legend 
  } from 'chart.js';
import {
	Pie
} from 'react-chartjs-2';

ChartJS.register(
	ArcElement, Tooltip, Legend 
);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	redraw: true,
	legend: {
		labels: {
			fontColor: ChartConfig.legendFontColor
		}
	}
};

export default class PieChart extends Component {

	componentDidMount() {
		this.props.getChartData(this.props.chartId)
	}

	render() {
		return (
			<article className="canvas-container">
				{
					this.props.loading ?
						<LifeSherpaLoading loading={this.props.loading}/>
					:
					<Pie data={this.props.data} options={options} />
				}
			</article>
		);
	}
}
