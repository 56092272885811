import {
    GET_USER_NOTIFICATIONS_HISTORY,
    GET_SUCCESS_USER_NOTIFICATIONS_HISTORY,
    SHOW_USER_NOTIFICATIONS_HISTORY_LOADING,
    HIDE_USER_NOTIFICATIONS_HISTORY_LOADING,
    SHOW_NOTIFICATION_POPUP,
    HIDE_NOTIFICATION_POPUP,
    SHOW_NOTIFICATION_LOADING,
    HIDE_NOTIFICATION_LOADING
 } from '../actions/types';

 const INITIAL_STATE = {
    notificationOpenLoading: false,
    notifications: null,
    notificationData:null,
    showNotificationPopup: false,
    notificationLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_USER_NOTIFICATIONS_HISTORY:
            return { ...state, notificationLoading: true };
        case GET_SUCCESS_USER_NOTIFICATIONS_HISTORY:
            return { ...state, notifications: action.payload, notificationLoading: false };
        case HIDE_USER_NOTIFICATIONS_HISTORY_LOADING:
            return { ...state, notificationLoading: false };
        case SHOW_USER_NOTIFICATIONS_HISTORY_LOADING:
            return { ...state, notificationLoading: true };
        case SHOW_NOTIFICATION_POPUP:
            return { ...state, notificationData: action.payload, showNotificationPopup: true};
        case HIDE_NOTIFICATION_POPUP:
            return { ...state, notificationData: null, showNotificationPopup: false};
        case SHOW_NOTIFICATION_LOADING:
            return { ...state, notificationOpenLoading: true};
        case HIDE_NOTIFICATION_LOADING:
            return { ...state, notificationOpenLoading: false};

        default: return { ...state };
    }
}
