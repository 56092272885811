import React, { Component } from 'react';

// Material UI Components
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import { Label, FormFeedback } from 'reactstrap';
import LifeSherpaLoading from '../../LifeSherpaLoading';
// import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

class UserWorkflowList extends Component {

    state = {
        showSearch: false,
        search: ""
    }

    componentDidMount() {
    }

    handleClosePanel() {
        this.props.handleCloseUseWorflow()
        const userId = localStorage.getItem("uid")
        const auth_uid = localStorage.getItem("auth_uid")
        let orgId = localStorage.getItem("selectedOrgId")
        if (auth_uid === userId) {
            orgId =  localStorage.getItem("orgId")
            localStorage.setItem("selectedOrgId", orgId)
        } 
        this.props.getUserPendingWorkflowList({userId, orgId})
    }

    handleSelectListItem(userWorkflow) {
        this.props.handleUserWorkflowSelect(userWorkflow)
    }

    handelSearch = (e) => {
        console.log(e);
        this.setState({ search: e.target.value });
    }

    handelCloseSearch = () => {
        this.setState({ search: "", showSearch: false});
    }

    render() {
        const {clientName, userWorkflowLoading, organizations} = this.props
        const {search, showSearch} = this.state
        const userWorkflows = search != "" && this.props.userWorkflows && this.props.userWorkflows.length > 0 && search.length > 0 ? this.props.userWorkflows.filter(workflow => workflow.executionName.toLowerCase().includes(search.toLowerCase())) : this.props.userWorkflows
        const workflowsSupportOrgIds = organizations ? organizations.filter(org => org.enableWorkflows): [];
        const isAdminUser = localStorage.getItem("auth_uid") == localStorage.getItem("uid")

        return (
            <React.Fragment>
                <DialogTitle>
                    <div className={`d-flex all-clients-for-grooup justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                        {!showSearch ? <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{`${clientName == "My" ? clientName : clientName + "'s"} Workflow with Pending Tasks`}</Label> : <></>}
                            {showSearch ?
                                <form className="mr-3 w-100">
                                    <TextField id="standard-search"
                                        onChange={(e) => this.handelSearch(e)}
                                        type="search"
                                        value={search}
                                        placeholder="Search workflow"
                                        autoFocus={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </form>
                                :
                                <IconButton className=" mx-4 " data-testid="search-button-on-pending-tasks-in-workflow" onClick={() => this.setState({ showSearch: true })}><SearchIcon /></IconButton>
                            }
                        <IconButton data-testid="close-pending-tasks-in-workflow" className="text-white mr-2" onClick={() => this.handleClosePanel()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="m-0 p-0 start-workflow-container ">
                    <LifeSherpaLoading loading={userWorkflowLoading} />
                    {/* <Slide direction={"right"} timeout={500} appear={this.props.pageMounted} in={true}> */}
                        <ul className='pt-3 pl-2 pb-3 pr-2 list-unstyled'>
                        { userWorkflows && userWorkflows.length > 0 ?  userWorkflows.map((userWorkflow, key) => (
                                <li key={key} className='p-3 list-card d-flex align-items-center justify-content-between cps' onClick={() => this.handleSelectListItem(userWorkflow)}>
                                    <Label className='mb-0 cps'>
                                        {workflowsSupportOrgIds.length > 1 ? 
                                            <span title="Organization" className='mb-1 d-flex align-items-center justfy-content-center mr-1'>
                                                <CorporateFareIcon className='mr-1'/>{organizations.find(org => org.id === (userWorkflow.organization)).name}
                                            </span>
                                            : <></>
                                        }
                                        {userWorkflow.executionName}
                                    </Label>
                                    <div className='d-flex align-items-center cps'>
                                        <FormFeedback className='d-block circular-label m-0'>{userWorkflow.tasks && userWorkflow.tasks.length > 0 ? userWorkflow.tasks.length : ""}</FormFeedback>
                                        <ArrowRightIcon />
                                    </div>
                                </li>
                        ))
                        : userWorkflowLoading ?
                            <></>
                        :
                            <li className='p-3 list-card d-flex align-items-center justify-content-between'>No workflow tasks are pending</li>
                        }
                        </ul>
                    {/* </Slide> */}
                </DialogContent>
            </React.Fragment>
        );
    }
}

export default UserWorkflowList