/**
 * Workflow
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withAuth0 } from "@auth0/auth0-react";

// rct collapsible card
import RctCollapsibleCard from '../../components/RctCollapsibleCard/RctCollapsibleCard'
import LSWorkflowList from './components/LSWorkflowList';
import WorkflowDashboard from './components/WorkflowDashboard';
import WorkflowExecutionHistory from '../../components/WorkflowExecutionHistory';
import UserWorkflow from "../../components/Workflows/components/UserWorkflow"
import StartWorkflowView from "../../components/Workflows"
import WorkflowSummaryReport from './components/WorkflowSummaryReport';
//Actions
import {
	selectActivityId,
	selectActivity,
	changeRole,
	getActivitiesList,
	getUserById,
	getActivityGroupsList,
	handleUserWorkflowPanel,
	getWorkflowChartData
} from '../../actions'

class LifesherpaWorkflow extends Component {

	constructor(props) {
		super(props)
	}

	getScrollHeight() {
		if (localStorage.getItem("widgetApp")) {
			return 'calc(100vh)';
		} else {
			return '630px';
		}
	}

	handleStartActivity = (e) => {
		this.props.handlEditor(true)
		this.setState({ previewId: null, mobileOpen: true, activityId: e.activityId, activityType: e.type }, () => {
			this.props.showExecuteActivityPannel('start')
		})
	}

	handleFilterChartData(filterData, filterOrgIds) {
		const orgIds = filterOrgIds
		const userId = localStorage.getItem("auth_uid")
		this.props.getWorkflowChartData(userId, filterData, orgIds)
	}

	render() {
		window["auth0"] = this.props.auth0
		const { userRole, showWorkflowHistory, userWorkflowPanel, startWorkflowPanel, workflowslist, workflowChartLoading, workflowChartData, workflowSummaryPanelOrgFiltered, popupThemeClass } = this.props;
		console.log("render -> startWorkflowPanel: ", startWorkflowPanel)
        
		return (
			<div className="w-100 " data-testid="ls-dashboard">
				{showWorkflowHistory ?
					<WorkflowExecutionHistory />
					:
                    <div className="row">
                        <RctCollapsibleCard
                            colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                            heading="Workflow Dashboard"
                            fullBlock
                            customClasses="overflow-hidden theme-background "
                            headingCustomClasses="bg-lifesherpa "
                        >
                            <WorkflowDashboard
                                userRole={userRole}
                            />
                        </RctCollapsibleCard>
                        <RctCollapsibleCard
                            colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
                            heading={workflowslist && workflowslist.length > 0 ? `Workflows(${workflowslist.length})` : "Workflows"}
                            fullBlock
                            customClasses="overflow-hidden theme-background "
                            headingCustomClasses="bg-lifesherpa "
                        >
                            <LSWorkflowList showViewStatus={false} showRolePendingAssignment={true} workflowMenu={true}/>
                        </RctCollapsibleCard>
						<RctCollapsibleCard
								heading="Workflows Report"
								colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
								fullBlock
								customClasses="overflow-hidden theme-background "
								headingCustomClasses="bg-lifesherpa "
							>
								<WorkflowSummaryReport
									chartData={workflowChartData}
									workflowChartLoading={workflowChartLoading} 
									workflowChartData={workflowChartData}
									handleFilterChartData={this.handleFilterChartData.bind(this)}
									workflowSummaryPanelOrgFiltered={workflowSummaryPanelOrgFiltered}
									organizations={this.props.organizations}
									popupThemeClass={popupThemeClass}
								/>
						</RctCollapsibleCard>					
                    </div>
				}
				{userWorkflowPanel ?
                    <UserWorkflow 
                        openUserWorkflow={userWorkflowPanel} 
						openFromWorkflowTab={true}
                        />
                : startWorkflowPanel ? 
                    <StartWorkflowView />
                : <></>
                }
			</div>
		)
	}
}

const mapStateToProps = ({ authUser, activitiesList, GroupList, chatAppReducer, settings, emailApp, workflowData, clientsList }) => {
	const { userRole, allowedModules, displayOnce } = authUser
	const { allActivities, privateActivities, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, filteredActivities } = activitiesList
	const { selectedRole } = chatAppReducer
	let { groupMembers, groupMembersDetails } = GroupList;
	const { screenThemeClass, popupThemeClass } = settings;
	const { showActivityHistory } = emailApp;
	const {showWorkflowHistory, userWorkflowPanel, startWorkflowPanel, workflowslist, workflowChartLoading, workflowChartData} = workflowData
	const {workflowSummaryPanelOrgFiltered, organizations} = clientsList;
	
	return { selectedRole, groupMembers, allActivities, privateActivities, userRole, allowedModules, screenThemeClass, popupThemeClass, displayOnce, showActivityHistory, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, groupMembersDetails, filteredActivities, showWorkflowHistory, userWorkflowPanel, startWorkflowPanel, workflowslist, workflowChartLoading, workflowChartData, workflowSummaryPanelOrgFiltered, organizations }
}

export default withAuth0(connect(mapStateToProps, {
	selectActivityId,
	selectActivity,
	changeRole,
	getActivitiesList,
	getUserById,
	getActivityGroupsList,
	handleUserWorkflowPanel,
	getWorkflowChartData
})(LifesherpaWorkflow))
