import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import StartActivityListView from "./StartActivityListView";

class EditorPopup extends Component {
    state = { 
        windowWidth:window.innerWidth
     }
    componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
	}
    componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}
    updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}
    getScrollbarsHeight=()=>{
        return "800px";
    }
    render() { 
        let{previewId}=this.props;
        return ( 
            <Scrollbars
                 className="rct-scroll"
                 autoHide 
                 style={{height: this.getScrollbarsHeight() }}
                 >
                <StartActivityListView  id={previewId} handleClose1={()=>this.props.handleClose1()} handleClose={()=>this.props.handleClose()} handleRewards={()=>this.props.handleRewards()} />
            </Scrollbars>
         );
    }
}
 
export default EditorPopup;