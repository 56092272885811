import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
	notifyGroup, 
	notifyUser, 
	hideVideoRoomPanel, 
	getVideoCallDetails,
	connectTwilioVideoCall,
	connectTwilioVideoCallSuccess,
	sendGroupVideoCallResponse,
	getSelectedVideoChat,
	removeVideoToken
} from "Actions"
import { connect } from 'react-redux';
import LifeSherpaLoading from 'Components/LifeSherpaLoading';
import VideoChatRoom from "Components/LifesherpaContacts/VideoChatRoom"
import LoadingVideoUI from "./LoadingVideoUI";

class VideoChat extends Component {
	state = { 
		romeName:'',
		room:null,
		video:'',
		channelTitle:"",
		loading:false,
		realTimeData: null,
		mediaAvailable : {audio: false, video: false},
		dominantSpeakerIdentity: null,
		bandwidthProfile: {
			video: {
			  mode: 'grid',
			  dominantSpeakerPriority: 'high'
			}
		}
	}

	async componentDidMount() {
        let {videoToken,selectedVideoChat, videoCallDetails} = this.props;
		console.log(" selectedVideoChat -->",selectedVideoChat);
		// getting available device media tracks
		const mediaAvailable = {audio: false, video: false};
        // connect to twilio video room
		let self = this;
		navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((audioStream) =>  {
			console.log("microphone available --> ");
			mediaAvailable.audio = true
			self.getAudioPermissionStatus(mediaAvailable)
		}).catch((err) => {
			console.log("microphone not available --> ", err);
			mediaAvailable.audio = false
			self.getAudioPermissionStatus(mediaAvailable)
		});
	}

	getAudioPermissionStatus = (mediaAvailable) => {
		let self = this;
		navigator.mediaDevices.getUserMedia({audio: false, video: true}).then((videoStream) =>  {
			console.log("camera available --> ");
			mediaAvailable.video = {width: 1280, height: 720};
			self.handleVidoeRoomConnect(mediaAvailable)
		}).catch((err) => {
			mediaAvailable.video = false;
			self.handleVidoeRoomConnect(mediaAvailable);
			console.log("camera not available --> ", err);
		}); 
	}

	handleVidoeRoomConnect = (mediaAvailable) => {
		console.log(" mediaAvailable --> ", mediaAvailable);
		let {videoToken,selectedVideoChat, videoCallDetails} = this.props;
		const {bandwidthProfile} = this.state
        // // connect to twilio video room
		this.props.connectTwilioVideoCall();
		const {connect}  =  require("twilio-video")
		connect(videoToken, {...mediaAvailable,name: selectedVideoChat.name, dominantSpeaker: true, bandwidthProfile}).then((room) => {
			console.log(" fetched room details -->",room);
			this.handleSendNotifiyUsers()
			room.on('dominantSpeakerChanged', participant => {
				console.log(" dominantSpeakerChanged ------------>");
				if(participant && participant.identity) {
					this.setState({dominantSpeakerIdentity : participant.identity})
				} 
			});
			this.setState({room:room,selected:'videoCall',romeName: selectedVideoChat.name, mediaAvailable: mediaAvailable})
		}).catch((err) => {
			console.error(' room error --> ',err);
			this.handleGoToMainPage();
		});
	}

	handleSendNotifiyUsers = () => {
		let {selectedVideoChat, videoCallDetails} = this.props;
		let videoCallRoomDetails = localStorage.getItem("videoCallRoomDetails")
		videoCallRoomDetails = videoCallRoomDetails ?  JSON.parse(videoCallRoomDetails) : null;
		console.log("[VideoChat] videoCallRoomDetails --> ", videoCallRoomDetails);
		let members=[];
		let groupId = "";
		if(videoCallRoomDetails) {
			// Notifying Member
			if(videoCallRoomDetails.members){
				videoCallRoomDetails.members.map((mb)=>members.push(mb.userId));
				groupId = videoCallRoomDetails.id;
				this.props.notifyGroup(videoCallRoomDetails.name,videoCallRoomDetails.id,members);
			} else if(videoCallRoomDetails.userId) {
				this.props.notifyUser(videoCallRoomDetails.userId,videoCallRoomDetails.name);
			}
		} else {
			// Fetching Room Details
			if(videoCallDetails && videoCallDetails.roomName == selectedVideoChat.name) {
				this.props.connectTwilioVideoCallSuccess();
			} else {
				this.props.getVideoCallDetails(selectedVideoChat.name);
			}
		}
	}

	handleLogout=(isGroupCall)=>{
		 const {room} = this.state;
		if (room) {
			room.localParticipant.videoTracks.forEach(publication => {
				publication.track.stop();
				publication.unpublish();
			});
			room.localParticipant.audioTracks.forEach(publication =>{
				publication.track.stop();
				publication.unpublish();
			});
			room.off('dominantSpeakerChanged', () => {
				console.log(" dominantSpeakerChanged listeners off ------------>");
			});
			room.disconnect();
		}
		this.sendVidoeCallResponse("cancel", null, isGroupCall);
		this.setState({room:null,selected: "",video:'',romeName:''});
		this.handleGoToMainPage()
		
	}

	componentWillUnmount() {
		const {room} = this.state;
		const {videoCallDetails} = this.props;
		if (room) {
			room.localParticipant.videoTracks.forEach(publication => {
				publication.track.stop();
				publication.unpublish();
			});
			room.localParticipant.audioTracks.forEach(publication =>{
				publication.track.stop();
				publication.unpublish();
			})
			room.disconnect();
		}
		this.props.removeVideoToken();
		this.sendVidoeCallResponse("cancel", null, videoCallDetails && videoCallDetails.group);
	}

	handleGoToMainPage = () => {
		this.props.removeVideoToken();
		const params = this.getParams();
		if(params && params.room) {
			this.props.getSelectedVideoChat(null);
			this.props.handleMessageForRoom(true)
			localStorage.removeItem("videoCallRoomDetails")
			setTimeout(() => {
			    this.props.handleMessageForRoom(false);
				window.close();
				if(this.props.location && this.props.location.pathname == "/lifesherpa/videocall") {
					this.props.history.push("/app/lsdashboard");
				}
			}, 4000)
		} 
		this.props.hideVideoRoomPanel();
	}

	getParams = () => {
		let params = null
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        return params
	}

	sendVidoeCallResponse = (action, members, isGroupCall ) => {
		const {romeName} = this.state;
		const {groupId} = this.props;
		let recipients = members ? { recipientId: members } : null;
		if (romeName) {
			this.props.sendGroupVideoCallResponse(romeName, action, recipients);
			console.log("isGroupCall :", isGroupCall);
		}
	}

    render() { 
		const {  selectedVideoChat, windowWidth, contactList, videoCallDetails, videoConnectLoading } = this.props;
		const { room, romeName, selected, loading,dominantSpeakerIdentity} = this.state;
		console.log("[VideoChat] videoTokenLoading : ",videoConnectLoading);
        return (
			<div> 
				 <LifeSherpaLoading loading={loading}/>
                    {!videoConnectLoading && selected == 'videoCall' && room ?
						<VideoChatRoom 
							roomName={romeName} 
							videoCallDetails={videoCallDetails} 
							dominantSpeakerIdentity={dominantSpeakerIdentity}
							room={room} 
							sendVidoeCallResponse={this.sendVidoeCallResponse}
							handleLogout={(e)=>this.handleLogout(e)} 
							contactList={contactList} 
							windowWidth={windowWidth}
							popupThemeClass={this.props.popupThemeClass}
							mediaAvailable = {this.state.mediaAvailable}
						 />
                        :
						<LoadingVideoUI text={"Fetching room details..."}/>
                    }	
             </div> 
		);
    }
}
 
const mapStateToProps = ({Contacts,authUser, settings}) => {
	const {userRole}=authUser;
	const { popupThemeClass } = settings; 
	const {videoToken, loading, selectedVideoChat, contactList, videoCallDetails, videoConnectLoading, inProgressVideoRoomDetail}=Contacts
	return {videoToken,userRole, loading, selectedVideoChat, contactList, videoCallDetails , videoConnectLoading, popupThemeClass, inProgressVideoRoomDetail};
};

export default withRouter(connect(mapStateToProps, {
	notifyGroup,
	notifyUser,
	hideVideoRoomPanel, 
	getVideoCallDetails,
	connectTwilioVideoCall,
	connectTwilioVideoCallSuccess,
	sendGroupVideoCallResponse,
	getSelectedVideoChat,
	removeVideoToken
})(VideoChat));
