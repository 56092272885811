/**
 * Video call History Reducer
 */
import {
    SHOW_VIDEO_CAll_LOGS_LOADING,
    HIDE_VIDEO_CAll_LOGS_LOADING,
    GET_VIDEO_CAll_LOGS_SUMMARY,
    GET_VIDEO_CAll_LOGS_SUMMARY_SUCCESS,
    GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL,
    GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL_SUCCESS,
    CLEAR_VIDEO_CALL_HISTORY_DATA
 } from '../actions/types';
 
 /**
  * initial data
  */
 const INIT_STATE = {
    videoCallSummary: null,
    videoCallSummaryDetail: null,
    loading: false,
    missedP2PVideoCallCount: 0,
    missedGroupVideoCallCount: 0,
    missedVCallClientCount: 0,
    pageCount: 1,
    hasMoreData: true
 };
 
export default (state = INIT_STATE, action) => {
    switch (action.type) {

    case SHOW_VIDEO_CAll_LOGS_LOADING:
        return { ...state, loading: true };

    case HIDE_VIDEO_CAll_LOGS_LOADING:
        return { ...state, loading: false };

    case GET_VIDEO_CAll_LOGS_SUMMARY:
        return { ...state, loading: true };

    case GET_VIDEO_CAll_LOGS_SUMMARY_SUCCESS:
        const videoCallSummary = action.payload
        let missedP2PVideoCallCount = 0
        let missedGroupVideoCallCount = 0
        let missedVCallClientCount = videoCallSummary ? videoCallSummary.length : 0
        if (videoCallSummary && videoCallSummary.length > 0) {
            videoCallSummary.map(videoCallItem => {
                if (videoCallItem.group) {
                    missedGroupVideoCallCount += videoCallItem.videoCallsCount
                } else {
                    missedP2PVideoCallCount += videoCallItem.videoCallsCount
                }
            })
        } 
        return { ...state, videoCallSummary, missedP2PVideoCallCount, missedGroupVideoCallCount, missedVCallClientCount, loading: false};

    case GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL:
        return { ...state, loading: true };

    case GET_VIDEO_CAll_LOGS_SUMMARY_DETAIL_SUCCESS:
        let hasMoreData = action.payload.length > 0 ? true : false
        let videoCallSummaryDetail =  state.videoCallSummaryDetail ? state.videoCallSummaryDetail.concat(action.payload) : action.payload
        return { ...state, videoCallSummaryDetail, loading: false, pageCount: state.pageCount + 1, hasMoreData};
    case CLEAR_VIDEO_CALL_HISTORY_DATA:
            return { ...state, videoCallSummaryDetail: action.payload, loading: false, pageCount: 1};

    default: 
        return { ...state };
    }
}
 