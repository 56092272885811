/**
 * Email Detail
 */
import React, { Component } from 'react';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import Button from '@mui/material/Button';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CommentIcon from '@mui/icons-material/Comment';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import ClearIcon from '@mui/icons-material/Clear';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

class UnConfirmDetail extends Component {


    onBackPress() {
		this.props.handleClose()
    }
    
	handleStepsCompletion = (stepId) => {
		let statusValue = null
		Object.keys(this.props.achievementDetail.StepsCompletion).map((objKey, key) => {
			if (this.props.achievementDetail.StepsCompletion[objKey].step.id === stepId)
			{
				statusValue = this.props.achievementDetail.StepsCompletion[objKey].status
				
			}
		})
		return statusValue
	}

	handleStepId = (stepId) => {
		let stepResponseId = null
		if (this.props.achievementDetail.StepsCompletion){
			Object.keys(this.props.achievementDetail.StepsCompletion).map((objKey, key) => {
				if (this.props.achievementDetail.StepsCompletion[objKey].step.id === stepId)
				{
					stepResponseId = this.props.achievementDetail.StepsCompletion[objKey].stepNotesResponseId
					
				}
			})
		}
		return stepResponseId
	}

	handleOpenEvaluationForm = (formId) => {
		this.props.handleOpenForm()
		if (this.props.achievementDetail.evaluationResponseId) {
			this.props.getUpdatedEvaluationForm(this.props.selectedMemberId, this.props.achievementDetail.evaluationResponseId)
		} else {
			this.props.getPokeForm(formId)
		}
		this.props.setSelectedStep(null)
	}

	handleOpenStepsNotesForm = (formId, stepId) => {
		this.props.handleOpenForm()
		if (this.handleStepId(stepId)) {
			this.props.getUpdatedEvaluationForm(this.props.selectedMemberId, this.handleStepId(stepId))
		} else {
			this.props.getPokeForm(formId)
		}
		this.props.setSelectedStep(stepId)
	}

	render() {
		const {selectedActivity, achievementDetail} = this.props
		let dateString = achievementDetail && achievementDetail.createdDate !== '' && achievementDetail.createdDate
		let time = dateString ? moment(dateString).format("hh:mm a") : moment().format("hh:mm a")
		let daysAgo = dateString ? moment(dateString).fromNow() : moment().fromNow()
	
		return (
            <div>
                <div className="bg-white">
                    <IconButton onClick={() => this.onBackPress()}><ArrowBackIcon /></IconButton>
				</div>
				<LifeSherpaLoading loading={this.props.loading}/>
				{ achievementDetail ? 
					<div className="email-detail-page-warrper" style={{width: '100%'}}>
						<div className="top-head border-bottom w-100 d-flex" style={{backgroundColor: '#f5f5f5', padding: '1rem'}}>
							<h3 className="w-60 d-flex align-items-center mb-0">Arrive at work</h3>
							<div className="w-40 d-flex align-items-center text-right justify-content-end">
								<div className="fs-xs text-muted text-center mr-25"><h2 className="mb-0">{time}</h2><strong>{daysAgo}</strong></div>
								<IconButton title="Evaluation Form" size="small"  onClick={() => this.handleOpenEvaluationForm(achievementDetail.routine && achievementDetail.routine.evaluationId)}>
									<AssignmentRoundedIcon style={achievementDetail && achievementDetail.routine.evaluationId && achievementDetail.evaluationResponseId ? {color: 'orange'} : {color: '#A5A7B2'}}/>
								</IconButton>
							</div>
						</div>
						<ul className="list-unstyled mb-0">
							{achievementDetail && achievementDetail.routine && achievementDetail.routine.steps && Object.keys(achievementDetail.routine.steps).map((histDataKey, key) => (
									<li key={key} className="list-item border-bottom d-flex bg-white">
										<div className="d-flex align-items-center w-90">
											<Avatar className="m-3 align-self-center" 
											style={
												achievementDetail.StepsCompletion && this.handleStepsCompletion(histDataKey) === 'Skipped' ?
													{backgroundColor: 'red'}
													: achievementDetail.StepsCompletion && this.handleStepsCompletion(histDataKey) === 'Done' ?
													{backgroundColor: 'blue'}
													:
													{backgroundColor:'#bdbdbd'}
											}
											> 
												{achievementDetail.StepsCompletion && this.handleStepsCompletion(histDataKey) === 'Skipped' ?
													<ClearIcon />
													: achievementDetail.StepsCompletion && this.handleStepsCompletion(histDataKey) === 'Done' ?
														<AccessAlarmRoundedIcon />
													:
													<DoneRoundedIcon/>
												}
											</Avatar>
											<p className="mb-1 pr-3">{achievementDetail.routine.steps[histDataKey].title}</p>
										</div>
										<div className="text-right w-10 d-flex align-items-center justify-content-end pr-3">
											<IconButton title="StepNotes Form" size="small" onClick={() => this.handleOpenStepsNotesForm(achievementDetail.routine && achievementDetail.routine.stepNotesId, histDataKey)} >
												<CommentIcon style={achievementDetail.routine && achievementDetail.routine.stepNotesId && this.handleStepId(histDataKey) ? {color: 'orange'} : {color: '#A5A7B2'}}/>
											</IconButton>
										</div>
									</li>
								))
							}
						</ul>
						{achievementDetail.endDate && typeof achievementDetail.routine.confirmationRewards === 'number' && !achievementDetail.confirmation &&
							<div className="top-head align-items-center justify-items-center" style={{backgroundColor: '#f5f5f5', padding: '1rem'}}>
								<h3>Satisfactory Completion?</h3>
								<div className="justify-content-end border-bottom-0 d-flex">
									<div className="mr-3 pl-4 pr-4" style={{border: '2px solid', borderRadius: 16, backgroundColor: 'white'}}>
										<Button size="small" style={{color: 'black'}}>No</Button>
									</div>
									<div className="pl-4 pr-4" style={{border: '2px solid', backgroundColor: 'yellow', borderRadius: 16}}>
										<Button size="small" style={{color: 'black'}}>Yes</Button>
									</div>
								</div>
							</div>
						}
					</div>
					:
					<p>No Detail available</p>
				}
			</div>
		);
	}
}

export default UnConfirmDetail;