/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preview from './components/Preview'
import queryString from 'query-string';
import LifeSherpaLoading from '../LifeSherpaLoading';
import moment from 'moment'
import SherpaCamera from "../SherpaCamera";

// redux action
import {
    getMyActivityById,
    getUserByIdConfigurator,
    getUserStatusByIdFromConfigurator,
    StartActivityPreviewConfigurator,
    resetReduxData,
    changeThemeColor,
    getLSThemeLogo,
    executeActivityForConfigurator
} from '../../actions';

class MyActivityPreview extends Component {
    state = {
        message: null
    }
    componentDidMount() {
        this.props.resetReduxData()
        const params = queryString.parse(this.props.location.search)
        if(params) {
            this.applyUserTheme(params);
        }
        if (params.userId && params.idToken && params.routineId && params.orgId && params.scope) {
            const { userId, idToken, routineId, orgId, scope } = params
            localStorage.setItem('idToken', idToken)
            localStorage.setItem('uid', userId)
            localStorage.setItem('orgId', orgId)
            console.log("activityMode --> ", params.activityMode);
            const timestamp = moment().format('YYYY-MM-DD HH:mm:ss Z');
            if(params.activityMode && params.activityMode == "execute") {
                this.props.executeActivityForConfigurator(routineId, timestamp, userId, orgId);
            } else {
                this.props.StartActivityPreviewConfigurator(routineId, timestamp, userId, orgId)
            }
            this.props.getUserByIdConfigurator(userId, idToken)
            this.props.getUserStatusByIdFromConfigurator(userId, idToken);
            this.setState({activityMode : params.activityMode});
        
        } else {  
            // all form test 2 = "-MZviSZpMUOQus0dQiHo"
            // routine with all card type = '-MYhw1oZM7sJDExZ5F2t'
            // attribute test activity = '-Me9HZXRTfKd2suTD1bD'
            // build a car = '-LLcH5Jg3KehfnTEFXj_'
            // Update your status = "-Mfat384xEAOJvAVQhOY"
            const routineId = "-Me9HZXRTfKd2suTD1bD" //"-Mfat384xEAOJvAVQhOY" "-Me9HZXRTfKd2suTD1bD"// '-LLcH5Ja8IgrxZkrBvEj' // -LLcH5Ja8IgrxZkrBvEj//'-LLcH5J_JYBRfzNS_yap' ////'-Me9HZXRTfKd2suTD1bD'//'-Mfat384xEAOJvAVQhOY'  // "-MW8R9RaPHJWiLCv1Yub" // 
            const userId = "xqekAeFd3qgpZnuxeilfQfiw7QT2"//'xqekAeFd3qgpZnuxeilfQfiw7QT2'
            const idToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImU3OTMwMjdkYWI0YzcwNmQ2ODg0NGI4MDk2ZTBlYzQzMjYyMjIwMDAiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiIiwiaXNHbG9iYWxBZG1pbkZvciI6eyJpcHdVWmU5eVU5OUV1NUhDa1d1NyI6dHJ1ZX0sIkRlbGV0ZUltYWdlcyI6dHJ1ZSwib3JnYW5pemF0aW9uIjoiVGVzdCIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9scy1jb25maWctbWlncmF0aW9uIiwiYXVkIjoibHMtY29uZmlnLW1pZ3JhdGlvbiIsImF1dGhfdGltZSI6MTY4MjQ4MDA5NCwidXNlcl9pZCI6InhxZWtBZUZkM3FncFpudXhlaWxmUWZpdzdRVDIiLCJzdWIiOiJ4cWVrQWVGZDNxZ3BabnV4ZWlsZlFmaXc3UVQyIiwiaWF0IjoxNjgyNDgzMzk1LCJleHAiOjE2ODI0ODY5OTUsImVtYWlsIjoibGlmZXNoZXJwYSthdXRoMHw1ZmNlMGYzYzViMzIzZjAwNmZhODQ2NDFAM3JiZWhhdmlvcmFsc29sdXRpb25zLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImxpZmVzaGVycGErYXV0aDB8NWZjZTBmM2M1YjMyM2YwMDZmYTg0NjQxQDNyYmVoYXZpb3JhbHNvbHV0aW9ucy5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.CoQhGcVDeEJCUF9N6k3wOhO_entwIblGbbnISwxZOQTJH5nOTorPR5jrOTfMBttPHsRMpu87Eg4XC5XgEcc3wgjtp9sk503fWgzZEkOGSjQvG0yrHUuVGoWkbewJX5hZWVLewlSw5iBPLFe0vYssJJfi0hZdopOvsKRfKwBJdJ5Ny3y3FLN4-HZzOdBz3DDYVc0fJYO3HbnJy3Hm_7bc6sfJAQhTeDb_-3TTPUukWb5p-O6Rx3umZz-DOWHEJImk7xLGTRk2dRtHYM-dcL-_Xz9GnYZ7SQCohmhvTVEhqqv1qGZkIYRRE-pQcYWu-pWRGFWYXaJeL7p5h67tdE57Og"
            const orgId = "Test"//'Test'
            const scope = 'Routine'
            const activityMode = ""
            localStorage.setItem('idToken', idToken)
            localStorage.setItem('uid', userId)
            localStorage.setItem('orgId', orgId)
            const timestamp = moment().format('YYYY-MM-DD HH:mm:ss Z')
            this.props.getUserByIdConfigurator(userId, idToken)
            this.props.getUserStatusByIdFromConfigurator(userId, idToken);
            if(activityMode == "execute") {
                this.props.executeActivityForConfigurator(routineId, timestamp, userId, orgId);
            } else {
                this.props.StartActivityPreviewConfigurator(routineId, timestamp, userId, orgId)
            }
            this.setState({activityMode : activityMode});
        }
    }
    applyUserTheme = (params) => {
        let theme = {};
        let userTheme = localStorage.getItem("theme");
        if (params && params.theme) {	
            theme['id'] = 7
            theme['name'] = params.theme
        } else if (userTheme) {
            theme = JSON.parse(userTheme);
        } else {
            theme['id'] = 1
            theme['name'] = 'primary'
        }
        if(params && params.theme)  {
            localStorage.setItem("theme",JSON.stringify(theme));
            localStorage.setItem("activeTheme",theme.id);
            this.props.getLSThemeLogo(theme);
            this.props.changeThemeColor(theme,this.props.themeData)
        } 
    }

    render() {
        const { loading, activity, showCamera } = this.props;
        const {activityMode} = this.state;
        return (
            <>
                <LifeSherpaLoading loading={this.props.loading || this.props.themeLoading} />
                <div className="row">
                    <div className="col-sm-12 col-md-2 col-lg-3 col-xl-4"></div>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                        {!this.props.themeLoading ?
                        <Preview 
                            loading={loading}
                            message={activity && activity.message ? activity.message : this.state.message}
                            themeLogo={this.props.themeLogo}
                            activityMode={activityMode}
                        /> :
                        <div className="loading-height-for-preveiw"></div>
                        }
                    </div>
                    {showCamera && <SherpaCamera/>}
                    <div className="col-sm-12 col-md-2 col-lg-3 col-xl-4"></div>
                </div>
            </>
        );
    }
}

// map state to props
const mapStateToProps = ({ MyActivity, settings,activitiesList, authUser }) => {
    const { popupThemeClass, themeLogo } = settings; 
    const { activity, loading } = MyActivity;
    const { loading1, showCamera } = activitiesList;
    const {themeLoading} = authUser;
    return { activity, loading ,popupThemeClass, themeLogo, loading1, themeLoading, showCamera}
}

export default connect(mapStateToProps, {
    getMyActivityById,
    getUserByIdConfigurator,
    getUserStatusByIdFromConfigurator,
    StartActivityPreviewConfigurator,
    resetReduxData,
    changeThemeColor,
    getLSThemeLogo,
    executeActivityForConfigurator
})(MyActivityPreview);