import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Label } from 'reactstrap'
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogTitle from "@mui/material/DialogTitle";


class ContactsFilterPopup extends Component {

    state = {
        filterOrgOptions: [],
    }

    componentDidMount() {
        const { selectedOrgs } = this.props
        console.log("selectedOrgs: ", selectedOrgs)
        if (selectedOrgs.length) {
            this.setState({ filterOrgOptions: [...selectedOrgs] });
        }
        
    }

   

    handelOrgIdFilter(id) {
        let { filterOrgOptions } = this.state
        if (filterOrgOptions && filterOrgOptions.length > 0) {
            const index = filterOrgOptions.findIndex(ele => ele === id)
            if (index === -1) {
                filterOrgOptions.push(id)
            } else {
                filterOrgOptions.splice(index, 1)
            }
        } else {
            const orgIds = [id]
            filterOrgOptions = orgIds
        }
        this.setState({ filterOrgOptions })
    }

    applyFilter() {
        let { filterOrgOptions } = this.state
        this.props.applyFilter([...filterOrgOptions])
    }

    clearFilter() {
        this.props.clearFilter();
    }

    render() {
        let { popupThemeClass, organizations, groups } = this.props;
        const { filterOrgOptions } = this.state

        return (
            <Dialog
                fullScreen={false}
                open={this.props.showFilter}
                onClose={() => this.props.handleCloseFilter()}
                fullWidth={true}
                maxWidth="sm"
                className={`${popupThemeClass}`}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <div data-testid="resource-filter-dialog" className="d-flex justify-content-between align-items-center py-2 px-3 bg-lifesherpa">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Filters</Label>
                        <IconButton data-testid="close-icon-for-filter-dialog" className="text-white" onClick={() => this.props.handleCloseFilter()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="pl-4 pr-4 activities-filter-dialog-content">
                    <div className="text-secondary filter-heading  fw-semi-bold my-2">Organizations</div>
                    <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                    >
                        {organizations && organizations.map((list, key) => (
                            <Grid item key={key}>
                                {filterOrgOptions && filterOrgOptions.length > 0 && filterOrgOptions.find(gid => gid == list.id) ?
                                    <Chip
                                        key={key}
                                        className={" mr-1 my-1 chipSelected "}
                                        avatar={<CheckCircleOutlinedIcon />}
                                        label={list.name}
                                        style={{ fontSize: "16px" }}
                                        onClick={() => this.handelOrgIdFilter(list.id)}
                                    />
                                    :
                                    <Chip
                                        key={key}
                                        className={" mr-1 my-1  chipStyle"}
                                        label={list.name}
                                        style={{ fontSize: "16px" }}
                                        onClick={() => this.handelOrgIdFilter(list.id)}
                                    />
                                }
                            </Grid>
                        ))}

                    </Grid>
                </DialogContent>
                <DialogActions className="p-4 filter-buttons-styles">
                    <Button color="primary" variant="outlined" data-testid="cancel-filter-button" className="  w-50 filter-button-cancel" onClick={() => this.clearFilter()}>
                        CLEAR FILTERS
                    </Button>
                    <Button color="primary" variant="contained" data-testid="apply-filter-button" className=" w-50 filter-button-apply" onClick={() => this.applyFilter()}>
                        APPLY
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }
}

const mapStateToProps = ({ settings, clientsList }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const { organizations } = clientsList
    return { screenThemeClass, popupThemeClass, organizations };
}

export default connect(mapStateToProps, {})(ContactsFilterPopup);