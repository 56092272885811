/**
 * Rct Theme Provider
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Auth0Provider } from "@auth0/auth0-react";
import queryString from 'query-string';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// App locale
import AppLocale from '../lang';
// themes
import primaryTheme from './themes/primaryTheme';
import darkTheme from './themes/darkTheme';
import secondaryTheme from './themes/secondaryTheme';
import warningTheme from './themes/warningTheme';
import dangerTheme from './themes/dangerTheme';
import infoTheme from './themes/infoTheme';
import successTheme from './themes/successTheme';
import theme1 from './themes/theme1';
import theme2 from './themes/theme2';
import theme3 from './themes/theme3';
import theme5 from './themes/theme5';
import config from "../config/config"
import {
	changeThemeColor,
	getLSThemeLogo
} from '../actions';

import {
	getCookies
} from '../helpers/helpers';
import LifeSherpaLoading from '../components/LifeSherpaLoading';

class RctThemeProvider extends Component {
	componentDidMount(){
		const {widgetApp} = this.props;
		if(widgetApp) {
            this.setWidgetThemeConfigurator();
		} else {
			this.setThemeConfiguration();
		}
	}

	setWidgetThemeConfigurator = () => {
		let theme = {}
		const params = this.getParams()
		let userTheme = localStorage.getItem("theme");
		if (params && params.theme) {	
			theme['id'] = 7
			theme['name'] = params.theme;
		} else if (userTheme) {
			theme = JSON.parse(userTheme);
			theme.id = 1;
		} else {
			theme['id'] = 1
			theme['name'] = 'Default'
		}
		localStorage.setItem("theme",JSON.stringify(theme));
		localStorage.setItem("activeTheme",theme.id);
		this.props.getLSThemeLogo(theme);
		this.props.changeThemeColor(theme,this.props.themeData)
	}

	setThemeConfiguration = () => {
		let theme = {}
		const params = this.getParams()
		let userTheme = localStorage.getItem("theme");
		if (params && params.theme) {	
			theme['id'] = 7
			theme['name'] = params.theme;
			if(window.location.pathname == "/launchscreen" || window.location.pathname == "/signinEmail") {
				theme['id'] = 8;
			}
		} else if (userTheme) {
			theme = JSON.parse(userTheme);
			theme.id = 1;
		} else {
			theme['id'] = 1
			theme['name'] = 'Default'
		}
		if((params && params.theme) || (!userTheme) || window.location.pathname == "/launchscreen" || window.location.pathname == "/signinEmail") {
			localStorage.setItem("theme",JSON.stringify(theme));
			localStorage.setItem("activeTheme",theme.id);
			if(window.location.pathname != "/lifesherp/videocall") {
				this.props.getLSThemeLogo(theme);
				this.props.changeThemeColor(theme,this.props.themeData)
			}
		} 
	}

	getParams(){
		let params = null
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        } else if (getCookies('objCookieData')) {
            params = JSON.parse(getCookies('objCookieData'))
        } 
        return params
	}

	darkenButtonColor(color, amount) {
		return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
	}

	render() {
		const { locale, darkMode, rtlLayout, activeTheme, children, domain, clientId, audience, email, connection, themeData , themeLoading, defaultAuth0LoginStatus, widgetApp, userLoading } = this.props;
		const currentAppLocale = AppLocale[locale.locale];
		const authoClientId = clientId["lsPortal"];
		// theme changes
		let theme = '';
		switch (activeTheme.id) {
			case 1:
				theme = primaryTheme
				break;
			case 2:
				theme = secondaryTheme
				break;
			case 3:
				theme = warningTheme
				break;
			case 4:
				theme = infoTheme
				break;
			case 5:
				theme = dangerTheme
				break;
			case 6:
				theme = successTheme
				break;
			case 7:
				theme = theme1
				break;
			case 8:
				theme = theme2
				break;
			case 9:
				theme = theme3
				break;
			case 10:
				theme = theme5
				break;
			default:
			theme = primaryTheme
				break;
		}
		if(themeData && themeData.primaryButtonNormalStateColor &&  themeData.alternativeSecondaryButtonNormalStateColor) {
			theme = createTheme({
			    palette: {
					primary: {
						main: themeData.primaryButtonNormalStateColor
					},
					secondary: {
						main: themeData.alternativeSecondaryButtonNormalStateColor
					},
					progress: {
						main: themeData.progressBarColor
					}
			   }
		    });
		   Object.keys(themeData).map(themeAttribute=> {
			    document.body.style.setProperty(`--${themeAttribute}`,themeData[themeAttribute])
		   });
		   if (themeData['primaryButtonNormalStateColor'] === themeData['headerBackgroundColor']) {
				document.body.style.setProperty(`--primaryButtonNormalStateBorderColor`,'#000000')
		   } else {
				document.body.style.setProperty(`--primaryButtonNormalStateBorderColor`,themeData['primaryButtonNormalStateColor'])
		   }
		   if(themeData['alternativeSecondaryButtonNormalStateColor']) {
			document.body.style.setProperty(`--alternativeSecondaryButtonSelectedStateColor`,this.darkenButtonColor(themeData['alternativeSecondaryButtonNormalStateColor'], -50))
		   }
		} else if(!theme) {
			theme = primaryTheme;
		}

		if (darkMode) {
			theme = darkTheme
		}
		if (rtlLayout) {
			theme.direction = 'rtl'
		} else {
			theme.direction = 'ltr'
		}
		console.log("themeLoading ->", themeLoading)
		console.log("userLoading ->", userLoading)
		if(widgetApp) {
			return (
				<ThemeProvider theme={theme}>
					<IntlProvider
						locale={currentAppLocale.locale}
						messages={currentAppLocale.messages}
					>
						<Fragment>
							<LifeSherpaLoading loading={themeLoading || userLoading} fullLoader={themeLoading}/>
							{children}
						</Fragment>
					</IntlProvider>
				</ThemeProvider>
			)
		}
		return (
			<Auth0Provider
				domain={domain}
				clientId={authoClientId}
				redirectUri={`${window.location.origin}/signin`}
				audience={audience}
				login_hint={email}
				connection={connection}
				useRefreshToken={false}
				>
				<ThemeProvider theme={theme}>
					<IntlProvider
						locale={currentAppLocale.locale}
						messages={currentAppLocale.messages}
					>
						<Fragment>
							<LifeSherpaLoading loading={themeLoading} fullLoader={themeLoading}/>
							{children}
						</Fragment>
					</IntlProvider>
				</ThemeProvider>
			</Auth0Provider>
		);
	}
 }
 
 // map state to props
const mapStateToProps = ({ settings, authUser }) => {	 
	const {userOrgDetail, userOrgFromToken, themeData , themeLoading, userLoading} = authUser;
	const params = queryString.parse(window.location.search);
	const fakaDataForAuth0 = config.defaultAuth0DataForForUSA
	const {auth0ClientConfig} = (userOrgDetail && userOrgDetail.auth0ClientConfig) ? userOrgDetail : (userOrgFromToken && userOrgFromToken.auth0ClientConfig)  ? userOrgFromToken : {auth0ClientConfig: fakaDataForAuth0}
	let {domain, clientId, audience, connection} =  auth0ClientConfig
	if(params && params.connection) {
		connection = params.connection;
	} 
	const { locale, darkMode, rtlLayout, activeTheme } = settings
	const {email} = userOrgDetail && userOrgDetail.email ? userOrgDetail : {email: params && params.email ? params.email : " "};
	localStorage.setItem('activeTheme', activeTheme.id)
	return {domain, clientId, audience, locale, darkMode, rtlLayout, activeTheme, email, connection, themeData , themeLoading, userLoading}
}
 
export default connect(mapStateToProps,{changeThemeColor, getLSThemeLogo})(RctThemeProvider);
 
