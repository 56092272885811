/**
 * Signin Firebase
 */

 import React, { Component } from 'react';
 import AppBar from '@mui/material/AppBar';
 import Toolbar from '@mui/material/Toolbar';
 import QueueAnim from 'rc-queue-anim';
 class UIFallbackScreen extends Component {
    render() {
      
       return (
          <QueueAnim type="bottom" duration={2000}>
             <div>
             <div className='login-screen'>
                <AppBar position="static" className="session-header">
                   <Toolbar>
                   <div className="container text-center pt-3">
                         <img src={require("Assets/img/life-sherpa-logo-retina.png")} alt="session-logo" width="auto" height="auto" style={{maxWidth: '100%', maxHeight: 120}} className='auto-resize-img' />
                    </div>
                   </Toolbar>
                </AppBar>
                <div  className="session-inner-wrapper bg-dark" >
                      <div className="row align-items-center" style={{height:"80px",}}>
                         <div className="col-12 text-white text-center">
                              <h1>Something went wrong..... </h1>
                         </div>
                      </div>
                </div>
                </div>
             </div>
          </QueueAnim>
       );
    }
 }
 
 // map state to props
 
 export default UIFallbackScreen;