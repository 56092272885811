import {
    SET_SELECTED_UNCONFIRM_ACTIVITIES,
    SET_UNCONFIRM_ACTIVITIES_LIST,
    SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST,
    SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL,
    SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL,
    SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA,
    SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT,
    SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR,
    SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST,
    HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST,
    UNCONFIRM_loading
} from '../actions/types';

const INITIAL_STATE = {
    allUnConfirmActivities: null,
    selectedUnConfirmActivity: null,
    unConfirmActivityDetail: null,
    selectedUnConfirmAchievemntId: null,
    loading: false,
    hasMoreData: true,
    pageCount: 1,

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // get recent chat user
        case SET_SELECTED_UNCONFIRM_ACTIVITIES:
            return { ...state, selectedUnConfirmActivity: action.payload };
        case SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR:
            return {...state, pageCount: 1, hasMoreData: true, unConfirmActivityDetail: null, allUnConfirmActivities: null, selectedUnConfirmActivity: null}
        case SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT:
            return { ...state, pageCount: action.payload };
        case SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA:
            return { ...state, hasMoreData: action.payload, pageCount };
        case SET_UNCONFIRM_ACTIVITIES_LIST:
            //let loading = true
            return { ...state, hasMoreData: true, loading:false };
        case SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST:
            console.log("action.payload",action.payload)
            let hasMoreData = action.payload.length > 0 ? true : false
            let pageCount = action.payload.length > 0 ? state.pageCount : 1
            let allUnConfirmActivities =  state.allUnConfirmActivities ? state.allUnConfirmActivities.concat(action.payload) : action.payload
            return { ...state, allUnConfirmActivities:allUnConfirmActivities, loading: false, pageCount: state.pageCount + 1, hasMoreData};
        case SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL:
            return { ...state, loading: true  };
        case SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL:
            return { ...state, unConfirmActivityDetail: action.payload ,loading: false  };
        // show loading 
        case SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST:
            return { ...state, loading: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST:
            return { ...state, loading: false };

        default: return { ...state };
    }
}
