/**
 * Email List Item
 */
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const MainLandingPageItem = ({ activity, userId, itemKey, handleShowActivitiesList, showActivitiesList, inProgressActivitiesCount }) => (
    <div style={{ marginLeft: "15px" }} className="mb-0 mt-2 " key={itemKey + "user5ListItem"}>


    
        <div className="row   actvity-routine-simple">
            <div className="col-12">
                <div style={{ marginLeft: "5px" }} className="pl-3  pt-2  my-auto" >
                    <h5 className="" style={{ marginBottom: "0px", fontWeight: "400" }}>{activity.title || "" }</h5>
                </div>
            </div>
            <div style={{ paddingLeft: "0px" }} className={activity.state === "paused" || activity.state === "inProgress" ? "col-12 pr-0" : "col-12 pr-0"}>
                {activity.state === "paused" || activity.state === "inProgress" || activity.state === undefined ?
                    <div data-testid="my-clients-first-inprogress-activities"  className="d-flex  justify-content-between w-100 align-items-center">
                        <div className="d-flex w-100 align-items-center   mt-2 font-sm" style={{ paddingLeft: '5px', whiteSpace: 'nowrap' }}>
                            <div><div className={"rounded-circle  mr-1 " + (activity.state === "paused" || activity.state === undefined ? "activity-icon-progress" : "activity-icon-progressa")}>
                                {activity.state === "paused"  || activity.state === undefined ? "P" : "A"}</div></div>
                            <div className="activity-step" >{activity.nextStep ? activity.nextStep : 'Step : Start'}</div>
                        </div>
                        <div className=" text-right   pr-0  msg-count d-flex ml-auto mr-0 align-items-right client-list-activities-dropdown-icon">
                            {inProgressActivitiesCount > 1 ?
                            <div style={{ height: "19px", width: "15px" }} className=" m-0 p-0">
                                {  showActivitiesList.findIndex(ele => ele == userId) >= 0 ?
                                 <KeyboardArrowUpIcon data-testid="clients-inprogress-activities-list-button" style={{ margin: "0px", padding: "0px", cursor: "pointer", marginLeft: "0px" }} className="m-0 p-0" onClick={handleShowActivitiesList} /> : <KeyboardArrowDownIcon data-testid="clients-inprogress-activities-list-button" style={{ margin: "0px", padding: "0px", cursor: "pointer" }} className="m-0 p-0" onClick={handleShowActivitiesList} />
                                }
                            </div>
                            :""}
                        </div>
                    </div>
                    : ''}
                {activity.state === "paused" || activity.state === "inProgress" || activity.state === undefined ?
                    <div style={{ marginLeft: "33px" }} className="mt-1 row pr-2  pb-1">
                        <div style={{ width: ((230 / activity.count) * activity.completed) + 'px', height: 5 + 'px', borderRadius: 2 + 'px' }} className="activity-completed"></div>
                        <div style={{ width: ((230 / activity.count) * (activity.count - activity.completed)) + 'px', background: 'lightgray', height: 5 + 'px', borderBottomRightRadius: 2 + 'px', borderTopRightRadius: 2 + 'px' }}></div>
                    </div> : ''}
            </div>
        </div>
    </div>
);

export default MainLandingPageItem;
