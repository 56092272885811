/**
 * Clients List Actions
 */

import {axiosClient} from '../sherpaApi'
import { NotificationManager } from 'react-notifications';
import { Client as ConversationsClient } from "@twilio/conversations";
import {openPopup} from "../helpers/helpers"
 // import qs from "qs";   
 import {
    GET_HELP_CONTACTS,
    GET_HELP_CONTACTS_SUCCESS,
    GET_VIDEO_GROUPS,
    GET_VIDEO_GROUPS_SUCCESS,
    SELECT_VIDEO_GROUPS,
    SELECTED_VIDEO_GROUPS,
    GET_CHAT_TOKEN,
    GET_VIDEO_TOKEN,
    GET_CHANNEL_INFO,
    GET_CHAT_LOADING,
    GET_HELP_CONTACTS_FAILED,
    HIDE_CHAT_LOADING,
    UPDATE_CONTACTS_LIST,
    ADD_SELECTED_CHAT_USER,
    HIDE_CHAT_PANEL,
    SET_TWILIO_CLIENT,
    SHOW_VIDEO_ROOM,
    HIDE_VIDEO_ROOM,
    GET_VIDEO_CALL_DETAILS_SUCCESS,
    SHOW_VIDEO_CALL_CONFIRMATION,
    HIDE_VIDEO_CALL_CONFIRMATION,
    CONNECT_TWILIO_VIDEO_CALL,
    CONNECT_TWILIO_VIDEO_CALL_SUCCESS,
    GET_VIDOE_TOKEN_LOADING,
    HIDE_VIDOE_TOKEN_LOADING,
    GET_SELECTED_VIDEO_CAHT,
    SHOW_MISSED_CALL_CONFIRMATION,
    HIDE_MISSED_CALL_CONFIRMATION,
    ADD_NEW_VIDEO_GROUP_DETAILS,
    ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS,
    ADD_NEW_VIDEO_GROUP_DETAILS_FAILED,
    SET_SELECTED_GROUP_VIDEO_CALL_DETAILS,
    GET_VIDEO_GROUPS_FAILED,
    GET_CLIENT_CONTACTS,
    GET_CLIENT_CONTACTS_SUCCESS,
    GET_CLIENT_CONTACTS_FAILED
 } from './types';

 /**
  * Redux Action To Emit Boxed Layout
  * @param {*boolean} isBoxLayout  */ 
 export const getHelpContacts = () => (dispatch) => {
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken')
    const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
    dispatch({type: GET_HELP_CONTACTS});
    dispatch({type: ADD_SELECTED_CHAT_USER, payload:[]}); 
    //dispatch({type: HIDE_CHAT_PANEL});
    let url = `/users/${uid}/help-contacts?organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/help-contacts
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:GET_HELP_CONTACTS_SUCCESS,payload:response.data})
    }).catch((error) => {
        dispatch({type:GET_HELP_CONTACTS_FAILED})
       console.log("error occured ", error);
     })
 }
 export const getVideoGroups= () => (dispatch) => {
   let uid = localStorage.getItem('uid')
   let idToken = localStorage.getItem('idToken')
   const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
   dispatch({type:GET_VIDEO_GROUPS})
   let url = `/users/${uid}/video-call-groups?asHost=true&organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/video-call-groups
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" fetched group list --> ", response.data)
        dispatch({type:GET_VIDEO_GROUPS_SUCCESS,payload:response.data})
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({type:GET_VIDEO_GROUPS_FAILED})
     })
}

export const addNewVideoGroup=(data, orgId)=>(dispatch)=>{
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${uid}/video-call-groups?organization=${orgId}` ///users/{userId}/video-call-groups
    let members = []; 
    if(data.members && data.members.length > 0) {
        data.members.map(member => {
            let user = {userId:member.userId,name: member.name};
            members.push(user);
        })
    }
    data.members = members;
    console.log(" group data --> ", data);
    dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS});
    axiosClient.post(url, data , {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.data) {
            const responseData = response.data;
            console.log(" video group added -->",responseData);
            let groupDetails = responseData.data;
            groupDetails.id = responseData.groupId;
            NotificationManager.success(responseData.message);
            dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS, payload:groupDetails});
        } else {
            dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS_FAILED});
        } 
        //dispatch(getSelectGroup(groupId))
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS_FAILED});
     })
}
export const editVideoGroupDetails=( groupId, data, orgId) => (dispatch)=>{
    let uid = localStorage.getItem('auth_uid')
   let idToken = localStorage.getItem('idToken');
   let url = `/users/${uid}/video-call-groups/${groupId}?organization=${orgId}` ///users/{userId}/video-call-groups/{groupId}
   let members = []; 
   if(data.members && data.members.length > 0) {
       data.members.map(member => {
           let user = {userId:member.userId,name: member.name};
           members.push(user);
       })
   }
   dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS});
   data.members = members;
   console.log(" group data --> ", data);
   axiosClient.post(url,data, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" updated group details --> ", response.data)
        if(response.data) {
            const responseData = response.data;
            console.log(" video group added -->",responseData);
            let groupDetails = responseData.data;
            groupDetails.id = responseData.groupId;
            NotificationManager.success(responseData.message);
            dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS, payload:groupDetails});
        } else {
            dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS_FAILED});
        } 
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({type: ADD_NEW_VIDEO_GROUP_DETAILS_FAILED});
     })
}
export const deleteVideoGroup=(groupId, orgId) => (dispatch)=>{
    let uid = localStorage.getItem('auth_uid')
   let idToken = localStorage.getItem('idToken')
   let url = `/users/${uid}/video-call-groups/${groupId}?organization=${orgId}` 
   dispatch({type: GET_VIDEO_GROUPS});
   axiosClient.delete(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" group deleted --> ", response.data)
        if(response.data) {
            dispatch(getVideoGroups());
            NotificationManager.success(response.data.message);
        } else {
            dispatch({type: GET_VIDEO_GROUPS_FAILED});
        } 
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({type: GET_VIDEO_GROUPS_FAILED});
     })
}
export const setSelectedGroupVideoCallDetails = (details) =>(dispatch)=>{
   dispatch({type: SET_SELECTED_GROUP_VIDEO_CALL_DETAILS, payload: details})
}

export const generateToken=(userId)=>(dispatch)=>{
    let uid = localStorage.getItem('auth_uid');
    let idToken = localStorage.getItem('idToken')
    dispatch({type:GET_CHANNEL_INFO,payload:null});
    dispatch({type:GET_CHAT_LOADING})
    let url = `/users/${uid}/chat/token` //users/{userId}/chat/token
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Chat Token generated..")
        dispatch(getchannelinfo(userId))
        dispatch({type:GET_CHAT_TOKEN,payload:response.data.token})

    }).catch((error) => {
    console.log("error occured ", error);
    dispatch({type:HIDE_CHAT_LOADING})
    })
}

export const getchannelinfo=(userId)=>(dispatch)=>{
   let idToken = localStorage.getItem('idToken')
   let url = `/users/${userId}/chat/channel` //users/{userId}/chat/channel
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Fetched channel info ----->",response.data);
        dispatch({type:GET_CHANNEL_INFO,payload:response.data});
        dispatch({type:HIDE_CHAT_LOADING})
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({type:HIDE_CHAT_LOADING})
    })
}


//get twilio token
export const getTwilioChatToken = () => {
    let uid = localStorage.getItem('auth_uid');
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${uid}/chat/token`
    return axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => response.data && response.data.token)
}

//get client chat room details
export const getClientChatRoomDetails = (userId) => {
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/chat/channel` //users/{userId}/chat/channel
    return axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => response.data)
}

export const getVideoToken=()=>(dispatch)=>{
    let uid = localStorage.getItem('uid')
   let idToken = localStorage.getItem('idToken')
   let url = `/users/${uid}/video-token` //users/{userId}/video-token
   dispatch({type:GET_VIDOE_TOKEN_LOADING})
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Fetched vidoe token ");
        dispatch({type:GET_VIDEO_TOKEN,payload:response.data});
    }).catch((error) => {
        dispatch({type:HIDE_VIDOE_TOKEN_LOADING})
        console.log(" error occured while fetching video token ", error);
     })
}

export const removeVideoToken=()=>(dispatch)=>{
    dispatch({type:GET_VIDEO_TOKEN,payload: null});
}

export const notifyGroup=(roomname,groupId,members)=>(dispatch)=>{
    // const fcmToken = localStorage.getItem("fcmToken");
    // console.log(" twilio client fcmToken --> ", fcmToken);
    let idToken = localStorage.getItem('idToken');
    const orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    let url = `/users/notify/group-video-call?roomname=${roomname}&videoCallGroupId=${groupId}&organization=${orgId}` // &deviceId=${fcmToken} //users/notify/group-video-call
    
    axiosClient.post(url,{recipientId:members}, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    })
    .then((response) => {
        console.log(" notification response -->",response)
        console.log(" group notified ---> ",groupId," room --->",roomname );
        dispatch(getVideoCallDetails(roomname))
    }).catch((error) => {
        console.log("error occured while notity group ", error);
        dispatch(getVideoCallDetails(roomname))
     })
}

export const notifyUser=(id,roomname)=>(dispatch)=>{
    // const fcmToken = localStorage.getItem("fcmToken");
    // console.log(" twilio client fcmToken --> ", fcmToken);
    const selectedOrgId = localStorage.getItem("selectedOrgId")
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${id}/notify/video-call?roomname=${roomname}&organization=${selectedOrgId}`;
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" notification response -->",response)
        console.log(" user notified ---> ",id," room --->",roomname );
        dispatch(getVideoCallDetails(roomname))
    }).catch((error) => {
        console.log("error occured while notify user ", error);
        dispatch(getVideoCallDetails(roomname))
     })
}

export const generateTwilioTokenForContacts= (contactList)=> (dispatch) => {
    let uid = localStorage.getItem('auth_uid')
   let idToken = localStorage.getItem('idToken')
   dispatch({type:GET_CHAT_LOADING})
   let url = `/users/${uid}/chat/token` //users/{userId}/chat/token
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Chat Token Fetched --> ", response.data);
        if(response.data && response.data.token) {
            const chatToken = response.data.token;
            dispatch({type:GET_CHAT_TOKEN,payload:chatToken});
            dispatch(createTwilioClient(contactList, chatToken))
        } else {
            dispatch({type:HIDE_CHAT_LOADING});
        }
    }).catch((error) => {
       console.error(" error occured while fetching twilio token ", error); 
       dispatch({type:HIDE_CHAT_LOADING})
     })
}
export const createTwilioClient = (contactList, chatToken) => async (dispatch) => {
    const client = new ConversationsClient(chatToken);
    console.log("[ContactAction] conversation client created --> ", client);
    client.on('stateChanged', (state) => {
        if (state === 'initialized') {
            console.log("[ContactAction] Client state initialized --> ")
             dispatch(setTwilioClient(client))
             //dispatch({type:HIDE_CHAT_LOADING});
             dispatch(updateContactListWithConversations(contactList, client))       
        }
    })
    // ConversationsClient.create(chatToken).then(client => {
    //     console.log(" conversation client created --> ", client);
    //     dispatch(setTwilioClient(client))
    //     dispatch(updateContactListWithConversations(contactList, client))
    // }).catch( error => {
    //     console.error(" error occured while creating twilio chat client --> ", error);
    //     dispatch({type:HIDE_CHAT_LOADING});
    // })
    // Chat.Client.create(chatToken).then(client => {
    //     console.log(" twilio client created --> ", client);
    //     dispatch(setTwilioClient(client))
    //     dispatch(updateContactListWithChannels(contactList, client, chatToken))
    // }).catch( error => {
    //     console.error(" error occured while creating twilio chat client --> ", error);
    //     dispatch({type:HIDE_CHAT_LOADING});
    // })
}

export const updateContactListWithConversations = (contactList, client) => (dispatch) => {
    client.getSubscribedConversations().then( async (paginator) => {
        const auth_uid = localStorage.getItem("auth_uid");  
        console.log(" getSubscribedConversations --> ", paginator);
        paginator.items && paginator.items.map(conversation => {
            let chatUserIndex = contactList.findIndex(user => {
                return `${user.userId}-${auth_uid}` == conversation.uniqueName || `${auth_uid}-${user.userId}` == conversation.uniqueName;
            })
            if(chatUserIndex >= 0) {
                let chatUser = contactList[chatUserIndex];
                chatUser.conversation = conversation;
                contactList[chatUserIndex] = chatUser;
            }
        });
        const promises = [];
        async function getConversion(contact) {
            if(!contact.conversation) {
                let uniqueName = [contact.userId, auth_uid].join("-");
                try {
                    contact.conversation = await client.getConversationByUniqueName(uniqueName);
                } catch(error) {
                    try {
                        uniqueName = [auth_uid, contact.userId].join("-");
                        contact.conversation = await client.getConversationByUniqueName(uniqueName);
                    } catch(error) {
                        console.log("[ContactAction] conversation not found with uniquename ", uniqueName);
                    } 
                }
            }
        }
        for(const contact of contactList) {
            promises.push(getConversion(contact));
        }
        await Promise.all(promises);
        dispatch({type:UPDATE_CONTACTS_LIST,payload:contactList});
        console.log(" client list updated with conversations --> ", contactList);
        dispatch({type:HIDE_CHAT_LOADING});
    }).catch( error => {
        console.error(" error occured while getSubscribedConversations --> ",error)
        dispatch({type:HIDE_CHAT_LOADING});
    })
}

export const updateContactListWithChannels = (contactList, client) => (dispatch) => {
    client.getSubscribedChannels().then((paginator) => {
        const auth_uid = localStorage.getItem("auth_uid");  
        paginator.items && paginator.items.map(channel => {
            let chatUserIndex = contactList.findIndex(user => {
                return `${user.userId}-${auth_uid}` == channel.uniqueName || `${auth_uid}-${user.userId}` == channel.uniqueName;
            })
            if(chatUserIndex >= 0) {
                let chatUser = contactList[chatUserIndex];
                chatUser.channel = channel;
                contactList[chatUserIndex] = chatUser;
            }
       });
       dispatch({type:UPDATE_CONTACTS_LIST,payload:contactList});
       console.log("[ContactAction] client list updated with channels --> ", contactList);
       dispatch({type:HIDE_CHAT_LOADING});
    }).catch( error => {
        console.error(" error occured while updating contacts with channel --> ",error)
        dispatch({type:HIDE_CHAT_LOADING});
    })
}
export const getHelpContactsWithChannel = () => (dispatch) => {
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken')
    const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
    dispatch({type: GET_HELP_CONTACTS});
    dispatch({type: ADD_SELECTED_CHAT_USER, payload:[]}); 
   // dispatch({type: HIDE_CHAT_PANEL});
    let url = `/users/${uid}/help-contacts?organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/help-contacts
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:UPDATE_CONTACTS_LIST,payload:response.data});
        dispatch(generateTwilioTokenForContacts(response.data))
    }).catch((error) => {
        dispatch({type:GET_HELP_CONTACTS_FAILED})
       console.error(" error occured while fetching contacts list ", error);
     })
 }


 export const getContacts = () => (dispatch) => {
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken')
    dispatch({type: GET_HELP_CONTACTS});
    dispatch({type: ADD_SELECTED_CHAT_USER, payload:[]}); 
    const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
   // dispatch({type: HIDE_CHAT_PANEL});
    let url = `/users/${uid}/help-contacts?organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/help-contacts
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:UPDATE_CONTACTS_LIST,payload:response.data});
    }).catch((error) => {
        dispatch({type:GET_HELP_CONTACTS_FAILED})
        console.error(" error occured while fetching contacts list ", error);
     })
 }

export const updateContactsList= (list) => (dispatch) => { 
        dispatch({type:UPDATE_CONTACTS_LIST,payload:list})
}

export const setTwilioClient = (client) => (dispatch) => {
    const fcmToken = localStorage.getItem("fcmToken");
    if(fcmToken) {
        client.setPushRegistrationId('fcm', fcmToken);
        console.log("[ContactAction] Twilio notifications enabled for fcmToken");
    } 
    dispatch({type:SET_TWILIO_CLIENT,payload:client});
    client.on("tokenAboutToExpire", async () => {
        console.log("[Contact Action] Twilio Token is about to expire update it.")
        dispatch(generateTokenToUpdateTwilioClient(client))
    });
    client.on("tokenExpired", async () => {
        console.log("[Contact Action] Twilio Token is expired update it.")
        dispatch(generateTokenToUpdateTwilioClient(client))
    });
}
export const generateTokenToUpdateTwilioClient=(client)=>  (dispatch)=>{
    let uid = localStorage.getItem('uid')
   let idToken = localStorage.getItem('idToken')
   let url = `/users/${uid}/chat/token` //users/{userId}/chat/token
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then( async (response) => {
        await client.updateToken(response.data.token);
        console.log("[Contact Action] Chat Token Updated")
    }).catch((error) => {
       console.log("error occured while genterating token to update twilio client", error);
       dispatch({type:HIDE_CHAT_LOADING});
     })
}
export const addChannelToContactList = (contactList, conversation, userId) => (dispatch) => {
    let index = contactList.findIndex(user => user.userId == userId);
    if(index >= 0) {
        let userData = contactList[index];
        userData.conversation = conversation;
        contactList[index] = userData;
    }
    dispatch({type:UPDATE_CONTACTS_LIST,payload:contactList});
}
export const hideContactsLoading = () => (dispatch) => {
    dispatch({type:HIDE_CHAT_LOADING});
}


export const showVideoRoomPanel = (selectedVideoChat) => (dispatch) => {
    // if(selectedVideoChat && selectedVideoChat.name) {
    //     dispatch(getVideoCallDetails(selectedVideoChat.name));
    // }
    dispatch({type:SHOW_VIDEO_ROOM, payload:selectedVideoChat});
}
export const getSelectedVideoChat = (selectedVideoChat) => (dispatch) => {
    dispatch({type: GET_SELECTED_VIDEO_CAHT, payload:selectedVideoChat});
}

export const hideVideoRoomPanel = () => (dispatch) => {
    dispatch({type:HIDE_VIDEO_ROOM});
}
export const registerDeviceForNotification= (payload) => (dispatch) => {
    console.log(" payload data -->", payload);
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${uid}/app-data` //users/{userId}/help-contacts
    axiosClient.post(url,payload, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" registered app data -->", response);
    }).catch((error) => {
        console.error(" error occured while register app data ", error);
     })
 }

 export const getVideoCallDetails=(roomName)=>(dispatch)=>{
    let uid = localStorage.getItem('auth_uid');
   let idToken = localStorage.getItem('idToken');
   const orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
   let url = `/users/${uid}/video-call-rooms/${roomName}?organization=${orgId}` //users/{userId}/video-token
    //dispatch({type: GET_CHAT_LOADING});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("Fetched Video call details --> ",response.data)
        dispatch({type:GET_VIDEO_CALL_DETAILS_SUCCESS,payload:response.data})
        dispatch({type:CONNECT_TWILIO_VIDEO_CALL_SUCCESS})
    }).catch((error) => {
       // dispatch({type:HIDE_CHAT_LOADING});
       dispatch({type:CONNECT_TWILIO_VIDEO_CALL_SUCCESS})
        console.log(" error occured while fething video call details ", error);
     })
}
export const showVideoCallConfirmation=(videoCallMessage)=>(dispatch)=>{
        dispatch({type:SHOW_VIDEO_CALL_CONFIRMATION, payload: videoCallMessage})
}
export const hideVideoCallConfirmation=(openVideoCallRoom)=>(dispatch)=>{
    dispatch({type:HIDE_VIDEO_CALL_CONFIRMATION});
    setTimeout(() => {
        if(openVideoCallRoom) {
            // window.open(openVideoCallRoom,"_blank");
            var windowReference = window.open();
            windowReference.location = openVideoCallRoom;
            openPopup(windowReference)
        }
    }, 1000);
   
}
export const sendVideoCallResponse=(roomname, action, orgId)=>(dispatch)=>{
    const fcmToken = localStorage.getItem("fcmToken");
    console.log(" twilio client fcmToken --> ", fcmToken);
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken');
    console.log(" Video call --->",action)
    const selectedOrgId = orgId || localStorage.getItem("selectedOrgId")
    let url = `/users/${uid}/notify/video-call?roomname=${roomname}&response=${action}&organization=${selectedOrgId}`;
    if(fcmToken) {
         url = `/users/${uid}/notify/video-call?roomname=${roomname}&response=${action}&deviceId=${fcmToken}&organization=${selectedOrgId}`
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Sent vidoe call response -->",response);
    }).catch((error) => {
        console.error(" error occured while sending vidoe call response ", error);
     })
}
export const sendGroupVideoCallResponse=(roomname, action, recipients = null, orgId)=>(dispatch)=>{
    const fcmToken = localStorage.getItem("fcmToken");
    console.log(" twilio client fcmToken --> ", fcmToken);
    let uid = localStorage.getItem('auth_uid')
    let idToken = localStorage.getItem('idToken');// &videoCallGroupId=${groupId}
    const selectedOrgId = orgId || localStorage.getItem("selectedOrgId")
    let url = `/users/notify/group-video-call?roomname=${roomname}&response=${action}&organization=${selectedOrgId}`;
    if(fcmToken) {
        url = `/users/notify/group-video-call?roomname=${roomname}&response=${action}&deviceId=${fcmToken}&organization=${selectedOrgId}` 
    } 
    axiosClient.post(url,recipients, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Video call --->",action)
    }).catch((error) => {
        console.error(" error occured while sending vidoe call response ", error);
     })
}

export const getVideoCallerInfoAndOpenConfirmation=(roomname,callerId)=>(dispatch)=>{
    let uid = localStorage.getItem('auth_uid');
   let idToken = localStorage.getItem('idToken');
   const orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
   let url = `/users/${uid}/video-call-rooms/${roomname}?organization=${orgId}` //users/{userId}/video-token
    //dispatch({type: GET_CHAT_LOADING});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Video call details --> ",response.data, callerId);
        dispatch({type:GET_VIDEO_CALL_DETAILS_SUCCESS,payload:response.data})
        if(response.data && response.data.members) {
            let member= response.data.members.find(user => user.userId == callerId);
            let videoUser = {...member, roomname:roomname,callerId:callerId }
            dispatch({type:SHOW_VIDEO_CALL_CONFIRMATION, payload: videoUser})
        }
    }).catch((error) => {
       // dispatch({type:HIDE_CHAT_LOADING});
        console.log(" error occured while fething video call details ", error);
     })
}
export const connectTwilioVideoCall=()=>(dispatch)=>{
    dispatch({type:CONNECT_TWILIO_VIDEO_CALL})
}
export const connectTwilioVideoCallSuccess=()=>(dispatch)=>{
    dispatch({type:CONNECT_TWILIO_VIDEO_CALL_SUCCESS})
}
export const showMissedCallConfirmation=(videoCallMessage)=>(dispatch)=>{
    dispatch({type:HIDE_VIDEO_CALL_CONFIRMATION});
    dispatch({type:SHOW_MISSED_CALL_CONFIRMATION, payload: videoCallMessage})
}
export const hideMissedCallConfirmation=(openVideoCallRoom)=>(dispatch)=>{
    dispatch({type:HIDE_MISSED_CALL_CONFIRMATION});
    setTimeout(() => {
        if(openVideoCallRoom) {
            // window.open(openVideoCallRoom,"_blank");
            var windowReference = window.open();
            windowReference.location = openVideoCallRoom;
            openPopup(windowReference)
        }
    }, 1000);
}

export const getClientContacts = (uid) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('auth_uid')
    dispatch({type: GET_CLIENT_CONTACTS});
    const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
    let url = `/users/${uid}/help-contacts?role=Client&organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/help-contacts
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("client contacts: ", response.data);
        dispatch({type: GET_CLIENT_CONTACTS_SUCCESS,payload:response.data})
    }).catch((error) => {
        dispatch({type: GET_CLIENT_CONTACTS_FAILED})
        console.log("error occured ", error);
     })
 }



