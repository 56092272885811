import React, { Component } from "react";
import LifeSherpaLoading from '../LifeSherpaLoading';
import { getLmsLoginUrl, setLmsLogoutUrl, lmsOpenedInIframe } from "../../actions";
import { connect } from 'react-redux';
import Iframe from "./iframe.js";

class LmsIFrame extends Component {
    state = {
        loading: true,
    }

    hideSpinner = () => {
        console.log("%c LMS loaded successfully............","color:green");
        this.setState({ ...this.state, loading: false });
    }

    componentDidMount() {
        let { lmsUrl, lmsUrlLoading } = this.props;
        this.props.getLmsLoginUrl();
        this.props.lmsOpenedInIframe();
    }

    componentWillUnmount() {
        let { lmsUrl, lmsUrlLoading } = this.props;
        lmsUrl = lmsUrl ? lmsUrl.replace("autologin", "logout") : lmsUrl;
        this.props.setLmsLogoutUrl(lmsUrl);
    }

    render() {

        let { lmsUrl, lmsUrlLoading, lmsLogoutUrl, orgDetail, userData } = this.props;
        let { loading } = this.state;
      //  console.log(lmsUrlLoading, lmsUrl)
        return (
            <div data-testid="LMS-iframe-component">
                <LifeSherpaLoading loading={loading} />
                {lmsUrlLoading ?
                    <div style={{ visibility: "hidden" }}>
                        <iframe data-testid="LMS-iframe" src={lmsLogoutUrl} className="w-100" height="calc(100vh - 100px)"></iframe>
                    </div>
                    :
                    <div data-testid="LMS-iframe" style={{ visibility: loading ? "hidden" : "visible" }}>
                        <Iframe
                            url={lmsUrl}
                            onLoad={this.hideSpinner}
                            height="calc(100vh)"
                            scrolling="yes"
                            id="myId"
                            className="w-100"
                            display="initial"
                            position="relative"
                            allowFullScreen
                        />
                    </div>
                }
            </div>

        )
    }
}
const mapStateToProps = ({ authUser }) => {
    const { lmsUrl, lmsUrlLoading, lmsLogoutUrl } = authUser
    return { lmsUrl, lmsUrlLoading, lmsLogoutUrl }
}

export default connect(mapStateToProps, {
    getLmsLoginUrl, setLmsLogoutUrl, lmsOpenedInIframe
})(LmsIFrame)
