/**
 * Ecommerce Dashboard
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import queryString from 'query-string';
 
 import Moment from 'moment'; 
 
 // rct collapsible card
 import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
 import ActivityDetails from "../../../components/UserDetails/components/ActivityDetails";

 //Actions
 import {
     selectActivityId,
     selectActivity,
     handlEditor,
     changeRole,
     showClientPanel,
     getlaunchpad,
     getCourseLaunchURL,
     getActivitiesList,
     getUserById,
     selectMenuItem,
     showAssignActivityIFramePanel,
     getActivityGroupsList,
     executeActivityWithAchievementId,
     networkActivity,
     hideActivityHistoryDetail,
     handleHideActivityDetailsPanel,
     getactivitiesCalendar,
     getHelpContacts
 } from '../../../actions'
 
 import {
     MyClientsPanel
 } from "../../../components/Widgets";
 
 import UserDetails from '../../../components/UserDetails';
 import AssignActivityComponent from "../../../components/AssignActivity";
 import { withAuth0 } from "@auth0/auth0-react";
 
 import AchievementHistoryList from "../../../components/AchievementHistoryList"
 
 class Clients extends Component {
 
     constructor(props) {
         super(props)
         this.state = {
             role: null,
             openChart: false,
             firebaseToken: null,
             activityId: '',
             activityType: '',
             previewId: null,
             showDetails: '',
             coursePanel: false,
             assingnActivityPanel: false,
             openEditor:false,
             editorType:null
         }
     }
     showUserDetails = (isShowClientDetail) => {
         if (isShowClientDetail) {
             console.group("%c[UserDetails]%c Rendered user details panel", "color:black", "color:green");
         } else {
             console.log("%c[UserDetails]%c Closed user details panel", "color:black", "color:red");
             console.groupEnd();
         }
         let auth_uid = localStorage.getItem('auth_uid');
         const orgId = localStorage.getItem("orgId")
         if (this.props.clientPanel) {
             localStorage.setItem('uid', auth_uid);
             localStorage.setItem("selectedOrgId", orgId)
             this.props.changeRole({ role: 'admin', id: auth_uid })
             this.props.getActivityGroupsList(auth_uid, [orgId]);
             this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
             this.props.getactivitiesCalendar(auth_uid);
             this.props.handleHideActivityDetailsPanel();
         }
         this.props.showClientPanel(isShowClientDetail);
     }
 
     componentDidMount() {
         this.handleOpenNotificationStartActivity();
         this.props.hideActivityHistoryDetail()
         // localStorage.setItem('selectedIndex', 0)
         const {widgetApp} = this.props;
         this.props.selectMenuItem(0)
         const auth_uid = localStorage.getItem('auth_uid');
         const uid = localStorage.getItem('uid');
         const idToken = localStorage.getItem('idToken')
         const orgId = localStorage.getItem("orgId")
         // this.props.networkActivity();
         if (uid != auth_uid) {
             localStorage.setItem('uid', auth_uid);
             localStorage.setItem("selectedOrgId", orgId)
             this.props.showClientPanel(false);
             this.props.changeRole({ role: 'admin', id: auth_uid });
             this.props.handleHideActivityDetailsPanel();
         }
         if (this.props.assingnActivityPanel) {
             this.props.showAssignActivityIFramePanel(false)
         }
         if (idToken && auth_uid) {
            this.props.selectActivity(false)
            this.props.getUserById(auth_uid, idToken, widgetApp)
            this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
            this.props.getactivitiesCalendar(auth_uid);
            this.props.getActivityGroupsList(auth_uid, [orgId]);
            this.props.getHelpContacts()
            this.props.networkActivity()
         }
 
         if (this.props.location && this.props.location.search) {
             const params = queryString.parse(this.props.location.search)
             this.setState({ role: params.role })
         }
         this.setState({ firebaseToken: idToken });
     }
     handleOpenNotificationStartActivity = () => {
         const params = this.getParams();
         if (params && params.achievementId) {
             console.log(" params.achievementId --> ", params.achievementId);
             let date = Moment().format('YYYY-MM-DD HH:mm:ss Z');
             let uid = localStorage.getItem('auth_uid');
             this.props.executeActivityWithAchievementId(params.achievementId, date, uid);
             this.props.selectActivityId(params.achievementId);
             this.props.handlEditor(true);
             this.props.history.push("/app/lsdashboard");
         }
     }
 
     getParams() {
         let params = null
         if (this.props.location && this.props.location.search) {
             params = queryString.parse(this.props.location.search)
         } else if (window.location && window.location.search) {
             params = queryString.parse(window.location.search)
         }
         return params
     }
     handleStartActivity = (e) => {
         this.props.handlEditor(true)
         this.setState({ previewId: null, mobileOpen: true, activityId: e.activityId, activityType: e.type })
     }
 
     handleRewards = () => {
         this.props.selectActivity(null)
         this.props.handlEditor(false)
         let uid = localStorage.getItem('uid')
         let idToken = localStorage.getItem('idToken')
         this.props.getUserById(uid, idToken)
         this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '', showRewards: true })
     }
 
     handleClose3 = () => {
         this.props.selectActivityId(null)
         this.props.selectActivity(null)
         this.props.handlEditor(false)
         let uid = localStorage.getItem('uid')
         this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '' })
     }
 
     handleClose2 = () => {
         this.props.selectActivityId(null)
         this.props.selectActivity(null)
         this.props.handlEditor(false)
         this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '', openForm: true })
     }
 
     handleDrawerToggle = () => {
         this.setState({ mobileOpen: !this.state.mobileOpen });
     };
 
     getCourseLaunchURL = (course) => {
         // const courseId = course.id
         // this.props.getCourseLaunchURL(courseId);
         // let key = 3;
         // localStorage.setItem('selectedIndex', JSON.stringify(key))
         // this.props.history.push(`/app/lms_course`);
         console.group("%c[LMSCourse]%c Rendered LMS iFrame", "color:black", "color:green");
         const courseId = course.id
         this.props.getCourseLaunchURL(courseId);
         this.setState({ coursePanel: true, selectedCourse: course.name })
     }
 
     previewId = (e) => {
         this.props.selectActivity(true)
         this.props.selectActivityId(e);
         localStorage.setItem('selectedIndex', JSON.stringify(1))
         localStorage.setItem('previewId', e);
         this.props.history.push(`/app/user/my_activities`);
     }
 
     handleCloseCourse() {
         console.log("%c[LMSCourse]%c Closed LMS iFrame", "color:black", "color:red");
         console.groupEnd();
         this.setState({ coursePanel: false, assingnActivityPanel: false, selectedCourse: '' })
     }
     handleCloseAssignActivity() {
         this.setState({ assingActivityInfo: {} });
         this.props.showAssignActivityIFramePanel(false)
     }
     handleOpenAssingActivity = (assingActivityInfo) => {
         // console.log("assingnActivity data --->", assingActivityInfo)
         console.group("%c[AssignActivityiFrame]%c Rendered Assign Activity iFrame", "color:black", "color:green");
         this.setState({ assingActivityInfo: assingActivityInfo });
         this.props.showAssignActivityIFramePanel(true)
     }
 
     handleOpenObjectEditor = (type) => {
         console.log("add icon clicked -->", type)
         this.setState({openEditor: true, activityId: null, editorType:type})
     }
 
     handleCloseObjectEditor = () => {
         this.setState({openEditor: false, activityId: null, editorType:null})
         const auth_uid = localStorage.getItem('auth_uid');
         this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
         this.props.getactivitiesCalendar(auth_uid);
     }
 
     handleEditActivity = (activityId, editorType) => {
         console.log("edit activity -->", activityId, editorType)
         this.setState({openEditor: true, activityId: activityId, editorType})
     }
     getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh)';
        } else {
            return '630px';
        }
     }
 
     render() {
         window["auth0"] = this.props.auth0
         let {activityId, activityType, userDetails, coursePanel, selectedCourse, assingActivityInfo } = this.state;
         const { clientPanel, match, userRole, launchpad, loading, myCourseLoading, myCourses, allowedModules, assingnActivityPanel, openChatPanel, displayPushNotiAlert, displayOnce, showActivityHistory, popupThemeClass, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, hideRewards } = this.props;
         //console.log(myCourses && myCourses.courses ? myCourses.courses : null);
         let list = this.props.selectedRole === 'admin' ? this.props.privateActivities : this.props.allActivities;
         // console.log("allowedModules",allowedModules);
         return (
             <div className="w-100 " data-testid="ls-dashboard">
                 {
                     showActivityHistory ?
                         <AchievementHistoryList popupThemeClass={this.props.popupThemeClass} />
                         : assingnActivityPanel ?
                             <AssignActivityComponent
                                 handleCloseAssignActivity={this.handleCloseAssignActivity.bind(this)}
                                 assingActivityInfo={assingActivityInfo}
                             /> : clientPanel ?
                                 <UserDetails
                                     previewId={this.previewId}
                                     handleStartActivity={this.handleStartActivity}
                                     showUserDetails={this.showUserDetails}
                                     handleOpenAssingActivity={this.handleOpenAssingActivity}
                                 /> : showActivityDetailsPanel && showEncounterActivityDetails ?
                                     <ActivityDetails handleStartActivity={this.props.handleStartActivity} />
                                    : 
                                    <div className="row">                      
                                        {userRole ? userRole !== 'Client' &&
                                            <RctCollapsibleCard
                                                colClasses="col-12 w-xs-full"
                                                heading={<div className="d-flex align-items-center justify-content-between text-white">
                                                    <div>My Clients</div>
                                                    <div /*onClick={() => this.openActivity("clients")}*/>
                                                        {this.props.groupMembers && Object.keys(this.props.groupMembers).length > 0 ? "(" + Object.keys(this.props.groupMembers).length + ")" : ""}
                                                    </div>
                                                </div>
                                                }
                                                fullBlock
                                                customClasses="overflow-hidden theme-background"
                                                headingCustomClasses="bg-lifesherpa "
                                            >
                                                <MyClientsPanel showUserDetails={this.showUserDetails} handleOpenAssingActivity={this.handleOpenAssingActivity} />
                                            </RctCollapsibleCard> : ""
                                        }
                                    </div>
                 }
             </div>
         )
     }
 }
 
 const mapStateToProps = ({ authUser, activitiesList, Charts, MyCourses, clientsList, GroupList, Resources, chatAppReducer, settings, emailApp }) => {
     let { clientPanel } = clientsList;
     const { userRole, allowedModules, displayPushNotiAlert, displayOnce, hideRewards } = authUser
     const { allActivities, privateActivities, launchpad, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails } = activitiesList
     const { usersCharts, loading } = Charts
     const { selectedUser, displayName, profileImage, selectedMemberId, selectedRole, openChatPanel } = chatAppReducer
     let { groupMembers } = GroupList
     const { resourceList, categories } = Resources;
     const { myCourseLoading, myCourses } = MyCourses;
     const { screenThemeClass, popupThemeClass, assingnActivityPanel } = settings;
     const { showActivityHistory } = emailApp;
     return { selectedRole, categories, groupMembers, resourceList, allActivities, privateActivities, userRole, launchpad, loading, myCourseLoading, myCourses, clientPanel, allowedModules, screenThemeClass, popupThemeClass, assingnActivityPanel, displayPushNotiAlert, displayOnce, showActivityHistory, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, hideRewards }
 }
 
 export default withAuth0(connect(mapStateToProps, {
     getlaunchpad,
     selectActivityId,
     selectActivity,
     handlEditor,
     getCourseLaunchURL,
     changeRole,
     showClientPanel,
     getActivitiesList,
     getUserById,
     selectMenuItem,
     showAssignActivityIFramePanel,
     getActivityGroupsList,
     executeActivityWithAchievementId,
     networkActivity,
     hideActivityHistoryDetail,
     handleHideActivityDetailsPanel,
     getactivitiesCalendar,
     getHelpContacts
 })(Clients))
 