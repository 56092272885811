import React, { Component } from "react";
import { connect } from 'react-redux'
import ConfiguratorIFrame from "./ConfiguratorIFrame";
import LmsIFrame from "./LmsIFrame";
import { getLmsLoginUrl } from "../../actions";
import CoursesLmsiFrame from "./CoursesLmsiFrame";

class LifesherpaiFrame extends Component {

    async componentDidMount() {
       // await this.props.getLmsLoginUrl();
    }

    render() {
        let pathname = this.props.location.pathname;
        return (
            <>
                {pathname == "/app/lms" ?
                    <LmsIFrame />
                    : pathname == "/app/lms_course" ?
                        <CoursesLmsiFrame />
                        : <ConfiguratorIFrame />
                }
            </>

        )
    }
}

// export default LifesherpaiFrame
const mapStateToProps = ({ authUser }) => {
    const { userRole, lmsUrl } = authUser
    return { userRole, lmsUrl }
}

export default connect(mapStateToProps, {
    getLmsLoginUrl
})(LifesherpaiFrame)
