import {
    USER_INACTIVITY_CONTINUE,
    USER_INACTIVITY_LOGOUT,
    USER_INACTIVITY_AUTOLOGOUT,
    USER_INACTIVITY_TIMER_UPDATE,
    HANDLE_UNACTIVE_ALERT,
    USER_SET_INACTIVE_TIMER_VALUE,
    CLEAR_INACTIVITY_IDLE_DATA,
    STORE_IN_ACTIVE_SET_INTERVAL_DATA
} from '../actions/types';

//Initial state value
const INIT_STATE = {
    logout: false,
    autoLogout: false,
    continueUser: false,
    timer: null,
    showUnActiveAlert: false,
    inAvtivityValue: null,
    inactivityWarningPeriod: null,
    factor: null,
    setIntervalData:null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case USER_INACTIVITY_CONTINUE:
            return { ...state, continueUser: action.payload, timer: null, showUnActiveAlert: false,  };

        case USER_INACTIVITY_LOGOUT:
            return {...state, logout: action.payload}

        case USER_INACTIVITY_AUTOLOGOUT:
            return { ...state, autoLogout: action.payload}; 

        case USER_INACTIVITY_TIMER_UPDATE:
            return { ...state, timer: action.payload};

        case HANDLE_UNACTIVE_ALERT:
            return { ...state, showUnActiveAlert: action.payload};

        case USER_SET_INACTIVE_TIMER_VALUE:
            const inactivityWarningPeriod = action.payload.inactivityWarningPeriod
            const inactivityWarningThreshold = action.payload.inactivityWarningThreshold
            const inAvtivityValue = inactivityWarningThreshold * 1000
            const factor = 100 / inactivityWarningPeriod
            return { ...state, inAvtivityValue, inactivityWarningPeriod, factor};

        case CLEAR_INACTIVITY_IDLE_DATA :
            return { ...state,  logout: false, autoLogout: false, continueUser: false, timer: null, showUnActiveAlert: false, inAvtivityValue: null, inactivityWarningPeriod: null, factor: null};
        
        case STORE_IN_ACTIVE_SET_INTERVAL_DATA:
            return { ...state, setIntervalData: action.payload};
        default: 
            return { ...state };
    }
}
