import React, { Component, createContext } from "react";
import { auth } from "../firebase";
import {onIdTokenChanged} from 'firebase/auth'

//Lato font family
import "@fontsource/josefin-sans";
import "@fontsource/lato"

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    user: null
  };

  componentDidMount = () => {
    // AuthStateChanged
    onIdTokenChanged(auth, userAuth => {
      if(userAuth) {
          userAuth.getIdToken().then(function (result) {
            localStorage.setItem("idToken", result);         
          }).catch(function (error) {
              console.log('google user error-->',error)
          });
        this.setState({ user: userAuth});
      }
    });
  };
  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
