import React, { Component } from "react";
import { getToken } from "../../firebase"
import config from '../../config/config'
import LifeSherpaLoading from '../LifeSherpaLoading';
import { configuratorOpenedInIframe } from "../../actions";

import { connect } from 'react-redux'
import Iframe from "./iframe.js";

class ConfiguratorIFrame extends Component {
  state = {
    configuratorUrl: '',
    loading: true,
  }

  hideSpinner = () => {
    console.log("%c Configurator loaded successfully............","color:green");
    this.setState({ ...this.state, loading: false });
  }

  getConfiguratorUrl() {
    let testing = localStorage.getItem("testing");
    if (testing == null || testing == undefined) {
      getToken().then((result) => {
        console.log("Fetched token for configurator")
        let url = `${config.configurator}/#/lifesherpa/user?ssotoken=${result}#user`;
        this.setState({ ...this.state, configuratorUrl: url });
      }).catch((error) => {
        console.log("Error occured while fetching token for configurator: ", error)
        this.setState({loading: false});
      })
    }
  }


  componentDidMount() {
    this.getConfiguratorUrl();
    this.props.configuratorOpenedInIframe();
  }


  render() {

    let { configuratorUrl, loading } = this.state;
    //Configurator url for testing configurator iframe component 
    let configuratorUrltesting = localStorage.getItem("configuratorUrl");
    if (configuratorUrltesting) {
      configuratorUrl = configuratorUrltesting;
      loading = false;
    }
    return (
      <div data-testid="configurator-iframe-conponent">
        <LifeSherpaLoading loading={loading} />
        {configuratorUrl ?
          <div data-testid="configurator-iframe" style={{ visibility: loading ? "hidden" : "visible" }}>
            <Iframe
              url={configuratorUrl}
              onLoad={this.hideSpinner}
              height="calc(100vh - 196px)"
              scrolling="yes"
              id="myId"
              className="w-100"
              display="initial"
              position="relative"
              allowFullScreen
              loading="my configurator"
            />
          </div>
          : <></>
        }
      </div>

    )
  }
}
const mapStateToProps = ({ authUser, activitiesList, Charts }) => {
  const { userRole } = authUser
  const { launchpad, loading1 } = activitiesList
  const { usersCharts, loading } = Charts
  let filterChartData = usersCharts && usersCharts.length > 0 ? usersCharts.filter(item => item.chartId === "ActivityByRoutine") : null
  let chartData = filterChartData ? filterChartData[0] : null
  return { userRole, launchpad, loading1, loading, chartData }
}

export default connect(mapStateToProps, {configuratorOpenedInIframe})(ConfiguratorIFrame)
