/**
 * Recent Chat Users
 */
import React, { Component } from 'react';
import List from '@mui/material/List';
import { connect } from 'react-redux';

// components
import ActivityHistoryListItem from './ActivityHistoryListItem';

// actions
import { chatWithSelectedUser, getRecentChatUsers, getAchievementDetail, getSelectedHistAchievement } from 'Actions';

class ActivityHistoryList extends Component {

    onClickListItem(achievement) {
        console.log("selected histroy item -->  ", achievement)
        this.props.getSelectedHistAchievement(achievement)
        const userId = localStorage.getItem("uid");
        this.props.getAchievementDetail(userId, achievement.id, null, achievement.organization);
        this.props.handleOpenActivityDetails();
    }

    render() {
        const { allActivitiesHistory, showUnconfirmedAcheivemts, loading } = this.props;
        const auth_uid = localStorage.getItem("auth_uid")
        const uid = localStorage.getItem("uid")
        const isClientUser = auth_uid !== uid ? true : false
        if (allActivitiesHistory === null) {
            return (
                <div className="no-found-user-wrap text-center mt-4 pt-4">
                    {!loading && <h4>{showUnconfirmedAcheivemts ? "Achievements Not Found" : "Activity History Not Found"}</h4>}
                </div>
            );
        }
        //console.log("allActivitiesHistory----->",allActivitiesHistory);
        return (
            <div className="">
                {allActivitiesHistory && allActivitiesHistory.length > 0 ? allActivitiesHistory.map((activity, key) => (
                    <ActivityHistoryListItem
                        selectedActivity={this.props.selectedActivitiesHistory}
                        activity={activity}
                        key={key}
                        alternativeColor={key % 2 == 0 ? false : true}
                        onClickListItem={() => this.onClickListItem(activity)}
                        isClientUser={isClientUser}
                        showUnconfirmedAcheivemts={showUnconfirmedAcheivemts}
                    />
                )) : 
                    <div className="no-found-user-wrap text-center mt-4 pt-4">
                        {!loading && <h4>{showUnconfirmedAcheivemts ? "Achievements Not Found" : "Activity History Not Found"}</h4>}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ chatAppReducer, activitiesHistoryList }) => {
    const { selectedMemberId } = chatAppReducer;
    const { showUnconfirmedAcheivemts, loading, allActivitiesHistory, selectedActivitiesHistory } = activitiesHistoryList
    return { selectedMemberId, showUnconfirmedAcheivemts, loading, allActivitiesHistory, selectedActivitiesHistory };
};

export default connect(mapStateToProps, {
    chatWithSelectedUser,
    getRecentChatUsers,
    getAchievementDetail,
    getSelectedHistAchievement,
})(ActivityHistoryList);
