/**
 * Clients List Actions
 */

import {axiosClient} from '../sherpaApi'
import moment from 'moment'
import {getPokeForm, getUpdatedEvaluationForm, getUnConfirmActivityDetail} from '../actions'

import {
    GET_RECENT_HISTORY,
    GET_SELECTED_HISTORY,
    UPDATE_HISTORY_SEARCH,
    SEARCH_HISTORY,
    GET_HISTORY_LIST,
    GET_SUCCESS_HISTORY_LIST,
    GET_ACHEIVEMENTS_DETAIL,
    GET_SUCCESS_ACHEIVEMENTS_DETAIL,
    SHOW_LOADING_INDICATOR_HISTORY_DETAIL,
    HIDE_LOADING_INDICATOR_HISTORY_DETAIL,
    POST_EVALUATION_FORM,
    POST_SUCCESS_EVALUATION_FORM,
    POST_STEPS_NOTES_FORM,
    POST_SUCCESS_STEPS_NOTES_FORM,
    SET_SELECTED_STEP_ID,
    SET_ACHIEVEMENTS_HAS_MORE_DATA,
    SET_ACHIEVEMENTS_PAGE_COUNT,
    SET_ACHIEVEMENTS_DATA_CLEAR,
    SHOW_LOADING_INDICATOR_HISTORY_LIST,
    HIDE_LOADING_INDICATOR_HISTORY_LIST,
    USER_CONFIRM_ACHEIVEMENT,
    USER_CONFIRM_ACHEIVEMENT_SUCCESS,
    CATCH_API_ERROR,
    GET_CLIENT_SUCCESS_POKE_FORM,
    SHOW_UNCONFIRMED_ACHIEVEMENTS,
    SET_HISTORY_LIST_PAGE_COUNT
} from './types';

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const getSelectedHistAchievement = (history) => ({
    type: GET_SELECTED_HISTORY,
    payload: history
});
export const clearHistoryData = () => ({
    type: SET_ACHIEVEMENTS_DATA_CLEAR
})
export const setPageCount = (page) => ({
    type: SET_ACHIEVEMENTS_PAGE_COUNT,
    payload: page
});
export const setAchievementHasMoreData = (hasMore) => ({
    type: SET_ACHIEVEMENTS_HAS_MORE_DATA,
     payload: hasMore
 })
export const setSelectedStep = (step) => ({
   type: SET_SELECTED_STEP_ID,
    payload: step
})
export const getRecentHistory = (history) => ({
    type: GET_RECENT_HISTORY,
    payload: history
});

export const postEvaluationForm = (userId, achievementId, body, isUnconfirm) => (dispatch) => {
    console.log("postEvaluationForm: ", body)
    let bodyJson = {
        "description": "Routine evaluation",
        "formID": "-L7LIIQYc7wcKvaAn4FB",
        "formName": "Evaluation",
        "sections": body.sections,
        "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z")
      }
    let url = `/users/${userId}/achievements/${achievementId}/evaluation-form` 
    let idToken = localStorage.getItem('idToken')
    let params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({type: POST_EVALUATION_FORM})
    axiosClient.post(url, bodyJson, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if (isUnconfirm) {
                dispatch(getUnConfirmActivityDetail(userId, achievementId))
            } else {
                dispatch(getAchievementDetail(userId, achievementId))
            }
            dispatch({ type: POST_SUCCESS_EVALUATION_FORM, payload: response.data });
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
        })
}
export const sendEvaluationFormResponse = (userId, achievementId, body,formId, isUnconfirm) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let bodyJson = {
        "description": body.description,
        "formID": formId,
        "formName": body.formName,
        "sections": body.sections,
        "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    console.log(" sended form data ---> ", bodyJson);
    let url = `/users/${userId}/achievements/${achievementId}/evaluation-form?organization=${orgId}` 
    let idToken = localStorage.getItem('idToken')
    let params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({type: POST_EVALUATION_FORM})
    axiosClient.post(url, bodyJson, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if (isUnconfirm) {
                dispatch(getUnConfirmActivityDetail(userId, achievementId))
            } else {
                dispatch(getAchievementDetail(userId, achievementId))
            }
            dispatch({ type: POST_SUCCESS_EVALUATION_FORM, payload: response.data });
            dispatch({ type: GET_CLIENT_SUCCESS_POKE_FORM, payload: null });
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
        })
}
export const sendStepsNotesFormResponse = (userId, achievementId, body, formId, stepId, isUnconfirm) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let bodyjson = {
        "description": body.description,
        "formID": formId,
        "formName": body.formName,
        "sections": body.sections,
        "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z")
      }
    let url = `/users/${userId}/achievements/${achievementId}/steps/${stepId}/step-notes-form?organization=${orgId}` ///users/{userId}/achievements/{achievementId}/steps/{stepId}/step-notes-form
    let idToken = localStorage.getItem('idToken')
    let params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({type: POST_STEPS_NOTES_FORM})
    axiosClient.post(url, bodyjson, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if (isUnconfirm) {
                dispatch(getUnConfirmActivityDetail(userId, achievementId))
            } else {
                dispatch(getAchievementDetail(userId, achievementId))
            }
            dispatch({ type: POST_SUCCESS_STEPS_NOTES_FORM, payload: response.data });
            dispatch({ type: GET_CLIENT_SUCCESS_POKE_FORM, payload: null });
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
        })
}

export const postStepsNotesForm = (userId, achievementId, body, stepId, isUnconfirm) => (dispatch) => {
    let bodyjson = {
        "description": "Select a pre-made message",
        "formID": "-LSP63A5jXbEmIlOH1HZ",
        "formName": "Step Notes",
        "sections": body.sections,
        "timeStamp": moment().format("YYYY-MM-DD HH:mm:ss Z")
      }
    let url = `/users/${userId}/achievements/${achievementId}/steps/${stepId}/step-notes-form` ///users/{userId}/achievements/{achievementId}/steps/{stepId}/step-notes-form
    let idToken = localStorage.getItem('idToken')
    let params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({type: POST_STEPS_NOTES_FORM})
    axiosClient.post(url, bodyjson, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if (isUnconfirm) {
                dispatch(getUnConfirmActivityDetail(userId, achievementId))
            } else {
                dispatch(getAchievementDetail(userId, achievementId))
            }
            dispatch({ type: POST_SUCCESS_STEPS_NOTES_FORM, payload: response.data });
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
        })
}

export const getHistoryList = (userId, routineId, pageCount) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let url = `/users/${userId}/achievements?organization=${orgId}`
    let idToken = localStorage.getItem('idToken');
    pageCount = pageCount ? pageCount : 1
    console.log("fetching activity history for page --> ", pageCount);
    const params = {
        'routineId': routineId,
        'page': pageCount,
        'limit': 20,
        'order': 'desc',
        'summary': true
    }
    dispatch({type: GET_HISTORY_LIST}) 
    axiosClient.get(url, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            console.log("activity history list api response ---->");
            dispatch({ type: GET_SUCCESS_HISTORY_LIST, payload: response.data });
            if(pageCount == 1) {
                if(response.data && response.data.length > 0) {
                    dispatch(getSelectedHistAchievement(response.data[0]));
                    dispatch(getAchievementDetail(userId, response.data[0].id, null, response.data[0].organization))
                }    
            }
            
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
            dispatch({type: CATCH_API_ERROR, payload: error})
        })
}
export const getHistoryListForNotification = (userId, routineId, achievementId, pageCount) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let url = `/users/${userId}/achievements?organization=${orgId}`
    let idToken = localStorage.getItem('idToken');
    pageCount = pageCount ? pageCount : 1
    console.log("fetching activity history for page --> ", pageCount);
    const params = {
        'routineId': routineId,
        'page': pageCount,
        'limit': 20,
        'order': 'desc',
        'summary': true
    }
    dispatch({type: GET_HISTORY_LIST}) 
    axiosClient.get(url, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            console.log("activity history list api response ---->");
            dispatch({ type: GET_SUCCESS_HISTORY_LIST, payload: response.data });
            if(pageCount == 1) {
                if(response.data && response.data.length > 0) {
                    let selectedAchievement = response.data.find(achievement => achievement.id === achievementId);
                    if(selectedAchievement && achievementId) {
                        dispatch(getSelectedHistAchievement(selectedAchievement));
                        dispatch(getAchievementDetail(userId, achievementId))
                    } else {
                        dispatch(getSelectedHistAchievement(response.data[0]));
                        dispatch(getAchievementDetail(userId, response.data[0].id))
                    }   
                }    
            }
            
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
            dispatch({type: CATCH_API_ERROR, payload: error})
        })
}


export const getClientUnconfirmedAchievementsList = (userId, pageCount) => (dispatch) => {
    // const orgId = localStorage.getItem("selectedOrgId")
    let url = `/users/${userId}/unconfirmed-achievements`
    let idToken = localStorage.getItem('idToken');
    pageCount = pageCount ? pageCount : 1
    console.log("fetching unconfirmedAchievements for page --> ", pageCount);
    const params = {
        'page': pageCount,
        'limit': 20,
        'order': 'desc',
        'summary': true
    }
    dispatch({type:SHOW_UNCONFIRMED_ACHIEVEMENTS})
    axiosClient.get(url, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            console.log("activity history list api response ---->");
            dispatch({ type: GET_SUCCESS_HISTORY_LIST, payload: response.data });
            if(pageCount == 1) {
                if(response.data && response.data.length > 0) {
                    dispatch(getSelectedHistAchievement(response.data[0]));
                    dispatch(getAchievementDetail(userId, response.data[0].id, null, response.data[0].organization))
                }    
            }
            
        })
        .catch(error => {
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
            dispatch({type: CATCH_API_ERROR, payload: error})
        })
}


export const getAchievementDetail = (userId, achievementId, type, organization) => (dispatch) => {
    const orgId = organization || localStorage.getItem("selectedOrgId") 
    let url = `/users/${userId}/achievements/${achievementId}?organization=${orgId}`
    let idToken = localStorage.getItem('idToken')
    const params = {
        'summary': true
    }
    dispatch({type: GET_ACHEIVEMENTS_DETAIL})
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_DETAIL });
            if (type === 'evaluationForm' && response.data) {
                if(response.data.evaluationResponseId) {
                    dispatch(getUpdatedEvaluationForm(userId, response.data.evaluationResponseId))
                } else {
                    dispatch(getPokeForm(response.data.routine.evaluationId))
                }
                dispatch(setSelectedStep(null))
            } else if (type === 'stepNotesForm' && response.data.routine) {
                if(response.data.StepsCompletion) {
                    let stepNotesResponseId = null
                    Object.keys(response.data.StepsCompletion).map((obj, key) => {
                        if (response.data.StepsCompletion[obj]) {
                            stepNotesResponseId = response.data.StepsCompletion[obj].stepNotesResponseId
                        }
                    })
                    dispatch(getUpdatedEvaluationForm(userId, stepNotesResponseId))
                } 
                dispatch(setSelectedStep(response.data.routine && Object.keys(response.data.routine.steps)[0]))
            }else {
                dispatch({ type: GET_SUCCESS_ACHEIVEMENTS_DETAIL, payload: response.data });
                console.log("fetched achievement details ---->", response.data)
                // dispatch(getAchievementSummary(userId, achievementId));
            }
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_DETAIL });
            dispatch({type: CATCH_API_ERROR, payload: error})
        })
}

export const getAchievementSummary = (userId, achievementId, type) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let url = `/users/${userId}/achievements/${achievementId}?summary=${true}&organization=${orgId}`
    let idToken = localStorage.getItem('idToken')
    const params = {
        'summary': true
    }
    axiosClient.get(url, {
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            console.log("fetched achievement summary ---->", response.data)
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_DETAIL });
            dispatch({type: CATCH_API_ERROR, payload: error})
        })
}


/**
 * Redux Action To Update Client Search
 */
export const updateHistorySearch = (value) => ({
    type: UPDATE_HISTORY_SEARCH,
    payload: value
});

/**
 * export const to search Clients
 */
export const onSearchHistory = (value) => ({
    type: SEARCH_HISTORY,
    payload: value
});

/**
 * Redux Action To Update Client Search
 */
export const showHistoryDetailLoading = () => ({
    type: SHOW_LOADING_INDICATOR_HISTORY_DETAIL,
});

/**
 * export const to search Clients
 */
export const hideHistoryDetailLoading = () => ({
    type: HIDE_LOADING_INDICATOR_HISTORY_DETAIL,
});

/**
 * Redux Action To Update Client Search
 */
export const showHistoryListLoading = () => ({
    type: SHOW_LOADING_INDICATOR_HISTORY_LIST,
});

/**
 * export const to search Clients
 */
export const hideHistoryListLoading = () => ({
    type: HIDE_LOADING_INDICATOR_HISTORY_LIST,
});

export const confirmAchevement = (userId, achievementId, answer) =>(dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    const requestBody = {
        answer
    }
    const idToken = localStorage.getItem('idToken')
    const url = `/users/${userId}/achievements/${achievementId}/confirm?organization=${orgId}`
    const params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({ type: USER_CONFIRM_ACHEIVEMENT});
    axiosClient.post(url, requestBody, {
        params: params,
        headers: {
           'Authorization': `Bearer ${idToken}`
        }
    })
    .then((response) => {
        dispatch({ type: USER_CONFIRM_ACHEIVEMENT_SUCCESS, payload: response.data });
        dispatch(getAchievementDetail(userId, achievementId))

    })
    .catch(error => {
        // error handling
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_LIST });
        dispatch({ type: HIDE_LOADING_INDICATOR_HISTORY_DETAIL });
        dispatch({type: CATCH_API_ERROR, payload: error});
        dispatch(getAchievementDetail(userId, achievementId))
    })
}

export const setHistoryListPageCount = (count) =>(dispatch) => {
    dispatch({type: SET_HISTORY_LIST_PAGE_COUNT, payload: count});
}






