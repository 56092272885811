/**
 * Landing Page Activity List Item
 */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import { Label } from 'reactstrap';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Avatar from '@mui/material/Avatar';
import CountUp from 'react-countup';

const WorkflowItem = ({ userRole, handleOpenWorkflowPendingRoleList, openWorkflowExecutionHistory, hideRewards, itemKey, anchorEl, handleClickMenu, handleCloseMenu, handleStartWorkflow, selectedMenuWorkflow, showStartOrResumeButton, organization, workflow, showRolePendingAssignment, handleClickOnStatus }) => (
    <div data-testid={`workflow-list-item${itemKey}`} className="mb-2 mx-2" key={itemKey}>
        {workflow.state === "paused" || workflow.state === "inProgress" ?
            <div className="d-flex justify-content-between align-items-center ml-1">
                <div className="col-4 col-md-4  col-lg-4 text-center activities-list-inprogress-title" >In Progress</div>
            </div>
            : ''
        }
        {hideRewards ?
            <div data-testid={"workflow-reward-diamand-" + itemKey} className="row mx-auto align-items-center justify-content-center mb-auto text-secondary position-relative font-lg" style={{ width: 50 + 'px', height: "46px" }}></div>
            : <div data-testid={"workflow-reward-diamand-" + itemKey} className="row mx-auto align-items-center justify-content-center  mb-auto text-secondary position-relative   font-lg" style={{ width: 50 + 'px' }}>
                <img className="mt-1 mx-auto d-block" style={{ zIndex: "1" }} src={require("Assets/img/diamond_icon.png")} alt="project logo" width="43" height="43" />
                {workflow.rewards && workflow.rewards > 99 ?
                    <h2 className="text-white reward-style " >{workflow.rewards && workflow.confirmationRewards ? workflow.rewards + workflow.confirmationRewards : workflow.rewards ? workflow.rewards : workflow.confirmationRewards ? workflow.confirmationRewards : 0}</h2>
                    : <h1 className="text-white reward-style " >{workflow.rewards && workflow.confirmationRewards ? workflow.rewards + workflow.confirmationRewards : workflow.rewards ? workflow.rewards : workflow.confirmationRewards ? workflow.confirmationRewards : 0}</h1>
                }
            </div>
        }
        <li
            key={itemKey}
            className={"d-flex  list-card p-3 justify-content-between align-items-center list-item " + `${workflow.state === "paused" || workflow.state === "inProgress" ? "activities-list-item-border-inprogress" : "activities-list-item-border"}`}
            style={{ backgroundImage: `url(${workflow.backgroundImageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain', backgroundPosition:"center" }}
        >
            <div className="w-100">
                <div className='d-flex align-items-center justify-content-between'>
                    <div className="d-flex align-items-center px-1">
                        <IconButton title="Workflow" data-testid={"list-workflow-type-icon-" + itemKey}>
                            <Diversity3OutlinedIcon color="primary" />
                        </IconButton>
                        {organization ? 
                            <Label title={organization.name+' '+workflow.name} className='mb-0 d-flex align-items-center justfy-content-center'>
                                <CorporateFareIcon className='mr-1'/>{organization.name}
                            </Label>
                            : <></>
                        }
                    </div>
                    <div className="/*moreButton*/">
                        <div className="w-4 d-flex justify-content-end align-items-center px-1 ml-1" style={{ borderRadius: 12 + "px" }}>
                            <div className='d-flex align-items-center justfy-content-between'>
                                <IconButton onClick={handleClickMenu} data-testid={"vertical-menu-icon-" + itemKey} title="Activity More Actions">
                                    <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                                </IconButton>
                            </div>
                            
                        </div>
                        {selectedMenuWorkflow && selectedMenuWorkflow.id === workflow.id &&
                            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} >
                                {userRole && userRole !== 'Client' && showRolePendingAssignment &&
                                    <MenuItem className="menuEditItem" onClick={handleOpenWorkflowPendingRoleList}>Pending Role Assignment</MenuItem>
                                }
                                <MenuItem className="menuEditItem" onClick={openWorkflowExecutionHistory}>Workflow History</MenuItem>
                                <MenuItem className="menuEditItem" onClick={handleCloseMenu}>Close</MenuItem>
                            </Menu>
                        }
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div></div>
                    <div>
                        <IconButton title="Completed Instances" data-testid={"list-workflow-completed-icon-" + itemKey} onClick={() => handleClickOnStatus(workflow, "completed")}>
                            <Avatar className='bg-secondary'>
                                <CountUp
                                    separator=","
                                    className="count-value"
                                    start={0}
                                    end={workflow.workflowHistory && workflow.workflowHistory.completed ? workflow.workflowHistory.completed : 0}
                                    duration={5}
                                    useEasing={true}
                                />
                            </Avatar>
                        </IconButton>
                        <div className='text-center small'>Completed</div>
                    </div>
                    <div>
                        <IconButton title="Open Instances" data-testid={"list-workflow-open-icon-" + itemKey} onClick={() => handleClickOnStatus(workflow, "open")}>
                            <Avatar className='bg-success'>
                                <CountUp
                                    separator=","
                                    className="count-value"
                                    start={0}
                                    end={workflow.workflowHistory && workflow.workflowHistory.open ? workflow.workflowHistory.open : 0}
                                    duration={5}
                                    useEasing={true}
                                />
                            </Avatar>
                        </IconButton>
                        <div className='text-center small'>Open</div>
                    </div>
                    <div>
                        <IconButton title="Cancelled Instances" data-testid={"list-workflow-cancelled-icon-" + itemKey} onClick={() => handleClickOnStatus(workflow, "cancelled")}>
                            <Avatar className='bg-danger'>
                                <CountUp
                                    separator=","
                                    className="count-value"
                                    start={0}
                                    end={workflow.workflowHistory && workflow.workflowHistory.cancelled ? workflow.workflowHistory.cancelled : 0}
                                    duration={5}
                                    useEasing={true}
                                />
                            </Avatar>
                        </IconButton>
                        <div className='text-center small'>Cancelled</div>
                    </div>
                    <div></div>
                </div>
                <div className="row">
                    <div /*onClick={userRole && userRole !== 'Client' && handleOpenWorkflowPendingRoleList}*/ className={showStartOrResumeButton ? "col-12 pt-2" : "col-12 pt-2 pb-3"}>
                        <div className=" text-center pt-2 pb-2" >
                            <h4 data-testid={"workflow-title-" + itemKey} className="mb-1" >{workflow && workflow.name ? workflow.name.toUpperCase() : ""}</h4>
                            <div data-testid={"workflow-description-" + itemKey} className="font-sm d-block">{workflow && workflow.description ? workflow.description : ""}</div>
                        </div>
                    </div>
                        <div className={workflow.state === "paused" || workflow.state === "inProgress" ? "col-12 pb-3" : "col-12 pb-3"}>
                            {workflow.showStartButton &&
                                <button title="Start" data-testid={"start-workflow-" + itemKey}  className="start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained primary-button MuiButton-containedPrimary cps mb-2" onClick={handleStartWorkflow}>START WORKFLOW</button>
                            }
                            {workflow.showViewHistory &&
                                <button title="Start" data-testid={"start-workflow-" + itemKey}  className="start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained primary-button MuiButton-containedPrimary cps" onClick={openWorkflowExecutionHistory}>VIEW STATUS</button>
                            }
                        </div>
                </div>
                <div className="d-none w-4 justify-content-end align-items-center text-right"><ArrowRightIcon /></div>
            </div>
        </li>
    </div>
);

export default WorkflowItem;