/**
* App
*/
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import queryString from 'query-string';
// css
import '../lib/reactifyCss';
// app component
import ARLFormDownload from './ARLFormDownload';
import UIFallbackScreen from '../components/UIFallbackScreen';

class App extends Component {
    getParamsData = () => {
        let params = {}
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        return params;
    }

    render() {
        const propsData = this.getParamsData();
        
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Router>
                <Switch>
                    <Route path="/external-links/xfa-form" render={(props) => <ARLFormDownload {...props} propsData={propsData} />} />
                    <Route path="/" render={(props) => <UIFallbackScreen {...props} />} /> 
                </Switch>
            </Router>
            </LocalizationProvider>

        )
    }
}

export default App;