/**
 * Signin Firebase
 */

 import React, { Component } from 'react';
 import Button from '@mui/material/Button';
 import { Link } from 'react-router-dom';
 import { Form, FormGroup } from 'reactstrap';
 import QueueAnim from 'rc-queue-anim';
 import { connect } from 'react-redux';
 import AppConfig from '../constants/AppConfig';
 import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
 // redux action
import {hideThemeLoading} from "../actions";
 
 
 class AnalyticsLogoutScreen extends Component {
    state = {

    } 

   componentDidMount() {
         setTimeout(() => {
            this.props.hideThemeLoading();
         }, 5000);
    }

    handleAnalyticsClose = () => {
        window.close();
    }
    render() {
       const { screenThemeClass, themeData } = this.props;
       const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo;
       const themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
       let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
       return (
          <QueueAnim type="bottom" duration={2000}>
             <div className={`rct-session-wrapper ${screenThemeClass}`}>
                <div className='login-screen analytics-logout-screen' style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
                <AppBar position="static" className="session-header">
               </AppBar>
                <div  data-testid="initial-launch-screen" className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div  className="session-body text-center">
                           <div className="container">
                            <div className="d-flex justify-content-center">
                                <div className="session-logo">
                                {activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
                                <Link to="/">
                                    <img src={themeLogo || applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img' />
                                </Link>
                                :
                                <Link to="/" className="text-center">
                                    <img src={themeLogo || applogo} alt="session-logo" width="auto" height="auto" className='auto-resize-img'/>
                                    <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                                    {themeData && themeData.loginLifeSherpaImage &&
                                        <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                                        }
                                                </div>
                                </Link>
                                }
                                </div>
                            </div>
                        </div>
                               <div className="session-head mb-4 mt-4">
                                 <h2 >You have been logged out from Analytics.</h2>
                                 {/* <p className="my-4 base-font-color">You have been logged out from Analytics. Click the button below to close this tab.</p> */}
                              </div>
                              {/* <Form>
                                 <FormGroup>
                                    <Button
                                       className="btn btn-block w-100 login-screen-button"
                                       variant="contained"
                                       size="large"
                                       onClick={() => this.handleAnalyticsClose()}
                                       data-testid="i-have-my-username-and-password"
                                    >
                                        Close
                                    </Button>
                                 </FormGroup>
                              </Form> */}
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
                </div>
             </div>
          </QueueAnim>
       );
    }
 }
 
 // map state to props
 const mapStateToProps = ({ authUser , settings}) => {
    const { user, loading, themeData, defaultAuth0LoginStatus, setAustralia, setUSA, themeLoading} = authUser; 
    const { screenThemeClass, popupThemeClass, themeLogo } = settings; 
    return { user, loading, screenThemeClass, popupThemeClass, themeLogo, themeData, defaultAuth0LoginStatus, setAustralia, setUSA, themeLoading}
 }
 
 export default connect(mapStateToProps,{hideThemeLoading})(AnalyticsLogoutScreen);
 
 
 